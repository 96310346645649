<mat-card>
  <mat-card-header class="fontTitleHeader">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal"> รายละเอียดประวัติเอกสาร </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="false">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="flex flex-col" style="padding: 20px; gap: 12px;">
    <div class="flex flex-row justify-center">
      <div class="flex flex-row" style="width: 100%">
        <div class="flex-1">
          <div class="flex flex-row p10 justify-between">
            <div class="f18 flex" style="gap: 10px; width: 70%;">
              <div class="self-center">
                รายละเอียดรายการ: {{ data.actionDescription }}
              </div>
              <div *ngIf="data.docUrl != undefined && data.docUrl != null && data.docUrl != ''">
                <button mat-icon-button matTooltip="พิมพ์เอกสาร" (click)="exportDocument()" matTooltipPosition="above"
                  style="height: 40px !important;">
                  <i class="ti ti-printer"></i>
                </button>
              </div>
            </div>
            <div class="f18 self-center" style="width: 30%">
              <div>
                ผู้ทำรายการ: {{ data.logBy }}
              </div>
              <div class="f18">
                วันที่เกิดรายการ: {{ data.logDate | thaidate }} {{ data.logDate | date:'HH:mm' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-center gap-3">
      <div class="flex flex-col p10 w100">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            Request Log
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding: 16px">
            <div class="flex">
              <div class="flex flex-col w100">
                <mat-form-field class="w100">
                  <textarea matInput placeholder="Response Body" [(ngModel)]="requestBody" name="requestBody"
                    #responseBody="ngModel" rows="15" readonly></textarea>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>