<mat-card class="example-card">
  <mat-card-header class="fontTitleHeader">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal"> กรอกจำนวนที่ต้องการ มส.13 </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <form #FormValidateItem="ngForm" class="py-10">
      <div>
        <dx-data-grid id="gridContainer" [dataSource]="dataSource" [columnAutoWidth]="true" [showBorders]="true"
          [allowColumnReordering]="true">
          <dxi-column caption="รายการที่" alignment="center" cellTemplate="wFlow" [width]="100">
          </dxi-column>
          <div *dxTemplate="let row of 'wFlow'">
            {{row.rowIndex + 1}}
          </div>

          <dxi-column alignment="center" dataField="productName" caption="รายการสินค้า">
          </dxi-column>

          <dxi-column alignment="center" cellTemplate="netWeight" caption="น้ำหนักรวม">
          </dxi-column>
          <div *dxTemplate="let row of 'netWeight'">
            {{row.data.netWeight | number:'0.6'}} {{row.data.netWeightUnit}}
          </div>

          <dxi-column alignment="center" cellTemplate="weightUsed" caption="ออกใบ มส.13 ไปแล้ว">
          </dxi-column>
          <div *dxTemplate="let row of 'weightUsed'">
            {{(row.data.weightUsed != null ? row.data.weightUsed : 0) | number:'0.6'}} {{row.data.netWeightUnit}}
          </div>

          <dxi-column alignment="center" cellTemplate="weightUsedMs13" caption="น้ำหนักที่ออกใบ มส.13">
          </dxi-column>
          <div *dxTemplate="let row of 'weightUsedMs13'">
            <mat-form-field>
              <input autocomplete="off" matInput placeholder="" type="number" [(ngModel)]="row.data.weightUsedMs13"
                name="weightUsedMs13_{{row.rowIndex}}" />
            </mat-form-field>
            {{row.data.netWeightUnit}}
          </div>

          <dxi-column alignment="center" cellTemplate="amountUsedMs13" caption="จำนวนที่ออกใบ มส.13">
          </dxi-column>
          <div *dxTemplate="let row of 'amountUsedMs13'">
            <mat-form-field>
              <input autocomplete="off" matInput placeholder="" type="number" [(ngModel)]="row.data.amountUsedMs13"
                name="amountUsedMs13_{{row.rowIndex}}" />
            </mat-form-field>
            {{row.data.quantityUnitName}}
          </div>
        </dx-data-grid>

      </div>
    </form>
  </mat-card-content>
  <div class="flex flex-col items-center p10 pb-10">
    <div class="basis-1/2 f18 flex" style="gap: 8px">
      <button mat-raised-button color="accent" (click)="createMs13()" [disabled]="!FormValidateItem.valid">
        สร้าง มส.13
      </button>
      <button mat-raised-button [mat-dialog-close]="true">
        ปิด
      </button>
    </div>
  </div>
</mat-card>