import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
  export class HttpLoadingService {
    loading = new BehaviorSubject<boolean>(false);
    enabled = true;
    countRequest = 0;
    constructor(private spinner: NgxSpinnerService) { }
    start(): void {
      if (!this.enabled) { return; }
      
      this.countRequest += 1;
      if (this.countRequest === 1) {
        this.spinner.show();
      }
    }
    stop(): void {
      if (this.countRequest > 0) {
        this.countRequest -= 1;
      }
  
      if (this.countRequest === 0) {
        setTimeout(() => {
          this.spinner.hide();
        }, 900);
  
      }
    }
  }
  