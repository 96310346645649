<mat-card>
    <mat-card-header>
        <div class="flex justify-between" style="width: 100%;">
            <div class="text-lg self-center">Weight </div>
            <div>
                <button mat-icon-button class="close-button" [mat-dialog-close]="true">
                    <i class="ti ti-x text-white"></i>
                  </button>
            </div>
        </div>
    </mat-card-header>
    <mat-dialog-content>
        <div class="pl-22 pr-22 flex flex-col gap-4">
            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>ตัวย่อ <span style="color:red;">*</span></h4>
                    <h4>Code</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-form-field style="flex: 0 80%;" class="flex">
                        <input required autocomplete="off" type="text" maxlength="5" matInput placeholder="ตัวย่อ"
                            matInput #input [(ngModel)]="form.code" name="code" #code="ngModel">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>ชื่อ</h4>
                    <h4>Name</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-form-field style="flex: 0 80%;" class="flex">
                        <input autocomplete="off" type="text" maxlength="100" matInput placeholder="ชื่อ" matInput
                            #input [(ngModel)]="form.name" name="name" #name="ngModel">
                    </mat-form-field>
                </div>
            </div>
            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>รายละเอียด</h4>
                    <h4>Description</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-form-field style="flex: 0 80%;" class="flex">
                        <input autocomplete="off" type="text" maxlength="150" matInput placeholder="รายละเอียด" matInput
                            #input [(ngModel)]="form.desc" name="desc" #pluralName="ngModel">
                    </mat-form-field>
                </div>
            </div>

            <ng-container>
                <div class="flex-row flex justify-between gap-2.5">
                    <div class="mat-popup-header flex flex-col">
                        <h4>Quantity None</h4>
                    </div>
                    <div class="mat-popup flex flex-row justify-between items-center">
                        <div>:</div>
                        <mat-form-field style="flex: 0 80%;" class="flex">
                            <mat-select [(ngModel)]="form.qtyNone" name="isActive" #isActive="ngModel">
                                <mat-option value="Y">Yes</mat-option>
                                <mat-option value="N">No</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </ng-container>

            <div class="flex-row flex justify-between gap-2.5">
                <div class="mat-popup-header flex flex-col">
                    <h4>ใช้งาน</h4>
                    <h4>Active</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false" (change)="onChange($event)"
                        style="flex: 0 80%;" class="flex">
                        Active</mat-slide-toggle>
                </div>
            </div>
            <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
                <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
                <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
            </div>
        </div>
    </mat-dialog-content>