<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center"> เพิ่มบริษัทตัวแทน </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">
      <form #createManualForm="ngForm">
        <div class="flex flex-col">
          <div class="flex flex-row p10">
            <div class="basis-1/4" style="align-self: center;"> ชื่อบริษัทตัวแทน:
              <label style="color:red">*</label>
            </div>
            <div class="basis-1/2 f18">
              <mat-form-field class="w100">
                <input autocomplete="off" required type="text" matInput placeholder=" ใบอนุญาตกระทรวงพาณิชย์ | พณ"
                  #input [(ngModel)]="form.docNo" [maxlength]="13" name="docNo" #docNo="ngModel">
              </mat-form-field>
            </div>
          </div>
          <div class="flex flex-row p10">
            <div class="basis-1/4" style="align-self: center;">อนุมัติ
              <label style="color:red">*</label>
            </div>
            <div class="basis-1/2 f18">
              <div class="flex pl-10">
                <mat-radio-group formControlName="isActive" style="margin-left: 20px;" required>
                  <mat-radio-button class="mr-12" [value]="'Y'">ใช้งาน</mat-radio-button>
                  <mat-radio-button class="mr-12" [value]="'N'">ยกเลิก</mat-radio-button>
                </mat-radio-group>
                <!-- <mat-slide-toggle [checked]="data.cpParamiter === 'ON' ? true : false" (change)="onChange($event)"
                style="flex: 0 95%;" class="flex">{{status}}</mat-slide-toggle> -->
              </div>
            </div>
          </div>
          <div class="flex flex-row p10">
            <div class="basis-1/4" style="align-self: center;"> วันหมดอายุ <label style="color:red">*</label>
            </div>
            <div class="basis-1/2 f18">
              <mat-form-field class="w100">
                <input required autocomplete="off" placeholder="วันหมดอายุ" matInput [matDatepicker]="exDates"
                  [(ngModel)]="form.expireDate" [min]="currentDate" [max]="form.maxExpireDate"
                  (dateChange)="setDate($event)" name="expireDate" #expireDate="ngModel" readonly>
                <mat-datepicker-toggle matSuffix [for]="exDates"></mat-datepicker-toggle>
                <mat-datepicker #exDates></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="flex flex-col items-center p10">
          <div class="basis-1/2 f18">
            <button mat-raised-button [mat-dialog-close] style="flex:0 50%; margin-right: 10px;">
              ปิด
            </button>
            <button mat-raised-button style="width: 100px;" color="accent" [disabled]="" routerLink="/page/o2/o2-manual"
              [disabled]="onValid(createManualForm)"
              [queryParams]="{ source : 'M' , type : 'New' , page : pages ,docNo : form.docNo , product : form.typeProduct , expireDate : form.expireDate ,issueDate : form.issueDate }"
              [mat-dialog-close]="form">
              บันทึก
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>