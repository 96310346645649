<mat-card style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
  <!--**********Element Data**********-->
  <div class="mat-elevation-z8 w100 center">
     <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          รายงาย มส.24
        </mat-card-header>
        <mat-card-content class="example-headers-align center" style="width:600px;">
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> รายงานการสรุป : </div>
                <div class="basis-4/5">
                <mat-form-field class="my-form-field formm w100">
                  <mat-label></mat-label>
                  <mat-select  [(ngModel)]="form.optionSelect">
                    <mat-option *ngFor="let Dropdown of SelectDataNameDocumentMs24" [value]="Dropdown.id">
                      {{Dropdown.value}}
                    </mat-option>
                  </mat-select>
                  <mat-error>กรุณาระบุ ชื่อกิจกรรม</mat-error>
                </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div  *ngIf="form.optionSelect !=4">
            <form  name ="FormDoc123" [formGroup]="FormDoc123"> 
              <div class="flex">
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> วันที่เริ่มต้น : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100" appearance="fill">
                        <input matInput [matDatepicker]="picker1" [(ngModel)]="form.selectedStartDate" formControlName ="startDate">
                        <mat-hint>YYYY/MM/DD</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 dateFormat="YYYY/MM/DD"></mat-datepicker>
                      </mat-form-field>
                      <!-- <div *ngIf="FormDoc123.controls.startDate.errors?.required">This field is required</div> -->
                      <div *ngIf="FormDoc123.controls['startDate'].invalid && (FormDoc123.controls['startDate'].dirty || FormDoc123.controls['startDate'].touched)" class="p-1 text-sm text-red-800" role="alert">
                        <div *ngIf="FormDoc123.controls['startDate'].errors.required">
                          **Start Date is required.
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>

              <div class="flex">
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> วันสุดท้าย : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100" appearance="fill">
                        <input matInput [matDatepicker]="picker2" [(ngModel)]="form.selectedEndDate" formControlName ="endDate">
                        <mat-hint>YYYY/MM/DD</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 dateFormat="YYYY/MM/DD"></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="FormDoc123.controls['endDate'].invalid && (FormDoc123.controls['endDate'].dirty || FormDoc123.controls['endDate'].touched)" class="p-1 text-sm text-red-800" role="alert">
                        <div *ngIf="FormDoc123.controls['endDate'].errors.required">
                          **End Date is required.
                        </div>
                      </div>
                      <div *ngIf="FormDoc123.touched && FormDoc123.invalid" class="p-1 text-sm text-red-800" role="alert">                    
                        **Please add a valid from and to date.
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </form>
              <div class="flex">
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ประเภทสินค้า : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100">
                        <mat-select [(ngModel)]="form.typeproduct" required="true">
                          <mat-option value="ALL">แสดงทั้งหมด</mat-option>
                          <mat-option *ngFor="let product of dataProduct" [value] = "product.productId">
                          {{product.productName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div> 
              </div>
              <div class="pt-4 flex flex-col items-center p10">
                <div class="basis-1/2 f18">
                  <button mat-raised-button style="width: 100px;" color="accent" (click)="Loadexcel()" 
                  [disabled]="FormDoc123.pristine || FormDoc123.invalid" class="btn btn-success"
                  mat-button>
                    Export Excell
                  </button>
                  &nbsp;&nbsp;
                  <button mat-raised-button style="width: 100px;" color="accent"  mat-button>
                    Export PDF
                  </button>
                </div>
          
              </div>
          </div>
          <div  *ngIf="form.optionSelect ==4">
            <div class="flex">

              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> วันที่อนุมัติใบรับรอง : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="my-form-field formm w100" appearance="fill">
                      <input matInput [matDatepicker]="picker3" [(ngModel)]="form.selectedStartDateApproved">
                      <mat-hint>YYYY/MM/DD</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                      <mat-datepicker #picker3 dateFormat="YYYY/MM/DD"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div> 
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ถึง : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="my-form-field formm w100" appearance="fill">
                      <input matInput [matDatepicker]="picker4" [(ngModel)]="form.selectedEndDateApproved"
                      [min] = "form.selectedStartDateApproved">
                      <mat-hint>YYYY/MM/DD</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
                      <mat-datepicker #picker4 dateFormat="YYYY/MM/DD"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div> 
            </div>

            <div class="flex">

              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> วันที่ยื่นคำขอใบรับรอง : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="my-form-field formm w100" appearance="fill">
                      <input matInput [matDatepicker]="RequestStart" [(ngModel)]="form.selectedStartDateRequest">
                      <mat-hint>YYYY/MM/DD</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="RequestStart"></mat-datepicker-toggle>
                      <mat-datepicker #RequestStart dateFormat="YYYY/MM/DD"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div> 
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ถึง : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="my-form-field formm w100" appearance="fill">
                      <input matInput [matDatepicker]="RequestEnd" [(ngModel)]="form.selectedEndDateRequest"
                      [min]="form.selectedStartDateRequest">
                      <mat-hint>YYYY/MM/DD</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="RequestEnd"></mat-datepicker-toggle>
                      <mat-datepicker #RequestEnd dateFormat="YYYY/MM/DD"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div> 
            </div>

            <div class="flex">

              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> วันที่ชำระเงินใบรับรอง : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="my-form-field formm w100" appearance="fill">
                      <input matInput [matDatepicker]="BillStart" [(ngModel)]="form.selectedStartDateBill">
                      <mat-hint>YYYY/MM/DD</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="BillStart"></mat-datepicker-toggle>
                      <mat-datepicker #BillStart dateFormat="YYYY/MM/DD"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div> 
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ถึง : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="my-form-field formm w100" appearance="fill">
                      <input matInput [matDatepicker]="BillEnd" [(ngModel)]="form.selectedEndDateBill"
                      [min] = "form.selectedStartDateBill" >
                      <mat-hint>YYYY/MM/DD</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="BillEnd"></mat-datepicker-toggle>
                      <mat-datepicker #BillEnd dateFormat="YYYY/MM/DD"></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div> 
            </div>

            <div class ="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> เลขที่ใบรับรองเอกสาร : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput  #input
                        [(ngModel)]="form.documentCert" name="documentCert" #documentCert="ngModel">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-4 flex flex-col items-center p10">
              <div class="basis-1/2 f18">
                <button mat-raised-button style="width: 100px;" color="accent" (click)="Loadexcel()"        
                mat-button>
                  Export Excell
                </button>
                &nbsp;&nbsp;
                <button mat-raised-button style="width: 100px;" color="accent"  mat-button>
                  Export PDF
                </button>
              </div>
        
            </div>
          </div>
          
        </mat-card-content>
      </mat-card>
    </div>
   </div>
</mat-card>