
import { ApiMs24Service } from "../api/api-ms24.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AppMs24Service {
  ms24Lists: BehaviorSubject<any[]>;
  mS24Attachment: BehaviorSubject<any[]>;
  constructor(private apiMs24Service: ApiMs24Service) {
    this.ms24Lists = new BehaviorSubject<any[]>(new Array());
    this.mS24Attachment = new BehaviorSubject<any[]>(new Array());
  }

  getrenewMS24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getrenewMS24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  renewMS24sent(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.renewMS24sent(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getMs24StaffList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getMs24StaffList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getMs24StaffDetailList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getMs24StaffDetailList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  MS24LogStaff(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.MS24LogStaff(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  MS24ExportDocumentStaffSummaryPrint(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.MS24ExportDocumentStaffSummaryPrint(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  MS24ExportDocumentStaffSummaryApproved(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.MS24ExportDocumentStaffSummaryApproved(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  MS24ExportDocumentStaffRequest(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.MS24ExportDocumentStaffRequest(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  MS24ExportDocumentStaffProductStandard(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.MS24ExportDocumentStaffProductStandard(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  MS24RecordStaffDocument(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.MS24RecordStaffDocument(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getshortMS24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getshortMS24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  shortMS24sent(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.shortMS24sent(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getMS24fromMS13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getMS24fromMS13(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMs24List(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.MS24list(dataReq).subscribe((res) => {
        this.ms24Lists.next(res);
        resolve(res);
      });
    });
  }

  getMs24Short(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getMs24Short(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateMs24ShortStatus(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.updateMs24ShortStatus(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getDataMS24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getDataMS24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  updateDataMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.updateDataMs24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  saveDataMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.saveDataMs24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getCurrentMs24ShortData(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getCurrentMs24ShortData(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveMs24Short(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.saveMs24Short(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getListPrint(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getListPrint(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reprint(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.reprint(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMs24ShortRequestLists(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getMs24ShortRequestLists(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveMs24ShortApproveStatus(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.saveMs24ShortApproveStatus(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveMs24ShortActiveStatus(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.saveMs24ShortActiveStatus(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  
  printCertificateMS24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.printCertificateMS24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  printCopyCertificateMS24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.printCopyCertificateMS24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getRenewMs24List(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getRenewMs24List(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateStatusRenewMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.updateStatusRenewMs24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateMs24ApproveStatus(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.updateMs24ApproveStatus(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateMs24PrintUser(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.updateMs24PrintUser(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMs24DocumentActionHistoryList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getMs24DocumentActionHistoryList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMs24PerDocumentActionHistoryList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.getMs24PerDocumentActionHistoryList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateMs24GoodLoadDate(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.updateMs24GoodLoadDate(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateMs24Active(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs24Service.updateMs24Active(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
}  
