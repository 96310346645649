<mat-card style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <!--**********Tab Menubar**********-->
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">
          
          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field" >
            <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
              [(ngModel)]="searchForm.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!--**********Button search**********-->
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <!--**********Menu for button search**********-->
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">              
              <div class="flex flex-row">
                <div class="basis-full">
                  <div class="flex">
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>เลขที่ใบอนุญาต อ.2</mat-label>
                      <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบอนุญาต อ.2" #input
                        [(ngModel)]="searchForm.o2DocNo" name="o2DocNo" #o2DocNo="ngModel">
                    </mat-form-field>
  
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>ผู้ตรวจ</mat-label>
                      <input autocomplete="off" type="text" matInput placeholder="ผู้ตรวจ" #input
                        [(ngModel)]="searchForm.botReviewByUsername" name="botReviewByUsername" #botReviewByUsername="ngModel">
                    </mat-form-field>
                  </div>
                  <div class="flex">
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>ผู้ส่งออก</mat-label>
                      <input autocomplete="off" type="text" matInput placeholder="ผู้ส่งออก" #input
                        [(ngModel)]="searchForm.companyName" name="companyName" #companyName="ngModel">
                    </mat-form-field>
  
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>เรือใหญ่ที่จะบรรทุก</mat-label>
                      <input autocomplete="off" type="text" matInput placeholder="เรือใหญ่ที่จะบรรทุก" #input
                        [(ngModel)]="searchForm.vesselName" name="vesselName" #vesselName="ngModel">
                    </mat-form-field>
                  </div>

                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>คำร้องขอเลขที่</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำร้องขอเลขที่" #input
                      [(ngModel)]="searchForm.ms13DocNo" name="ms13DocNo" #ms13DocNo="ngModel">
                  </mat-form-field>

                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ใบรับร้องเลขที่</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="ใบรับร้องเลขที่" #input
                      [(ngModel)]="searchForm.ms24RefNo" name="ms24RefNo" #ms24RefNo="ngModel">
                  </mat-form-field>

                  <div class="flex">
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>ประเภทสินค้า</mat-label>
                      <input autocomplete="off" type="text" matInput placeholder="ประเภทสินค้า" #input
                        [(ngModel)]="searchForm.productType" name="productType" #productType="ngModel">
                    </mat-form-field>
  
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>สถานะ</mat-label>
                      <input autocomplete="off" type="text" matInput placeholder="สถานะ" #input
                        [(ngModel)]="searchForm.status" name="status" #status="ngModel">
                    </mat-form-field>
                  </div>

                </div>
              </div>    
            </div>
              <div class="flex justify-end formm">
                <div>
                  <button type = "submit" mat-raised-button color="accent"  style="flex:0 50%;" (click)="loadData()">
                    ค้นหา
                  </button>
                </div>
              </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <!--**********Table ietmtype Data**********-->
  <div class="mat-elevation-z8">
   <table mat-table  [dataSource]="dataSource" matSort > 
   
      <!--**********1.o2DocNoResponse**********-->
      <ng-container matColumnDef="o2DocNoResponse">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;" sortActionDescription="Sort by name" >ใบอนุญาตเลขที่</th>
        <td mat-cell *matCellDef="let element"> {{element.o2DocNoResponse}} </td>
      </ng-container>
      <!--**********2.CompanyName**********-->
      <ng-container matColumnDef="companyNameResponse">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          ผู้ส่งออก</th>
        <td mat-cell *matCellDef="let element"> {{element.companyNameResponse}} </td>
      </ng-container>
      <!--**********3.producttypeResponse**********-->
      <ng-container matColumnDef="producttypeResponse">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          ประเภทสินค้า</th>
        <td mat-cell *matCellDef="let element"> {{element.producttypeResponse}} </td>
      </ng-container>
       <!--**********4.vesselNameResponse**********-->
       <ng-container matColumnDef="vesselNameResponse">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          เรือใหญ่ที่จะบรรทุก</th>
        <td mat-cell *matCellDef="let element"> {{element.vesselNameResponse}} </td>
      </ng-container>
       <!--**********5.DocNoResponse**********-->
       <ng-container matColumnDef="ms13DocNoResponse">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          คำร้องขอเลขที่</th>
        <td mat-cell *matCellDef="let element"> {{element.ms13DocNoResponse}} </td>
      </ng-container>
       <!--**********6.RefNoResponse**********-->
       <ng-container matColumnDef="ms24RefNoResponse">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          ใบรับรองเลขที่</th>
        <td mat-cell *matCellDef="let element"> {{element.ms24RefNoResponse}} </td>
      </ng-container>
      <!--**********7.Status**********-->
      <ng-container matColumnDef="ms24StatusResponse">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          สถานะ</th>
        <td mat-cell *matCellDef="let element"> {{element.ms24StatusResponse}} </td>
      </ng-container>
      <!--**********8.BotReview**********-->
      <ng-container matColumnDef="botReviewByUsernameResponse">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          ผู้ตรวจ</th>
        <td mat-cell *matCellDef="let element"> {{element.botReviewByUsernameResponse}} </td>
      </ng-container>
      <!--**********9.Action button**********-->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          <button mat-icon-button matTooltip="รายละเอียด" [routerLink]="['/page/ms24/ms24-record-staff-detail']"
          [queryParams]="{ id: element.ms24HeaderIdResponse}">
            <mat-icon>description</mat-icon>
          </button>   
          <button mat-icon-button matTooltip="พิมพ์เอกสาร" (click)="exportMs24StaffPDF(element)">
            <mat-icon>print</mat-icon>
          </button> 
          <button mat-icon-button matTooltip="Log" (click)="showLogMs24Staff(element.ms24HeaderIdResponse)">
            <mat-icon>content_paste_search</mat-icon>
          </button> 
          <button mat-icon-button matTooltip="ยอมรับ" (click)="Adjustms24StatusType(element,'Reject')">
            <mat-icon>check_circle</mat-icon>
          </button> 
          <button mat-icon-button matTooltip="ยอมรับ" (click)="Adjustms24StatusType(element,'Complete')">
            <mat-icon>check_circle_outline</mat-icon>
          </button> 
          <button mat-icon-button matTooltip="" (click)="Adjustms24StatusType(element,'Reject')">
            <mat-icon>lock_open</mat-icon>
          </button> 
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
   <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</mat-card>