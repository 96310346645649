import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { environment } from "environments/environment";

@Component({
  selector: "app-Image-modal",
  templateUrl: "./Image-modal.component.html",
  styleUrls: ["./Image-modal.component.css"],
})
export class ImageModalComponent implements OnInit { 
  constructor(
    public dialogRef: MatDialogRef<ImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {}
}
