import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApiMenuService } from "../api/api-menu.service";
import { IGetButtonPermissionResponse, IGetMenuAllResponse, IGetMenuButtomAllResponse, IMenuDataModel, IMenuNavigation, IMenuResponse } from "app/business/interface/api/api-menu.interface";
import { AuthService } from "app/core/auth/auth.service";

@Injectable({
  providedIn: "root",
})
export class AppMenuService {
  actionMenuPermission: BehaviorSubject<any>;
  actionButtonPermission: BehaviorSubject<any>;
  actionMenuTo: BehaviorSubject<any>;
  actionButtonTo: BehaviorSubject<any>;

  companyData: BehaviorSubject<any[]>;
  countryData: BehaviorSubject<any[]>;

  userMenuList: BehaviorSubject<IMenuDataModel[]>;
  userRoleMenuList: BehaviorSubject<IMenuDataModel[]>;
  userGroupMenuList: BehaviorSubject<IMenuDataModel[]>;
  
  menuList: BehaviorSubject<IMenuResponse[]>;
  menuPermissionList: BehaviorSubject<IMenuResponse[]>;
  buttonPermissionList: BehaviorSubject<IGetButtonPermissionResponse[]>;
  navigation: BehaviorSubject<IMenuNavigation[]>;

  buttonList: BehaviorSubject<IMenuResponse[]>;
  userButtonList: BehaviorSubject<IMenuDataModel[]>;
  userRoleButtonList: BehaviorSubject<IMenuDataModel[]>;
  userGroupButtonList: BehaviorSubject<IMenuDataModel[]>;
  userProfile: BehaviorSubject<any>;

  constructor(
    private apiMenuService: ApiMenuService,
    private httpClient: HttpClient,
    private _authService:AuthService
  ) {
    this.actionMenuPermission = new BehaviorSubject(new Array<any>());
    this.actionButtonPermission = new BehaviorSubject(new Array<any>());
    this.actionButtonTo = new BehaviorSubject(null);
    this.companyData = new BehaviorSubject(new Array<any>());
    this.countryData = new BehaviorSubject<any[]>(new Array());
    this.buttonPermissionList = new BehaviorSubject(
      new Array<IGetButtonPermissionResponse>()
    );
    this.menuPermissionList = new BehaviorSubject(new Array<IMenuResponse>());
    this.buttonList = new BehaviorSubject(new Array<IMenuResponse>());
    this.menuList = new BehaviorSubject(new Array<IMenuResponse>());
    this.userButtonList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.userRoleButtonList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.userGroupButtonList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.userProfile = new BehaviorSubject(null);
    this.userMenuList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.userRoleMenuList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.userGroupMenuList = new BehaviorSubject(new Array<IMenuDataModel>());
    this.actionMenuTo = new BehaviorSubject(null);
  }

  getMenuPermission(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMenuService.getMenuPermission().subscribe((res) => {
        this.menuPermissionList.next(res);
         ;
        // this.navigation.next(this.setNavigation(res));
        var loginUser = JSON.parse(this._authService.loginUser);
        var userProfile = loginUser //JSON.parse(this._authService.userProfile);
        // ;
        this.userProfile.next(userProfile);
        // var isDisable = loginUser.isDisable;
        // this.isMenuDisable.next(isDisable);
        resolve(res);
      });
    });
  }

  setNavigation(datas: IMenuResponse[]): IMenuNavigation[] {
    const nav = datas.map((e) => {
      if (e.children.length > 0) {
        return {
          id: e.menuCode,
          title: e.name,
          icon: e.icon,
          type: "collapsable",
          children: this.setNavigation(e.children),
          jsonDisPlayConfig: e.jsonDisPlayConfig
        } as IMenuNavigation;
      } else {
        return {
          id: e.menuCode,
          title: e.name,
          icon: e.icon,
          type: "item",
          url: e.url,
          jsonDisPlayConfig: e.jsonDisPlayConfig
        } as IMenuNavigation;
      }
    });

    return nav;
  }

  getButtonPermissionPerPages(
    dataReq: any
  ): Promise<IGetButtonPermissionResponse[]> {
    return new Promise((resolve, reject) => {
      this.apiMenuService
        .getButtonPermissionPerPages(dataReq)
        .subscribe((res) => {
          // set data result
          this.buttonPermissionList.next(res);
          resolve(res);
        });
    });
  }
  getButtonAll(dataReq: any): Promise<IGetMenuButtomAllResponse> {
    return new Promise((resolve, reject) => {
      this.apiMenuService.getButtonAll(dataReq).subscribe((res) => {
        // set data result
        this.buttonList.next(res.menuList);
        
        this.userButtonList.next(res.userMenuList);
        this.userRoleButtonList.next(res.userRoleMenuList);
        this.userGroupButtonList.next(res.userGroupMenuList);
        resolve(res);
      });
    });
  }
  savePermissionMenuOrButton(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMenuService
        .savePermissionMenuOrButton(dataReq)
        .subscribe((res) => {
          // set data result
          resolve(res);
        });
    });
  }
  getMenuAll(dataReq: any): Promise<IGetMenuAllResponse> {
    return new Promise((resolve, reject) => {
      this.apiMenuService.getMenuAll(dataReq).subscribe((res) => {
        // set data result
        this.menuList.next(res.menuList);
        // this.userProfile.next(res.userProfile);
        this.userMenuList.next(res.userMenuList);
        this.userRoleMenuList.next(res.userRoleMenuList);
        this.userGroupMenuList.next(res.userGroupMenuList);
        resolve(res);
      });
    });
  }
}
