import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { FormHelperService } from '@bundle/service/form-helper.service';
import { IApiSaveUserGroupRequest } from 'app/business/interface/api/api-user.interface';
import { AppUserService } from 'app/business/service/app/app-user.service';

@Component({
  selector: 'app-user-group-modal',
  templateUrl: './user-group-modal.component.html',
  styleUrls: ['./user-group-modal.component.css']
})
export class UserGroupModalComponent implements OnInit {

  dataUserGroup: any;
  dataForm: any;
  constructor(
    private appUserService: AppUserService,
    private alertService: AlertMessageService,
    private formHelper: FormHelperService,
    private dialogRef: MatDialogRef<UserGroupModalComponent>,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {

    this.dataUserGroup = data;
  }

  ngOnInit(): void {
  }
  userGroupFormSubmit(): void {

    if (this.userGroupFormValidate()) {
      const data = {
        userGroupId: this.dataUserGroup.userGroupId,
        userGroupCode: this.dataUserGroup.userGroupCode,
        groupName: this.dataForm.value.groupName,
        isActive: this.dataForm.value.isActive,
      } as IApiSaveUserGroupRequest;

      this.appUserService.saveUserGroup(data).then(res => {
        if (res !== 'error') {
          this.alertService.info({ title: 'การบันทึก', message: 'บันทึกข้อมูลเรียบร้อยแล้ว' });
          this.dialogRef.close(data);
        }
      });
    }

  }

  userGroupFormEmit(event: any): void {
    this.dataForm = event;


  }
  userGroupFormValidate(): boolean {
    const isValid = this.dataForm.valid;
    this.formHelper.validateForm(this.dataForm);
    return isValid;
  }
}
