<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">รายชื่อผู้ประกอบธุรกิจตรวจสอบมาตรฐานสินค้า </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="flex flex-col mat-typography" style="gap: 15px;">
    <div class="flex flex-col">
      <div class="flex flex-row">
        <!-- <div class="basis-3/12 p10" style="align-self: center;">ตราประทับ </div> -->
        <div class="w100 p10">
          <mat-form-field [formGroup]="surveyorForm" class="my-form-field formm w100">
            <input type="text" matInput formControlName="companySurveyorSearch" [matAutocomplete]="autosx"
              placeholder="ผู้ประกอบธุรกิจตรวจสอบมาตรฐานสินค้า">
            <mat-autocomplete #autosx="matAutocomplete" (optionSelected)="selectionCompanySurveyorChange($event)">
              <mat-option *ngFor="let shipp of filteredOptionsCompanySurveyor$" [value]="shipp">
                {{shipp.companyNameTH}}
              </mat-option>
            </mat-autocomplete>
            <button mat-icon-button matTooltip="ลบ" (click)="deleteItem()" style="width: 48px" *ngIf="this.form.inspectBy !== undefined && this.form.inspectBy !== null">
              <i class="ti ti-trash"></i>
            </button>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex justify-center w100" style="padding: 0 20px 20px 20px;">
      <button mat-raised-button style="width: 100px;" color="accent" [mat-dialog-close]="form">
        ตกลง
      </button>
    </div>
  </mat-dialog-actions>
</mat-card>