<mat-card class="example-card">
  <mat-card-header class="fontTitleHeader">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal"> เปลี่ยนสถานะเอกสาร อ.2 </div>
      <div>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content style="padding: 20px;">
    <form #FormValidateItem="ngForm">
      <div class="f18" style="padding-bottom: 10px;">
        กรุณาระบุเหตุผลที่ไม่อนุมัติเอกสาร อ.2
      </div>
      <mat-form-field class="w100">
        <textarea matInput placeholder="เหตุผลทีไม่อนุมัติเอกสาร" [(ngModel)]="remark" name="remark"
          #fileName="ngModel"></textarea>
      </mat-form-field>

    </form>
  </mat-card-content>
  <div class="flex flex-col items-center" style="padding-bottom: 20px">
    <div class="basis-1/2 f18 flex" style="gap: 8px">
      <button mat-raised-button color="accent" (click)="saveReason()" [disabled]="!FormValidateItem.valid">
        ยืนยัน
      </button>
    </div>
  </div>
</mat-card>