import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMs13Service } from "app/business/service/app/app-ms13.service";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { AppO2Service } from "app/business/service/app/app-o2.service";

@Component({
  selector: "amount-of-weight-touse-ms24-modal",
  templateUrl: "./amount-of-weight-touse-ms24-modal.component.html",
  styleUrls: ["./amount-of-weight-touse-ms24-modal.component.css"],
})
export class AmountOfWeightTouseMs24ModalComponent implements OnInit {
  ms13Id: any;
  ms24Id: any;
  dataMs13Source: any = {};
  formMs13: any = {};
  dataMs13List: any = [];
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  productList: any = [];
  @ViewChild("FormValidateItem") FormValidateItem: NgForm;
  constructor(
    public dialogRef: MatDialogRef<AmountOfWeightTouseMs24ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

    private appMs13Service: AppMs13Service,
    private appO2Service: AppO2Service,
    private appMasterService: AppMasterService,
    private appMs24Service: AppMs24Service,
    private alertMessageService: AlertMessageService,
    private router: Router
  ) {

  }
  async ngOnInit() {
    console.log("==>>>>", this.data);
    this.getdataMs13ById();
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: Number(this.data.formData.typeGoods),
      isActive: "Y",
    };
    this.appMasterService.getProduct(formProduct).then((res) => {
      console.log("productList >> ", res);
      this.productList = res;
    });
  }

  // async createMs24()
  // {

  // }

  createMs24() {
    // ;
    // -สถานะเปลี่ยนเป็น Save to Database
    // ดึงข้อมูล ms13 มาเพื่อบันทึก
    const formDataMs13 = {
      ID: this.data.formData.id,
      companyTax: "TH",
    };
    this.formMs13 = this.data.formData;
    console.log('formMs13 >>', this.formMs13);
    var save = true;
    this.formMs13.status = Number(this.formMs13.status);
    this.userProfile.userTypeId = Number(this.userProfile.userTypeId);
    // 
    const formDataMS24 = {
      cmms24IssueDate: this.formMs13.refDate, //2024-04-04 00:00:00.000",
      cmms24ExpireDate: this.formMs13.o2ExpireDate, //2024-04-13 00:00:00.000",
      cmdtDocumentTypeId: this.formMs13.typeDocument, //1,
      cmdtDocumentType: this.formMs13.goodsName, //-",
      cmms24RefNo: this.formMs13.refNo, //00001/2567/1",
      cmms24RefDate: this.formMs13.refDate, //2024-04-04 10:34:10.000",
      cmsStatusId: 1, //13,
      cmcCompanyId: this.userProfile.cpId, //118,
      cmms24Exporter: this.formMs13.exporter, //2021",
      cmcTaxId: this.formMs13.companyTax, //0115542000168",
      cmcNameTh: this.formMs13.companyThName, //บริษัท เอเซีย โกลเด้น ไรซ์ จำกัด",
      cmcNameEn: this.formMs13.companyEnName, //ASIA GOLDEN RICE CO., LTD.",
      cmcAddressStreet: this.formMs13.exporterStreetandNo, //54-55 หมู่ 8 ถนนฉะเชิงเทรา-บางปะกง",
      cmcDistrict: this.formMs13.exporterDistrict, //ตำบลท่าสะอ้าน",
      cmcSubProvince: this.formMs13.exporterSubProvince, //อำเภอบางปะกง",
      cmcProvince: this.formMs13.exporterProvince, //ฉะเชิงเทรา",
      cmcPostCode: this.formMs13.exporterPostcode, //24130",
      cmsmShippingId: this.formMs13.shippingId, //0,
      cmms24ShippingNameTh: this.formMs13.shippingName, //บริษัท ไทย แกรนลักซ์ อินเตอร์เนช่นแนล ไรซ์ จำกัด",
      cmms24ShippingstreetNo: this.formMs13.shippingStreetNo, //54/1 หมู่ 8 ถนนฉะเชิงเทรา-บางปะกง",
      cmms24ShippingDistrict: this.formMs13.shippingDistrict, //ตำบลท่าสะอ้าน",
      cmms24ShippingSubProvince: this.formMs13.shippingSubProvince, //อำเภอบางปะกง",
      cmms24ShippingProvince: this.formMs13.shippingProvince, //ฉะเชิงเทรา",
      cmms24ShippingPostCode: this.formMs13.shippingPostcode, //24130",
      cmms24RecieveNameTh: this.formMs13.recieveName, //",
      cmms24RecieveNameEn: this.formMs13.recieveEnName, //LEE&#039;S FU INC.",
      cmms24RecieveStreetNo: this.formMs13.recieveStreetNo, //",
      cmms24RecieveDistrict: this.formMs13.recieveDistrict, //",
      cmms24RecieveSubProvince: this.formMs13.recieveSubProvince, //",
      cmms24RecieveProvince: this.formMs13.recieveProvince, //",
      cmms24RecievePostCode: this.formMs13.recievePostcode, //",
      cmms24RecieveCountryCode: this.formMs13.recieveCountryCode, //US",
      cmms24BuyerCountryCode: this.formMs13.recieveCountryCode, //US",
      cmms24DestinationCountryCode: this.formMs13.destinationCountryCode, //US",
      cmcuCurrencyCode: this.formMs13.currency, //USD",
      cmtmId: this.formMs13.transportationMode, //4,
      cmpoPortId: this.formMs13.port, //0,
      cmpoName: this.formMs13.porttxt, //ท่ากรุงเทพฯ / แหลมฉบัง",
      cmms24VesselName: this.formMs13.vesselName, //เรือใหญ่",
      cmms24VesselNameAccual: this.formMs13.vesselName, //เรือใหญ่",
      cmms24ExchangeRate: this.formMs13.exchangeRate, //33.976600,
      cmsmShippingMarkNo: this.formMs13.shippingMark, //@",
      cmms24GoodsCode: this.formMs13.goodsCode, //1,
      cmms24GoodsName: this.formMs13.goodsName, //ข้าวหอมมะลิไทย",
      cmms24TypeClass: this.formMs13.typeClass, //0.1: ข้าวขาว 100%",
      cmms24ProofPurchase: this.formMs13.proofpurchase, //INVOICE NO.ASIA430/2023-AA",
      cmms24LoadedPort: this.formMs13.lodedPor, //",
      cmms24LoadedPortName: this.formMs13.loadePortName, //LOS ANGELES",
      cmms24LoadedPortCountryCode: this.formMs13.loadedPortCountryCode, //UNITED STATES OF AMERICA",
      cmms24GoodsLoadDateStart: this.formMs13.loadedDateStart, //2024-04-04 00:00:00.000",
      cmms24GoodsLoadDateEnd: this.formMs13.loadedDateEnd, //null,
      cmms24SurveyLocationNameTh: this.formMs13.inspectName, //โกดัง บริษัท เอเซียโกลเด้น ไรซ์ จำกัด",
      cmms24SurveyLocationStreetNo: this.formMs13.inspectStreetNo, //54-55 หมู่ 8 ถนนฉะเชิงเทรา-บางปะกง",
      cmms24SurveyLocationDistrict: this.formMs13.inspectDistrict, //ตำบลท่าสะอ้าน",
      cmms24SurveyLocationSubProvince: this.formMs13.inspectSubprovince, //อำเภอบางปะกง",
      cmms24SurveyLocationProvince: this.formMs13.inspectProvince, //จ.ฉะเชิงเทรา",
      cmms24SurveyLocationPostCode: this.formMs13.inspectPostcode, //24130",
      cmms24SurveyTimeStart: null,
      cmms24SurveyTimeEnd: null,
      cmms24SurveyLocationRemark: this.formMs13.botRemark, //",
      cmms24PetitionNo: "",
      cmms24PetitionDate: null,
      cmms24SurveyCheif: this.formMs13.surveyCheif, //นายสว่าง ทำนาบุญ",
      cmms24SurveyCheifNo: null,
      cmms24SurveyBy1: null,
      cmms24SurveyByNo1: null,
      cmms24SurveyBy2: this.formMs13.surveyBy2, //นายมนตรี มั่นยืน",
      cmms24SurveyByNo2: null,
      cmms24SurveyBy3: this.formMs13.surveyBy3, //",
      cmms24SurveyByNo3: null,
      cmms24SurveyBy4: this.formMs13.surveyby4, //",
      cmms24SurveyByNo4: null,
      cmms24SurveyBy5: this.formMs13.surveyBy5, //",
      cmms24SurveyByNo5: null,
      cmms24SurveyBy_6: this.formMs13.surveyBy6, //",
      cmms24SurveyByNo_6: null,
      cmms24SurveyOrder: this.formMs13.surveyOrder1, //มยุรี ทุมขุนทด",
      cmms24SurveyOrderNo: null,
      cmms24SurveyOrderDate: this.formMs13.surveyOrderDate1, //2024-03-09 14:30:00.000",
      cmms24SurveyAnalyze: this.formMs13.surveyAnalyze, //นางสาวศศิวรรณ รัตนกังวานวงศ์ ",
      cmms24SurveyAnalyzeNo: null,
      cmms24SurveyOrderAnalyze: this.formMs13.surveyOrder2, //มยุรี ทุมขุนทด",
      cmms24SurveyOrderAnalyzeNo: null,
      cmms24SurveyOrderAnalyzeDate: this.formMs13.surveyOrderDate2, //2024-03-09 14:40:00.000",
      cmms24SurveyAcceptUser: this.userProfile.uId, //382,
      cmms24ShpMaskPic: this.formMs13.shpMaskpic, //./shpmask/118/B1.gif",
      cmms24Comment1: this.formMs13.comment1, //",
      cmo2hId: this.formMs13.o2Hid, //74077,
      cmo2hDocumentNo: this.formMs13.o2DocNo, //0308116641568",
      cmo2hIssueDate: this.formMs13.o2IssueDate, //2024-04-09 00:00:00.000",
      cmo2hExpireDate: this.formMs13.o2ExpireDate, //2024-04-08 00:00:00.000",
      cmms13hId: this.formMs13.id, //105417,
      cmms13hRefNo: this.formMs13.refNo, //00971/2566",
      cmms24SurveyReportDate: this.formMs13.surveyAcceptDate,
      cmms24IsActive: "Y",
      cmedExporterNo: this.formMs13.registrationNoPk, //2024",
      cmms24CheckResult: "",// this.formMs13.mstMs13SurResultRespons.checkResult, //มาตรฐานเทียบได้ เท่ากับ มาตรฐานที่ทางราชการกำหนด",
      cmms24CheckQuantityResult: "",//this.formMs13.mstMs13SurResultRespons.checkQty, //กระสอบที่บรรจุถูกต้อง",
      cmms24CheckPackageResult: "",//this.formMs13.mstMs13SurResultRespons.checkPackageResult, //การเย็บปากกระสอบ เรียบร้อย",
      cmms24SurveyRemark: null,// this.formMs13.mstMs13SurResultRespons.surveyRemark, //null,
      cmms24SurveyAccualDate: this.formMs13.surveyAcceptDate,//this.formMs13.mstMs13SurResultRespons.checkDate, //null,
      cmms24SurveyorId: this.formMs13.inspectbyId,//this.formMs13.mstMs13SurResultRespons.rid, //256,
      cmms24Name: this.formMs13.companyThName, //บริษัท เอสจีเอส (ประเทศไทย) จำกัด",
      cmms24CreateByOld: this.formMs13.createby, //494,
      cmms24CreateDateOld: this.formMs13.refDate, //2024-04-04 10:32:55.000",
      cmms24Ms13Condition: null, //null,
      cmms24Source: this.formMs13.source, //M",
      cmms24SurveyAcceptDate: this.formMs13.surveyorAcceptDate, //null,
      cmms24Lock: this.formMs13.lock, //null,
      cmms24Comment2: this.formMs13.comment2, //null,
      cmms24BotRemark: this.formMs13.botRemark, //null,
      cmms24FullRefNo: this.productList.find((f) => f.docTypeId == this.formMs13.typeDocument).prefix,
      dataMs24Detail: []
    }
    console.log("selectionProduct==>> ", this.data.selectionProduct);
    const dataMs24Detail = this.data.selectionProduct.map((e, n) => {
      var itemWeight = this.dataMs13List.find(el => el.itemId === e.itemId);

      if (itemWeight != undefined && itemWeight != null) {
        var surveyDetail;
        if (e?.surveyDetail.length > 0) {
          surveyDetail = e?.surveyDetail[0];
        }

        //recalculate fob if usedNet/quantityUsed > 0

        var exchangeRate = this.formMs13.exchangeRate;
        var fobValueBaht = 0;
        var fobValueUsd = 0;


        if (itemWeight.quantityUsed > 0 && itemWeight.usedNet > 0) {
          fobValueBaht = Number((itemWeight.amountUsedMs13 * e.unitPrice * exchangeRate) / 1000);
          fobValueUsd = Number((itemWeight.amountUsedMs13 * e.unitPrice) / 1000);

          fobValueBaht = this.roundTo(fobValueBaht, 6);
          fobValueUsd = this.roundTo(fobValueUsd, 6);
        } else {
          fobValueBaht = e.fobValueBaht;
          fobValueUsd = e.fobValueUsd
        }

        return {
          cmms13dId: e.itemId,
          cmms24dInvoiceNo: e.invoiceNo, //ASIA430/2023-AA",
          cmms24dInvoiceDate: e.invoiceDate, //2024-04-08 00:00:00.000",
          cmms24dInvoiceItem: e.invoiceItem, //1,
          cmms24dItemNo: e.itemNo, //1",
          cmms24dGoodsDescription: e.goodsDescription, //ต้นข้าว ข้าวขาวหอมมะลิไทย 100 % บรรจุกระสอบพีพีใสกราเวียร์ 2 หน้า หนักกระสอบละ 22.700 กก. จำนวน 2,200 กระสอบ",
          cmtTariffClass: e.tariffCode,// this.formMs13.mstMs13SurDetailRespons.tariffCode, //10063040001",
          cmtTariffStat: e.tariffSequence,//this.formMs13.mstMs13SurDetailRespons.sm13SdStatisticalCode, //000",
          cmms24dTariffSequence: e.tariffSequence,//this.formMs13.mstMs13SurDetailRespons.tariffSequence, //",
          cmms24dQuantityNone: e.noneQty, //N",
          cmms24dQuantity: itemWeight.quantityUsedMs13, //2200,
          cmms24dQuantityUnit: e.quantityUnit, //กระสอบ",
          cmms24dNetWeight: itemWeight.amountUsedMs13, //49940.000000,
          cmms24dNetWeightUnit: e.netWeightUnit, //KGM",
          cmms24dNetWeightPerUnit: e.netWeightPerUnit, //22.700000,
          cmms24dGrossWeight: itemWeight.amountUsedMs13,
          cmms24dGrossWeightPerUnit: e.grossWeightUnit, //22.790000,
          cmms24dGrossWeightPerUnitExporter: e.grossWeightUnitExp, //22.790000,
          cmms24dUnitPrice: e.unitPrice, //908.000000,
          cmcuCurrencyCode: e.currency, //USD",

          cmms24dFobValueBaht: fobValueBaht, //1540686.595000,
          cmms24dFobValueUsd: fobValueUsd, //45345.520000,

          cmms24dSurveyQuantity: e?.surveyDetail.length > 0 ? surveyDetail?.cmms13srdSurveyQuantity : 0,
          cmms24dSurveyQuantityUnit: e?.surveyDetail.length > 0 ? e.quantityUnit : 0,
          cmms24dSurveyNetWeight: e?.surveyDetail.length > 0 ? surveyDetail?.cmms13srdSurveyNetWeight : 0,
          cmms24dSurveyNetWeightUnit: e?.surveyDetail.length > 0 ? e.netWeightUnit : 0,
          cmms24dSurveyNetWeightPerUnit: e?.surveyDetail.length > 0 ? surveyDetail?.cmms13srdSurveyNetWeightUnit : 0,
          cmms24dSurveyGrossWeightPerUnit: e?.surveyDetail.length > 0 ? surveyDetail?.cmms13srdSurveyGrossWeightPerUnit : 0,

          cmms24hKpotQuantity1: e.kPotQty1, //0.000000,
          cmms24hKpotQuantity2: e.kpotQty2, //0.000000,
          cmms24hKpotQuantity3: e.kpotQty3, //0.000000,
          cmms24hKpotQuantity4: null,
          cmms24hKpotQuantity5: null,
          cmms24hRefer1: null,
          cmms24hRefer2: null,
          cmms24hRefer3: null,
          cmms24hRefer4: null,
          cmms24hRefer5: null,
          cmms13srhIsActive: "Y",
          cmms24hKpot1: e.kPot1, //",
          cmms24hKpot2: e.kPot2, //",
          cmms24hKpot3: e.kPot3, //",
          cmms24hKpot4: null,
          cmms24hKpot5: null
        };
      }
    });
    // 
    formDataMS24.dataMs24Detail = dataMs24Detail;
    // console.log("productList ==> ", this.productList);
    console.log("formDataMS24-amount ==> ", formDataMS24);
    // if (save) {
    this.appMs24Service.saveDataMs24(formDataMS24).then((res) => {
      //console.log("this.dataMs13List==>>",this.dataMs13List);
      // ;
      if (res != null && res != "error") {
        var rqUseData = {
          useItem: this.dataMs13List.map((element) => {
            return {
              ID: element.itemId,
              hId: element.itemHid,
              weightUsed: element.usedNet + element.amountUsedMs13,
              availableNet: element.netWeight - (element.usedNet + element.amountUsedMs13),
            }
          })
        };

        this.appMs13Service.updateUseDataMS13(rqUseData).then((res2) => {
          //console.log("updateUseDataMS13 ==> ", res);
          if (res != "error") {
            this.router.navigate(["/page/o2/create-ms24"], {
              queryParams: {
                page: "ms24",
                refNo: res.cmms24RefNo,
                id: res.cmms24HId,
                product: res.cmms24GoodsCode,
                docNo: "",
                item: res,
              },
            });

            this.dialogRef.close();
          }
        });
      }

      if (res != "error") {
        this.ms24Id = res.cmms24HId;
      }
    });
    // }
  }

  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  async getdataMs13ById() {
    const formMs13ID = {
      id: this.data.formData.id,
    };
    // 
    await this.appMs13Service.getDataMS13ById(formMs13ID).then((res: any) => {
      console.log(res);
      if (res == "error") {
      } else {
        var dataSourceList = res.map((x, n) => {
          return {
            ...x,
            rowNo: (n + 1),
            amountUsedMs13: x.surveyNetWeight - x.usedNet,
            quantityUsedMs13: x.surveyQuantity > 1 ? x.surveyQuantity - x.quantityUsed : 1
          };
        });

        var data = dataSourceList.filter(item => item.quantityUsedMs13 > 0 && item.amountUsedMs13 > 0);

        this.dataMs13Source = new MatTableDataSource(data);
        this.dataMs13List = data;
      }
    });
  }
}