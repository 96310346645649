<div class="flex-col" style="width: 100%;">
  <div class="flex flex-col px-20">
    <!-- MENU BAR -->
    <div class="flex flex-col" style="padding-left: 20px; padding-right: 20px;">
      <mat-card class="flex flex-row" style="width: 100%; gap: 5px; padding: 5px;">
        <button mat-icon-button matTooltip="ย้อนกลับ" matTooltipPosition="above"
          style="width: 2em !important; height: 2em!important;" routerLink="/page/DFT/surveyorlist">
          <i class="ti ti-chevron-left"></i>
        </button>
        <button mat-icon-button matTooltip="โหลดข้อมูลใหม่" matTooltipPosition="above"
          style="width: 2em !important; height: 2em !important;" (click)="loadData(companyID)">
          <i class="ti ti-refresh"></i>
        </button>
        <!-- <button mat-icon-button matTooltip="บันทึก" matTooltipPosition="above"
          style="width: 2em !important; height: 2em !important;" (click)="upDate()">
          <i class="ti ti-device-floppy"></i>
        </button>
        <button mat-icon-button matTooltip="แก้ไข" matTooltipPosition="above"
          style="width: 2em !important; height: 2em !important;">
          <i class="ti ti-edit"></i>
        </button> -->
      </mat-card>
    </div>

    <div class="flex flex-col p10">
      <div class="p10">
        <mat-card>
          <div class="flex flex-col p-5">
            <div class="flex flex-col">
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  วันที่ได้รับข้อมูลครั้งแรก : {{ form.createDatetime | date:'yyyy-MM-dd h:mm:ss' }}
                </div>
                <div class="basis-1/2 f18 text-right">
                  วันที่ได้รับข้อมูลล่าสุด : {{ form.lastUpdate | date:'yyyy-MM-dd h:mm:ss' }}
                </div>
              </div>
            </div>
            <!-- ข้อมูลผู้ประกอบธุรกิจตรวจสอบมาตรฐานสินค้า -->
            <div class="flex-col">
              <fieldset class="mt-5 border-2 border-solid rounded-lg">
                <legend class="ml-2.5 pr-2.5">
                  ข้อมูลผู้ประกอบธุรกิจตรวจสอบมาตรฐานสินค้า
                </legend>
                <div class="px-10">
                  <div class="flex flex-col pt-5">
                    <div class="flex flex-row">
                      <div class="basis-1/4 self-center	text-right pr-2.5">
                        ใบทะเบียนเลขที่ ผธ.
                      </div>
                      <div class="basis-1/4">
                        <mat-form-field class="w-full">
                          <input type="text" matInput readonly #input [(ngModel)]="form.ptNo" />
                        </mat-form-field>
                      </div>
                      <div class="basis-1/4 self-center	text-right pr-2.5">
                        ปีที่จดทะเบียน
                      </div>
                      <div class="basis-1/4">
                        <mat-form-field class="w-10/12">
                          <input type="text" matInput readonly #input [(ngModel)]="form.ptYear" />
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col pt-2.5">
                    <div class="flex flex-row">
                      <div class="basis-1/4 self-center	text-right pr-2.5">
                        เลขประจำตัวผู้เสียภาษี
                      </div>
                      <div class="basis-1/4">
                        <mat-form-field class="w-full">
                          <input type="text" matInput readonly #input [(ngModel)]="form.taxNo" />
                        </mat-form-field>
                      </div>
                      <div class="basis-1/4 self-center	text-right pr-2.5">
                        วันที่มีผลบังคับใช้
                      </div>
                      <div class="basis-1/4">
                        <mat-form-field class="w-10/12">
                          <input matInput [matDatepicker]="startDate" readonly type="datetime"
                            [(ngModel)]="form.mS6DateStart" [max]="form.mS6DateEnd" name="mS6DateStart"
                            #startDateSearch="ngModel" class="cursor-pointer">
                          <mat-hint>MM/DD/YYYY</mat-hint>
                          <mat-datepicker #startDate></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col pt-2.5">
                    <div class="flex flex-row">
                      <div class="basis-1/4 self-center	text-right pr-2.5">
                        ชื่อบริษัทตรวจสอบสินค้ามาตรฐาน
                      </div>
                      <div class="basis-1/4">
                        <mat-form-field class="w-full">
                          <input type="text" matInput readonly #input [(ngModel)]="form.name" />
                        </mat-form-field>
                      </div>
                      <div class="basis-1/4 self-center	text-right pr-2.5">
                        วันที่หมดอายุ
                      </div>
                      <div class="basis-1/4">
                        <mat-form-field class="w-10/12">
                          <input matInput [matDatepicker]="endDate" readonly type="datetime"
                            [(ngModel)]="form.mS6DateEnd" [min]="form.mS6DateStart" name="mS6DateEnd"
                            #endDateSearch="ngModel" class="cursor-pointer">
                          <mat-hint>MM/DD/YYYY</mat-hint>
                          <mat-datepicker #endDate></mat-datepicker>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col pt-2.5">
                    <div class="flex flex-row">
                      <div class="basis-1/4 self-center	text-right pr-2.5">
                        สถานะผู้ประกอบธุรกิจ
                      </div>
                      <div class="basis-1/4">
                        <mat-form-field class="w-full">
                          <input type="text" matInput readonly #input [(ngModel)]="form.status" />
                        </mat-form-field>
                      </div>
                      <div class="basis-1/4 self-center	text-right pr-2.5">
                        สถานะอัพเดทเข้า MR
                      </div>
                      <div class="basis-1/4">
                        <mat-form-field class="w-2/4">
                          <mat-select name="upMR" [(ngModel)]="form.upMR" disabled>
                            <mat-option [value]="''">กรุณาเลือก</mat-option>
                            <mat-option [value]="'N'">ไม่อัพเดต</mat-option>
                            <mat-option [value]="'Y'">อัพเดต</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <!-- สินค้าที่ได้รับอนุญาต -->
                  <div class="flex flex-col pt-2.5 pb-5">
                    <div class="flex flex-row">
                      <div class="basis-1/4">
                        <div class="flex flex-row">

                        </div>
                      </div>
                      <div class="basis-2/4">
                        <fieldset class="border-2 border-solid rounded-lg">
                          <legend class="ml-2.5 pr-2.5">
                            สินค้าที่ได้รับอนุญาต
                          </legend>
                          <div class="px-10 pb-5" *ngFor="let item of form.productAccepts; let i = index">
                            <div class="flex flex-col pt-2.5">
                              <div class="flex flex-row">
                                <div class="basis-1/2 self-center	text-left pr-2.5">
                                  {{ item.productAcceptName }}
                                </div>
                                <div class="basis-1/4 self-center	text-right pr-2.5">
                                  สถานะที่ได้รับอนุญาต
                                </div>
                                <div class="basis-1/4">
                                  <mat-form-field class="w-full">
                                    <input type="text" matInput readonly #input
                                      value="{{ item.productAcceptStatus }}" />

                                  </mat-form-field>
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                      <div class="basis-1/4">

                      </div>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <!--รายละเอียดข้อมูลผู้ตรวจสอบมาตรฐานสินค้า  -->
            <div class="flex-col">
              <fieldset class="mt-5 border-2 border-solid rounded-lg">
                <legend class="ml-2.5 pr-2.5">
                  รายละอียดข้อมูลผู้ตรวจสอบมาตรฐานสินค้า
                </legend>
                <div style="padding: 20px;">
                  <mat-card>
                    <mat-card-header class="fontTitleHeader">
                      เงื่อนไขในการค้นหาข้อมูลผู้ตรวจสอบ
                    </mat-card-header>
                    <mat-card-content class="example-headers-align" style="background-color: #DFDFDF;">
                      <div class="flex flex-col pt-2.5">
                        <div class="flex flex-row">
                          <div class="basis-1/2 f18 self-center text-right pr-5">
                            เลขที่ ผตก. / ผตข. :
                          </div>
                          <div class="basis-1/2 f18">
                            <mat-form-field class="w-2/5">
                              <input type="text" matInput autocomplete="off" [(ngModel)]="search.surveyorNumber"
                                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" name="surveyorNumber" />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col pt-2.5">
                        <div class="flex flex-row">
                          <div class="basis-1/2 f18 self-center text-right pr-5">
                            ชื่อผู้ตรวจสอบมาตรฐานสินค้า :
                          </div>
                          <div class="basis-1/2 f18">
                            <mat-form-field class="w-2/5">
                              <input type="text" matInput autocomplete="off" [(ngModel)]="search.nameTH"
                                name="nameTH" />
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col pt-2.5">
                        <div class="flex flex-row">
                          <div class="basis-1/2 f18 self-center text-right pr-5">
                            ประเภทผู้ตรวจสอบ :
                          </div>
                          <div class="basis-1/2 f18">
                            <mat-form-field class="w-2/5">
                              <mat-select name="Sur_type" [(ngModel)]="search.type" name="type">
                                <mat-option [value]="'A'">ผตก.</mat-option>
                                <mat-option [value]="'B'">ผตข.</mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                      <div class="flex flex-col pt-2.5">
                        <div class="flex flex-row">
                          <div class="basis-1/2 f18 self-center text-right pr-5">
                          </div>
                          <div class="flex basis-1/2 f18" style="gap: 6px">
                            <button class="pl-5" mat-raised-button (click)="searchData()">
                              ค้นหา
                            </button>
                            <button class="pl-5" mat-raised-button (click)="resetFilter()">
                              ล้างตัวกรอง
                            </button>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                  <!-- ตารางรายชื่อผู้ตรวจสอบมาตรฐาน -->
                  <div class="pt-5">
                    <div class="mat-elevation-z8" style="width: 100%;">
                      <table mat-table matSort [dataSource]="dataSource">
                        <ng-container matColumnDef="surveyorNumber" style="text-align: center;">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
                            เลขที่ ผตก./ผตข.
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: center; font-size:14;">
                            {{ element.surveyorNumber }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="nameTH">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            ชื่อผู้ตรวจสอบมาตรฐานสินค้า
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: center; font-size:14;">
                            {{ element.nameTH }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="type">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
                            ประเภทผู้ตรวจสอบ
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: center; font-size:14;">
                            {{ element.type == 'A' ? 'ผตก.' : element.type == 'B' ? 'ผตข.' : "ไม่ระบุ" }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="pYear">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef>
                            ปีของใบอนุญาต
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: center; font-size:14;">
                            {{ element.pYear }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="issuedDate">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
                            วันที่มีผลบังคับใช้
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: center; font-size:14;">
                            {{ element.issuedDate |date:'yyyy-MM-dd' }}
                          </td>
                        </ng-container>

                        <ng-container matColumnDef="expireDate">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
                            วันที่หมดอายุ
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: center; font-size:14;">
                            {{ element.expireDate |date:'yyyy-MM-dd' }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="productName">
                          <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
                            สินค้าที่ได้รับอนุญาต
                          </th>
                          <td mat-cell *matCellDef="let element" style="text-align: left; font-size:10px"
                            [innerHTML]="element.type == 'A' ? '-' : element.productName">
                          </td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="displayedColumns" style="text-align:center;"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                      </table>
                      <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
                        aria-label="Select page of periodic elements">
                      </mat-paginator>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>