import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: 'root'
  })
  export class ApiItemtypeService {
  
    constructor(private apiService: ApiService) { }
  
    getDataRefGoodsClass(request: any): Observable<any[]> {
      return this.apiService.post('/api/getDataRefGoodsClass',request);
    }

    getDataDetailProduct(request: any): Observable<any[]> {
      return this.apiService.post('/api/getDataDetailProduct', request);
    }

    AdjustDataRefGoodsClass(request: any): Observable<any[]> {
      return this.apiService.post('/api/AdjustDataRefGoodsClass', request);
    }

  
  }
  