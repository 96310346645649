<div style="display: flex;flex-flow: column;height: 100%; width: 100%; gap: 30px;">
    <div style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
        background-color: gray;font-size: 20px;" class="pl-20 pr-20">
        <div style="display: flex;flex-flow: row;gap: 30px">
            <h2 style="cursor:pointer;text-align: center;">Request</h2>
            <h2 style="cursor:pointer;text-align: center;">Payment</h2>
            <h2 style="cursor:pointer;text-align: center;">Print</h2>
            <h2 style="cursor:pointer;text-align: center;">Configuration</h2>
        </div>
    </div>

    <div class="full pb-5" style="display: flex;flex-flow:row wrap;">

        <div style="display: flex; flex-flow: column;">
            <!-- Photo -->
            <div class="center pb-5" style="height: 200px;">
                 <i class="bi bi-person-circle" style="font-size: 150px; color: rgb(0, 0, 0);"></i> 
                <!-- <mat-icon>supervised_user_circle</mat-icon> -->
            </div>

            <div>
                <div class="pl-5 font">USER PROFILE | ข้อมูลผู้ใช้งาน</div>
                <div class="pl-5">
                    <!-- Username -->
                    <div class="pt-3" style="display: flex; flex-flow: row ;">
                        <div class="border border-end-0  pl-3" style="width:150px;">
                            <p>Username</p>
                        </div>
                        <div class="border border-start-0 pl-3" style="width: 300px;">
                            <p>A5200484</p>
                        </div>
                    </div> <!-- End User-->

                    <!-- Name -->
                    <div class="pt-3" style="display: flex; flex-flow: row;">
                        <div class="border border-end-0  pl-3" style="width:150px;">
                            <p>Name</p>
                        </div>
                        <div class="border border-start-0 pl-3" style="width:300px;">
                            <p>นายสามารถ ส่งออก</p>
                        </div>
                    </div> <!-- End Name-->

                    <!-- Phone -->
                    <div class="pt-3" style="display: flex; flex-flow: row;">
                        <div class="border border-end-0  pl-3" style="width:150px;">
                            <p>Phone</p>
                        </div>
                        <div class="border border-start-0 pl-3" style="width:300px;">
                            <p>081-234-5678</p>
                        </div>
                    </div> <!-- End Phone-->

                    <!-- Email -->
                    <div class="pt-3" style="display: flex; flex-flow: row;">
                        <div class="border border-end-0  pl-3" style="width:150px;">
                            <p>Email</p>
                        </div>
                        <div class="border border-start-0 pl-3" style="width:300px;">
                            <p>Customer@mail.com</p>
                        </div>
                    </div> <!-- End Email-->
                </div>
            </div>
        </div> <!-- End User Profile-->

        <!-- Company Profile -->
        <div class="pt-5" style="display: flex; flex-flow: column;">
            <app-company-profilei></app-company-profilei>
        </div> <!-- End Company Profile -->
    </div>


</div>
