import { Injectable } from "@angular/core";
import { ApiStaffService } from "../api/api-staff.service";
import { BehaviorSubject } from "rxjs";
import { IAppStaffHistoryMS13ListResponse } from "app/business/interface/api/api-staff.interface";

@Injectable({
    providedIn: "root",
  })
  export class AppStaffService {

    listHistoryMs13Staff : BehaviorSubject<IAppStaffHistoryMS13ListResponse>

    constructor(private apiStaffService: ApiStaffService) {
      this.listHistoryMs13Staff = new BehaviorSubject<IAppStaffHistoryMS13ListResponse>(null);
    }
  
    StaffGetListRenew(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiStaffService.StaffGetListRenew(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
  
    StaffGetRenew(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiStaffService.StaffGetRenew(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
 
    StaffVerifyRenew(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiStaffService.StaffVerifyRenew(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    StaffHistoryMs13List(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiStaffService.StaffHistoryMs13List(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    StaffGetLogMs13(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiStaffService.StaffGetLogMs13(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
  }
  