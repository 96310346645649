import { Injectable } from "@angular/core";
import { ApiDFTService } from "../api/api-dft.service";

@Injectable({
    providedIn: "root",
  })
  export class AppDFTService {
    constructor(private ApiDFTService: ApiDFTService) {}
  
    ExporterList(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiDFTService.ExporterList(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
  
    getExporterDataDetail(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiDFTService.getExporterDataDetail(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
 
    getSurveyorList(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiDFTService.getSurveyorList(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    getDataSurveyor(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) =>{
        this.ApiDFTService.getDataSurveyor(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    updateDataSurveyor(dataReq: any): Promise<any>{
      return new Promise((resolve, reject) =>{
        this.ApiDFTService.updateDataSurveyor(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

}
  