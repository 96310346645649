import { AppMasterService } from 'app/business/service/app/app-master.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormHelperService } from '@bundle/service/form-helper.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import _ from 'lodash';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss']
})
export class UserFormComponent implements OnInit {
  userForm: FormGroup;
  userGroupList: any[];
  userGroupCode: string;
  userRoleCode: string;
  userRoleList: any[];
  clientList: any[];
  branchList: any[];
  activeStatusList: any[];
  pathUpload: string;
  value: any[] = [];
  file: any;
  fileUpload: any;
  userToken: any;
  userProfile: any;
  isGroupEdit: boolean = false;
  isLockEdit: boolean = false;

  @Output() userFormEmit: EventEmitter<FormGroup> = new EventEmitter();
  @Input() dataInput: any;
  constructor(
    private formBuilder: FormBuilder,
    private formHelper: FormHelperService,
    private appUserService: AppUserService,
    private appMasterService: AppMasterService,
    private _authService: AuthService,
    private alertService: AlertMessageService,
  ) { }

  ngOnInit(): void {
    this.userToken = JSON.parse(
      this._authService.loginUser
    )
    this.userFormInit();
    this.setSubcribe();
  }
  async setSubcribe() {
    // ;

    this.checkGroupAccess(this.userToken.gId);

    const formData = {
      username: this.userToken.userName,
      uId: this.userToken.uId ? this.userToken.uId : this.userToken.uid,
    };

    await this.appUserService.getUserProfile(formData).then(res => {
      this.userProfile = res;
    });

    this.checkEditAccess(this.userToken.gId);

    this.appUserService.userRoleList.subscribe(res => {
      if (res && res.length > 0) {
        this.userRoleList = res?.filter(x => x.isActive === 'Y');
      } else {
        this.userRoleList = new Array();
      }
    });
    this.appUserService.userGroupList.subscribe(res => {
      if (res && res.length > 0) {
        this.userGroupList = res?.filter(x => x.isActive === 'Y');
      } else {
        this.userGroupList = new Array();
      }
    });
    this.appMasterService.getListCscBranchManage({}).then(res => {
      if (res && res.length > 0) {
        this.branchList = res?.filter(x => x.isActive === 'Y');
      } else {
        this.branchList = new Array();
      }
    });
  }
  userFormInit(): void {
    this.userForm = this.formBuilder.group({
      userName: [this.dataInput?.userName, [Validators.required]],
      name: [this.dataInput?.name, [Validators.required]],
      lastName: [this.dataInput?.lastName, [Validators.required]],
      email: [this.dataInput?.email],
      userGroupCode: [this.dataInput?.userGroupCode, [Validators.required]],
      userRoleCode: [this.dataInput?.userRoleCode, [Validators.required]],
      isActive: [this.dataInput?.isActive, [Validators.required]],
      activeBranchId: [this.dataInput?.activeBranchId],
      statusLogin: [this.dataInput?.statusLogin, [Validators.required]],
      pathPicture: [this.dataInput?.pathPicturn],
    });
    this.pathUpload = this.dataInput?.pathPicturn
    this.userFormEmit.emit(this.userForm);
  }
  userFormReset(): void {
    this.userFormInit();
  }

  async upload(): Promise<void> {
    if (this.value.length === 0) {
      return
    }
    // const file = event.value.files[0];
    this.value.forEach((item) => {
      this.file = item

      this.onUploadBr()
    })
    this.value = []
  }
  onUploadBr(): void {
    const formData = new FormData()
    formData.append('image', this.file, this.file.name)
    formData.append('name', 'File/VerifySign');

    this.appMasterService.uploadImageToBlobs(formData).subscribe((result) => {
      if (result) {
        const firstRes = _.first(result);
        this.fileUpload = firstRes;
        this.pathUpload = this.fileUpload.filePath
        //update picture
        this.userForm.patchValue({
          pathPicture: this.pathUpload
        });
      }
    })
  }

  checkEditAccess(groupCode: string) {
    // if (groupCode !== "1" && groupCode !== "4" && groupCode !== "5") {
    //   if (this.userProfile?.isRoleAdmin === "Y") {
    //     this.userForm.get('userRoleCode')?.enable();
    //     this.userForm.get('statusLogin')?.enable();
    //   } else {
    //     this.userForm.get('userRoleCode')?.disable();
    //     this.userForm.get('statusLogin')?.disable();
    //   }
    // } else {
    //   this.userForm.get('userRoleCode')?.enable();
    //   this.userForm.get('statusLogin')?.enable();
    // }
    this.userForm.get('userRoleCode')?.enable();
    this.userForm.get('statusLogin')?.enable();
  }

  checkGroupAccess(groupCode: string) {
    // if (groupCode !== "1" && groupCode !== "4" && groupCode !== "5") {
    //   this.userForm.get('userGroupCode')?.disable();
    // } else {
    //   this.userForm.get('userGroupCode')?.enable();
    // }
    this.userForm.get('userGroupCode')?.enable();

  }

  userGroupChange(val: any): void {
    this.userGroupCode = val;
  }

  userRoleChange(val: any): void {
    this.userRoleCode = val;
  }
}
