import { Injectable } from "@angular/core";
import { ApiPaymentService } from "../api/api-payment";

@Injectable({
  providedIn: "root",
})
export class AppPaymentService {
  rcListsData: any = [];
  constructor(private apiPaymentService: ApiPaymentService) {}

  getPaymentList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.getPaymentList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getPaymentObjList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.getPaymentObjList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveCreaterPaymantRequest(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService
        .saveCreaterPaymantRequest(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  savePayment(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService
        .savePayment(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getMasterFeeRateList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.getMasterFeeRateList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getRelationshipList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.getRelationshipList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getDetailPaymentRequest(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService
        .getDetailPaymentRequest(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  updateStatusPayment(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.updateStatusPayment(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getCompanyListForPayment(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService
        .getCompanyListForPayment(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  pullPaymentFromCSC(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.pullPaymentFromCSC(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  dataReprintForPay(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.dataReprintForPay(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getCompanyAddressReciept(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService
        .getCompanyAddressReciept(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  checkIsSeftPrint(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.checkIsSeftPrint(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  exportBillPayment(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.exportBillPayment(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  exportBillPaymentUpdate(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.exportBillPaymentUpdate(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  exportPDFReqPayment(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.exportPDFReqPayment(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getPaymentDetailByPackId(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService
        .getPaymentDetailByPackId(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  paymentGetEmailETax(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.paymentGetEmailETax(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getStatusPaymentList(): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.getStatusPaymentList().subscribe((res) => {
        resolve(res);
      });
    });
  }

  reportPayment(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.reportPayment(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  reportApporvedCoCf(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.reportApporvedCoCf(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  paymentObjForFreerateList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiPaymentService.paymentObjForFreerateList(request).subscribe((res) => {
        resolve(res);
      });
    });
  } 
}
