<mat-card class="example-card">
  <mat-card-header class="fontTitleHeader">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal"> ยื่นคำขอทำ Short </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="flex flex-col" style="padding: 20px; gap:16px;">
    <form #FormValidateItem="ngForm">
      <div class="flex flex-col" style="gap:16px;">
        <div class="flex flex-row">
          <div class="basis-1/3 gap-1 p10">
            <div class="flex flex-row">
              <mat-form-field class="w100">
                <mat-label>เลขที่ใบรับรอง </mat-label>
                <input [ngModelOptions]="{standalone: true}" disabled type="text" matInput placeholder="เลขที่ใบรับรอง"
                  [(ngModel)]="form.ms24no" name="ms24no" #ms24no="ngModel">
              </mat-form-field>
            </div>
          </div>
          <div class="basis-1/3 gap-1 p10 w100">
          </div>
          <div class="basis-1/3 gap-1 p10">
            <mat-form-field class="w100">
              <mat-label>วันหมดอายุ</mat-label>
              <input autocomplete="off" disabled placeholder="วันหมดอายุ" matInput [matDatepicker]="expireDate"
                [(ngModel)]="form.expireDate" name="expireDate" #expireDate="ngModel" readonly>
              <mat-datepicker-toggle matSuffix [for]="expireDate"></mat-datepicker-toggle>
              <mat-datepicker #expireDate></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="w100 f18 p10">
            <span>ขอยื่นเรื่องทำ Short โดยมีรายละเอียดดังนี้</span>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="mat-elevation-z8" style="width: 100%">
            <dx-data-grid id="gridContainer" [dataSource]="dataSource" [columnAutoWidth]="true" [showBorders]="true"
              [allowColumnReordering]="true" [showBorders]="true"
              [noDataText]="'ไม่พบข้อมูลสินค้าจากใบอนุญาตที่จะยื่นขอทำ Short กรุณาตรวจสอบ'">
              <!-- <dxo-editing mode="row" [allowUpdating]="true">
              </dxo-editing> -->
              <dxi-column caption="รายการที่" alignment="center" cellTemplate="wFlow" [width]='100'>
              </dxi-column>
              <div *dxTemplate="let row of 'wFlow'">
                {{row.rowIndex+1}}
              </div>
              <dxi-column alignment="center" dataField="productName" caption="รายการสินค้า">
              </dxi-column>
              <dxi-column alignment="center" cellTemplate="netWeight" caption="น้ำหนักรวม">
              </dxi-column>
              <div *dxTemplate="let row of 'netWeight'">
                {{row.data.netWeight | number: '0.6' }} {{row.data.netWeightUnit}}
              </div>
              <dxi-column alignment="center" cellTemplate="weightUsed" caption="น้ำหนักที่ Short ไปแล้ว">
              </dxi-column>
              <div *dxTemplate="let row of 'weightUsed'">
                {{row.data.shortWeightUsed | number: '0.6' }} {{row.data.netWeightUnit}}
              </div>
              <dxi-column alignment="center" cellTemplate="shortWeightRequest" caption="น้ำหนักที่ Short">
              </dxi-column>
              <div *dxTemplate="let row of 'shortWeightRequest'">
                <mat-form-field style="width: 100%">
                  <input autocomplete="off" matInput placeholder="" type="text" #input
                    [(ngModel)]="row.data.shortWeightRequest" name="shortWeightRequest" #shortWeightRequest="ngModel">
                  {{row.data.netWeightUnit}}
                </mat-form-field>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </form>
    <div class="flex flex-col items-center p10">
      <div class="flex basis-1/2 f18" style="gap: 15px;">
        <button mat-raised-button style="width: 100px;" color="accent" *ngIf="checkButton('BT167')"  (click)="saveItem()">
          ยื่นคำร้อง
        </button>
        <button mat-raised-button style="width: 100px;" *ngIf="checkButton('BT168')" [mat-dialog-close]>
          ยกเลิก
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>