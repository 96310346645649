<mat-card>
   <mat-card-header  class="fontTitleHeader" >
      <div class="flex justify-between" style="width: 100%;">
        <div class="fontTitleModal" > ประวัติและการแก้ไข มส.13</div>
        <div>
          <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <i class="ti ti-x text-white"></i>
          </button>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div class="basis-full p-2.5 self-center text-2xl ">LOG</div>
        </div>
      </div>
      <div class="flex flex-col">
        
    <div class="p-0">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
          <ng-container matColumnDef="action" style="text-align: center;">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="justify-center text-center"
              >
                ACTION
              </th>
              <td mat-cell *matCellDef="let element" 
              >
                {{ element.action }}
              </td>
          </ng-container>
          <ng-container matColumnDef="description" style="text-align: center;">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="justify-center text-center"
              >
                รายละเอียด
              </th>
              <td mat-cell *matCellDef="let element" >
                {{ element.description }}
              </td>
          </ng-container>
          <ng-container matColumnDef="securityCode" style="text-align: center;">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="justify-center text-center"
              >
                SECURITY CODE
              </th>
              <td mat-cell *matCellDef="let element" >
                {{ element.securityCode }}
              </td>
          </ng-container>
          <ng-container matColumnDef="actionBy" style="text-align: center;">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="justify-center text-center"
              >
                กระทำโดย
              </th>
              <td mat-cell *matCellDef="let element" 
                  class="justify-center text-center">
                {{ element.actionBy }}
              </td>
          </ng-container>
          <ng-container matColumnDef="actionDate" style="text-align: center;">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="justify-center text-center"
              >
                วันและเวลา
              </th>
              <td mat-cell *matCellDef="let element" 
                  class="justify-center text-center">
                {{ element.actionDate | date: 'yyyy-MM-dd' }}
              </td>
          </ng-container>
          <ng-container matColumnDef="ip" style="text-align: center;">
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="justify-center text-center"
              >
                IP
              </th>
              <td mat-cell *matCellDef="let element"  
                  class="justify-center text-center">
                {{ element.ip }}
              </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
      </div>
    </mat-card-content>
  </mat-card>