import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: 'root'
  })
  export class ApiShippingService {
  
    constructor(private apiService: ApiService) { }
  
    ShippingList(request: any): Observable<any[]> {
      return this.apiService.post('/api/getDataShipping',request);
    }

    getDataCompanyDetail(request: any): Observable<any[]> {
      return this.apiService.post('/api/getDataCompanyDetail', request);
    }

  
  }
  