import { items } from "./../../../../mock-api/apps/file-manager/data";
import { SelectionModel } from "@angular/cdk/collections";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { IcoStatus } from "app/business/interface/app/app-co.interface";
import { AppPaymentService } from "app/business/service/app/app-payment";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";
import { AddBillCompanyModalComponent } from "../add-bill-company-modal/add-bill-company-modal.component";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { Location, formatDate } from "@angular/common";
@Component({
  selector: "app-create-payment-list-inquiry",
  templateUrl: "./create-payment-list-inquiry.component.html",
  styleUrls: ["./create-payment-list-inquiry.component.scss"],
})
export class CreatePaymentListInquiryComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  searchString: string;
  form: any = {};
  url: any;
  buttonList: any[] = [];
  searchForm: any = {
    certificateNo: "",
    endDate: null,
    startDate: null,
    isRqStatus: "0",
    receiptNo: "",
    requestNo: "",
    invoiceReference: "",
  };

  statusOptions: IcoStatus[] = [
    {
      text: "แสดงทั้งหมด",
      code: "0",
    },
    {
      text: "ยังไม่ส่งตรวจ",
      code: "1",
    },
    {
      text: "กำลังตรวจสอบ",
      code: "2",
    },
    {
      text: "ผ่านอนุมัติ",
      code: "3",
    },
  ];

  displayedColumns: string[] = [
    "select",
    "refNo",
    "docNo",
    "docType",
    // "netWeight",
    // "netWeightTon", 
  ];
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  _liveAnnouncer: any;
  selection = new SelectionModel<any>(true, []);
  chkIsEpayment: boolean = false;
  chkConfig: boolean = true;
  userToken: any;
  constructor(
    private appPaymentService: AppPaymentService,
    private router: Router,
    private alertService: AlertMessageService,
    private dialog: MatDialog,
    private appUserService: AppUserService,
    private _authService: AuthService,
    private appMenuService: AppMenuService,
    private _location: Location
  ) {
    // this.userProfile = JSON.parse(this.appUserService.getSessionUserProfile());
  }

  async ngOnInit(): Promise<void> {
    await this.getRouter();
    this.userProfile = JSON.parse(this._authService.loginUser);
    this.userToken = JSON.parse(this._authService.loginUser);

    this.form.typeCreate = "1";
    localStorage.setItem("selectionCreate", null);
    this.loadData();
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode,
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(
      formData
    );
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
  loadData() {
    const formData = {
      uId: Number(this.userProfile.uId),
    };
    // this.mockupData();
    this.appPaymentService.getPaymentObjList(formData).then((res) => {
      console.log("res", res);
      if (res != "error") {
        res = res.map((m) => {
          return {
            ...m,
            detailId: m.ms24Id,
            netWeight: m.item.netWeight,
            netWeightTon: m.item.netWeightTon,
          };
        });
        // console.log("dataSource  getPaymentObjList >> ", res);
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } else {
        // this.alertService
        // .open({
        //   message: "User ของคุณไม่สามารถสร้างใบชำระเงินได้",
        //   title: "",
        // })
        // .then((res) => {
        // this._location.back();
        // });
      }
    });
  }

  mockupData() {
    var dataSource = [
      {
        reqDateTime: "2023-05-25T13:46:30.727",
        reqNo: "RQ66052500004",
        certificateNo: "-",
        docType: "CF",
        reqType: "N",
        reqTypeName: "งานใหม่",
        reqComId: 22349,
        consignor: "22349",
        invoiceNo: "2334242",
        companyCode: "0105560015660",
        companyTaxId: "0105560015660",
        companyName: "บริษัท จีน นีเอล เอเชีย จำกัด",
        companyBranch: "",
        companyEmail: null,
        userAddress: "9/247-250 หมู่5 ถนนพหลโยธิน",
        companyExpireDate: null,
        reqAmount: 1,
        packId: 4716578,
        doc_hId: 4742357,
        isMember: "Y",
        feeCode: "110015",
        feeCodeOther: "110011",
        expressType: "N",
        certificateDateRequest: "on_date",
        destination: null,
        isEpayment: "N",
        certificateDate: "2023-05-25T00:00:00",
        createDate: "2023-05-25T13:46:30.517",
      },
      {
        reqDateTime: "2023-05-25T13:15:09.623",
        reqNo: "RQ66052500002",
        certificateNo: "-",
        docType: "CO",
        reqType: "N",
        reqTypeName: "งานใหม่",
        reqComId: 22349,
        consignor: "JEAN NIEL ASIA LTD.",
        invoiceNo: "-",
        companyCode: "0105560015660",
        companyTaxId: "0105560015660",
        companyName: "บริษัท จีน นีเอล เอเชีย จำกัด",
        companyBranch: "",
        companyEmail: null,
        userAddress: "9/247-250 หมู่5 ถนนพหลโยธิน",
        companyExpireDate: null,
        reqAmount: 0,
        packId: 4716576,
        doc_hId: 4742355,
        isMember: "Y",
        feeCode: "110012",
        feeCodeOther: null,
        expressType: "N",
        certificateDateRequest: "approve_date",
        destination: "AMERICAN SAMAO",
        isEpayment: "N",
        certificateDate: null,
        createDate: "2023-05-25T13:15:09.353",
      },
      {
        reqDateTime: "2023-05-25T11:36:05.353",
        reqNo: "RQ66052500001",
        certificateNo: "-",
        docType: "CO",
        reqType: "N",
        reqTypeName: "งานใหม่",
        reqComId: 22349,
        consignor: "JEAN NIEL ASIA LTD.",
        invoiceNo: "-",
        companyCode: "0105560015660",
        companyTaxId: "0105560015660",
        companyName: "บริษัท จีน นีเอล เอเชีย จำกัด",
        companyBranch: "",
        companyEmail: null,
        userAddress: "9/247-250 หมู่5 ถนนพหลโยธิน",
        companyExpireDate: null,
        reqAmount: 0,
        packId: 4716575,
        doc_hId: 4742354,
        isMember: "Y",
        feeCode: "110008",
        feeCodeOther: null,
        expressType: "Y",
        certificateDateRequest: "approve_date",
        destination: "BARBADOS",
        isEpayment: "N",
        certificateDate: null,
        createDate: "2023-05-25T11:36:05.187",
      },
    ];
  }

  applyFilter($event) {}

  setupFilter(column: string) {}

  stopPropagation(event) {
    event.stopPropagation();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    //
    if (!row) {
      return `${this.isAllSelected() ? "deselect" : "select"} all`;
    }
    return `${this.selection.isSelected(row) ? "deselect" : "select"} row ${
      row.position + 1
    }`;
  }

  create() {
    localStorage.setItem(
      "selectionCreate",
      JSON.stringify(this.selection.selected)
    );
    // ;
    if (this.form.typeCreate != 3) {
      if (this.selection.selected.length == 0) {
        this.alertService.open({
          message:
            "กรุณาเลือกรายการที่ต้องการชำระ หรือเลือก 'จ่ายรายการอื่นๆ (ไม่บันทึกรายการ)'",
          title: "กรุณาเลือก มส.24 ที่ต้องการชำระ",
        });
      } else {
        this.router.navigate(["/page/payment/create-payment-inquiry"], {
          queryParams: {
            typeCreate: this.form.typeCreate,
          },
        });
      }
    } else if (this.form.typeCreate == 3) {
      //2 Exporter
      //3	Shipper
      //8 Exporter and Shipper
      if (this.userProfile.gId != 2) {
        // const dialogRef = this.dialog.open(AddBillCompanyModalComponent, {
        //   disableClose: false,
        //   width: "45vw",
        //   data: {
        //     cpId: this.userProfile.cpId,
        //     gId: this.userProfile.gId,
        //   },
        //   maxWidth: "none",
        // });
        // dialogRef.afterClosed().subscribe((res) => {
        //   if (res != null && res != undefined && res != true) {
        this.router.navigate(["/page/payment/create-payment-inquiry"], {
          queryParams: {
            typeCreate: this.form.typeCreate,
            cpId: this.userProfile.cpId,
            gid: this.userProfile.gId,
          },
        });
        // } else {
        //   this.chkConfig = true;
        // }
        // });
      } else {
        if (this.userProfile.isDisable == true) {
          this.router.navigate(["/page/payment/create-payment-inquiry"], {
            queryParams: {
              typeCreate: this.form.typeCreate,
              cpId: this.userProfile.cpId,
              gid: this.userProfile.gId,
            },
          });
        } else {
          this.chkConfig = true;
        }
      }
    } else {
      this.router.navigate(["/page/payment/create-payment-inquiry"], {
        queryParams: {
          typeCreate: this.form.typeCreate,
        },
      });
    }
  }

  checkCompanyExporter(row) {
    var checkCompanyExporter = this.selection.selected.filter(
      (f) => f.companyId != row.companyId
    );
    if (checkCompanyExporter.length > 0) {
      this.alertService
        .open({
          message: "การสร้างใบคำขอชำระเงินต้องดำเนินการภายใต้บริษัทเดียวกัน",
          title: "",
        })
        .then((res) => {
          this.selection.deselect(row);
        });
    }
    if (this.selection.selected.length == 0) {
      this.chkIsEpayment = false;
    } else {
      this.chkIsEpayment = row.isEpayment == "Y" ? true : false;
    }
  }

  chkCreate(e) {
    if (e == 3) {
      this.chkConfig = this.userProfile.isDisable;
    } else {
      this.chkConfig = true;
    }
  }
}
