<mat-card class="example-card">
    <mat-card-header  class="fontTitleHeader" >
       <div class="flex justify-between" style="width: 100%;">
         <div class="fontTitleModal" > กรอกจำนวนที่ต้องการ มส.24 </div>
         <div>
          <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <i class="ti ti-x text-white"></i>
          </button>
         </div>
       </div>
     </mat-card-header>
     <mat-card-content>
        <form #FormValidateItem="ngForm" class="py-10">
            <div>
                <dx-data-grid id="gridDataMs13Detail" [dataSource]="dataMs13List" [columnAutoWidth]="true" [showBorders]="true"
                [allowColumnReordering]="true" [showBorders]="true">
                <!-- <dxo-editing mode="row" [allowUpdating]="true">
                </dxo-editing> -->
                <dxi-column caption="ลำดับ" alignment="center" cellTemplate="wFlow" [width]='100'>
                </dxi-column>
                <div *dxTemplate="let row of 'wFlow'">
                  {{row.rowIndex+1}}
                </div>
                <dxi-column alignment="center" dataField="goodsDescription" caption="รายการสินค้า">
                </dxi-column>
                <dxi-column alignment="center" cellTemplate="netWeight" caption="น้ำหนักรวม">
                </dxi-column>
                <div *dxTemplate="let row of 'netWeight'">
                  {{row.data.netWeight | number: '0.6' }} {{row.data.netWeightUnit}}
                </div>

                <dxi-column alignment="center" cellTemplate="usedNet" caption="ออกใบ มส.24 ไปแล้ว">
                </dxi-column>
                <div *dxTemplate="let row of 'usedNet'">
                  {{row.data.usedNet | number: '0.6' }} {{row.data.netWeightUnit}}
                </div>

                <dxi-column alignment="center" cellTemplate="surveyNetWeight" caption="น้ำหนักที่ออกใบ มส.24">
                </dxi-column>
                <div *dxTemplate="let row of 'surveyNetWeight'">
                  <mat-form-field>
                    <input readonly autocomplete="off" matInput placeholder="" type="text" #input
                      [(ngModel)]="row.data.amountUsedMs13" name="amountUsedMs13_{{row.rowIndex}}">
                  </mat-form-field>
                  {{row.data.netWeightUnit}}
                </div>
                
                <dxi-column alignment="center" cellTemplate="surveyQuantity" caption="จำนวนที่ออกใบ มส.24">
                </dxi-column>
                <div *dxTemplate="let row of 'surveyQuantity'">
                  <mat-form-field>
                    <input autocomplete="off" readonly matInput placeholder="" type="number" #input
                      [(ngModel)]="row.data.quantityUsedMs13" name="quantityUsedMs13_{{row.rowIndex}}">
                  </mat-form-field>
                  {{row.data.quantityUnit}}
                </div>
              </dx-data-grid>                
            </div>
        </form>        
    </mat-card-content>
    <div class="flex flex-col items-center p10 pb-10">
        <div class="basis-1/2 f18 flex" style="gap: 8px">
          <button mat-raised-button color="accent" (click)="createMs24()"
            [disabled]="!FormValidateItem.valid">
            สร้าง มส.24
          </button>
          <button mat-raised-button [mat-dialog-close]="true">
            ปิด
          </button>
        </div>
      </div>    
</mat-card>
