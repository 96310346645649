import { Component, OnInit, AfterViewInit, ViewChild, Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { GetLogMs24Staff } from "app/business/interface/app/app-ms24.interface";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { LogDocumentActionDetailModalComponent } from "../../log-document-action-detail-modal/log-document-action-detail-modal.component";

@Component({
  selector: 'app-ms24-record-log-detail',
  templateUrl: './ms24-record-log-detail.component.html',
  styleUrls: ['./ms24-record-log-detail.component.css']
})
export class Ms24RecordLogDetailComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "index",
    "logDate",
    "logDescription",
    "logBy",
    "action",
  ];
  clickBody: boolean = false;
  form: any = {};
  datas: any = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMs24Service: AppMs24Service,
  ) {
    console.log(data);
    this.datas = data;
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  loadData() {
    var request = {
      ms24HId: this.datas.id
    };

    this.appMs24Service.getMs24PerDocumentActionHistoryList(request).then((res: any) => {
      if (res === 'error') {
        return;
      } else {
        if (res === null) {
          return;
        } else {
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    })
  }

  openLog(e) {
    this.dialog.open(LogDocumentActionDetailModalComponent, {
      width: '70vw',
      disableClose: true,
      data: e
    });
  }
}
