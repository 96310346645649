<mat-toolbar class="bgGray">
  <button
    mat-icon-button
    class="bglightgrey"
    routerLink="/page/surveyor-search/surveyor-search"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
  <button mat-icon-button class="bglightgrey" (click)="loadData(ID)">
    <mat-icon>cached</mat-icon>
  </button>
  <button mat-icon-button class="bglightgrey" (click)="saveRemark(ID)" disabled>
    <mat-icon>save</mat-icon>
  </button>
  <div class="w-full text-right text-xl">
    <strong>รายชื่อผู้ประกอบธุรกิจตรวจสอบมาตรฐานสินค้า</strong>
  </div>
</mat-toolbar>
<div class="flex flex-col p-2.5">
  <div style="padding: 20px 30px 0px 30px">
    <mat-card>
      <div class="flex flex-col p-5">
        <div class="flex flex-col">
          <fieldset class="mt-5 border-2 border-solid rounded-lg">
            <legend class="ml-2.5 pr-2.5">
              แก้ไขข้อมูลผู้ประกอบธุรกิจตรวจสอบมาตรฐาน
            </legend>
            <div class="px-10">
              <div class="flex flex-col pt-5">
                <div class="flex flex-row">
                  <div class="basis-[20%] self-center text-right pr-2.5">
                    ใบทะเบียนเลขที่ ผธ. :
                  </div>
                  <div class="basis-[80%]">
                    <mat-form-field class="w-1/5">
                      <input
                        type="text"
                        matInput
                        readonly
                        #input
                        [(ngModel)]="form.ptNo"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-col pt-2.5">
                <div class="flex flex-row">
                  <div class="basis-[20%] self-center text-right pr-2.5">
                    ชื่อบริษัทภาษาไทย :
                  </div>
                  <div class="basis-[80%]">
                    <mat-form-field class="w-2/5">
                      <input
                        type="text"
                        matInput
                        readonly
                        #input
                        [(ngModel)]="form.nameTH"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-col pt-2.5">
                <div class="flex flex-row">
                  <div class="basis-[20%] self-center text-right pr-2.5">
                    ชื่อบริษัทภาษาอังกฤษ :
                  </div>
                  <div class="basis-[80%]">
                    <mat-form-field class="w-2/5">
                      <input
                        type="text"
                        matInput
                        readonly
                        #input
                        [(ngModel)]="form.nameEN"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-col pt-2.5">
                <div class="flex flex-row">
                  <div class="basis-[20%] self-center text-right pr-2.5">
                    ตั้งอยู่เลขที่ :
                  </div>
                  <div class="basis-[80%]">
                    <mat-form-field class="w-2/5">
                      <input
                        type="text"
                        matInput
                        readonly
                        #input
                        [(ngModel)]="form.addr"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-col pt-2.5">
                <div class="flex flex-row">
                  <div class="basis-[20%] self-center text-right pr-2.5">
                    แขวง/ตำบล :
                  </div>
                  <div class="basis-[35%]">
                    <mat-form-field class="w-3/5">
                      <input
                        type="text"
                        matInput
                        readonly
                        #input
                        [(ngModel)]="form.district"
                      />
                    </mat-form-field>
                  </div>
                  <div class="basis-[10%] self-center text-right pr-2.5">
                    เขต/อำเภอ :
                  </div>
                  <div class="basis-[35%]">
                    <mat-form-field class="w-3/5">
                      <input
                        type="text"
                        matInput
                        readonly
                        #input
                        [(ngModel)]="form.subprovince"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-col pt-2.5">
                <div class="flex flex-row">
                  <div class="basis-[20%] self-center text-right pr-2.5">
                    จังหวัด :
                  </div>
                  <div class="basis-[35%]">
                    <mat-form-field class="w-3/5">
                      <input
                        type="text"
                        matInput
                        readonly
                        #input
                        [(ngModel)]="form.province"
                      />
                    </mat-form-field>
                  </div>
                  <div class="basis-[10%] self-center text-right pr-2.5">
                    รหัสไปรษณีย์:
                  </div>
                  <div class="basis-[35%]">
                    <mat-form-field class="w-3/5">
                      <input
                        type="text"
                        matInput
                        readonly
                        #input
                        [(ngModel)]="form.postcode"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-col pt-2.5">
                <div class="flex flex-row">
                  <div class="basis-[20%] self-center text-right pr-2.5">
                    หมายเลขโทรศัพท์ :
                  </div>
                  <div class="basis-[35%]">
                    <mat-form-field class="w-3/5">
                      <input
                        type="text"
                        matInput
                        readonly
                        #input
                        [(ngModel)]="form.phone"
                      />
                    </mat-form-field>
                  </div>
                  <div class="basis-[10%] self-center text-right pr-2.5">
                    โทรสาร :
                  </div>
                  <div class="basis-[35%]">
                    <mat-form-field class="w-3/5">
                      <input
                        type="text"
                        matInput
                        readonly
                        #input
                        [(ngModel)]="form.fax"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <!-- สินค้าที่ได้รับอนุญาต -->
              <div class="flex flex-col pt-2.5 pb-2.5">
                <div class="flex flex-row">
                  <div class="basis-full">
                    <fieldset class="border-2 border-solid rounded-lg">
                      <legend class="ml-2.5 pr-2.5">
                        สินค้าที่ได้รับอนุญาต
                      </legend>
                      <div class="px-10 pb-10">
                        <div class="flex flex-row">
                          <div class="flex basis-1/2 f18">
                            <section class="basis-3/4">
                              <mat-checkbox [checked]="form.rice =='Y' ? true : false" [class.mat-checkbox-disabled]="false" disabled >ข้าวหอมมะลิไทย</mat-checkbox>
                            </section>
                          </div>
                          <div class="flex basis-1/2 f18">
                            <section class="basis-3/4">
                              <mat-checkbox [checked]="form.cassava =='Y' ? true : false" disabled>ผลิตภัณฑ์มันสำปะหลัง</mat-checkbox>
                            </section>
                          </div>
                        </div>
                        <div class="flex flex-row">
                          <div class="flex basis-1/2 f18">
                            <section class="basis-3/4">
                              <mat-checkbox [checked]="form.corn =='Y' ? true : false" disabled>ข้าวโพด</mat-checkbox>
                            </section>
                          </div>
                          <div class="flex basis-1/2 f18">
                            <section class="basis-3/4">
                              <mat-checkbox [checked]="form.millet =='Y' ? true : false" disabled>ข้าวฟ่าง</mat-checkbox>
                            </section>
                          </div>
                        </div>
                        <div class="flex flex-row">
                          <div class="flex basis-1/2 f18">
                            <section class="basis-3/4">
                              <mat-checkbox [checked]="form.greenbean =='Y' ? true : false" disabled>ถั่วเขียว</mat-checkbox>
                            </section>
                          </div>
                          <div class="flex basis-1/2 f18">
                            <section class="basis-3/4">
                              <mat-checkbox [checked]="form.blackmatpe =='Y' ? true : false" disabled>ถั่วเขียวผิวดำ</mat-checkbox>
                            </section>
                          </div>
                        </div>
                        <div class="flex flex-row">
                          <div class="flex basis-1/2 f18">
                            <section class="basis-3/4">
                              <mat-checkbox [checked]="form.fishmeal =='Y' ? true : false" disabled>ปลาป่น</mat-checkbox>
                            </section>
                          </div>
                          <div class="flex basis-1/2 f18">
                            <section class="basis-3/4">
                              <mat-checkbox [checked]="form.fluff =='Y' ? true : false" disabled>ปุยนุ่น</mat-checkbox>
                            </section>
                          </div>
                        </div>
                        <div class="flex flex-row">
                          <div class="flex basis-1/2 f18">
                            <section class="basis-3/4">
                              <mat-checkbox [checked]="form.whiterice =='Y' ? true : false" disabled>ข้าวขาว</mat-checkbox>
                            </section>
                          </div>
                          <div class="flex basis-1/2 f18">
                            <section class="basis-3/4">
                              <mat-checkbox [checked]="form.tapioca =='Y' ? true : false" disabled>แป้งมันสำปะหลัง</mat-checkbox>
                            </section>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div class="flex flex-col pt-5 pb-5">
                <div class="flex flex-row">
                  <div class="basis-[20%] self-center text-right pr-2.5">
                    หมายเหตุ :
                  </div>
                  <div class="basis-[80%]">
                    <mat-form-field class="w-2/5">
                      <textarea
                        type="text"
                        rows="4"
                        matInput
                        #input
                        [(ngModel)]="form.remark"
                      ></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </mat-card>
  </div>
</div>
