<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">

  <div class="menu justify-between flex items-stretch flex-row gap-5 px-20 pr-10 pl-10">
    <div class="gap-2.5 flex flex-row">
      <!-- <div *ngIf="checkButton('BT080')"> -->
      <button mat-raised-button color="accent" *ngIf="checkButton('BT067')" (click)="openMrConfig()">
        <div class="flex items-center">
          <span>Connect MR</span>
        </div>
      </button>
      <!-- </div> -->

    </div>
    <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">

      <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0" style="flex: 0 40%;">
        <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
          <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input [(ngModel)]="form.search"
            name="search" #search="ngModel" (keyup)="applyFilter($event.target.value)">
          <button matSuffix mat-icon-button aria-label="ค้นหา">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
        <div class="flex items-center">
          <span>เงื่อนไขการค้นหา</span>
          <mat-icon>expand_more</mat-icon>
        </div>
      </button>
      <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
        <div class="pl-2 pr-2 gap-4 flex flex-col" (click)="stopPropagation($event)">
          <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>Company Id | รหัสบริษัท</mat-label>
              <input autocomplete="off" type="text" matInput placeholder="รหัสบริษัท" matInput #input
                [(ngModel)]="form.cpId" name="cpId" #bankAccountName="ngModel">
            </mat-form-field>
          </div>
          <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>Company Tax No. | เลขที่ประจำตัวผู้เสียภาษี</mat-label>
              <input autocomplete="off" type="text" matInput placeholder="เลขที่ประจำตัวผู้เสียภาษี" matInput #input
                [(ngModel)]="form.companyTaxId" name="companyTaxId" #accTaxNo="ngModel">
            </mat-form-field>
          </div>
          <div class="justify-evenly flex gap-2.5 items-center flex-row">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>ภาษาไทย</mat-label>
              <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัท" matInput #input
                [(ngModel)]="form.companyNameTh" name="companyNameTh" #bankAccountNo="ngModel">
            </mat-form-field>
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>English</mat-label>
              <input autocomplete="off" type="text" matInput placeholder="Company Name" matInput #input
                [(ngModel)]="form.companyNameEn" name="companyNameEn" #bankAccountName="ngModel">
            </mat-form-field>
          </div>
          <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0">
            <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
              <mat-label>เบอร์โทร</mat-label>
              <input autocomplete="off" type="text" matInput placeholder="เบอร์โทร" matInput #input
                [(ngModel)]="form.phone" name="phone" #accTaxNo="ngModel">
            </mat-form-field>
          </div>
          <div class="flex flex-row">
            <div class="w-full">
              <mat-form-field class="my-form-field formm w100">
                <mat-label>ประเภทธุรกิจ</mat-label>
                <mat-select [(ngModel)]="form.typeId">
                  <mat-option *ngFor="let comType of companyTypeList" [value]="comType.companyTypeId">
                    {{comType.companyTypeName}}
                  </mat-option>
                </mat-select>

              </mat-form-field>
            </div>

          </div>
          <div class="flex justify-end pt-2">
            <div>
              <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                เคลียร์
              </button>
              <button mat-raised-button color="accent" (click)="onSearch()" style="flex:0 50%;">
                ค้นหา
              </button>
            </div>
          </div>
        </div>
      </mat-menu>
    </div>

  </div>
  <!-- </div> -->
  <div class="mat-elevation-z8 ">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table matSort style="width: 100%;" [dataSource]="dataSource"
      (matSortChange)="announceSortChange($event)">

      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ลำดับ </th>
        <td mat-cell *matCellDef="let i = index">
          {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
        </td>
      </ng-container>

      <ng-container matColumnDef="companyTaxId">
        <th mat-header-cell *matHeaderCellDef class="text-left"> เลขที่ประจำตัวผู้เสียภาษี </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.companyTaxId}} </td>
      </ng-container>

      <ng-container matColumnDef="cpId">
        <th mat-header-cell *matHeaderCellDef class="text-left"> รหัสบริษัท </th>
        <td mat-cell *matCellDef="let element" class="text-left"> {{element.companyCode}} </td>
      </ng-container>

      <ng-container matColumnDef="companyNameTh">
        <th mat-header-cell *matHeaderCellDef> ชื่อบริษัท </th>
        <td mat-cell *matCellDef="let element"> {{element.companyNameTh}} </td>
      </ng-container>

      <ng-container matColumnDef="companyNameEn">
        <th mat-header-cell *matHeaderCellDef> Company Name </th>
        <td mat-cell *matCellDef="let element"> {{element.companyNameEn}} </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef> เบอร์โทร </th>
        <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
      </ng-container>

      <ng-container matColumnDef="typeId">
        <th mat-header-cell *matHeaderCellDef> ประเภทธุรกิจ </th>
        <td mat-cell *matCellDef="let element"> {{element.typeId}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-left"> การจัดการ </th>
        <td mat-cell *matCellDef="let element;let i = index" class="text-left">
          <div class="flex flex-row" style="justify-content: start;">
            <!-- <div *ngIf="checkButton('BT081')"> -->
            <button mat-icon-button matTooltip="รายละเอียด" *ngIf="checkButton('BT068')" matTooltipPosition="above"
              routerLink="/page/config-company/company-profile/" [queryParams]="{
                      companyId: element.cpId
                    }">
            <i class="ti ti-file-description"></i>
          </button>
            <!-- <button mat-icon-button matTooltip="ลบ" (click)="delete(element)" matTooltipPosition="above">
                  <mat-icon>delete</mat-icon>
              </button> -->
            <!-- </div> -->
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" (page)="_page($event)" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>

</div>