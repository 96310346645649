import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppShippingService } from "app/business/service/app/app-shipping.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { ShippingDetailCompanyComponent } from "../shipping-detail-company/shipping-detail-company.component";
import { IResGetDataShipping } from "app/business/interface/app/app-shipping.interface";

@Component({
  selector: 'app-shipping-inquiry',
  templateUrl: './shipping-inquiry.component.html',
  styleUrls: ['./shipping-inquiry.component.css']
})
export class ShippingInquilyComponent implements OnInit {
  searchForm: any ={};
  form: any = {};
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "shippingName",
    "active",
    "dateAdd",
    "expireDate",
    "action",
  ];
  dataView :IResGetDataShipping[]=[];
  CompaynyIdToken : number;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppShippingService: AppShippingService,) 
    { 
      const jwt = localStorage.getItem('userProfile');
      const user = jwt? JSON.parse(jwt) : {};
      this.CompaynyIdToken = user.cpId;
    }


  ngOnInit() {
    this.searchForm.shippingName = '';
    this.loadData();

  }
  async loadData(): Promise<void> {
    const formData = {
      CompanyId: this.CompaynyIdToken,
      CompanyName: this.searchForm.shippingName,
    };
    console.log(formData);
    this.AppShippingService.ShippingList(formData).then((res) => {
      if(res){
        this.dataView = res;
        this.dataSource = new MatTableDataSource(this.dataView);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  stopPropagation(event) {
    event.stopPropagation();
  }


  DetailCompany(idCompany) {
    const dialogRef = this.dialog.open(ShippingDetailCompanyComponent, {
      disableClose: false,
      width: '60vw',
      data: idCompany,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.loadData();
      }
    });
  }

  


}


