import { Injectable } from "@angular/core";
import { ApiGroupListService } from "../api/api-group.service";

@Injectable({
    providedIn: "root",
  })
  export class AppGroupListService {
    constructor(private ApiGroupListService: ApiGroupListService) {}
  
    getGroupList(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiGroupListService.getGroupList(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
  
    AdjustGroup(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiGroupListService.AdjustGroup(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    CompanyDropdown(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiGroupListService.CompanyDropdown(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    ListGroup(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiGroupListService.ListGroup(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

   
    GetStaffDataGroupList(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiGroupListService.GetStaffDataGroupList(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
    
    SaveDataConfigGroup(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiGroupListService.SaveDataConfigGroup(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    GetDataConfGroup(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiGroupListService.GetDataConfGroup(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
  }
  