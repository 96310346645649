<!-- <div class="flex justify-end">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div> -->
<mat-card class="example-card">
  <mat-card-header class="fontTitleHeader">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal"> รายการสินค้า (Goods Item)</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <form #FormValidateItem="ngForm">
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div class="basis-1/3 gap-1 p10">
            <div class="flex flex-row">
              <mat-form-field class="w100">
                <mat-label>Invoice No </mat-label>
                <input [ngModelOptions]="{standalone: true}" [disabled]="disabled" required type="text" matInput
                  placeholder="Invoice No" [(ngModel)]="form.invoiceNo" name="invoiceNo" #invoiceNo="ngModel">
              </mat-form-field>
            </div>
          </div>
          <div class="basis-1/3 gap-1 p10">
            <mat-form-field class="w100">
              <mat-label>Invoice Date </mat-label>
              <input required [disabled]="disabled" [ngModelOptions]="{standalone: true}" placeholder="Invoice Date"
                matInput [matDatepicker]="invoiceDates" [(ngModel)]="form.invoiceDate" name="invoiceDate"
                #invoiceDate="ngModel" readonly>
              <mat-datepicker-toggle matSuffix [for]="invoiceDates"></mat-datepicker-toggle>
              <mat-datepicker #invoiceDates></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="basis-1/3 gap-1 p10">
            <mat-form-field class="w100">
              <mat-label>ลำดับรายการสินค้าใน Invoice</mat-label>
              <input [disabled]="disabled" required type="number" matInput placeholder="ลำดับรายการสินค้าใน Invoice"
                [(ngModel)]="form.invoiceItem" [ngModelOptions]="{standalone: true}" name="invoiceItem"
                #invoiceItem="ngModel">
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="w100 f18 p10">
            <mat-form-field class="w100">
              <mat-label>รายการสินค้า</mat-label>
              <input [disabled]="disabled" [ngModelOptions]="{standalone: true}" required type="text" matInput
                placeholder="รายการสินค้า" [(ngModel)]="form.goodsDescription" name="goodsDescription"
                #goodsDescription="ngModel">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div [formGroup]="grpForm" class="basis-2/3 p10">
            <mat-form-field class="w100">
              <mat-label>พิกัดศุลกากร (Tariff Code.Stat Code) เช่น 012345678910.000 </mat-label> <!--o2 = 68-->
              <input type="text" required matInput [disabled]="disabled" formControlName="tariffSearch" [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectionTariffChange($event)">
                <mat-option *ngFor="let tarff of filteredOptionsTariff$" [value]="tarff">
                  {{tarff.desc}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div [formGroup]="grpForm" class="basis-1/3 p10">
            <mat-form-field class="w100">
              <mat-label></mat-label> <!--o2 = 68-->
              <input type="text" required matInput [disabled]="disabled" formControlName="tariffTypeSearch"
                [matAutocomplete]="autos">
              <mat-autocomplete #autos="matAutocomplete" (optionSelected)="selectionTariffTypeChange($event)">
                <mat-option *ngFor="let tarffType of filteredOptionsTariffType$" [value]="tarffType">
                  {{tarffType.desc}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <!-- <mat-form-field class="w100">
            <input  required type="text" matInput placeholder=""
              [(ngModel)]="form.statisticalCode" name="statisticalCode" #statisticalCode="ngModel">
          </mat-form-field> -->
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div class="flex" style="align-items: center; justify-items: center;width: 5%;padding-top: 10px">
            <mat-checkbox [checked]="form.noneQtys" [disabled]="disabled" [ngModelOptions]="{standalone: true}"
              (change)="setCheckNoneQty($event.checked)" [(ngModel)]="form.noneQtys" class="p10"></mat-checkbox>
          </div>
          <div class="flex p10" style="width: 50%; gap: 10px;">
            <div style="width: 35%">
              <mat-form-field class="w100">
                <mat-label>ปริมาณ-หน่วย </mat-label> <!--o2 = 68-->
                <input type="text" (focus)="onFocusChang()" (blur)="onBlurChang()"
                  [disabled]="disabled || form.noneQtyOnes" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="calNetWeightPerUnit($event)" [required]="form.noneQtys" matInput
                  placeholder="ปริมาณ-หน่วย" [(ngModel)]="form.quantity" name="quantity" #quantity="ngModel">
              </mat-form-field>
            </div>
            <div style="flex-grow: 1;width: 65%">
              <mat-form-field class="my-form-field formm w100">
                <mat-label> </mat-label>
                <mat-select [disabled]="disabled || form.noneQtyOnes" [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="form.quantityUnit" name="quantityUnit" #quantityUnit="ngModel">
                  <mat-option *ngFor="let qty of qtyUnitList" [value]="qty.code">
                    {{qty.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="p10" style="width: 20%">
            <mat-form-field class="w100">
              <mat-label> น้ำหนักรวม </mat-label><!--o2 = 70-->
              <input required type="text" [disabled]="disabled" [ngModelOptions]="{standalone: true}" matInput
                placeholder="น้ำหนัก" (focus)="onFocusNetWeight()" (blur)="onBlurNetWeight()"
                [(ngModel)]="form.netWeight" (ngModelChange)="calNetWeightPerUnit($event); calFOB(null)" name="netWeight"
                #netWeight="ngModel">
            </mat-form-field>
          </div>
          <div class="p10" style="width: 10%">
            <mat-form-field class="w100">
              <mat-label></mat-label><!--o2 = 71-->
              <input readonly type="text" [disabled]="disabled" [ngModelOptions]="{standalone: true}" matInput
                placeholder="" [(ngModel)]="form.netWeightUnit" name="netWeightUnit" #netWeightUnit="ngModel">
            </mat-form-field>
          </div>
          <div class="p10" style="width: 20%">
            <mat-form-field class="w100">
              <mat-label>น้ำหนักเฉพาะต่อหน่วย</mat-label>
              <input readonly type="text" [disabled]="disabled" matInput placeholder="น้ำหนักเฉพาะต่อหน่วย"
                [(ngModel)]="form.netWeightPerUnit" [ngModelOptions]="{standalone: true}" name="netWeightPerUnit"
                #netWeightPerUnit="ngModel" (focus)="onFocusPerWeight()" (blur)="onBlurPerWeight()">
            </mat-form-field>
          </div>
          <ng-container *ngIf="data.page == 'ms13'">
            <div class="p10" style="width: 20%">
              <mat-form-field class="w100">
                <mat-label>น้ำหนักรวมต่อหน่วย</mat-label>
                <input type="text" required [disabled]="disabled" matInput placeholder="น้ำหนักรวมต่อหน่วย"
                  [(ngModel)]="form.grossWeightPerUnit" [ngModelOptions]="{standalone: true}" name="grossWeightPerUnit"
                  #grossWeightPerUnit="ngModel" (focus)="onFocusGrossPerWeight()" (blur)="onBlurGrossPerWeight()">
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div class="flex" style="align-items: center; justify-items: center;width: 5%;padding-top: 20px">
            <mat-checkbox (change)="setCheckNoneQtyOne($event.checked)" [disabled]="disabled"
              [(ngModel)]="form.noneQtyOnes" [ngModelOptions]="{standalone: true}" class="p10"></mat-checkbox>
          </div>
          <div class="p10" style="width: 50%">
            <mat-form-field class="w100">
              <mat-label>หนึ่งจำนวน</mat-label><!--o2 = 74-->
              <mat-select [(ngModel)]="form.netQty" [required]="form.noneQtyOnes" name="netQty" #netQty="ngModel"
                [ngModelOptions]="{standalone: true}" [disabled]="disabled || form.noneQtys">
                <mat-option *ngFor="let qtyOne of qtyUnitOneList" [value]="qtyOne.code">
                  {{qtyOne.name}}
                </mat-option>
              </mat-select>
              <mat-error>น้ำหนัก</mat-error>
            </mat-form-field>
          </div>
          <div class="p10" style="width: 20%">
            <mat-form-field class="w100">
              <mat-label>ราคาต่อหน่วย (ต่อเมตริกตัน)</mat-label><!--o2 = 75-->
              <input required type="text" matInput placeholder="ราคาต่อหน่วย" [disabled]="disabled"
                [(ngModel)]="form.unitPrice" [ngModelOptions]="{standalone: true}" (focus)="onFocusUnitPrice()"
                (blur)="onBlurUnitPrice()" (ngModelChange)="calFOB($event)" name="unitPrice" #unitPrice="ngModel">
            </mat-form-field>
          </div>
          <div class="p10" style="width: 10%">
            <mat-form-field class="w100">
              <mat-label> </mat-label><!--o2 = 76-->
              <input type="text" readonly matInput [disabled]="disabled" [ngModelOptions]="{standalone: true}"
                placeholder="" [(ngModel)]="form.currency" name="currency" #currency="ngModel">
            </mat-form-field>
          </div>
          <div class="p10" style="width: 20%">
            <mat-form-field class="w100">
              <mat-label>FOB Value (THB) </mat-label><!--o2 = 77-->
              <input readonly [disabled]="disabled" [ngModelOptions]="{standalone: true}" type="text" matInput
                placeholder="FOB Value (THB)" [(ngModel)]="form.fobValueBaht" name="fobValueBaht"
                #fobValueBaht="ngModel" (focus)="onFocusFob()" (blur)="onBlurFob()">
            </mat-form-field>
          </div>
          <ng-container *ngIf="data.page == 'ms13'">
            <div class="p10" style="width: 20%">
              <mat-form-field class="w100">
                <mat-label>FOB Value (USD) </mat-label><!--o2 = 77-->
                <input [disabled]="disabled" [ngModelOptions]="{standalone: true}" type="text" matInput
                  placeholder="FOB Value (USD)" [(ngModel)]="form.fobValueUsd" name="fobValueUsd" #fobValueUsd="ngModel"
                  (focus)="onFocusFobUsd()" (blur)="onBlurFobUsd()">
              </mat-form-field>
            </div>
          </ng-container>
        </div>
      </div>
    </form>
  </mat-card-content>
  <div class="flex flex-col items-center p10">
    <div class="flex basis-1/2 f18" style="gap: 5px;">
      <button mat-raised-button style="width: 100px;" *ngIf="data.type != 'info'" color="accent" (click)="saveItem()">
        ตกลง
      </button>
      <button mat-raised-button style="width: 100px;" [mat-dialog-close]>
        <ng-container *ngIf="data.type != 'info'">ยกเลิก</ng-container>
        <ng-container *ngIf="data.type == 'info'">ปิด</ng-container>
      </button>
    </div>
  </div>
</mat-card>