import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { AppMs13Service } from "app/business/service/app/app-ms13.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { ExportDocumentMs24Dropdown } from "app/business/interface/app/app-ms24.interface";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn} from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS,MomentDateAdapter,MAT_MOMENT_DATE_ADAPTER_OPTIONS,} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import moment from "moment";
import { AppItemtypeService } from "app/business/service/app/app-itemtype.service";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-ms13-report-export',
  templateUrl: './ms13-report-export.component.html',
  styleUrls: ['./ms13-report-export.component.css'],
  providers: [
    { provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
})
export class Ms13ReportExportComponent implements OnInit {

  form: any = {};
  FormDoc : FormGroup;
  FormDocExportMs13 : FormGroup;
  dataProduct : any[] = [];
  dataStatus : any[] = [];
  typeproduct : string = ' ';
  typeproductForm1 : string = ' ';
  statusList : string = ' ';
  SelectDataNameDocumentMs24: ExportDocumentMs24Dropdown[] = [
    {id:1, value:'รายงานแสดงการยื่นขอออก มส.13'},
    {id:2, value:'รายงาน มส.13 ทั้งหมดที่ได้รับอนุมัติและได้มาขอออก มส.24'},
    {id:3, value:'รายงาน มส.13 ทั้งหมดที่ได้รับอนุมัติและยังไม่มาขอออก มส.24'},
    {id:4, value:'รายงาน มส.13 ทั้งหมดที่เซอร์เวเยอร์ได้ใส่น้ำหนักผลการตรวจสอบแล้วและยังไม่มาขอออก มส.24'},
    {id:5, value:'สรุปยอดการอนุมัติ มส.13'},
    {id:6, value:'ทะเบียนคุมการออกเอกสารงานมาตรฐานสินค้า มส.13 ในระบบ'},
    {id:7, value:'รายงานสรุปจำนวนชุด มส.13 ของแต่ละบริษัทที่ผ่านการอนุมัติแล้ว'},
    {id:8, value:'สรุปยอดการปริ้นท์ มส.13'}     
  ];
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
 


  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppMs24Service: AppMs24Service,
    private ConfirmDialogService: ConfirmDialogService,
    private AlertMessageService : AlertMessageService,
    private formBuilder: FormBuilder,
    private AppItemtypeService: AppItemtypeService,
    private AppMasterService: AppMasterService,
    private AppMs13Service : AppMs13Service
  ) { }

  ngOnInit() {
    this.form.typeproduct = 'ALL';
    this.form.statusList = 'ALL';
    this.form.typeproductForm1 = 'ALL';
    this.checkFormGroup();
    this.loadDataProduct();
    this.loadDataStatus();
  }

  checkFormGroup (){
  this.FormDoc = new FormGroup({
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required)
  });
  this.FormDocExportMs13= new FormGroup({
    startDateExportMs13: new FormControl('', Validators.required),
    endDateExportMs13: new FormControl('', Validators.required)
  });
  }
  
  
  Loadexcel(){
    this.typeproduct = this.form.typeproduct;
    if(this.form.typeproduct == 'ALL') {
      this.typeproduct = ' ';
    }

    this.typeproductForm1 = this.form.typeproductForm1;
    if(this.form.typeproductForm1 == 'ALL') {
      this.typeproductForm1 = ' ';
    }

    this.statusList = this.form.statusList;
    if(this.form.statusList == 'ALL') {
      this.statusList = ' ';
    }
    switch (this.form.optionSelect){
      case 1 : this.MS13ExportExcelStaffDocumentRequest(); break;
      case 2 : this.MS13ExportExcelStaffAllApprove(); break;
      case 3 : this.MS13ExportExcelStaffAllApproveNoMs24(); break;
      case 4 : this.MS13ExportExcelStaffSurveyer(); break;  
      case 5 : this.MS13ExportExcelStaffCountBotReview(); break;
      case 6 : this.MS13ExportExcelStaffControl(); break;
      case 7 : this.MS13ExportExcelStaffRice(); break;
      case 8 : this.MS13ExportExcelStaffSummaryPrint(); break;  
    }

  }
      async MS13ExportExcelStaffDocumentRequest(): Promise<void> {
        const dateSelectStart = moment(this.form.selectedStartDate).format('YYYY-MM-DD');
        const dateSelectEnd = moment(this.form.selectedEndDate).format('YYYY-MM-DD');
        const formdatas = {
          "startDate" : dateSelectStart,
          "endDate" : dateSelectEnd,
          "productType" : this.typeproductForm1,
          "statusType" : this.statusList
        };
        console.log(formdatas);
        await this.AppMs13Service
          .MS13ExportExcelStaffDocumentRequest(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("รายงานแสดงการยื่นขอออก มส13" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }

      async MS13ExportExcelStaffAllApprove(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDate,
          "endDate" : this.form.selectedEndDate,
          "productType" : this.typeproduct
        };
        await this.AppMs13Service
          .MS13ExportExcelStaffAllApprove(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("รายงาน มส13 ทั้งหมดที่ได้รับอนุมัติและได้มาขอออก มส24" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }
      async MS13ExportExcelStaffAllApproveNoMs24(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDate,
          "endDate" : this.form.selectedEndDate,
          "productType" : this.typeproduct
        };
        await this.AppMs13Service
          .MS13ExportExcelStaffAllApproveNoMs24(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("รายงาน มส13 ทั้งหมดที่ได้รับอนุมัติและยังไม่มาขอออก มส24" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }
      async MS13ExportExcelStaffSurveyer(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDate,
          "endDate" : this.form.selectedEndDate,
          "productType" : this.typeproduct
        };
        await this.AppMs13Service
          .MS13ExportExcelStaffSurveyer(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("รายงาน มส13 ทั้งหมดที่เซอร์เวเยอร์" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }
      async MS13ExportExcelStaffCountBotReview(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDate,
          "endDate" : this.form.selectedEndDate,
        };
        await this.AppMs13Service
          .MS13ExportExcelStaffCountBotReview(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("สรุปยอดการอนุมัติ มส13" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }

      async MS13ExportExcelStaffControl(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDate,
          "endDate" : this.form.selectedEndDate,
          "productType" : this.typeproduct
        };
        await this.AppMs13Service
          .MS13ExportExcelStaffControl(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("ทะเบียนคุมการออกเอกสารงานมาตรฐานสินค้า มส13 ในระบบ" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }
      async MS13ExportExcelStaffRice(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDate,
          "endDate" : this.form.selectedEndDate,
        };
        await this.AppMs13Service
          .MS13ExportExcelStaffRice(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("รายงานสรุปจำนวนชุด มส13 ของแต่ละบริษัท" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }
      async MS13ExportExcelStaffSummaryPrint(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDate,
          "endDate" : this.form.selectedEndDate,   
        };
        await this.AppMs13Service
          .MS13ExportExcelStaffSummaryPrint(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("สรุปยอดการปริ้นท์ มส13" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }

      exportExcelData(fileName, data) {
        var blob = new Blob([this.stringToByteArray(atob(data))]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
      }
      stringToByteArray(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      async loadDataProduct(): Promise<void> {
        //send data to api
        const formData = {
          
        };
         await this.AppItemtypeService.getDataDetailProduct(formData).then((res)=>{ 
          this.dataProduct = res
        });
      }
      async loadDataStatus(): Promise<void> {
        //send data to api
        const formData = {
          "statusID" : 0
        };
         await this.AppMasterService.getStatus(formData).then((res)=>{ 
          this.dataStatus = res
        });
      }

}
