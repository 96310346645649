import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-company-address-grid',
  templateUrl: './company-address-grid.component.html',
  styleUrls: ['./company-address-grid.component.css']
})

export class CompanyAddressGridComponent implements OnInit, AfterViewInit {
  form: any = {};
  companyTypeList: any[] = [];
  displayedColumns: string[] = [
    'branch',
    'tcaAddressStreet',
    'subDistrictName',
    'districtName',
    'province',
    'postCode',
    'phone',
    'fax',
    'tcaTypeComAddName',
  ]
  dataSource = new MatTableDataSource<any>()
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Input()
  set inputData(value) {
    this.dataSource = new MatTableDataSource(value);
  }
  get inputData(): any {
    return this.dataSource
  }

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  save() {
  }

}
