import { products } from './../../../../mock-api/apps/ecommerce/inventory/data';
import { AppMs13Service } from './../../../service/app/app-ms13.service';
import { AppO2Service } from "./../../../service/app/app-o2.service";
import { filter } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { MatSort, Sort } from "@angular/material/sort";
import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { MatTableDataSource } from "@angular/material/table";
import moment from "moment";
import { FormGroup, NgForm } from "@angular/forms";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { MatDialog } from "@angular/material/dialog";
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AppMs24Service } from 'app/business/service/app/app-ms24.service';
import { environment } from 'environments/environment';
import { AppCompanyService } from 'app/business/service/app/app-company.service';
import { AppExternalService } from 'app/business/service/app/app-external.service';
import _ from 'underscore';

@Component({
  selector: "app-create-o2-modal",
  templateUrl: "./create-o2-modal.component.html",
  styleUrls: ["./create-o2-modal.component.css"]
})
export class CreateO2ModalComponent implements OnInit {
  form: any = {};
  formMs13: any = {};
  confirmMs24Form: any = {};
  pages: any;
  productList: any = [];
  refMs13List: any = [];
  statusList: any = [];
  currentDate: Date = new Date();
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  dataMs13Source = new MatTableDataSource<any>();
  dataMs13List: any = [];
  count: number;
  displayedColumns: string[] = [
    "number",
    "goodsDescription",
    "grossWeightNet",
    "grossQty",
  ];
  transModeList: any = [];
  ms13Id: any;
  ms24Id: any;
  productId: any;
  shippingMaskList: any = [];
  buyerCountryForm: FormGroup;
  surveyorList: any = [];
  companyProfile: any = {}
  allowManualCreate: boolean = false;
  @ViewChild("createOnlineForm") createOnlineForm: NgForm;
  @ViewChild("createManualForm") createManualForm: NgForm;
  @ViewChild("createMs13Form") createMs13Form: NgForm;
  @ViewChild("createMs24Form") createMs24Form: NgForm;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private router: Router,
    private route: ActivatedRoute,
    private appMasterService: AppMasterService,
    private appMs13Service: AppMs13Service,
    private appO2Service: AppO2Service,
    public dialogRef: MatDialogRef<CreateO2ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private alertMessageService: AlertMessageService,
    private dialog: MatDialog,
    private appMs24Service: AppMs24Service,
    private appCompanyService: AppCompanyService,
    private appExternalService: AppExternalService,
  ) {
    this.appMasterService.companyData.subscribe(res => {
      this.companyProfile = res;
    });
  }

  ngOnInit() {
    const { page } = this.route.snapshot.queryParams;
    // console.log("createO2 ngOnInit", page);
    this.pages = this.data.page; //"ms13";
    // this.pages = page ;//"ms13";
    this.ms13Id = this.data.ms13HId;
    this.ms24Id = this.data.ms24HId;

    this.loadData();
  }

  async loadData() {
    // const formProduct = {
    //   companyID: Number(this.userProfile.cpId),
    //   productId: 0,
    //   isActive: "Y",
    // };
    // this.productList = await this.appMasterService.getProduct(formProduct);
    // if (this.pages == "o2") {
    //   this.productList = this.productList.filter(
    //     (f: { productId: number }) => f.productId == 3 || f.productId == 10
    //   );
    // }
    var rqCompany = {
      id: Number(this.userProfile.cpId)
    }

    this.appMasterService.getO2ManualMethodFlag().then(res => {
      if (res === 'error') {
        return;
      } else {
        var checker = _.first(res);

        if (checker.o2MethodValue == "Y") {
          this.allowManualCreate = true;
        } else {
          this.allowManualCreate = false;
        }
      }
    });

    this.appCompanyService.getCompanyById(rqCompany).then(res => {
      if (res === "error") {
        return;
      } else {
        this.form.taxNo = res.taxId;
      }
    });

    const formProduct = {
      page: this.pages,
      isActive: "Y",
    };

    await this.appMasterService.getProduct(formProduct).then((res: any) => {
      if (res == "error") {
      } else {
        this.productList = res;
        if (this.pages == "o2") {
          this.productList = this.productList.filter(
            (f: { productId: number }) => f.productId == 3 || f.productId == 10
          );
        } else {
          this.productList = this.productList.filter(
            (f: { productId: number }) => f.productId != 3 && f.productId != 10
          );
        }
      }
    });
    this.appMasterService.getCompanyByCompanyCode({ companyId: this.userProfile.cpId });
    const formStatus = {
      statusId: 0,
      isActive: "Y",
    };
    this.statusList = await this.appMasterService.getStatus(formStatus);
    if (this.ms13Id != null) {
      this.getdataMs13ById();
    }
    const rqTransMode = {
      ID: "",
    };
    await this.appMasterService.getTransMode(rqTransMode).then((res) => {
      this.transModeList = res;
    });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  dataListMs13() {
    const formMs13List = {
      productId: this.form.product,
    };
    this.appMs13Service.getListMs13No(formMs13List).then((res: any) => {
      if (res == "error") {
      } else {
        this.refMs13List = res;
      }
    });
  }

  setDate(val: { targetElement: { name: string }; value: moment.MomentInput }) {
    if (val.targetElement.name == "expireDate") {
      this.form.expireDate = moment(val.value).format("YYYY-MM-DD");
    } else {
      this.form.issueDate = moment(val.value).format("YYYY-MM-DD");
      this.form.maxExpireDate = moment(val.value)
        .add(30, "days")
        .format("YYYY-MM-DD");
      this.form.minExpireDate = moment(val.value)
        .add(-30, "days")
        .format("YYYY-MM-DD");
    }
  }

  onValid(e: { status: string }): boolean {
    return e.status == "VALID" ? false : true;
  }

  createMs13(): any {
    // 
    console.log("saveMs13>>>", this.companyProfile);
    var product = this.productList.find(x => x.productId == this.form.product);

    const formDataMS13H = {
      ownerId: this.userProfile.cpId,
      exporterId: this.userProfile.cpId,
      status: 1,
      registrationNoPk: this.companyProfile.exporterNo,

      typeDocument: product.docTypeId,
      goodsName: product.productName,
      typeGoods: product.docTypeName,

      // refDate: new Date(),
      exporterNo: this.companyProfile.exporterNo,
      exporter: this.companyProfile.exporterNo,
      companyTax: this.companyProfile.taxID,
      companyThName: this.companyProfile.nameTh,
      companyEnName: this.companyProfile.nameEn,
      exporterStreetandNo: this.companyProfile.addressStreet,
      exporterDistrict: this.companyProfile.district,
      exporterSubProvince: this.companyProfile.subProvince,
      exporterProvince: this.companyProfile.province,
      exporterPostcode: this.companyProfile.postcode,
      source: "M",
    };

    this.appMs13Service.saveMs13(formDataMS13H).then((res) => {
      if (res != "error") {
        this.router.navigate(["/page/o2/create-ms13"], {
          queryParams: {
            page: 'ms13',
            type: 'Edit',
            id: res.cmmS13H_ID,
            status: res.status
          }
        }).then((r) => {
          this.dialogRef.close();
        });;
      }
    });
  }
  
  checkDFT() {
    var rqCheckDft = {
      licenseNo: "พณ" + this.form.docNo,
      companyTax: this.form.taxNo,
      productId: this.form.typeProduct
    };
    var data: any;

    this.appExternalService.getLicenseO2External(rqCheckDft).then(res => {
      if (res === 'error') {
        return;
      } else {
        if (res === null) {
          return;
        } else {
          data = res;

          if (data.documentHeader.status === 0) {
            this.alertMessageService.open({
              message: "ไม่พบเอกสารที่ร้องขอ กรุณาตรวจสอบเลขที่เอกสารอีกครั้ง",
              title: ""
            });

            return;
          } else {
            //CREATE อ.2
            const reqq = {
              docNo: data.documentHeader.licenseNo,
            };
            this.appO2Service.checkDupDocNo(reqq).then((res) => {
              if (res != null && res != "error") {
                if (res == 0) {
                  //SAVE O2
                  var rqSaveO2Online = {
                    source: "O",
                    docId: data.documentHeader.licenseNo,
                    companyTax: data.documentHeader.companyTax,
                    expireDate: data.documentHeader.expireDate,
                    typeDoc: Number(data.documentHeader.productID),
                    productId: data.documentHeader.productID,
                    typeName: data.documentHeader.productName,
                    approveDate: data.documentHeader.approveDate,
                    status: data.documentHeader.status,
                    item: data.documentDetail.detailInfo
                  };

                  this.appO2Service.saveO2Online(rqSaveO2Online).then((res) => {
                    if (res != "error") {
                      this.router.navigate(["/page/o2/o2-manual"], {
                        queryParams: {
                          page: 'o2',
                          source: res.source,
                          type: 'Edit',
                          id: res.id,
                          docNo: res.docNo,
                          productId: res.productId
                        },
                      }).then(ress => {
                        this.dialogRef.close();
                      });
                    } else {
                      return;
                    }
                  });
                } else {
                  this.alertMessageService.open({
                    message:
                      "ใบอนุญาตกระทรวงพาณิชย์เลขที่ฯ พณ" +
                      this.form.docNo +
                      " ได้เคยถูกบันทึกในระบบแล้ว ไม่สามารถบันทึกซ้ำได้ ",
                    title: "",
                  });
                }
              }
            });
          }
        }
      }
    })
  }

  checkDupDocNo() {
    console.log(this.form);

    const reqq = {
      docNo: this.form.docNo,
    };
    this.appO2Service.checkDupDocNo(reqq).then((res) => {
      if (res != null && res != "error") {
        if (res == 0) {
          var rqSaveO2Manual = {
            source: "M",
            docId: this.form.docNo,
            companyTax: this.form.taxNo,
            expireDate: this.form.expireDate,
            typeDoc: this.form.typeProduct,
            approveDate: this.form.issueDate,
          };

          this.appO2Service.saveO2Manual(rqSaveO2Manual).then((res) => {
            if (res != "error") {
              this.router.navigate(["/page/o2/o2-manual"], {
                queryParams: {
                  page: 'o2',
                  source: res.source,
                  type: 'Edit',
                  id: res.id,
                  docNo: res.docNo,
                  productId: this.form.typeProduct
                },
              }).then(ress => {
                this.dialogRef.close();
              });
            } else {
              return;
            }
          });
        } else {
          this.alertMessageService.open({
            message:
              "ใบอนุญาตกระทรวงพาณิชย์เลขที่ฯ " +
              this.form.docNo +
              " ได้เคยถูกบันทึกในระบบแล้ว ไม่สามารถบันทึกซ้ำได้ ",
            title: "",
          });
        }
      }
    });
  }

  async getdataMs13ById() {
    //this.ms13Id = this.form.refMs13No;
    const formMs13ID = {
      id: this.ms13Id,
    };
    await this.appMs13Service.getDataMS13ById(formMs13ID).then((res: any) => {
      // 
      if (res == "error") {
      } else {
        var dataSourceList = res.map((x, n) => {
          return {
            ...x,
            rowNo: (n + 1)
          };
        });
        //this.dataMs13Source.data = dataSourceList;
        this.dataMs13Source = new MatTableDataSource(dataSourceList);
        //this.dataMs13Source.data = dataSourceList;
        this.dataMs13List = dataSourceList;
        //console.log(this.dataMs13List);
      }
    });
  }
  async confirmCreateMs24() {
    if (this.form.refMs13No != null) {

      // บันทึก ms24 header
      this.saveMs24(this.form.refMs13No, 1);

      const dialogRef = this.dialog.open(CreateO2ModalComponent, {
        disableClose: true,
        maxWidth: "none",
        width: "65vw",
        data: {
          page: "ms24",
          ms13HId: this.form.refMs13No,
          ms24HId: this.form.ms24Id,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }
  async saveMs24(ms13Id, status) {
    // ;
    // -สถานะเปลี่ยนเป็น Save to Database
    // ดึงข้อมูล ms13 มาเพื่อบันทึก
    const formDataMs13 = {
      ID: ms13Id,
      companyTax: "TH",
    };
    await this.appMs13Service.getDataMS13(formDataMs13).then((res) => {
      if (res != null) {
        this.formMs13 = res;
        // console.log(res);
        // console.log("formData : ",this.formMs13);
        this.formMs13.id = res.id;
        this.formMs13.companyTax = res.companyTax;
        this.formMs13.o2DocNo = res.docNo;
        this.formMs13.o2ExpireDate = res.expireDate;
        this.formMs13.o2IssueDate = res.issueDate;
        this.formMs13.attachO2 = res.attachO2;
        this.formMs13.source = res.source;
        this.formMs13.refNoShow = res.refNo;
        this.formMs13.refNo = res.refNo;
        this.formMs13.fullRefNo = res.fullRefNo;
        this.formMs13.exporterNo = res.exporter;
        this.formMs13.productName = res.typeGoods;
        this.formMs13.source = res.source;
        this.formMs13.goodsName = res.typeGoods;
        this.formMs13.typeGoodsz = Number(res.typeDocument);
        this.formMs13.statusName = this.statusList.find((f) => f.statusId == res.status).statusName;
        this.formMs13.transportationName = this.transModeList.find((f) => f.id == res.transportationMode).name;
        this.formMs13.port = Number(res.port);
        const dataShip = {};
        this.appMasterService.getShippingMask(dataShip).then((res) => {
          this.shippingMaskList = res.filter((f) => f.doc == this.productId);
          this.shippingMaskList.map((m) => {
            return {
              ...m,
              img: environment.api + m.paht,
            };
          });
          this.formMs13.shippingMarkNo = this.formMs13.shippingMark;
          this.formMs13.shippingMarkNoPath = this.shippingMaskList.find(
            (f) => f.no == this.formMs13.shippingMarkNo
          )?.path;
        });

        this.formMs13.shippingProvince =
          this.formMs13.shippingProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.formMs13.shippingProvince;
        this.formMs13.recieveProvince =
          this.formMs13.recieveProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.formMs13.recieveProvince;
        this.formMs13.inspectProvince =
          this.formMs13.inspectProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.formMs13.inspectProvince;
        this.formMs13.porttxt =
          this.formMs13.porttxt == "ท่าเรือกรุงเทพฯ/แหลมฉบัง"
            ? "ท่ากรุงเทพฯ / แหลมฉบัง"
            : this.formMs13.porttxt;

        const rqSurveyorDd = {
          surveyorID: "",
          isActive: "Y",
        };
      }
    });
    var save = true;
    this.formMs13.status = Number(this.formMs13.status);
    this.userProfile.userTypeId = Number(this.userProfile.userTypeId);
    const formDataMS24 = {
      //cmms24HId : this.formMs13., //97825,
      cmms24DocumentNo: null, //00001/2567",
      cmms24IssueDate: this.formMs13.refDate, //2024-04-04 00:00:00.000",
      cmms24ExpireDate: this.formMs13.o2ExpireDate, //2024-04-13 00:00:00.000",
      cmdtDocumentTypeId: this.formMs13.typeDocument, //1,
      cmdtDocumentType: this.formMs13.typeGoods, //-",
      cmms24RefNo: this.formMs13.refNo, //00001/2567/1",
      cmms24RefDate: this.formMs13.refDate, //2024-04-04 10:34:10.000",
      cmsStatusId: status, //13,
      cmcCompanyId: this.userProfile.cpId, //118,
      cmms24Exporter: this.formMs13.exporter, //2021",
      cmcTaxId: this.formMs13.companyTax, //0115542000168",
      cmcNameTh: this.formMs13.companyThName, //บริษัท เอเซีย โกลเด้น ไรซ์ จำกัด",
      cmcNameEn: this.formMs13.companyEnName, //ASIA GOLDEN RICE CO., LTD.",
      cmcAddressStreet: this.formMs13.exporterStreetandNo, //54-55 หมู่ 8 ถนนฉะเชิงเทรา-บางปะกง",
      cmcDistrict: this.formMs13.exporterDistrict, //ตำบลท่าสะอ้าน",
      cmcSubProvince: this.formMs13.exporterSubProvince, //อำเภอบางปะกง",
      cmcProvince: this.formMs13.exporterProvince, //ฉะเชิงเทรา",
      cmcPostCode: this.formMs13.exporterPostcode, //24130",
      cmsmShippingId: this.formMs13.shippingId, //0,
      cmms24ShippingNameTh: this.formMs13.shippingName, //บริษัท ไทย แกรนลักซ์ อินเตอร์เนช่นแนล ไรซ์ จำกัด",
      cmms24ShippingstreetNo: this.formMs13.shippingStreetNo, //54/1 หมู่ 8 ถนนฉะเชิงเทรา-บางปะกง",
      cmms24ShippingDistrict: this.formMs13.shippingDistrict, //ตำบลท่าสะอ้าน",
      cmms24ShippingSubProvince: this.formMs13.shippingSubProvince, //อำเภอบางปะกง",
      cmms24ShippingProvince: this.formMs13.shippingProvince, //ฉะเชิงเทรา",
      cmms24ShippingPostCode: this.formMs13.shippingPostcode, //24130",
      cmms24RecieveNameTh: this.formMs13.recieveName, //",
      cmms24RecieveNameEn: this.formMs13.recieveEnName, //LEE&#039;S FU INC.",
      cmms24RecieveStreetNo: this.formMs13.recieveStreetNo, //",
      cmms24RecieveDistrict: this.formMs13.recieveDistrict, //",
      cmms24RecieveSubProvince: this.formMs13.recieveSubProvince, //",
      cmms24RecieveProvince: this.formMs13.recieveProvince, //",
      cmms24RecievePostCode: this.formMs13.recievePostcode, //",
      cmms24RecieveCountryCode: this.formMs13.recieveCountryCode, //US",
      cmms24BuyerCountryCode: this.formMs13.recieveCountryCode, //US",
      cmms24DestinationCountryCode: this.formMs13.destinationCountryCode, //US",
      cmcuCurrencyCode: this.formMs13.currency, //USD",
      cmtmId: this.formMs13.transportationMode, //4,
      cmpoPortId: this.formMs13.port, //0,
      cmpoName: this.formMs13.porttxt, //ท่ากรุงเทพฯ / แหลมฉบัง",
      cmms24VesselName: this.formMs13.vesselName, //เรือใหญ่",
      cmms24VesselNameAccual: this.formMs13.vesselName, //เรือใหญ่",
      cmms24ExchangeRate: this.formMs13.exchangeRate, //33.976600,
      cmms24AttachMs24: "",
      cmsmShippingMarkNo: this.formMs13.shippingMark, //@",
      cmms24GoodsCode: this.formMs13.goodsCode, //1,
      cmms24GoodsName: this.formMs13.goodsName, //ข้าวหอมมะลิไทย",
      cmms24TypeClass: this.formMs13.typeClass, //0.1: ข้าวขาว 100%",
      cmms24ProofPurchase: this.formMs13.proofpurchase, //INVOICE NO.ASIA430/2023-AA",
      cmms24LoadedPort: this.formMs13.lodedPor, //",
      cmms24LoadedPortName: this.formMs13.loadePortName, //LOS ANGELES",
      cmms24LoadedPortCountryCode: this.formMs13.loadedPortCountryCode, //UNITED STATES OF AMERICA",
      cmms24GoodsLoadDateStart: this.formMs13.loadedDateStart, //2024-04-04 00:00:00.000",
      cmms24GoodsLoadDateEnd: this.formMs13.loadedDateEnd, //null,
      cmms24SurveyLocationNameTh: this.formMs13.inspectName, //โกดัง บริษัท เอเซียโกลเด้น ไรซ์ จำกัด",
      cmms24SurveyLocationNameEn: null,
      cmms24SurveyLocationStreetNo: this.formMs13.inspectStreetNo, //54-55 หมู่ 8 ถนนฉะเชิงเทรา-บางปะกง",
      cmms24SurveyLocationDistrict: this.formMs13.inspectDistrict, //ตำบลท่าสะอ้าน",
      cmms24SurveyLocationSubProvince: this.formMs13.inspectSubprovince, //อำเภอบางปะกง",
      cmms24SurveyLocationProvince: this.formMs13.inspectProvince, //จ.ฉะเชิงเทรา",
      cmms24SurveyLocationPostCode: this.formMs13.inspectPostcode, //24130",
      cmms24SurveyTimeStart: null,
      cmms24SurveyTimeEnd: null,
      cmms24SurveyLocationRemark: this.formMs13.botRemark, //",
      cmms24PetitionNo: "",
      cmms24PetitionDate: null,
      cmms24SurveyCheif: this.formMs13.surveyCheif, //นายสว่าง ทำนาบุญ",
      cmms24SurveyCheifNo: null,
      cmms24SurveyBy1: null,
      cmms24SurveyByNo1: null,
      cmms24SurveyBy2: this.formMs13.surveyBy2, //นายมนตรี มั่นยืน",
      cmms24SurveyByNo2: null,
      cmms24SurveyBy3: this.formMs13.surveyBy3, //",
      cmms24SurveyByNo3: null,
      cmms24SurveyBy4: this.formMs13.surveyby4, //",
      cmms24SurveyByNo4: null,
      cmms24SurveyBy5: this.formMs13.surveyBy5, //",
      cmms24SurveyByNo5: null,
      cmms24SurveyBy_6: this.formMs13.surveyBy6, //",
      cmms24SurveyByNo_6: null,
      cmms24SurveyOrder: this.formMs13.surveyOrder1, //มยุรี ทุมขุนทด",
      cmms24SurveyOrderNo: null,
      cmms24SurveyOrderDate: this.formMs13.surveyOrderDate1, //2024-03-09 14:30:00.000",
      cmms24SurveyAnalyze: this.formMs13.surveyAnalyze, //นางสาวศศิวรรณ รัตนกังวานวงศ์ ",
      cmms24SurveyAnalyzeNo: null,
      cmms24SurveyOrderAnalyze: this.formMs13.surveyOrder2, //มยุรี ทุมขุนทด",
      cmms24SurveyOrderAnalyzeNo: null,
      cmms24SurveyOrderAnalyzeDate: this.formMs13.surveyOrderDate2, //2024-03-09 14:40:00.000",
      cmms24RejectReason: this.formMs13.rejectReason, //",
      cmms24SurveyAcceptUser: this.userProfile.uId, //382,
      cmms24ShpMaskPic: this.formMs13.shpMaskpic, //./shpmask/118/B1.gif",
      cmms24PrintCode: this.formMs13.printCode, //",
      cmms24PrintUser: this.formMs13.printUser, //0,
      cmms24PrintDate: this.formMs13.printDate, //null,
      cmms24PrintCount: this.formMs13.printCount, //0,
      cmms24PrintLock: this.formMs13.printLock, //",
      cmms24PrintSecure: this.formMs13.source, //",
      cmms24Comment1: this.formMs13.comment1, //",
      cmo2hId: this.formMs13.o2Hid, //74077,
      cmo2hDocumentNo: this.formMs13.o2DocNo, //0308116641568",
      cmo2hIssueDate: this.formMs13.o2IssueDate, //2024-04-09 00:00:00.000",
      cmo2hExpireDate: this.formMs13.o2ExpireDate, //2024-04-08 00:00:00.000",
      cmms13hId: this.formMs13.id, //105417,
      cmms13hRefNo: this.formMs13.refNo, //00971/2566",
      cmms24SurveyReportDate: null,
      cmms24ShortDocument: "",
      cmms24FlagReqPay: "",
      cmms24TakeTransfered: "",
      cmms24BotReceiveUser: this.formMs13.botReceiveUser, //108,
      cmms24BotReceiveDate: this.formMs13.botReceiveDate, //2024-04-04 10:39:32.000",
      cmms24BotApproveUser: this.formMs13.botReceiveUser, //108,
      cmms24BotApproveDate: this.formMs13.botApproveDate, //2024-04-04 10:43:52.000",
      cmms24Kpot1: 0,
      cmms24KpotQuantity1: 0, //0.000000,
      cmms24Kpot2: 0,
      cmms24KpotQuantity2: 0, //0.000000,
      cmms24Kpot3: 0,
      cmms24KpotQuantity3: 0, //0.000000,
      cmms24Kpot4: null,
      cmms24KpotQuantity4: null,
      cmms24Kpot5: null,
      cmms24KpotQuantity5: null,
      cmms24Refer1: null,
      cmms24Refer2: null,
      cmms24Refer3: null,
      cmms24Refer4: null,
      cmms24Refer5: null,
      cmms24IsActive: "Y",
      cmedExporterNo: this.formMs13.exporterId, //2024",
      cmms24CheckResult: "",// this.formMs13.mstMs13SurResultRespons.checkResult, //มาตรฐานเทียบได้ เท่ากับ มาตรฐานที่ทางราชการกำหนด",
      cmms24CheckQuantityResult: "",//this.formMs13.mstMs13SurResultRespons.checkQty, //กระสอบที่บรรจุถูกต้อง",
      cmms24CheckPackageResult: "",//this.formMs13.mstMs13SurResultRespons.checkPackageResult, //การเย็บปากกระสอบ เรียบร้อย",
      cmms24SurveyRemark: null,// this.formMs13.mstMs13SurResultRespons.surveyRemark, //null,
      cmms24SurveyAccualDate: null,//this.formMs13.mstMs13SurResultRespons.checkDate, //null,
      cmms24SurveyorId: null,//this.formMs13.mstMs13SurResultRespons.rid, //256,
      cmms24Name: this.formMs13.companyThName, //บริษัท เอสจีเอส (ประเทศไทย) จำกัด",
      cmms24CreateByOld: this.formMs13.createby, //494,
      cmms24CreateDateOld: this.formMs13.refDate, //2024-04-04 10:32:55.000",
      cmms24Ms13Condition: null, //null,
      cmms24Source: this.formMs13.source, //M",
      cmms24SurveyAcceptDate: this.formMs13.surveyorAcceptDate, //null,
      cmms24Lock: this.formMs13.lock, //null,
      cmms24Comment2: this.formMs13.comment2, //null,
      cmms24BotRemark: this.formMs13.botRemark, //null,
      cmms24FullRefNo: this.productList.find((f) => f.docTypeId == this.formMs13.typeDocument).prefix,
      dataMs24Detail: []
    }
    await this.appMs13Service.getDataMS13Detail(formDataMs13).then((resD) => {
      this.formMs13.item = resD;
      //this.formMs13.item = this.appMs13Service.getDataMS13Detail()
      // 
      console.log(this.formMs13.item);
      const dataMs24Detail = this.formMs13.item.map((e, n) => {
        return {
          cmms24dInvoiceNo: e.invoiceNo, //ASIA430/2023-AA",
          cmms24dInvoiceDate: e.invoiceDate, //2024-04-08 00:00:00.000",
          cmms24dInvoiceItem: e.invoiceItem, //1,
          cmms24dItemNo: e.itemNo, //1",
          cmms24dGoodsDescription: e.goodsDescription, //ต้นข้าว ข้าวขาวหอมมะลิไทย 100 % บรรจุกระสอบพีพีใสกราเวียร์ 2 หน้า หนักกระสอบละ 22.700 กก. จำนวน 2,200 กระสอบ",
          cmtTariffClass: null,// this.formMs13.mstMs13SurDetailRespons.tariffCode, //10063040001",
          cmtTariffStat: null,//this.formMs13.mstMs13SurDetailRespons.sm13SdStatisticalCode, //000",
          cmms24dTariffSequence: null,//this.formMs13.mstMs13SurDetailRespons.tariffSequence, //",
          cmms24dQuantityNone: e.noneQty, //N",
          cmms24dQuantity: e.quantity, //2200,
          cmms24dQuantityUnit: e.quantityUnit, //กระสอบ",
          cmms24dNetWeight: e.availableNetww, //49940.000000,
          cmms24dNetWeightUnit: e.netWeightUnit, //KGM",
          cmms24dNetWeightPerUnit: e.netWeighUnit, //22.700000,
          cmms24dGrossWeight: 0.000000,
          cmms24dGrossWeightPerUnit: e.grossWeightUnit, //22.790000,
          cmms24dGrossWeightPerUnitExporter: e.grossWeightUnitExp, //22.790000,
          cmms24dUnitPrice: e.unitPrice, //908.000000,
          cmcuCurrencyCode: e.currency, //USD",
          cmms24dFobValueBaht: e.fobValueBaht, //1540686.595000,
          cmms24dFobValueUsd: e.fobValueUsd, //45345.520000,
          cmms24dSurveyQuantity: 0,
          cmms24dSurveyQuantityUnit: 0,
          cmms24dSurveyNetWeight: 0,
          cmms24dSurveyNetWeightUnit: 0,
          cmms24dSurveyNetWeightPerUnit: 0,
          cmms24dSurveyGrossWeightPerUnit: 0,
          cmms24hKpotQuantity1: e.kPotQty1, //0.000000,
          cmms24hKpotQuantity2: e.kpotQty2, //0.000000,
          cmms24hKpotQuantity3: e.kpotQty3, //0.000000,
          cmms24hKpotQuantity4: null,
          cmms24hKpotQuantity5: null,
          cmms24hRefer1: null,
          cmms24hRefer2: null,
          cmms24hRefer3: null,
          cmms24hRefer4: null,
          cmms24hRefer5: null,
          cmms13srhIsActive: "Y",
          cmms24hKpot1: e.kPot1, //",
          cmms24hKpot2: e.kPot2, //",
          cmms24hKpot3: e.kPot3, //",
          cmms24hKpot4: null,
          cmms24hKpot5: null
        };
      });
      formDataMS24.dataMs24Detail = dataMs24Detail;
      // console.log("productList ==> ", this.productList);
      console.log("formDataMS24 ==> ", formDataMS24);
      if (save) {
        this.appMs24Service.saveDataMs24(formDataMS24).then((res) => {
          if (res != "error") {
            this.form.ms24Id = res.cmms24HId;
          }
        });
      }
    });

  }

  gotoCreateMs24() {
    this.dataMs13List.forEach((element) => {
      this.form = element;
      console.log("datatb ==> ", element);
      const formData = {
        ID: element.itemId,
        hId: element.itemHid,
        weightUsed: element.usedNet + element.amountUsedMs13,
        availableNet: this.form.netWeight - this.form.amountUsedMs13,
      };
      this.appMs13Service.updateUseDataMS13(formData).then((res) => {
        console.log("updateUseDataMS13 ==> ", res);
        if (res != "error") {
          // /page/o2/o2-manual?source=M&page=ms13&product=3&status=5
          // this.router
          //   .navigateByUrl("/", { skipLocationChange: true })
          //   .then(() => {
          //     this.router
          //       .navigate(["/page/o2/create-ms13"], {
          //         queryParams: {
          //           source: "O",
          //           type: "NEW",
          //           page: "ms13",
          //           docNo: this.data.docNo,
          //           product: this.data.product,
          //           expireDate: this.data.expireDate,
          //           issueDate: this.data.issueDate,
          //           hId: this.data.hId,
          //           itemId: res.cmO2D_ID,
          //           // dftId: res,
          //         },
          //       })
          //       .then((r) => {
          //         this.dialogRef.close();
          //       });
          //   });
        }
      });
    });
    console.log(this.form);
    // ;
  }
}