<!-- การตรวจสอบมาตรฐานสินค้า -->
<div class="flex flex-col p10">
  <mat-card>
    <mat-card-header class="fontTitleHeader"> การตรวจสอบมาตรฐานสินค้า </mat-card-header>
    <mat-card-content class="example-headers-align">
      <div class="flex pt-5">
        <div class="flex-1 w-50">
          <div class="flex flex-row p10">
            <div class="basis-1/2 f18" style="align-self: center">
              บริษัทผู้ตรวจสอบ :
            </div>
            <div class="basis-4/5">{{ form.inspectByName }}</div>
          </div>
        </div>
        <div class="flex-1 w-50">
          <div class="flex flex-row p10">
            <div class="basis-1/2 f18" style="align-self: center"></div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-50">
          <div class="flex flex-row p10">
            <div class="basis-1/2 f18" style="align-self: center">
              ผู้รับตรวจสอบ :
            </div>
            <div class="basis-4/5">
              <mat-form-field class="w100">
                <input autocomplete="off" type="text" matInput placeholder="ชื่อผู้รับตรวจสอบ (ภาษาไทย)" #input
                  name="surveyAcceptName" [(ngModel)]="form.surveyAcceptName" required #surveyAcceptName="ngModel" />
                <mat-error>กรุณาระบุ</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="flex-1 w-50">
          <div class="flex flex-row p10">
            <div class="basis-1/2 f18" style="align-self: center">
              วันและเวลาที่ได้รับคำร้อง :
            </div>
            <div class="basis-4/5">
              <mat-form-field class="w100">
                <input required autocomplete="off" placeholder="วันและเวลาที่ได้รับคำร้อง" matInput
                  [matDatepicker]="surveyAcceptDate" [(ngModel)]="form.surveyAcceptDate" name="surveyAcceptDate" />
                <mat-datepicker-toggle matSuffix [for]="surveyAcceptDate"></mat-datepicker-toggle>
                <mat-datepicker #surveyAcceptDate></mat-datepicker>
                <mat-error>กรุณาระบุ</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="basis-2/2">
              ผู้ตรวจสอบมาตราฐานสินค้า (ประเภท ข.) ปฎิบัติการตรวจสอบมาตรฐาน
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="flex flex-column basis-3/5">
              <div class="basis-1" style="align-self: center">1.</div>
              <div class="basis-3/5 pl-5">
                <div [formGroup]="empForm" class="basis-5/6">
                  <mat-form-field class="w96">
                    <input type="text" matInput [required]="typePage == 'o2'" formControlName="empSearch"
                      [matAutocomplete]="autoEmp">
                    <mat-autocomplete #autoEmp="matAutocomplete" (optionSelected)="selectionEmpChange($event)">
                      <mat-option *ngFor="let option of filterOptEmp$" [value]="option">
                        {{option.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
                <!-- <mat-form-field class="w100">
                <input required autocomplete="off" type="text" matInput #input [(ngModel)]="form.survey_cheif"
                  name="survey_cheif" />
                <mat-error>กรุณาระบุ</mat-error>
              </mat-form-field> -->
              </div>
              <div class="basis-1/6 pl-5" style="align-self: center">
                เลขที่ ผตข.
              </div>
              <div class="basis-1/6">
                <div class="basis-4/5">
                  <mat-form-field class="w96">
                    <input autocomplete="off" readonly type="text" matInput [(ngModel)]="form.surveyCheifNo"
                      name="surveyCheifNo" />
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="basis-10 pl-5">
              <div class="basis-2/5">
                <mat-form-field class="w96">
                  <input autocomplete="off" readonly type="text" matInput [(ngModel)]="form.surveyCheifNo"
                    name="surveyCheifNo" />
                </mat-form-field>
              </div>
            </div> -->
              <div class="basis-1/5 pl-5" style="align-self: center">
                ...เป็นหัวหน้า
              </div>
            </div>
            <!-- <div class="flex flex-column basis-1/2 f18"></div> -->
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="flex flex-column basis-2/5">
              <div class="basis-1" style="align-self: center">2.</div>
              <div class="basis-3/5 pl-5">
                <div [formGroup]="empForm" class="basis-4/5">
                  <mat-form-field class="w96">
                    <input type="text" matInput [required]="typePage == 'o2'" formControlName="empSearch2"
                      [matAutocomplete]="autoEmp2">
                    <mat-autocomplete #autoEmp2="matAutocomplete" (optionSelected)="selectionEmp2Change($event)">
                      <mat-option *ngFor="let option of filterOptEmp2$" [value]="option">
                        {{option.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
              <div class="basis-1/6 pl-5" style="align-self: center">
                เลขที่ ผตข.
              </div>
              <div class="basis-1/6">
                <div class="basis-4/5">
                  <mat-form-field class="w96">
                    <input autocomplete="off" readonly type="text" matInput [(ngModel)]="form.surveyByNo2"
                      name="surveyByNo2" />
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="basis-2/4 pl-5">
                <mat-form-field class="w100">
                  <input required autocomplete="off" type="text" matInput #input [(ngModel)]="form.surveyBy2"
                    name="surveyBy2" />
                  <mat-error>กรุณาระบุ</mat-error>
                </mat-form-field>
              </div> -->
            </div>
            <div class="flex flex-column basis-1/2 f18">
              <div class="basis-1/5" style="align-self: center">ผู้ส่ง</div>
              <div class="basis-2/5 pl-5">
                <mat-form-field class="w100">
                  <input required autocomplete="off" type="text" matInput #input [(ngModel)]="form.surveyOrder1"
                    name="surveyOrder1" />
                  <mat-error>กรุณาระบุ</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="flex flex-column basis-2/5">
              <div class="basis-1" style="align-self: center">3.</div>
              <div class="basis-3/5 pl-5">
                <div [formGroup]="empForm" class="basis-4/5">
                  <mat-form-field class="w96">
                    <input type="text" matInput [required]="typePage == 'o2'" formControlName="empSearch3"
                      [matAutocomplete]="autoEmp3">
                    <mat-autocomplete #autoEmp3="matAutocomplete" (optionSelected)="selectionEmp3Change($event)">
                      <mat-option *ngFor="let option of filterOptEmp3$" [value]="option">
                        {{option.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
              <div class="basis-1/6 pl-5" style="align-self: center">
                เลขที่ ผตข.
              </div>
              <div class="basis-1/6">
                <div>
                  <mat-form-field class="w96">
                    <input autocomplete="off" readonly type="text" matInput [(ngModel)]="form.surveyByNo3"
                      name="surveyByNo3" />
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="basis-2/4 pl-5">
                <mat-form-field class="w100">
                  <input required autocomplete="off" type="text" matInput #input [(ngModel)]="form.surveyBy3"
                    name="surveyBy3" />
                  <mat-error>กรุณาระบุ</mat-error>
                </mat-form-field>
              </div> -->
            </div>
            <div class="flex flex-column basis-1/2 f18">
              <div class="basis-1/5" style="align-self: center">
                วันและเวลาที่ส่ง
              </div>
              <div class="basis-2/5 pl-5">
                <mat-form-field class="w100">
                  <input required autocomplete="off" placeholder="วันและเวลาที่ส่ง" matInput
                    [matDatepicker]="surveyOrderDate1" [(ngModel)]="form.surveyOrderDate1" name="surveyOrderDate1" />
                  <mat-datepicker-toggle matSuffix [for]="surveyOrderDate1"></mat-datepicker-toggle>
                  <mat-datepicker #surveyOrderDate1></mat-datepicker>
                  <mat-error>กรุณาระบุ</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="basis-2/2">ผู้วิเคราะห์</div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="flex flex-column basis-2/5">
              <div class="basis-1" style="align-self: center">1.</div>
              <div class="basis-3/5 pl-5">
                <div [formGroup]="empForm" class="basis-4/5">
                  <mat-form-field class="w96">
                    <input type="text" matInput [required]="typePage == 'o2'" formControlName="surveyAnalyze"
                      [matAutocomplete]="autoSurveyAnalyze">
                    <mat-autocomplete #autoSurveyAnalyze="matAutocomplete"
                      (optionSelected)="selectionSurveyAnalyzeChange($event)">
                      <mat-option *ngFor="let option of filterSurveyAnalyze$" [value]="option">
                        {{option.name}}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
                </div>
              </div>
              <!-- <div class="basis-2/4 pl-5">
                <mat-form-field class="w100">
                  <input required autocomplete="off" type="text" matInput #input [(ngModel)]="form.surveyAnalyze"
                    name="surveyAnalyze" />
                  <mat-error>กรุณาระบุ</mat-error>
                </mat-form-field>
              </div> -->
              <div class="basis-1/6 pl-5" style="align-self: center">
                เลขที่ ผตก.
              </div>
              <div class="basis-1/6">
                <div class="basis-4/5">
                  <mat-form-field class="w96">
                    <input autocomplete="off" readonly type="text" matInput [(ngModel)]="form.surveyAnalyzeNo"
                      name="surveyAnalyzeNo" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex flex-column basis-1/2 f18">
              <div class="basis-1/5" style="align-self: center">ผู้สั่ง</div>
              <div class="basis-2/5 pl-5">
                <mat-form-field class="w100">
                  <input required autocomplete="off" type="text" matInput #input [(ngModel)]="form.surveyOrder2"
                    name="surveyOrder2" />
                  <mat-error>กรุณาระบุ</mat-error>
                </mat-form-field>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="flex flex-column basis-2/5"></div>
            <div class="flex flex-column basis-1/2 f18">
              <div class="basis-1/5" style="align-self: center">
                วันและเวลาที่สั่ง
              </div>
              <div class="basis-2/5 pl-5">
                <mat-form-field class="w100">
                  <input required autocomplete="off" placeholder="วันและเวลาที่สั่ง" matInput
                    [matDatepicker]="surveyOrderDate2" [(ngModel)]="form.surveyOrderDate2" name="surveyAcceptDate" />
                  <mat-datepicker-toggle matSuffix [for]="surveyOrderDate2"></mat-datepicker-toggle>
                  <mat-datepicker #surveyOrderDate2></mat-datepicker>
                  <mat-error>กรุณาระบุ</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<!-- ผลการตรวจสอบมาตรฐานสินค้า -->
<div class="flex flex-col p10">
  <mat-card>
    <mat-card-header class="fontTitleHeader"> ผลการตรวจสอบมาตรฐานสินค้า </mat-card-header>
    <mat-card-content class="example-headers-align">
      <div class="flex" *ngIf="result" >
        <div class="flex-1 w-64">
          <div class="flex flex-row p10">
            <div class="basis-1/2 f18" style="align-self: center">
              <button mat-raised-button  (click)="addAuditResults()">
                เพิ่มผลการตรวจสอบ
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="(result | json) != ({} | json)">
        <div class="flex">
          <div class="flex-1 w-100 p10">
            <div class="basis-1/5" style="align-self: center; font-size: 18px">
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 w-100 p10">
            <div class="basis-1/5 pl-3" style="align-self: center">
              {{ result.checkResult }}
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 w-100 p10">
            <div class="basis-1/5 pl-3" style="align-self: center">
              {{ result.checkQty }}
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 w-100 p10">
            <div class="basis-1/5 pl-3" style="align-self: center">
              {{ result.checkPackageResult }}
            </div>
          </div>
        </div>
        <div class="flex">
          <div class="flex-1 p-2.5">
            <div class="flex flex-row pt-2.5 justify-center">
              <div class="mat-elevation-z8 w-[95%]">
                <dx-data-grid id="gridContainer" [dataSource]="auditDatasource" [columnAutoWidth]="true"
                  [showBorders]="true" [allowColumnReordering]="true" class="justify-center">
                  <dxi-column caption="ลำดับรายการ" alignment="center" cellTemplate="wFlow" [width]='100'>
                  </dxi-column>
                  <div *dxTemplate="let row of 'wFlow'">
                    {{row.rowIndex+1}}
                  </div>
                  <dxi-column alignment="center" dataField="goodsDescription" caption="รายการสินค้า"></dxi-column>
                  <dxi-column alignment="center" dataField="surveyQty" caption="จำนวนที่ตรวจปล่อย">
                  </dxi-column>
                  <dxi-column alignment="center" dataField="surveyNetWeightPerUnit" caption="น้ำหนักสุทธิ">
                  </dxi-column>
                  <dxi-column alignment="center" dataField="surveyNetWeight" caption="น้ำหนักสุทธิทั้งหมด">
                  </dxi-column>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
<!-- เอกสารประกอบการพิจารณา -->
<!-- <div class="flex flex-col p10">
  <mat-card>
    <mat-card-header class="fontTitleHeader"> เอกสารประกอบการพิจารณา </mat-card-header>
    <mat-card-content class="example-headers-align">
      <div class="flex">
        <div class="flex-1 p-2.5 w-100">
          <div class="flex flex-row pt-2.5">
            <div class="mat-elevation-z8">
              <dx-data-grid id="gridContainer" [dataSource]="" [columnAutoWidth]="true" [showBorders]="true"
                [allowColumnReordering]="true" class="justify-center">
                <dxi-column alignment="center" caption="ชื่อเอกสาร">
                </dxi-column>
                <dxi-column alignment="center" dataField="order" caption="ขนาดของเอกสาร"></dxi-column>
                <dxi-column alignment="center" dataField="detail" caption="ชนิดเอกสาร">
                </dxi-column>
                <dxi-column alignment="center" dataField="detail" caption="Action">
                </dxi-column>
              </dx-data-grid>
            </div>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div> -->