<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
        <button mat-raised-button style="width: 70px;font-size: 1.5em;" matTooltip="รีเฟรชข้อมูล" matTooltipPosition="above" (click)="onRefresh()">
          <i class="ti ti-refresh"></i>
        </button>
      </div>
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">
          <!-- <h4>records</h4> -->
          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="เลขที่คำร้อง" matInput #input
              [(ngModel)]="searchForm.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu" (menuClosed)="menuClosed()">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex-row">
                <div class="mat-button">
                  <mat-form-field class="my-form-field formm" fxFlex="50">
                    <mat-label>วันที่ออกใบอนุญาต</mat-label>
                    <input autocomplete="off" placeholder="วันที่ออกใบอนุญาต" matInput [matDatepicker]="StartDate"
                      [(ngModel)]="searchForm.issueDate" name="issueDate" #issueDate="ngModel" readonly>
                    <mat-datepicker-toggle matSuffix [for]="StartDate"></mat-datepicker-toggle>
                    <mat-datepicker #StartDate></mat-datepicker>
                    <mat-error>กรุณาระบุ วันที่ออกใบอนุญาต</mat-error>
                  </mat-form-field>
                  <mat-form-field class="my-form-field formm" fxFlex="50">
                    <mat-label>วันหมดอายุ</mat-label>
                    <input autocomplete="off" placeholder="วันหมดอายุ" matInput [matDatepicker]="exDates"
                      [(ngModel)]="searchForm.expireDate" name="expireDate" #expireDate="ngModel" readonly>
                    <mat-datepicker-toggle matSuffix [for]="exDates"></mat-datepicker-toggle>
                    <mat-datepicker #exDates></mat-datepicker>
                    <mat-error>กรุณาระบุ วันหมดอายุ</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่ใบอนุญาต</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.docNo" name="docNo" #docNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทสินค้า</mat-label>
                    <mat-select [(ngModel)]="searchForm.productId" name="productId" #productId="ngModel" ngmodel>
                      <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>ประเภทสินค้า</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>สถานะ</mat-label>
                    <mat-select [(ngModel)]="searchForm.status" name="status" #status="ngModel" ngmodel>
                      <mat-option *ngFor="let status of statusList" [value]="status.statusId">
                        {{status.statusName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>สถานะ</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div>
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" (click)="searchOption()" style="flex:0 50%;">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="index">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ลำดับ</th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-left"> {{ i + 1 }} </td>
      </ng-container>

      <ng-container matColumnDef="docNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">เลขที่คำร้อง</th>
        <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.fullRefNo}} </td>
      </ng-container>

      <ng-container matColumnDef="expireDateOld">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          วันหมดอายุเดิม</th>
        <td mat-cell *matCellDef="let element"> {{(element.expireDateOld | date:'yyyy-MM-dd') || '-'}} </td>
      </ng-container>
      <ng-container matColumnDef="expireDateNew">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">วันหมดอายุใหม่
        </th>
        <td mat-cell *matCellDef="let element"> {{(element.expireDateNew | date:'yyyy-MM-dd') || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="productName" style="justify-content: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ประเภทสินค้า</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="statusCode">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">สถานะ</th>
        <td mat-cell *matCellDef="let element"> {{element.statusCode}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center; width: 300px;"> Action </th>
        <td mat-cell *matCellDef="let element;let i = index">
          <!-- <button mat-icon-button matTooltip="รายละเอียด" *ngIf="checkButton('BT216')" routerLink="/page/o2/renew-o2" -->
          <button mat-icon-button matTooltip="รายละเอียด" (click)="renewMs13(element, false, true)" matTooltipPosition="above">
            <i class="ti ti-file-description"></i>
          </button>
          <!-- <button mat-icon-button matTooltip="แก้ไข" *ngIf="checkButton('BT215')" routerLink="/page/o2/renew-o2" -->
          <button mat-icon-button matTooltip="แก้ไขคำร้อง" (click)="renewMs13(element, false, false)" matTooltipPosition="above" *ngIf="element.status == 4">
            <i class="ti ti-edit"></i>
          </button>
          <!-- <button mat-icon-button matTooltip="ลบ" *ngIf="checkButton('BT217') && element.status < 5" -->
          <button mat-icon-button matTooltip="ยกเลิกคำร้อง" (click)="deleteItem(element)" matTooltipPosition="above" *ngIf="element.status == 2">
            <i class="ti ti-square-x"></i>
          </button>
          <!-- <button mat-icon-button matTooltip="พิจารณาคำร้อง" (click)="renewMs13(element, true, true)" matTooltipPosition="above">
            <i class="ti ti-eye-check"></i>
          </button> -->
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[ 10, 20]" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>