<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">NSW Message Tracking</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="flex flex-col mat-typography" style="gap: 15px;">
    <div class="flex flex-row justify-center gap-3">
      <div class="mat-elevation-z8 w100">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
          <ng-container matColumnDef="index">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ลำดับ</th>
            <td mat-cell *matCellDef="let element; let i = index" style="justify-content: center;"> {{ i + 1 }} </td>
          </ng-container>

          <ng-container matColumnDef="referenceNo" style="justify-content: center;">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Reference No.</th>
            <td mat-cell *matCellDef="let element"> {{element.referenceNo}} </td>
          </ng-container>

          <ng-container matColumnDef="statusName" style="justify-content: center;">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Status</th>
            <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
          </ng-container>

          <ng-container matColumnDef="lastUpdate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Last Update
            </th>
            <td mat-cell *matCellDef="let element"> {{(element.lastUpdate | date:'yyyy-MM-dd') || '-'}}
              {{(element.lastUpdate | date:'HH:mm') || null}} </td>
          </ng-container>

          <ng-container matColumnDef="action" style="text-align: left;">
            <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Action </th>
            <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;">
              <div class="flex">
                <div>
                  <button mat-icon-button matTooltip="Information" matTooltipPosition="above"
                    (click)="openInfo(element)">
                    <i class="ti ti-info-circle"></i>
                  </button>
                </div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>