 
<div class="menu-top-main">
    <div class="menu-top-sub">
        <div *ngFor="let item of menuForm">
            <button mat-button *ngIf="item.type == 'collapsable'"  [matMenuTriggerFor]="menu">
                <mat-icon svgIcon="{{item.icon}}"></mat-icon>
                <!-- <mat-icon style="font-size: 24px; " class="fontSize">{{item.icon}}</mat-icon> -->
                &nbsp;
                {{item.title}}
            </button>
  
            <button mat-button *ngIf="item.type == 'item'" [routerLink]="[item.url]">
                <mat-icon svgIcon="{{item.icon}}"></mat-icon>
                <!-- <mat-icon style="font-size: 24px;" class="fontSize">{{item.icon}} </mat-icon> -->
                &nbsp;
                {{item.title}}
            </button>
  
            <mat-menu #menu="matMenu" routerLinkActive="active-link">
                <div *ngFor="let itemChildren of item.children">
                    <div *ngIf="itemChildren.type == 'collapsable'">
                        <button mat-menu-item [matMenuTriggerFor]="menuChild">
                            {{itemChildren.title}}
                        </button>
  
                        <mat-menu #menuChild="matMenu"  routerLinkActive="active-link">
                            <div *ngFor="let itemChild of itemChildren.children">
                                <button mat-menu-item class="setWidth"   [routerLink]="[itemChild.url]">
                                    <!-- <mat-icon svgIcon="{{itemChild.icon}}"></mat-icon> -->
                                    {{itemChild.title}}
                                </button>
                            </div>
                        </mat-menu>
  
                    </div>
                    <div *ngIf="itemChildren.type == 'item'">
                        <button mat-menu-item [routerLink]="[itemChildren.url]">{{itemChildren.title}}</button>
                    </div>
  
                    <!-- <mat-divider class="my-2"></mat-divider> -->
                </div>
            </mat-menu>
        </div>
    </div>
    <div>
        <!-- <h2 style="cursor:pointer;text-align: center;">{{type == 'o2' ? 'อ.2' : type == 'ms13' ? 'มส.13' : type == 'history-ms13'? 'แก้ไขข้อมูลและปลดล็อกการพิมพ์ มส. 13': type == 'dailyreport' ? 'รายงานประจำวัน' : 'มส.24' }}</h2> -->
    </div>
  </div>