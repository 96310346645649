<div class="framepad" fxLayout="column" fxLayoutAlign="space-between none" style="line-height: 23px;">
    <div class="input-row" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px">
        <div class="bx-25">
            <mat-form-field class="bx-100">
                <mat-label>ตั้งค่าสำหรับ</mat-label>
                <mat-select flex="20" [(ngModel)]="form.action" (ngModelChange)="actionChange();">
                    <mat-option *ngFor="let item of actionList" [value]="item.value">
                        {{item.text}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="bx-25" *ngIf="action === 'R'">
            <mat-form-field class="bx-100">
                <mat-label>สิทธิ์ผู้ใช้</mat-label>
                <mat-select flex="20" [(ngModel)]="form.userRoleCode" (ngModelChange)="userRoleChange();">
                    <mat-option [value]="''">กรุณาเลือก</mat-option>
                    <mat-option *ngFor="let item of userRoleList" [value]="item.userRoleCode">
                        {{item.roleName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="bx-25" *ngIf="action === 'G'">
            <mat-form-field class="bx-100">
                <mat-label>กลุ่มผู้ใช้</mat-label>
                <mat-select flex="20" [(ngModel)]="form.userGroupCode" (ngModelChange)="userGroupChange();">
                    <mat-option [value]="''">กรุณาเลือก</mat-option>
                    <mat-option *ngFor="let item of userGroupList" [value]="item.userGroupCode">
                        {{item.groupName}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="bx-25" *ngIf="action === 'U'">
            <form name="searchUserForm" [formGroup]="searchUserForm" class="bx-100">
                <mat-form-field class="bx-100">
                    <mat-label>รายบุคคล</mat-label>
                    <input required matInput type="text" formControlName="userSearch" [matAutocomplete]="auto"
                        name="userCode">
                    <mat-autocomplete #auto="matAutocomplete" required (optionSelected)="selectionChange($event)">
                        <mat-option *ngIf="isLoading">
                            <mat-spinner [diameter]="20"></mat-spinner>
                        </mat-option>
                        <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                            {{option.display}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </form>
        </div>
    </div>
</div>