<div class="flex flex-col pb-10">
  <div class="pb-10">
    <div class=" flex justify-between flow-row pt-10 pl-10 pr-10">
      <div>
        <mat-radio-group [(ngModel)]="form.typeCreate" (change)="chkCreate(form.typeCreate)" name="book" #book="ngModel"
          aria-label="Select an option">
          <mat-radio-button value="1">รวมใบเสร็จ</mat-radio-button>
          <!-- <mat-radio-button value="2">แยกใบเสร็จ</mat-radio-button> -->
          <mat-radio-button value="3">จ่ายรายการอื่นๆ (ไม่บันทึก มส.24)</mat-radio-button>
        </mat-radio-group>
      </div>
      <div>
        <button *ngIf="checkButton('BT156')" mat-raised-button color="accent" (click)="create()">
          Create
        </button>
      </div>
    </div>
    <div *ngIf="chkIsEpayment" class="pl-10">
      <h3 style="color: red;">
        *คุณสามารถเลือกสูงสุดได้ 10 รายการเท่านั้น เมื่อเลือกเป็น การชำระแบบ ODD (E-Payment)
      </h3>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <p class="text-white">Select</p>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(row) : null;checkCompanyExporter(row);"
            [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="refNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">คำร้องเลขที่ </th>
        <td mat-cell *matCellDef="let element"> {{element.ms13FullRefNo}} </td>
      </ng-container>
      <ng-container matColumnDef="docNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>ใบรับรองเลขที่ </th>
        <td mat-cell *matCellDef="let element"> {{element.ms24FullRefNo}} </td>
      </ng-container>
      <ng-container matColumnDef="docType">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">
          ประเภทสินค้า
        </th>
        <td mat-cell *matCellDef="let element"> {{element.goodsName}} </td>
      </ng-container>
      <!-- <ng-container matColumnDef="netWeight">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">
          น้ำหนัก/กก.
        </th>
        <td mat-cell *matCellDef="let element"> {{element.netWeight | number: '1.2-2'|| 0}} </td>
      </ng-container>
      <ng-container matColumnDef="netWeightTon">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">
          น้ำหนัก/ตัน
        </th>
        <td mat-cell *matCellDef="let element"> {{element.netWeightTon | number: '1.2-2'|| 0}} </td>
      </ng-container> -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>