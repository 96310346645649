import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppShippingService } from "app/business/service/app/app-shipping.service";



@Component({
  selector: 'app-shipping-detail-company',
  templateUrl: './shipping-detail-company.component.html',
  styleUrls: ['./shipping-detail-company.component.css']
})

export class ShippingDetailCompanyComponent implements OnInit {
  dataSource : any = {};
  id: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ShippingDetailCompanyComponent>,
    private AppShippingService: AppShippingService,
  ) 
  {
    this.id = data.companyID
  }
  ngOnInit() {
    this.loadData(); 
  }
  async loadData(): Promise<void> {
    //send data to api
    const formData = {
      CompanyId: this.id,
    };
     await this.AppShippingService.getDataCompanyDetail(formData).then((res)=>{ 
      this.dataSource = res[0]
    });
  
  }
}