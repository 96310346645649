import { Injectable } from "@angular/core";
import { ApiSurveyorService } from "../api/api-surveyor.service";

@Injectable({
    providedIn: "root",
  })
  export class AppSurveyorService {
    constructor(private apiSurveyorService: ApiSurveyorService) {}
  
    getSurveyorEmp(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiSurveyorService.getSurveyorEmp(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
  
    listMs13Sur(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiSurveyorService.listMs13Sur(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
 
    getDataMs13Sur(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiSurveyorService.getDataMs13Sur(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    saveDataMs13Sur(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiSurveyorService.saveDataMs13Sur(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    getSurveyorSearchList(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiSurveyorService.getSurveyorSearchList(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
 
    getDataSurveyorSearch(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiSurveyorService.getDataSurveyorSearch(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    saveDataSurveyorSearch(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.apiSurveyorService.saveDataSurveyorSearch(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
    
  }
  