import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild, Inject} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppCompanyService } from "app/business/service/app/app-company.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { IGetCompanyTypeDetailListResponse } from "app/business/interface/app/app-company.interface";
import {  formatDate  } from '@angular/common';
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { ActivatedRoute } from '@angular/router';
import { environment } from "environments/environment";


@Component({
  selector: 'app-company-staff-information-detail',
  templateUrl: './company-staff-information-detail.component.html',
  styleUrls: ['./company-staff-information-detail.component.css']
})
export class CompanyStaffInformationComponent implements OnInit {

  searchForm: any ={};
  form: any = {};
  dataSource = new MatTableDataSource<any>();
  dataView :IGetCompanyTypeDetailListResponse[]=[];
  id : number = 0;
  displayedColumns: string[] = [
    "companyName",
    "isActive",
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppCompanyService: AppCompanyService,
    private AlertMessage : AlertMessageService,
    private route: ActivatedRoute,
    ) 
    {
    }
    

  ngOnInit() {
    this.loadData();
  }
  async loadData(): Promise<void> {
    this.route.queryParams.subscribe(params => {this.id = params.id});
    const formData = {
      taxId : this.id, 
    };

    this.AppCompanyService.getCompanyDetailStaffList(formData).then((res) => {
        this.dataView = res;
        this.dataSource = new MatTableDataSource(this.dataView);
        this.form.expire = formatDate(res.expireDate,'yyyy-MM-dd',"en-US") ; 
        this.form.update = res.updateDate == null?null : formatDate(res.updateDate,'yyyy-MM-dd',"en-US");
        this.form.changeUpCom = res.changeUpComDate == null ? null :formatDate(res.changeUpComDate,'yyyy-MM-dd',"en-US");
        this.form = {...this.form,...res};
    });
    
  }
  exportCompanyPDF() {
  const formData = {
    taxId : this.id, 
       };
     this.AppCompanyService.getDocCompanyDetailStaff(formData).then((res) => {
      (this.form.CompanyStaff = res)

      window.open(
      this.form.CompanyStaff.data.linkUrl,
      '_blank'
    );
    console.log(this.form.CompanyStaff.data.linkUrl);
  });
    
  }

}
