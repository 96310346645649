<mat-card style="width: 100%;">
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">ค้นหาคำขอ</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <dx-data-grid class="mt-3" #gridContainer [dataSource]="dataSource" [allowColumnReordering]="true"
      [showBorders]="true" (onSelectionChanged)="selectionChanged($event)">
      <dxo-selection mode="multiple"></dxo-selection>
      <dxi-column dataField="ms13FullRefNo" caption="คำร้องเลขที่">
      </dxi-column>
      <dxi-column dataField="ms24FullRefNo" caption="ใบรับรองเลขที่">
      </dxi-column>
      <dxi-column dataField="goodsName" caption="ประเภทสินค้า">
      </dxi-column>
      <dxi-column cellTemplate="netWeight" [width]="100" caption="น้ำหนัก/กก.">
      </dxi-column>
      <div *dxTemplate="let row of 'netWeight'">
        {{row.data.item.netWeight| number: '1.2-6' || 0}}
      </div>
      <dxi-column cellTemplate="netWeightTon" [width]="100" caption="น้ำหนัก/ตัน">
      </dxi-column>
      <div *dxTemplate="let row of 'netWeightTon'">
        {{row.data.item.netWeightTon| number: '1.2-6' || 0}}
      </div>
    </dx-data-grid>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="selectionList" [disabled]="selectionList.length == 0"
      color="accent">เพิ่มคำขอ</button>
  </mat-dialog-actions>