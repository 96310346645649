import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-receiver-form-helper',
  templateUrl: './add-receiver-form-helper.component.html',
  styleUrls: ['./add-receiver-form-helper.component.css']
})
export class AddReceiverFormHelperComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
