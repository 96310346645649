<!-- <div> -->
<!-- <div class="flex justify-end">
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div> -->

<mat-tab-group *ngIf="pages == 'o2'" mat-stretch-tabs="true" mat-align-tabs="start">
  <form #createOnlineForm="ngForm">
    <mat-tab label="Online">
      <div class="flex flex-col">
        <div class="flex flex-row p10 justify-center">
          <div class="basis-1/4" style="align-self: center;"> ใบอนุญาตกระทรวงพาณิชย์ | พณ
            <label style="color:red">*</label>
          </div>
          <div class="basis-1/2 f18">
            <mat-form-field class="w100">
              <input autocomplete="off" type="text" matInput required placeholder=" ใบอนุญาตกระทรวงพาณิชย์ | พณ " #input
                [(ngModel)]="form.docNo" [maxlength]="13" name="docNo" #docNo="ngModel">
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row p10 justify-center">
          <div class="basis-1/4" style="align-self: center;">ประเภทสินค้า <label style="color:red">*</label></div>
          <div class="basis-1/2 f18">
            <mat-form-field class="w100">
              <mat-select [(ngModel)]="form.typeProduct" required name="typeProduct" matInput>
                <mat-option *ngFor="let prod of productList" [value]="prod.productId">
                  {{prod.productName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row p10 justify-center">
          <div class="basis-1/4" style="align-self: center;"> เลขประจำตัวผู้เสียภาษีของบริษัท
            <label style="color:red">*</label>
          </div>
          <div class="basis-1/2 f18">
            <mat-form-field class="w100">
              <input autocomplete="off" type="text" matInput required placeholder="เลขประจำตัวผู้เสียภาษีของบริษัท"
                #input [(ngModel)]="form.taxNo" name="taxNo" #taxNo="ngModel">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center p10">
        <div class="basis-1/2 f18">
          <button mat-raised-button [mat-dialog-close] style="flex:0 50%; margin-right: 10px;">
            ปิด
          </button>
          <button mat-raised-button style="width: 100px;" color="accent" [disabled]="onValid(createOnlineForm)"
            (click)="checkDFT()" [mat-dialog-close]>
            ถัดไป
          </button>
          <!-- <button mat-raised-button style="width: 100px;" color="accent" routerLink="/page/o2/o2-manual"
            [disabled]="onValid(createOnlineForm)"
            [queryParams]="{ source : 'O' , type : 'New', page : pages ,docNo : form.docNo , product : form.typeProduct , expireDate : form.expireDate ,issueDate : form.issueDate  }"
            [mat-dialog-close]>
            ถัดไป
          </button> -->
        </div>
      </div>
    </mat-tab>
  </form>
  <form #createManualForm="ngForm" *ngIf="allowManualCreate">
    <mat-tab label="Manual">
      <div class="flex flex-col">
        <div class="flex flex-row p10 justify-center">
          <div style="align-self: center; flex-basis: 27%;"> ใบอนุญาตกระทรวงพาณิชย์ | พณ
            <label style="color:red">*</label>
          </div>
          <div class="basis-1/2 f18">
            <mat-form-field class="w100">
              <input autocomplete="off" required type="text" matInput placeholder="ใบอนุญาตกระทรวงพาณิชย์ | พณ" #input
                [(ngModel)]="form.docNo" [maxlength]="13" name="docNo" #docNo="ngModel">
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row p10 justify-center">
          <div style="align-self: center; flex-basis: 27%;">ประเภทสินค้า
            <label style="color:red">*</label>
          </div>
          <div class="basis-1/2 f18">
            <mat-form-field class="w100">
              <mat-select [(ngModel)]="form.typeProduct" required name="typeProduct" matInput>
                <mat-option *ngFor="let prod of productList" [value]="prod.productId">
                  {{prod.productName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row p10 justify-center">
          <div style="align-self: center; flex-basis: 27%;"> วันที่ออกใบอนุญาต <label style="color:red">*</label></div>
          <div class="basis-1/2 f18">
            <mat-form-field class="w100">
              <input required autocomplete="off" placeholder="วันที่ออกใบอนุญาต" matInput [matDatepicker]="issueD"
                [(ngModel)]="form.issueDate" [max]="form.expireDate" [min]="form.issueDate"
                (dateChange)="setDate($event)" name="issueDate" #issueDate="ngModel" readonly>
              <mat-datepicker-toggle matSuffix [for]="issueD"></mat-datepicker-toggle>
              <mat-datepicker #issueD></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row p10 justify-center">
          <div style="align-self: center; flex-basis: 27%;">วันที่ใบอนุญาตหมดอายุ <label style="color:red">*</label>
          </div>
          <div class="basis-1/2 f18">
            <mat-form-field class="w100">
              <input required autocomplete="off" placeholder="วันหมดอายุ" matInput [matDatepicker]="exDates"
                [(ngModel)]="form.expireDate" [min]="currentDate" [max]="form.maxExpireDate"
                (dateChange)="setDate($event)" name="expireDate" #expireDate="ngModel" readonly>
              <mat-datepicker-toggle matSuffix [for]="exDates"></mat-datepicker-toggle>
              <mat-datepicker #exDates></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="flex flex-col items-center p10">
        <div class="basis-1/2 f18">
          <button mat-raised-button [mat-dialog-close] style="flex:0 50%; margin-right: 10px;">
            ปิด
          </button>
          <button mat-raised-button style="width: 100px;" color="accent" [disabled]="onValid(createManualForm)"
            (click)="checkDupDocNo()">
            ถัดไป
          </button>
        </div>
      </div>
    </mat-tab>
  </form>
</mat-tab-group>
<!-- </div> -->
<!-- <div >
  <div class="flex justify-end">
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div> -->
<form #createMs13Form="ngForm">
  <mat-card *ngIf="pages == 'ms13'">
    <mat-card-header class="fontTitleHeader">
      <div class="flex justify-between" style="width: 100%;">
        <div class="flex align-center justify-center" style="padding-left: 10px;">
          <span style="align-self: center;">สร้าง มส.13</span>
        </div>
        <div>
          <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <i class="ti ti-x text-white"></i>
          </button>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col">
        <div class="flex flex-row justify-center">
          <div class="basis-3/12 p10" style="align-self: center;">ประเภทสินค้าที่ต้องการสร้าง มส. 13 </div>
          <div class="basis-1/2 f18 p10">
            <mat-form-field class="my-form-field formm w100">
              <mat-select [(ngModel)]="form.product" name="docNo" #docNo="ngModel" ngmodel>
                <mat-option *ngFor="let product of productList" [value]="product.productId">
                  {{product.productName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card-content>
    <div class="flex flex-col items-center p10">
      <div class="basis-1/2 f18">
        <button mat-raised-button [mat-dialog-close] style="flex:0 50%; margin-right: 10px;">
          ปิด
        </button>
        <button mat-raised-button style="width: 100px;" color="accent"
          [disabled]="onValid(createMs13Form)"
          (click)="createMs13()" 
          [mat-dialog-close]>
          ถัดไป
        </button>
      </div>
    </div>
  </mat-card>
</form>

<form #confirmMs24Form="ngForm">
  <mat-card *ngIf="pages == 'createMs24'">
    <mat-card-header class="fontTitleHeader">
      <div class="flex justify-between" style="width: 100%;">
        <div> สร้าง มส. 24</div>
        <div>
          <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <i class="ti ti-x text-white"></i>
          </button>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div class="basis-3/12 p10" style="align-self: center;">ประเภทสินค้าที่ต้องการสร้าง มส. 24 </div>
          <div class="basis-1/2 f18 p10">
            <mat-form-field class="my-form-field formm w100">
              <mat-label></mat-label>
              <mat-select [(ngModel)]="form.product" requested name="docNo" #docNo="ngModel" ngmodel (selectionChange)="dataListMs13()">
                <mat-option *ngFor="let product of productList" [value]="product.productId">
                  {{product.productName}}
                </mat-option>
              </mat-select>
              <mat-error>ประเภทสินค้า</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="basis-3/12 p10" style="align-self: center;">เลือกเลขที่คำร้อง มส. 13 </div>
          <div class="basis-1/2 f18 p10">
            <mat-form-field class="my-form-field formm w100">
              <mat-label></mat-label>
              <mat-select [(ngModel)]="form.refMs13No" requested name="refMs13No" #refMs13No="ngModel" ngmodel>
                <mat-option *ngFor="let refMs13 of refMs13List" [value]="refMs13.ms13Id">
                  {{refMs13.ms13No}}
                </mat-option>
              </mat-select>
              <mat-error>ประเภทสินค้า</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card-content>
    <div class="flex flex-col items-center p10">
      <div class="basis-1/2 f18">
        <button mat-raised-button [mat-dialog-close] style="flex:0 50%; margin-right: 10px;">
          ปิด
        </button>
        <button mat-raised-button style="width: 100px;" color="accent"
          (click)="confirmCreateMs24($event)"
          [disabled]="onValid(confirmMs24Form)"
          [queryParams]="{ source : 'M' , page :pages , product:  form.product , status : 1 , type : 'NEW' }"
          [mat-dialog-close]>
          ถัดไป
        </button>
      </div>
    </div>
  </mat-card>
</form>

<form #createMs24Form="ngForm">
  <mat-card *ngIf="pages == 'ms24'">
    <mat-card-header class="fontTitleHeader">
      <div class="flex justify-between" style="width: 100%;">
        <div> สร้าง มส. 24</div>
        <div>
          <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <i class="ti ti-x text-white"></i>
          </button>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col">
        <div class="flex flex-row">
          <form #FormValidateItem="ngForm" class="py-10">
            <div>
              <dx-data-grid id="gridDataMs13Detail" [dataSource]="dataMs13List" [columnAutoWidth]="true" [showBorders]="true"
                [allowColumnReordering]="true" [showBorders]="true">
                <!-- <dxo-editing mode="row" [allowUpdating]="true">
                </dxo-editing> -->
                <dxi-column caption="ลำดับ" alignment="center" cellTemplate="wFlow" [width]='100'>
                </dxi-column>
                <div *dxTemplate="let row of 'wFlow'">
                  {{row.rowIndex+1}}
                </div>
                <dxi-column alignment="center" dataField="goodsDescription" caption="รายการสินค้า">
                </dxi-column>
                <dxi-column alignment="center" cellTemplate="netWeight" caption="น้ำหนักรวม">
                </dxi-column>
                <div *dxTemplate="let row of 'netWeight'">
                  {{row.data.netWeight | number: '0.6' }} {{row.data.netWeightUnit}}
                </div>

                <dxi-column alignment="center" dataField="usedNet" caption="ออกใบ มส.24 ไปแล้ว">
                </dxi-column>
                <div *dxTemplate="let row of 'usedNet'">
                  {{row.data.usedNet | number: '0.6' }} {{row.data.netWeightUnit}}
                </div>

                <dxi-column alignment="center" cellTemplate="grossWeightNet" caption="น้ำหนักที่ออกใบ มส.24">
                </dxi-column>
                <div *dxTemplate="let row of 'grossWeightNet'">
                  <mat-form-field>
                    <input autocomplete="off" matInput placeholder="" type="number" #input
                      [(ngModel)]="row.data.grossWeightNet" name="grossWeightNet" #grossWeightNet="ngModel">
                  </mat-form-field>
                  {{row.data.netWeightUnit}}
                </div>
                
                <dxi-column alignment="center" cellTemplate="amountUsedMs13" caption="จำนวนที่ออกใบ มส.24">
                </dxi-column>
                <div *dxTemplate="let row of 'amountUsedMs13'">
                  <mat-form-field>
                    <input autocomplete="off" matInput placeholder="" type="number" #input
                      [(ngModel)]="row.data.amountUsedMs13" name="amountUsedMs13" #amountUsedMs13="ngModel">
                  </mat-form-field>
                  {{row.data.netWeightUnit}}
                </div>
              </dx-data-grid>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
    <div class="flex flex-col items-center p10">
      <div class="basis-1/2 f18">
        <button mat-raised-button [mat-dialog-close] style="flex:0 50%; margin-right: 10px;">
          ปิด
        </button>
        <button mat-raised-button style="width: 100px;" color="accent" routerLink="/page/o2/create-ms24"
          (click)="gotoCreateMs24()"
          [disabled]="onValid(createMs24Form)"
          [queryParams]="{ source : 'M' , page :pages , product:  confirmMs24Form.product , status : 1 , type : 'NEW', id: ms13Id, useQty: weightUit }"
          [mat-dialog-close]>
          ถัดไป
        </button>
      </div>
    </div>
  </mat-card>
</form>
<!-- </div> -->