import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMs13Service } from "app/business/service/app/app-ms13.service";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { SurveyorModalComponent } from "../../ms13/surveyor-modal/surveyor-modal.component";
import { AbstractControl, FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { environment } from "environments/environment";
import { AppO2Service } from "app/business/service/app/app-o2.service";
import { map, Observable, startWith } from "rxjs";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { ImageModalComponent } from "../../ms13/Image-modal/Image-modal.component";
import { AppSurveyorService } from "app/business/service/app/app-surveyor.service";
import { AuthService } from "app/core/auth/auth.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { Ms24RejectReasonComponent } from "../ms24-reject-reason/ms24-reject-reason.component";
import moment from "moment";

@Component({
  selector: "app-create-ms24-inquiry",
  templateUrl: "./create-ms24-inquiry.component.html",
  styleUrls: ["./create-ms24-inquiry.component.css"],
})
export class CreateMs24InquiryComponent implements OnInit {
  typePage: any;
  type: any;
  form: any = {};
  Data: any = {};
  dataSource: any = [];
  dataProductSource: any = [];
  formId: any;
  docNo: any;
  refNo: any;
  disabled: boolean = false;
  formO2: boolean = false;
  productId: any;
  productName: any;
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  ms13DForSur: any;
  buyerCountryForm: FormGroup;
  shippingMaskList: any = [];
  surveyorList: any = [];
  dropDownSurveyorList: any = [];
  statusList: any = [];
  shippingList: any = [];
  dataSourceAtt: any = [];
  fileImg: any;
  checkAddProduct: boolean = false;
  itemProduct: any = {};
  selectionProduct: any = [];
  currentDate: Date = new Date();
  export: any = {};
  companyList: any = [];
  formAttachment: any = {};
  currencyList: any = [];
  transModeList: any = [];
  countryList: any = [];
  portList: any = [];
  SurveyorForm: any = {};
  file: any = {};
  formFile: any = {};
  formStatus: any = {};
  myControl = new FormControl;
  filteredOptionsCompanyShipper$: Observable<string[]>;
  filteredOptionsCompanyExporter$: Observable<string[]>;
  filteredOptionsCompanyReceiver$: Observable<string[]>;
  filteredOptionsLoadedPortCountry$: Observable<string[]>;
  filteredOptions: Observable<string[]>;
  options: ["One", "Two", "Three"];
  //for rework
  mockData: any = {};
  value: any[] = [];
  url: any;
  buttonList: any[] = [];
  get countrySearch(): AbstractControl {
    return this.buyerCountryForm.get("countrySearch");
  }
  get currencySearch(): AbstractControl {
    return this.buyerCountryForm.get("currencySearch");
  }
  get currencyOtherSearch(): AbstractControl {
    return this.buyerCountryForm.get("currencyOtherSearch");
  }
  get countryISOSearch(): AbstractControl {
    return this.buyerCountryForm.get("countryISOSearch");
  }
  get countryRecieveSearch(): AbstractControl {
    return this.buyerCountryForm.get("countryRecieveSearch");
  }
  get countryLoadedPortSearch(): AbstractControl {
    return this.buyerCountryForm.get("countryLoadedPortSearch");
  }
  get companyShipperSearch(): AbstractControl {
    return this.buyerCountryForm.get("companyShipperSearch");
  }
  get companyExporterSearch(): AbstractControl {
    return this.buyerCountryForm.get("companyExporterSearch");
  }
  get companyReceiverSearch(): AbstractControl {
    return this.buyerCountryForm.get("companyReceiverSearch");
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private appMs13Service: AppMs13Service,
    private appMs24Service: AppMs24Service,
    private alertService: AlertMessageService,
    private appO2Service: AppO2Service,
    private confirmService: ConfirmDialogService,
    private appSuveryorService: AppSurveyorService,
    private fb: FormBuilder,
    private _authService: AuthService,
    private appMenuService: AppMenuService,
  ) {

    this.buyerCountryForm = new FormGroup({
      countrySearch: new FormControl(),
      currencySearch: new FormControl(),
      countryISOSearch: new FormControl(),
      countryRecieveSearch: new FormControl(),
      countryLoadedPortSearch: new FormControl(),
    });

    this.appMs13Service.mS24Attachment.subscribe(res => {
      this.dataSourceAtt = res;
    });

    this.appMasterService.countryData.subscribe((res) => {
      this.countryList = res;
    });

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.countryList.slice();
      }),
    );
  }

  async ngOnInit() {
    await this.getRouter();
    const {
      source,
      docNo,
      type,
      page,
      product,
      expireDate,
      issueDate,
      status,
      id,
      productId,
      refNo,
      category,
      vesselName,
      dftId,
      itemId,
      hId,
    } = this.route.snapshot.queryParams;
    this.typePage = page;
    this.formId = id;
    this.docNo = docNo;
    this.refNo = refNo;
    this.type = type;
    this.productId = productId;
    this.formAttachment.fileName = null;
    console.log("===>>>", this.typePage, this.formId, this.docNo, this.refNo, this.productId);

    const rqStatus = {
      hCode: "STATUS",
      statusId: 0,
      isActive: "Y",
    };
    await this.appMasterService.getStatus(rqStatus).then((res) => {
      this.statusList = res;
    });

    const rqTransMode = {
      ID: "",
    };
    await this.appMasterService.getTransMode(rqTransMode).then((res) => {
      this.transModeList = res;
    });

    await this.getCountry();

    this.form.shippingMarkNoPath = "assets/images/blankImg/blankImg.png";

    await this.getData();

    if (this.type == "Detail") {
      this.disabled = true;
    }
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;
    const spitUrl = this.url.split('?')
    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: spitUrl[0],
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
  async getCountry() {
    const rqCountry = {
      ID: "",
      deActive: "",
    };
    await this.appMasterService.getCountry(rqCountry);
  }

  async loadData(docNo, id) {
    const formData = {
      hId: id,
      docNo: docNo,
    };

    this.appMs24Service.getMS24fromMS13(formData).then((res) => {
      // [res] = [res].map((x) => {
      //   return {
      //     ...x,
      //     // netWeightPer : x.netWeight + " " + x.netWeightPerUnit
      //   };
      // });
      this.dataSource = [res];
    });

    await this.appMasterService.getCompanyList(null).then((res) => {
      this.companyList = res;
      this.filteredOptionsCompanyShipper$ = res.filter((f) => f.typeId == 5);
      this.filteredOptionsCompanyExporter$ = res.filter((f) => f.typeId == 2);
      this.filteredOptionsCompanyReceiver$ = res;
    });
    this.loadformMs13();
  }

  loadformMs13() {
    const formDataMs13 = {
      ID: this.formId,
      docNo: this.form.docNo,
      companyTax: this.userProfile.companyCode,
      refNo: this.form.refNo,
      productId: this.form.product,
    };
    console.log("getDataMS13 > ", formDataMs13);
    this.appMs13Service.getDataMS13(formDataMs13).then((res) => {
      console.log("getDataMS13 > ", res);
      if (res != null) {
        this.form = res;
        this.form.id = res.id;
        this.form.companyTax = res.companyTax;
        this.form.o2DocNo = res.docNo;
        this.form.o2ExpireDate = res.expireDate;
        this.form.o2IssueDate = res.issueDate;
        this.form.attachO2 = res.attachO2;
        this.form.source = res.source;
        this.form.refNoShow = res.refNo;
        this.form.refNo = res.refNo;
        this.form.fullRefNo = res.fullRefNo;
        this.form.exporterNo = res.exporter;
        this.productId = res.typeDocument;
        this.productName = res.typeGoods;
        this.form.productName = res.typeGoods;
        this.form.source = res.source;
        this.form.goodsName = res.typeGoods;
        this.form.typeGoodsz = Number(res.typeDocument);
        this.form.statusName = this.statusList.find((f) => f.statusId == res.status).statusName;
        this.form.transportationName = this.transModeList.find((f) => f.id == res.transportationMode).name;
        this.form.port = Number(res.port);
        this.dataSourceAtt.push(this.form.attachment);
        const dataShip = {};
        this.appMasterService.getShippingMask(dataShip).then((res) => {
          this.shippingMaskList = res.filter((f) => f.doc == this.productId);
          this.shippingMaskList.map((m) => {
            return {
              ...m,
              img: environment.api + m.paht,
            };
          });
          this.form.shippingMarkNo = this.form.shippingMark;
          this.form.shippingMarkNoPath = this.shippingMaskList.find(
            (f) => f.no == this.form.shippingMarkNo
          )?.path;
        });

        this.form.shippingProvince =
          this.form.shippingProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.shippingProvince;
        this.form.recieveProvince =
          this.form.recieveProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.recieveProvince;
        this.form.inspectProvince =
          this.form.inspectProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.inspectProvince;
        this.form.porttxt =
          this.form.porttxt == "ท่าเรือกรุงเทพฯ/แหลมฉบัง"
            ? "ท่ากรุงเทพฯ / แหลมฉบัง"
            : this.form.porttxt;
        this.buyerCountryForm = this.fb.group({
          countrySearch: [this.form.buyerCountry],
          currencySearch: [this.form.currency],
          countryISOSearch: [this.form.destinationCountry],
          countryRecieveSearch: [this.form.recieveCountry],
          countryLoadedPortSearch: [this.form.loadedPortCountryName],
        });

        if (this.disabled) {
          this.buyerCountryForm.disable();
        }
        console.log("this.form.ms13DForSur", this.form.ms13DForSur);
        this.ms13DForSur = this.form.ms13DForSur;
        const rqSurveyorDd = {
          surveyorID: "",
          isActive: "Y",
        };
        this.appMasterService.DropdownSurveyor(rqSurveyorDd).then((res) => {
          // ;
          this.surveyorList = res;
          this.form.inspectBy = Number(this.form.inspectBy);
          this.form.inspectByBy = this.surveyorList.find(
            (f) => f.surveyorID == this.form.inspectBy
          ).companyNameTH;
        });
        if (this.formId != null && this.form.o2Hid != null) {
          this.appO2Service
            .getO2item({
              ID: 0,
              hId: this.formId,
            })
            .then((res) => {
              this.dataSource = res.map((m) => {
                return {
                  ...m,
                  itemStatusName: this.statusList.find(
                    (f) => f.statusId == m.itemStatus
                  ).statusName,
                };
              });
              //this.dataSourceAtt.push(this.form.attachment);
              this.fileImg = this.form.attachO2;
            });
        } else {
          console.log("cmmS13H_ID", this.form);
          this.dataSource = this.form?.item?.map((m) => {
            return {
              ...m,
              itemStatusName: this.statusList.find(
                (f) => f.statusId == m.itemStatus
              )?.statusName,
            };
          });
          //this.dataSourceAtt.push(this.form.attachment);
          this.fileImg = this.form.attachO2;
        }
        this.fileImg = environment.api + this.form.attachO2;
      }
    });
  }

  backPage(): void {
    this.router.navigate(["page/ms24/ms24"], {
      //queryParams: { page: this.typePage },
    });
  }

  reset(): void {
    this.form = {};
  }

  selectionChanged(e) {
    this.selectionProduct = e.selectedRowKeys.map((res) => {
      return {
        ...res,
        productName: this.form.productName,
        weightUsedMs13: null,
        amountUsedMs13: null,
      };
    });
  }
  async getDropdownSurveyor() {
    const dialogRef = this.dialog.open(SurveyorModalComponent, {
      disableClose: true,
      maxWidth: "none",
      width: "65vw",
      data: {
        inspectBy: this.form.inspectBy,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.form.inspectBy = result.inspectBy.surveyorID;
      this.form.inspectByBy = result.inspectBy.companyNameTH;
    });
  }
  getData() {
    const formDataMs24 = {
      ID: this.formId,
      docNo: this.form.docNo,
      companyTax: this.userProfile.companyCode,
      productId: this.form.product,
      items: {
        ID: this.formId,
        hId: this.formId,
      },
    };
    this.appMs24Service.getDataMS24(formDataMs24).then((res) => {
      if (res != null) {
        this.form = res;
        if (res.item.length) {
          this.itemProduct = res.item[0];
        }
        this.dataSource = res.item;
        this.form.shippingProvince =
          this.form.shippingProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.shippingProvince;
        this.form.recieveProvince =
          this.form.recieveProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.recieveProvince;
        this.form.inspectProvince =
          this.form.inspectProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.inspectProvince;
        this.buyerCountryForm = this.fb.group({
          countrySearch: [this.form.buyerCountry],
          currencySearch: [this.form.currency],
          countryISOSearch: [this.form.destinationCountry],
          countryRecieveSearch: [this.form.recieveCountry],
          countryLoadedPortSearch: [this.form.loadedPortCountryName],
        });

        var loadedPortCountry = this.countryList.find(e => e.code === this.form.loadedPortCountry);

        this.myControl.patchValue(loadedPortCountry);

        if (this.disabled) {
          this.buyerCountryForm.disable();
          this.myControl.disable();
        }

        this.dataSource = this.form?.item?.map((m) => {
          return {
            ...m,
            itemStatusName: "",
          };
        });

        this.fileImg = environment.api + this.form.attachO2;

        const rqPort = {
          ID: Number(this.form.typeDoc),
          isActive: "Y",
        };

        this.appMasterService.getPort(rqPort).then((res) => {
          this.portList = res;
        });

        console.log("===>>>", this.form, res);

        this.formStatus.status = this.form.status;
      }
    });
    const formDataMs13 = {
      ID: this.formId,
    };
    this.appMs13Service.getDataMS24Attachment(formDataMs13);

  }
  getExporter(cpid: any): Promise<any> {
    const rqExport = {
      companyID: cpid,
      companyType: 2,
    };
    return new Promise((resolve, reject) => {
      this.appMasterService.getExporter(rqExport).then((ee) => {
        this.export = ee;
        if (this.export != null && this.export != undefined) {
          this.form.companyTax = this.export.taxId;
          this.form.companyThName = this.export.nameTH;
          this.form.companyEnName = this.export.nameEN;
          this.form.exporterStreetandNo = this.export.address;
          this.form.exporterProvince =
            this.export.province == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.export.province;
          this.form.exporterNo = this.export.exporterNo;
          this.form.exporterSubProvince = this.export.subProvince;
          this.form.exporterPostcode = this.export.postcode;
          this.form.exporterDistrict = this.export.nameEN;
          this.form.exporterCompanyId = this.export.companyId;
          this.form.signpic1 = this.export.signpic1;
          this.form.tradeMark = this.export.tradeMark;
          this.form.signNameTh1 = this.export.signNameTh1;
          this.form.position1 = this.export.position1;
          this.form.exporterId = this.export.companyId;
          this.appMasterService.getShipping(rqExport).then((res) => {
            this.shippingList = res;
          });
          this.buyerCountryForm
            .get("companyExporterSearch")
            .setValue(this.export.nameTH);
          resolve(this.form);
        }
      });
    });
  }
  async saveSurveryor() {
    // const req = {
    //   id: this.form.id,
    //   status: 3,
    //   userId: this.userProfile.uId,
    // };
    // this.appMs13Service.updateBotApprove(req).then(async (res) => {
    //   if (res != "error") {
    const data = this.SurveyorForm;
    const alertConfirm = await this.confirmService.open({
      header: "ท่านต้องการบันทึกหรือไม่",
      content: "ท่านต้องการบันทึกหรือไม่",
    });
    if (alertConfirm) {
      const formData = {
        // "RID":"",
        hId: "1",
        // "checkSeq":"",
        checkResult: data.result.check_result,
        checkQty: data.result.check_QTY,
        checkPackageResult: data.result.check_package_result,
        // "checkDate":"",
        surveyRemark: data.result.survey_remark,
        confirm: "Y",
        surveyAccept: data.MS13H_surveyor.survey_accept,
        surveyAcceptDate: data.MS13H_surveyor.survey_accept_date,
        surveyCheif: data.MS13H_surveyor.survey_cheif,
        surveyBy2: data.MS13H_surveyor.survey_by2,
        surveyOrder1: data.MS13H_surveyor.survey_order1,
        surveyBy3: data.MS13H_surveyor.survey_by3,
        surveyOrderDate1: data.MS13H_surveyor.survey_order_date1,
        surveyAnalyze: data.MS13H_surveyor.survey_analyze,
        surveyOrder2: data.MS13H_surveyor.survey_order2,
        surveyOrderDate2: data.MS13H_surveyor.survey_order_date2,
        // "ID":"",
        // "ihId":"",
        itemNo: "1",
        goodsDescription: data.result.goods_description,
        tariffCode: this.ms13DForSur.tariffCode,
        tariffSequence: this.ms13DForSur.tariffSeq,
        statisticalCode: this.ms13DForSur.statisCode,
        unitPrice: this.ms13DForSur.unitPrice,
        netWeight: this.ms13DForSur.netWeight,
        netWeightUnit: this.ms13DForSur.netWeightUnit,
        // "currency":"",
        fobValueBaht: this.ms13DForSur.fobValueBaht,
        valueBaht: "0",
        quantity: this.ms13DForSur.quantity,
        quantityUnit: this.ms13DForSur.quantityUnit,
        netWeightPerUnit: this.ms13DForSur.netWeightPerUnit,
        grossWeightPerUnit: this.ms13DForSur.grossWeightPerUnit,
        surveyGrossWeightPerUnit: data.result.survey_gross_weight_per_unit,
        surveyQty: data.result.survey_qty,
        surveyQtyUnit: data.result.survey_qty_unit,
        surveyNetWeight: data.result.survey_net_weight,
        surveyNetWeightPerUnit: data.result.survey_net_weight_per_unit,
        surveyNetWeightUnit: data.result.survey_net_weight_unit,
      };
      console.log("saveDataMs13Sur", formData);
      this.appSuveryorService.saveDataMs13Sur(formData).then((res) => {
        console.log(res);
      });
    }
    //   }
    // });
  }
  getExporterNo(exporterNo) {
    if (exporterNo.length >= 4) {
      var exporterNoData = this.companyList.find(
        (f) => f.exporterNo == exporterNo
      );
      if (exporterNoData != null && exporterNoData != undefined) {
        this.getExporter(exporterNoData.companyId);
      }
    }
  }
  async save(status) {
    // -สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "",
      content: "ท่านต้องการบันทึกหรือไม่",
    });
    var save = true;
    if (alertConfirm) {

      this.form.status = Number(status);
      this.userProfile.userTypeId = Number(this.userProfile.userTypeId);
      const formDataMS24 = {
        ms24HId: this.formId,
        vesselName: this.form.vesselName,
        loadedDateStart: this.form.loadedDateStart,
        port: this.form.port,
        loadedPortName: this.form.loadedPortName,
        transportationMode: this.form.transportationMode,
        loadedPortCountry: this.form.loadedPortCountry,
        checkResult: this.form.checkResult,
        checkPackageResult: this.form.checkPackageResult,
        status: this.form.status,
        expireDate: this.form.expireDate,
        remark: this.form.remark,
        docTypeId: this.form.typeDoc,
      };

      console.log(formDataMS24);
      if (save) {
        this.appMs24Service.updateDataMs24(formDataMS24).then((res) => {
          if (res != "error") {
            this.alertService
              .info({ title: "", message: "บันทึกข้อมูลเรียบร้อย" })
              .then((x) => {
                this.router.navigate(["page/ms24/ms24"], {

                });
                //this.getData();
                // this.router.navigate(["/page/ms24/create-ms24"], {
                //   queryParams: {
                //     page: "ms24",
                //     refNo: this.form.refNo,
                //     id: this.formId,
                //     product: this.productId,
                //     docNo: this.form.o2DocNo,
                //   },
                // });
              });
          }
        });
      }
    }
  }

  viewMs13Copy() {
    var rqPrintMs13Doc = {
      ms13hId: this.form.ms13Id,
      copyNumber: 1
    };

    this.appMs13Service.printCopyCertificateMS13(rqPrintMs13Doc).then(res => {
      if (res === 'error') {
        return;
      } else {
        if (res === null) {
          this.alertService.open({
            message: "ไม่พบสำเนา มส.13 กรุณาตรวจสอบ",
            title: ""
          });

          return;
        } else {
          var data = res;
          window.open(data.pathPdf, "_blank");
        }
      }
    });
  }

  async onFileChangedBr(event): Promise<void> {
    if (event.value.length === 0) {
      return;
    }
    if (this.formAttachment.fileName === undefined || this.formAttachment.fileName === null || this.formAttachment.fileName === '') {
      this.alertService.open({
        message: "กรุณากรอกชื่อเอกสารก่อนอัปโหลด",
        title: ""
      });
      this.value = [];
      return;
    }
    event.value.forEach((item) => {
      this.file = item;
      this.onUploadBr();
    });
    this.value = [];
  }
  deleteImg(e) {
    console.log("==>>", e);
    e.cmms24aIsActive = 'N';
    this.appMasterService.saveMs24Attachment(e).then(res => {
      console.log("API", res);
      const formDataMs13 = {
        ID: this.formId,
        docNo: this.form.docNo,
        companyTax: this.userProfile.companyCode,
        refNo: this.form.refNo,
        productId: this.form.product,
      };
      this.appMs13Service.getDataMS24Attachment(formDataMs13);
    });
  }
  onUploadBr(): void {
    const formData = new FormData();
    formData.append("image", this.file, this.file.name);
    formData.append("blobType", "CSCAttachments");
    formData.append("typePage", this.typePage);
    formData.append("action", "new");
    formData.append("linkDoc", this.formId ?? 0);
    this.appMasterService.uploadFile(formData).subscribe((result) => {
      console.log("uploadFile", result);
      if (result != null) {
        this.formFile = result[0];
        this.fileImg = result[0]?.filePath;
        this.form.pic = result[0]?.fileName + "." + result[0]?.fileType;
        this.formFile.appType = result[0]?.fileType;
        this.formFile.fileExtension = this.file.type;
        const attachment = {
          cmms24aFileName: this.formAttachment.fileName,
          cmms24aDocumentName: this.form.docNo,
          linkDoc: 1,
          documentContent: "image",
          cmms24aDocumentSize: this.formFile.fileByte,
          cmms24aFileExtension: this.formFile.fileExtension,
          cmms24aDocumentByte: this.formFile.fileByte,
          cmms24aPathFile: result[0]?.filePath,
          cmms24aIsActive: "Y",
          cmms24hId: this.formId
        };

        //SAVE Ms24 ATTACHMENT

        this.appMasterService.saveMs24Attachment(attachment).then(res => {
          console.log("API", res);
          const formDataMs13 = {
            ID: this.formId,
            docNo: this.form.docNo,
            companyTax: this.userProfile.companyCode,
            refNo: this.form.refNo,
            productId: this.form.product,
          };
          this.appMs13Service.getDataMS24Attachment(formDataMs13);
        });
      }
    });
  }

  showImg(e) {
    const dialogRef = this.dialog.open(ImageModalComponent, {
      disableClose: true,
      // maxWidth: "none",
      width: "45vw",
      height: "35vw",
      data: {
        path: e.cmms24aPathFile,
        title: "เอกสารผลวิเคราะห์",
      },
    });
  }


  filterLoadedPortCountryRecieveValues(search: any) {
    // ;
    console.log(search);
    const filterValue = search.toLowerCase();

    return this.countryList.filter(function (option) {
      if (option.name.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue)) {
        return option;
      }
    });
  }

  selectionLoadedPortCountryChange(e): void {
    this.form.loadedPortCountry = e.option.value.name;
    this.form.loadedPortCountryCode = e.option.value.code;
  }

  displayFn(country): string {
    return country && country.name ? `${country.name}` : '';
  }

  setPort(e) {
    const porttxt = this.portList.find((f) => f.id == e.value);
    this.form.porttxt = porttxt?.name;
    this.form.port = porttxt?.id;
  }

  public _filter(varSearch) {
    // ;
    console.log(varSearch);
    const filterValue = varSearch.toLowerCase();

    return this.countryList.filter(function (option) {
      if (option.name.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue)) {
        return option;
      }
    });
  }

  checkResultApproval() {
    if (this.form.status === 7 || this.form.status === 9 || this.form.status === 13 || this.form.status === 14 || this.form.status === 15) {
      return true;
    } else {
      if (this.userProfile.gId === 'UG004') {
        return true;
      } else {
        return false;
      }
    }
  }

  updateStatusApprove() {
    var status = this.statusList.find(e => e.statusId == this.formStatus.status);
    var content = "คุณต้องการบันทึกสถานะเอกสาร มส.24 ใหม่เป็น \"" + status.statusName + "\" หรือไม่?";

    if (this.formStatus.status == 14) {
      content = "คุณต้องการบันทึกสถานะเอกสาร มส.24 ใหม่เป็น \"" + status.statusName + "\" ด้วยเหตุผล \"" + this.formStatus.rejectReason + "\" หรือไม่?";
    }

    this.confirmService.open({
      header: "",
      content: content
    }).then(res => {
      if (res) {
        var rqUpdateMs24Status = {
          hId: this.form.id,
          status: this.formStatus.status,
          rejectReason: "",
        };

        if (this.formStatus.status === 14) {
          rqUpdateMs24Status.rejectReason = this.formStatus.rejectReason;
        }

        this.appMs24Service.updateMs24ApproveStatus(rqUpdateMs24Status).then(res => {
          if (res === 'error') {
            return;
          } else {
            this.alertService.open({
              message: "บันทึกสถานะใหม่เรียบร้อยแล้ว",
              title: ""
            }).then(res => {
              this.router.navigate(["page/ms24/ms24"], { });
          });
          }
        });
        console.log(rqUpdateMs24Status);
      }
    });
  }

  checkMs24Status() {
    if (this.userProfile.gId != 'UG001' && this.userProfile.gId != 'UG004') {
      return false;
    } else {
      return true;
    }
  }

  checkStatus(e) {
    if (e.value == 14) {
      this.dialog.open(Ms24RejectReasonComponent, {
        width: '50vw',
        disableClose: true,
      }).afterClosed().subscribe(res => {
        this.formStatus.rejectReason = res;
      });
    } else {
      this.formStatus.rejectReason = null;
    }
  }

  openTab(e) {
    window.open(e, '_blank').focus();
  }

  checkButtonDiv() {
    if (this.userProfile.gId === 'UG004') {
      if (this.form.status == '5' || this.form.status == '14') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  checkExporterButtonDiv() {
    if (this.userProfile.gId !== 'UG004') {
      return !(this.disabled);
    } else {
      return false;
    }
  }

  checkFormStaff() {
    if (this.userProfile.gId === 'UG004') {
      if (this.form.status == '2') {
        return false;
      } else {
        return true;
      }
    } else {
      return (this.disabled);
    }
  }

  checkCompleteForm() {
    if (this.userProfile.gId === 'UG004') {
      if (this.form.status == '9') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  onGoodsLoadDateChange() {
    const e: any = moment(this.form.goodsLoadDate).add('9', 'days');
    this.form.goodsLoadDateEnd = e;
  }

  saveLoadDate() {
    this.confirmService.open({
      header: "",
      content: "ท่านต้องการบันทึกการแก้ไขข้อมูลหรือไม่?"
    }).then(res => {
      if (res) {
        var rqUpdateLoadDate = {
          ms24HId: this.formId,
          goodsLoadDateStart: this.form.goodsLoadDate,
          goodsLoadDateEnd: this.form.goodsLoadDateEnd,
          vesselName: this.form.vesselName
        };

        this.appMs24Service.updateMs24GoodLoadDate(rqUpdateLoadDate).then(res => {
          if (res === 'error') {
            return;
          } else {
            this.alertService.open({
              message: "บันทึกข้อมูลใหม่เรียบร้อยแล้ว",
              title: ""
            }).then(res => {
              this.router.navigate(["page/ms24/ms24"], {});
            })
          }
        })
      }
    })
  }
}

