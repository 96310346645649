export const country = [
    {
      "code": "AF",
      "name": "AFGHANISTAN",
      "currency": "AFA",
      "wtocountry": "F",
      "deActive": ""
    },
    {
      "code": "AG",
      "name": "ANTIGUA AND BARBUDA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "AL",
      "name": "ALBANIA",
      "currency": "ALL",
      "wtocountry": "F",
      "deActive": ""
    },
    {
      "code": "AM",
      "name": "ARMENIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "AN",
      "name": "NETHERLANDS ANTILLES",
      "currency": "ANG",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "AO",
      "name": "ANGOLA",
      "currency": "AON",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "AR",
      "name": "ARGENTINA",
      "currency": "ARS",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "AS",
      "name": "AMERICAN SAMOA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "AT",
      "name": "AUSTRIA",
      "currency": "ATS",
      "wtocountry": "T",
      "deActive": ""
    },
    {
      "code": "AU",
      "name": "AUSTRALIA",
      "currency": "AUD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "AW",
      "name": "ARUBA",
      "currency": "AWG",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "AZ",
      "name": "AZERBAIJAN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BB",
      "name": "BARBADOS",
      "currency": "BBD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "BD",
      "name": "BANGLADESH",
      "currency": "BDT",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "BE",
      "name": "BELGIUM",
      "currency": "BEF",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "BF",
      "name": "BURKINA FASO",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BG",
      "name": "BULGARIA",
      "currency": "BGL",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BH",
      "name": "BAHRAIN",
      "currency": "BHD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "BI",
      "name": "BURUNDI",
      "currency": "BIF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BJ",
      "name": "BENIN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BM",
      "name": "BERMUDA",
      "currency": "BMD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BN",
      "name": "BRUNEI DARUSSALAM",
      "currency": "BND",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BO",
      "name": "BOLIVIA",
      "currency": "BOB",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BR",
      "name": "BRAZIL",
      "currency": "BRL",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "BS",
      "name": "BAHAMAS",
      "currency": "BSD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BT",
      "name": "BHUTAN",
      "currency": "INR",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BV",
      "name": "BOUVET ISLAND",
      "currency": "NOK",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BW",
      "name": "BOTSWANA",
      "currency": "BWP",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "BY",
      "name": "BELARUS",
      "currency": "BYB",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "BZ",
      "name": "BELIZE",
      "currency": "BZD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "CA",
      "name": "CANADA",
      "currency": "CAD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "CC",
      "name": "COCOS (KEELING) IS.S",
      "currency": "AUD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CF",
      "name": "CENTRAL AFRICAN REP.",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CG",
      "name": "CONGO",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CH",
      "name": "SWITZERLAND",
      "currency": "CHF",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "CI",
      "name": "COTE D IVOIRE",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CK",
      "name": "COOK ISLANDS",
      "currency": "NZD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CL",
      "name": "CHILE",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "CM",
      "name": "CAMEROON",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CN",
      "name": "CHINA",
      "currency": "CNY",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CO",
      "name": "COLOMBIA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "CR",
      "name": "COSTA RICA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "CU",
      "name": "CUBA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CV",
      "name": "CAPE VERDE",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CX",
      "name": "CHRISTMAS ISLAND",
      "currency": "AUD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CY",
      "name": "CYPRUS",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "CZ",
      "name": "CZECH REPUBLIC",
      "currency": "CZK",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "DE",
      "name": "GERMANY",
      "currency": "DEM",
      "wtocountry": "F",
      "deActive": ""
    },
    {
      "code": "DJ",
      "name": "DJIBOUTI",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "DK",
      "name": "DENMARK",
      "currency": "DKK",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "DM",
      "name": "DOMINICA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "DO",
      "name": "DOMINICAN REPUBLIC",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "DZ",
      "name": "ALGERIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "EC",
      "name": "ECUADOR",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "EE",
      "name": "ESTONIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "EG",
      "name": "EGYPT",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "EH",
      "name": "WESTERN SAHARA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "ER",
      "name": "ERITREA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "ES",
      "name": "SPAIN",
      "currency": "ESP",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "ET",
      "name": "ETHIOPIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "FI",
      "name": "FINLAND",
      "currency": "FIM",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "FJ",
      "name": "FIJI",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "FK",
      "name": "FALKLAND ISLANDS (MALVINAS)",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "FM",
      "name": "MICRONESIA (FEDERATED STATES OF)",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "FO",
      "name": "FAROE ISLANDS",
      "currency": "DKK",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "FR",
      "name": "FRANCE",
      "currency": "FRF",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "FX",
      "name": "FRANCE, METROPOLITAN",
      "currency": "FRF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GA",
      "name": "GABON",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "GB",
      "name": "UNITED KINGDOM",
      "currency": "GBP",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "GD",
      "name": "GRENADA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GE",
      "name": "GEORGIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GF",
      "name": "FRENCE GUIANA",
      "currency": "FRF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GH",
      "name": "GHANA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "GI",
      "name": "GIBRALTAR",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GL",
      "name": "GREENLAND",
      "currency": "DKK",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GM",
      "name": "GAMBIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GN",
      "name": "GUINEA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GP",
      "name": "GUADELOUPE",
      "currency": "FRF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GQ",
      "name": "EQUATORIAL GUINEA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GR",
      "name": "GREECE",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GS",
      "name": "SOUTH",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GT",
      "name": "GUATEMALA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GU",
      "name": "GUAM",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GW",
      "name": "GUINEA-BISSAU",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "GY",
      "name": "GUYANA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "HK",
      "name": "HONG KONG",
      "currency": "HKD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "HM",
      "name": "HEARD ISLAND AND MCDONALD ISLANDS",
      "currency": "AUD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "HN",
      "name": "HONDURAS",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "HR",
      "name": "CROATIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "HT",
      "name": "HAITI",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "HU",
      "name": "HUNGARY",
      "currency": "HUF",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "ID",
      "name": "INDONESIA",
      "currency": "IDR",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "IE",
      "name": "IRELAND",
      "currency": "IEP",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "IL",
      "name": "ISRAEL",
      "currency": "ILS",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "IN",
      "name": "INDIA",
      "currency": "INR",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "IO",
      "name": "BRITISH INDIAN OCEAN TERRITORY",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "IQ",
      "name": "IRAQ",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "IR",
      "name": "IRAN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "IS",
      "name": "ICELAND",
      "currency": "ISK",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "IT",
      "name": "ITALY",
      "currency": "ITL",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "JM",
      "name": "JAMAICA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "JO",
      "name": "JORDAN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "JP",
      "name": "JAPAN",
      "currency": "JPY",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "KE",
      "name": "KENYA",
      "currency": "KES",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "KG",
      "name": "KYRGYZSTAN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "KH",
      "name": "CAMBODIA",
      "currency": "KHR",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "KI",
      "name": "KIRIBATI",
      "currency": "AUD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "KM",
      "name": "COMOROS",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "KN",
      "name": "SAINT KITTS AND NEVIS",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "KP",
      "name": "KOREA",
      "currency": "KRW",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "KR",
      "name": "KOREA,REPUBLIC OF",
      "currency": "KRW",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "KW",
      "name": "KUWAIT",
      "currency": "KWD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "KY",
      "name": "CAYMAN ISLANDS",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "KZ",
      "name": "KAZAKHSTAN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "LA",
      "name": "LAO REPUBLIC",
      "currency": "LAK",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "LB",
      "name": "LEBANON",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "LC",
      "name": "SAINT LUCIA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "LI",
      "name": "LIECHTENSTEIN",
      "currency": "CHF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "LK",
      "name": "SRI LANKA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "LR",
      "name": "LIBERIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "LS",
      "name": "LESOTHO",
      "currency": "ZAL",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "LT",
      "name": "LITHUANIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "LU",
      "name": "LUXEMBOURG",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "LV",
      "name": "LATVIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "LY",
      "name": "LIBYAN ARAB JAMAHIRIYA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MA",
      "name": "MOROCCO",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MC",
      "name": "MONACO",
      "currency": "FRF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MD",
      "name": "MOLDOVA REPUBLIC OF",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MG",
      "name": "MADAGASCAR",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MH",
      "name": "MARSHALL ISLAND",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MK",
      "name": "MACEDONIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "ML",
      "name": "MALI",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MM",
      "name": "MYANMAR",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MN",
      "name": "MONGOLIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MO",
      "name": "MACAU",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "MP",
      "name": "NORTHERN MARIANA ISLANDS",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MQ",
      "name": "MARTINIQUE",
      "currency": "FRF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MR",
      "name": "MAURITANIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MS",
      "name": "MONTSERRAT",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MT",
      "name": "MALTA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MU",
      "name": "MAURITIUS",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MV",
      "name": "MALDIVES",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MW",
      "name": "MALAWI",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "MX",
      "name": "MEXICO",
      "currency": "MXN",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "MY",
      "name": "MALAYSIA",
      "currency": "MYR",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "MZ",
      "name": "MOZAMBIQUE",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "NA",
      "name": "NAMIBIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "NC",
      "name": "NEW CALEDONIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "NE",
      "name": "NIGER",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "NF",
      "name": "NORFOLK ISLAND",
      "currency": "AUD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "NG",
      "name": "NIGERIA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "NI",
      "name": "NICARAGUA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "NL",
      "name": "NETHERLANDS",
      "currency": "NLG",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "NO",
      "name": "NORWAY",
      "currency": "NOK",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "NP",
      "name": "NEPAL",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "NR",
      "name": "NAURU",
      "currency": "AUD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "NU",
      "name": "NIUE",
      "currency": "NZD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "NZ",
      "name": "NEW ZEALAND",
      "currency": "NZD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "OM",
      "name": "OMAN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "PA",
      "name": "PANAMA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "PE",
      "name": "PERU",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "PF",
      "name": "FRENCH POLYNESIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "PG",
      "name": "PAPUA NEW GUINEA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "PH",
      "name": "PHILIPPINES",
      "currency": "PHP",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "PK",
      "name": "PAKISTAN",
      "currency": "PKR",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "PL",
      "name": "POLAND",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "PM",
      "name": "SAINT PIERRE AND MIQUELON",
      "currency": "FRF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "PN",
      "name": "PITCAIRN",
      "currency": "NZD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "PR",
      "name": "PUERTO RICO",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "PT",
      "name": "PORTUGAL",
      "currency": "PTE",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "PW",
      "name": "PALAU",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "PY",
      "name": "PARAGUAY",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "QA",
      "name": "QATAR",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "RE",
      "name": "REUNION",
      "currency": "FRF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "RO",
      "name": "ROMANIA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "RU",
      "name": "RUSSIAN FEDERATION",
      "currency": "RUR",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "RW",
      "name": "RWANDA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SA",
      "name": "SAUDI ARABIA",
      "currency": "SAR",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SB",
      "name": "SOLOMON ISLANDS",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SC",
      "name": "SEYCHELLES",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SD",
      "name": "SUDAN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SE",
      "name": "SWEDEN",
      "currency": "SEK",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "SG",
      "name": "SINGAPORE",
      "currency": "SGD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "SH",
      "name": "SAINT HELENA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SI",
      "name": "SLOVENIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SJ",
      "name": "SVALBARD AND JAN MAYEN",
      "currency": "NOK",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SK",
      "name": "SLOVAKIA",
      "currency": "SKK",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SL",
      "name": "SIERRA LEONE",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SM",
      "name": "SAN MARINO",
      "currency": "ITL",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SN",
      "name": "SENEGAL",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "SO",
      "name": "SOMALIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SR",
      "name": "SURINAME",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "ST",
      "name": "SAO TOME AND PRINCIPE",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SV",
      "name": "EL SALVADOR",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SY",
      "name": "SYRIAN ARAB REPUBLIC",
      "currency": "SYP",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "SZ",
      "name": "SWAZILAND",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TC",
      "name": "TURKS AND CAICOS ISLANDS",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TD",
      "name": "CHAD",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TF",
      "name": "FRENCH SOUTHERN TERRITORIES",
      "currency": "FRF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TG",
      "name": "TOGO",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TH",
      "name": "THAILAND",
      "currency": "THB",
      "wtocountry": "T",
      "deActive": ""
    },
    {
      "code": "TJ",
      "name": "TAJIKISTAN",
      "currency": "RUR",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TK",
      "name": "TOKELAU",
      "currency": "NZD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TM",
      "name": "TURKMENISTAN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TN",
      "name": "TUNISIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TO",
      "name": "TONGA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TP",
      "name": "EAST TIMOR",
      "currency": "IDR",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TR",
      "name": "TURKEY",
      "currency": "TRL",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TV",
      "name": "TUVALU",
      "currency": "AUD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TW",
      "name": "TAIWAN PROVINCE OF CHINA",
      "currency": "TWD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "TZ",
      "name": "TANZANIA UNITED REPUBLIC OF",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "UA",
      "name": "UKRAINE",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "UG",
      "name": "UGANDA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "UM",
      "name": "UNITED STATES MINOR",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "US",
      "name": "UNITED STATES",
      "currency": "USD",
      "wtocountry": "T",
      "deActive": ""
    },
    {
      "code": "UY",
      "name": "URUGUAY",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "UZ",
      "name": "UZBEKISTAN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "VA",
      "name": "VATICAN CITY",
      "currency": "ITL",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "VC",
      "name": "SAINT VINCENT AND THE GRENADINES",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "VE",
      "name": "VENEZUELA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "VG",
      "name": "VIRGIN ISLANDS (BRITISH)",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "VI",
      "name": "VIRGIN ISLANDS (U.S.)",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "VN",
      "name": "VIETNAM",
      "currency": "VND",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "VU",
      "name": "VANUATU",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "WF",
      "name": "WALLIS AND FUTUNA ISLANDS",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "WS",
      "name": "SAMOA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "YE",
      "name": "YEMEN",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "YT",
      "name": "MAYOTTE",
      "currency": "FRF",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "YU",
      "name": "YUGOSLAVIA",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "ZA",
      "name": "SOUTH AFRICA",
      "currency": "ZAL",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "ZM",
      "name": "ZAMBIA",
      "currency": "USD",
      "wtocountry": "Y",
      "deActive": ""
    },
    {
      "code": "ZR",
      "name": "ZAIRE",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "ZW",
      "name": "ZIMBABWE",
      "currency": "USD",
      "wtocountry": "N",
      "deActive": ""
    },
    {
      "code": "OT",
      "name": "OTHER COUNTRY",
      "currency": "",
      "wtocountry": "F",
      "deActive": ""
    },
    {
      "code": "AE",
      "name": "UNITED ARAB EMIRATES",
      "currency": "",
      "wtocountry": "",
      "deActive": ""
    },
    {
      "code": "TT",
      "name": "TRINIDAD AND TOBAGO",
      "currency": "USD",
      "wtocountry": "",
      "deActive": ""
    }
   ]