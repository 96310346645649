import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: 'root'
  })
  export class ApiGroupListService {
  
    constructor(private apiService: ApiService) { }
  
    getGroupList(request: any): Observable<any[]> {
      return this.apiService.post('/api/getGroupList',request);
    }

    AdjustGroup(request: any): Observable<any[]> {
      return this.apiService.post('/api/AdjustGroup', request);
    }
    
    CompanyDropdown(request: any): Observable<any[]> {
      return this.apiService.post('/api/CompanyDropdown', request);
    }

    ListGroup(request: any): Observable<any[]> {
      return this.apiService.post('/api/listGroup',request);
    }
  
    GetStaffDataGroupList(request: any): Observable<any[]> {
      return this.apiService.post('/api/getstaffdatagrouplist',request);
    }
    SaveDataConfigGroup(request: any): Observable<any> {
      return this.apiService.post('/api/savedataConfigGroup',request);
    }
    GetDataConfGroup(request: any): Observable<any> {
      return this.apiService.post('/api/getdataconfgroup',request);
    }
  }
  