import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ConfigBankPopupComponent } from './config-bank-popup/config-bank-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { Router } from '@angular/router';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-config-bank-inquiry',
  templateUrl: './config-bank-inquiry.component.html',
  styleUrls: ['./config-bank-inquiry.component.scss'],
  //encapsulation: ViewEncapsulation.None,
})
  
export class ConfigBankInquiryComponent implements OnInit, AfterViewInit {
  BenefitTarget: any = [];
  form: any = {};
  BenefitTargetValue: string;
  displayedColumns: string[] = ['select', 'bankCode', 'bankAccountName', 'bankAccountNo', 'bankBranch','accTaxNo','isBotAcc','action'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  url: any;
  buttonList: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appBankService: AppBankService,
    private router: Router,
    private _authService:AuthService,
    private appMenuService: AppMenuService,
  ) {
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit(): Promise<void> {
    await this.getRouter();
    this.BenefitTarget = [{ benefitTargetCode: 1, benefitTargetTName: '10' }, { benefitTargetCode: 2, benefitTargetTName: '20' }];
    this.form.isActive = 'A';
    this.loadData();
  }
  loadData() {
    const data = {
      "bankCode": "",
      "bankAccountNo": this.form.bankAccountNo,
      "bankAccountName": this.form.bankAccountName,
      "bankBranch": "",
      "accType": this.form.accType,
      "accTaxNo": this.form.accTaxNo,
      "isBotAcc": this.form.isBotAcc,
      isActive: this.form.isActive
    };
    this.appBankService.bankAccount(data).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.dataSource = new MatTableDataSource(res)
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        console.log(res)
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selectionPercent() {
    
  }
  editItem(e) {
    const data = {...e};
    const dialogRef = this.dialog.open(ConfigBankPopupComponent, {
      disableClose: false,
      width: '60vw',
      data: data,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.loadData();
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    console.log(this.selection.selected, 'this.isAllSelected');
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
    console.log(this.selection.selected, 'this.selection');
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log("hello");
  }

  save() {
    
  }

  addAccount(data): void {
    const dialogRef = this.dialog.open(ConfigBankPopupComponent, {
      disableClose: false,
      width: '60vw',
      data: null,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.loadData();
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  delete(e) {
    console.log(e)
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      width: '30vw',
      data: {
        header: 'คุณต้องการลบข้อมูลนี้หรือไม่',
        button: 'open'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        const data = {
          "bankAccountID": e.id,
          "bankCode": e.bankCode,
          "bankAccountNo": e.bankAccountNo,
          "bankAccountName": e.bankAccountName,
          "bankBranch": e.bankBranch,
          "accType": e.accType,
          "accTaxNo": e.accTaxNo,
          "isBotAcc": e.isBotAcc,
          "isActive": 'N'
        };
        this.appBankService.saveBankAccount(data).then((res: any) => {
          if (res === "error") {
            return;
          } else {
            this.loadData();
          }
        });
      }
    });
   
  }

  deleteAccount() {
    this.selection.selected.forEach((item, index, chats) => {
      const data = {
        "bankAccountID": item.id,
        "bankCode": item.bankCode,
        "bankAccountNo": item.bankAccountNo,
        "bankAccountName": item.bankAccountName,
        "bankBranch": item.bankBranch,
        "accType": this.form.accType,
        "accTaxNo": item.accTaxNo,
        "isBotAcc": item.isBotAcc,
        "isActive": 'N'
      };
      this.appBankService.saveBankAccount(data).then((res: any) => {
        if (res === "error") {
          return;
        } else {
          this.loadData();
        }
      });
    });
    
  }

}
