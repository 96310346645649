import { filter, Observable } from "rxjs";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppPaymentService } from "app/business/service/app/app-payment";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";
import _ from "lodash";
import {
  FormGroup,
  AbstractControl,
  FormBuilder,
  FormControl,
} from "@angular/forms";

@Component({
  selector: "app-add-other-payment-request-modal",
  templateUrl: "./add-other-payment-request-modal.component.html",
  styleUrls: ["./add-other-payment-request-modal.component.css"],
})
export class AddOtherPaymentRequestModalComponent implements OnInit {
  form: any = {};
  detailRc: any = {};
  detailExdList: any = [];
  feeRateList: any = [];
  dataSource: any = [];
  dataObjList: any = [];

  isShowList: boolean = false;
  docForm: FormGroup;
  filteredOptionsDoc$: Observable<any[]>;
  get countrySearch(): AbstractControl {
    return this.docForm.get("docSearch");
  }
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddOtherPaymentRequestModalComponent>,
    private appMasterService: AppMasterService,
    private appPaymentService: AppPaymentService,
    private _authService: AuthService,
    private fb: FormBuilder
  ) {
    this.formInit();
    this.userProfile = JSON.parse(this._authService.loginUser);
  }

  async ngOnInit() {
    this.formInit();
    // this.form.id = 1;
    await this.appMasterService.getFormRate().then((t) => {
      this.feeRateList = t;
      this.feeRateList = this.feeRateList
        .filter((f) => f.formType == "M")
        .map((m) => {
          return {
            ...m,
            code: m.activitiesCode,
            name: m.description,
            feeType: m.formType,
            val: m.price,
            id: m.fromId,
            feeunit: m.unitName,
            type: m.flagDocumentNo,
            isActive: "Y",
          };
        });

      this.form.id = 1;
      this.isShowList = true;

      this.form.fee = this.feeRateList.find((f) => f.fromId == 1);
      this.form.documentNoWording = this.feeRateList.find(
        (f) => f.id == 1
      ).documentNoWording;
      this.form.flagDocumentNo = this.feeRateList.find(
        (f) => f.id == 1
      ).flagDocumentNo;
      this.loadData("24").then((res) => {
        this.dataObjList = res.filter((f) => f.typeDoc == 1);
        this.filteredOptionsDoc$ = this.dataObjList;
      });
    });
  }

  selectionFeeRate(e) {
    this.form.qty = null;
    this.form.remark = null;
    this.docForm = this.fb.group({
      docSearch: null,
    });
    this.form.documentNoWording = e.value.documentNoWording;
    this.form.flagDocumentNo = e.value.flagDocumentNo;
    this.filteredOptionsDoc$ = null;
    if (e.value.code == "240001") {
      this.isShowList = true;
      this.loadData("24").then((res) => {
        this.dataObjList = res.filter((f) => f.typeDoc == 1);
        this.filteredOptionsDoc$ = this.dataObjList;
      });
    } else if (
      e.value.code == "130003" ||
      e.value.code == "130004" ||
      e.value.code == "130005"
    ) {
      this.isShowList = true;
      this.loadData("13").then((res) => {
        this.dataObjList = res;
        this.filteredOptionsDoc$ = this.dataObjList;
      });
    } else if (
      e.value.code == "240002" ||
      e.value.code == "240003" ||
      e.value.code == "240004"
    ) {
      this.isShowList = true;
      this.loadData("24").then((res) => {
        this.dataObjList = res;
        this.filteredOptionsDoc$ = this.dataObjList;
      });
    } else {
      this.isShowList = false;
    }
    // else if (e.value.code == "130001") {
    //   this.isShowList = true;
    //   this.loadData("13").then((res) => {
    //     this.dataObjList = res.filter((f) => f.productId == 3);
    //     this.filteredOptionsDoc$ = this.dataObjList;
    //   });
    // }
    //  else if (e.value.code == "130002") {
    //   this.loadData("13").then((res) => {
    //     this.filteredOptionsDoc$ = this.dataObjList;
    //   });
    //   this.filteredOptionsDoc$ = this.dataObjList;
    // }  else if (e.value.code == "240002") {
    //   this.loadData("24").then((res) => {
    //     this.filteredOptionsDoc$ = this.dataObjList;
    //   });
    //   this.filteredOptionsDoc$ = this.dataObjList;
    // }
    this.docForm = this.fb.group({
      docSearch: [this.form.fullRefNo],
    });

    this.docForm.valueChanges.subscribe((newValue) => {
      this.filteredOptionsDoc$ = this.filterCompanyDocSearchValues(
        newValue.fullRefNo
      );
    });

    // console.log("selectionFeeRate >> ", e);
  }

  filterCompanyDocSearchValues(search: any) {
    if (search != "") {
      return this.dataObjList.filter(
        (value) =>
          value?.fullRefNo?.toLowerCase().indexOf(search?.toLowerCase()) === 0
      );
    } else {
      return this.dataObjList;
    }
  }
  formInit(): void {
    this.docForm = new FormGroup({
      docSearch: new FormControl(),
    });

    this.docForm = this.fb.group({
      docSearch: [""],
    });
  }
  selectionDocChange(e): void {
    this.form.remark = e.option.value.fullRefNo;
    this.docForm.get("docSearch").setValue(e.option.value.fullRefNo);
  }
  tabChanged(e) {}

  loadData(type): any {
    return new Promise((resolve, reject) => {
      const formData = {
        uId: Number(this.userProfile.uId),
        type: type,
      };

      this.appPaymentService.paymentObjForFreerateList(formData).then((res) => {
        this.dataObjList = res;
        // console.log("ref", this.dataObjList);
        // this.dataObjList = this.dataSource.map((m) => {
        //   return {
        //     ...m,
        //     detailId: m.ms24Id,
        //   };
        // });
        resolve(res);
      });
    });
  }
  // loadData(type) {
  //   const formData = {
  //     uId: Number(this.userProfile.uId),
  //     type: type,
  //   };

  //   this.appPaymentService.paymentObjForFreerateList(formData).then((res) => {
  //     this.dataObjList = res;
  //     console.log("ref", this.dataObjList);
  //   });
  // }

  addDetail(res) {
    if (this.form.code == "130003") {
    } else if (this.form.code == "240002") {
    }
    // console.log("addDetail >> ", res);
    // this.dialogRef.close(res);
  }
}
