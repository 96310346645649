<div fxLayout="column" fxLayoutGap="20px">
    <dx-data-grid id="gridLogOTPList" [dataSource]="dataSourceList" [showBorders]="false" [allowColumnReordering]="true"
        [rowAlternationEnabled]="true" [allowColumnResizing]="true" [columnAutoWidth]="true" class="mt-12 mb-12"
        [noDataText]="'ไม่มีข้อมูล'" [remoteOperations]="{ paging: true}">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[10, 30, 50, 100]" [showInfo]="true">
        </dxo-pager>
        <dxo-scrolling [useNative]="true" mode="standard" [scrollByContent]="true" [scrollByThumb]="true"
            showScrollbar="onHover"></dxo-scrolling>
        <dxo-pager *ngIf="dataSourceList?.length > 0" [showPageSizeSelector]="true" [visible]="true"
            [allowedPageSizes]="false"></dxo-pager>

        <dxo-search-panel [visible]="false"></dxo-search-panel>
        <dxo-filter-row [visible]="true" [showAllText]="'ทั้งหมด'"></dxo-filter-row>
        <dxi-column dataField="index" caption="ลำดับ" dataType="string" alignment="center" [width]="60"
            cellTemplate="index">
        </dxi-column>
        <div *dxTemplate="let row of 'index'">
            {{ (row.rowIndex+1) +(dataGrid.instance.pageIndex() * dataGrid.instance.pageSize() ) }}
        </div>
        <!-- <dxi-column [filterOperations]="['contains']" dataField="userCode" caption="รหัสผู้ใช้" dataType="string">
        </dxi-column> -->
        <dxi-column [filterOperations]="['contains']" dataField="userName" caption="Username" dataType="string">
        </dxi-column>
        <dxi-column [filterOperations]="['contains']" dataField="staffName" caption="ชื่อผู้ใช้" dataType="string">
        </dxi-column>
        <dxi-column [filterOperations]="['contains']" dataField="userGroupName" caption="กลุ่มผู้ใช้" dataType="string">
        </dxi-column>
        <dxi-column [filterOperations]="['contains']" dataField="userRoleName" caption="สิทธิ์ผู้ใช้" dataType="string">
        </dxi-column>
        <dxi-column alignment="center" dataField="isActive" caption="สถานะ" dataType="string" cellTemplate="status">
            <dxo-lookup valueExpr="code" displayExpr="name" [dataSource]="lookupStatusCode">
            </dxo-lookup>
        </dxi-column>
        <dxi-column [filterOperations]="['contains']" cellTemplate="editor" caption="ผู้บันทึก"></dxi-column>
        <dxi-column caption="แก้ไข" cellTemplate="edit"> </dxi-column>
        <div *dxTemplate="let row of 'status'">
            <div *ngIf="row.data?.isActive == 'Y'" fxLayout="row" fxLayoutAlign="center center">
                ใช้งาน
            </div>
            <div *ngIf="row.data?.isActive == 'N'" fxLayout="row" fxLayoutAlign="center center">
                ยกเลิก
            </div>
        </div>
        <div *dxTemplate="let row of 'editor'">
            {{row.data.updateByName ? row.data.updateByName + " " + row.data.updateDate : row.data.createByName + " " +
            row.data.createDate }}
        </div>
        <div *dxTemplate="let row of 'edit'">
            <!-- <div *ngIf="checkButton('BT001')"> -->
            <button mat-icon-button (click)="editUser(row.data)" matTooltip="แก้ไข" matTooltipPosition="above">
                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
            </button>
            <!-- </div> -->
        </div>
    </dx-data-grid>
</div>