<div class="flex flex-col" style="padding: 20px; gap: 20px; align-items: center;">
  <div class="flex flex-row p10" style="width: 72%">
    <div class="basis-1/2 f18" style="align-self: center;"> ระบุอัตราแลกเปลี่ยน
      <label style="color:red">*</label> :
    </div>
    <div class="basis-4/5">
      <mat-form-field class="w100">
        <input autocomplete="off" required type="number" matInput placeholder="อัตราแลกเปลี่ยน" #input
          [(ngModel)]="form.exchangeRate" name="exchangeRate" #exchangeRate="ngModel">
        <mat-error>กรุณาระบุ</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div>
    <button class="p10" mat-raised-button color="accent" [mat-dialog-close]="form">
      ตกลง
    </button>
  </div>
</div>
