import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { IResGetDataMs24RecordStaffList } from "app/business/interface/app/app-ms24.interface";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { Ms24RecordLogDetailComponent } from "../ms24-record-log-detail/ms24-record-log-detail.component";



@Component({
  selector: 'app-ms24-record-staff-inquiry',
  templateUrl: './ms24-record-staff-inquiry.component.html',
  styleUrls: ['./ms24-record-staff-inquiry.component.css']
})
export class Ms24RecordStaffInquiryComponent implements OnInit {

  searchForm: any ={};
  form: any = {};
  message :string;
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "o2DocNoResponse",
    "companyNameResponse",
    "producttypeResponse",
    "vesselNameResponse",
    "ms13DocNoResponse",
    "ms24RefNoResponse",
    "ms24StatusResponse",
    "botReviewByUsernameResponse",
    "action",
  ];
  dataView :IResGetDataMs24RecordStaffList[]=[];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppMs24Service: AppMs24Service,
    private ConfirmDialogService: ConfirmDialogService,
    private AlertMessageService : AlertMessageService
  ) { 

  }

  ngOnInit() {
    this.loadData();
  }
  async loadData(): Promise<void> {
    //send data to api
    const formData = {
      o2DocNoRequest : this.searchForm.o2DocNo,
      companyNameRequest : this.searchForm.companyName,
      producttypeRequest : this.searchForm.productType,
      vesselNameRequest : this.searchForm.vesselName,
      ms13DocNoRequest : this.searchForm.ms13DocNo,
      ms24RefNoRequest : this.searchForm.ms24RefNo,
      ms24StatusRequest : this.searchForm.status,
      BotReviewByUsernameRequest : this.searchForm.botReviewByUsername,
    };
    this.AppMs24Service.getMs24StaffList(formData).then((res) => {
      if(res){
        this.dataView = res;
        this.dataSource = new MatTableDataSource(this.dataView);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  stopPropagation(event) {
    event.stopPropagation();
  }
  showLogMs24Staff(HeadId) {
    const dialogRef = this.dialog.open(Ms24RecordLogDetailComponent, {
      disableClose: false,
      width: '60vw',
      data: HeadId,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res == "OK") {
        this.loadData();
      }
      
    });
  }
  exportMs24StaffPDF(e) {
    const formData = {
      HeadID : e.ms24HeaderIdResponse, 
         };
       this.AppMs24Service.MS24RecordStaffDocument(formData).then((res) => {
        (this.form.CompanyStaff = res)
  
        window.open(
        this.form.CompanyStaff.data.linkUrl,
        '_blank'
      );
      console.log(this.form.CompanyStaff.data.linkUrl,formData);
    });
      
    }
  
}
