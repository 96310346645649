<mat-card>
 <mat-card-header  class="fontTitleHeader" >
    <div class="flex justify-between" style="width: 100%;">
      <div  class="fontTitleModal" > เอกสารม.ส.13 </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <mat-icon class="close-icon">close</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <!-- <pdf-viewer [src]="form.linkMs13Export" [original-size]="false" [zoom]="true" [render-text]='true' scrolling="auto"
      [show-all]="true" style="display: block; height: 400px;" [fit-to-page]="true">
    </pdf-viewer> -->
    <pdf-viewer [src]="urlFormmm" [original-size]="false" [zoom]="true" [render-text]='true' scrolling="auto"
      [show-all]="true" style="display: block; height: 400px;" [fit-to-page]="true">
    </pdf-viewer>
    <a mat-mini-fab href="{{form.linkMs13Export}}" target="_blank" class="my-fab">
      <mat-icon>zoom_in</mat-icon>
    </a>
  </mat-card-content>
</mat-card>