import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";
import { IGetButtonPermissionResponse, IGetMenuAllResponse, IGetMenuButtomAllResponse, ISetPermissionMenuRequest } from "app/business/interface/api/api-menu.interface";

@Injectable({
  providedIn: "root",
})
export class ApiMenuService {
  constructor(private apiService: ApiService) { }

  getMenuPermission(): Observable<any[]> {
    return this.apiService.post('/api/getMenuPermission', {});
  }
  getButtonPermissionPerPages(data: any): Observable<IGetButtonPermissionResponse[]> {
    return this.apiService.post('/api/getButtonPermissionPerPages', data);
  }
  getButtonAll(data: any): Observable<IGetMenuButtomAllResponse> {
    return this.apiService.post('/api/getButtonAll', data);
  }
  savePermissionMenuOrButton(data: ISetPermissionMenuRequest): Observable<any> {
    return this.apiService.post('/api/savePermissionMenuOrButton', data);
  }
  getMenuAll(data: any): Observable<IGetMenuAllResponse> {
    return this.apiService.post('/api/getMenuAll', data);
  }
}
