import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IGetReceiverResponse, IReceiverListResponse } from "app/business/interface/app/app-receiver.interface";
import { ApiReceiver } from "../api/api-receiver.service";
import { IApiGetListReceiverRequest, IApiGetReceiverRequest, IApiReceiverSaveRequest } from "app/business/interface/api/api-receiver.interface";

@Injectable({
    providedIn: "root",
  })
  export class AppReceiverService {
    
    listReceiver: BehaviorSubject<IReceiverListResponse>;
    getReceiver : BehaviorSubject<IGetReceiverResponse>;

    
    constructor(private apiReceiver: ApiReceiver) {
        this.listReceiver = new BehaviorSubject<IReceiverListResponse>(null);
        this.getReceiver = new BehaviorSubject<IGetReceiverResponse>(null);
    }
  
    GetReceiverList(dataReq: any ): Promise<any[]> {
      return new Promise((resolve, reject) => {
        this.apiReceiver.GetListReceiver(dataReq).subscribe((res) => {
            this.listReceiver.next(res);
          resolve(res);
        });
      });
    }

    ReceiverSave(dataReq: any ): Promise<any> {
        return new Promise((resolve, reject) => {
          this.apiReceiver.ReceiverSave(dataReq).subscribe((res) => {
              this.listReceiver.next(res);
            resolve(res);
          });
        });
    }
    

    GetDataReceiver(dataReq: IApiGetReceiverRequest ): Promise<IGetReceiverResponse> {
        return new Promise((resolve, reject) => {
          this.apiReceiver.GetDataReceiver(dataReq).subscribe((res) => {
              this.listReceiver.next(res);
            resolve(res);
          });
        });
    }
  }
  