import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppO2Service } from "app/business/service/app/app-o2.service";
import { CreateProductGoodsItemModalComponent } from "../../o-two/create-product-goods-item-modal/create-product-goods-item-modal.component";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: 'app-o2-viewer',
  templateUrl: './o2-viewer.component.html',
  styleUrls: ['./o2-viewer.component.css'],
  providers: [DecimalPipe]
})
export class O2ViewerComponent implements OnInit {
  form: any = {};
  dataSource: any = [];
  dataSourceAttO2: any = [];
  statusList: any[] = [];
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));

  constructor(
    public appO2Service: AppO2Service,
    public appMasterService: AppMasterService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<O2ViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    const id = data.formData.o2Hid;
    this.loadData(id)
  }

  ngOnInit() {
  }

  loadData(e) {
    this.appMasterService.statusData.subscribe(res => {
      this.statusList = res;
    })

    const req = {
      ID: e
    };

    this.appO2Service.getdataO2(req).then(res => {
      if (res === 'error') {
        this.dialogRef.close();
      } else {
        this.form = res;
        this.form.formatExchangeRate = this.form.exchangeRate.toFixed(6);
        this.dataSource = res.item.map(el => {
          return {
            ...el,
            itemStatusName: this.statusList.find(m => m.statusId === el.itemStatus)?.statusName
          }
        });
      }
    })
  }

  addProduct(id, data): void {
    const dialogRef = this.dialog.open(CreateProductGoodsItemModalComponent, {
      disableClose: true,
      maxWidth: "none",
      width: "65vw",
      data: {
        hId: this.form.id,
        status: this.form.status,
        type: "info",
        id: id,
        exchangeRate: this.form.exchangeRate,
        page: "o2",
        item: data,
        currency: this.form.currency,
      },
    });
  }
}
