import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: 'root'
  })
  export class ApiDFTService {
  
    constructor(private apiService: ApiService) { }
  
    ExporterList(request: any): Observable<any[]> {
      return this.apiService.post('/api/getExporterList',request);
    }

    getExporterDataDetail(request: any): Observable<any[]> {
      return this.apiService.post('/api/getExporterData', request);
    }

    getSurveyorList(request: any): Observable<any[]> {
      return this.apiService.post('/api/getSurveyorList',request);
    }

    getDataSurveyor(request: any): Observable<any[]> {
      return this.apiService.post('/api/getDataSurveyor',request);
    }
    
    updateDataSurveyor(request: any): Observable<any[]> {
      return this.apiService.post('/api/updateSurveyor',request);
    }
  }
  