import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AuthService } from "app/core/auth/auth.service";
import { DischargePortManagePopupComponent } from "./discharge-port-manage-popup/discharge-port-manage-popup.component";

@Component({
  selector: "app-discharge-port-manage-inquiry",
  templateUrl: "./discharge-port-manage-inquiry.component.html",
  styleUrls: ["./discharge-port-manage-inquiry.component.css"],
})
export class DischargePortManageInquiryComponent implements OnInit {
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  searchForm: any = {};
  form: any = {};
  countryList: any = [];
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = ["dischargePortName", "countryName", "action"];
  url: any;
  buttonList: any[] = [];
  searchString: any;
  count: any;
  timer: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private router: Router,
    private _authService: AuthService,
    private appMenuService: AppMenuService,
    private alertMessageService: AlertMessageService,
    private confirmMessage: ConfirmDialogService,
    private alertMessage: AlertMessageService
  ) {}

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode,
    };
    await this.appMenuService
      .getButtonPermissionPerPages(formData)
      .then((bb) => {
        this.buttonList = bb;
      });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);
    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    await this.getRouter();
    this.loadData();
  }

  loadData() {
    this.appMasterService.getCountry({}).then((res) => {
      if (res.length === 0) {
        this.countryList = [];
      } else {
        this.countryList = res;

        const CheckAll = this.countryList.find(
          (element) => element.provinceName === "ทั้งหมด"
        );
        if (CheckAll === undefined) {
          this.countryList.unshift({ id: 0, name: "ทั้งหมด", code: "" });
        }
      }
    });

    this.loadDataSource();
  }

  async loadDataSource() {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    const formData = {
      id: this.searchForm.id,
      dischargePortName: this.searchForm.dischargePortName,
      countryId: this.searchForm.countryId,
      isActive: this.searchForm.isActive,
      keyword: this.searchForm.keyword,
      pagination: {
        Skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appMasterService
      .getMasterDischargePortList(formData)
      .then((res: any) => {
        if (res != null) {
          if (res === "error") {
            return;
          } else {
            if (res != null) {
              let a = res.item;

              this.dataSource.data = a;
              this.count = res.totalItem;
            } else {
              this.dataSource.data = [];
              this.count = 0;
            }
          }
        }
      });

    this.dataSource.paginator.length = this.count;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: Event) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.searchForm.keyword = event;
      this.dataSource.paginator = this.paginator;
      this.loadDataSource();
    }, 500); // Specify the desired delay in milliseconds
  }

  async pageChanged(e: any) {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    this.searchForm.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };

    const formData = {
      id: this.searchForm.id,
      dischargePortName: this.searchForm.dischargePortName,
      countryId: this.searchForm.countryId,
      isActive: this.searchForm.isActive,
      keyword: this.searchForm.keyword,
      pagination: {
        Skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appMasterService
      .getMasterDischargePortList(formData)
      .then((res: any) => {
        if (res != null) {
          if (res === "error") {
            return;
          } else {
            if (res != null) {
              let a = res.item;

              this.dataSource.data = a;
              this.count = res.totalItem;
            } else {
              this.dataSource.data = [];
            }
          }
        }
      });

    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = e.pageIndex;
  }

  onCountryChange(e) {
    if (e.value != 0) {
      this.searchForm.countryId = e.value;
    } else {
      this.searchForm.countryId = null;
    }

    this.loadDataSource();
  }

  addItem(e) {
    const dialogRef = this.dialog.open(DischargePortManagePopupComponent, {
      disableClose: false,
      width: '60vw',
      data: null,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.loadDataSource();
      }
    });

  }

  editItem(e) {
    const data = { ...e };
    const dialogRef = this.dialog.open(DischargePortManagePopupComponent, {
      disableClose: false,
      width: '60vw',
      data: data,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.loadDataSource();
    });
  }

  delete(e) {
    this.confirmMessage.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
      if (res) {
        const data = {
          id: e.id,
          dischargePortName: e.dischargePortName,
          countryId: e.countryId,
          isActive: "N"
        };

        this.appMasterService.saveMasterDischargePort(data).then((res: any) => {
          if (res === "error") {
            return;
          } else {
            this.loadDataSource();
          }
        });
      }
    });
  }
}
