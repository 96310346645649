import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: 'root'
  })
  export class ApiExternalService {
  
    constructor(private apiService: ApiService) { }
  
    getLicenseO2External(request): Observable<any> {
        return this.apiService.post('/api/getLicenseO2External', request);
    }
  }
  