<div>
    <h3 mat-dialog-title align="center" style="margin-bottom: 0px;">{{data.header}}</h3>
    <mat-dialog-actions align="center" *ngIf="data.button == 'open'">
        <button mat-stroked-button [mat-dialog-close]="false">
            ยกเลิก
        </button>
        <button mat-raised-button class="primary" style="background-color: gray !important;
  color: white!important; " [mat-dialog-close]="true" cdkFocusInitial>
            ยืนยัน
        </button>
    </mat-dialog-actions>
    <div mat-dialog-actions align="center" *ngIf="data.button == 'close'">
        <button mat-raised-button class="primary" style="background-color: gray !important;
  color: white!important; " [mat-dialog-close]="true" cdkFocusInitial>
            ปิด
        </button>
    </div>
</div>
  