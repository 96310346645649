import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiBankService } from '../api/api-bank.service';

@Injectable({
  providedIn: 'root'
})
export class AppBankService {

  constructor(private apiBankService: ApiBankService,) { }

  getTestList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiBankService.getTestList(request).subscribe((res) => {
        resolve(res);
        });
    });
  }
  

  bankAccount(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiBankService.bankAccount(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveBankAccount(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiBankService.saveBankAccount(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  
  bankCode(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiBankService.bankCode(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
