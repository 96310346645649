import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { Router } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppReportService } from "app/business/service/app/app-report.service";

import moment from "moment";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-report-filter",
  templateUrl: "./report-filter.component.html",
  styleUrls: ["./report-filter.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ReportFilterComponent implements OnInit {
  url: any;
  selectYear=[];
  form: any = {};
  jsonDisPlayConfig;
  productList: any = {};
  statusOptionList: any = [];
  countryOptionList: any = [];
  branchList: any = [];
  userList: any = [];
  userFinancialList: any = [];
  branchRCList: any = [];
  dailyReport: any = {};
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  myControl = new FormControl("");
  myDate = new Date();
  dailyReportForm: FormGroup = this.formBuilder.group({
    month: "",
    year: "",
    startDate: ["", Validators.required],
    startDateOnly:[""],
    endDate: ["", Validators.required],
    typeList: [""],
    statusList: [],
    branch:  [""], 
    branchCode:  [""], 
    userNamePayment:  [""], 
    userNameVerify:  [""], 
    userNameCreate:  [""], 
    userNameCSC:  [""], 
  });

  selectMonthFilterList = [
    { no: 1, month: "มกราคม", tai: "1" },
    { no: 2, month: "กุมภาพันธ์", tai: "1" },
    { no: 3, month: "มีนาคม", tai: "1" },
    { no: 4, month: "เมษายน", tai: "2" },
    { no: 5, month: "พฤษภาคม", tai: "2" },
    { no: 6, month: "มิถุนายน", tai: "2" },
    { no: 7, month: "กรกฎาคม", tai: "3" },
    { no: 8, month: "สิงหาคม", tai: "3" },
    { no: 9, month: "กันยายน", tai: "3" },
    { no: 10, month: "ตุลาคม", tai: "4" },
    { no: 11, month: "พฤศจิกายน", tai: "4" },
    { no: 12, month: "ธันวาคม", tai: "4" },
  ];
  today = new Date();
  @Output() formSearch = new EventEmitter<any>();
  constructor(
    private router: Router,
    private alertService: AlertMessageService,
    private formBuilder: FormBuilder,
    private appReport: AppReportService,
    private appMasterService: AppMasterService,
    private appMenuService: AppMenuService
  ) {}

  async ngOnInit() {
    this.url = this.router.url;
    this.loadDisplayCofig();
    await this.loadData();
  }
  async loadData() {
    this.setYear(2015);
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: 0,
      isActive: "Y",
    };
    await this.appMasterService.getProduct(formProduct).then((product) => {
      //console.log(product);
      this.productList = product;
    });

    const rqStatus = {
      hCode: "STATUS",
      statusId: 0,
      isActive: "Y",
      valueList: null,
    };

    if (this.url == "/page/report/reportms24") {
      rqStatus.valueList = "9,13";
    } else if (this.url == "/page/report/reportsummaryapprovems24") {
      rqStatus.valueList = "4,7";
    }
    await this.appMasterService.getStatus(rqStatus).then((res) => {
      this.statusOptionList = res;
    });
    const rqCountry = {
      isActive: "Y"
    };
    await this.appMasterService.getCountry(rqCountry).then((res) => {
      this.countryOptionList = res;
    });

    await this.appMasterService.getBranch().then((res) => {
      this.branchList = res;
    });

    var rquserList = {
      isFinancial: "N"
    }
    await this.appMasterService.getUserTCC(rquserList).then((res) => {
      this.userList = res;
    });

    var rquserList = {
      isFinancial: "Y"
    }
    await this.appMasterService.getUserTCC(rquserList).then((res) => {
      this.userFinancialList = res;
    });
  }

  setYear(year): void {
    const selectYear = [];
    let todayYear = this.today.getFullYear();
    while (year <= todayYear) {
      selectYear.push(todayYear);
      todayYear = todayYear - 1;
    }

    this.selectYear = selectYear;
  }
  loadDisplayCofig() {
    this.appMenuService.getMenuPermission().then((res) => {
      // 
      let menuList = res;
      let masterChidren = [];
      // ค้นหา chidren
      let child = menuList
        .filter((a) => a.children?.length !== 0)
        .flatMap((b) => b.children)
        .filter((c) => c);

      let i = 0;

      while (child?.length !== 0) {
        masterChidren = [];
        child.forEach((item) => {
          masterChidren.push(item);
          i = i + 1;
        });

        menuList = [...menuList, masterChidren];
        child = child
          .filter((d) => d)
          .filter((a) => a.children?.length !== 0)
          .flatMap((b) => b.children)
          .filter((e) => e);
      }
      const spitUrl = this.url.split("?");
      const jsonDisPlayConfig = menuList
        .flatMap((a) => a)
        .find((a) => a.url === spitUrl[0])?.jsonDisPlayConfig; 
      this.form = JSON.parse(jsonDisPlayConfig);
      // console.log("jsonDisPlayConfig" , jsonDisPlayConfig);
      // 
    });
  }
  functionChangeEvent(e): void {
    //this.form = this.dailyReportForm;
    //console.log("formSearch : ", this.form);
    this.formSearch.emit(this.dailyReportForm);
  }
}
