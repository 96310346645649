import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IResNotifyList } from '../notifications.types';
import { Router } from '@angular/router';
import { OverlayRef } from '@angular/cdk/overlay';

@Component({
  selector: 'app-notification-details',
  templateUrl: './notification-details.component.html',
  styleUrls: ['./notification-details.component.scss']
})
export class NotificationDetailsComponent implements OnInit {
  notifyData: IResNotifyList;
  timeAgo: any = {};

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<NotificationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) data: IResNotifyList
  ) {
    this.notifyData = data;
    this.timeAgo = this.calculateTimeAgo(this.notifyData.notificationDate);
    console.log(this.notifyData, this.timeAgo);
  }

  ngOnInit() {
  }

  convertDate(dateStr: any) {
    var date = new Date(dateStr);
    var thaiDate = date.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return thaiDate;
  }

  redirectToRefLink(notification: IResNotifyList) {
    //<!-- [routerLink]="notification.linkRef" [queryParams]="notification.refData" -->
    this.router.navigateByUrl("/dashboard", { skipLocationChange: true }).then(() => {
      this.router.navigate([notification.linkRef], { queryParams: notification.refData });
    });

    this.dialogRef.close(true);
  }

  calculateTimeAgo(pastDate: any): { minutesAgo: number, hoursAgo: number } {
    // Parse the past date string into a Date object
    const pastDateObj = new Date(pastDate);

    // Get the current date and time
    const currentDateObj = new Date();

    // Calculate the difference in milliseconds
    const differenceInMillis = currentDateObj.getTime() - pastDateObj.getTime();

    // Convert milliseconds to minutes and hours
    const minutesAgo = Math.floor(differenceInMillis / (1000 * 60));
    const hoursAgo = Math.floor(differenceInMillis / (1000 * 60 * 60));

    return { minutesAgo, hoursAgo };
  }
}
