import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { NswTrackingResponseModalComponent } from '../nsw-tracking-response-modal/nsw-tracking-response-modal.component';

@Component({
  selector: 'app-nsw-tracking-round-list',
  templateUrl: './nsw-tracking-round-list.component.html',
  styleUrls: ['./nsw-tracking-round-list.component.css']
})
export class NswTrackingRoundListComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "index",
    "referenceNo",
    "statusName",
    "lastUpdate",
    "action",
  ];
  clickBody: boolean = false;
  form: any = {};
  datas: any = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
  ) {
    console.log(data);
    this.datas = data;
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  loadData() {
    var request = {
      docId: this.datas.docId
    };

    this.appMasterService.getNswDocumentItemTrackingList(request).then((res: any) => {
      if (res === 'error') {
        return;
      } else {
        if (res === null) {
          return;
        } else {
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    })
  }

  openInfo(e) {
    this.dialog.open(NswTrackingResponseModalComponent, {
      data: e,
      disableClose: false,
      width: '65vw',
    });
  }
}
