import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppMs13Service } from 'app/business/service/app/app-ms13.service';
import { AppMs24Service } from 'app/business/service/app/app-ms24.service';
import { AppO2Service } from 'app/business/service/app/app-o2.service';
import { AuthService } from 'app/core/auth/auth.service';
import moment from 'moment';
import { ImageModalComponent } from '../Image-modal/Image-modal.component';
import { Ms13ViewerComponent } from '../ms13-viewer/ms13-viewer.component';

@Component({
  selector: 'app-renew-ms13-inquiry',
  templateUrl: './renew-ms13-inquiry.component.html',
  styleUrls: ['./renew-ms13-inquiry.component.css']
})
export class RenewMs13InquiryComponent implements OnInit {
  form: any = {};
  formAttachment: any = {};
  fileImg: any;
  file: any;
  value: any[] = [];
  typePage: any;
  typePageName: any;
  id: any;
  dataSource: any = [];
  uploadPass: boolean = false;
  disabled: boolean = false;
  buttonList: any[] = [];
  url: any;
  isStaff: boolean = false;
  isDetail: boolean = false;
  formFile: any = {};

  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  constructor(
    private dialogRef: MatDialogRef<RenewMs13InquiryComponent>,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private appMaster: AppMasterService,
    private appMs13Service: AppMs13Service,
    private appO2Service: AppO2Service,
    private alertService: AlertMessageService,
    private appMs24Service: AppMs24Service,
    private confirmService: ConfirmDialogService,
    private _authService: AuthService,
    private appMenuService: AppMenuService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.form = data.item;
    this.isStaff = data.isStaff;
    this.isDetail = data.isDetail;
  }

  async ngOnInit() {
    await this.getRouter();
    this.id = this.form.id;
    console.log("int ", this.id);

    this.appMs13Service.mS13Attachment.subscribe(res => {
      this.dataSource = res;
    });

    this.getRenew();
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: null,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };

    await this.appMenuService.getButtonPermissionPerPages(formData)
      .then((bb) => {
        this.buttonList = bb
      });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);
    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  getRenew() {
    this.appMs13Service.getRenewMS13({ ID: this.id }).then((res) => {
      this.form = res;
      this.form.expireDateMax = new Date();

      var maxDay = moment(new Date(this.form.expireDate))
        .add(30, "days")
        .format("YYYY-MM-DD");
      this.form.expireDateMax = new Date(maxDay);

      if (this.form.newExpireDate === undefined || this.form.newExpireDate === null)
      {
        this.alertService.open({
          message: "คุณยังไม่ได้ขอต่ออายุเอกสาร อ.2 กรุณาต่ออายุเอกสาร อ.2 ก่อนดำเนินการ",
          title: ""
        })
        this.dialogRef.close();
      }
      this.form.docNo = this.form.docNo ?? "-";
    });

    const formDataMs13 = {
      ID: this.id,
      docNo: this.form.docNo,
      companyTax: this.userProfile.companyCode,
      refNo: this.form.refNo,
      productId: this.form.product,
    };

    this.appMs13Service.getDataMS13Attachment(formDataMs13);

    // } else if (this.typePage == "ms13") {
    // } else {
    //   this.appMs24Service.getrenewMS24({ ID: this.id }).then((res) => {
    //     this.form = res;
    //     this.form.expireDateMax = new Date();
    //     var maxDay = moment(new Date(this.form.expireDate))
    //       .add(1, "days")
    //       .format("YYYY-MM-DD");
    //     this.form.expireDateMax = new Date(maxDay);
    //     // console.log(this.form.expireDateMax);
    //   });
    // }
  }

  async onFileChangedBr(event): Promise<void> {
    if (event.value.length === 0) {
      return;
    }
    if (this.formAttachment.fileName === undefined || this.formAttachment.fileName === null || this.formAttachment.fileName === '') {
      this.alertService.open({
        message: "กรุณากรอกชื่อเอกสารก่อนอัปโหลด",
        title: ""
      });
      this.value = [];
      return;
    }
    event.value.forEach((item) => {
      this.file = item;
      this.onUploadBr();
    });
    this.value = [];
  }

  async sentRenew(status) {
    if (this.dataSource > 0) {
      this.alertService.open({
        message: "กรุณาอัปโหลดเอกสารประกอบคำร้องขอต่ออายุ มส.13 อย่างน้อย 1 รายการ",
        title: ""
      });
      return;
    } else {
      var rqRenewMs13 = {
        id: this.id,
        refNo: this.form.docNo,
        expireDate: this.form.expireDate,
        status: status,
        newExpireDate: null
      }

      if (status === 9) {
        if ((this.form.newExpireDate instanceof Date)) {
          var newDate = moment(this.form.newExpireDate);

          var adjustedDate = newDate.toISOString(true);

          this.form.newExpireDate = adjustedDate
        }

        rqRenewMs13.newExpireDate = this.form.newExpireDate;
      }

      this.appMs13Service.SentRenewMs13(rqRenewMs13).then((res) => {
        if (res != "error") {
          var message = this.isStaff ? 'บันทึกผลอนุมัติการขอต่ออายุเอกสารแล้ว' : "คุณได้ดำเนินการขอต่ออายุ มส.13 ดังกล่าวแล้ว ขณะนี้ทางเจ้าหน้าที่กำลังดำเนินการ"
          this.alertService.info({
            title: "",
            message: message,
          }).then((x) => {
            if (!this.isStaff) {
              this.router.navigate(["page/ms13/list-renew-ms13"], {});
            }
            this.dialogRef.close(true);
          });
        }
      });
    }

    //   } else if(this.typePage == "ms24") {
    //   this.appMs24Service
    //     .renewMS24sent({
    //       attachment: this.file.linkDoc,
    //       docNo: this.form.docNo,
    //       expireDate: this.form.expireDate,
    //     })
    //     .then((res) => {
    //       if (res != "error") {
    //         this.alertService
    //           .info({
    //             title: "",
    //             message:
    //               "คุณได้ดำเนินการขอต่ออายุ มส.24 ดังกล่าวแล้ว ขณะนี้ทางเจ้าหน้าที่กำลังดำเนินการ",
    //           })
    //           .then((x) => {
    //             this.router.navigate(["page/ms24/ms24"], {
    //               queryParams: { page: this.typePage },
    //             });
    //           });
    //       }
    //     });
    // }
  }

  onUploadBr(): void {
    const formData = new FormData();
    formData.append("image", this.file, this.file.name);
    formData.append("blobType", "CSCAttachments");
    formData.append("typePage", "ms13");
    formData.append("action", "new");
    formData.append("linkDoc", this.id ?? 0);
    this.appMaster.uploadFile(formData).subscribe((result) => {
      console.log("uploadFile", result);
      if (result != null) {
        this.formFile = result[0];
        this.fileImg = result[0]?.filePath;
        this.form.pic = result[0]?.fileName + "." + result[0]?.fileType;
        this.formFile.appType = result[0]?.fileType;
        this.formFile.fileExtension = this.file.type;

        const attachment = {
          fileName: this.formFile.fileName,
          documentName: this.form.docNo,
          linkDoc: 1,
          documentContent: "image",
          documentSize: this.formFile.fileByte,
          fileExtension: this.formFile.fileExtension,
          docByte: this.formFile.fileByte,
        };

        const attachmentSaveMs13 = {
          cmms13aAttachmentId: null,
          cmms13aFileName: this.formAttachment.fileName,
          cmms13aDocumentName: this.form.docNo,
          cmms13hId: this.id,
          cmms13aDocumentSize: this.formFile.fileByte,
          cmms13aFileExtension: this.formFile.fileExtension,
          cmms13aDocumentByte: this.formFile.fileByte,
          cmms13aIsActive: "Y",
          cmms13aReason: null,
          cmms13aPathFile: this.formFile.filePath
        }

        this.appMaster.saveMs13Attachment(attachmentSaveMs13).then(res => {
          console.log("API", res);
          const formDataMs13 = {
            ID: this.id,
            docNo: this.form.docNo,
            companyTax: this.userProfile.companyCode,
            refNo: this.form.refNo,
            productId: this.form.product,
          };
          this.appMs13Service.getDataMS13Attachment(formDataMs13);
        });
        this.formAttachment.fileName = null;
      }
    });
  }

  showImg(file) {
    const dialogRef = this.dialog.open(ImageModalComponent, {
      disableClose: true,
      // maxWidth: "none",
      width: "45vw",
      height: "35vw",
      data: {
        path: file.filePath,
        title: "เอกสารประกอบการพิจารณา",
      },
    });
  }

  async deleteImg(e) {
    const alertConfirm = await this.confirmService.open({
      header: "",
      content: "ต้องการลบเอกสารหรือไม่",
    });
    // ;
    if (alertConfirm) {

      let rqDelImg = {
        attachId: e.id,
        ms13HId: this.id
      };

      this.appMs13Service.deleteMs13Attachment(rqDelImg).then(res => {
        if (res === 'error') {
          return;
        } else {
          this.formFile = null;
          this.fileImg = null;
          this.form.pic = null;

          const formDataMs13 = {
            ID: this.id,
            docNo: this.form.docNo,
            companyTax: this.userProfile.companyCode,
            refNo: this.form.refNo,
            productId: this.form.product,
          };
          this.appMs13Service.getDataMS13Attachment(formDataMs13);
        }
      })
    }
  }

  openMs13Copy() {
    this.dialog.open(Ms13ViewerComponent, {
      disableClose: true,
      data: {
        id: this.id,
      },
      width: '85vw',
      height: '85vh'
    });
  }
}