<div class="mat-elevation-z8" style="width: 100%">
  <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
    <!-- Position Column -->
    <!-- bankAccountNo Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ลำดับ </th>
      <td mat-cell *matCellDef="let element; let i = index" class="text-left"> {{ i + 1 }} </td>
    </ng-container>

    <!-- Name Thai Column -->    
    <ng-container matColumnDef="signNameTh">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ชื่อภาษาไทย </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.signNameTh}} </td>
    </ng-container>

    <!-- Name English Column -->
    <ng-container matColumnDef="signNameEn">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ชื่อภาษาอังกฤษ </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.signNameEn}} </td>
    </ng-container>

    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ตำแหน่ง </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.position}} </td>
    </ng-container>

    <ng-container matColumnDef="signature">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ภาพลายมือชื่อ<br/>Signature Picture </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        <img src="{{element.pic}}" class="img-radius" (click)="imagePopup(element.signature)" />
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
    aria-label="Select page of periodic elements">
  </mat-paginator>
</div>