<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <!-- <app-head-o2 style="width: 100%;" [type]="'ms13'"></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
        <!-- Mockup TypeUser Surveyor  -->
        <button *ngIf="checkButton('BT021')" class="set-btn-unlimited" mat-raised-button color="accent"
          (click)="createO2($event)">
          สร้างใบ มส.13
        </button>
        <button mat-raised-button style="width: 70px;font-size: 1.5em;" matTooltip="รีเฟรชข้อมูล" matTooltipPosition="above" (click)="onRefresh()">
          <i class="ti ti-refresh"></i>
        </button>
      </div>
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">
          <!-- <h4>records</h4> -->
          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="เลขที่คำร้อง / เลขที่ใบอนุญาต อ.2" matInput
              #input [(ngModel)]="searchString" name="searchText" #search="ngModel"
              (keyup)="applyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu" (menuClosed)="menuClosed()">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex-row">
                <div class="mat-button">
                  <mat-form-field class="my-form-field formm" fxFlex="50">
                    <mat-label>วันที่ยื่นคำร้อง</mat-label>
                    <input required autocomplete="off" placeholder="วันที่ยื่นคำร้อง" matInput
                      [matDatepicker]="StartDate" [(ngModel)]="searchForm.refDate" name="refDate" #refDate="ngModel"
                      readonly>
                    <mat-datepicker-toggle matSuffix [for]="StartDate"></mat-datepicker-toggle>
                    <mat-datepicker #StartDate></mat-datepicker>
                    <mat-error>กรุณาระบุ วันที่ยื่นคำร้อง</mat-error>
                  </mat-form-field>
                  <div [formGroup]="ms13Form" fxFlex="50">
                    <mat-form-field style="width: 100%;" class="my-form-field formm">
                      <mat-label>ผู้ตรวจ</mat-label>
                      <input type="text" matInput formControlName="userSearch" [matAutocomplete]="autoas">
                      <mat-autocomplete #autoas="matAutocomplete" (optionSelected)="selectionUserChange($event)">
                        <mat-option *ngFor="let option of filteredOptionsUser$" [value]="option">
                          {{option.fullName}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่คำร้อง</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.refNo" name="refNo" #refNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่ใบอนุญาต อ.2</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.o2DocNo" name="o2DocNo" #o2DocNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทสินค้า</mat-label>
                    <mat-select [(ngModel)]="searchForm.product" name="product" #product="ngModel" ngmodel>
                      <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>ประเภทสินค้า</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>สถานะ</mat-label>
                    <mat-select [(ngModel)]="searchForm.status" name="status" #status="ngModel" ngmodel>
                      <mat-option *ngFor="let status of statusList" [value]="status.statusId">
                        {{status.statusName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>สถานะ</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div>
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" (click)="searchOption()" style="flex:0 50%;">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="index">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ลำดับ</th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-left"> {{ i + 1 }} </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ผู้ส่งออก</th>
        <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.companyName}} </td>
      </ng-container>

      <ng-container matColumnDef="refNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          เลขที่คำร้อง</th>
        <td mat-cell *matCellDef="let element"> {{element.fullRefNo || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="refDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          วันที่ยื่นคำร้อง</th>
        <td mat-cell *matCellDef="let element"> {{(element.refDate | date:'yyyy-MM-dd HH:mm') || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="o2DocNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">เลขที่ใบอนุญาต อ.2</th>
        <td mat-cell *matCellDef="let element"> {{element.o2DocNo || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="o2ExpireDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">วันที่ อ.2 หมดอายุ
        </th>
        <td mat-cell *matCellDef="let element"> {{(element.o2ExpireDate | date:'yyyy-MM-dd') || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="expireDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">วันที่ มส.13 หมดอายุ
        </th>
        <td mat-cell *matCellDef="let element"> {{(element.expireDate | date:'yyyy-MM-dd') || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="productId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ประเภทสินค้า</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="botReceive">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ผู้ตรวจ</th>
        <td mat-cell *matCellDef="let element"> {{element.reviewByName}} </td>
      </ng-container>

      <ng-container matColumnDef="statusCode">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">สถานะ</th>
        <td mat-cell *matCellDef="let element"> {{element.statusCode}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element;let i = index">
          <button mat-icon-button matTooltip="รายละเอียด" routerLink="/page/o2/create-ms13" *ngIf="checkButton('BT022')"
            [queryParams]="{ type : 'Detail' , page : 'ms13' , refNo : element.refNo, id:element.id , docNo :element.o2DocNo , status : element.status}"
            matTooltipPosition="above">
            <i class="ti ti-file-description"></i>
          </button>

          <button *ngIf="(element.status == 1 || element.status == 14) && checkButton('BT023')" mat-icon-button
            matTooltip="แก้ไข มส.13" routerLink="/page/o2/create-ms13" matTooltipPosition="above"
            [queryParams]="{ page: 'ms13',  type : 'Edit', refNo : element.refNo, id:element.id , docNo :element.o2DocNo , status : element.status}">
            <!-- {{element}} -->
            <i class="ti ti-edit"></i>
          </button>

          <button mat-icon-button matTooltip="ประวัติเอกสาร" *ngIf="element.status > 1"
            (click)="openDocumentHistory(element)" matTooltipPosition="above">
            <i class="ti ti-history"></i>
          </button>

          <button *ngIf="(element.status == 1 || element.status == 2) && checkButton('BT024')"
            mat-icon-button matTooltip="พิมพ์ มส.13 ฉบับร่าง" (click)="exportDraftMs13(element)" matTooltipPosition="above">
            <i class="ti ti-file-type-doc"></i>
          </button>

          <!-- -แสดงเฉพาะ item ที่ TAB_STATUS.TSU_ID = MST_O2_HEAD. TSU_ID (‘’1’’),(“5”) -สถานะ Draft -สถานะ Available -->
          <button
            *ngIf="(element.status == 5 || element.status == 6 || element.status == 7 || element.status == 8 || element.status == 9) && checkButton('BT024') && (element.printLock == 'N' || element.printLock == null)"
            mat-icon-button matTooltip="พิมพ์เอกสาร มส.13" (click)="exportMs13(element)" matTooltipPosition="above">
            <i class="ti ti-printer"></i>
          </button>

          <button mat-icon-button matTooltip="ต่ออายุ"
            *ngIf="checkButton('BT025') && (element.status == 3 || element.status == 5 || element.status == 6 || element.status == 7 || element.status == 8 || element.status == 9) && element.isRenewable"
            (click)="renewMs13(element)" matTooltipPosition="above">
            <i class="ti ti-reload"></i>
          </button>

          <button mat-icon-button matTooltip="ล็อคการพิมพ์"
            *ngIf="checkButton('BT176') && (element.printLock == 'N' || element.printLock == null) && (element.status == 5 || element.status == 6 || element.status == 7 || element.status == 8 || element.status == 9)"
            (click)="lockPrint(element)" matTooltipPosition="above">
            <i class="ti ti-lock"></i>
          </button>

          <button mat-icon-button matTooltip="ปลดล็อคการพิมพ์"
            *ngIf="checkButton('BT177') && element.printLock == 'Y' && (element.status == 5 || element.status == 6 || element.status == 7 || element.status == 8 || element.status == 9)"
            (click)="unlockPrint(element)" matTooltipPosition="above">
            <i class="ti ti-lock-open"></i>
          </button>

          <button mat-icon-button matTooltip="ส่งข้อมูลไปยังกรมการค้าต่างประเทศ"
            *ngIf="checkButton('BT178') && element.status == 5" (click)="sendDft(element)" matTooltipPosition="above">
            <i class="ti ti-message-forward"></i>
          </button>

          <!-- <button mat-icon-button matTooltip="รอการตรวจ DNA จากกรมการค้า" *ngIf="checkButton('BT179') && element.status == 5" matTooltipPosition="above">
            <i class="ti ti-dna"></i>
          </button> -->

          <!-- BOT TAKE งาน -->
          <button mat-icon-button matTooltip="กดรับงาน"
            *ngIf="checkButton('BT163') && (element.status === 2 && userProfile.gId == 'UG004')"
            (click)="acceptJobs(element, 3)" matTooltipPosition="above">
            <i class="ti ti-direction-sign"></i>
          </button>

          <!-- SURVEYOR TAKE งาน -->
          <button mat-icon-button matTooltip="กดรับงาน"
            *ngIf="checkButton('BT163') && (element.status === 7 && userProfile.gId == 'UG005')"
            (click)="surveyorJobs(element, 8)" matTooltipPosition="above">
            <i class="ti ti-direction-sign"></i>
          </button>

          <!-- SURVEYOR DONE งาน -->
          <!-- <button mat-icon-button matTooltip="สิ้นสุดงาน" *ngIf="checkButton('BT166') && element.status === 8"
            (click)="surveyorJobs(element, 5)" matTooltipPosition="above">
            <i class="ti ti-zoom-check"></i>
          </button> -->

          <!-- <button mat-icon-button matTooltip="ย้ายสถานะสิ้นสุดงาน" (click)="expireO2(element)" matTooltipPosition="above">
              <i class="ti ti-replace"></i>
          </button> -->
          <button *ngIf="(element.status == 1 || element.status == 14) && checkButton('BT026')" mat-icon-button
            matTooltip="ลบ" (click)="deleteItem(element)" matTooltipPosition="above" matTooltipPosition="above">
            <i class="ti ti-trash"></i>
          </button>
          <!-- <button mat-icon-button matTooltip="สร้างใบ มส.13" (click)="deleteItem(element)" matTooltipPosition="above">
            <div class="btnCircle">
              <small>
                13
              </small>
            </div> 
          </button> -->
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements" (page)="pageChanged($event)">
    </mat-paginator>
  </div>
</div>