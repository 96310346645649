import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppSurveyorService } from 'app/business/service/app/app-surveyor.service';
import { AuthService } from 'app/core/auth/auth.service';
import { surveyorSearchList } from 'app/mock-api/apps/dft-surveyor/data';


@Component({
    selector: 'app-surveyor-search-list',
    templateUrl: './surveyor-search-list.component.html',
    styleUrls: ['./surveyor-search-list.component.css']
})

export class SurveyorSearchListComponent implements OnInit {

    displayedColumns: string[] = [
        'ptNo',
        'name',
        'province',
        'phone',
        'productId',
        'id'
    ];

    url: any;
    buttonList: any[] = [];
    userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
    search: any = {};
    dataSource = new MatTableDataSource<any>(surveyorSearchList);

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

    constructor(
        private _liveAnnouncer: LiveAnnouncer,
        private appSurveyorService: AppSurveyorService,
        private router: Router,
        private _authService: AuthService,
        private appMenuService: AppMenuService,
    ) {

    }

    async getRouter(): Promise<void> {
        this.url = this.router.url;

        const profile = JSON.parse(this._authService.loginUser);
        const formData = {
            url: this.url,
            userGroupCode: profile?.userGroupCode,
            userRoleCode: profile?.userRoleCode,
            userCode: profile?.userCode
        };
        this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
    }

    checkButton(buttonCode: string) {
        var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

        if (checkBtn != undefined) {
            return true;
        } else {
            return false;
        }
    }

    async ngOnInit(): Promise<void> {
        await this.getRouter();
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loadData();
    }


    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }

    SortChange(sortState: Sort) {
        if (sortState.direction) {
            this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
            this._liveAnnouncer.announce('Sorting cleared');
        }
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }


    stopPropagation(event) {
        event.stopPropagation();
    }

    clearOption() {
        this.search = {};
        this.searchData();
    }

    searchData() {
        // this.loadData();
        const filterObj = {
            ptNo: (this.search.pt_no ? this.search.pt_no : '').toLowerCase(),
            name: (this.search.name ? this.search.name : '').toLowerCase(),
            province: (this.search.province ? this.search.province : '').toLowerCase(),
            phone: (this.search.phone ? this.search.phone : '').toLowerCase()
        };
        var defaulteFilterPredict = this.dataSource.filterPredicate;
        this.setFilterPredicate();
        this.dataSource.filter = JSON.stringify(filterObj);
        this.dataSource.filterPredicate = defaulteFilterPredict;
        this.search = {};
        this.trigger.closeMenu();
    }

    async loadData() {
        const data = {
            // request ไปยังไม่มีการเอาไปคำนวณ รอเช็คสิทธิ์ user
            UID: this.userProfile.uId,
        }
        await this.appSurveyorService.getSurveyorSearchList(
            data).then((res) => {
                this.dataSource.data = res
            });
    }

    setFilterPredicate() {
        this.dataSource.filterPredicate = (data: any, filterObjectString: string) => {
            const filterObj = JSON.parse(filterObjectString);
            return (
                (data.ptNo.toString() ? data.ptNo.toString() : '').toLowerCase().includes(filterObj.ptNo.toString()) &&
                (data.name ? data.name : '').toLowerCase().includes(filterObj.name) &&
                (data.province ? data.province : '').toLowerCase().includes(filterObj.province) &&
                (data.phone ? data.phone : '').toLowerCase().includes(filterObj.phone)
            )
        }
    }
}