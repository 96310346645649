import { Subject } from 'rxjs';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { IGetUserProfileResponse, IMenuDataModel, IMenuResponse, ISetPermissionMenuRequest, ITreeviewStructure } from 'app/business/interface/api/api-menu.interface';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { DxTreeViewComponent } from 'devextreme-angular';
import _ from 'underscore';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-menu-permission',
  templateUrl: './menu-permission.component.html',
  styleUrls: ['./menu-permission.component.css']
})
export class MenuPermissionComponent implements OnInit, OnDestroy {
  showCheckBoxesMode = 'selectAll';
  selectionMode = 'multiple';
  selectNodesRecursive = true;
  selectByClick = true;
  unsubscribe$: Subject<any> = new Subject();
  // component prop
  @Input() selectdata : any;
  form: any;
  actionItem: any;
  actionMenuTo: any;
  displayFlex: string;
  selectedNodes: any[];
  dataMenuUserRoleList: any[];
  dataMenuUserGroupList: any[];
  dataMenuUserList: any[];
  checkselectmenu;
  @ViewChild('menuActionTreeView1', { static: false }) treeView1: DxTreeViewComponent;
  @ViewChild('menuActionTreeView2', { static: false }) treeView2: DxTreeViewComponent;
  @ViewChild('menuActionTreeView3', { static: false }) treeView3: DxTreeViewComponent;
  @Input() dataInput: any;

  // service prop
  menuList: IMenuResponse[];
  userMenuList: IMenuDataModel[];
  userRoleMenuList: IMenuDataModel[];
  userGroupMenuList: IMenuDataModel[];
  userProfile: IGetUserProfileResponse;
  dataForSavePermission: ISetPermissionMenuRequest;
  url;
  buttonList: any;
  userToken:any;
  constructor(
    private appMenuService: AppMenuService,
    private alertMessageService: AlertMessageService,
    private router: Router,
    private appUserService: AppUserService,
    private _authService:AuthService,
  ) {
    this.form = this.dataInput;
    this.menuList = new Array<IMenuResponse>();
    this.userMenuList = new Array<IMenuDataModel>();
    this.userRoleMenuList = new Array<IMenuDataModel>();
    this.userGroupMenuList = new Array<IMenuDataModel>();
    this.selectedNodes = new Array();
    this.userProfile = null;
    this.dataForSavePermission = {
      userCode: '',
      userGroupCode: '',
      userRoleCode: '',
      setPermissionType: 'M',
      menuList: []
    } as ISetPermissionMenuRequest;

    this.dataMenuUserRoleList = new Array();
    this.dataMenuUserGroupList = new Array();
    this.dataMenuUserList = new Array();

  }

  async ngOnInit() {
    this.userToken = JSON.parse(
      this._authService.loginUser
    )

    await this.getRouter();
    
    this.setSubcribe();
  }
  ngOnDestroy(): void {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  setSubcribe(): void {

    // this.appMenuService.buttonPage.subscribe(item =>{
    //   this.checkselectmenu = item;
    // })

    this.appMenuService.actionMenuPermission.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.actionItem = res;
      this.displayFlex = this.actionItem?.value === 'U' ? 'space-around start' : 'start start';
    });
    this.appMenuService.actionMenuTo.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.actionMenuTo = res; 
    });
    this.appMenuService.menuList.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.menuList = res;
      this.appMenuService.userMenuList.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
        this.userMenuList = result;
        const menuCodeList = _.pluck(this.userMenuList, 'menuCode');
        this.dataMenuUserList = this.dataMenuInit(this.menuList, this.userMenuList);
      });
      this.appMenuService.userRoleMenuList.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
        this.userRoleMenuList = result;
        const menuCodeList = _.pluck(this.userRoleMenuList, 'menuCode');
        this.dataMenuUserRoleList = this.dataMenuInit(this.menuList, this.userRoleMenuList);
      });
      this.appMenuService.userGroupMenuList.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
        this.userGroupMenuList = result;
        const menuCodeList = _.pluck(this.userGroupMenuList, 'menuCode');
        this.dataMenuUserGroupList = this.dataMenuInit(this.menuList, this.userGroupMenuList);
      });
      this.appMenuService.userProfile.pipe(takeUntil(this.unsubscribe$)).subscribe(result => this.userProfile = result);
    });

  }
  async getRouter(): Promise<void> {

    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userToken.userGroupCode,
      userRoleCode: this.userToken.userRoleCode,
      userCode: this.userToken.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);


  }
  getMenuAll(): void {
    this.appMenuService.getMenuAll({
      gId: this.userToken.gId,
      menuGroupCode: 'M',
      userCode: this.actionItem?.value === 'U' ?  this.actionMenuTo?.user.userId : '',
      userGroupCode: this.actionItem?.value === 'U' ? this.actionMenuTo?.user.userGroupCode : this.actionItem?.value === 'G' ? this.actionMenuTo?.value : '',
      userRoleCode: this.actionItem?.value === 'U' ? this.actionMenuTo?.user.userRoleCode : this.actionItem?.value === 'R' ? this.actionMenuTo?.value : '',
    });
  }
  resetMenuSubmit(): void {
    
    this.dataForSavePermission = {
      userCode: '',
      userGroupCode: '',
      userRoleCode: '',
      setPermissionType: 'M',
      menuList: []
    } as ISetPermissionMenuRequest;
    this.getMenuAll();
  }
  saveMenuPermissionSubmit(): void {
    // this.getSelectedNodes(this.treeView1);
    let menuCodeList = [];
    switch (this.actionItem.value) {
      case 'R': {
        menuCodeList = this.getSelectedNodes('treeView2');
        this.dataForSavePermission.userRoleCode = this.actionMenuTo?.value;
        this.dataForSavePermission.userGroupCode = '';
        this.dataForSavePermission.userCode = '';
        break;
      }
      case 'G': {
        menuCodeList = this.getSelectedNodes('treeView3');
        this.dataForSavePermission.userGroupCode = this.actionMenuTo?.value;
        this.dataForSavePermission.userRoleCode = '';
        this.dataForSavePermission.userCode = '';
        break;
      }
      case 'U': {
        menuCodeList = this.getSelectedNodes('treeView1');
        // this.dataForSavePermission.userCode = this.actionMenuTo?.value;
        this.dataForSavePermission.userCode = this.actionMenuTo?.user.userId;

        this.dataForSavePermission.userGroupCode = '';
        this.dataForSavePermission.userRoleCode = '';
        break;
      }
    }
    this.dataForSavePermission.menuList = _.uniq(menuCodeList);
    // 

    console.log("save role menu: ", this.dataForSavePermission.menuList);
    this.appMenuService.savePermissionMenuOrButton(this.dataForSavePermission).then(res => {
      if (res !== 'error') {
        this.alertMessageService.info({ title: '', message: 'บันทึกข้อมูลเรียบร้อยแล้ว' });
        this.getMenuAll();
      }
    });

  }
  getSelectedNodes(treeviewTo: string): any[] {

    this.selectedNodes = treeviewTo === 'treeView1' ? this.treeView1.instance.getSelectedNodes()
      : treeviewTo === 'treeView2' ? this.treeView2.instance.getSelectedNodes() : this.treeView3.instance.getSelectedNodes();
    let menuCodeList = [];
    this.selectedNodes.map(e => {
      menuCodeList.push(e.key);
      if (e.parent) {
        menuCodeList.push(e.parent.key);
      }
    });
    menuCodeList = _.uniq(menuCodeList);
    return menuCodeList;
  }

  dataMenuInit(datas: IMenuResponse[], menuList: IMenuDataModel[]): any[] {
    const menuHasChildList = _.pluck(datas.filter(r => r.children.length > 0), 'menuCode');
    const useMenuNotRoot = menuList?.filter(r => {
      if (!_.contains(menuHasChildList, r.menuCode)) {
        return r;
      }
    });
    const menuCodeList = _.pluck(useMenuNotRoot, 'menuCode');
    const result = datas.map((e) => {
      return {
        ...e,
        expanded: true,
        children: this.dataMenuInit(e.children, menuList),
        selected: _.contains(menuCodeList, e.menuCode)
      };
    });
    return result;
  }

  hasAllChildren(data: IMenuResponse, menuList: IMenuDataModel[]): boolean {
    let isSelect = false;
    if (data.children.length > 0) {
      const child = menuList.filter(r => r.parrentCode === data.menuCode);
      isSelect = child.length === data.children.length;
    }
    return isSelect;
  }

  selectItem(e): void {
    // 
  }
  treeViewSelectionChanged(e: any): void {
    // this.syncSelection(e.component);
    // 

  }

  treeViewContentReady(e: any): void {
    // this.syncSelection(e.component);
    // 

  }
}
