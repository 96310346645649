<mat-card class="example-card">
  <mat-card-header class="fontTitleHeader">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal">สำเนาเอกสาร มส.13 (Copy of MS13)</div>
      <div>
        <button mat-icon-button class="close-button" (click)="printMs13()"
          matTooltip="เปิดสำเนา มส.13" matTooltipPosition="above">
          <i class="ti ti-license"></i>
        </button>
        <button mat-icon-button class="close-button" [mat-dialog-close]
          matTooltip="ปิดหน้าต่าง" matTooltipPosition="above">
          <i class="ti ti-x"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content style="padding: 16px">
    <div class="flex flex-col" style="gap: 10px;">
      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            ข้อมูลเอกสาร มส.13 (MS13 Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> เลขที่คำร้องขอ :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.fullRefNo}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">วันเวลาที่ยื่นคำร้อง :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.refDate | thaidate}} {{form.dateCreated |
                      date:'HH:mm'}}</div>
                  </div>
                  <div class="basis-1/2 f18" style="align-self: center;"></div>
                </div>
              </div>
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">ประเภทสินค้า :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.typeGoods}}</div>
                  </div>
                  <div class="basis-1/2 f18" style="align-self: center;"></div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            ข้อมูลเอกสาร อ.2 (O2 Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ใบอนุญาตกระทรวงพาณิชย์ :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">พณ{{form.o2DocNo}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">วันที่ออกใบอนุญาต :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.o2IssueDate | thaidate}}</div>
                  </div>
                  <div class="basis-1/2 f18" style="align-self: center;"></div>
                </div>
              </div>
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="f18" style="width: 28%;">วันหมดอายุ :</div>
                  <div style="width: 62%">
                    <div class="f18" style="align-self: center;">{{form.o2ExpireDate | thaidate}}</div>
                  </div>
                  <div class="f18" style="align-self: center; width: 10%;">
                    <button mat-icon-button matTooltip="รายละเอียดเอกสาร อ.2" matTooltipPosition="above"
                      style="width: 48px !important" (click)="viewO2Copy()">
                      <i class="ti ti-info-circle"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- ผู้ส่งออก (Exporter Information) และ ข้อมูลบริษัทผู้รับสินค้า (ผู้ซื้อ) (Receiver Information) -->
      <div class="flex flex-row p10" style="gap: 10px">
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ผู้ส่งออก (Exporter Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%; gap: 5px">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center;width: 30%;"> เลขประจำตัวผู้ส่งออก :</div>
                  <div style="width: 10%;">
                    <div class="f18" style="align-self: center;">{{form.exporter}}</div>
                  </div>
                  <div class="f18" style="align-self: center;width: 40%;"> เลขที่ประจำตัวผู้เสียภาษี :</div>
                  <div style="width: 20%;">
                    <div class="f18" style="align-self: center;">{{form.companyTax}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;">
                    ชื่อบริษัทผู้ส่งออก (ภาษาไทย) :</div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.companyThName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;">
                    ชื่อบริษัทผู้ส่งออก (ภาษาอังกฤษ) :</div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.companyEnName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> ที่อยู่บริษัท: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.exporterStreetandNo}}
                      {{form.exporterDistrict}} {{form.exporterSubProvince}} {{form.exporterProvince}}
                      {{form.exporterPostcode}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ข้อมูลบริษัทผู้รับสินค้า (ผู้ซื้อ) (Receiver Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;">
                    ข้อมูลบริษัทผู้รับสินค้า (ผู้ซื้อ) :</div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.recieveEnName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> ที่อยู่บริษัท: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.recieveStreetNo}}
                      {{form.recieveDistrict}} {{form.recieveSubProvince}} {{form.recieveProvince}}
                      {{form.recievePostcode}} {{form.recieveCountry}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- ข้อมูลผู้กระทำการแทนผู้ส่งออก (Shipper Information) และ ข้อมูลอื่น ๆ (Other Information) -->
      <div class="flex flex-row p10" style="gap: 10px">
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ข้อมูลผู้กระทำการแทนผู้ส่งออก (Shipper Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ชื่อบริษัท :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.shippingName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> ที่อยู่บริษัท: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.shippingStreetNo}}
                      {{form.shippingDistrict}} {{form.shippingSubProvince}} {{form.shippingProvince}}
                      {{form.shippingPostcode}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ข้อมูลอื่น ๆ (Other Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%; gap: 5px">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center;width: 25%;"> ประเทศผู้ซื้อ :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{form.buyerCountry}}</div>
                  </div>
                  <div class="f18" style="align-self: center;width: 25%;"> ประเทศผู้รับ :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{form.destinationCountry}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 25%;padding-right: 20px;"> ส่งออกโดยทาง:
                  </div>
                  <div class="flex align-center" style="width: 75%;">
                    <div class="f18" style="align-self: center;">{{form.transportationModeName}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="flex-1" style="width: 100%; gap: 5px">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center;width: 25%;"> ชื่อพาหนะ / เที่ยว :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{form.vesselName}}</div>
                  </div>
                  <div class="f18" style="align-self: center;width: 25%;"> สถานที่ส่งออก :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{form.porttxt}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="flex-1" style="width: 100%; gap: 5px">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center;width: 25%;"> สกุลเงิน :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{form.currency}}</div>
                  </div>
                  <div class="f18" style="align-self: center;width: 25%;"> อัตราแลกเปลี่ยน :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{ form.formatExchangeRate }}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- ข้อมูลผู้กระทำการแทนผู้ส่งออก (Shipper Information) และ ข้อมูลอื่น ๆ (Other Information) -->
      <div class="flex flex-row p10" style="gap: 10px">
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            เลขที่ทะเบียน ผค.
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> เลขที่ทะเบียน ผค. :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.registrationNoPk}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex" *ngIf="form.shippingMarkId != undefined && form.shippingMarkId != null">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> ตราเครื่องหมายส่งออก: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.shippingMark}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex" *ngIf="form.shippingMarkId == undefined || form.shippingMarkId == null">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> เหตุผลที่ไม่ระบุตรา: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.shippingMark}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ชื่อสินค้า :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.goodsName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ประเภทสินค้า 1 :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.typeGoods}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ชนิด / ชั้น :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.typeClass}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> หลักฐานการซื้อขาย :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.proofpurchase}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 50%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 40%;padding-right: 20px;"> เมืองตราส่ง :
                  </div>
                  <div style="width: 60%;">
                    <div class="f18" style="align-self: center;">{{form.loadePortName}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1" style="width: 50%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ประเทศ :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.loadedPortCountry}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> วันที่บรรทุก :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.loadedDateStart | thaidate}} ถึง
                      {{form.loadedDateEnd | thaidate}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ข้อมูลสถานที่ตรวจสอบ
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ชื่อสถานที่ตรวจสอบ :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.inspectName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> ที่อยู่: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.inspectStreetNo}}
                      {{form.inspectDistrict}} {{form.inspectSubprovince}} {{form.inspectProvince}}
                      {{form.inspectPostcode}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> ข้อมูลอื่น ๆ เกี่ยวกับสถานที่ตรวจสอบ: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.inspectOther}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> เวลาที่ให้ไปตรวจสอบ: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.inspectDate1}} ถึง {{form.inspectDate2}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> ตรวจสอบโดย: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.inspectBy}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- รายการสินค้า (Other Information) -->
      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            รายการสินค้า ({{formO2 ? 'O2' : 'MS13'}} Stock)
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding: 16px;">
            <div class="flex">
              <div class="flex-1 w-100">
                <div class="mat-elevation-z8" style="width: 100%">
                  <dx-data-grid id="gridContainer" [dataSource]="dataSource" [columnAutoWidth]="true"
                    [showBorders]="true" [allowColumnReordering]="true" [showBorders]="true"
                    [noDataText]="'ไม่มีข้อมูล'">
                    <dxi-column caption="รายการที่" alignment="center" cellTemplate="wFlow" [width]='100'>
                    </dxi-column>
                    <div *dxTemplate="let row of 'wFlow'">
                      {{row.rowIndex+1}}
                    </div>
                    <dxi-column alignment="center" dataField="goodsDescription" caption="รายละเอียดสินค้า">
                    </dxi-column>
                    <dxi-column alignment="center" caption="ราคา">
                      <dxi-column alignment="center" caption="ต่อจำนวน" cellTemplate="unitPrice">
                      </dxi-column>
                      <div *dxTemplate="let row of 'unitPrice'">
                        {{ row.data?.unitPrice | number:'1.2-6' }}
                      </div>
                      <dxi-column alignment="center" caption="FOB(บาท)" cellTemplate="fobValueBaht">
                      </dxi-column>
                      <div *dxTemplate="let row of 'fobValueBaht'">
                        {{ row.data?.fobValueBaht | number:'1.2-6' }}
                      </div>
                    </dxi-column>
                    <dxi-column alignment="center" caption="จำนวน" cellTemplate="quantity">
                    </dxi-column>
                    <div *dxTemplate="let row of 'quantity'">
                      {{row.data?.quantity | number:'1.0'}} {{row.data?.quantityUnitName}}
                    </div>
                    <dxi-column alignment="center" caption="น้ำหนัก">
                      <dxi-column alignment="center" caption="สุทธิ" cellTemplate="netWeight">
                      </dxi-column>
                      <div *dxTemplate="let row of 'netWeight'">
                        {{row.data?.netWeight | number:'1.2-6'}} {{row.data?.netWeightUnit}}
                      </div>
                      <dxi-column alignment="center" caption="เฉพาะต่อหน่วย" cellTemplate="netWeightPerUnit">
                      </dxi-column>
                      <div *dxTemplate="let row of 'netWeightPerUnit'">
                        {{ (row.data?.netWeightPerUnit != null ? row.data?.netWeightPerUnit : 0) | number:'1.2-6'}}
                        {{row.data?.netWeightUnit}}
                      </div>
                      <dxi-column alignment="center" caption="ใช้ไปแล้ว" cellTemplate="weightUsed">
                      </dxi-column>
                      <div *dxTemplate="let row of 'weightUsed'">
                        {{ (row.data?.weightUsed != null ? row.data.weightUsed : 0) | number:'1.2-6'}}
                        {{row.data?.netWeightUnit}}
                      </div>
                      <dxi-column alignment="center" caption="คงเหลือ" cellTemplate="availableNet">
                      </dxi-column>
                      <div *dxTemplate="let row of 'availableNet'">
                        {{ (row.data?.availableNet != null ? row.data?.availableNet : 0) | number:'1.2-6'}}
                        {{row.data?.netWeightUnit}}
                      </div>
                    </dxi-column>
                    <dxi-column alignment="center" dataField="itemStatusName" caption="สถานะ">
                    </dxi-column>
                    <dxi-column alignment="center" caption="Action" cellTemplate="action"> </dxi-column>
                    <div *dxTemplate="let row of 'action'">
                      <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                        (click)="addProduct(row.data.id ,row.data )">
                        <i class="ti ti-info-circle"></i>
                      </button>
                      <!-- <button mat-icon-button matTooltip="แก้ไข" *ngIf="!disabled && typePage == 'o2'"
                        (click)="addProduct('edit',row.data.id)" matTooltipPosition="above">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button matTooltip="แก้ไข" *ngIf="!disabled && typePage == 'o2'"
                        (click)="delProduct(row.data.id)" matTooltipPosition="above">
                        <mat-icon>delete</mat-icon>
                      </button> -->
                    </div>
                  </dx-data-grid>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- เอกสารประกอบแนบคำพิจารณา -->
      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            เอกสารประกอบแนบคำพิจารณา
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding: 16px;">
            <div class="flex">
              <div class="flex-1 w-100">
                <div class="mat-elevation-z8" style="width: 100%">
                  <dx-data-grid id="gridContainer" [dataSource]="dataSourceAtt" [columnAutoWidth]="true"
                    [showBorders]="true" [allowColumnReordering]="true" [showBorders]="true"
                    [noDataText]="'ยังไม่ได้แนบเอกสารประกอบคำพิจารณา'">
                    <dxi-column alignment="center" dataField="fileName" caption="ชื่อเอกสาร">
                    </dxi-column>
                    <dxi-column alignment="center" cellTemplate="documentSize" caption="ขนาดของเอกสาร">
                    </dxi-column>
                    <div *dxTemplate="let row of 'documentSize'">
                      {{row?.data.documentSize}} กิโลไบต์ (kB)
                    </div>
                    <dxi-column alignment="center" dataField="fileExtension" caption="ชนิดเอกสาร">
                    </dxi-column>
                    <dxi-column alignment="center" cellTemplate="action" caption="Action">
                    </dxi-column>
                    <div *dxTemplate="let row of 'action'">
                      <button (click)="openTab(row?.data.filePath)" mat-icon-button matTooltipPosition="above"
                        matTooltip="เปิดเอกสาร">
                        <i class="ti ti-file-search"></i>
                      </button>
                    </div>
                  </dx-data-grid>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- การตรวจสอบมาตราฐานสินค้า -->
      <div class="flex flex-row p10" style="gap: 10px">
        <mat-card style="width: 100%">
          <mat-card-header class="fontTitleHeader" style="background-color: #684637;">
            การตรวจสอบมาตราฐานสินค้า
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align"
            style="padding: 16px; gap: 10px; background-color: #EFEECE;">
            <div class="flex">
              <div class="flex" style="align-self: center; width: 60%;">
                <div class="flex flex-row p10" style="width: 100%">
                  <div class="f18" style="align-self: center; width: 30%"> บริษัทผู้ตรวจสอบ : </div>
                  <div class="f18" style="align-self: center; width: 70%; font-weight: bold;">
                    {{form.inspectBy}}</div>
                </div>
              </div>
              <div class="flex justify-end" style="align-self: center; width: 40%">
                <div class="flex flex-row p10" style="width: 85%">
                  <div class="f18" style="align-self: center; width: 50%"> วันและเวลาที่ได้รับคำร้อง : </div>
                  <div class="f18" style="align-self: center; width: 50%"> {{form.surveyAcceptDate | thaidate}}
                    {{form.surveyAcceptDate | date: 'HH:mm'}}</div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex" style="align-self: center; width: 60%;">
                <div class="flex flex-row p10" style="width: 100%">
                  <div class="f18" style="align-self: center; width: 30%"> ผู้ตรวจสอบ : <label
                      style="color:red">*</label></div>
                  <div class="f18" style="align-self: center; width: 70%">
                    {{formSur.surveyOrder}}
                  </div>
                </div>
              </div>
              <div class="flex" style="align-self: center; width: 40%">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> </div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;"></div>
                  </div>
                </div>
              </div>


            </div>
            <fieldset>
              <legend>ผู้ตรวจสอบมาตรฐานสินค้า(ประเภท ข.) ปฎิบัติดารตรวจสอบมาตรฐาน</legend>
              <div class="flex flex-col" style="gap: 6px;">
                <div class="flex">
                  <div class="flex" style="width: 50%">
                    <div class="flex flex-row p10" style="width: 100%">
                      <div class="f18" style="align-self: center; width: 10%"> ผู้สั่ง :</div>
                      <div class="f18" style="width: 90%">
                        {{formSur.surveyOrder}} ({{formSur.surveyOrderNo}})
                      </div>
                    </div>
                  </div>
                  <div class="flex" style="width: 50%">
                    <div class="flex flex-row p10" style="width: 100%">
                      <div class="f18" style="align-self: center; width: 15%"> วันที่สั่ง :</div>
                      <div class="f18" style="width: 75%; display: flex; gap: 6px;">
                        {{formSur.surveyOrderDate | thaidate}} {{formSur.surveyOrderTime}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-1">
                    <div class="flex" style="width: 100%">
                      <div class="flex flex-row p10" style="width: 100%">
                        <div class="f18" style="align-self:center;">1. {{formSur.surveyCheif}} ผตข.
                          {{formSur.surveyCheifNo}} เป็นหัวหน้า </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="flex" style="width: 100%"
                      *ngIf="formSur.surveyBy2 != undefined && formSur.surveyBy2 != null">
                      <div class="flex flex-row p10" style="width: 100%">
                        <div class="f18" style="align-self: center;">2. {{formSur.surveyBy2}} ผตข.
                          {{formSur.surveyBy2No}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1">
                    <div class="flex" style="width: 100%"
                      *ngIf="formSur.surveyBy3 != undefined && formSur.surveyBy3 != null">
                      <div class="flex flex-row p10" style="width: 100%">
                        <div class="f18" style="align-self: center;">3. {{formSur.surveyBy3}} ผตข.
                          {{formSur.surveyBy3No}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
            <fieldset style="margin-bottom: 10px;">
              <legend>ผู้วิเคราะห์</legend>
              <div class="flex flex-col" style="gap: 6px;">
                <div class="flex">
                  <div class="flex" style="width: 75%">
                    <div class="flex flex-row p10" style="width: 100%">
                      <div class="f18" style="align-self: center; width: 15%"> ผู้วิเคราะห์ :</div>
                      <div class="f18" style="width: 85%; align-items: center; display: flex;">
                        {{formSur.surveyAnalyze}} เลขที่ ผตก. {{formSur.surveyAnalyzeNo}} เป็นหัวหน้า
                      </div>
                    </div>
                  </div>
                </div>

                <div class="flex">
                  <div class="flex" style="width: 75%">
                    <div class="flex flex-row p10" style="width: 100%">
                      <div class="f18" style="align-self: center; width: 15%"> ผู้สั่ง :</div>
                      <div class="f18" style="width: 50%; align-items: center; display: flex;">
                        {{formSur.surveyAnalyzeOrder}}
                      </div>
                      <div style="width: 35%;display: flex;align-items: center;"></div>
                    </div>
                  </div>
                  <div class="flex" style="width: 25%">
                  </div>
                </div>

                <div class="flex">
                  <div class="flex" style="width: 75%">
                    <div class="flex flex-row p10" style="width: 100%">
                      <div class="f18" style="align-self: center; width: 15%"> วันที่สั่ง :</div>
                      <div class="f18" style="width: 50%;align-items: center;display: flex; gap: 6px;">
                        {{formSur.surveyAnalyzeOrderDate | thaidate}} {{formSur.surveyAnalyzeOrderTime}}
                      </div>
                      <div style="width: 35%;display: flex;align-items: center;"></div>
                    </div>
                  </div>
                  <div class="flex" style="width: 25%">
                  </div>
                </div>
              </div>
            </fieldset>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- ผลการตรวจสอบ -->
      <div class="flex flex-row p10" style="gap: 10px">
        <mat-card>
          <mat-card-header class="fontTitleHeader" style="background-color: #684637;">
            ผลการตรวจสอบ
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding: 16px; background-color: #EFEECE;">
            <div class="flex p10">
              <div class="mat-elevation-z8" style="width: 100%">
                <dx-data-grid id="gridContainer" [dataSource]="surveyDataSource" [columnAutoWidth]="true"
                  [showBorders]="true" [allowColumnReordering]="true" [showBorders]="true"
                  [noDataText]="'ยังไม่ได้เพิ่มผลการตรวจสอบ'">
                  <dxi-column caption="ครั้งการตรวจสอบที่" alignment="center" cellTemplate="wFlow" [width]='100'>
                  </dxi-column>
                  <div *dxTemplate="let row of 'wFlow'">
                    {{row.rowIndex + 1}}
                  </div>
                  <dxi-column alignment="center" dataField="checkResult" caption="ผลการตรวจสอบ">
                  </dxi-column>
                  <dxi-column alignment="center" dataField="quantity" caption="จำนวนสินค้าที่ตรวจสอบ">
                  </dxi-column>
                  <dxi-column alignment="center" caption="Action" cellTemplate="action"> </dxi-column>
                  <div *dxTemplate="let row of 'action'">
                    <button mat-icon-button matTooltip="ดูรายละเอียด"
                      (click)="editSurveyorCheck(row.data.surveyId, 'info')" matTooltipPosition="above"
                      style="width: 48px !important">
                      <i class="ti ti-info-circle"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>