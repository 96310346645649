import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild, Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppDFTService } from "app/business/service/app/app-dft.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { IAppDftExporterDetails } from "app/business/interface/app/app-dft.interface";
import { formatDate } from '@angular/common';
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { ActivatedRoute } from '@angular/router';




@Component({
  selector: 'app-dft-exporter-information',
  templateUrl: './dft-exporter-information.component.html',
  styleUrls: ['./dft-exporter-information.component.css']
})
export class DftExporterInformationComponent implements OnInit {

  searchForm: any = {};
  form: any = {};
  dataSource = new MatTableDataSource<any>();
  dataView: IAppDftExporterDetails[] = [];
  id: number = 0;


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppDFTService: AppDFTService,
    private AlertMessage: AlertMessageService,
    private route: ActivatedRoute,
  ) {
  }


  ngOnInit() {
    this.loadData();
  }
  async loadData(): Promise<void> {
    this.route.queryParams.subscribe(params => { this.id = params.id });
    const formData = {
      exporterId: this.id,
    };

    this.AppDFTService.getExporterDataDetail(formData).then((res) => {
      this.form.ms5DateStart = formatDate(res.ms5DateStart, 'yyyy-MM-dd', "en-US");
      this.form.ms5DateEnd = formatDate(res.ms5DateEnd, 'yyyy-MM-dd', "en-US");
      this.form.createDate = formatDate(res.createDate, 'yyyy-MM-dd', "en-US");
      this.form.updateDate = res.updateDate == null ? null : formatDate(res.updateDate, 'yyyy-MM-dd', "en-US");
      this.form = { ...this.form, ...res };

      this.dataView = this.form.productList;
      this.dataSource = new MatTableDataSource(this.dataView);

    });
  }

  async saveData(): Promise<void> {
    const formData = {
      ExporterIdRequest: this.id,
      UPMRRequest: this.form.statusUpdateDataResponse,
    };

    this.AppDFTService.getExporterDataDetail(formData).then((res) => {
      this.AlertMessage.info({ title: '', message: res });
    });
  }
}
