import { AppO2Service } from "app/business/service/app/app-o2.service";
import { AppMs13Service } from "app/business/service/app/app-ms13.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { ImageModalComponent } from "../../ms13/Image-modal/Image-modal.component";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import LocalStore from "devextreme/data/local_store";
import { AuthService } from "app/core/auth/auth.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { O2ViewerComponent } from "../../ms13/o2-viewer/o2-viewer.component";
import { O2DocViewerComponent } from "../o2-doc-viewer/o2-doc-viewer.component";

@Component({
  selector: "app-renew-o2-inquiry",
  templateUrl: "./renew-o2-inquiry.component.html",
  styleUrls: ["./renew-o2-inquiry.component.css"],
})
export class RenewO2InquiryComponent implements OnInit {
  form: any = {};
  fileImg: any;
  file: any;
  value: any[] = [];
  typePage: any;
  typePageName: any;
  id: any;
  dataSource: any = [];
  uploadPass: boolean = false;
  disabled: boolean = false;
  buttonList: any[] = [];
  url: any;
  isStaff: boolean = false;
  isDetail: boolean = false;
  formFile: any = {};

  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  constructor(
    private dialogRef: MatDialogRef<RenewO2InquiryComponent>,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private appMaster: AppMasterService,
    private appMs13Service: AppMs13Service,
    private appO2Service: AppO2Service,
    private alertService: AlertMessageService,
    private appMs24Service: AppMs24Service,
    private confirmService: ConfirmDialogService,
    private _authService: AuthService,
    private appMenuService: AppMenuService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.form = data.item;
    this.isStaff = data.isStaff;
    this.isDetail = data.isDetail;
  }

  async ngOnInit() {
    await this.getRouter();
    this.id = this.form.id;
    console.log("int ", this.id)
    this.getRenew();
    // console.log("this.userProfile.userTypeId", this.userProfile.userTypeId);
    // this.userProfile.userTypeId; // 2. Exporter  // 3. BOT
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url
    const strUrl = this.url.split('?')
    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: strUrl[0],
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };

    await this.appMenuService.getButtonPermissionPerPages(formData)
      .then((bb) => {
        this.buttonList = bb
      });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);
    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  getRenew() {
    this.appO2Service.getrenewO2({ ID: this.id }).then((res) => {
      this.form = res;
      this.dataSource = [];
      this.dataSource.push(res.attachment);
      this.form.expireDateMax = new Date();
      var maxDay = moment(new Date(this.form.expireDate))
        .add(30, "days")
        .format("YYYY-MM-DD");
      this.form.expireDateMax = new Date(maxDay);

      var newExpDate = moment(new Date(this.form.expireDate))
        .add(9, "days")
        .format("YYYY-MM-DD");
      this.form.newExpireDate = newExpDate;

      // console.log(this.form);
    });

    // if (this.typePage == "o2") {

    // } else if (this.typePage == "ms13") {
    //   this.appMs13Service.getRenewMS13({ ID: this.id }).then((res) => {
    //     this.form = res;
    //     this.form.expireDateMax = new Date();
    //     var maxDay = moment(new Date(this.form.expireDate))
    //       .add(1, "days")
    //       .format("YYYY-MM-DD");
    //     this.form.expireDateMax = new Date(maxDay);
    //     this.form.docNo = this.form.docNo ?? "-";
    //     // console.log(this.form.expireDateMax);
    //   });
    // } else {
    //   this.appMs24Service.getrenewMS24({ ID: this.id }).then((res) => {
    //     this.form = res;
    //     this.form.expireDateMax = new Date();
    //     var maxDay = moment(new Date(this.form.expireDate))
    //       .add(1, "days")
    //       .format("YYYY-MM-DD");
    //     this.form.expireDateMax = new Date(maxDay);
    //     // console.log(this.form.expireDateMax);
    //   });
    // }
  }

  async onFileChangedBr(event): Promise<void> {
    if (event.value.length === 0) {
      return;
    }
    // const file = event.value.files[0];
    event.value.forEach((item) => {
      this.file = item;
      this.onUploadBr();
    });

    this.value = [];
  }

  async sentRenew(status) {
    if (!this.isStaff && (this.form.linkDoc != undefined && this.form.linkDoc != null && this.form.linkDoc != 0)) {
      this.alertService.open({
        message: "กรุณาอัปโหลดเอกสารประกอบคำร้องขอต่ออายุ อ.2",
        title: ""
      });
      return;
    } else {
      var rqRenewO2 = {
        attachment: this.form.linkDoc,
        docNo: this.form.docNo,
        expireDate: this.form.expireDate,
        status: status,
        newExpireDate: null
      }

      if (status === 9) {
        if ((this.form.newExpireDate instanceof Date)) {
          var newDate = moment(this.form.newExpireDate);

          var adjustedDate = newDate.toISOString(true);

          this.form.newExpireDate = adjustedDate
        }

        rqRenewO2.newExpireDate = this.form.newExpireDate;
      }

      this.appO2Service.renewO2sent(rqRenewO2).then((res) => {
        if (res != "error") {
          var message = this.isStaff ? 'บันทึกผลอนุมัติการขอต่ออายุเอกสารแล้ว' : "คุณได้ดำเนินการขอต่ออายุ อ.2 ดังกล่าวแล้ว ขณะนี้ทางเจ้าหน้าที่กำลังดำเนินการ"
          this.alertService.info({
            title: "",
            message: message,
          }).then((x) => {
            if (!this.isStaff) {
              this.router.navigate(["page/o2/list-renew-o2"], {});
            }
            this.dialogRef.close(true);
          });
        }
      });
    }
  }

  onUploadBr(): void {
    const formData = new FormData();
    formData.append("image", this.file, this.file.name);
    formData.append("blobType", "CSCAttachments");
    formData.append("action", "new");
    formData.append("linkDoc", this.id ?? 0);
    formData.append("documentName", this.form.docNo);
    formData.append("typePage", "o2");
    this.appMaster.uploadFile(formData).subscribe((result) => {
      if (result) {
        this.formFile = result[0];
        this.fileImg = result[0]?.filePath;
        this.form.pic = result[0]?.fileName + "." + result[0]?.fileType;
        this.form.linkDoc = result[0]?.fileName + "." + result[0]?.fileType;
        this.formFile.appType = result[0]?.fileType;
        this.formFile.fileExtension = this.file.type;

        var linkDoc = result[0]?.linkDoc;

        const attachment = {
          fileName: this.formFile.fileName,
          documentName: this.form.docNo,
          linkDoc: this.form.id,
          documentContent: "image",
          documentSize: this.formFile.fileByte,
          fileExtension: this.formFile.fileExtension,
          docByte: this.formFile.fileByte,
          ID: linkDoc,
        };

        this.getRenew();
      }
    });

    // const formData = new FormData();
    // formData.append("image", this.file, this.file.name);
    // formData.append("typePage", this.typePage);
    // formData.append("action", "renew");
    // formData.append("fileExtension", this.file.type);
    // formData.append("linkDoc", this.id);
    // this.appMaster.uploadFile(formData).subscribe((result) => {
    //   if (result) {
    //     this.uploadPass = true;
    //     this.fileImg = result[0]?.filePath;
    //     this.form.pic = result[0]?.fileName + "." + result[0]?.fileType;
    //     this.file.linkDoc = result[0]?.linkDoc;
    //     
    //   }
    // });
  }

  showImg(file) {
    const dialogRef = this.dialog.open(ImageModalComponent, {
      disableClose: true,
      // maxWidth: "none",
      width: "45vw",
      height: "35vw",
      data: {
        path: file,
        title: "สำเนาอ.2",
      },
    });
  }

  async deleteImg(e) {
    const alertConfirm = await this.confirmService.open({
      header: "",
      content: "ต้องการลบเอกสารหรือไม่",
    });
    // ;
    if (alertConfirm) {

      let rqDelImg = {
        id: e.id
      };
      this.appO2Service.deleteO2Img(rqDelImg).then(res => {
        if (res === 'error') {
          return;
        } else {
          this.formFile = null;
          this.fileImg = null;
          this.form.pic = null;
          this.form.linkDoc = null;
          this.dataSource = [];
        }
      })
    }
  }

  openO2Copy() {
    this.dialog.open(O2DocViewerComponent, {
      disableClose: true,
      data: {
        id: this.id,
      },
      width: '85vw',
      height: '85vh'
    });

  }
}
