import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";
import { IApiGetListReceiverRequest, IApiGetReceiverRequest, IApiReceiverSaveRequest } from "app/business/interface/api/api-receiver.interface";

@Injectable({
    providedIn: 'root'
  })
  export class ApiReceiver {
    constructor(
        private apiService: ApiService
    ) { }
    
    GetListReceiver(request: IApiGetListReceiverRequest) : Observable<any>{
        return this.apiService.post('/api/getlistreceiver',request)
    }

    ReceiverSave(request: IApiReceiverSaveRequest) : Observable<any>{
        return this.apiService.post('/api/receiversave',request)
    }
    GetDataReceiver(request: IApiGetReceiverRequest) : Observable<any>{
        return this.apiService.post('/api/getdatareceiver',request)
    }
  }
  