<mat-card class="example-card">
  <mat-card-header class="fontTitleHeader">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal">สำเนาเอกสาร อ.2 (Copy of O2)</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content style="padding: 16px">
    <div class="flex flex-col" style="gap: 10px;">
      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            ข้อมูลเอกสาร อ.2 (O2 Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> เลขที่คำร้องขอ :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.docNo}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">วันเวลาที่ยื่นคำร้อง :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.dateCreated | thaidate}} {{form.dateCreated |
                      date:'HH:mm'}}</div>
                  </div>
                  <div class="basis-1/2 f18" style="align-self: center;"></div>
                </div>
              </div>
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">ประเภทสินค้า :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.typeOfDocument}}</div>
                  </div>
                  <div class="basis-1/2 f18" style="align-self: center;"></div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ใบอนุญาตกระทรวงพาณิชย์ :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">พณ{{form.docNo}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">วันที่ออกใบอนุญาต :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.issueDate | thaidate}}</div>
                  </div>
                  <div class="basis-1/2 f18" style="align-self: center;"></div>
                </div>
              </div>
              <div class="flex-1 w-33">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">วันหมดอายุ :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.expireDate | thaidate}}</div>
                  </div>
                  <div class="basis-1/2 f18" style="align-self: center;"></div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- ผู้ส่งออก (Exporter Information) และ ข้อมูลบริษัทผู้รับสินค้า (ผู้ซื้อ) (Receiver Information) -->
      <div class="flex flex-row p10" style="gap: 10px">
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ผู้ส่งออก (Exporter Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%; gap: 5px">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center;width: 30%;"> เลขประจำตัวผู้ส่งออก :</div>
                  <div style="width: 10%;">
                    <div class="f18" style="align-self: center;">{{form.exporterNo}}</div>
                  </div>
                  <div class="f18" style="align-self: center;width: 40%;"> เลขที่ประจำตัวผู้เสียภาษี :</div>
                  <div style="width: 20%;">
                    <div class="f18" style="align-self: center;">{{form.companyTaxId}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;">
                    ชื่อบริษัทผู้ส่งออก (ภาษาไทย) :</div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.companyThName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;">
                    ชื่อบริษัทผู้ส่งออก (ภาษาอังกฤษ) :</div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.companyEnName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> ที่อยู่บริษัท: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.exporterStreetandNo}}
                      {{form.exporterDistrict}} {{form.exporterSubProvince}} {{form.exporterProvince}}
                      {{form.exporterPostcode}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ข้อมูลบริษัทผู้รับสินค้า (ผู้ซื้อ) (Receiver Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;">
                    ข้อมูลบริษัทผู้รับสินค้า (ผู้ซื้อ) :</div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.recieveEnName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> ที่อยู่บริษัท: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.recieveStreetNo}}
                      {{form.recieveDistrict}} {{form.recieveSubProvince}} {{form.recieveProvince}}
                      {{form.recievePostcode}} {{form.recieveCountry}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <!-- ข้อมูลผู้กระทำการแทนผู้ส่งออก (Shipper Information) และ ข้อมูลอื่น ๆ (Other Information) -->
      <div class="flex flex-row p10" style="gap: 10px">
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ข้อมูลผู้กระทำการแทนผู้ส่งออก (Shipper Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ชื่อบริษัท :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.shippingName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;"> ที่อยู่บริษัท: </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.shippingStreetNo}}
                      {{form.shippingDistrict}} {{form.shippingSubProvince}} {{form.shippingProvince}}
                      {{form.shippingPostcode}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ข้อมูลอื่น ๆ (Other Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%; gap: 5px">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center;width: 25%;"> ประเทศผู้ซื้อ :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{form.buyerCountry}}</div>
                  </div>
                  <div class="f18" style="align-self: center;width: 25%;"> ประเทศผู้รับ :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{form.destinationCountry}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 25%;padding-right: 20px;"> ส่งออกโดยทาง:
                  </div>
                  <div class="flex align-center" style="width: 75%;">
                    <div class="f18" style="align-self: center;">{{form.transportationModeName}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="flex-1" style="width: 100%; gap: 5px">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center;width: 25%;"> ชื่อพาหนะ / เที่ยว :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{form.vesselName}}</div>
                  </div>
                  <div class="f18" style="align-self: center;width: 25%;"> สถานที่ส่งออก :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{form.porttxt}}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="flex-1" style="width: 100%; gap: 5px">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center;width: 25%;"> สกุลเงิน :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{form.currency}}</div>
                  </div>
                  <div class="f18" style="align-self: center;width: 25%;"> อัตราแลกเปลี่ยน :</div>
                  <div class="flex align-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">{{ form.formatExchangeRate }}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- รายการสินค้า (Other Information) -->
      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            รายการสินค้า (O2 Stock)
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding: 16px;">
            <div class="flex">
              <div class="flex-1 w-100">
                <div class="mat-elevation-z8" style="width: 100%">
                  <dx-data-grid id="gridContainer" [dataSource]="dataSource" [columnAutoWidth]="true"
                    [showBorders]="true" [allowColumnReordering]="true" [showBorders]="true"
                    [noDataText]="'ไม่มีข้อมูล'">
                    <dxi-column caption="รายการที่" alignment="center" cellTemplate="wFlow" [width]='100'>
                    </dxi-column>
                    <div *dxTemplate="let row of 'wFlow'">
                      {{row.rowIndex+1}}
                    </div>
                    <dxi-column alignment="center" dataField="goodsDescription" caption="รายละเอียดสินค้า">
                    </dxi-column>
                    <dxi-column alignment="center" caption="ราคา">
                      <dxi-column alignment="center" caption="ต่อจำนวน" dataField="unitPrice">
                      </dxi-column>
                      <dxi-column alignment="center" caption="FOB(บาท)" dataField="fobValueBaht">
                      </dxi-column>
                    </dxi-column>
                    <dxi-column alignment="center" dataField="quantity" caption="จำนวน">
                    </dxi-column>
                    <dxi-column alignment="center" caption="น้ำหนัก">
                      <dxi-column alignment="center" caption="สุทธิ" dataField="netWeight">
                      </dxi-column>
                      <dxi-column alignment="center" caption="เฉพาะต่อหน่วย" dataField="netWeightUnit">
                      </dxi-column>
                      <dxi-column alignment="center" caption="ใช้ไปแล้ว" dataField="weightUsed">
                      </dxi-column>
                      <dxi-column alignment="center" caption="คงเหลือ" dataField="availableNet">
                      </dxi-column>
                    </dxi-column>
                    <dxi-column alignment="center" dataField="itemStatusName" caption="สถานะ">
                    </dxi-column>
                    <dxi-column alignment="center" caption="Action" cellTemplate="action"> </dxi-column>
                    <div *dxTemplate="let row of 'action'">
                      <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above"
                        (click)="addProduct(row.data.id ,row.data )">
                        <i class="ti ti-info-circle"></i>
                      </button>
                      <!-- <button mat-icon-button matTooltip="แก้ไข" *ngIf="!disabled && typePage == 'o2'"
                        (click)="addProduct('edit',row.data.id)" matTooltipPosition="above">
                        <mat-icon>edit</mat-icon>
                      </button>
                      <button mat-icon-button matTooltip="แก้ไข" *ngIf="!disabled && typePage == 'o2'"
                        (click)="delProduct(row.data.id)" matTooltipPosition="above">
                        <mat-icon>delete</mat-icon>
                      </button> -->
                    </div>
                  </dx-data-grid>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- ลายมือชื่อ -->
      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            ลายมือชื่อ (Signature)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 16px;">
            <div class="flex flex-row p10" style="gap: 10px">
              <mat-card style="width: 50%">
                <mat-card-header class="fontTitleHeader">
                  ลายมือชื่อผู้รับมอบอำนาจลงนามคนที่ 1
                </mat-card-header>
                <mat-card-content class="flex flex-col example-headers-align"
                  style="padding: 16px; gap: 10px; height: 170px;">
                  <div class="flex" style="height: 100%">
                    <div class="flex-1" style="height: 100%; gap: 5px">
                      <ng-container *ngIf="form.signPic1 != undefined && form.signPic1 != null && form.signPic1 != ''">
                        <div class="flex flex-row p10 justify-center" style="height: 100%">
                          <img [src]="form.signPic1" style="width: auto" />
                        </div>
                      </ng-container>
                      <ng-container *ngIf="form.signPic1 == undefined || form.signPic1 == null || form.signPic1 == ''">
                        <div class="flex flex-row p10 justify-center" style="height: 100%">
                          <div style="align-self: center;">
                            <div class="flex align-center" style="gap: 4px; color: grey;">
                              <i class="ti ti-signature-off" style="font-size: 30px"></i> <span
                                style="font-size: 18px">ไม่ปรากฎลายมือชื่อ</span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-card style="width: 50%">
                <mat-card-header class="fontTitleHeader">
                  ลายมือชื่อผู้รับมอบอำนาจลงนามคนที่ 2
                </mat-card-header>
                <mat-card-content class="flex flex-col example-headers-align"
                  style="padding: 16px; gap: 10px; height: 170px;">
                  <div class="flex" style="height: 100%">
                    <div class="flex-1" style="height: 100%; gap: 5px">
                      <ng-container *ngIf="form.signPic2 != undefined && form.signPic2 != null && form.signPic2 != ''">
                        <div class="flex flex-row p10 justify-center" style="height: 100%">
                          <img [src]="form.signPic2" style="width: auto" />
                        </div>
                      </ng-container>
                      <ng-container *ngIf="form.signPic2 == undefined || form.signPic2 == null || form.signPic2 == ''">
                        <div class="flex flex-row p10 justify-center" style="height: 100%">
                          <div style="align-self: center;">
                            <div class="flex align-center" style="gap: 4px; color: grey;">
                              <i class="ti ti-signature-off" style="font-size: 30px"></i> <span
                                style="font-size: 18px">ไม่ปรากฎลายมือชื่อ</span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
            <div class="flex flex-row p10" style="gap: 10px">
              <mat-card style="width: 50%">
                <mat-card-header class="fontTitleHeader">
                  ลายมือชื่อผู้รับมอบอำนาจลงนามคนที่ 3
                </mat-card-header>
                <mat-card-content class="flex flex-col example-headers-align"
                  style="padding: 16px; gap: 10px; height: 170px;">
                  <div class="flex" style="height: 100%">
                    <div class="flex-1" style="height: 100%; gap: 5px">
                      <ng-container *ngIf="form.signPic3 != undefined && form.signPic3 != null && form.signPic3 != ''">
                        <div class="flex flex-row p10 justify-center" style="height: 100%">
                          <img [src]="form.signPic3" style="width: auto" />
                        </div>
                      </ng-container>
                      <ng-container *ngIf="form.signPic3 == undefined || form.signPic3 == null || form.signPic3 == ''">
                        <div class="flex flex-row p10 justify-center" style="height: 100%">
                          <div style="align-self: center;">
                            <div class="flex align-center" style="gap: 4px; color: grey;">
                              <i class="ti ti-signature-off" style="font-size: 30px"></i> <span
                                style="font-size: 18px">ไม่ปรากฎลายมือชื่อ</span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
              <mat-card style="width: 50%">
                <mat-card-header class="fontTitleHeader">
                  ลายมือชื่อผู้รับมอบอำนาจลงนามคนที่ 4
                </mat-card-header>
                <mat-card-content class="flex flex-col example-headers-align"
                  style="padding: 16px; gap: 10px; height: 170px;">
                  <div class="flex" style="height: 100%">
                    <div class="flex-1" style="height: 100%; gap: 5px">
                      <ng-container *ngIf="form.signPic4 != undefined && form.signPic4 != null && form.signPic4 != ''">
                        <div class="flex flex-row p10 justify-center" style="height: 100%">
                          <img [src]="form.signPic4" style="width: auto" />
                        </div>
                      </ng-container>
                      <ng-container *ngIf="form.signPic4 == undefined || form.signPic4 == null || form.signPic4 == ''">
                        <div class="flex flex-row p10 justify-center" style="height: 100%">
                          <div style="align-self: center;">
                            <div class="flex align-center" style="gap: 4px; color: grey;">
                              <i class="ti ti-signature-off" style="font-size: 30px"></i> <span
                                style="font-size: 18px">ไม่ปรากฎลายมือชื่อ</span>
                            </div>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>