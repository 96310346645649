import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class ApiMs13Service {
  constructor(private apiService: ApiService) {}

  MS13itemsave(data: any): Observable<any[]> {
    return this.apiService.post("/api/MS13itemsave", data);
  }
  getDataMS13Detail(data: any): Observable<any[]> {
    return this.apiService.post("/api/getDataMS13Detail", data);
  }
  getDataMS13Attachment(data: any): Observable<any[]> {
    return this.apiService.post("/api/getDataMS13Attachment", data);
  }
  getDataMS24Attachment(data: any): Observable<any[]> {
    return this.apiService.post("/api/getDataMS24Attachment", data);
  }
  getMS13item(data: any): Observable<any[]> {
    return this.apiService.post("/api/getMS13item", data);
  }

  MS13save(data: any): Observable<any[]> {
    return this.apiService.post("/api/MS13save", data);
  }

  getdataMS13(data: any): Observable<any[]> {
    return this.apiService.post("/api/getDataMS13Header", data);
  }

  MS13list(data: any): Observable<any[]> {
    return this.apiService.post("/api/getMs13List", data);
  }

  MS13Nolist(data: any): Observable<any[]> {
    return this.apiService.post("/api/getMs13NoList", data);
  }
  getDataMS13ById(data: any): Observable<any[]> {
    return this.apiService.post("/api/getDataMS13ById", data);
  }
  checkStatusPrint(data: any): Observable<any[]> {
    return this.apiService.post("/api/checkStatusPrint", data);
  }

  renewMS13sent(data: any): Observable<any[]> {
    return this.apiService.post("/api/renewMS13sent", data);
  }

  getrenewMS13(data: any): Observable<any[]> {
    return this.apiService.post("/api/getrenewMS13", data);
  }
  
  exportMs13(data: any): Observable<any[]> {
    return this.apiService.post("/api/exportMs13", data);
  }

  MS13ExportExcelStaffDocumentRequest(request: any): Observable<any[]> {
    return this.apiService.post('/api/MS13ExportExcelStaffDocumentRequest', request);
  }
  MS13ExportExcelStaffAllApprove(request: any): Observable<any[]> {
    return this.apiService.post('/api/MS13ExportExcelStaffAllApprove', request);
  }
  MS13ExportExcelStaffAllApproveNoMs24(request: any): Observable<any[]> {
    return this.apiService.post('/api/MS13ExportExcelStaffAllApproveNoMs24', request);
  }
  MS13ExportExcelStaffSurveyer(request: any): Observable<any[]> {
    return this.apiService.post('/api/MS13ExportExcelStaffSurveyer', request);
  }
  MS13ExportExcelStaffCountBotReview(request: any): Observable<any[]> {
    return this.apiService.post('/api/MS13ExportExcelStaffCountBotReview', request);
  }
  MS13ExportExcelStaffControl(request: any): Observable<any[]> {
    return this.apiService.post('/api/MS13ExportExcelStaffControl', request);
  }
  MS13ExportExcelStaffRice(request: any): Observable<any[]> {
    return this.apiService.post('/api/MS13ExportExcelStaffRice', request);
  }
  MS13ExportExcelStaffSummaryPrint(request: any): Observable<any[]> {
    return this.apiService.post('/api/MS13ExportExcelStaffSummaryPrint', request);
  }
  getDataMs13Sur(request: any): Observable<any[]> {
    return this.apiService.post('/api/getdatams13sur', request);
  } 
  genRunningMs13(request: any): Observable<any[]> {
    return this.apiService.post('/api/genRunningMs13', request);
  }
  updateUseDataMS13(request: any): Observable<any[]> {
    return this.apiService.post('/api/updateUseDataMS13', request);
  }
  updateStatusMs13(request: any): Observable<any[]> {
    return this.apiService.post('/api/updateStatusMs13', request);
  }
  updateBotReceive(request: any): Observable<any[]> {
    return this.apiService.post('/api/updateBotReceive', request);
  }
  updateBotApprove(request: any): Observable<any[]> {
    return this.apiService.post('/api/updateBotApprove', request);
  }
  getRenewMs13List(request: any): Observable<any[]> {
    return this.apiService.post('/api/getRenewMs13List', request);
  }

  saveMs13FromO2(request: any): Observable<any[]> {
    return this.apiService.post('/api/saveMs13FromO2', request);
  }

  saveMs13SurveyorAuditPerson(request: any): Observable<any> {
    return this.apiService.post('/api/saveMs13SurveyorAuditPerson', request);
  }

  saveConfirmMs13SurveyorResult(request: any): Observable<any> {
    return this.apiService.post('/api/saveConfirmMs13SurveyorResult', request);
  }

  getMs13SurveyorAuditPerson(request: any): Observable<any> {
    return this.apiService.post('/api/getMs13SurveyorAuditPerson', request);
  }

  getMs13SurveyorResultList(request: any): Observable<any> {
    return this.apiService.post('/api/getMs13SurveyorResultList', request);
  }

  getMs13SurveyorFullResult(request: any): Observable<any> {
    return this.apiService.post('/api/getMs13SurveyorFullResult', request);
  }

  saveMs13SurveyResult(request: any): Observable<any> {
    return this.apiService.post('/api/saveMs13SurveyResult', request);
  }

  saveMs13JobStatus(request: any): Observable<any> {
    return this.apiService.post('/api/saveMs13JobStatus', request);
  }

  saveMs13SurveyorJob(request: any): Observable<any> {
    return this.apiService.post('/api/saveMs13SurveyorJob', request);
  }

  printCertificateMS13(request: any): Observable<any> {
    return this.apiService.post('/api/printCertificateMS13', request);
  }

  printCopyCertificateMS13(request: any): Observable<any> {
    return this.apiService.post('/api/printCopyCertificateMS13', request);
  }

  deleteMs13Attachment(request: any): Observable<any> {
    return this.apiService.post('/api/deleteMs13Attachment', request);
  }

  deleteMs13Item(request: any): Observable<any> {
    return this.apiService.post('/api/deleteMs13Item', request);
  }

  updateStatusRenewMs13(data: any): Observable<any[]> {
    return this.apiService.post('/api/updateStatusRenewMs13', data);
  }

  lockPrint(data: any): Observable<any[]> {
    return this.apiService.post('/api/lockPrint', data);
  }
    
  getMs13SurveyLocationHistory(data: any): Observable<any[]> {
    return this.apiService.post('/api/getMs13SurveyLocationHistory', data);
  }

  recalculateMs13FobData(data: any): Observable<any[]> {
    return this.apiService.post('/api/recalculateMs13FobData', data);
  }

  updateMs13ApproveStatus(data: any): Observable<any[]> {
    return this.apiService.post('/api/updateMs13ApproveStatus', data);
  }

  getMs13DocumentActionHistoryList(data: any): Observable<any[]> {
    return this.apiService.post('/api/getMs13DocumentActionHistoryList', data);
  }

  getMs13PerDocumentActionHistoryList(data: any): Observable<any[]> {
    return this.apiService.post('/api/getMs13PerDocumentActionHistoryList', data);
  }
}
