import { items } from "./../../../../mock-api/apps/file-manager/data";
import { DateTime } from "luxon";
import { products } from "./../../../../mock-api/apps/ecommerce/inventory/data";
import { AlertMessageComponent } from "@bundle/component/alert-message/alert-message.component";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AppO2Service } from "app/business/service/app/app-o2.service";
import { CreateProductGoodsItemModalComponent } from "../create-product-goods-item-modal/create-product-goods-item-modal.component";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
} from "@angular/forms";
import {
  count,
  map,
  Observable,
  startWith,
  Subject,
  take,
  takeUntil,
} from "rxjs";
import moment from "moment";
import { AppMs13Service } from "app/business/service/app/app-ms13.service";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { ShippingmaskModalComponent } from "../../ms13/shippingmask-modal/shippingmask-modal.component";
import { SurveyorMS13InquiryComponent } from "../../surveyor/surveyor-ms13-inquiry.component";
import { AppSurveyorService } from "app/business/service/app/app-surveyor.service";
import { environment } from "environments/environment";
import { SurveyorModalComponent } from "../../ms13/surveyor-modal/surveyor-modal.component";
import { ImageModalComponent } from "../../ms13/Image-modal/Image-modal.component";
import { ExportPdfModalComponent } from "../../ms13/export-pdf-modal/export-pdf-modal.component";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import _ from "lodash";
import { AmountOfWeightTouseMs13ModalComponent } from "../../ms13/amount-of-weight-touse-ms13-modal/amount-of-weight-touse-ms13-modal.component";
import { AuthService } from "app/core/auth/auth.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { ReCalculateFobRateComponent } from "../re-calculate-fob-rate/re-calculate-fob-rate.component";
import { O2RejectReasonModalComponent } from "../o2-reject-reason-modal/o2-reject-reason-modal.component";
import { SubDistrictSelectionDialogComponent } from "../../ms13/sub-district-selection-dialog/sub-district-selection-dialog.component";

@Component({
  selector: "app-create-o2-manual-inquiry",
  templateUrl: "./create-o2-manual-inquiry.component.html",
  styleUrls: ["./create-o2-manual-inquiry.component.css"],
})
export class CreateO2ManualInquiryComponent implements OnInit {
  //Attibutes for SurveyorPage
  @ViewChild(SurveyorMS13InquiryComponent)
  SurveyorForm: SurveyorMS13InquiryComponent;
  ms13DForSur: any;
  // End

  selectedReceiverItem: boolean = false;
  form: any = {};
  formSur: any = {};
  formStatus: any = {};
  fileImg: any;
  file: any;
  formFile: any = {};
  value: any[] = [];
  typePage: any;
  dataSource: any = [];
  dataSourceAtt: any = [];
  source: any;
  productId: any;
  formId: any;
  disabled: boolean = false;
  countryList: any = [];
  productList: any = [];
  portList: any = [];
  export: any = {};
  currencyList: any = [];
  transModeList: any = [];
  companyData: any;
  selectedValue: any;
  valueSelect = false;
  isLoading: boolean;
  tariffList: any = [];
  productName: any;
  provinceList: any = [];
  statusList: any = [];
  shippingList: any = [];
  shippingMaskList: any = [];
  typeClassList: any = [];
  currentDate: Date = new Date();
  formAttachment: any = {};
  companyList: any = [];
  companyRelationShipperList: any = [];
  companyReceiverList: any = [];
  surveyorList: any = [];
  dropDownSurveyorList: any = [];
  hour: any = [];
  minute: any = [];
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  linkMs13Export: any;
  dftId: any;
  type: any;
  checkAddProduct: boolean = false;
  dataSourceAttachPic: any = [];
  selectionProduct: any = [];
  url: any;
  buttonList: any[] = [];
  timer: any = 0;
  countrySearch = new FormControl();
  currencySearch = new FormControl();
  currencyOtherSearch = new FormControl();
  countryISOSearch = new FormControl();
  countryRecieveSearch = new FormControl();
  countryLoadedPortSearch = new FormControl();
  companyShipperSearch = new FormControl();
  companyExporterSearch = new FormControl();
  companyReceiverSearch = new FormControl();

  @ViewChild("FormValidateItem") FormValidateItem: NgForm;

  filteredOptionsTariff$: Observable<string[]>;
  filteredOptionsCountry$: Observable<string[]>;
  filteredOptionsCurrency$: Observable<string[]>;
  filteredOptionsCurrencyOther$: Observable<string[]>;
  filteredOptionsISOCountry$: Observable<string[]>;
  filteredOptionsRecieveCountry$: Observable<string[]>;
  filteredOptionsLoadedPortCountry$: Observable<string[]>;
  filteredOptionsCompanyShipper$: Observable<string[]>;
  filteredOptionsCompanyExporter$: Observable<string[]>;
  filteredOptionsCompanyReceiver$: Observable<string[]>;
  selectedRows: any[] = [];
  maxSelection: number = 3;
  o2docNo: string = "";

  private _unsubscribeAll$: Subject<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private appO2Service: AppO2Service,
    private appMs13Service: AppMs13Service,
    private appMs24Service: AppMs24Service,
    private confirmService: ConfirmDialogService,
    private appMasterService: AppMasterService,
    private fb: FormBuilder,
    private alertService: AlertMessageService,
    private appSuveryorService: AppSurveyorService,
    private _authService: AuthService,
    private appMenuService: AppMenuService
  ) {
  }

  async ngOnInit() {
    // ;
    this.getRouter();

    this._unsubscribeAll$ = new Subject();
    const {
      source,
      docNo,
      type,
      page,
      product,
      expireDate,
      issueDate,
      status,
      id,
      productId,
      refNo,
      category,
      vesselName,
      dftId,
    } = this.route.snapshot.queryParams;
    this.source = source;
    this.typePage = page;
    this.type = type;
    this.form.docNo = docNo;
    this.form.status = Number(status);
    this.form.id = id;
    this.form.product = product ?? productId;
    this.formId = id;
    this.dftId = dftId;
    this.productId = product ?? productId;
    const rqStatus = {
      statusId: 0,
      isActive: "Y",
    };
    this.appMasterService.getStatus(rqStatus).then((res) => {
      this.statusList = res;
    });

    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: Number(this.productId),
      isActive: "Y",
    };
    this.appMasterService.getProduct(formProduct).then((res) => {
      this.productList = res;
      const productData = this.productList.find(
        (f) => f.productId == this.productId
      );
      if (productData) {
        this.form.docTypeId = productData.docTypeId;
        this.form.productName = productData.docTypeName;
      }

      // this.form.productName = this.productList.find(
      //   (f) => f.docTypeId == this.productId
      // )?.productName;
    });


    await this.getCountry();
    await this.getCurrency();
    await this.loadData();
    this.initForm();

    if (type != "New") {
      this.disabled = false;
      if (this.typePage == "ms13") {
        this.appMs13Service.ms13Lists.subscribe((res) => {
          var length = res.length + 1;
          if (this.form.refNo == undefined && this.form.refNo != null) {
            this.form.refNo =
              length.toString().padStart(4, "0") +
              "/" +
              this.currentDate.getFullYear();
          }
          if (this.formId != null) {
            this.appO2Service
              .getO2item({
                ID: 0,
                hId: this.formId,
              })
              .then((res) => {
                this.dataSource = res.map((m) => {
                  return {
                    ...m,
                    itemStatusName: this.statusList.find(
                      (f) => f.statusId == m.itemStatus
                    ).statusName,
                  };
                });
              });
          } else {
            this.dataSource = this.form?.item?.map((m) => {
              return {
                ...m,
                itemStatusName: this.statusList.find(
                  (f) => f.statusId == m.itemStatus
                ).statusName,
              };
            });
          }
        });
        if (category == "fromO2") {
          const formData = {
            ID: this.form.id,
            DocNo: this.form.docNo,
            CompanyName: this.userProfile.companyCode,
            ProductID: this.productId,
            // items: null,
          };
          this.appO2Service.getDataO2(formData).then((res) => {
            console.log("getDataO2", res);
            this.form = res;
            this.form.id = res?.id;
            this.form.o2Id = res?.id;
            this.form.o2DocNo = res.docNo;
            this.form.o2ExpireDate = res.expireDate;
            this.form.o2IssueDate = res.issueDate;
            this.form.attachO2 = res.attachO2;
            this.form.source = res.source;
            this.form.companyTax = res.companyTaxId;
            this.source = res.source;

            this.form.shippingProvince =
              this.form.shippingProvince == "กรุงเทพฯ"
                ? "กรุงเทพฯ"
                : this.form.shippingProvince;
            this.form.recieveProvince =
              this.form.recieveProvince == "กรุงเทพฯ"
                ? "กรุงเทพฯ"
                : this.form.recieveProvince;
            this.form.inspectProvince =
              this.form.inspectProvince == "กรุงเทพฯ"
                ? "กรุงเทพฯ"
                : this.form.inspectProvince;
            this.form.porttxt =
              this.form.porttxt == "ท่าเรือกรุงเทพฯ/แหลมฉบัง"
                ? "ท่ากรุงเทพฯ / แหลมฉบัง"
                : this.form.porttxt;
            this.dataSource = this.form.item.map(m => {
              return {
                ...m,
                itemStatusName: this.statusList.find(
                  (f) => f.statusId == m.itemStatus
                ).statusName,
              }
            });
          });
        }
        const formData = {
          ID: this.form.id,
          DocNo: this.form.docNo,
          CompanyName: this.userProfile.companyCode,
          ProductID: this.productId,
        };
        this.getData(formData);
      } else {
        const formData = {
          ID: this.form.id,
          DocNo: this.form.docNo,
          CompanyName: this.userProfile.companyCode,
          ProductID: this.productId,
        };
        this.getData(formData);
      }
    }

    if (type == "New") {
      this.getExporter(this.userProfile.cpId).then((t) => {
        if (this.form.exporterId != null) {
          if (this.typePage == "o2" && type == "New") {
            this.saveFrist(1);
          }
        }
      });

      //Default Exporter Information
      var cpId = Number(this.userProfile.cpId);
      var companyData = this.companyList.find(el => el.companyId == cpId);

      this.companyExporterSearch.patchValue(companyData);
      this.form.exporterCompanyId = companyData.companyId;
      this.form.companyEnName = companyData.nameEN.trim();
      this.form.companyThName = companyData.nameTH.trim();
      this.form.exporterStreetandNo = companyData.address.trim();
      this.form.exporterProvince = companyData.province.trim();
      this.form.exporterDistrict = companyData.district.trim();
      this.form.exporterPostcode = companyData.postcode.trim();
      this.form.exporterSubProvince = companyData.subProvince.trim();
      this.form.exporterNo = companyData.exporterNo.trim();
      this.form.companyTax = companyData.taxId.trim();
      this.form.companyTaxId = companyData.taxId.trim();
      this.form.exporterNo = companyData.exporterNo.trim();
      this.companyExporterSearch.disable();
    }

    if (this.typePage == "ms13") {
      this.form.inspectDate1 =
        this.productId == 3 || this.productId == 8 ? "08:00" : "08:30";
      this.form.inspectDate2 = "22:30";
    }

    this.form.refNo = refNo;
    this.form.issueDate =
      issueDate == null ? moment(new Date()) : moment(new Date(issueDate));
    this.form.expireDate = new Date(expireDate);
    this.form.vesselName =
      this.form.typePage == "ms24" ? vesselName : this.form.vesselName;
    this.disabled = type == "Detail" ? true : false;
    this.form.docNo = docNo;
    this.form.status = status;
    this.form.id = id;
    this.form.product = product ?? productId;
    this.o2docNo = docNo;
  }

  initForm() {
    this.filteredOptionsCountry$ = this.countrySearch.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.filterCountryValues(name as string) : this.countryList.slice();
      }),
    );

    this.filteredOptionsCurrency$ = this.currencySearch.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.filterCurrencyValues(name as string) : this.currencyList.slice();
      }),
    );

    this.filteredOptionsCurrencyOther$ = this.currencyOtherSearch.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.filterCurrencyValues(name as string) : this.currencyList.slice();
      }),
    );

    this.filteredOptionsISOCountry$ = this.countryISOSearch.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.filterCountryValues(name as string) : this.countryList.slice();
      }),
    );

    this.filteredOptionsRecieveCountry$ = this.countryRecieveSearch.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.filterCountryValues(name as string) : this.countryList.slice();
      }),
    );

    this.filteredOptionsLoadedPortCountry$ = this.countryLoadedPortSearch.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.filterCountryValues(name as string) : this.countryList.slice();
      }),
    );

    this.filteredOptionsCompanyShipper$ = this.companyShipperSearch.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.nameTH;
        return name ? this.filterCompanyShipperSearchValues(name as string) : this.companyRelationShipperList.slice();
      }),
    );

    this.filteredOptionsCompanyExporter$ = this.companyExporterSearch.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.filterCompanyExporterSearchValues(name as string) : this.companyList.slice();
      }),
    );

    this.filteredOptionsCompanyReceiver$ = this.companyReceiverSearch.valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this.filterCompanyReceiverSearchValues(name as string) : this.companyReceiverList.slice();
      }),
    );
  }

  async getCountry() {
    const rqCountry = {
      ID: "",
      deActive: "",
    };
    this.appMasterService.getCountry(rqCountry).then(res => {
      if (res.length === 0) {
        return;
      } else {
        this.countryList = res;
      }
    });
  }

  async getSurveyor() {
    const rqSurveyor = {
      surveyorID: "",
      productId: this.productId,
      isActive: "Y",
    };
    this.surveyorList = await this.appMasterService.getSurveyor(rqSurveyor);

    const rqSurveyorDd = {
      surveyorID: "",
      isActive: "Y",
    };
    this.surveyorList = await this.appMasterService.DropdownSurveyor(
      rqSurveyorDd
    );
  }

  async getCurrency() {
    const rqCurrency = {
      ID: "",
      name: "",
      code: "",
    };
    this.currencyList = await this.appMasterService.getCurrency(rqCurrency);
  }

  public filterCurrencyValues(search: any) {
    console.log(search);
    const filterValue = search.toLowerCase();

    return this.currencyList.filter(function (option) {
      if (option.name.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue)) {
        return option;
      }
    });
  }

  public filterCountryValues(search: any) {
    console.log(search);
    const filterValue = search.toLowerCase();

    return this.countryList.filter(function (option) {
      if (option.name.toLowerCase().includes(filterValue) || option.code.toLowerCase().includes(filterValue)) {
        return option;
      }
    });
  }

  public filterCompanyShipperSearchValues(search: any) {
    console.log(search);
    const filterValue = search.toLowerCase();

    return this.companyRelationShipperList.filter(function (option) {
      if (option.nameTH.toLowerCase().includes(filterValue)) {
        return option;
      }
    });
  }

  public filterCompanyExporterSearchValues(search: any) {
    console.log(search);
    const filterValue = search.toLowerCase();

    return this.companyList.filter(function (option) {
      if (option.nameTH.toLowerCase().includes(filterValue)) {
        return option;
      }
    });
  }

  public filterCompanyReceiverSearchValues(search: any) {
    console.log(search);
    const filterValue = search.toLowerCase();

    return this.companyReceiverList.filter(function (option) {
      if (option.nameEN.includes(filterValue)) {
        return option;
      }
    });
  }

  selectionCountryChange(e): void {
    this.form.buyerCountry = e.option.value.code;
    this.form.buyerCountryId = e.option.value.id;
    this.form.buyerCountryCode = e.option.value.code;
  }

  selectionCurrencyOtherChange(e): void {
    this.form.currency = e.option.value.code;
  }

  selectionCurrencyChange(e): void {
    if (this.dataSource.length > 0) {
      if (this.form.currency != e.option.value.code) {
        this.confirmService.open({
          header: "",
          content: "ตรวจพบการเพิ่มข้อมูลรายการสินค้าในแบบฟอร์มแล้ว คุณต้องการคำนวณ FOB ใหม่เลยหรือไม่?"
        }).then(res => {
          if (res) {
            this.form.currency = e.option.value.code;
            this.dialog.open(ReCalculateFobRateComponent, {
              disableClose: true,
              width: '600px',
            }).afterClosed().subscribe(res => {
              console.log(res);
              this.form.exchangeRate = res.exchangeRate;

              var rqReCalFob = {
                hId: this.form.id,
                currency: e.option.value.code,
                exchangeRate: res.exchangeRate
              };

              this.appO2Service.recalculateO2FobData(rqReCalFob).then(res => {
                if (res === 'error') {
                  return;
                } else {
                  this.dataSource = res.map(el => {
                    return {
                      ...el,
                      itemStatusName: this.statusList.find(
                        (f) => f.statusId == el.itemStatus
                      ).statusName,
                    }
                  });
                }
              })
            });
          } else {
            this.form.currency = e.option.value.code;
          }
        })
      } else {
        this.form.currency = e.option.value.code;
      }
    } else {
      this.form.currency = e.option.value.code;
    }
  }

  onExchangerateKeyup(e) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.recalculateExchangeRate(e);
    }, 500); // Specify the desired delay in milliseconds

    console.log(e, this.form.exchangeRate)
  }

  recalculateExchangeRate(e) {
    if (this.dataSource.length > 0) {
      if (this.form.exchangeRate != e.value) {
        this.confirmService.open({
          header: "",
          content: "ตรวจพบการเพิ่มข้อมูลรายการสินค้าในแบบฟอร์มแล้ว คุณต้องการคำนวณ FOB ใหม่เลยหรือไม่?"
        }).then(res => {
          if (res) {
            var rqReCalFob = {
              hId: this.form.id,
              currency: this.form.currency,
              exchangeRate: this.form.exchangeRate
            };

            this.appO2Service.recalculateO2FobData(rqReCalFob).then(res => {
              if (res === 'error') {
                return;
              } else {
                this.dataSource = res.map(el => {
                  return {
                    ...el,
                    itemStatusName: this.statusList.find(
                      (f) => f.statusId == el.itemStatus
                    ).statusName,
                  }
                });
              }
            });
          }
        })
      }
    }
  }

  selectionCountryISOChange(e): void {
    this.form.destinationCountryId = e.option.value.id;
    this.form.destinationCountry = e.option.value.code;
    this.form.destinationCountryCode = e.option.value.code;
  }

  selectionCountryRecieveChange(e): void {
    this.form.recieveCountryId = e.option.value.id;
    this.form.recieveCountry = e.option.value.code;
    this.form.recieveCountryCode = e.option.value.code;
  }

  selectionLoadedPortCountryChange(e): void {
    this.form.loadedPortCountryCode = e.option.value.code;
    this.form.loadedPortCountry = e.option.value.id;
    this.form.loadedPortCountryId = e.option.value.id;
  }

  selectionCompanyShipperChange(e): void {
    this.form.shippingId = e.option.value.companyId;
    this.form.shippingName = e.option.value.nameTH.trim();
    this.form.shippingCompanyId = e.option.value.companyId;
    this.form.shippingStreetNo = e.option.value.address.trim();
    this.form.shippingProvince = e.option.value.province.trim();
    this.form.shippingDistrict = e.option.value.district.trim();
    this.form.shippingPostcode = e.option.value.postcode.trim();
    this.form.shippingSubProvince = e.option.value.subProvince.trim();
  }

  selectionCompanyExporterChange(e): void {
    this.form.exporterCompanyId = e.option.value.companyId;
    this.form.companyEnName = e.option.value.nameEN.trim();
    this.form.companyThName = e.option.value.nameTH.trim();
    this.form.exporterStreetandNo = e.option.value.address.trim();
    this.form.exporterProvince = e.option.value.province.trim();
    this.form.exporterDistrict = e.option.value.district.trim();
    this.form.exporterPostcode = e.option.value.postcode.trim();
    this.form.exporterSubProvince = e.option.value.subProvince.trim();
  }

  selectionCompanyReceiverChange(e): void {
    this.form.recieveThName = e.option.value.nameEN.trim();
    this.form.recieveEnName = e.option.value.nameEN.trim();
    this.form.recieveStreetNo = e.option.value.address.trim();
    this.form.recieveProvince = e.option.value.province.trim();
    this.form.recieveDistrict = e.option.value.district.trim();
    this.form.recievePostcode = e.option.value.postcode.trim();
    this.form.recieveSubProvince = e.option.value.subProvince.trim();

    //เปลี่ยนประเทศ Receiver
    var country = this.countryList.find(el => el.code === e.option.value.countryCode);

    if (country != null) {
      this.form.recieveCountryId = country.id;
      this.form.recieveCountry = country.name;
      this.form.recieveCountryCode = country.code;
      this.countryRecieveSearch.patchValue(country);
    }

    this.countryRecieveSearch.disable();

    if (!this.disabled) {
      this.selectedReceiverItem = true;
    }
  }

  cancelReceiverItem() {
    this.countryRecieveSearch.setValue("");
    this.countryRecieveSearch.enable();
    this.companyReceiverSearch.setValue("");
    this.form.recieveThName = ""
    this.form.recieveEnName = "";
    this.form.recieveStreetNo = "";
    this.form.recieveProvince = "";
    this.form.recieveDistrict = "";
    this.form.recievePostcode = "";
    this.form.recieveSubProvince = "";
    this.form.recieveCountryId = "";
    this.form.recieveCountry = "";
    this.form.recieveCountryCode = "";
    
    this.selectedReceiverItem = false;
  }

  displayCompanyExporter(company): string {
    return company && company.nameTH ? `${company.nameTH}` : '';
  }

  displayCompanyShipper(company): string {
    return company && company.nameTH ? `${company.nameTH}` : '';
  }

  displayCompanyReceiver(company): string {
    return company && company.nameEN ? `${company.nameEN}` : '';
  }

  displayCountryReceive(country): string {
    return country && country.name ? `${country.name}` : '';
  }

  displayCountryIso(country): string {
    return country && country.name ? `${country.name}` : '';
  }

  displayCountry(country): string {
    return country && country.name ? `${country.name}` : '';
  }

  displayCountryLoadedPort(country): string {
    return country && country.name ? `${country.name}` : '';
  }

  displayCurrency(currency): string {
    return currency && currency.name ? `${currency.name}` : '';
  }

  displayCurrencyOther(currency): string {
    return currency && currency.name ? `${currency.name}` : '';
  }

  getData(e) {
    if (this.typePage == "o2") {
      this.appO2Service.getDataO2(e).then((res) => {
        this.form = res;
        this.form.id = res.id;
        this.form.o2Id = res.id;
        this.form.o2DocNo = res.docNo;
        this.form.o2ExpireDate = res.expireDate;
        this.form.o2IssueDate = res.issueDate;
        this.form.attachO2 = res.attachO2;
        this.form.source = res.source;
        this.form.companyTax = res.companyTaxId;
        this.form.shippingProvince =
          this.form.shippingProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.shippingProvince;
        this.form.recieveProvince =
          this.form.recieveProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.recieveProvince;
        this.form.inspectProvince =
          this.form.inspectProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.inspectProvince;
        this.form.porttxt =
          this.form.porttxt == "ท่าเรือกรุงเทพฯ/แหลมฉบัง"
            ? "ท่ากรุงเทพฯ / แหลมฉบัง"
            : this.form.porttxt;
        // this.buyerCountryForm = this.fb.group({
        //   countrySearch: [this.form.buyerCountry],
        //   currencySearch: [this.form.currency],
        //   currencyOtherSearch: [this.form.currency],
        //   countryISOSearch: [this.form.destinationCountry],
        //   countryRecieveSearch: [this.form.recieveCountry],
        //   countryLoadedPortSearch: [this.form.loadedPortCountry],
        //   companyShipperSearch: [this.form.shippingName],
        //   companyExporterSearch: [this.form.companyThName],
        //   companyReceiverSearch: [this.form.recieveThName],
        // });

        if (this.disabled) {
          this.countrySearch.disable();
          this.currencySearch.disable();
          this.currencyOtherSearch.disable();
          this.countryISOSearch.disable();
          this.countryRecieveSearch.disable();
          this.countryLoadedPortSearch.disable();
          this.companyShipperSearch.disable();
          this.companyExporterSearch.disable();
          this.companyReceiverSearch.disable();
        }
        this.dataSource = this.form?.item?.map((m) => {

          var statusItem = this.statusList.find(
            (f) => f.statusId == m.itemStatus
          );

          m.itemStatusName = statusItem?.statusName;

          return m;
        });

        this.dataSourceAtt = this.form.attachment;
        this.fileImg = this.form.attachO2;
        this.form.productName = res.typeOfDocument;
        // this.form.productName = this.productList.find(
        //   (f) => f.docTypeId == this.productId
        // )?.productName;
        this.formStatus.status = this.form.status;

        this.patchValue();
      });
    } else if (this.typePage == "ms13") {
      const formDataMs13 = {
        ID: this.formId,
        docNo: this.form.docNo,
        companyTax: this.userProfile.companyCode,
        refNo: this.form.refNo,
        productId: this.form.product,
      };
      this.appMs13Service.getDataMS13(formDataMs13).then((res) => {
        if (res != null) {
          this.form = res;
          this.form.shippingProvince =
            this.form.shippingProvince == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.form.shippingProvince;
          this.form.recieveProvince =
            this.form.recieveProvince == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.form.recieveProvince;
          this.form.inspectProvince =
            this.form.inspectProvince == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.form.inspectProvince;

          this.companyExporterSearch.disable();

          if (this.disabled) {
            this.countrySearch.disable();
            this.currencySearch.disable();
            this.currencyOtherSearch.disable();
            this.countryISOSearch.disable();
            this.countryRecieveSearch.disable();
            this.countryLoadedPortSearch.disable();
            this.companyShipperSearch.disable();
            this.companyExporterSearch.disable();
            this.companyReceiverSearch.disable();
          }

          if (this.formId != null) {
            this.appO2Service
              .getO2item({
                ID: 0,
                hId: this.formId,
              })
              .then((res) => {
                this.dataSource = res.map((m) => {
                  return {
                    ...m,
                    itemStatusName: this.statusList.find(
                      (f) => f.statusId == m.itemStatus
                    ).statusName,
                  };
                });
                this.dataSourceAtt.push(this.form.attachment);
                this.fileImg = this.form.attachO2;
              });
          } else {
            this.dataSource = this.form.item.map((m) => {
              return {
                ...m,
                itemStatusName: this.statusList.find(
                  (f) => f.statusId == m.itemStatus
                ).statusName,
              };
            });
            this.dataSourceAtt.push(this.form.attachment);
            this.fileImg = this.form.attachO2;
          }
          this.fileImg = environment.api + this.form.attachO2;
        }
      });
    } else {
      const formDataMs24 = {
        ID: this.formId,
        docNo: this.form.docNo,
        companyTax: this.userProfile.companyCode,
        productId: this.form.product,
        items: {
          ID: this.formId,
          hId: this.formId,
        },
      };
      this.appMs24Service.getDataMS24(formDataMs24).then((res) => {
        if (res != null) {
          this.form = res;
          this.form.shippingProvince =
            this.form.shippingProvince == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.form.shippingProvince;
          this.form.recieveProvince =
            this.form.recieveProvince == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.form.recieveProvince;
          this.form.inspectProvince =
            this.form.inspectProvince == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.form.inspectProvince;

          this.companyExporterSearch.disable();

          if (this.disabled) {
            this.countrySearch.disable();
            this.currencySearch.disable();
            this.currencyOtherSearch.disable();
            this.countryISOSearch.disable();
            this.countryRecieveSearch.disable();
            this.countryLoadedPortSearch.disable();
            this.companyShipperSearch.disable();
            this.companyExporterSearch.disable();
            this.companyReceiverSearch.disable();
          }

          this.dataSource = this.form?.item?.map((m) => {
            return {
              ...m,
              itemStatusName: this.statusList.find(
                (f) => f.statusId == m.itemStatus
              ).statusName,
            };
          });
          // }
          this.fileImg = environment.api + this.form.attachO2;
        }
      });
    }
  }

  getExporter(cpid: any): Promise<any> {
    const rqExport = {
      companyID: cpid,
      companyType: 2,
    };
    return new Promise((resolve, reject) => {
      this.appMasterService.getExporter(rqExport).then((ee) => {
        this.export = ee;
        if (this.export != null && this.export != undefined) {
          this.form.exporterNo = this.export.exporterNo.trim();
          this.form.exporterCompanyId = this.export.companyId;
          this.form.exporterId = this.export.companyId;
          this.form.companyTax = this.export.taxId.trim();
          this.form.companyThName = this.export.nameTH.trim();
          this.form.companyEnName = this.export.nameEN.trim();
          this.form.exporterStreetandNo = this.export.address.trim();

          this.companyExporterSearch.patchValue(this.export);

          this.form.exporterProvince =
            this.export.province == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.export.province.trim();
          this.form.exporterSubProvince = this.export.subProvince.trim();
          this.form.exporterPostcode = this.export.postcode.trim();
          this.form.exporterDistrict = this.export.district.trim();
          this.appMasterService.getShipping(rqExport).then((res) => {
            this.shippingList = res;
          });
          resolve(this.form);
          // return this.form;
        }
      });
    });
  }

  async loadData() {
    if (this.source == "O" && this.dftId != undefined && this.dftId != null) {
      this.appO2Service.getDataDftO2({ Id: this.dftId }).then((res) => {
        console.log("getDataDftO2", res);
        if (res != null) {
          this.form = res;
          this.form.exporterCompanyId = res.exporterID;
          this.form.exporterNo = res.exporterNo;
          this.form.exporterProvince = res.exporterProvince;
          this.form.exporterPostcode = res.exporterPostcode;
          this.form.shippingProvince =
            this.form.shippingProvince == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.form.shippingProvince;
          this.form.recieveProvince =
            this.form.recieveProvince == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.form.recieveProvince;
          this.form.inspectProvince =
            this.form.inspectProvince == "กรุงเทพฯ"
              ? "กรุงเทพฯ"
              : this.form.inspectProvince;
          this.form.porttxt =
            this.form.porttxt == "ท่าเรือกรุงเทพฯ/แหลมฉบัง"
              ? "ท่ากรุงเทพฯ / แหลมฉบัง"
              : this.form.porttxt;
          // this.buyerCountryForm = this.fb.group({
          //   countrySearch: [this.form.buyerCountry],
          //   currencySearch: [this.form.currency],
          //   countryISOSearch: [this.form.destinationCountry],
          //   countryRecieveSearch: [this.form.recieveCountry],
          //   countryLoadedPortSearch: [this.form.loadedPortCountry],
          //   companyShipperSearch: [this.form.companyShipperSearch],
          //   companyExporterSearch: [this.form.companyThName],
          //   companyReceiverSearch: [this.form.companyReceiverThName],
          // });
          this.form.productName = res.typeOfDocument;
          this.dataSource = this.form.item.map(el => {
            return {
              ...el,
              itemStatusName: this.statusList.find(
                (f) => f.statusId == el.itemStatus
              ).statusName,
            }
          });
        }
      });
    } else {
    }

    var rqCompany = {
      companyId: this.userProfile.cpId
    }
    this.appMasterService.getCompanyByCompanyCode(rqCompany);
    this.appMasterService.companyData.subscribe((res) => {
      this.companyData = res;
    });

    // if (this.form.id != undefined && this.type != "Detail") {
    //   const rqExport = {
    //     companyID: this.userProfile.cpId,
    //     companyType: 2, //this.companyData.tcP_TYPEID,
    //   };
    //   await this.appMasterService.getExporter(rqExport).then(async (ee) => {
    //     this.export = ee;
    //     if (this.export != null && this.export != undefined) {
    //       // this.form.exporterNo = this.export.pk;
    //       // this.form.companyTax = this.export.taxId;
    //       // this.form.companyTaxId = this.export.taxId;
    //       this.form.companyThName = this.export.nameTH;
    //       this.form.companyEnName = this.export.nameEN;
    //       this.form.exporterStreetandNo = this.export.address;
    //       this.form.exporterProvince =
    //         this.export.province == "กรุงเทพฯ"
    //           ? "กรุงเทพฯ"
    //           : this.export.province;
    //       this.form.exporterSubProvince = this.export.subProvince;
    //       this.form.exporterPostcode = this.export.postcode;
    //       this.form.exporterDistrict = this.export.district;

    //       await this.appMasterService.getShipping(rqExport).then((res) => {
    //         this.shippingList = res;
    //       });
    //     }
    //   });
    // }

    const rqProvince = {
      ID: "",
      isActive: "",
    };
    this.appMasterService.getProvince(rqProvince).then((res) => {
      this.provinceList = res;
    });


    const rqPort = {
      ID: Number(this.form.docTypeId),
      isActive: "Y",
    };
    this.appMasterService.getPort(rqPort).then((res) => {
      this.portList = res;
    });
    const rqTransMode = {
      ID: "",
    };
    this.appMasterService.getTransMode(rqTransMode).then((res) => {
      this.transModeList = res;
    });

    await this.appMasterService.getCompanyList(null).then((res) => {
      this.companyList = res.filter((f) => f.typeId == 2);
    });

    const rqRelationShipper = {
      companyId: this.userProfile.cpId,
    };

    await this.appMasterService.getRelationShipperCompanyList(rqRelationShipper).then((res) => {
      if (res === 'error') {
        return;
      } else {
        if (res.length == 0) {
          var noRelationItem = {
            companyId: null,
            nameTH: "ไม่พบข้อมูลผู้กระทำการแทนผู้ส่งออก",
            address: "",
            district: "",
            subProvince: "",
            province: "",
            postcode: ""
          }

          this.companyRelationShipperList.push(noRelationItem);

          this.companyShipperSearch.patchValue(noRelationItem);
        } else {
          this.companyRelationShipperList = res;
        }
      }
    });

    if (this.userProfile.userGroupCode != "UG002" && this.userProfile.userGroupCode != "UG003") {
      rqRelationShipper.companyId = null;
    }

    await this.appMasterService.getCompanyReceiverList(rqRelationShipper).then((res) => {
      if (res === 'error') {
        return;
      } else {
        if (res.length == 0) {
          var noReceiverItem = {
            id: null,
            nameEN: "ไม่พบข้อมูลบริษัทผู้รับสินค้า (ผู้ซื้อ)",
            address: "",
            district: "",
            subProvince: "",
            province: "",
            postcode: "",
            country: "",
            countryCode: ""
          }

          this.companyReceiverList.push(noReceiverItem);

          this.companyReceiverSearch.patchValue(noReceiverItem);
        } else {
          this.companyReceiverList = res;
        }
      }
    });

    this.appMasterService
      .getTypeClass({
        docId: this.productId,
      })
      .then((res) => {
        this.typeClassList = res;
      });

    this.setData();
  }

  patchValue() {
    var companyData = this.companyList.find(el => el.nameTH?.trim() == this.form.companyThName?.trim());
    if (companyData != undefined && companyData != null) {
      this.companyExporterSearch.patchValue(companyData);
      this.companyExporterSearch.disable();
    }

    var currencySearch = this.currencyList.find(e => e.code?.trim() == this.form.currency?.trim());
    if (currencySearch != undefined && currencySearch != null) {
      this.currencySearch.patchValue(currencySearch);
    }

    var currencyOtherSearch = this.currencyList.find(e => e.code?.trim() == this.form.currency?.trim());
    if (currencyOtherSearch != undefined && currencyOtherSearch != null) {
      this.currencyOtherSearch.patchValue(currencyOtherSearch);
    }

    var countrySearch = this.countryList.find(e => e.code?.trim() == this.form.buyerCountryCode?.trim());
    if (countrySearch != undefined && countrySearch != null) {
      this.countrySearch.patchValue(countrySearch);
    }

    var countryISOSearch = this.countryList.find(e => e.code?.trim() == this.form.destinationCountryCode?.trim());
    if (countryISOSearch != undefined && countryISOSearch != null) {
      this.countryISOSearch.patchValue(countryISOSearch);
    }

    var countryRecieveSearch = this.countryList.find(e => e.code?.trim() == this.form.recieveCountryCode?.trim());
    if (countryRecieveSearch != undefined && countryRecieveSearch != null) {
      this.countryRecieveSearch.patchValue(countryRecieveSearch);
    }

    var countryLoadedPortSearch = this.countryList.find(e => e.code?.trim() == this.form.loadedPortCountryCode?.trim());
    if (countryLoadedPortSearch != undefined && countryLoadedPortSearch != null) {
      this.countryLoadedPortSearch.patchValue(countryLoadedPortSearch);
    }

    var companyShipperSearch = this.companyRelationShipperList.find(e => e.nameTH?.trim() == this.form.shippingName?.trim());
    if (companyShipperSearch != undefined && companyShipperSearch != null) {
      this.companyShipperSearch.patchValue(companyShipperSearch);
    }

    var companyReceiverSearch = this.companyReceiverList.find(e => e.nameEN?.trim() == this.form.recieveEnName?.trim());
    if (companyReceiverSearch != undefined && companyReceiverSearch != null) {
      this.companyReceiverSearch.patchValue(companyReceiverSearch);
    }
    
    if (!this.disabled) {
      if (this.form.recieveEnName != undefined && this.form.recieveEnName != null) {
        this.selectedReceiverItem = true;
      }
    }

    if (this.userProfile.gId == 'UG004' || this.userProfile.gId == 'UG005') {
      console.log("exporterId >>", this.form)

      var shipper = {
        nameTH: this.form.shippingName,
        address: this.form.shippingStreetNo,
        district: this.form.shippingDistrict,
        subProvince: this.form.shippingSubProvince,
        province: this.form.shippingProvince,
        postcode: this.form.shippingPostcode
      };

      var receiver = {
        nameEN: this.form.recieveEnName,
        address: this.form.recieveStreetNo,
        district: this.form.recieveDistrict,
        subProvince: this.form.recieveSubProvince,
        province: this.form.recieveProvince,
        postcode: this.form.recievePostcode,
        country: this.form.recieveCountry,
        countryCode: this.form.recieveCountryCode
      };

      this.companyShipperSearch.patchValue(shipper);
      this.companyReceiverSearch.patchValue(receiver);
    }
  }

  changeCompany(e) {
    const company = this.companyList.find((f) => f.companyId == e.value);
    this.form.recieveEnName = company.nameEN;
    this.form.recieveStreetNo = company.address;
    this.form.recieveProvince =
      company.province == "กรุงเทพฯ" ? "กรุงเทพฯ" : company.province;
    this.form.recieveSubProvince = company.subProvince;
    this.form.recievePostcode = company.postcode;
    this.form.recieveDistrict = company.district;
  }

  changeShippingCompany(e) {
    const company = this.companyList.find((f) => f.companyId == e.value);
    this.form.shippingStreetNo = company.address;
    this.form.shippingProvince =
      company.province == "กรุงเทพฯ" ? "กรุงเทพฯ" : company.province;
    this.form.shippingSubProvince = company.subProvince;
    this.form.shippingPostcode = company.postcode;
    this.form.shippingDistrict = company.district;
  }

  async setData() {
    const product = Number(this.form.product);
    if (this.form.product != undefined) {
      const productName = this.productList.find((f) => f.docTypeId == product);
      this.productName = productName?.productName;
    }
    const status = Number(this.form.status);
    if (this.form.status != undefined) {
      const getstatus = this.statusList.find((f) => f.statusId == status);
      this.form.statusName = getstatus?.statusName;
    }
  }

  async getShippingMask() {
    const dialogRef = this.dialog.open(ShippingmaskModalComponent, {
      disableClose: true,
      maxWidth: "none",
      width: "65vw",
      // data: {
      // },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.form.shippingMark = result.shippIdd;
    });
    // this.shippingMaskList = await this.appMasterService.getShippingMask(null);
  }

  async getDropdownSurveyor() {
    // const rqSurveyor = {
    //   surveyorID: "",
    //   productId: this.productId,
    //   isActive: "Y",
    // };
    // this.surveyorList = await this.appMasterService.getSurveyor(rqSurveyor);

    // const rqSurveyorDd = {
    //   surveyorID: "",
    //   isActive: "Y",
    // };
    // this.surveyorList = await this.appMasterService.DropdownSurveyor(
    //   rqSurveyorDd
    // );
    const dialogRef = this.dialog.open(SurveyorModalComponent, {
      disableClose: true,
      maxWidth: "none",
      width: "65vw",
      // data: {
      // },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.form.inspectBy = result.inspectBy.surveyorID;
      this.form.inspectByBy = result.inspectBy.companyNameTH;
      // this.form.inspectName = result.inspectBy.companyNameTH;
      // this.form.inspectStreetNo = result.inspectBy.streetNo;
      // this.form.inspectDistrict = result.inspectBy.district;
      // this.form.inspectSubprovince = result.inspectBy.subprovince;
      // this.form.inspectProvince = result.inspectBy.province;
      // this.form.inspectPostcode = result.inspectBy.postcode;
      // this.form.inspectOther = result.inspectBy.Other;
    });
  }

  async setPort(e) {
    const porttxt = this.portList.find((f) => f.id == e.value);
    this.form.porttxt = porttxt?.name;
    this.form.port = porttxt?.id;
  }

  async setTimeInspectDate1(e) {
    this.form.inspectDate1 = new TimeRanges();
  }

  backPage(): void {
    if (this.typePage == "ms13") {
      this.router.navigate(["page/ms13/ms13"], {
        // queryParams: { page: this.typePage },
      });
    } else if (this.typePage == "o2") {
      this.router.navigate(["page/o2/o2"], {
        // queryParams: { page: this.typePage },
      });
    } else if (this.typePage == "ms24") {
      this.router.navigate(["page/ms24/ms24"], {
        queryParams: { page: this.typePage },
      });
    }
  }

  reset(): void {
    this.form = {};
  }

  addProduct(type, id, data): void {
    if (
      (this.form.exchangeRate == null || this.form.exchangeRate == undefined) &&
      this.typePage != "ms13" && type != "info"
    ) {
      this.alertService
        .info({ title: "", message: "กรุณากรอกอัตราแลกเปลี่ยน" })
        .then((x) => {
          return;
        });
      return;
    }
    if (
      (this.form.currency == null || this.form.currency == undefined) &&
      this.typePage != "ms13" && type != "info"
    ) {
      this.alertService
        .info({ title: "", message: "กรุณากรอกสกุลเงิน" })
        .then((x) => {
          return;
        });
      return;
    }
    if (this.typePage == "ms13") {
      this.router.navigate(["/page/ms24/create-ms24"], {
        queryParams: {
          page: "ms24",
          refNo: this.form.refNo,
          id: this.formId,
          product: this.productId,
          docNo: this.form.o2DocNo,
          item: data,
        },
      });
    } else {
      const dialogRef = this.dialog.open(CreateProductGoodsItemModalComponent, {
        disableClose: true,
        maxWidth: "none",
        width: "65vw",
        data: {
          hId: this.form.id,
          status: this.form.status,
          type: type,
          id: id,
          exchangeRate: this.form.exchangeRate,
          page: this.typePage,
          item: data,
          currency: this.form.currency,
          docTypeId: this.form.docTypeId,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        this.appO2Service
          .getO2item({
            hId: this.form.id,
          })
          .then((res) => {
            if (res === 'error') {
              return;
            } else {
              this.dataSource = res.map(el => {
                return {
                  ...el,
                  itemStatusName: this.statusList.find(
                    (f) => f.statusId == el.itemStatus
                  ).statusName,
                }
              });
              if (this.productId == 3) {
                this.checkAddProduct = res.length >= 4 ? true : false;
              } else {
                this.checkAddProduct = res.length > 1 ? true : false;
              }
            }
          });
      });
    }
  }

  async save(status) {
    var checkResult = true;
    // if (this.form.shippingName === undefined || this.form.shippingName === null || this.form.shippingName == '') {
    //   this.alertService.open({
    //     title: "",
    //     message: "กรุณากรอกข้อมูลผู้กระทำการแทนผู้ส่งออก หากไม่ปรากฎข้อมูลกรุณาเพิ่มผู้กระทำการแทนผู้ส่งออกที่เมนู Shipping List"
    //   });
    //   return;
    // }

    if (this.form.docNo != this.o2docNo) {
      //มีการแก้ไขเลข อ.2 ต้องเช็คใหม่
      const reqq = {
        docNo: this.form.docNo,
      };

      await this.appO2Service.checkDupDocNo(reqq).then((res) => {
        if (res === 'error') {
          return;
        } else {
          if (res != 0) {
            this.alertService.open({
              message: "ใบอนุญาตกระทรวงพาณิชย์เลขที่ฯ " + this.form.docNo + " ได้เคยถูกบันทึกในระบบแล้ว ไม่สามารถบันทึกซ้ำได้",
              title: "",
            });
            checkResult = false;
          }
        }
      });
    }

    if (checkResult) {
      // if (this.form.recieveEnName === undefined || this.form.recieveEnName === null || this.form.recieveEnName == '') {
      //   this.alertService.open({
      //     title: "",
      //     message: "กรุณาเลือกผู้รับสินค้า หากไม่ปรากฎข้อมูลกรุณาเพิ่มผู้รับสินค้าที่เมนู Receiver List"
      //   });
      //   return;
      // }

      // -สถานะเปลี่ยนเป็น Save to Database
      const alertConfirm = await this.confirmService.open({
        header: "",
        content: "ท่านต้องการบันทึกหรือไม่",
      });

      if (alertConfirm) {
        if (this.typePage == "o2") {
          if (!this.selectedReceiverItem) {
            var userToken = JSON.parse(
              this._authService.loginUser
            )
       
            var companyId = Number(userToken.cpId);
    
            var formReceiverData = {
              nameEN: this.checkReceiverOptionValue(this.companyReceiverSearch.value),
              address: this.form.recieveStreetNo,
              district: this.form.recieveDistrict,
              subProvince: this.form.recieveSubProvince,
              province: this.form.recieveProvince,
              postcode: this.form.recievePostcode,
              country: this.form.recieveCountry,
              countryCode: this.form.recieveCountryCode,
              cpId: companyId
            };
    
            this.appMasterService.saveCompanyReceiver(formReceiverData).then(res => {
              if (res === 'error') {
                return;
              } else {
                return;
              }
            });
          }
    
    
          const formData = {
            source: this.source,
            docId: this.productId,
            status: status,
            companyTax: this.form.companyTaxId ?? this.form.companyTax,
            companyThName: this.form.companyThName,
            companyEnName: this.form.companyEnName,
            exporterStreetandNo: this.form.exporterStreetandNo,
            exporterDistrict: this.form.exporterDistrict,
            exporterSubProvince: this.form.exporterSubProvince,
            exporterProvince: this.form.exporterProvince,
            exporterPostcode: this.form.exporterPostcode,
            exporterNo: this.form.exporterNo,
            recieveThName: this.selectedReceiverItem ? this.form.recieveEnName : this.checkReceiverOptionValue(this.companyReceiverSearch.value),
            recieveEnName: this.selectedReceiverItem ? this.form.recieveEnName : this.checkReceiverOptionValue(this.companyReceiverSearch.value),
            recieveStreetNo: this.form.recieveStreetNo,
            recieveDistrict: this.form.recieveDistrict,
            recieveSubProvince: this.form.recieveSubProvince,
            recieveProvince: this.form.recieveProvince,
            recievePostcode: this.form.recievePostcode,
            recieveCountry: this.form.recieveCountryCode,
            shippingName: this.form.shippingName,
            shippingStreetNo: this.form.shippingStreetNo,
            shippingDistrict: this.form.shippingDistrict,
            shippingSubProvince: this.form.shippingSubProvince,
            shippingProvince: this.form.shippingProvince,
            shippingPostcode: this.form.shippingPostcode,
            docNo: this.form.docNo,
            expireDate: this.form.expireDate,
            buyerCountry: this.form.buyerCountryCode,
            destinationCountry: this.form.destinationCountryCode,
            transportationMode: this.form.transportationMode,
            vesselName: this.form.vesselName,
            port: this.form.port,
            porttxt: this.form.porttxt,
            currency: this.form.currency,
            exchangeRate: this.form.exchangeRate,
            issueDate: this.form.issueDate,
            typeDoc: this.form.productName ?? this.productName,
            // exporter: this.companyExporterSearch, // this.form.exporter ?? "exporter",
            sendBy: this.userProfile?.uId,
            send: this.form.send,
            botReceiveUser: this.form.botReceiveUser,
            botReceiveDate: this.form.botReceiveDate,
            botApproveUser: this.form.botApproveUser,
            botApproveDate: this.form.botApproveDate,
            dateCreated: this.form.dateCreated,
            exporterId: this.form.exporterCompanyId, // this.form.exporterId ?? 1,
            shippingId: this.form.shippingId ?? 1,
            ownerId: this.form.ownerId,
            attachO2: this.fileImg,
            o2Condition: this.form.o2Condition,
            items: this.dataSource[0],
            attachment: this.dataSource,
            trademarkList: [
              {
                trademark: this.fileImg,
              },
            ],
            id: this.form.id,
            typeId: 0,
          };
          formData.typeId = this.productList.find((f) => f.productId == this.productId)?.docTypeId;
          const { type } = this.route.snapshot.queryParams;
          if (type == "Edit") {
            formData.attachment = this.dataSource;
          }
          var xxx = formData;
          // return;
          this.appO2Service.saveO2(xxx).then((res) => {
            if (res != "error") {
              this.alertService
                .info({ title: "", message: "บันทึกข้อมูลเรียบร้อย" })
                .then((x) => {
                  this.router.navigate(["page/o2/o2"], {
                    // queryParams: { page: this.typePage },
                  });
                });
            }
          });
        } else {
          const formDataMS13 = {
            ownerId: this.form.ownerId,
            exporterId: this.export.companyId,
            shippingId: this.form.shippingId ?? 1,
            refNo: this.form.refNo,
            refDate: new Date(),
            status: this.form.status,
            // exporter: this.export.nameTH,
            exporter: this.export.companyId,
            companyTax: this.form.companyTaxId,
            companyThName: this.form.companyThName,
            companyEnName: this.form.companyEnName,
            exporterStreetandNo: this.form.exporterStreetandNo,
            exporterDistrict: this.form.exporterDistrict,
            exporterSubProvince: this.form.exporterSubProvince,
            exporterProvince: this.form.exporterProvince,
            exporterPostcode: this.form.exporterPostcode,
            recieveThName: this.form.recieveThName,
            recieveEnName: this.form.recieveEnName,
            recieveStreetNo: this.form.recieveStreetNo,
            recieveDistrict: this.form.recieveDistrict,
            recieveSubProvince: this.form.recieveSubProvince,
            recieveProvince: this.form.recieveProvince,
            recievePostcode: this.form.recievePostcode,
            recieveCountry: this.form.recieveCountry,
            country: this.form.country,
            shippingName: this.form.shippingName,
            shippingStreetNo: this.form.shippingStreetNo,
            shippingDistrict: this.form.shippingDistrict,
            shippingSubProvince: this.form.shippingSubProvince,
            shippingProvince: this.form.shippingProvince,
            shippingPostcode: this.form.shippingPostcode,
            o2Id: this.form.o2Id,
            o2DocNo: this.form.o2DocNo,
            o2ExpireDate: this.form.o2ExpireDate,
            buyerCountry: this.form.buyerCountryCode,
            destinationCountry: this.form.destinationCountry,
            transportationMode: this.form.transportationMode,
            vesselName: this.form.vesselName,
            port: this.form.port,
            porttxt: this.form.porttxt,
            currency: this.form.currency,
            exchangeRate: this.form.exchangeRate,
            o2IssueDate: new Date(),
            typeDocument: this.productId,
            registrationNoPk: this.export.pk,
            shippingMark: this.form.shippingMark,
            goodsName: this.form.goodsName,
            typeGoods: this.form.typeGoods,
            goodsCode: 0,
            proofpurchase: this.form.proofpurchase,
            loadePort: this.form.loadePortName,
            loadePortName: this.form.loadePortName,
            loadedPortCountry: this.form.loadedPortCountry,
            goodsLoadDate1: this.form.goodsLoadDate1,
            goodsLoadDate2: this.form.goodsLoadDate2,
            inspectDate1: this.form.inspectDate1,
            inspectDate2: this.form.inspectDate2,
            inspectbyId: 0,
            inspectBy: this.form.inspectBy,
            inspectName: this.form.inspectName,
            inspectStreetNo: this.form.inspectStreetNo,
            inspectDistrict: this.form.inspectDistrict,
            inspectSubprovince: this.form.inspectSubprovince,
            inspectProvince: this.form.inspectProvince,
            inspectPostcode: this.form.inspectPostcode,
            inspectOther: this.form.inspectOther,
            createbyusername: new Date(),
            petitionNo: 0,
            petitionDate: new Date(),
            botReviewUser: 0,
            botReceiveUser: 0,
            botApproveDate: new Date(),
            botReceiveDate: new Date(),
            botRemark: "-",
            checkResult: "-",
            checkQty: "-",
            checkPackageResult: "-",
            accualSurveyDate: new Date(),
            typeClass: "-",
            rejectReason: "-",
            surveyAccept: 0,
            surveyAcceptDate: new Date(),
            surveyCheif: "-",
            surveyBy2: "-",
            surveyBy3: "-",
            surveyOrder1: "-",
            surveyOrderDate1: new Date(),
            surveyOrder2: "-",
            surveyOrderDate2: new Date(),
            surveyAnalyze: "-",
            source: this.form.source,
            surveyorAccept: "-",
            surveyorAcceptDate: new Date(),
            shpMaskpic: "-",
            attachO2: this.form.attachO2,
            printCode: "-",
            printUser: 0,
            printDate: new Date(),
            rintCount: "-",
            lock: "-",
            printLock: "-",
            printCount: 0,
            printSecure: "-",
            comment1: this.form.comment1,
            comment2: "-",
            signpic1: "-",
            tradeMark: "-",
            signNameTh1: "-",
            position1: "-",
            signature: "-",
            surveyby4: "-",
            surveyby5: "-",
            surveyby6: "-",
            pt1Ms7No: "-",
            pt2Ms8No: "-",
            pt2Ms8NoSur2: "-",
            pt2Ms8NoSur3: "-",
            pt2Ms8NoSur4: "-",
            pt2Ms8NoSur5: "-",
            pt2Ms8NoSur6: "-",
            takeTansfered: "-",
            items: this.dataSource != null ? this.dataSource[0] : null,
            attachment: {
              fileName: this.formFile.fileName,
              documentName: this.formAttachment.fileName,
              linkDoc: 1,
              documentContent: "image",
              documentSize: this.formFile.fileByte,
              fileExtension: this.formFile.fileExtension,
              docByte: this.formFile.fileByte,
            },
            trademarkList: [
              {
                trademark: this.fileImg,
              },
            ],
          };
          const { type } = this.route.snapshot.queryParams;
          if (type == "Edit") {
            formDataMS13.attachment = this.form.attachment;
          }
          const save = this.appMs13Service.saveMs13(formDataMS13).then((res) => {
            if (res != "error") {
              this.alertService
                .info({ title: "", message: "บันทึกข้อมูลเรียบร้อย" })
                .then((x) => {
                  this.router.navigate(["page/ms13/ms13"], {
                    queryParams: { page: this.typePage },
                  });
                });
            }
          });
        }
      }
    }
  }

  checkReceiverOptionValue(e) {
    if (typeof e === 'object') {
      return e?.nameEN;
    } else {
      return e;
    }
  }

  async onFileChangedBr(event): Promise<void> {
    if (event.value.length === 0) {
      return;
    }
    event.value.forEach((item) => {
      this.file = item;
      this.onUploadBr();
    });
    this.value = [];
  }

  onUploadBr(): void {
    const formData = new FormData();
    formData.append("image", this.file, this.file.name);
    formData.append("blobType", "CSCAttachments");
    formData.append("typePage", this.typePage);
    formData.append("action", "new");
    formData.append("linkDoc", this.formId ?? 0);
    formData.append("documentName", this.form.docNo);
    this.appMasterService.uploadFile(formData).subscribe((result) => {
      if (result) {
        this.formFile = result[0];
        this.fileImg = result[0]?.filePath;
        this.form.pic = result[0]?.fileName + "." + result[0]?.fileType;
        this.formFile.appType = result[0]?.fileType;
        this.formFile.fileExtension = this.file.type;

        let linkDoc = result[0]?.linkDoc;

        const attachment = {
          fileName: this.formFile.fileName,
          documentName: this.form.docNo,
          linkDoc: this.form.id,
          documentContent: "image",
          documentSize: this.formFile.fileByte,
          fileExtension: this.formFile.fileExtension,
          docByte: this.formFile.fileByte,
          ID: linkDoc,
        };
        this.dataSourceAtt.push(attachment);
      }
    });
  }

  saveStock() { }

  // Function for Surveyor
  async saveSurveryor() {
    const data = this.SurveyorForm;
    const alertConfirm = await this.confirmService.open({
      header: "ท่านต้องการบันทึกหรือไม่",
      content: "",
    });
    if (alertConfirm) {
      const formData = {
        hId: "1",
        checkResult: data.result.check_result,
        checkQty: data.result.check_QTY,
        checkPackageResult: data.result.check_package_result,
        surveyRemark: data.result.survey_remark,
        confirm: "Y",
        surveyAccept: data.MS13H_surveyor.survey_accept,
        surveyAcceptDate: data.MS13H_surveyor.survey_accept_date,
        surveyCheif: data.MS13H_surveyor.survey_cheif,
        surveyBy2: data.MS13H_surveyor.survey_by2,
        surveyOrder1: data.MS13H_surveyor.survey_order1,
        surveyBy3: data.MS13H_surveyor.survey_by3,
        surveyOrderDate1: data.MS13H_surveyor.survey_order_date1,
        surveyAnalyze: data.MS13H_surveyor.survey_analyze,
        surveyOrder2: data.MS13H_surveyor.survey_order2,
        surveyOrderDate2: data.MS13H_surveyor.survey_order_date2,
        itemNo: "1",
        goodsDescription: data.result.goods_description,
        tariffCode: this.ms13DForSur.tariffCode,
        tariffSequence: this.ms13DForSur.tariffSeq,
        statisticalCode: this.ms13DForSur.statisCode,
        unitPrice: this.ms13DForSur.unitPrice,
        netWeight: this.ms13DForSur.netWeight,
        netWeightUnit: this.ms13DForSur.netWeightUnit,
        fobValueBaht: this.ms13DForSur.fobValueBaht,
        quantity: this.ms13DForSur.quantity,
        quantityUnit: this.ms13DForSur.quantityUnit,
        netWeightPerUnit: this.ms13DForSur.netWeightPerUnit,
        grossWeightPerUnit: this.ms13DForSur.grossWeightPerUnit,
        surveyGrossWeightPerUnit: data.result.survey_gross_weight_per_unit,
        surveyQty: data.result.survey_qty,
        surveyQtyUnit: data.result.survey_qty_unit,
        surveyNetWeight: data.result.survey_net_weight,
        surveyNetWeightPerUnit: data.result.survey_net_weight_per_unit,
        surveyNetWeightUnit: data.result.survey_net_weight_unit,
      };
      const save = this.appSuveryorService.saveDataMs13Sur(formData);
    }
  }

  resetSurveyor() {
    this.SurveyorForm.auditDatasource = {};
    this.SurveyorForm.result = {};
    this.SurveyorForm.MS13H_surveyor = {};
  }

  async deleteImg(e) {
    if (this.typePage == "o2") {
      const alertConfirm = await this.confirmService.open({
        header: "ต้องการลบเอกสารสำเนา อ.2 หรือไม่",
        content: "ยืนยันการลบ",
      });
      // ;
      if (alertConfirm) {

        let rqDelImg = {
          id: e.id
        };
        this.appO2Service.deleteO2Img(rqDelImg).then(res => {
          if (res === 'error') {
            return;
          } else {
            this.fileImg = null;
            this.formFile.fileByte = null;
            this.formFile.fileExtension = null;
            this.formFile.appType = null;
            this.formAttachment.fileName = null;
            this.dataSourceAtt = [];
          }
        })
      }
    } else {
      const alertConfirm = await this.confirmService.open({
        header: "ต้องการลบใบรูป อ.2 หรือไม่",
        content: "ยืนยันการลบ",
      });
      if (alertConfirm) {
        this.fileImg = null;
        this.formFile.fileByte = null;
        this.formFile.fileExtension = null;
        this.formFile.appType = null;
        this.formAttachment.fileName = null;
        this.dataSourceAtt = [];
      }
    }
  }

  showImg() {
    const dialogRef = this.dialog.open(ImageModalComponent, {
      disableClose: true,
      // maxWidth: "none",
      width: "45vw",
      height: "35vw",
      data: {
        path: this.fileImg,
        title: "สำเนาอ.2",
      },
    });
  }

  moreDetailMs13() {
    const {
      source,
      docNo,
      type,
      page,
      product,
      expireDate,
      issueDate,
      status,
      id,
      productId,
      refNo,
    } = this.route.snapshot.queryParams;
    const formDataMs13 = {
      ID: id,
      docNo: docNo,
      companyTax: this.userProfile.companyCode,
      refNo: refNo,
      productId: productId,
    };
    this.appMs13Service.exportMs13(formDataMs13).then((res) => {
      this.linkMs13Export = res.linkUrl;
      const dialogRef = this.dialog.open(ExportPdfModalComponent, {
        disableClose: true,
        maxWidth: "none",
        width: "65vw",
        data: {
          linkMs13Export: res.linkUrl,
        },
      });
      // window.open(res.linkUrl, "_blank");
    });
  }

  checkProduct() {
    return this.productId == 3 && this.dataSource.length > 1 ? true : false;
  }

  delProduct(id, hId, data) {
    this.appO2Service.delO2Item({ id: id }).then((res) => {
      this.appO2Service
        .getO2item({
          // ID: id,
          hId: hId,
        })
        .then((res) => {
          this.dataSource = res.map((x) => {
            return {
              ...x,
              balance: x.netWeight - x.weightUsed,
            };
          });
        });
    });
  }

  upload() {
    if (this.value.length > 0) {
      if (this.value.filter((x) => x.size > 3145728).length > 0) {
        this.alertService.open({
          message: "รูปมีขนาดใหญ่กว่า 3 MB",
          title: "โปรดตรวจสอบขนาดรูปภาพ",
        });
        return;
      }

      if (
        this.value.filter(
          (x) => x.type != "image/jpeg" && x.type != "image/png"
        ).length > 0
      ) {
        this.alertService.open({
          message:
            "รูปที่เลือกไม่ถูกต้อง กรุณาอัปโหลดเฉพาะไฟล์ .JPG หรือ .PNG เท่านั้น หรือตรวจสอบการตั้งค่าเครื่องสแกนเนอร์",
          title: "",
        });
        return;
      }
      this.value.forEach((file) => {
        const fileName = file.name;
        const formData = new FormData();
        formData.append("image", file, fileName);
      });
      this.value = [];
    } else {
      this.alertService.open({
        message: "กรุณาเลือกรูปภาพหรือเอกสารอย่างน้อยชนิด",
        title: "",
      });
    }
  }

  onUpload(file: any): void {
    if (file.size > 24000000) {
      this.alertService.open({
        message: "รูปมีขนาดใหญ่กว่า 3 MB",
        title: "โปรดตรวจสอบขนาดรูปภาพ",
      });
      return;
    }
    const formData = new FormData();
    formData.append("image", this.file, this.file.name);
    formData.append("blobType", "CSCAttachments");
    this.appMasterService.uploadFile(formData).subscribe((result) => {
      if (result) {
        let index = this.form.attachments.length + 1;
        const data = {
          attachPicId: 0,
          docHid: this.form.id,
          attachFilePic: result[0]?.filePath,
          attachType: 0,
          attachIndex: index,
          remark: "",
          attachHeaderType: 2,
          isActive: "Y",
        };
        // this.fileImg = result[0]?.filePath;
        // this.pic = result[0]?.fileName + result[0]?.fileType;

        this.form.attachments.push(data);
        this.dataSourceAttachPic.push(data);

        // this.formSourceUpload = [];
        // this.formSourceUpload = [...this.form.attachments];
        // this.dataSourceUpload = [];
        // this.dataSourceUpload = [...this.dataSourceAttachPic];
      }
    });
  }

  createMs13() {
    var expireDate = moment(this.form.expireDate).startOf('day');
    var currentDate = moment(this.currentDate).startOf('day');

    var itemsId = this.selectionProduct.map((m) => {
      return {
        id: m.id,
      };
    });
    console.log("expireDate", expireDate);
    console.log("currentDate", currentDate);
    console.log("expireDate >= currentDate ", expireDate >= currentDate);
    if (this.selectionProduct.length > 0 && expireDate >= currentDate) {
      const dialogRef = this.dialog.open(
        AmountOfWeightTouseMs13ModalComponent,
        {
          disableClose: true,
          maxWidth: "none",
          width: "65vw",
          data: {
            hId: this.form.id,
            status: this.form.status,
            product: this.productId,
            docNo: this.form.docNo,
            expireDate: this.form.expireDate,
            issueDate: this.form.issueDate,
            // type: type,
            // id: id,
            page: this.typePage,
            selectionProduct: this.selectionProduct,
            formData: this.form,
            itemId: JSON.stringify(itemsId),
          },
        }
      );
    } else if (this.selectionProduct.length == 0) {
      this.alertService
        .info({ title: "", message: "กรุณาเลือกรายการสินค้า" })
        .then((x) => {
          return;
        });
    } else if (expireDate < currentDate) {
      // 
      this.alertService
        .info({ title: "", message: "เอกสาร อ.2 หมดอายุ" })
        .then((x) => {
          return;
        });
    }
  }

  selectionChanged(e) {
    console.log(e.currentSelectedRowKeys);
    var allowMap = true;

    // Add new selections to selectedRows
    if (e.selectedRowKeys.length > 0) {
      if (e.selectedRowKeys.length <= this.maxSelection) {
        this.selectedRows = e.selectedRowKeys;
      } else {
        this.alertService.open({
          message: "ไม่สามารถเลือกรายการเพิ่มเติมได้ เนื่องจากคุณเลือกรายการสินค้าเกินกำหนด",
          title: ""
        }).then(res => {
          e.component.deselectRows(e.currentSelectedRowKeys);
          const currentSelect = _.first(e.currentSelectedRowKeys);
          this.selectedRows = this.selectedRows.filter(row => row != currentSelect);
        });
      }
      console.log("this.selectedRows>>>", this.selectedRows);
    }
    
    if (this.selectionProduct.length > 0) {
      var currentSelect = _.first(e.currentSelectedRowKeys) as any;
      const tariffItem = this.selectionProduct.filter(el => el.tariffCode == currentSelect.tariffCode);

      if (tariffItem.length === 0) {
        this.alertService.open({
          message: "ไม่สามารถเลือกสินค้านี้ไปสร้าง มส.13 ได้ กรุณาตรวจสอบพิกัดศุลกากรในรายการสินค้าที่เลือกไว้ก่อนหน้า",
          title: ""
        }).then(res => {
          e.component.deselectRows(e.currentSelectedRowKeys);

          debugger;
          var rowKey = e.selectedRowKeys.filter(row => row != currentSelect);
          e.selectedRowKeys = rowKey;
          this.selectedRows = this.selectedRows.filter(row => row != currentSelect);
          this.selectionProduct = this.selectionProduct.filter(row => row.tariffCode != currentSelect.tariffCode);
          allowMap = false;
          return;
        });
      }
    }

    // Update selectionProduct array
    if (allowMap) {
      this.selectionProduct = this.selectedRows.map((res) => {
        return {
          ...res,
          productName: this.form.typeOfDocument,
          weightUsedMs13: null,
          amountUsedMs13: null,
        };
      });
    }
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;
    const spitUrl = this.url.split('?')
    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: spitUrl[0],
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode,
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(
      formData
    );
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async saveFrist(status) {
    if (this.typePage == "o2") {
      const formData = {
        source: this.source,
        docId: this.productId,
        status: status,
        companyTax: this.form.companyTaxId ?? this.form.companyTax,
        companyThName: this.form.companyThName,
        companyEnName: this.form.companyEnName,
        exporterStreetandNo: this.form.exporterStreetandNo,
        exporterDistrict: this.form.exporterDistrict,
        exporterSubProvince: this.form.exporterSubProvince,
        exporterProvince: this.form.exporterProvince,
        exporterPostcode: this.form.exporterPostcode,
        exporterNo: this.form.exporterNo,
        recieveThName: this.form.recieveThName,
        recieveEnName: this.form.recieveEnName,
        recieveStreetNo: this.form.recieveStreetNo,
        recieveDistrict: this.form.recieveDistrict,
        recieveSubProvince: this.form.recieveSubProvince,
        recieveProvince: this.form.recieveProvince,
        recievePostcode: this.form.recievePostcode,
        recieveCountry: this.form.recieveCountryCode,
        shippingName: this.form.shippingName,
        shippingStreetNo: this.form.shippingStreetNo,
        shippingDistrict: this.form.shippingDistrict,
        shippingSubProvince: this.form.shippingSubProvince,
        shippingProvince: this.form.shippingProvince,
        shippingPostcode: this.form.shippingPostcode,
        docNo: this.form.docNo,
        expireDate: this.form.expireDate,
        buyerCountry: this.form.buyerCountryCode,
        destinationCountry: this.form.destinationCountryCode,
        transportationMode: this.form.transportationMode,
        vesselName: this.form.vesselName,
        port: this.form.port,
        porttxt: this.form.porttxt,
        currency: this.form.currency,
        exchangeRate: this.form.exchangeRate,
        issueDate: this.form.issueDate,
        typeDoc: "",
        // exporter: this.companyExporterSearch, // this.form.exporter ?? "exporter",
        sendBy: this.userProfile?.uId,
        send: this.form.send,
        botReceiveUser: this.form.botReceiveUser,
        botReceiveDate: this.form.botReceiveDate,
        botApproveUser: this.form.botApproveUser,
        botApproveDate: this.form.botApproveDate,
        dateCreated: this.form.dateCreated,
        exporterId: this.form.exporterCompanyId, // this.form.exporterId ?? 1,
        shippingId: this.form.shippingId ?? 1,
        ownerId: this.form.ownerId,
        attachO2: this.fileImg,
        o2Condition: this.form.o2Condition,
        items: this.dataSource[0],
        attachment: this.dataSourceAtt,
        trademarkList: [
          {
            trademark: this.fileImg,
          },
        ],
        id: this.form.id,
        typeId: 0
      };
      formData.typeId = this.productList.find((f) => f.productId == this.productId)?.docTypeId;
      formData.typeDoc = this.productList.find((f) => f.productId == this.productId)?.docTypeName;
      const { type } = this.route.snapshot.queryParams;
      if (type == "Edit") {
        formData.attachment = this.form.attachment;
        formData.attachment.linkDoc = this.form.attachment.id;
        formData.buyerCountry =
          this.form.buyerCountryCode ?? this.form.buyerCountry;
        formData.recieveCountry =
          this.form.recieveCountryCode ?? this.form.recieveCountry;
        formData.destinationCountry =
          this.form.destinationCountryCode ?? this.form.destinationCountry;
        formData.companyTax = this.form.companyTaxId;
      }
      var xxx = formData;
      // return;
      this.appO2Service.saveO2(xxx).then((res) => {
        if (res != "error") {
          this.form.id = res.cmO2H_ID;
          this.formId = res.cmO2H_ID;
          const formData = {
            ID: this.form.id,
            DocNo: this.form.docNo,
            CompanyName: this.userProfile.companyCode,
            ProductID: this.productId,
          };
          this.getData(formData);
        }
      });
    } else {
      const formDataMS13 = {
        ownerId: this.form.ownerId,
        exporterId: this.export.companyId,
        shippingId: this.form.shippingId ?? 1,
        refNo: this.form.refNo,
        refDate: new Date(),
        status: this.form.status,
        // exporter: this.export.nameTH,
        exporter: this.export.companyId,
        companyTax: this.form.companyTax,
        companyThName: this.form.companyThName,
        companyEnName: this.form.companyEnName,
        exporterStreetandNo: this.form.exporterStreetandNo,
        exporterDistrict: this.form.exporterDistrict,
        exporterSubProvince: this.form.exporterSubProvince,
        exporterProvince: this.form.exporterProvince,
        exporterPostcode: this.form.exporterPostcode,
        recieveThName: this.form.recieveThName,
        recieveEnName: this.form.recieveEnName,
        recieveStreetNo: this.form.recieveStreetNo,
        recieveDistrict: this.form.recieveDistrict,
        recieveSubProvince: this.form.recieveSubProvince,
        recieveProvince: this.form.recieveProvince,
        recievePostcode: this.form.recievePostcode,
        recieveCountry: this.form.recieveCountry,
        country: this.form.country,
        shippingName: this.form.shippingName,
        shippingStreetNo: this.form.shippingStreetNo,
        shippingDistrict: this.form.shippingDistrict,
        shippingSubProvince: this.form.shippingSubProvince,
        shippingProvince: this.form.shippingProvince,
        shippingPostcode: this.form.shippingPostcode,
        o2Id: this.form.o2Id,
        o2DocNo: this.form.o2DocNo,
        o2ExpireDate: this.form.o2ExpireDate,
        buyerCountry: this.form.buyerCountry,
        destinationCountry: this.form.destinationCountry,
        transportationMode: this.form.transportationMode,
        vesselName: this.form.vesselName,
        port: this.form.port,
        porttxt: this.form.porttxt,
        currency: this.form.currency,
        exchangeRate: this.form.exchangeRate,
        o2IssueDate: new Date(),
        typeDocument: this.productId,
        registrationNoPk: this.export.pk,
        shippingMark: this.form.shippingMark,
        goodsName: this.form.goodsName,
        typeGoods: this.form.typeGoods,
        goodsCode: 0,
        proofpurchase: this.form.proofpurchase,
        loadePort: this.form.loadePortName,
        loadePortName: this.form.loadePortName,
        loadedPortCountry: this.form.loadedPortCountryCode,
        goodsLoadDate1: this.form.goodsLoadDate1,
        goodsLoadDate2: this.form.goodsLoadDate2,
        inspectDate1: this.form.inspectDate1,
        inspectDate2: this.form.inspectDate2,
        inspectbyId: 0,
        inspectBy: this.form.inspectBy,
        inspectName: this.form.inspectName,
        inspectStreetNo: this.form.inspectStreetNo,
        inspectDistrict: this.form.inspectDistrict,
        inspectSubprovince: this.form.inspectSubprovince,
        inspectProvince: this.form.inspectProvince,
        inspectPostcode: this.form.inspectPostcode,
        inspectOther: this.form.inspectOther,
        createbyusername: new Date(),
        petitionNo: 0,
        petitionDate: new Date(),
        botReviewUser: 0,
        botReceiveUser: 0,
        botApproveDate: new Date(),
        botReceiveDate: new Date(),
        botRemark: "-",
        checkResult: "-",
        checkQty: "-",
        checkPackageResult: "-",
        accualSurveyDate: new Date(),
        typeClass: "-",
        rejectReason: "-",
        surveyAccept: 0,
        surveyAcceptDate: new Date(),
        surveyCheif: "-",
        surveyBy2: "-",
        surveyBy3: "-",
        surveyOrder1: "-",
        surveyOrderDate1: new Date(),
        surveyOrder2: "-",
        surveyOrderDate2: new Date(),
        surveyAnalyze: "-",
        source: this.form.source,
        surveyorAccept: "-",
        surveyorAcceptDate: new Date(),
        shpMaskpic: "-",
        attachO2: this.form.attachO2,
        printCode: "-",
        printUser: 0,
        printDate: new Date(),
        rintCount: "-",
        lock: "-",
        printLock: "-",
        printCount: 0,
        printSecure: "-",
        comment1: this.form.comment1,
        comment2: "-",
        signpic1: "-",
        tradeMark: "-",
        signNameTh1: "-",
        position1: "-",
        signature: "-",
        surveyby4: "-",
        surveyby5: "-",
        surveyby6: "-",
        pt1Ms7No: "-",
        pt2Ms8No: "-",
        pt2Ms8NoSur2: "-",
        pt2Ms8NoSur3: "-",
        pt2Ms8NoSur4: "-",
        pt2Ms8NoSur5: "-",
        pt2Ms8NoSur6: "-",
        takeTansfered: "-",
        items: this.dataSource != null ? this.dataSource[0] : null,
        attachment: [],
        trademarkList: [
          {
            trademark: this.fileImg,
          },
        ],
      };
      const { type } = this.route.snapshot.queryParams;
      if (type == "Edit") {
        formDataMS13.attachment = this.form.attachment;
      }
      const save = this.appMs13Service.saveMs13(formDataMS13).then((res) => {
        if (res != "error") {
          this.alertService
            .info({ title: "", message: "บันทึกข้อมูลเรียบร้อย" })
            .then((x) => {
              this.router.navigate(["page/ms13/ms13"], {
                queryParams: { page: this.typePage },
              });
            });
        }
      });
    }
  }

  updateStatusApprove() {
    var status = this.statusList.find(e => e.statusId == this.formStatus.status);
    var content = "คุณต้องการบันทึกสถานะเอกสาร อ.2 ใหม่เป็น \"" + status.statusName + "\" หรือไม่?";

    if (this.formStatus.status == 14) {
      content = "คุณต้องการบันทึกสถานะเอกสาร อ.2 ใหม่เป็น \"" + status.statusName + "\" ด้วยเหตุผล \"" + this.formStatus.rejectReason + "\" หรือไม่?";
    }

    this.confirmService.open({
      header: "",
      content: content
    }).then(res => {
      if (res) {
        var rqUpdateMs24Status = {
          hId: this.form.id,
          status: this.formStatus.status,
          rejectReason: "",
        };

        if (this.formStatus.status === 14) {
          rqUpdateMs24Status.rejectReason = this.formStatus.rejectReason;
        }

        this.appO2Service.updateO2ApproveStatus(rqUpdateMs24Status).then(res => {
          if (res === 'error') {
            return;
          } else {
            this.alertService.open({
              message: "บันทึกสถานะใหม่เรียบร้อยแล้ว",
              title: ""
            }).then(res => {
              this.router.navigate(["page/o2/o2"], { });
            });
          }
        });
        console.log(rqUpdateMs24Status);
      }
    });
  }

  checkO2Status() {
    if (this.userProfile.gId != 'UG001' && this.userProfile.gId != 'UG004') {
      return false;
    } else {
      return true;
    }
  }

  checkStatus(e) {
    if (e.value == 14) {
      this.dialog.open(O2RejectReasonModalComponent, {
        width: '50vw',
        disableClose: true,
      }).afterClosed().subscribe(res => {
        this.formStatus.rejectReason = res;
      });
    } else {
      this.formStatus.rejectReason = null;
    }
  }

  selectExporterSubdistrict() {
    if (this.disabled) {
      return;
    }

    this.dialog.open(SubDistrictSelectionDialogComponent, {
      disableClose: false,
      width: '50vw'
    }).afterClosed().subscribe(res => {
      if (res != undefined && res != null) {
        this.form.exporterDistrict = res.subdistrictName;
        this.form.exporterSubProvince = res.districtName;
        this.form.exporterProvince = res.provinceName;
        this.form.exporterPostcode = res.postalCode;    
      }
    });
  }

  selectShippingSubdistrict() {
    if (this.disabled) {
      return;
    }

    this.dialog.open(SubDistrictSelectionDialogComponent, {
      disableClose: false,
      width: '50vw'
    }).afterClosed().subscribe(res => {
      if (res != undefined && res != null) {
        this.form.shippingDistrict = res.subdistrictName;
        this.form.shippingSubProvince = res.districtName;
        this.form.shippingProvince = res.provinceName;
        this.form.shippingPostcode = res.postalCode;    
      }
    });
  }
}
