import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-pull-job-modal",
  templateUrl: "./pull-job-modal.component.html",
  styleUrls: ["./pull-job-modal.component.css"],
})
export class PullJobModalComponent implements OnInit {
  form: any = {};
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<PullJobModalComponent>
  ) {}

  ngOnInit() {}
}
