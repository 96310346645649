import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { environment } from "environments/environment";

@Component({
  selector: "app-export-pdf-modal",
  templateUrl: "./export-pdf-modal.component.html",
  styleUrls: ["./export-pdf-modal.component.css"],
})
export class ExportPdfModalComponent implements OnInit {
  form: any = {};
  urlFormmm: any = "../../../../../assets/file/hops_contest_all.pdf";
  constructor(
    private appMasterService: AppMasterService,
    public dialogRef: MatDialogRef<ExportPdfModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    this.form.linkMs13Export = this.data.linkMs13Export;
    console.log(this.data.linkMs13Export);
  }
}
