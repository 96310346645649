<div style="display: flex; flex-flow: column; height: 100%; gap: 30px" class="flex flex-col h-full">
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex; flex-flow: row; gap: 10px; align-items: stretch">
        <button class="set-btn-unlimited" mat-raised-button color="accent" (click)="openDialog('add',{})">
          Add Receiver
        </button>
      </div>
      <div style="
          display: flex;
          flex-flow: row;
          gap: 10px;
          flex: 1;
          justify-content: flex-end;
        ">
        <div class="mat-button" style="gap: 10px; flex: 0 40%">
          <mat-form-field style="display: flex; flex-flow: row; flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="คำค้นหา"
              (keyup)="applyFilter($event.target.value)" />
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" mat-button [matMenuTriggerFor]="menu">
          <div style="display: flex; gap: 10px; align-items: center; text-align: center;">
            <span>เงื่อนไขการค้นหา</span>
          </div>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)" (keydown.tab)="stopPropagation($event)">
            <form (ngSubmit)="searchData()">
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>Receiver Name</mat-label>
                    <input autocomplete="off" type="text" matInput [(ngModel)]="search.receiver" name="receiver" />
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>Province</mat-label>
                    <input autocomplete="off" type="text" matInput [(ngModel)]="search.province" name="province" />
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>Postcode</mat-label>
                    <input autocomplete="off" type="text" matInput [(ngModel)]="search.postcode" name="postcode" />
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>Country</mat-label>
                    <input autocomplete="off" type="text" matInput [(ngModel)]="search.country" name="country" />
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div>
                  <button mat-raised-button color="accent" type="submit" style="flex: 0 50%">
                    ค้นหา
                  </button>
                </div>
              </div>
            </form>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort (matSortChange)="SortChange($event)" [dataSource]="dataSource">
      <ng-container matColumnDef="receiver" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
          Receiver Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.receiver }}
        </td>
      </ng-container>

      <ng-container matColumnDef="addressAndStreet">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Address and Street
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.addressAndStreet }}
        </td>
      </ng-container>

      <ng-container matColumnDef="province">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          Province
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.province }}
        </td>
      </ng-container>

      <ng-container matColumnDef="postcode">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
          Postcode
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.postcode }}
        </td>
      </ng-container>

      <ng-container matColumnDef="country">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
          Country
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.country }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">
          Action
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <button mat-icon-button matTooltip="แก้ไข" matTooltipPosition="above" (click)="openDialog('update',element)">
            <mat-icon>description</mat-icon>
          </button>
          <button mat-icon-button matTooltip="ลบ" (click)="openDialog('delete',element)" matTooltipPosition="above">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" style="text-align:center;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>