<div class="box-subheader">
  รายละเอียดที่อยู่บริษัท | OFFICE ADDRESS
</div>
<div class="mat-elevation-z8" style="width: 100%">
  <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
    <!-- Position Column -->
    <ng-container matColumnDef="branch">
      <th mat-header-cell *matHeaderCellDef class="text-left"> สาขา </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.branch}} </td>
    </ng-container>

    <!-- bankAccountNo Column -->
    <ng-container matColumnDef="tcaAddressStreet">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ที่อยู่ </th>
      <td mat-cell *matCellDef="let element"> {{element.tcaAddressStreet}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="subDistrictName">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ตำบล / แขวง
      </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.subDistrictName}} </td>
    </ng-container>

    <ng-container matColumnDef="districtName">
      <th mat-header-cell *matHeaderCellDef class="text-left"> อำเภอ / เขต </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.districtName}} </td>
    </ng-container>

    <ng-container matColumnDef="province">
      <th mat-header-cell *matHeaderCellDef class="text-left"> จังหวัด </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.province}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="postCode">
      <th mat-header-cell *matHeaderCellDef class="text-left"> รหัสไปรษณีย์ </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.postCode}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef class="text-left"> โทรศัพท์ </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="fax">
      <th mat-header-cell *matHeaderCellDef class="text-left"> แฟกซ์ </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.fax}} </td>
    </ng-container>

    <ng-container matColumnDef="tcaTypeComAddName">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ประเภทที่อยู่ </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.tcaTypeComAddName}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
    aria-label="Select page of periodic elements">
  </mat-paginator>
</div>
