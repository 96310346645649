import { LiveAnnouncer } from '@angular/cdk/a11y';
import { OnInit, Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { IReceiverListResponse } from 'app/business/interface/app/app-receiver.interface';
import { ReceiverCreateComponent } from '../receiver-create/receiver-create.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { AppReceiverService } from 'app/business/service/app/app-receiver.service';
import { IApiGetReceiverRequest } from 'app/business/interface/api/api-receiver.interface';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { country } from 'app/mock-api/apps/country/data';

@Component( {
    selector: "app-receiver-inquiry",
    templateUrl: './receiver-inquiry.component.html',
    styleUrls: ['./receiver-inquiry.component.css'],
})

export class ReceiverInquiryComponent implements OnInit , AfterViewInit{

    //column Display Header
    displayedColumns: string[] = [
        'receiver',
        'addressAndStreet',
        'province',
        'postcode',
        'country',
        'action'
    ];
    search: any = {};
    dataSource= new MatTableDataSource <IReceiverListResponse>();
    receiver: any = {};
    CompaynyIdToken : number;
    country_code = country;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    
    constructor(
        private _liveAnnouncer : LiveAnnouncer,
        private dialog: MatDialog,
        private appReceiverService: AppReceiverService,
        private alertService: AlertMessageService,
    ){
      const jwt = localStorage.getItem('userProfile');
      const user = jwt? JSON.parse(jwt) : {};
      this.CompaynyIdToken = user.cpId;
    }

    ngOnInit(): void {
        this.dataSource.paginator = this.paginator; 
        this.dataSource.sort = this.sort;
        this.loadData();
        
    }
    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    
      SortChange(sortState: Sort) {
        if (sortState.direction) {
          this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
          this._liveAnnouncer.announce('Sorting cleared');
        }
      }

    async loadData(){
      const data = {
        /*UID:1,
        ExporterID: 1*/
        ExporterID :  this.CompaynyIdToken
      }
      await this.appReceiverService.GetReceiverList(
        data).then((res)=>{
          this.dataSource.data = res
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      }

    async searchData(){
      await this.loadData();
      const filterObj = {
        receiver : (this.search.receiver?this.search.receiver:'').toLowerCase(),
        province : (this.search.province?this.search.province:'').toLowerCase(),
        postcode : (this.search.postcode?this.search.postcode:'').toLowerCase(),
        country : (this.search.country?this.search.country:'').toLowerCase()
      };
      var defaulteFilterPredict = this.dataSource.filterPredicate;
      this.setFilterPredicate();
      this.dataSource.filter = JSON.stringify(filterObj);
      this.dataSource.filterPredicate = defaulteFilterPredict;
      this.search = {};
      this.trigger.closeMenu();
    }

    stopPropagation(event){
        event.stopPropagation();
    }

    setFilterPredicate(){
      this.dataSource.filterPredicate = (data: any, filterObjectString: string) => {
        const filterObj = JSON.parse(filterObjectString);
        return (
          (data.province?data.province:'').toLowerCase().includes(filterObj.province) &&
          (data.receiver?data.receiver:'').toLowerCase().includes(filterObj.receiver) &&
          (data.postcode?data.postcode:'').toLowerCase().includes(filterObj.postcode) &&
          (data.country?data.country:'').toLowerCase().includes(filterObj.country)
        )
      }
    }

    async openDialog(action,detail){
      if(action === 'delete' || action === 'update'){
        const data = {
          ID: detail.id,
          ExporterID: this.CompaynyIdToken
        }
        await this.appReceiverService.GetDataReceiver(
          data as unknown as IApiGetReceiverRequest).then((res)=>{
            detail = res
          });
          // Mockup Country
          detail.country_name = country.filter(country => country.code === detail.country).map(country => country.name);
      }
      detail.action = action;
      const width = action === 'delete' ? '250px' : '900px';
      const dialogRef = this.dialog.open(ReceiverCreateComponent, {
          panelClass: 'custom-dialog-container',
          width: width, 
          data: detail
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result.event == 'add' || result.event == 'update' && result.event !== 'Cancel'){
          const add = {
            id: result.data.id,
            exporterId: this.CompaynyIdToken,
            // exporterId: localStorage.getItem('exporterID'),
            name: result.data.name,
            addr: result.data.addr,
            district: result.data.district,
            subProvince: result.data.subProvince,
            province: result.data.province,
            postcode: result.data.postcode,
            country : result.data.country[0],
            // country: 'TH',
            action: result.data.action
          }
          this.appReceiverService.ReceiverSave(add).then((res) =>{
              if (res !== 'error') {
                this.alertService.info({ title: '', message: 'บันทึกข้อมูลเรียบร้อย' }).then(x =>{
                  this.dialog.closeAll();
                  this.loadData();
                }); 
              }
            });
          }
        if(result || result.events !== 'Cancel'){
          this.loadData();
        }
      });
    }
}