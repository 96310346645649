<div class="menu-top">
  <h2 style="cursor:pointer;text-align: center;">Itemtype</h2>
  <i class="ti ti-x text-white f17e" mat-dialog-close style="cursor: pointer;"></i>
</div>
<form  name ="FormDoc" [formGroup]="FormDoc"> 
    <div class="flex flex-col">

      <div class="pt-10 basis-1/4" style="display:flex; flex-flow:row; width: 100%;">
         
        <div style="display:flex; flex-flow:column; width: 30%; align-items: star;"> 
          <mat-label class="pl-8" style="display:flex; align-items: start;">ประเภทของสินค้า</mat-label>
        </div>  
      
        <div class="basis-3/4" style="display:flex; flex-flow:row; width: 100%;">
          <mat-form-field class="pl-4" style="height:auto; width: 70%;">
                    <mat-select [(ngModel)]="form.typeproduct" formControlName ="productControl">
                      <mat-option *ngFor="let product of dataProduct" [value] = "product.productId">
                      {{product.productName}}
                      </mat-option>
                    </mat-select>
          </mat-form-field>
        </div>

      </div>
      <div class="pt-4 basis-1/4" style="display:flex; flex-flow:row; width: 100%;">
              
        <div style="display:flex; flex-flow:column; width: 30%; align-items: star;"> 
          <mat-label class="pl-8" style="display:flex; align-items: start;">Code</mat-label>
        </div>  
      
        <div class="basis-3/4" style="display:flex; flex-flow:row; width: 100%;">
          <mat-form-field class="pl-4" style="height:auto; width: 70%;">
            <input autocomplete="off" type="text" maxlength="4" matInput  #input pattern="^[0-9]*(\.[0-9]{0,1})?$" (keyup)="decimalFixed($event.target.value)"
            [(ngModel)]="form.code" formControlName ="codeControl" name="code" > 
          </mat-form-field>
        </div>
      </div>
      <div class="pt-4 basis-1/4" style="display:flex; flex-flow:row; width: 100%;">

        <div style="display:flex; flex-flow:column; width: 30%; align-items: star;"> 
          <mat-label class="pl-8" style="display:flex; align-items: start;">ชนิด / ชั้นของสินค้า</mat-label>
        </div>  
      
        <div class="basis-3/4" style="display:flex; flex-flow:row; width: 100%;">
          <mat-form-field class="pl-4" style="height:auto; width: 70%;">
            <input autocomplete="off" type="text" matInput  #input
            [(ngModel)]="form.category" name="category" formControlName ="categoryControl"> 
          </mat-form-field>
        </div>
      </div>
    </div>
   </form>
    <div class="pt-4 flex flex-col items-center p10">
      <div class="basis-1/2 f18">
        <button mat-raised-button style="width: 100px;" color="accent" (click)="saveData()" 
        [disabled]="FormDoc.pristine || FormDoc.invalid"
        mat-button>
          Save
        </button>
        &nbsp;&nbsp;
        <button mat-raised-button style="width: 100px;" color="accent" (click)="onReset()" mat-button>
          Reset
        </button>
      </div>

    </div>
  

