<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center"> การเชื่อมต่อระบบ MR </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">
      <div class="flex-row flex justify-center" style="gap: 10px;">
        <div class="mat-popup-header flex flex-col">
          <h4>ควบคุมการเชื่อมต่อข้อมูลของระบบกับ ระบบ MR</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="gap: 10px;width:20%">
          <div>:</div>
          <mat-slide-toggle [checked]="data.cpParamiter === 'ON' ? true : false" (change)="onChange($event)"
            style="flex: 0 95%;" class="flex">{{status}}</mat-slide-toggle>
        </div>
      </div>
      <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
        <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
        <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
      </div>
    </div>
  </mat-dialog-content>
