<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">ตำบล / อำเภอ / จังหวัด / รหัสไปรษณีย์
      </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="flex flex-col" style="gap: 15px;">
    <div class="flex flex-row w100" style="gap: 15px;">
      <div class="flex flex-col" style="width: 50%">
        <div class="flex-1">
          <div class="flex flex-row p10">
            <div class="basis-1/2" style="align-self: center"> จังหวัด :
            </div>
            <div class="basis-4/5">
              <mat-form-field class="w100">
                <mat-select [(ngModel)]="form.provinceId" required
                  (selectionChange)="provinceChange($event)" name="province"
                  #goodsCode="ngModel"
                  ngmodel>
                  <mat-option *ngFor="let province of provinceList"
                    [value]="province.id">
                    {{province.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
  
      <div class="flex flex-col" style="width: 50%">
        <div class="flex-1">
          <div class="flex flex-row p10">
            <div class="basis-1/2" style="align-self: center;"> เขต / อำเภอ :
            </div>
            <div class="basis-4/5">
              <mat-form-field class="w100">
                <mat-select [(ngModel)]="form.districtId" required
                  (selectionChange)="districtChange($event)" name="district"
                  #goodsCode="ngModel"
                  ngmodel>
                  <mat-option *ngFor="let distirct of districtList"
                    [value]="distirct.districtId">
                    {{distirct.districtName}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>  
    </div>

    <div class="flex flex-row w100" style="gap: 15px;">
      <div class="mat-elevation-z8 w100">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
          <ng-container matColumnDef="subdistrictName">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">อำเภอ » ตำบล
            </th>
            <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.districtName}} » {{element.subdistrictName}} </td>
          </ng-container>

          <ng-container matColumnDef="postalCode">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">รหัสไปรณีย์
            </th>
            <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.postalCode}} </td>
          </ng-container>

          <ng-container matColumnDef="action" style="text-align: left;">
            <th mat-header-cell *matHeaderCellDef style="text-align: left;"> เลือก </th>
            <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;">
              <div class="flex">
                <div>
                  <button mat-icon-button matTooltip="เลือกรายการนี้" matTooltipPosition="above"
                    (click)="selectItem(element)" style="height: 40px !important;">
                    <i class="ti ti-square-check"></i>
                  </button>
                </div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>