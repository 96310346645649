import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppGroupListService } from "app/business/service/app/app-group.service";

@Component({
    selector: 'app-group-config-management',
    templateUrl: './group-config-management.component.html',
    styleUrls: ['./group-config-management.component.css']
})
export class GroupConfigManagementComponent implements OnInit {

    form : any = {};
    dataCompany : any[] = [];
    idCompany: number;
    groupId: any;
    constructor(
        private route: ActivatedRoute,
        private AppGroupListService: AppGroupListService,
        private alertService: AlertMessageService,
        private dialog: MatDialog,
        private router: Router
    ){
        // this.idCompany = LocalStorage.getItem('cpId');
        this.idCompany= 2;
    }

    ngOnInit(): void {
        this.form.GroupCompanyId = this.idCompany;
        const ID = this.route.snapshot.queryParams;
        console.log(ID);
        if(!ID.ID){
            this.groupId = 0;
        }
        else{
            this.groupId = ID
            this.loadData(this.groupId)
        }
        this.loadDataCompany();
    }

    async loadDataCompany(): Promise<void> {
        //send data to api
        const formData = {
          CompanyId: this.idCompany,
        };
         await this.AppGroupListService.CompanyDropdown(formData).then((res)=>{ 
          this.dataCompany = res
        });
    }

    async loadData(e){
        const groupId = {
            groupId:e.ID 
        }
        await this.AppGroupListService.GetDataConfGroup(groupId).then((res)=>{
            if(res){
                this.form.GroupName = res.groupName;
                this.form.RadioBox = res.adminGroup;
                this.doc.completed = res.doc;
                this.doc.subdoc.find((x => x.id === 2)).completed = res.o2;
                this.doc.subdoc.find((x => x.id === 3)).completed = res.ms13;
                this.doc.subdoc.find((x => x.id === 4)).completed = res.ms24;
                this.doc.subdoc.find((x => x.id === 5)).completed = res.docReNew;
                this.doc.subdoc.find((x => x.id === 6)).completed = res.sendShrt;
                this.his.completed = res.history;
                this.his.subhis.find((x => x.id === 8)).completed = res.historyMs13;
                this.his.subhis.find((x => x.id === 9)).completed = res.historyMs24;
                this.report.completed = res.report;
                this.report.subreport.find((x => x.id === 11)).completed = res.dailyReport;
                this.report.subreport.find((x => x.id === 12)).completed = res.wvReport;
                this.report.subreport.find((x => x.id === 13)).completed = res.ms13Report;
                this.report.subreport.find((x => x.id === 14)).completed = res.ms24Report;
                this.report.subreport.find((x => x.id === 15)).completed = res.otherReport;
                this.report.subreport.find((x => x.id === 16)).completed = res.paymentReport;
                this.report.subreport.find((x => x.id === 17)).completed = res.internalReport;
                this.report.subreport.find((x => x.id === 18)).completed = res.dashboard;
                this.report.subreport.find((x => x.id === 19)).completed = res.adminReport;
                this.reqPay.completed = res.requestPayment;
                this.DFT.completed = res.dftData;
                this.DFT.subDFT.find(x=> x.id === 22).completed = res.surData;
                this.DFT.subDFT.find(x=> x.id === 23).completed = res.exporterData;
                this.tracking.completed = res.tracking;
                this.tracking.subtracking.find(x=> x.id === 25).completed = res.nswTracking;
                this.tracking.subtracking.find(x=> x.id === 26).completed = res.dftTracking;
                this.config.completed = res.config;
                this.config.subconfig.find(x=> x.id === 28).completed = res.userMgt;
                this.config.subconfig.find(x=> x.id === 29).completed = res.compM;
                this.config.subconfig.find(x=> x.id === 30).completed = res.surveyor;
                this.config.subconfig.find(x=> x.id === 31).completed = res.type;
                this.config.subconfig.find(x=> x.id ===32).completed = res.groupConfig;
                this.config.subconfig.find(x=> x.id ===33).completed = res.noti;
                this.config.subconfig.find(x=> x.id === 34).completed = res.dftCon;
            }
        });

    }


    reqPay: any = {
        id:20,
        name: 'Request payment',
        completed: false
    };
    // Functions ตรวจเอกสาร
    doc: any = {
        id: 1,
        name: 'ตรวจเอกสาร',
        completed: false,
        subdoc: [
            { id:2, name: 'อ.2', completed: false },
            { id:3, name: 'มส.13', completed: false },
            { id:4, name: 'มส.24', completed: false },
            { id:5, name: 'การต่ออายุเอกสาร', completed: false },
            { id:6, name: 'ยื่นขอทำ Short', completed: false },
        ],
    };

    clickDoc(){
        this.doc.completed = this.doc.subdoc.filter(t => t.completed).length >0 ;
    }

    setDocAll(completed: boolean) {
        this.doc.completed = completed;
        if (this.doc.subdoc == null) {
            return;
        }
        this.doc.subdoc.forEach(t => (t.completed = completed));
    }

    // Functions ประวัติและแก้ไข
    his: any = {
        id:7,
        name: 'ประวัติและการแก้ไข',
        completed: false,
        subhis: [
            { id:8, name: 'มส.13', completed: false },
            { id:9, name: 'มส.24', completed: false },
        ],
    };
    
    clickHis(){
        this.his.completed = this.his.subhis.filter(t => t.completed).length >0 ;
    }

    setHisAll(completed: boolean) {
        this.his.completed = completed;
        if (this.his.subhis == null) {
            return;
        }
        this.his.subhis.forEach(t => (t.completed = completed));
    }

    // Functions รายงาน
    report: any = {
        id: 10,
        name: 'รายงาน',
        completed: false,
        subreport: [
            { id:11, name: 'รายงานประจำวัน', completed: false },
            { id:12, name: 'ปริมาณและมูลค่าที่ส่งออก', completed: false },
            { id:13, name: 'รายงาน มส.13', completed: false },
            { id:14, name: 'รายงาน มส.24', completed: false },
            { id:15, name: 'รายงานอื่นๆ', completed: false },
            { id:16, name: 'รายงานการชำระเงิน', completed: false },
            { id:17, name: 'Internal Report', completed: false },
            { id:18, name: 'Dashboard', completed: false },
            { id:19, name: 'รายงานขั้นสูง', completed: false }
        ],
    };
    
    clickReport(){
        this.report.completed = this.report.subreport.filter(t => t.completed).length >0 ;
    }

    setReportAll(completed: boolean) {
        this.report.completed = completed;
        if (this.report.subreport == null) {
            return;
        }
        this.report.subreport.forEach(t => (t.completed = completed));
    }

    // Functions DFT
    DFT: any = {
        id:21,
        name: 'DFT Data',
        completed: false,
        subDFT: [
            { id:22, name: 'Surveyor Data', completed: false },
            { id:23, name: 'Exporter Data', completed: false },
        ],
    };

    clickDFT(){
        this.DFT.completed = this.DFT.subDFT.filter(t => t.completed).length >0 ;
    }

    setDFTAll(completed: boolean) {
        this.DFT.completed = completed;
        if (this.DFT.subDFT == null) {
            return;
        }
        this.DFT.subDFT.forEach(t => (t.completed = completed));
    }

    // Functions Tracking
    tracking: any = {
        id:24,
        name: 'Tracking',
        completed: false,
        subtracking: [
            { id:25, name: 'NSW Tracking', completed: false },
            { id:26, name: 'DFT Tracking', completed: false },
        ],
    };
    clickTracking(){
        this.tracking.completed = this.tracking.subtracking.filter(t => t.completed).length >0 ;
    }

    setTrackingAll(completed: boolean) {
        this.tracking.completed = completed;
        if (this.tracking.subtracking == null) {
            return;
        }
        this.tracking.subtracking.forEach(t => (t.completed = completed));
    }

    // Functions Configuration
    config: any = {
        id:27,
        name: 'Configuration',
        completed: false,
        subconfig: [
            { id:28, name: 'User Mgt', completed: false },
            { id:29, name: 'Company Management', completed: false },
            { id:30, name: 'Surveyor', completed: false },
            { id:31, name: 'ชนิด/ชั้นของสินค้า', completed: false },
            { id:32, name: 'Group', completed: false },
            { id:33, name: 'Notification', completed: false },
            { id:34, name: 'DFT Connection', completed: false },
        ],
    };
    clickConfig(){
        this.config.completed = this.config.subconfig.filter(t => t.completed).length >0 ;
    }

    setConfigAll(completed: boolean) {
        this.config.completed = completed;
        if (this.config.subconfig == null) {
            return;
        }
        this.config.subconfig.forEach(t => (t.completed = completed));
    }

    saveData(e){
        // ตอนบันทึกข้อมูล CompanyId จะค่าของบริษัทนั้นๆที่ผูกกับ user ทำไว้เพื่อให้ mockup เล่นได้ก่อน ต้องแก้ไขให้ดึงค่า companyid มาจาก localstorage
        const form = {
            "groupId": e.ID,
            "companyId": this.idCompany,
            "groupName": this.form.GroupName,
            "adminGroup": this.form.RadioBox,
            "doc": this.doc.completed,
            "o2": this.doc.subdoc.filter(o => o.id == 2 ).map(o => o.completed).values().next().value,
            "ms13": this.doc.subdoc.filter(o => o.id == 3).map(o => o.completed).values().next().value,
            "ms24": this.doc.subdoc.filter(o => o.id == 4).map(o => o.completed).values().next().value,
            "docReNew":this.doc.subdoc.filter(o => o.id == 5).map(o => o.completed).values().next().value,
            "sendShrt":this.doc.subdoc.filter(o => o.id == 6).map(o => o.completed).values().next().value,
            "history": this.his.completed,
            "historyMs13": this.his.subhis.filter(o => o.id == 8).map(o => o.completed).values().next().value,
            "historyMs24": this.his.subhis.filter(o => o.id == 9).map(o => o.completed).values().next().value,
            "report":this.report.completed,
            "dailyreport": this.report.subreport.filter(o=> o.id == 11).map(o=> o.completed).values().next().value,
            "wvReport":this.report.subreport.filter(o=> o.id == 12).map(o=> o.completed).values().next().value,
            "ms13Report":this.report.subreport.filter(o=> o.id == 13).map(o=> o.completed).values().next().value,
            "ms24Report":this.report.subreport.filter(o=> o.id == 14).map(o=> o.completed).values().next().value,
            "otherReport":this.report.subreport.filter(o=> o.id == 15).map(o=> o.completed).values().next().value,
            "paymentReport":this.report.subreport.filter(o=> o.id == 16).map(o=> o.completed).values().next().value,
            "internalReport":this.report.subreport.filter(o=> o.id == 17).map(o=> o.completed).values().next().value,
            "dashboard":this.report.subreport.filter(o=> o.id == 18).map(o=> o.completed).values().next().value,
            "adminReport":this.report.subreport.filter(o=> o.id == 19).map(o=> o.completed).values().next().value,
            "requestPayment": this.reqPay.completed,
            "dftData":this.DFT.completed,
            "surData":this.DFT.subDFT.filter(o=> o.id == 22).map(o=> o.completed).values().next().value,
            "exporterData":this.DFT.subDFT.filter(o=> o.id == 23).map(o=> o.completed).values().next().value,
            "tracking": this.tracking.completed,
            "nswTracking":this.tracking.subtracking.filter(o=> o.id == 25).map(o=> o.completed).values().next().value,
            "dftTracking":this.tracking.subtracking.filter(o=> o.id == 26).map(o=> o.completed).values().next().value,
            "config":this.config.completed,
            "userMgt":this.config.subconfig.filter(o=> o.id == 28).map(o=> o.completed).values().next().value,
            "compM":this.config.subconfig.filter(o=> o.id == 29).map(o=> o.completed).values().next().value,
            "surveyor":this.config.subconfig.filter(o=> o.id == 30).map(o=> o.completed).values().next().value,
            "type":this.config.subconfig.filter(o=> o.id == 31).map(o=> o.completed).values().next().value,
            "groupConfig":this.config.subconfig.filter(o=> o.id == 32).map(o=> o.completed).values().next().value,
            "noti":this.config.subconfig.filter(o=> o.id == 33).map(o=> o.completed).values().next().value,
            "dftCon":this.config.subconfig.filter(o=> o.id == 34).map(o=> o.completed).values().next().value 
        };
        this.AppGroupListService.SaveDataConfigGroup(form).then((res)=>{
            if (res !== 'error') {
                this.alertService.info({ title: '', message: 'บันทึกข้อมูลเรียบร้อย' }).then(x =>{
                  this.dialog.closeAll();
                  this.router.navigate(['/page/group/grouplist']);
                }); 
              }
        })
    }

    onReset(){

    }

}