import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppMs13Service } from 'app/business/service/app/app-ms13.service';
import { AppMs24Service } from 'app/business/service/app/app-ms24.service';
import { AppO2Service } from 'app/business/service/app/app-o2.service';
import { AuthService } from 'app/core/auth/auth.service';
import { Observable, Subject } from 'rxjs';
import { LogDocumentActionDetailModalComponent } from '../../log-document-action-detail-modal/log-document-action-detail-modal.component';

@Component({
  selector: 'app-ms24-document-action-log-inquiry',
  templateUrl: './ms24-document-action-log-inquiry.component.html',
  styleUrls: ['./ms24-document-action-log-inquiry.component.css']
})
export class Ms24DocumentActionLogInquiryComponent implements OnInit {
  searchString: any = "";
  searchForm: any = {};
  productList: any = [];
  statusList: any = [];
  userList: any = [];
  actionTypeList: any = [];
  dataSource = new MatTableDataSource<any>();
  ms13Form: FormGroup;
  displayedColumns: string[] = [
    "index",
    "logDate",
    "docRefNo",
    "actionDescription",
    "logBy",
    "action",
  ];

  filteredOptionsUser$: Observable<string[]>
  url: any;
  buttonList: any[] = [];
  count: any;
  timer: any

  get userSearch(): AbstractControl {
    return this.ms13Form.get("userSearch");
  }

  private _unsubscribeAll$: Subject<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private appMs24Service: AppMs24Service,
    private fb: FormBuilder,
    private router: Router,
    private _authService: AuthService,
    private appMenuService: AppMenuService,
  ) {
    if (this.userProfile.gId != "UG002") {
      //Admin/BOT/Surveyor
      this.displayedColumns.splice(1, 0, "companyName");
    }
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode,
    };
    await this.appMenuService
      .getButtonPermissionPerPages(formData)
      .then((bb) => {
        this.buttonList = bb;
      });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);
    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    this.ms13Form = new FormGroup({
      userSearch: new FormControl(),
    });

    await this.getRouter();
    this.formInit();
    this.loadData();
    this.searchForm.nameSearch = "";
  }

  formInit(): void {
    this.ms13Form = this.fb.group({
      userSearch: [],
    });
  }

  async loadData() {
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: 0,
      isActive: "Y",
    };
    this.productList = await this.appMasterService.getProduct(formProduct);

    const formStatus = {
      statusId: 0,
      isActive: "Y",
    };
    await this.appMasterService.getStatus(formStatus).then((status) => {
      this.statusList = status;
    });

    //4 BOT
    this.appMasterService.getUserByCompanyType({ typeId: 3 }).then((res) => {
      this.userList = res;
      this.filteredOptionsUser$ = res;
    });

    this.appMasterService.getDocumentActionMtList().then((res) => {
      this.actionTypeList = res;
    });

    this.ms13Form.valueChanges.subscribe((newValue) => {
      this.filteredOptionsUser$ = this.filterUserValues(newValue.userSearch);
    });

    this.loadDataSource();
  }

  searchOption() {
    this.searchForm.keyword = "";
    this.searchString = "";

    this.loadDataSource();
  }

  clearOption() {
    this.searchForm = {};
    this.searchString = "";
    this.ms13Form.get("userSearch").setValue("");
    this.searchOption();
  }

  async loadDataSource() {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    const formData = {
      UID: Number(this.userProfile.uId),
      keyword: this.searchForm.keyword ?? null,
      expireDate: this.searchForm.expireDate,
      vesselName: this.searchForm.vesselName,
      docNo: this.searchForm.docNo,
      ms13No: this.searchForm.ms13No,
      o2No: this.searchForm.o2No,
      petitionNo: this.searchForm.petitionNo,
      actionType: this.searchForm.actionType,
      productId: this.searchForm.productId,
      statusId: this.searchForm.statusId,
      pagination: {
        Skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appMs24Service.getMs24DocumentActionHistoryList(formData).then((res) => {
      if (res != null) {
        if (res === 'error') {
          return;
        } else {
          if (res != null) {
            let a = res.itemLists;
            this.dataSource.data = a;
            this.count = res.totalItem;
          } else {
            this.dataSource.data = [];
            this.count = 0;
          }
        }
      }
    });

    this.dataSource.paginator.length = this.count;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: Event) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.searchForm.keyword = event;
      this.dataSource.paginator = this.paginator;
      this.loadDataSource();
    }, 500); // Specify the desired delay in milliseconds
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  menuClosed() { }

  filterUserValues(search: any) {
    return this.userList.filter(
      (value) =>
        value?.fullName?.toLowerCase().indexOf(search?.toLowerCase()) === 0
    );
  }

  selectionUserChange(e): void {
    this.ms13Form.get("userSearch").setValue(e.option.value.fullName);
    this.searchForm.reviewBy = e.option.value.userId;
  }

  async pageChanged(e: any) {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    this.searchForm.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };

    const formData = {
      UID: Number(this.userProfile.uId),
      keyword: this.searchForm.keyword ?? null,
      expireDate: this.searchForm.expireDate,
      vesselName: this.searchForm.vesselName,
      docNo: this.searchForm.docNo,
      ms13No: this.searchForm.ms13No,
      petitionNo: this.searchForm.petitionNo,
      o2No: this.searchForm.o2No,
      actionType: this.searchForm.actionType,
      productId: this.searchForm.productId,
      statusId: this.searchForm.statusId,
      pagination: {
        Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
        /*(!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),*/
        Take: !e.pageSize ? 10 : e.pageSize, //!this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appMs24Service.getMs24DocumentActionHistoryList(formData).then((res) => {
      if (res != null) {
        if (res === 'error') {
          return;
        } else {
          if (res != null) {
            let a = res.itemLists;
            this.dataSource.data = a;
            this.count = res.totalItem;
          } else {
            this.dataSource.data = [];
          }
        }
      }
    });

    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = e.pageIndex;
  }

  exportMs24(e) {
    window.open(e.docUrl, '_blank').focus();
  }

  openLog(e) {
    this.dialog.open(LogDocumentActionDetailModalComponent, {
      width: '70vw',
      disableClose: true,
      data: e
    });
  }

  checkPrintButton(e) {
    if (this.userProfile.gId == 'UG004') {
      if (e.docUrl != undefined && e.docUrl != null && e.docUrl != '') {
        return true;
      } else {
        return false;
      }
    } else {
      if (e.docUrl != undefined && e.docUrl != null && e.docUrl != '') {
        return true;
      } else {
        return false;
      }
      // return false;
    }
  }

  onRefresh() {
    this.clearOption();
  }
}
