import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppGroupListService } from "app/business/service/app/app-group.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { GroupAdjustManagementComponent } from "../group-adjust-management/group-adjust-management.component";
import { IAppGroupListResponse , IAppGroupAdjustResponse } from "app/business/interface/app/app-group.interface";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";

@Component({
  selector: 'app-group-inquiry',
  templateUrl: './group-inquiry.component.html',
  styleUrls: ['./group-inquiry.component.css']
})
export class GroupInquiryComponent implements OnInit {
  searchForm: any ={};
  form: any = {};
  message :string;
  dataSource = new MatTableDataSource <any>();
  displayedColumns: string[] = [
    "groupName",
    "adminGroup",
    "action",
  ];
  dataView :IAppGroupListResponse[]=[];
  AdminSearch : string = '';
  companyId : any ;
  typeUser: any ;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppGroupListService: AppGroupListService,
    private ConfirmDialogService: ConfirmDialogService,
    private AlertMessageService : AlertMessageService
  ) { 

  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator; 
    this.dataSource.sort = this.sort;
    // เช็คสิทธิ์ user
    // this.typeUser = localStorage.getItem('userTypeId');
    this.typeUser = 3 //จาก data จริง typeid ของ user staff คือ 3 
    // this.companyId = localStorage.getItem('cpId');
    this.companyId = 2;
    if(this.typeUser == 3) {
      this.loadDataStaff()
    }
    else {
      this.loadData();
    }

  }
  
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  async loadData(): Promise<void> {
    const formData = {
      "CompanyId": this.companyId
    };
    await this.AppGroupListService.ListGroup(formData).then((res) => {
      if(res){
        this.dataSource.data = res;
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async searchData(): Promise<void> {
    await this.loadData();
    const filterObj = {
      groupName: (this.searchForm.groupName?this.searchForm.groupName:'').toLowerCase(),
      adminGroup: (this.searchForm.adminGroup?this.searchForm.adminGroup:'').toLowerCase()
    }
    var defaulteFilterPredict = this.dataSource.filterPredicate;
    this.setFilterPredicate();
    this.dataSource.filter = JSON.stringify(filterObj);
    this.dataSource.filterPredicate = defaulteFilterPredict;
    this.searchForm = {};
    this.trigger.closeMenu();

  }
  
  stopPropagation(event) {
    event.stopPropagation();
  }

  setFilterPredicate(){
    this.dataSource.filterPredicate = (data: any, filterObjectString: string) => {
      const filterObj = JSON.parse(filterObjectString);
      return (
        (data.groupName?data.groupName:'').toLowerCase().includes(filterObj.groupName) &&
        (data.adminGroup?data.adminGroup:'').toLowerCase().includes(filterObj.adminGroup)
      )
    }
  }

  
  AdjustGroupName(groupId) {
    if(groupId === 0){
      groupId = { companyId: this.companyId}
    }
    const dialogRef = this.dialog.open(GroupAdjustManagementComponent, {
      disableClose: false,
      width: '60vw',
      data: groupId,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res == "OK") {
        this.loadData();
      }
      
    });
  }
  async deleteItem(e) {
    // -สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.ConfirmDialogService.open({
      header: "ต้องการลบกลุ่ม หรือไม่",
      content: "ยืนยันการลบ",
    });
    if (alertConfirm) {
      const formData = {
        GroupId :e.groupId,
        GROUPNAME :e.groupName,
        GROUPCOMPANYID :e.companyId,
        GROUPSTATUSADMIN :e.adminGroup,
        GroupStatusActive : "N"
      };
      await this.AppGroupListService.AdjustGroup(formData).then((res)=>{ 
        if(res !== 'error'){
          this.AlertMessageService.info({ title: '', message: res }).then(x=>{
            this.dialog.closeAll();
          });
        }
      });
      if(this.typeUser == 3) {
        this.loadDataStaff()
      }
      else {
        this.loadData();
      }
    }
  }


  // staff group management
  async loadDataStaff(): Promise<void> {
    const formData = {
      "CompanyId": this.companyId
    };
    await this.AppGroupListService.GetStaffDataGroupList(formData).then((res) => {
      if(res){
        this.dataSource.data = res;
      }
    });
  }


}


