import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { Location, formatDate } from "@angular/common";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { SelectionModel } from "@angular/cdk/collections";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { AppCompanyService } from "app/business/service/app/app-company.service";
import { ActivatedRoute } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { ImageModalComponent } from "../ms13/Image-modal/Image-modal.component";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { IGetCompanyTypeDetailListResponse } from "app/business/interface/app/app-company.interface";
import { AddCompanyMemberDialogComponent } from "../add-company-member-dialog/add-company-member-dialog.component";

@Component({
  selector: "app-company-profile-form",
  templateUrl: "./company-profile-form.component.html",
  styleUrls: ["./company-profile-form.component.css"],
})
export class CompanyProfileFormComponent implements OnInit, AfterViewInit {
  form: any = {};
  file: any;
  fileImg: any;
  formFile: any = {};
  formAttachment: any = {};
  value: any[] = [];
  dataSourceAtt: any = [];
  comMember: any[] = [];
  companyTypeList: any[] = [];
  provinceLis: any[] = [];
  isMREnabled: boolean = false;
  companyId: any;
  dataView: IGetCompanyTypeDetailListResponse[] = [];
  displayedColumns: string[] = [
    "tcaAddressStreet",
    "subDistrictName",
    "districtName",
    "province",
    "postCode",
    "phone",
    "fax",
    "tcaTypeComAddName",
  ];
  isEpayment: any[] = [
    {
      value: "Y",
      name: "ใช้งาน",
    },
    {
      value: "N",
      name: "ไม่ใช้งาน",
    },
  ];

  isFactory: any[] = [
    {
      value: "Y",
      name: "ใช่",
    },
    {
      value: "N",
      name: "ไม่ใช่",
    },
  ];

  provinceList: any[] = [];

  dataSource = new MatTableDataSource<any>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private _location: Location,
    private appMasterService: AppMasterService,
    private appCompanyService: AppCompanyService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertMessage: AlertMessageService,
    private confirmService: ConfirmDialogService
  ) {
    const { companyId } = this.route.snapshot.queryParams;
    this.companyId = companyId;
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    // this.appMasterService.getMrConnectParameter("MR_CONNECT").then(res => {
    //   if (res === 'error') {
    //     this.isMREnabled = false;
    //   } else {
    //     this.isMREnabled = res?.cpParamiter === 'ON' ? true : false;
    //   }
    // });

    this.appMasterService.getMasterBankCode("").then((res) => {
      if (res === "error") {
        return;
      } else {
        this.comMember = res;
        console.log("commember: ", res);
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });

    this.appMasterService.getCompanyAddressType("").then((res) => {
      if (res === "error") {
        this.companyTypeList = [];
      } else {
        this.companyTypeList = res;
      }
    });

    this.appCompanyService.getCompanyProfile(this.companyId).then((res) => {
      if (res === "error") {
        return;
      } else {
        console.log("com pro: ", res);
        this.form = res;
      }
    });

    this.appMasterService.getMasterProvince({}).then((res) => {
      if (res === "error") {
        return;
      } else {
        this.provinceList = res;
        console.log("province : ", res);

        const CheckAll = this.provinceList.find(
          (element) => element.provinceName === "กรุณาเลือก"
        );
        if (CheckAll === undefined) {
          this.provinceList.unshift({
            provinceId: 0,
            provinceName: "กรุณาเลือก",
          });
        }
      }
    });

    const formData = {
      taxId: "0405549000012",
    };

    this.appCompanyService.getCompanyDetailStaffList(formData).then((res) => {
      this.dataView = res;
      this.dataSource = new MatTableDataSource(this.dataView);
      this.form.expire = formatDate(res.expireDate, "yyyy-MM-dd", "en-US");
      this.form.update =
        res.updateDate == null
          ? null
          : formatDate(res.updateDate, "yyyy-MM-dd", "en-US");
      this.form.changeUpCom =
        res.changeUpComDate == null
          ? null
          : formatDate(res.changeUpComDate, "yyyy-MM-dd", "en-US");
      this.form = { ...this.form, ...res };
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  imagePopup(e) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minHeight = "30vh";
    dialogConfig.minWidth = "30vh";
    dialogConfig.maxHeight = "100vh";
    dialogConfig.data = {
      fullImageUrl: e,
      header: "ภาพตราบริษัท",
    };

    const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig);
  }

  backClicked() {
    this._location.back();
  }

  save() {
    const req = {
      cId: this.companyId,
      isAllowEpayment: this.form.isAllowEpayment,
    };

    console.log("req: ", req);
    this.appCompanyService.updateCompanyEPayment(req).then((res) => {
      if (res === "error") {
        this.alertMessage.open({
          message: "เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล",
          title: "",
        });
      } else {
        this.alertMessage.open({ message: "บันทึกข้อมูลสำเร็จ", title: "" });
      }
    });
  }

  async deleteImg() {
    const alertConfirm = await this.confirmService.open({
      header: "ต้องการลบใบรูป ตราบริษัท หรือไม่",
      content: "ยืนยันการลบ",
    });
    if (alertConfirm) {
      this.fileImg = null;
      this.formFile.fileByte = null;
      this.formFile.fileExtension = null;
      this.formFile.appType = null;
      this.formAttachment.fileName = null;
      this.dataSourceAtt = [];
    }
  }

  async deleteResItem(e) {
    // -สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "ต้องการลบ ตัวแทนที่ได้รับอนุญาต หรือไม่",
      content: "ยืนยันการลบ",
    });
    // ;
    // if (alertConfirm) {
    //   const updateForm = {
    //     ID: e.id,
    //     status: e.status,
    //     isActive: "N",
    //     companyCode: this.userProfile.companyCode,
    //   };
    //   this.appO2Service.updateStatusO2(updateForm).then((res) => {
    //     this.searchOption();
    //   });
    // }
  }

  showImg() {
    const dialogRef = this.dialog.open(ImageModalComponent, {
      disableClose: true,
      // maxWidth: "none",
      width: "45vw",
      height: "35vw",
      data: {
        path: this.fileImg,
        title: "ตราบริษัท",
      },
    });
  }

  async onFileChangedBr(event): Promise<void> {
    if (event.value.length === 0) {
      return;
    }
    event.value.forEach((item) => {
      this.file = item;
      this.onUploadBr();
    });
    this.value = [];
  }

  onUploadBr(): void {
    const formData = new FormData();
    formData.append("image", this.file, this.file.name);
    formData.append("blobType", "CSCAttachments");
    // formData.append("typePage", this.typePage);
    // formData.append("action", "new");
    // formData.append("linkDoc", this.formId ?? 0);
    this.appMasterService.uploadFile(formData).subscribe((result) => {
      if (result) {
        this.formFile = result[0];
        this.fileImg = result[0]?.filePath;
        this.form.pic = result[0]?.fileName + "." + result[0]?.fileType;
        this.formFile.appType = result[0]?.fileType;
        this.formFile.fileExtension = this.file.type;
        const attachment = {
          fileName: this.formFile.fileName,
          documentName: this.form.docNo,
          linkDoc: 1,
          documentContent: "image",
          documentSize: this.formFile.fileByte,
          fileExtension: this.formFile.fileExtension,
          docByte: this.formFile.fileByte,
        };
        this.dataSourceAtt.push(attachment);
        console.log(this.formFile);
      }
    });
  }

  addCompanyMember(data) {
    // console.log("add member: ", this.form.shippingRelationList[0].companyName);
    console.log("add member: ", data.resId);

    this.dialog.open(AddCompanyMemberDialogComponent, {
      width: "50vw",
      disableClose: true,
      data: {
        resId: this.form.shippingRelationList.resId,
        // status: this.form.status,
        // type: type,
        // id: id,
        // exchangeRate: this.form.exchangeRate,
        // page: this.typePage,
        // item: data,
        // currency: this.form.currency,
      },
    });
  }
}
