import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from "@angular/core";
import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import { MatButton } from "@angular/material/button";
import { Subject, takeUntil } from "rxjs";
import { IResNotifyList, Notification } from "app/layout/common/notifications/notifications.types";
import { NotificationsService } from "app/layout/common/notifications/notifications.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Location, formatDate } from "@angular/common";
import { AuthService } from "app/core/auth/auth.service";
import { NotificationDetailsComponent } from "./notification-details/notification-details.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "notifications",
  templateUrl: "./notifications.component.html",
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "notifications",
})
export class NotificationsComponent implements OnInit, OnDestroy {
  @ViewChild('notificationsOrigin') private _notificationsOrigin: MatButton;
  @ViewChild('notificationsPanel') private _notificationsPanel: TemplateRef<any>;

  notifications: Notification[];
  notifyList: IResNotifyList[];
  unreadCount: number = 0;
  private _overlayRef: OverlayRef;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  @Output() updateUnreadCount = new EventEmitter();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _notificationsService: NotificationsService,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to notification changes
    this._notificationsService.notifications$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((notifications: IResNotifyList[]) => {

        // Load the notifications
        this.notifyList = notifications.map((m) => {
          var linkRefData = m.linkRef?.split("?");
          var parameter = (linkRefData != undefined && linkRefData != null) ? this.parseQueryParams(linkRefData[1]) : null;
          return {
            ...m,
            linkRef: (linkRefData != undefined && linkRefData != null) ? linkRefData[0] : null,
            refData: parameter,
          }
        });

        console.log("oninit >> ", this.notifyList);

        // Calculate the unread count
        this._calculateUnreadCount();

        //Emit unread count
        this.updateUnreadCount.emit(this.unreadCount);

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  parseQueryParams(queryString: string) {
    return queryString.split("&").reduce((acc, param) => {
      const [key, value] = param.split("=");
      acc[key] = value;
      return acc;
    }, {});
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();

    // Dispose the overlay
    if (this._overlayRef) {
      this._overlayRef.dispose();
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Open the notifications panel
   */
  openPanel(): void {
    // Return if the notifications panel or its origin is not defined
    if (!this._notificationsPanel || !this._notificationsOrigin) {
      return;
    }

    // Create the overlay if it doesn't exist
    if (!this._overlayRef) {
      this._createOverlay();
    }

    // Attach the portal to the overlay
    this._overlayRef.attach(new TemplatePortal(this._notificationsPanel, this._viewContainerRef));
  }

  /**
   * Close the notifications panel
   */
  closePanel(): void {
    this._overlayRef.detach();
  }

  /**
   * Mark all notifications as read
   */
  markAllAsRead(notification: IResNotifyList[]): void {
    // Mark all as read
    this._notificationsService.markAllAsRead(notification).subscribe();
  }

  /**
   * Toggle read status of the given notification
   */
  toggleRead(notification: IResNotifyList): void {
    if (notification.notificationReadStatus === "W") {
      // Toggle the read status
      notification.notificationReadStatus = "R";

      // Update the notification
      this._notificationsService.update(notification).subscribe();
    }
  }

  /**
   * Delete the given notification
   */
  delete(notification: IResNotifyList): void {
    // Delete the notification
    this._notificationsService.delete(notification).subscribe();
  }

  /**
   * Open notification detail dialog
   */
  showNotificationDetail(notification: IResNotifyList) {
    this.toggleRead(notification);
    this.dialog.open(NotificationDetailsComponent, {
      width: '60vw',
      disableClose: false,
      data: notification
    }).afterClosed().subscribe(res => {
      if (res) {
        this.closePanel();
      }
    });
  }

  redirectToLink(notification: IResNotifyList) {
    this.toggleRead(notification);
    window.open(notification.notificationLink, '_blank').focus();
  }

  redirectToRefLink(notification: IResNotifyList) {
    //<!-- [routerLink]="notification.linkRef" [queryParams]="notification.refData" -->
    this.toggleRead(notification);
    this.router.navigate([notification.linkRef], { queryParams: notification.refData });
  }

  /**
   * Track by function for ngFor loops
   *
   * @param index
   * @param item
   */
  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Create the overlay
   */
  private _createOverlay(): void {
    // Create the overlay
    this._overlayRef = this._overlay.create({
      hasBackdrop: true,
      backdropClass: 'fuse-backdrop-on-mobile',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: this._overlay.position()
        .flexibleConnectedTo(this._notificationsOrigin._elementRef.nativeElement)
        .withLockedPosition(true)
        .withPush(true)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top'
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom'
          }
        ])
    });

    // Detach the overlay from the portal on backdrop click
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.detach();
    });
  }

  /**
   * Calculate the unread count
   *
   * @private
   */
  private _calculateUnreadCount(): void {
    let count = 0;

    if (this.notifyList && this.notifyList.length) {
      count = this.notifyList.filter(e => e.notificationReadStatus === "W").length;
    }

    this.unreadCount = count;
  }

  /**
   * Set Style
   */

  getNotificationClass(notificationReadStatus: string): string {
    if (notificationReadStatus === 'W') {
      return 'flex group hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5';
    } else {
      return 'flex group darker-bg hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5';
    }
  }

  convertDate(dateStr: any) {
    var date = new Date(dateStr);
    var thaiDate = date.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return thaiDate;
  }
}
