import { Component, OnInit, Inject, NgModule } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RedirectDialogInputModel } from './redirect-dialog-model';
@Component({
  selector: 'redirect-dialog',
  templateUrl: './redirect-dialog.component.html',
  styleUrls: ['./redirect-dialog.component.css']
})
export class RedirectDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<RedirectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RedirectDialogInputModel
  ) {
    data.confirmText = data.confirmText != undefined && data.confirmText != null ? data.confirmText : "ยืนยัน";
    data.cancelText = data.cancelText != undefined && data.cancelText != null ? data.cancelText : "ยกเลิก";
    data.showCancelBtn = data.showCancelBtn != undefined && data.showCancelBtn != null ? data.showCancelBtn : true;
  }

  redirect(link: string): void {
    window.open(link, "_blank");
  }
}
