<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">รายงานสรุปการส่งเอกสารไปยังระบบ OCS Connect ของกรมการค้าต่างประเทศ</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="flex flex-col mat-typography" style="gap: 15px;">
    <form name="dailyReportForm" [formGroup]="dailyReportForm" novalidate>
      <div class="flex flex-column items-center pl-2.5 pt-10">
        <div class="flex flex-row basis-1/3  text-lg">
          <div class="flex-column text-right w-full">
            <mat-label> วันเริ่มต้น :</mat-label>
          </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
          <mat-form-field class="w-full pl-2.5" appearance="fill">
            <input matInput #input class="form-control" [matDatepicker]="picker1" (click)="picker1.open()" readonly
              name="startDate" formControlName="startDate" [(ngModel)]="dailyReport.startDate" required="true" [max]="myDate">
            <mat-hint>YYYY/MM/DD</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1 dateFormat="YYYY/MM/DD" disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <div
          *ngIf="dailyReportForm.controls['startDate'].invalid && (dailyReportForm.controls['startDate'].dirty || dailyReportForm.controls['startDate'].touched)"
          class="p-1 text-sm text-red-800" role="alert">
          <div *ngIf="dailyReportForm.controls['startDate'].errors.required">
            **กรุณาระบุวันเริ่มต้น.
          </div>
        </div>
      </div>
      <div class="flex flex-column items-center pl-2.5 pt-5">
        <div class="flex flex-row basis-1/3  text-lg">
          <div class="flex-column text-right w-full">
            <mat-label> วันสุดท้าย :</mat-label>
          </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
          <mat-form-field class="w-full pl-2.5" appearance="fill">
            <input matInput #input class="form-control" [matDatepicker]="picker2" (click)="picker2.open()" readonly
              name="endDate" formControlName="endDate" [(ngModel)]="dailyReport.endDate" [max]="myDate"
              [min]="dailyReport.startDate" required="true">
            <mat-hint>YYYY/MM/DD</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2 dateFormat="YYYY/MM/DD" disabled="false"></mat-datepicker>
          </mat-form-field>
        </div>
        <div
          *ngIf="dailyReportForm.controls['endDate'].invalid && (dailyReportForm.controls['endDate'].dirty || dailyReportForm.controls['endDate'].touched)"
          class="p-1 text-sm text-red-800" role="alert">
          <div *ngIf="dailyReportForm.controls['endDate'].errors.required">
            **กรุณาระบุวันสุดท้าย.
          </div>
        </div>
      </div>
      <div class="flex flex-column items-center pl-2.5 pt-5">
        <div class="flex flex-row basis-1/3  text-lg">
          <div class="flex-column text-right w-full">
            <mat-label> ประเภทเอกสาร :</mat-label>
          </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
          <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
            <mat-select [(ngModel)]="dailyReport.typeList" name="statusList" formControlName="statusList" ngmodel>
              <mat-option value="0">All</mat-option>
              <mat-option value="MS13">มส.13</mat-option>
              <mat-option value="MS24">มส.24</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="flex flex-column items-center pl-2.5 pt-5">
        <div class="flex flex-row basis-1/3  text-lg">
          <div class="flex-column text-right w-full">
            <mat-label> สถานะ :</mat-label>
          </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
          <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
            <mat-select [(ngModel)]="dailyReport.statusList" name="statusList" formControlName="statusList" ngmodel>
              <mat-option value="0">All</mat-option>
              <mat-option *ngFor="let status of statusOptionList" [value]="status.statusId">
                {{status.statusName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>

    <div class="flex flex-column items-center pl-2.5 pt-5">
      <div class="flex flex-row basis-1/2 f18 text-lg  justify-end">
        <button type="submit" mat-raised-button color="accent" class="h-12 px-6 m-2 text-lg rounded-lg  justify-right"
          (click)="exportExcel()" [disabled]="dailyReportForm.controls['startDate'].invalid || dailyReportForm.controls['endDate'].invalid">
          Export Excel
        </button>
      </div>
      <div class="flex flex-row basis-1/2 f18  text-lg">
        <button mat-raised-button color="accent" class="h-12 px-6 m-2 text-lg rounded-lg"
          [disabled]="dailyReportForm.controls['startDate'].invalid || dailyReportForm.controls['endDate'].invalid">
          Export PDF
        </button>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>