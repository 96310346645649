<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
  <div class="menu justify-between flex items-stretch flex-row gap-5 px-20 pr-10 pl-10">
    <div class="gap-2.5 flex flex-row">
      <!-- <div *ngIf="checkButton('BT145')"> -->
      <button mat-raised-button color="accent" (click)="addAccount($event)">
        <div class="flex items-center">
          <span>Add</span>
        </div>
      </button>
      <!-- </div> -->
      <!-- <div *ngIf="checkButton('BT146')"> -->
      <button mat-raised-button color="warn" (click)="deleteSelection()">
        <div class="flex items-center">
          <span>Delete</span>
        </div>
      </button>
      <!-- </div> -->
    </div>
    <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">
      <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0" style="flex: 0 40%;">
        <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
          <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input [(ngModel)]="form.search"
            name="search" #search="ngModel" (keyup)="applyFilter($event)">
          <button matSuffix mat-icon-button aria-label="ค้นหา">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>

  </div>
  <!-- </div> -->
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
            Select
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="provinceName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ชื่อจังหวัด (ภาษาไทย) </th>
        <td mat-cell *matCellDef="let element"> {{element.provinceName}} </td>
      </ng-container>

      <ng-container matColumnDef="provinceEname">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ชื่อจังหวัด (ภาษาอังกฤษ) </th>
        <td mat-cell *matCellDef="let element"> {{element.provinceEname}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-left"> การจัดการ </th>
        <td mat-cell *matCellDef="let element;let i = index" class="text-left">
          <div class="flex flex-row" style="justify-content: start;">
            <!-- <div *ngIf="checkButton('BT147')"> -->
            <button mat-icon-button matTooltip="รายละเอียด" (click)="editItem(element)" matTooltipPosition="above">
              <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
            </button>
            <!-- </div> -->
            <!-- <div *ngIf="checkButton('BT146')"> -->
            <button mat-icon-button matTooltip="ลบ" (click)="delete(element)" matTooltipPosition="above">
              <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
            </button>
            <!-- </div> -->
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>