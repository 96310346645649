<form name="userForm" [formGroup]="userForm" novalidate class="formFlex">
    <!-- <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="space-between center"> -->
    <!-- fxFlex="50"  v -->

    <div class="pl-22 pr-22 flex flex-col gap-4">
        <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
            <div class="mat-popup-header flex flex-col">
                <h4>ชื่อผู้ใช้งาน <span style="color:red;">*</span></h4>
                <h4>Username</h4>
            </div>
            <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
                <div>:</div>
                <mat-form-field style="flex: 0 95%;" class="flex">
                    <input autocomplete="off" matInput formControlName="userName" value="" required pattern="\S(.*\S)?"
                        maxlength="100" readonly="{{dataInput?.userId !== 0}}">
                    <mat-error *ngIf="userForm.get('userName').hasError('required')">
                        กรุณาระบุ Username
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
            <div class="mat-popup-header flex flex-col">
                <h4>ชื่อ <span style="color:red;">*</span></h4>
                <h4>Name</h4>
            </div>
            <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
                <div>:</div>
                <mat-form-field style="flex: 0 95%;" class="flex">
                    <input autocomplete="off" matInput formControlName="name" value="" required pattern="\S(.*\S)?"
                        maxlength="255">
                    <mat-error *ngIf="userForm.get('name').hasError('required')">
                        กรุณาระบุชื่อผู้ใช้งาน
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
            <div class="mat-popup-header flex flex-col">
                <h4>นามสกุล <span style="color:red;">*</span></h4>
                <h4>Last Name</h4>
            </div>
            <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
                <div>:</div>
                <mat-form-field style="flex: 0 95%;" class="flex">
                    <input autocomplete="off" matInput formControlName="lastName" value="" required pattern="\S(.*\S)?"
                        maxlength="255">
                    <mat-error *ngIf="userForm.get('lastName').hasError('required')">
                        กรุณาระบุชื่อผู้ใช้งาน
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
            <div class="mat-popup-header flex flex-col">
                <h4>กลุ่มผู้ใช้งาน <span style="color:red;">*</span></h4>
                <h4>User Group</h4>
            </div>
            <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
                <div>:</div>
                <mat-form-field style="flex: 0 95%;" class="flex">
                    <mat-select matNativeControl formControlName="userGroupCode" required
                        (ngModelChange)="userGroupChange($event);">
                        <mat-option *ngFor="let option of userGroupList"
                            [value]="option.userGroupCode">{{option.groupName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="userForm.get('userGroupCode').hasError('required')">
                        กรุณาระบุกลุ่มผู้ใช้
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
            <div class="mat-popup-header flex flex-col">
                <h4>รหัสกลุ่มผู้ใช้งาน</h4>
                <h4>User Group Code</h4>
            </div>
            <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
                <div>:</div>
                <mat-form-field style="flex: 0 95%;" class="flex">
                    <input autocomplete="off" disabled matInput formControlName="groupCode" value="{{this.userGroupCode}}">
                </mat-form-field>
            </div>
        </div> -->

        <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
            <div class="mat-popup-header flex flex-col">
                <h4>สิทธิ์ผู้ใช้งาน <span style="color:red;">*</span></h4>
                <h4>User Role</h4>
            </div>
            <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
                <div>:</div>
                <mat-form-field style="flex: 0 95%;" class="flex">
                    <mat-select matNativeControl formControlName="userRoleCode" required
                        (ngModelChange)="userRoleChange($event);">
                        <mat-option *ngFor="let option of userRoleList"
                            [value]="option.userRoleCode">{{option.roleName}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="userForm.get('userRoleCode').hasError('required')">
                        กรุณาระบุสิทธิ์ผู้ใช้
                    </mat-error>
                </mat-form-field>
            </div>
        </div>

        <!-- <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
            <div class="mat-popup-header flex flex-col">
                <h4>รหัสสิทธิ์ผู้ใช้งาน</h4>
                <h4>User Role Code</h4>
            </div>
            <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
                <div>:</div>
                <mat-form-field style="flex: 0 95%;" class="flex">
                    <input autocomplete="off" disabled matInput formControlName="roleCode" value="{{this.userRoleCode}}">
                </mat-form-field>
            </div>
        </div> -->


        <ng-container *ngIf="(userToken.userRoleCode == 'RO005' || userToken.userRoleCode == 'RO007') && (dataInput.userGroupCode == 'UG004' || dataInput.userGroupCode == 'UG001')">
            <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
                <div class="mat-popup-header flex flex-col">
                    <h4>ศูนย์ที่ดำเนินงาน <span style="color:red;">*</span></h4>
                    <h4>Active Branch</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center" style="width:75%">
                    <div>:</div>
                    <mat-form-field style="flex: 0 95%;" class="flex">
                        <mat-select matNativeControl formControlName="activeBranchId" required>
                            <mat-option *ngFor="let option of branchList"
                                [value]="option.cscBranchId">{{option.cscBranchName}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="userForm.get('activeBranchId').hasError('required')">
                            กรุณาเลือกศูนย์ที่ดำเนินงาน
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>

        <div class="flex-row flex justify-between gap-2.5" *ngIf="dataInput.userGroupCode == 'UG004' || dataInput.userGroupCode == 'UG001'">
        <!-- <div class="flex-row flex justify-between gap-2.5" *ngIf="1==1"> -->
            <div class="mat-popup-header flex flex-col">
                <h4>แนบเอกสาร</h4>
            </div>
            <div class="mat-popup flex flex-row items-center" style="width:75%">
                <div>:</div>
                <section class="px-4 flex flex-col flex-1 py-4  justify-evenly">
                    <mat-label style="color : red;">
                        รายละเอียดของไฟล์ภาพควรมีขนาดไม่เกิน 3 MB / รูปแบบไฟล์ที่อนุญาต .jpg, .jpeg, .png
                    </mat-label>
                    <section class="flex flex-row gap-4 ">
                        <div class="file-uploader-block">
                            <dx-file-uploader style="border: none; padding: 0" [allowCanceling]="true" [(value)]="value"
                                [multiple]="false" [uploadMode]="'useForm'" #fileUploader [multiple]="false"
                                [uploadMode]="'useForm'" accept="image/jpeg, image/png ,image/jpg"
                                [allowedFileExtensions]="['.jpg', '.jpeg', '.png']">
                            </dx-file-uploader>
                        </div>
                    </section>
                    <div class="flex flex-row gap-4 mb-3 ml-3">
                        <dx-button text="Upload" (onClick)="upload($event)">
                        </dx-button>
                    </div>
                    <div class="flex flex-row self-left">
                        <div>
                            <div style="width: 400px; height: auto;">

                                <a target="_blank"><img src="{{pathUpload}}"></a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>


        <div class="flex-row flex justify-between gap-2.5">
            <div class="mat-popup-header flex flex-col">
                <h4>ใช้งาน</h4>
                <h4>Active</h4>
            </div>
            <div class="mat-popup flex flex-row items-center" style="width:75%">
                <div>:</div>
                <mat-radio-group formControlName="isActive" style="margin-left: 20px;" required>
                    <mat-radio-button class="mr-12" [value]="'Y'">ใช้งาน</mat-radio-button>
                    <mat-radio-button class="mr-12" [value]="'N'">ยกเลิก</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="userForm.get('isActive').hasError('required')">
                    กรุณาระบุสถานะ
                </mat-error>
            </div>
        </div>

        <div class="flex-row flex justify-between gap-2.5">
            <div class="mat-popup-header flex flex-col">
                <h4>ล็อค</h4>
                <h4>Lock (สถานะการ Login)</h4>
            </div>
            <div class="mat-popup flex flex-row items-center" style="width:75%">
                <div>:</div>
                <mat-radio-group formControlName="statusLogin" style="margin-left: 20px;" required>
                    <mat-radio-button class="mr-12" [value]="'OFF'">Unlock</mat-radio-button>
                    <mat-radio-button class="mr-12" [value]="'ON'">Lock</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="userForm.get('statusLogin').hasError('required')">
                    กรุณาระบุสถานะปลดล็อคสถานะการ Login
                </mat-error>
            </div>
        </div>
    </div>
</form>