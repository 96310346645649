import { Component, Input, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  type: any;
  typePage: any;
  menuForm: any;
  @Input("type") set _type(value: any) {
    if (!value) {
      return;
    } else {
      this.type =
        value == "dailyreport"
          ? "dailyreport"
          : value == "o2"
            ? "อ.2"
            : value == "history-ms13"
              ? "แก้ไขข้อมูลและปลดล็อกการพิมพ์ มส. 13"
              : value == "ms13"
                ? "มส.13"
                : "มส.24";
    }
  }
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appMenuService: AppMenuService
  ) {
    this.appMenuService.menuPermissionList.subscribe(res => { 
      this.menuForm = this.appMenuService.setNavigation(res); 
    });
  }

  async ngOnInit() {
    const { page } = this.route.snapshot.queryParams;
    this.type = page;
    const menu = await this.appMenuService.getMenuPermission();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
}
