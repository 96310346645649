<mat-card class="example-card">
  <mat-card-header class="fontTitleHeader">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal">สำเนาเอกสาร มส.24 (Copy of MS24)</div>
      <div>
        <button mat-icon-button class="close-button" (click)="printMs24()"
          matTooltip="เปิดสำเนา มส.24" matTooltipPosition="above">
          <i class="ti ti-license"></i>
        </button>
        <button mat-icon-button class="close-button" [mat-dialog-close]
          matTooltip="ปิดหน้าต่าง" matTooltipPosition="above">
          <i class="ti ti-x"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content style="padding: 16px">
    <div class="flex flex-col" style="gap: 10px;">
      <!-- มส.24 REQUEST SUMMARY -->
      <div class="flex flex-col p10">
        <mat-card style="padding: 20px; gap: 5px;">
          <div class="flex" *ngIf="form.o2Docno != undefined && form.o2Docno != null">
            <div class="flex" style="width: 50%">
              <div class="f18" style="align-self: center;">
                ใบอนุญาตกระทรวงพาณิชย์ที่ : พณ{{form.o2Docno}} ลงวันที่ {{form.o2IssueDate | thaidate}}
              </div>
            </div>
            <div class="flex justify-end" style="width: 50%">
            </div>
          </div>
          <div class="flex">
            <div class="flex" style="width: 55%">
              <div class="flex" style="width: 25%;">
                <div class="f18" style="align-self: center;">
                  เลขที่ประจำตัวผู้เสียภาษี :
                </div>
              </div>
              <div class="flex justify-center" style="width: 25%;">
                <div class="f18" style="align-self: center;">
                  {{form.companyTax}}
                </div>
              </div>
              <div class="flex" style="width: 16%;">
                <div class="f18" style="align-self: center;">
                  คำร้องขอเลขที่ :
                </div>
              </div>
              <div class="flex justify-center" style="width: 25%;">
                <div class="f18" style="align-self: center;">
                  {{form.ms13FullRefNo}}
                </div>
              </div>
            </div>
            <div class="flex justify-end" style="width: 45%">
              <div class="f18" style="align-self: center;">
                ประเภทสินค้า : {{form.goodsName}}
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <!-- ข้อมูลผู้ขอใบรับรอง -->
      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            ข้อมูลผู้ขอใบรับรอง (Applicant Information)
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding: 16px">
            <div class="flex">
              <div class="flex-1 w-50">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชื่อบริษัทผู้ส่งออก (ภาษาไทย) :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.companyThName}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-50">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">ใบทะเบียน ผค. :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.registrationNoPk}}</div>
                  </div>
                  <div class="basis-1/2 f18" style="align-self: center;"></div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- ข้อมูลสินค้า -->
      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            ข้อมูลสินค้า (Product Information)
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding: 16px">
            <div class="flex">
              <div class="flex-1 w-50">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชื่อสินค้า :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.goodsName}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-50">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">ประเภทสินค้า :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.typeGoods}}</div>
                  </div>
                  <div class="basis-1/2 f18" style="align-self: center;"></div>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="flex-1 w-50">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชนิด/ชั้น :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.typeClass}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-50">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">ตราหรือเครื่องหมายส่งออก :</div>
                  <div class="basis-4/5">
                    <div class="f18" style="align-self: center;">{{form.shippingMark ?? 'ไม่ระบุตรา'}}</div>
                  </div>
                  <div class="basis-1/2 f18" style="align-self: center;"></div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- ข้อมูลรายการสินค้า -->
      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            ข้อมูลรายการสินค้า (Product Item Information)
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding: 16px">
            <div class="mat-elevation-z8" style="width: 100%">
              <dx-data-grid id="gridContainer" [dataSource]="dataSource" [columnAutoWidth]="true" [showBorders]="true"
                [allowColumnReordering]="true" [showBorders]="true" [noDataText]="'ไม่มีข้อมูล'">
                <dxi-column caption="รายการที่" alignment="center" cellTemplate="wFlow" [width]='100'>
                </dxi-column>
                <div *dxTemplate="let row of 'wFlow'">
                  {{row.rowIndex+1}}
                </div>
                <dxi-column alignment="center" caption="Invoice" cellTemplate="invoice"> </dxi-column>
                <div class="flex flex-col" *dxTemplate="let row of 'invoice'">
                  <div class="flex" style="gap: 6px;">
                    <div>No. : </div>
                    <div>{{row.data.invoiceNo}}</div>
                  </div>
                  <div class="flex" style="gap: 6px;">
                    <div>วันที่ : </div>
                    <div>{{row.data.invoiceDate | thaidate}}</div>
                  </div>
                  <div class="flex" style="gap: 6px;">
                    <div>ลำดับรายการสินค้า : </div>
                    <div>{{row.data.invoiceItem}}</div>
                  </div>
                </div>
                <dxi-column alignment="center" dataField="goodsDescription" [width]="450" caption="รายการสินค้า">
                </dxi-column>
                <dxi-column alignment="center" caption="ปริมาณ" cellTemplate="quantity"> </dxi-column>
                <div *dxTemplate="let row of 'quantity'">
                  {{ (row.data.quantity != null ? row.data.quantity : 0) | number:'1.0-6'}}
                  {{row.data.quantityUnitName}}
                </div>
                <dxi-column alignment="center" caption="น้ำหนัก" cellTemplate="netWeight"> </dxi-column>
                <div *dxTemplate="let row of 'netWeight'">
                  {{ (row.data.netWeight != null ? row.data.netWeight : 0) | number:'1.0-6'}}
                  {{row.data.netWeightUnit}}
                </div>
                <dxi-column alignment="center" caption="FOB(บาท)" cellTemplate="fobValueBaht"></dxi-column>
                <div *dxTemplate="let row of 'fobValueBaht'">
                  {{ (row.data.fobValueBaht != null ? row.data.fobValueBaht : 0) | number:'1.2-6'}}
                </div>
                <dxi-column alignment="center" caption="มูลค่า (บาท)" cellTemplate="valueBaht"></dxi-column>
                <div *dxTemplate="let row of 'valueBaht'">
                  {{ (row.data.valueBaht != null ? row.data.valueBaht : 0) | number:'1.2-6'}}
                </div>
                <dxi-column alignment="center" caption="มูลค่า (USD)" cellTemplate="fobUsd"> </dxi-column>
                <div *dxTemplate="let row of 'fobUsd'">
                  {{ (row.data.fobValueUsd != null ? row.data.fobValueUsd : 0) | number:'1.2-6'}}
                  {{row.data.currency}}
                </div>

                <dxi-column alignment="center" caption="ราคาต่อจำนวน" cellTemplate="unitPrice">
                </dxi-column>
                <div *dxTemplate="let row of 'unitPrice'">
                  {{ (row.data.unitPrice != null ? row.data.unitPrice : 0) | number:'1.2-6'}} {{row.data.currency}}
                </div>
                <dxi-column alignment="center" caption="น้ำหนักเฉพาะต่อหน่วย" cellTemplate="grossWeight">
                </dxi-column>
                <div *dxTemplate="let row of 'grossWeight'">
                  {{ (row.data.grossWeight != null ? row.data.grossWeight : 0) | number:'1.2-6'}}
                  {{row.data.netWeightUnit}}
                </div>
                <dxi-column alignment="center" caption="น้ำหนักรวม" cellTemplate="grossWeightPerUnit">
                </dxi-column>
                <div *dxTemplate="let row of 'grossWeightPerUnit'">
                  {{ (row.data.grossWeight != null ? row.data.grossWeight : 0) | number:'1.2-6'}}
                  {{row.data.netWeightUnit}}
                </div>
                <!-- <dxi-column alignment="center" caption="ราคาต่อจำนวน" cellTemplate="pricePerUnit2">
              </dxi-column>
              <div *dxTemplate="let row of 'pricePerUnit2'">
                {{row.data.netWeight}} {{row.data.currency}}
              </div> -->

              </dx-data-grid>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- ข้อมูลการส่งออก / ข้อมูลการตรวจสอบ -->
      <div class="flex flex-row p10" style="gap: 10px">
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ข้อมูลการส่งออก (Export Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> วันที่จะบรรทุก :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.goodsLoadDate | thaidate}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> เรือใหญ่ที่จะบรรทุก :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.vesselName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ท่าหรือสถานที่ส่งออก :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.porttxt}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ส่งออกโดยทาง :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.transportationModeName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ชื่อพาหนะ / เที่ยว :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.accualVesselName}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 50%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 40%;padding-right: 20px;"> เมืองตราส่ง :
                  </div>
                  <div style="width: 60%;">
                    <div class="f18" style="align-self: center;">{{form.loadedPortName}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1" style="width: 50%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> ประเทศ :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.loadedPortCountryName}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card style="width: 50%">
          <mat-card-header class="fontTitleHeader">
            ข้อมูลการตรวจสอบ (Inspection Information)
          </mat-card-header>
          <mat-card-content class="flex flex-col example-headers-align" style="padding: 16px; gap: 10px;">
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> มาตรฐาน :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.checkResult}}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1" style="width: 100%">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 30%;padding-right: 20px;"> การบรรจุหุ้มห่อ :
                  </div>
                  <div style="width: 70%;">
                    <div class="f18" style="align-self: center;">{{form.checkPackageResult}}</div>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- ผลวิเคราะห์ -->
      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            ผลวิเคราะห์ (Analysis Attachment)
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding: 16px">
            <div class="flex">
              <div class="mat-elevation-z8" style="width: 100%">
                <dx-data-grid id="gridContainer" [dataSource]="dataSourceAtt" [columnAutoWidth]="true"
                  [showBorders]="true" [allowColumnReordering]="true" [showBorders]="true"
                  [noDataText]="'ยังไม่ได้แนบเอกสารผลวิเคราะห์'">
                  <dxi-column alignment="center" dataField="cmms24aFileName" caption="ชื่อเอกสาร">
                  </dxi-column>
                  <dxi-column alignment="center" cellTemplate="documentSize" caption="ขนาดของเอกสาร">
                  </dxi-column>
                  <div *dxTemplate="let row of 'documentSize'">
                    {{row?.data.cmms24aDocumentSize}} กิโลไบต์ (kB)
                  </div>
                  <dxi-column alignment="center" dataField="cmms24aFileExtension" caption="ชนิดเอกสาร">
                  </dxi-column>
                  <dxi-column alignment="center" cellTemplate="action" caption="Action">
                  </dxi-column>
                  <div *dxTemplate="let row of 'action'">
                    <button mat-icon-button (click)="showImg(row?.data)" color="accent">
                      <i class="ti ti-photo-search"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-card-content>
</mat-card>