<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
        <div *ngIf="checkButton('BT046')">
          <!-- <button class="set-btn-unlimited" mat-raised-button
                color="accent" (click)="popupFactory()">ค้นหารายชื่อบริษัท
            </button> -->
        </div>
        <div class="gap-2.5 flex flex-row">
          <button mat-raised-button color="accent" (click)="loadData()">
            <div class="flex items-center">
              <span class="icon-text"><mat-icon svgIcon="heroicons_outline:refresh"></mat-icon>รีเฟรชข้อมูล</span>
            </div>
          </button>
        </div>
      </div>
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px; flex: 0 40%">
          <mat-form-field style="display: flex; flex-flow: row; flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="คำค้นหา"
              (keyup)="applyFilter($event.target.value)" />
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
          <div style="display: flex; align-items: center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #menu="matMenu" yPosition="below" xPosition="before" style="max-width: 50vw ; min-width: 50vw ;">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex flex-row">
                <div class="basis-1/2">
                  <mat-form-field appearance="fill" class="w-full p-2">
                    <mat-label>วันที่เริ่มต้น | Start Date
                    </mat-label>
                    <input matInput [matDatepicker]="startDate" (click)="startDate.open()" readonly type="datetime"
                      [(ngModel)]="search.startDate" [max]="myDate" name="startDateSearch"
                      (dateInput)="datecheck($event)" #startDateSearch="ngModel" class="cursor-pointer">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                    <mat-datepicker #startDate></mat-datepicker>
                  </mat-form-field>
                  <mat-error *ngIf="checkStartDate == false"
                    class="p-1 text-sm text-red-800 absolute">วันที่เริ่มต้นไม่ถูกต้อง</mat-error>
                </div>
                <div class="basis-1/2">
                  <mat-form-field appearance="fill" class="w-full p-2">
                    <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                    <input matInput [matDatepicker]="endDate" (click)="endDate.open(); checkEndDate = true" readonly
                      type="datetime" [(ngModel)]="search.endDate" [max]="myDate" [min]="search.startDate"
                      (dateInput)="endDateCheck($event)" name="endDateSearch" #endDateSearch="ngModel"
                      class="cursor-pointer">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                    <mat-datepicker #endDate></mat-datepicker>
                  </mat-form-field>
                  <mat-error *ngIf="checkEndDate == false"
                    class="p-1 text-sm text-red-800 absolute">วันที่สิ้นสุดไม่ถูกต้อง</mat-error>
                </div>
              </div>
            </div>
            <div class="pl-2 pr-2">
              <div class="flex flex-row">
                <div class="basis-1/2">
                  <form name="searchCompanyForm" [formGroup]="searchCompanyForm" class="w-full p-2">
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1">
                      <mat-label>รหัสบริษัท</mat-label>
                      <input matInput type="text" formControlName="companySearch" [matAutocomplete]="auto"
                        name="companyCode">
                      <mat-autocomplete #auto="matAutocomplete" required (optionSelected)="selectionChange($event)"
                        [panelWidth]="'auto'">
                        <mat-option *ngIf="isLoading">
                          <mat-spinner [diameter]="20"></mat-spinner>
                        </mat-option>
                        <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
                          {{option.display}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </form>
                </div>
                <div class="basis-1/2">
                  <mat-form-field class="w-full p-2">
                    <mat-label>จังหวัด</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา (ภาษาไทยเท่านั้น)" #input
                      [(ngModel)]="search.province" name="province" #province="ngModel"
                      onkeyup="this.value=this.value.replace(/[^ก-ฮะ-ฺเ-ํฯ]/g,'');" />
                    <!-- พิมพ์เฉพาะภาษาไทย สระ ได้ -->
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="pl-2 pr-2">
              <div class="flex flex-row">
                <div class="basis-1/2">
                  <mat-form-field class="w-full  p-2">
                    <mat-label>ชื่อบริษัท (ภาษาไทย)</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา (ภาษาไทยเท่านั้น)" #input
                      [(ngModel)]="search.companyNameTH" name="companyNameTH" #companyNameTH="ngModel"
                      onkeyup="this.value=this.value.replace(/[^ก-๙0-9\s]/g,'');" />
                    <!-- พิมพ์เฉพาะภาษาไทย สระ และตัวเลข ได้ -->
                  </mat-form-field>
                </div>
                <div class="basis-1/2">
                  <mat-form-field class="w-full  p-2">
                    <mat-label>ชื่อบริษัท (ภาษาอังกฤษ)</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา (ภาษาอังกฤษเท่านั้น)" #input
                      [(ngModel)]="search.companyNameEN" name="companyNameEN" #companyNameEN="ngModel"
                      onkeyup="this.value=this.value.replace(/[^a-zA-Z0-9\s]/g,'');" />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="pl-2 pr-2">
              <div class="flex justify-end pt-2">
                <div>
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" (click)="loadData()" style="flex:0 50%;">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8" style="overflow-x:auto;">
    <table mat-table matSort matSortActive="companyNameEN" matSortDirection="asc" matSortDisableClear
      [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="comId" style="width: 70px !important; text-align: center">
        <th mat-header-cell *matHeaderCellDef style="width: 70px !important;" class="justify-start text-center">
          รหัส
        </th>
        <td mat-cell *matCellDef="let element" style="width: 70px !important;" class="justify-start text-center">
          {{ element.taxId }}
        </td>
      </ng-container>
      <ng-container matColumnDef="companyNameTH" style="width: 150px; text-align: left">
        <th mat-header-cell *matHeaderCellDef style="width: 150px;" class="text-left">
          ชื่อบริษัท
        </th>
        <td mat-cell *matCellDef="let element" style="width: 150px;">
          {{ element.companyNameTH }}
        </td>
      </ng-container>
      <ng-container matColumnDef="companyNameEN" style=" text-align: left">
        <th mat-header-cell *matHeaderCellDef>Company Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.companyNameEN }}
        </td>
      </ng-container>
      <ng-container matColumnDef="addressStreet" style="text-align: left">
        <th mat-header-cell *matHeaderCellDef class="text-left">
          ที่อยู่
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.addressStreet }}
        </td>
      </ng-container>
      <ng-container matColumnDef="province" style="text-align: left">
        <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
          จังหวัด
        </th>
        <td mat-cell *matCellDef="let element" class="justify-start text-left">
          {{ element.province }}
        </td>
      </ng-container>
      <ng-container matColumnDef="phone" style="width: 150px; text-align: left">
        <th mat-header-cell *matHeaderCellDef style="width: 150px;" class="justify-start text-left">
          เบอร์โทร
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.phone }}
        </td>
      </ng-container>
      <ng-container matColumnDef="blacklist" style="text-align: left">
        <th mat-header-cell *matHeaderCellDef class="justify-start text-left">
          สถานะ
        </th>
        <td mat-cell *matCellDef="let element" class="justify-start text-left">
          <div class="flex" style="gap: 6px; margin-bottom: -8px;">
            {{
            element.blacklist == "Y"
            ? "Lock"
            : element.blacklist == "N"
            ? "Unlock"
            : element.blacklist
            }}
            <button matTooltip="{{element.lockReason ?? 'ไม่ระบุเหตุผล'}}" matTooltipPosition="above"
              matTooltipClass="custom-tooltip" *ngIf="element.blacklist == 'Y'">
              <mat-icon svgIcon="heroicons_outline:information-circle"></mat-icon>
            </button>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="action" style="width: 50px;">
        <th mat-header-cell *matHeaderCellDef style="width: 50px; text-align: left">
          Action
        </th>
        <td mat-cell *matCellDef="let element; let i = index" style=" width: 50px; text-align: left">
          <div class="flex flex-row" style="justify-content: start;">
            <div *ngIf="checkButton('BT182')">
              <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above" (click)="getDetail(element)">
                <mat-icon svgIcon="heroicons_outline:document-search"></mat-icon>
              </button>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons aria-label="Select page of periodic elements"
      (page)="pageChanged($event)">
    </mat-paginator>
  </div>
</div>