import { NotificationDetailsComponent } from './../layout/common/notifications/notification-details/notification-details.component';
import { ReportInvoiceBymouthComponent } from './component/report/report-invoice-bymouth/report-invoice-bymouth.component';
import { ReportCertificateMs24Component } from './component/report/report-certificate-ms24/report-certificate-ms24.component';
import { ReportCertificateMs13Component } from './component/report/report-certificate-ms13/report-certificate-ms13.component';
import { AddPaymentDetailModalComponent } from './component/payment/add-payment-detail-modal/add-payment-detail-modal.component';
import { AddBillCompanyModalComponent } from './component/payment/add-bill-company-modal/add-bill-company-modal.component';
import { Component, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfigBankInquiryComponent } from "./component/config-bank-inquiry/config-bank-inquiry.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ConfigBankPopupComponent } from "./component/config-bank-inquiry/config-bank-popup/config-bank-popup.component";
import { ConfigRelationshipInquiryComponent } from "./component/config-relationship-inquiry/config-relationship-inquiry.component";
import { ConfigRelationshipPopupComponent } from "./component/config-relationship-inquiry/config-relationship-popup/config-relationship-popup.component";
import { ExportDocInquiryComponent } from "./component/export-doc-inquiry/export-doc-inquiry.component";
import { UserProfileComponent } from "./component/profile/user-profile/user-profile.component";
import { CompanyProfileiComponent } from "./component/profile/company-profile/company-profilei.component";
import { OTwoInquiryComponent } from "./component/o-two/o-two-inquiry/o-two-inquiry.component";
import { CreateO2ModalComponent } from "./component/o-two/create-o2-modal/create-o2-modal.component";
import { CreateO2ManualInquiryComponent } from "./component/o-two/create-o2-manual-inquiry/create-o2-manual-inquiry.component";
import { DevExtremeDesignModule } from "app/shared/devextreme.module";
import { MaterialDesignModule } from "app/shared/materialDesign.module";
import { CreateProductGoodsItemModalComponent } from "./component/o-two/create-product-goods-item-modal/create-product-goods-item-modal.component";
import { RouterModule } from "@angular/router";
import { AlertMessageComponent } from "@bundle/component/alert-message/alert-message.component";
import { ConfirmButtonComponent } from "@bundle/component/confirm-button/confirm-button.component";
import { ConfirmDialogComponent } from "@bundle/component/confirm-dialog/confirm-dialog.component";
import { RedirectDialogComponent } from "@bundle/component/redirect-dialog/redirect-dialog.component";
import { RenewO2InquiryComponent } from "./component/o-two/renew-o2-inquiry/renew-o2-inquiry.component";
import { Ms13InquiryComponent } from "./component/ms13/ms13-inquiry/ms13-inquiry.component";
import { ReceiverInquiryComponent } from "./component/receiver/receiver-inquiry/receiver-inquiry.component";
import { ReceiverCreateComponent } from "./component/receiver/receiver-create/receiver-create.component";
import { ShippingInquilyComponent } from "./component/shipping/shipping-inquiry/shipping-inquiry.component";
import { ShippingDetailCompanyComponent } from "./component/shipping/shipping-detail-company/shipping-detail-company.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { MatTableModule } from "@angular/material/table";
import { GroupInquiryComponent } from "./component/group/group-manage-inquiry/group-inquiry.component";
import { GroupAdjustManagementComponent } from "./component/group/group-adjust-management/group-adjust-management.component";
import { CompanyDetailInquiryComponent } from "./component/company/company-detail-inquiry/company-detail-inquiry.component";
import { ShippingmaskModalComponent } from "./component/ms13/shippingmask-modal/shippingmask-modal.component";
import { DftExporterInquiryComponent } from "./component/dft-exporter/dft-exporter-inquiry/dft-exporter-inquiry.component";
import { DftExporterInformationComponent } from "./component/dft-exporter/dft-exporter-information/dft-exporter-information.component";
import { ItemtypeInquiryComponent } from "./component/itemtype/itemtype-inquiry/itemtype-inquiry.component";
import { ItemtypeAdjustManagementComponent } from "./component/itemtype/itemtype-adjust-management/itemtype-adjust-management.component";
import { CompamyStaffInquiryComponent } from "./component/company/compamy-staff-inquiry/compamy-staff-inquiry.component";
import { CompanyStaffInformationComponent } from "./component/company/company-staff-information-detail/company-staff-information-detail.component";
import { SurveyorMS13InquiryComponent } from "./component/surveyor/surveyor-ms13-inquiry.component";
import { SurveyorAuditPopupComponent } from "./component/surveyor/surveyor-audit-popup/surveyor-audit-popup.component";
import { ConfirmPopupComponent } from "./component/confirm-popup/confirm-popup.component";
import { DFTSurveyorListComponent } from "./component/dft-surveyor/dft-surveyor-list/dft-surveyor-list.component";
import { DFTSurveyorInquiryComponent } from "./component/dft-surveyor/dft-surveyor-inquiry/dft-surveyor-inquiry.component";
import { SurveyorSearchInquiryComponent } from "./component/surveyor-search/surveyor-search-inquiry/surveyor-search-inquiry.component";
import { SurveyorSearchListComponent } from "./component/surveyor-search/surveyor-search-list/surveyor-search-list.component";
import { Ms24InquiryComponent } from "./component/ms24/ms24-inquiry/ms24-inquiry.component";
import { ShortMs24InquiryComponent } from "./component/ms24/short-ms24-inquiry/short-ms24-inquiry.component";
import { CreateMs24InquiryComponent } from "./component/ms24/create-ms24-inquiry/create-ms24-inquiry.component";
import { CreateMs24ModalComponent } from "./component/ms24/create-ms24-modal/create-ms24-modal.component";
import { GroupConfigManagementComponent } from "./component/group/group-config-management/group-config-management.component";
import { StaffRenewApproveInquiryComponent } from "./component/staff-renew/staff-renew-approve-inquiry/staff-renew-approve-inquiry.component";
import { StaffRenewListComponent } from "./component/staff-renew/staff-renew-list/staff-renew-list.component";
import { Ms24ShortInquiryComponent } from "./component/ms24/ms24-short-inquiry/ms24-short-inquiry.component";
import { Ms24RecordStaffInquiryComponent } from "./component/ms24/ms24-record-staff-inquiry/ms24-record-staff-inquiry.component";
import { Ms24RecordStaffDetailComponent } from "./component/ms24/ms24-record-staff-detail/ms24-record-staff-detail.component";
import { Ms24RecordLogDetailComponent } from "./component/ms24/ms24-record-log-detail/ms24-record-log-detail.component";
import { Ms24ReportExportComponent } from "./component/ms24/ms24-report-export/ms24-report-export.component";
import { Ms13ReportExportComponent } from "./component/ms13/ms13-report-export/ms13-report-export.component";
import { StaffHistoryMs13ListComponent } from "./component/staff-history/staff-history-ms13-list/staff-history-ms13-list.component";
import { StaffHistoryMs13ModalComponent } from "./component/staff-history/staff-history-ms13-modal/staff-history-ms13-modal.component";
import { SurveyorModalComponent } from "./component/ms13/surveyor-modal/surveyor-modal.component";
import { ImageModalComponent } from "./component/ms13/Image-modal/Image-modal.component";
import { ExportPdfModalComponent } from "./component/ms13/export-pdf-modal/export-pdf-modal.component";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { DailyReportInquiryComponent } from "./component/report/daily-report-inquiry/daily-report-inquiry.component";
import { MatDividerModule } from "@angular/material/divider";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list";
import { MatSortModule } from "@angular/material/sort";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DxButtonModule, DxChartModule, DxDiagramModule, DxFileManagerModule, DxFileUploaderModule, DxHtmlEditorModule, DxLoadPanelModule, DxLookupModule, DxPieChartModule, DxPivotGridModule, DxProgressBarModule, DxTreeListModule, DxTreeViewModule, DxGalleryModule, DxLinearGaugeModule, DxTemplateModule, DxDataGridModule } from "devextreme-angular";
import { NewsModalComponent } from "app/page/news-modal/news-modal.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { UserFilterFormComponent } from "./component/user-filter-form/user-filter-form.component";
import { UserRoleFilterFormComponent } from "./component/user-role-filter-form/user-role-filter-form.component";
import { UserRoleListComponent } from "./component/user-role-list/user-role-list.component";
import { UserRoleModalComponent } from "./component/user-role-modal/user-role-modal.component";
import { UserRoleFormComponent } from "./component/user-role-form/user-role-form.component";
import { UserModalComponent } from "./component/user-modal/user-modal.component";
import { UserFormComponent } from "./component/user-form/user-form.component";
import { UserGroupModalComponent } from "./component/user-group-modal/user-group-modal.component";
import { UserGroupFormComponent } from "./component/user-group-form/user-group-form.component";
import { UserListComponent } from "./component/user-list/user-list.component";
import { UserManagementTablesInquiryComponent } from "./component/user-management-inquiry/user-management-tables-inquiry/user-management-tables-inquiry.component";
import { UserManagementComponent } from "./component/user-management-inquiry/user-management-inquiry/user-management-inquiry.component";
import { UserManagementBLComponent } from "./component/profile/user-management/user-management.component";
import { ListRenewO2InquiryComponent } from "./component/o-two/list-renew-o2-inquiry/list-renew-o2-inquiry.component";
import { UploadDocumentDatagridComponent } from "./component/upload-document-datagrid/upload-document-datagrid.component";
import { AmountOfWeightTouseMs13ModalComponent } from "./component/ms13/amount-of-weight-touse-ms13-modal/amount-of-weight-touse-ms13-modal.component";
import { SurveyorCheckResultComponent } from "./component/ms13/surveyor-check-result/surveyor-check-result.component";
import { UserGroupFilterFormComponent } from "./component/user-group-filter-form/user-group-filter-form.component";
import { UserGroupListComponent } from "./component/user-group-list/user-group-list.component";
import { ButtonFilterPermissionComponent } from "./component/button-filter-permission/button-filter-permission.component";
import { ButtonPermissionComponent } from "./component/button-permission/button-permission.component";
import { MenuFilterPermissionComponent } from "./component/menu-filter-permission/menu-filter-permission.component";
import { MenuPermissionComponent } from "./component/menu-permission/menu-permission.component";
import { EmailConfigInquiryComponent } from "./component/email-config-inquiry/email-config-inquiry.component";
import { EmailConfigAgreementDialogComponent } from "./component/email-config-inquiry/email-config-agreement-dialog/email-config-agreement-dialog.component";
import { UnitControlInquiryComponent } from "./component/unit-control-inquiry/unit-control-inquiry.component";
import { UnitControlPopupInquiryComponent } from "./component/unit-control-inquiry/unit-control-popup-inquiry/unit-control-popup-inquiry.component";
import { ConfigUserAllComponent } from "./component/config-user-all/config-user-all.component";
import { WeightControlInquiryComponent } from "./component/weight-control-inquiry/weight-control-inquiry.component";
import { WeightControlPopupInquiryComponent } from "./component/weight-control-inquiry/weight-control-popup-inquiry/weight-control-popup-inquiry.component";
import { BankCodeManageInquiryComponent } from "./component/bank-code-manage-inquiry/bank-code-manage-inquiry.component";
import { BankCodeManagePopupComponent } from "./component/bank-code-manage-inquiry/bank-code-manage-popup/bank-code-manage-popup.component";
import { ProvinceManageInquiryComponent } from "./component/province-manage-inquiry/province-manage-inquiry.component";
import { ProvinceManagePopupComponent } from "./component/province-manage-inquiry/province-manage-popup/province-manage-popup.component";
import { CompanyProfileFormComponent } from "./component/company-profile-form/company-profile-form.component";
import { CompanyMemberHistoryGridComponent } from "./component/company-profile-form/company-member-history-grid/company-member-history-grid.component";
import { CompanyAuthorizedSignatureGridComponent } from "./component/company-profile-form/company-authorized-signature-grid/company-authorized-signature-grid.component";
import { CompanyAddressGridComponent } from "./component/company-profile-form/company-address-grid/company-address-grid.component";
import { CompanyListInquiryComponent } from "./component/company-list-inquiry/company-list-inquiry.component";
import { ConnectMrDialogComponent } from "./component/connect-mr-dialog/connect-mr-dialog.component";
import { AddCompanyMemberDialogComponent } from "./component/add-company-member-dialog/add-company-member-dialog.component";
import { StepPriceInquiryComponent } from "./component/step-price-inquiry/step-price-inquiry.component";
import { StepPricePopupInquiryComponent } from "./component/step-price-inquiry/step-price-popup-inquiry/step-price-popup-inquiry.component";
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { AddPaymentRequestModalComponent } from './component/payment/add-payment-request-modal/add-payment-request-modal.component';
import { CreatePaymentListInquiryComponent } from './component/payment/create-payment-list-inquiry/create-payment-list-inquiry.component';
import { PullJobModalComponent } from './component/payment/pull-job-modal/pull-job-modal.component';
import { AddOtherPaymentRequestModalComponent } from './component/payment/add-other-payment-request-modal/add-other-payment-request-modal.component';
import { ListPaymentInquiryComponent } from './component/payment/list-payment-inquiry/list-payment-inquiry.component';
import { PrintInquiryComponent } from './component/print/print-inquiry/print-inquiry.component';
import { ThaidatePipe } from 'app/pipe/thaidate.pipe';
import { ReCalculateFobRateComponent } from './component/o-two/re-calculate-fob-rate/re-calculate-fob-rate.component';
import { FormatNumberPipe } from 'app/pipe/numberformat.pipe';
import { O2ViewerComponent } from './component/ms13/o2-viewer/o2-viewer.component';
import { CreateShortModalComponent } from './component/ms24/create-short-modal/create-short-modal.component';
import { ConfirmPopupEtaxComponent } from "./component/confirm-popup-etax/confirm-popup-etax.component";
import { ConfigReceiverCompanyComponent } from './component/config-receiver-company/config-receiver-company.component';
import { AddReceiverCompanyModalComponent } from './component/config-receiver-company/add-receiver-company-modal/add-receiver-company-modal.component';
import { AmountOfWeightTouseMs24ModalComponent } from './component/ms13/amount-of-weight-touse-ms24-modal/amount-of-weight-touse-ms24-modal.component';
import { AddReceiverFormHelperComponent } from './component/config-receiver-company/add-receiver-form-helper/add-receiver-form-helper.component';
import { PasswordChangeComponent } from './component/profile/user-management/password-change/password-change.component';
import { NswTrackingInquiryComponent } from './component/tracking/nsw-tracking-inquiry/nsw-tracking-inquiry.component';
import { DftTrackingInquiryComponent } from './component/tracking/dft-tracking-inquiry/dft-tracking-inquiry.component';
import { NswTrackingResponseModalComponent } from './component/tracking/nsw-tracking-response-modal/nsw-tracking-response-modal.component';
import { DftTrackingResponseComponent } from './component/tracking/dft-tracking-response/dft-tracking-response.component';
import { ReportDataMs24Component } from './component/report/report-data-ms24/report-data-ms24.component';
import { ReportDataMs24ByMonthComponent } from './component/report/report-data-ms24-by-month/report-data-ms24-by-month.component';
import { ReportSummaryPrintMs24Component } from './component/report/report-summary-print-ms24/report-summary-print-ms24.component';
import { ReportControlMs24Component } from './component/report/report-control-ms24/report-control-ms24.component';
import { ReportSummaryApproveMs24Component } from './component/report/report-summary-approve-ms24/report-summary-approve-ms24.component';
import { ReportProductWorkloadSummaryComponent } from './component/report-payment/report-product-workload-summary/report-product-workload-summary.component';
import { ReportSummarizingAmountOfWeightMs24PaidPaymentComponent } from './component/report-payment/report-summarizing-amount-of-weight-ms24-paid-payment/report-summarizing-amount-of-weight-ms24-paid-payment.component';
import { ReportSummarizingDaysAndNumberMs24ReceivedPaymentComponent } from './component/report-payment/report-summarizing-days-and-number-ms24-received-payment/report-summarizing-days-and-number-ms24-received-payment.component';
import { ReportSummarizingIssuanceFormsProductComponent } from './component/report-payment/report-summarizing-issuance-forms-product/report-summarizing-issuance-forms-product.component';
import { ReportSummarizingMs24PaidPaymentComponent } from './component/report-payment/report-summarizing-ms24-paid-payment/report-summarizing-ms24-paid-payment.component';
import { ReportSummarizingPaidPaymentComponent } from './component/report-payment/report-summarizing-paid-payment/report-summarizing-paid-payment.component';
import { ReportFilterComponent } from './component/report/report-filter/report-filter.component';
import { RenewMs13InquiryComponent } from './component/ms13/renew-ms13-inquiry/renew-ms13-inquiry.component';
import { O2DocViewerComponent } from './component/o-two/o2-doc-viewer/o2-doc-viewer.component';
import { ListRenewMs13InquiryComponent } from './component/ms13/list-renew-ms13-inquiry/list-renew-ms13-inquiry.component';
import { RenewMs24InquiryComponent } from './component/ms24/renew-ms24-inquiry/renew-ms24-inquiry.component';
import { ListRenewMs24InquiryComponent } from './component/ms24/list-renew-ms24-inquiry/list-renew-ms24-inquiry.component';
import { BlacklistInquiryListComponent } from './component/blacklist-inquiry-list/blacklist-inquiry-list.component';
import { BlacklistModalComponent } from './component/blacklist-modal/blacklist-modal.component';
import { ReportExportByCountryComponent } from './component/report/report-export-by-country/report-export-by-country.component';
import { ReportTop10ShipoutByCountryComponent } from './component/report/report-top10-shipout-by-country/report-top10-shipout-by-country.component';
import { ReportTop10ShipoutByCompanyComponent } from './component/report/report-top10-shipout-by-company/report-top10-shipout-by-company.component';
import { ReportCountryExportComponent } from './component/report/report-country-export/report-country-export.component';
import { ReportCompanyInSystemComponent } from './component/report/report-company-in-system/report-company-in-system.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Ms13ViewerComponent } from './component/ms13/ms13-viewer/ms13-viewer.component';
import { Ms24ViewerComponent } from './component/ms24/ms24-viewer/ms24-viewer.component';
import { Ms13RejectReasonModalComponent } from './component/ms13/ms13-reject-reason-modal/ms13-reject-reason-modal.component';
import { Ms24RejectReasonComponent } from './component/ms24/ms24-reject-reason/ms24-reject-reason.component';
import { O2RejectReasonModalComponent } from './component/o-two/o2-reject-reason-modal/o2-reject-reason-modal.component';
import { Ms24PrintUserModalComponent } from './component/ms24/ms24-print-user-modal/ms24-print-user-modal.component';
import { Ms13DocumentActionLogInquiryComponent } from './component/ms13/ms13-document-action-log-inquiry/ms13-document-action-log-inquiry.component';
import { LogDocumentActionDetailModalComponent } from './component/log-document-action-detail-modal/log-document-action-detail-modal.component';
import { Ms24DocumentActionLogInquiryComponent } from './component/ms24/ms24-document-action-log-inquiry/ms24-document-action-log-inquiry.component';
import { Ms13RecordLogDetailComponent } from './component/ms13/ms13-record-log-detail/ms13-record-log-detail.component';
import { ReportMs13FileComplaintComponent } from './component/report/report-ms13-file-complaint/report-ms13-file-complaint.component';
import { ReportMs13CompleteMs24Component } from './component/report/report-ms13-complete-ms24/report-ms13-complete-ms24.component';
import { ReportMs13IncompleteMs24Component } from './component/report/report-ms13-incomplete-ms24/report-ms13-incomplete-ms24.component';
import { ReportMs13SurveyorIncompleteMs24Component } from './component/report/report-ms13-surveyor-incomplete-ms24/report-ms13-surveyor-incomplete-ms24.component';
import { ReportMs13IscommitComponent } from './component/report/report-ms13-iscommit/report-ms13-iscommit.component';
import { ReportMs13FilecontrolComponent } from './component/report/report-ms13-filecontrol/report-ms13-filecontrol.component';
import { ReportMs13ApprovePerCompanyComponent } from './component/report/report-ms13-approve-per-company/report-ms13-approve-per-company.component';
import { ReportMs13PrintListComponent } from './component/report/report-ms13-print-list/report-ms13-print-list.component';
import { NswTrackingRoundListComponent } from './component/tracking/nsw-tracking-round-list/nsw-tracking-round-list.component';
import { DftMessageReportComponent } from './component/tracking/dft-message-report/dft-message-report.component';

import { ReportProductWorkloadSummaryPaymentComponent } from './component/report-payment/report-Product-Workload-Summary-Payment/report-Product-Workload-Summary-Payment.component';
import { DftTrackingRoundListComponent } from './component/tracking/dft-tracking-round-list/dft-tracking-round-list.component';
import { NotiPopupComponent } from './component/noti-popup/noti-popup.component';

const businessComps = [
  AlertMessageComponent,
  ConfirmButtonComponent,
  ConfirmDialogComponent,
  RedirectDialogComponent,
  ConfigBankInquiryComponent,
  ConfigBankPopupComponent,
  ConfigRelationshipInquiryComponent,
  ConfigRelationshipPopupComponent,
  ExportDocInquiryComponent,
  UserProfileComponent,
  UserManagementBLComponent,
  CompanyProfileiComponent,
  OTwoInquiryComponent,
  CreateO2ModalComponent,
  CreateO2ManualInquiryComponent,
  CreateProductGoodsItemModalComponent,
  RenewO2InquiryComponent,
  ShippingInquilyComponent,
  ShippingDetailCompanyComponent,
  ReceiverCreateComponent,
  ReceiverInquiryComponent,
  GroupInquiryComponent,
  GroupAdjustManagementComponent,
  Ms13InquiryComponent,
  ReceiverInquiryComponent,
  ReceiverCreateComponent,
  CompanyDetailInquiryComponent,
  ShippingInquilyComponent,
  ShippingDetailCompanyComponent,
  ShippingmaskModalComponent,
  DftExporterInquiryComponent,
  ConfirmPopupComponent,
  SurveyorAuditPopupComponent,
  SurveyorMS13InquiryComponent,
  DftExporterInformationComponent,
  ItemtypeInquiryComponent,
  ItemtypeAdjustManagementComponent,
  CompamyStaffInquiryComponent,
  CompanyStaffInformationComponent,
  DFTSurveyorListComponent,
  DFTSurveyorInquiryComponent,
  SurveyorSearchInquiryComponent,
  SurveyorSearchListComponent,
  Ms24InquiryComponent,
  ShortMs24InquiryComponent,
  CreateMs24InquiryComponent,
  CreateMs24ModalComponent,
  GroupConfigManagementComponent,
  StaffRenewListComponent,
  StaffRenewApproveInquiryComponent,
  Ms24ShortInquiryComponent,
  Ms24RecordStaffInquiryComponent,
  Ms24RecordStaffDetailComponent,
  Ms24RecordLogDetailComponent,
  Ms13RecordLogDetailComponent,
  Ms24ReportExportComponent,
  Ms13ReportExportComponent,
  StaffHistoryMs13ListComponent,
  StaffHistoryMs13ModalComponent,
  SurveyorModalComponent,
  ImageModalComponent,
  DailyReportInquiryComponent,
  ReportDataMs24Component,
  ReportDataMs24ByMonthComponent,
  ReportControlMs24Component,
  ReportSummaryApproveMs24Component,
  ReportSummaryPrintMs24Component,
  ReportCertificateMs24Component,
  ReportCertificateMs13Component,
  ReportFilterComponent,
  ReportExportByCountryComponent,
  ReportTop10ShipoutByCountryComponent,
  ReportTop10ShipoutByCompanyComponent,
  ReportCountryExportComponent,
  ReportCompanyInSystemComponent,
  ExportPdfModalComponent,
  NewsModalComponent,
  ListRenewO2InquiryComponent,
  UploadDocumentDatagridComponent,
  AmountOfWeightTouseMs13ModalComponent,
  AmountOfWeightTouseMs24ModalComponent,
  SurveyorCheckResultComponent,
  ConfigUserAllComponent,
  UserFilterFormComponent,
  UserRoleFilterFormComponent,
  UserRoleListComponent,
  UserRoleModalComponent,
  UserRoleFormComponent,
  UserModalComponent,
  UserRoleFormComponent,
  UserFormComponent,
  UserGroupModalComponent,
  UserGroupFormComponent,
  UserListComponent,
  UserManagementTablesInquiryComponent,
  UserManagementComponent,
  ListRenewO2InquiryComponent,
  UserGroupFilterFormComponent,
  UserGroupListComponent,
  ButtonFilterPermissionComponent,
  ButtonPermissionComponent,
  MenuFilterPermissionComponent,
  MenuPermissionComponent,
  EmailConfigInquiryComponent,
  EmailConfigAgreementDialogComponent,
  UnitControlInquiryComponent,
  UnitControlPopupInquiryComponent,
  WeightControlInquiryComponent,
  WeightControlPopupInquiryComponent,
  BankCodeManageInquiryComponent,
  BankCodeManagePopupComponent,
  ProvinceManageInquiryComponent,
  ProvinceManagePopupComponent,
  CompanyProfileFormComponent,
  CompanyMemberHistoryGridComponent,
  CompanyAuthorizedSignatureGridComponent,
  CompanyAddressGridComponent,
  CompanyListInquiryComponent,
  ConnectMrDialogComponent,
  AddCompanyMemberDialogComponent,
  StepPriceInquiryComponent,
  StepPricePopupInquiryComponent,

  AddBillCompanyModalComponent,
  AddPaymentDetailModalComponent,
  AddOtherPaymentRequestModalComponent,
  AddPaymentRequestModalComponent,
  CreatePaymentListInquiryComponent,
  ListPaymentInquiryComponent,
  PullJobModalComponent,
  PrintInquiryComponent,
  ThaidatePipe,
  FormatNumberPipe,
  ReCalculateFobRateComponent,
  O2ViewerComponent,
  CreateShortModalComponent,
  Ms13RejectReasonModalComponent,
  Ms24RejectReasonComponent,
  O2RejectReasonModalComponent,
  Ms24PrintUserModalComponent,

  ConfirmPopupEtaxComponent,
  ConfigReceiverCompanyComponent,
  AddReceiverCompanyModalComponent,
  AddReceiverFormHelperComponent,
  PasswordChangeComponent,
  NswTrackingInquiryComponent,
  DftTrackingInquiryComponent,
  NswTrackingResponseModalComponent,
  DftTrackingResponseComponent,
  NswTrackingRoundListComponent,
  DftMessageReportComponent,
  DftTrackingRoundListComponent,
  RenewMs13InquiryComponent,
  O2DocViewerComponent,
  ListRenewMs13InquiryComponent,
  RenewMs24InquiryComponent,
  ListRenewMs24InquiryComponent,
  Ms13ViewerComponent,
  Ms24ViewerComponent,
  Ms13DocumentActionLogInquiryComponent,
  Ms24DocumentActionLogInquiryComponent,
  LogDocumentActionDetailModalComponent,

  ReportProductWorkloadSummaryComponent,
  ReportSummarizingAmountOfWeightMs24PaidPaymentComponent,
  ReportSummarizingDaysAndNumberMs24ReceivedPaymentComponent,
  ReportSummarizingIssuanceFormsProductComponent,
  ReportSummarizingMs24PaidPaymentComponent,
  ReportSummarizingPaidPaymentComponent,
  ReportMs13FileComplaintComponent,
  ReportMs13CompleteMs24Component,
  ReportMs13IncompleteMs24Component,
  ReportMs13SurveyorIncompleteMs24Component,
  ReportMs13IscommitComponent,
  ReportMs13FilecontrolComponent,
  ReportMs13ApprovePerCompanyComponent,
  ReportMs13PrintListComponent,

  BlacklistInquiryListComponent,
  BlacklistModalComponent,

  ReportProductWorkloadSummaryPaymentComponent,

  NotiPopupComponent, 
  ReportInvoiceBymouthComponent,
  NotificationDetailsComponent  
];

const importComp = [
  FormsModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatStepperModule,
  ReactiveFormsModule,
  RouterModule,
  MatDialogModule,
  MatProgressBarModule,
  MatDatepickerModule,
  ScrollingModule,
  MatIconModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatSelectModule,
  MatPaginatorModule,
  MatTableModule,
  MatMenuModule,
  MatAutocompleteModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatDatepickerModule,
  MatRadioModule,
  PdfViewerModule,
  DxButtonModule,
  DxChartModule,
  DxDiagramModule,
  DxFileManagerModule,
  DxFileUploaderModule,
  DxHtmlEditorModule,
  DxLoadPanelModule,
  DxLookupModule,
  DxPieChartModule,
  DxPivotGridModule,
  DxProgressBarModule,
  DxTreeListModule,
  DxTreeViewModule,
  DxGalleryModule,
  DxLinearGaugeModule,
  DxTemplateModule,
  DxDataGridModule,
  MatDividerModule,
  MatExpansionModule,
  MatListModule,
  MatTabsModule,
  MatTooltipModule,
  MatSortModule,
  FlexLayoutModule,
  MatButtonToggleModule,
  NgxMaterialTimepickerModule, 
  
]

@NgModule({
  imports: [
    CommonModule,
    DevExtremeDesignModule,
    MaterialDesignModule,
    RouterModule,
    importComp,
  ],
  declarations: [businessComps],
  exports: [
    businessComps,
    CommonModule,
    FormsModule,
    DevExtremeDesignModule,
    MaterialDesignModule,
    RouterModule,
    importComp,
  ],
})
export class BusinessModule { }
