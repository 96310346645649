<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <!-- <app-head-o2 style="width: 100%;" [type]="'ms24'"></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
        <!-- Mockup TypeUser Surveyor  -->
        <!-- <button class="set-btn-unlimited" mat-raised-button color="accent" (click)="createMs24($event)">
          สร้างใบ มส.24
        </button> -->
        <button mat-raised-button style="width: 70px;font-size: 1.5em;" matTooltip="รีเฟรชข้อมูล" matTooltipPosition="above" (click)="onRefresh()">
          <i class="ti ti-refresh"></i>
        </button>
      </div>
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">
          <!-- <h4>records</h4> -->
          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput
              placeholder="เลขที่ใบรับรอง (มส.24) / เลขที่คำร้อง (มส.13) / เลขที่ใบอนุญาต (อ.2)" matInput #input
              [(ngModel)]="searchString" name="searchText" #search="ngModel" (keyup)="applyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu" (menuClosed)="menuClosed()">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>วันหมดอายุ</mat-label>
                    <input autocomplete="off" placeholder="วันหมดอายุ" matInput [matDatepicker]="exDate"
                      [(ngModel)]="searchForm.expireDate" name="expireDate" #expireDate="ngModel" readonly>
                    <mat-datepicker-toggle matSuffix [for]="exDate"></mat-datepicker-toggle>
                    <mat-datepicker #exDate></mat-datepicker>
                    <mat-error>กรุณาระบุ วันหมดอายุ</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทเรือ</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.vesselName" name="vesselName" #vesselName="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ใบรับรองเลขที่</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.docNo" name="docNo" #docNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่คำร้อง</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.ms13No" name="ms13No" #ms13No="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่ใบอนุญาต อ.2</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.o2No" name="o2No" #o2No="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทสินค้า</mat-label>
                    <mat-select [(ngModel)]="searchForm.productId" name="productId" #productId="ngModel" ngmodel>
                      <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>ประเภทสินค้า</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>สถานะ</mat-label>
                    <mat-select [(ngModel)]="searchForm.statusId" name="statusId" #statusId="ngModel" ngmodel>
                      <mat-option *ngFor="let status of statusList" [value]="status.statusId">
                        {{status.statusName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>สถานะ</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div class="flex flex-row">
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" (click)="searchOption()" style="flex:0 50%;">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8 ">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    <table mat-table matSort style="width: 100%;" [dataSource]="dataSource"
      (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ลำดับ </th>
        <td mat-cell *matCellDef="let i = index">
          {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
        </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ผู้ส่งออก</th>
        <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.companyName}} </td>
      </ng-container>

      <ng-container matColumnDef="ms13No">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">เลขที่คำร้อง(13)</th>
        <td mat-cell *matCellDef="let element"> {{element.ms13No}} </td>
      </ng-container>

      <ng-container matColumnDef="o2No">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">เลขที่ใบอนุญาต อ.2</th>
        <td mat-cell *matCellDef="let element"> {{element?.o2No}} </td>
      </ng-container>

      <ng-container matColumnDef="docNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ใบรับรองเลขที่(24)</th>
        <td mat-cell *matCellDef="let element"> {{element.docNo}} </td>
      </ng-container>

      <ng-container matColumnDef="productId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ประเภทสินค้า</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="expireDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">วันที่หมดอายุ</th>
        <td mat-cell *matCellDef="let element"> {{(element.expireDate | date:'yyyy-MM-dd') || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="vesselName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">เรือที่จะบรรทุก</th>
        <td mat-cell *matCellDef="let element"> {{element.vesselName}} </td>
      </ng-container>

      <ng-container matColumnDef="statusCode">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">สถานะ</th>
        <td mat-cell *matCellDef="let element"> {{element.statusCode}} </td>
      </ng-container>

      <ng-container matColumnDef="invoiceNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">เลขที่ใบเสร็จ</th>
        <td mat-cell *matCellDef="let element"> {{element.invoiceNo}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element;let i = index">
          <!-- -แสดงเฉพาะ item ที่ TAB_STATUS.TSU_ID = MST_O2_HEAD. TSU_ID (‘’1’’),(“5”) -สถานะ Draft -สถานะ Available -->
          <!-- <button *ngIf="(element.status == 2 || element.status == 3 || element.status == 7 || element.status == 8 || element.status == 5) && checkButton('BT024') && (element.printLock == 'N' || element.printLock == null)"
           mat-icon-button matTooltip="Export มส.24" (click)="exportMs24(element)" matTooltipPosition="above">
            <i class="ti ti-printer"></i>
          </button> -->
          <!-- <button mat-icon-button matTooltip="รายละเอียด" *ngIf="checkButton('BT101')" routerLink="/page/o2/create-ms24" -->
          <button mat-icon-button matTooltip="รายละเอียด" *ngIf="element.status != 12" routerLink="/page/o2/create-ms24"
            [queryParams]="{ 
              source : 'M' , 
              page :'ms24' , 
              product:  element.productId , 
              status : element.status , 
              type : 'Detail', 
              id: element.id, 
              refNo : element.refNo,
              vesselName : element.vesselName
            }" matTooltipPosition="above">
            <i class="ti ti-file-description"></i>
          </button>
          <button mat-icon-button matTooltip="แก้ไข"
            *ngIf="checkButton('BT104') && (element.status == 1 || element.status == 4 || element.status == 14)"
            routerLink="/page/o2/create-ms24" [queryParams]="{ 
              source : 'M' , 
              page :'ms24' , 
              product:  element.productId , 
              status : element.status , 
              type : 'Edit', 
              id: element.id, 
              refNo : element.refNo,
              vesselName : element.vesselName
            }" matTooltipPosition="above">
            <i class="ti ti-edit"></i>
          </button>
          <button mat-icon-button matTooltip="ประวัติเอกสาร" *ngIf="element.status > 1"
            (click)="openDocumentHistory(element)" matTooltipPosition="above">
            <i class="ti ti-history"></i>
          </button>
          <button mat-icon-button matTooltip="ต่ออายุ"
            *ngIf="checkButton('BT102') && (element.status == 5 || element.status == 7) && element.isRenewable"
            (click)="renewMs24(element)" matTooltipPosition="above">
            <i class="ti ti-reload"></i>
          </button>
          <button mat-icon-button matTooltip="ทำ Short" *ngIf="checkButton('BT103') && element.status == 9"
            matTooltipPosition="above" (click)="addShort(element)">
            <i class="ti ti-section"></i>
          </button>
          <button mat-icon-button matTooltip="ลบ"
            *ngIf="checkButton('BT104') && (element.status == 1 || element.status == 4 || element.status == 12 || element.status == 14)"
            (click)="deleteItem(element)" matTooltipPosition="above">
            <i class="ti ti-trash"></i>
          </button>
          <button mat-icon-button matTooltip="SEND DFT" *ngIf="element.status == 5 || element.status == 9"
            (click)="sendDft(element)">
            <i class="ti ti-replace"></i>
          </button>
          <!-- <button mat-icon-button matTooltip="สร้างใบ มส.24" (click)="deleteItem(element)" matTooltipPosition="above">
            <div class="btnCircle">
              <small>
                24
              </small>
            </div>
          </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" (page)="_page($event)" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>

</div>