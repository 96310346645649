import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { SelectionModel } from '@angular/cdk/collections';
import { IResGetDataMs24ShortList } from "app/business/interface/app/app-ms24.interface";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { MAT_MOMENT_DATE_FORMATS,MomentDateAdapter,MAT_MOMENT_DATE_ADAPTER_OPTIONS,} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import moment from "moment";


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-ms24-short-inquiry',
  templateUrl: './ms24-short-inquiry.component.html',
  styleUrls: ['./ms24-short-inquiry.component.css'],
  providers: [
    { provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
})
export class Ms24ShortInquiryComponent implements OnInit {

  searchForm: any ={};
  form: any = {};
  slidetoggerDetail:string = " ";
  message :string;
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  statusType : string;
  displayedColumns: string[] = [
    "m24HeadNoResponse",
    "dateOldResponse",
    "m24HeadCompanyNameResponse",
    "m24HeadDetailResponse",
    "m24ShortStatusResponse",
    "action",
  ];
  dataView :IResGetDataMs24ShortList[]=[];
  AdminSearch : string = '';


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppMs24Service: AppMs24Service,
    private ConfirmDialogService: ConfirmDialogService,
    private AlertMessageService : AlertMessageService
  ) { 

  }

  ngOnInit() {
    this.loadData();
  }
  async loadData(): Promise<void> {
    //send data to api
    const dateSelect = moment(this.searchForm.selectedDate).format('YYYY-MM-DD');
    const formData = {
      dateOldRequest :  !this.searchForm.selectedDate ? undefined : dateSelect,
      m24HeadNoRequest : this.searchForm.ms24headno,
      m24HeadCompanyNameRequest : this.searchForm.companyname,
    };
    this.AppMs24Service.getMs24Short(formData).then((res) => {
      if(res){
        this.dataView = res;
        this.dataSource = new MatTableDataSource(this.dataView);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  stopPropagation(event) {
    event.stopPropagation();
  }
  async Adjustms24StatusType(dataInquiry,statusType) {
    const formData = {
      idShortRequest :dataInquiry.idShort,
      M24ShortStatusRequest : statusType,
      idHeaderRequest :dataInquiry.idHeader,
      M24HeadCompanyNameRequest :dataInquiry.m24HeadCompanyNameResponse,
    };
    await this.AppMs24Service.updateMs24ShortStatus(formData).then((res)=>{ 
      this.message = res;
      this.loadData();
    });
    await this.AlertMessageService.info({ title: '', message: this.message  });
  }

}
