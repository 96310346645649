<div style="display: flex; flex-flow: column; height: 100%; gap: 30px" class="flex flex-col h-full">
  <!-- <app-head-o2 style="width: 100%"></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <!--**********Tab Menubar**********-->
    <div class="menu">
      <div class="mat-button">
        <button mat-raised-button color="accent" (click)="AdjustGroupName(0)" *ngIf="typeUser != 3">
          <div style="display: flex; align-items: left">
            <span>Add</span>
          </div>
        </button>
        <button mat-raised-button color="accent" 
          routerLink="/page/group/groupmanagement"
          *ngIf="typeUser == 3">
          <div style="display: flex; align-items: left">
            <span>Add</span>
          </div>
        </button>
      </div>
      <div
        style="
          display: flex;
          flex-flow: row;
          gap: 10px;
          flex: 1;
          justify-content: flex-end;
        "
      >
        <div class="mat-button" style="gap: 10px; flex: 0 40%">
          <mat-form-field
            style="display: flex; flex-flow: row; flex: 1"
            class="my-form-field"
          >
            <input
              autocomplete="off"
              type="text"
              matInput
              placeholder="คำค้นหา"
              (keyup)="applyFilter($event.target.value)"
            />
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!--**********Button search**********-->
        <button 
          mat-raised-button 
          color="accent" 
          [matMenuTriggerFor]="myMenu"
          mat-button
          >
          <div style="display: flex; align-items: center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <!--**********Menu for button search**********-->
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)"  (keydown.tab)="stopPropagation($event)">
            <form (ngSubmit)="searchData()">
              <div class="pl-2 pr-2">
                <div class="flex flex-row">
                  <div class="basis-full">
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>Group Name</mat-label>
                      <input
                        autocomplete="off"
                        type="text"
                        matInput
                        placeholder="คำค้นหา"
                        [(ngModel)]="searchForm.groupName"
                        name="groupName"
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="pl-2 pr-2">
                <div class="flex flex-row">
                  <div class="basis-full">
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>Admin Group</mat-label>
                      <mat-select [(ngModel)]="searchForm.adminGroup" name="adminGroup">
                        <mat-option value="">แสดงทั้งหมด</mat-option>
                        <mat-option value="Y">Y</mat-option>
                        <mat-option value="N">N</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div>
                  <button
                    mat-raised-button
                    color="accent"
                    type="submit"
                    style="flex: 0 50%"
                  >
                    ค้นหา
                  </button>
                </div>
              </div>
            </form>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <!--**********Table Shipping Data**********-->
  <div class="mat-elevation-z8">
    <table
      mat-table
      matSort
      (matSortChange)="announceSortChange($event)"
      [dataSource]="dataSource"
    >
      <!--**********1.Group Name**********-->
      <ng-container matColumnDef="groupName">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          style="text-align: center"
        >
          Group Name
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.groupName }}
        </td>
      </ng-container>
      <!--**********2.Admin Group**********-->
      <ng-container matColumnDef="adminGroup">
        <th
          mat-header-cell
          mat-sort-header
          *matHeaderCellDef
          style="text-align: center"
        >
          Admin Group
        </th>
        <td mat-cell *matCellDef="let element" 
        style="text-align: center"
        >{{ element.adminGroup }}</td>
      </ng-container>
      <!--**********3.Action button**********-->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">
          Action
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <div *ngIf="typeUser != 3">
            <button
            mat-icon-button
            matTooltip="แก้ไข"
            matTooltipPosition="above"
            (click)="AdjustGroupName(element)"
            >
            <mat-icon>edit</mat-icon>
            </button>
            <button
              mat-icon-button
              matTooltip="ลบ"
              (click)="deleteItem(element)"
              matTooltipPosition="above"
            >
              <mat-icon>delete</mat-icon>
            </button>
          </div>
          <div *ngIf="typeUser == 3 && element.companyId == 2 ">
            <button
            mat-icon-button
            matTooltip="แก้ไข"
            matTooltipPosition="above"
            routerLink="/page/group/groupmanagement"
            [queryParams]="{ ID: element.groupId }"
          >
            <mat-icon>edit</mat-icon>
          </button>
          <button
            mat-icon-button
            matTooltip="ลบ"
            (click)="deleteItem(element)"
            matTooltipPosition="above"
          >
            <mat-icon>delete</mat-icon>
          </button>
          </div>
        </td>
      </ng-container>
      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        style="text-align: center"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator
      [pageSizeOptions]="[10, 20, 30]"
      showFirstLastButtons
      aria-label="Select page of periodic elements"
    >
    </mat-paginator>
  </div>
</div>
