import { LiveAnnouncer } from "@angular/cdk/a11y";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { ImageModalComponent } from "../ms13/Image-modal/Image-modal.component";

@Component({
  selector: "app-upload-document-datagrid",
  templateUrl: "./upload-document-datagrid.component.html",
  styleUrls: ["./upload-document-datagrid.component.css"],
})
export class UploadDocumentDatagridComponent implements OnInit {
  displayedColumns = ["position", "attachType", "attachFilePic", "remark"];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  form: any[] = [];
  attachTypeList: any[] = [];
  isDisabled: boolean = false;
  docType: any;

  @Input()
  set uploadPhotoData(value) {
    console.log(value);
    this.dataSource = new MatTableDataSource(value);
  }
  get uploadPhotoData(): any {
    return this.dataSource;
  }

  @Input()
  set uploadPhotoForm(value) {
    console.log(value);
    this.form = value;
  }
  get uploadPhotoForm(): any {
    return this.form;
  }

  @Input()
  set disabled(value) {
    this.isDisabled = value;
    if (!value) {
      this.displayedColumns.push("action");
    }
  }
  get disabled(): any {
    return this.isDisabled;
  }

  @Input()
  set documentType(value) {
    this.docType = value;
  }
  get documentType(): any {
    return this.docType;
  }

  @Output() dataSourceUpdate = new EventEmitter();
  @Output() formSourceUpdate = new EventEmitter();

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private confirmService: ConfirmDialogService,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loadOption();
    this.dataSource.sort = this.sort;
  }

  loadOption() {
    // this.appCOService.getListAttachType().then((res: any) => {
    //   if (res === 'error') {
    //     return
    //   } else {
    //     this.attachTypeList = []
    //     res = res.filter(e => e.maT_DOC_TYPE === this.docType);
    //     // var newRes = this.convertData(res);
    //     this.attachTypeList = this.attachTypeList.concat(res);
    //   }
    // })
  }

  convertData(data: any[]): any[] {
    const convertedData: any[] = [];

    for (const item of data) {
      const convertedItem = {
        ...item,
        maT_DOC_TYPE_ID: item.maT_DOC_TYPE_ID.toString(),
      };
      convertedData.push(convertedItem);
    }

    return convertedData;
  }

  onChanged(e) {
    this.formUpdate();
    this.sourceUpdate();
  }

  onSelectionChanged(e, item) {
    this.formUpdate();
    this.sourceUpdate();
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  onView(e) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.minHeight = "30vh";
    dialogConfig.minWidth = "30vh";
    dialogConfig.maxHeight = "100vh";
    dialogConfig.data = {
      fullImageUrl: e,
    };

    const dialogRef = this.dialog.open(ImageModalComponent, dialogConfig);
  }

  deleteAttachment(e, ind) {
    this.confirmService
      .open({
        header: "ยืนยันการทำรายการ",
        content: "ต้องการลบเอกสารประกอบคำขอใช่หรือไม่",
      })
      .then((res) => {
        // 
        if (res == true) {
          let data = this.dataSource.data;
          console.log(data);

          if (e.attachPicId != 0) {
            data = this.dataSource.data.filter(
              (x) => x.attachPicId != e.attachPicId
            );

            let key = this.form.findIndex(
              (x) => x.attachPicId == e.attachPicId
            );
            this.form[key].isActive = "N";
          } else {
            let delData = data[ind];

            data = this.dataSource.data.filter(
              (x) => x.attachFilePic != delData.attachFilePic
            );

            this.form = this.form.filter(
              (e) => e.attachFilePic !== delData.attachFilePic
            );
          }

          this.dataSource = new MatTableDataSource(
            data.filter((x) => x.isActive == "Y")
          );

          this.formUpdate();
          this.sourceUpdate();
        }
      });
  }

  sourceUpdate() {
    const data = this.dataSource.data;
    this.dataSourceUpdate.emit(data);
  }

  formUpdate() {
    this.formSourceUpdate.emit(this.form);
  }
}
