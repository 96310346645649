<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">

    <div class="menu justify-between flex items-stretch flex-row gap-5 px-20  pl-10  pr-10">
        <div class="gap-2.5 flex flex-row">
            <button mat-raised-button color="accent" *ngIf="checkButton('BT087')" (click)="addAccount($event)">
                <div class="flex items-center">
                    <span>Add</span>
                </div>
            </button>
            <button mat-raised-button color="warn" *ngIf="checkButton('BT088')" (click)="deleteSelection()">
                <div class="flex items-center">
                    <span>Delete</span>
                </div>
            </button>
        </div>
        <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">

            <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0" style="flex: 0 40%;">
                <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
                        [(ngModel)]="form.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
                    <button matSuffix mat-icon-button aria-label="ค้นหา">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

    </div>
    <!-- </div> -->
    <div class="mat-elevation-z8">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ลำดับ </th>
                <td mat-cell *matCellDef="let i = index">
                  {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
                </td>
              </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by number"
                    class="text-left"> Code
                </th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ชื่อ </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="preFix">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> Prefix </th>
                <td mat-cell *matCellDef="let element"> {{element.preFix}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="desc">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> รายละเอียด </th>
                <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-left"> การจัดการ </th>
                <td mat-cell *matCellDef="let element;let i = index" class="text-left">
                    <div class="flex flex-row" style="justify-content: start;">
                        <button mat-icon-button matTooltip="รายละเอียด" *ngIf="checkButton('BT089')"
                            (click)="editItem(element)" matTooltipPosition="above">
                            <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="ลบ" *ngIf="checkButton('BT088')" (click)="delete(element)"
                            matTooltipPosition="above">
                            <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>