<div class="flex-col" style="width: 100%;">
  <div class="flex flex-col px-20" style="margin-bottom: 20px;">
    <!-- MENU BAR -->
    <div class="flex flex-col" style="padding-left: 20px; padding-right: 20px; padding-bottom: 10px;">
      <mat-card class="flex flex-row" style="width: 100%; gap: 5px; padding: 5px;">
        <button mat-icon-button matTooltip="ย้อนกลับ" matTooltipPosition="above"
          style="width: 2em !important; height: 2em!important;" routerLink="/page/DFT/ExporterList">
          <i class="ti ti-chevron-left"></i>
        </button>
        <button mat-icon-button matTooltip="โหลดข้อมูลใหม่" matTooltipPosition="above"
          style="width: 2em !important; height: 2em !important;" (click)="loadData()">
          <i class="ti ti-refresh"></i>
        </button>
        <!-- <button mat-icon-button matTooltip="บันทึก" matTooltipPosition="above"
          style="width: 2em !important; height: 2em !important;" (click)="saveData()">
          <i class="ti ti-device-floppy"></i>
        </button>
        <button mat-icon-button matTooltip="แก้ไข" matTooltipPosition="above"
          style="width: 2em !important; height: 2em !important;">
          <i class="ti ti-edit"></i>
        </button> -->
      </mat-card>
    </div>

    <div class="flex flex-col">
      <div style="padding: 0px 10px 0px 10px;">
        <!-- Title -->
        <div class="flex flex-col p10">
          <mat-card>

            <div class="flex">
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div style="align-self: center;"> วันที่ออกใบทะเบียน : </div>
                  <div class="pl-2">
                    <mat-form-field class="w100">
                      <input matInput [matDatepicker]="ms5DateStart" readonly type="datetime"
                        [(ngModel)]="form.ms5DateStart" [max]="form.ms5DateEnd" name="ms5DateStart"
                        #ms5DateStart="ngModel">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker #ms5DateStart></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div style="align-self: center;">วันที่หมดอายุใบทะเบียน : </div>
                  <div class="pl-2">
                    <mat-form-field class="w100">
                      <input matInput [matDatepicker]="ms5DateEnd" readonly type="datetime"
                        [(ngModel)]="form.ms5DateEnd" [min]="form.ms5DateStart" name="ms5DateEnd" #ms5DateEnd="ngModel">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker #ms5DateEnd></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div style="align-self: center;">ปี พ.ศ. ที่จดทะเบียน : </div>
                  <div class="pl-2">
                    <mat-form-field class="w100">
                      <input autocomplete="off" matInput [(ngModel)]="form.yearPK" name="yearPK" #yearPK="ngModel"
                        readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div style="align-self: center;"> วันที่ได้รับข้อมูลครั้งแรก : </div>
                  <div class="pl-2">
                    <mat-form-field class="w100">
                      <input matInput [matDatepicker]="createDate" readonly type="datetime"
                        [(ngModel)]="form.createDate" name="createDate" #createDate="ngModel" class="cursor-pointer">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker #createDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div style="align-self: center;"> วันที่ได้รับข้อมูลล่าสุด : </div>
                  <div class="pl-2">
                    <mat-form-field class="w100">
                      <input matInput [matDatepicker]="updateDate" readonly type="datetime"
                        [(ngModel)]="form.updateDate" name="updateDate" #updateDate="ngModel" class="cursor-pointer">
                      <mat-hint>MM/DD/YYYY</mat-hint>
                      <mat-datepicker #updateDate></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
        <!-- ข้อมูลบริษัท -->
        <div class="flex flex-col p10">
          <mat-card>
            <mat-card-header class="fontTitleHeader">
              ข้อมูลบริษัท
            </mat-card-header>
            <mat-card-content class="example-headers-align">
              <div class="flex">
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เลขประจำตัวผู้ส่งออก : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.exporterNo"
                          name="exporterNo" #exporterNo="ngModel" readonly>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เลขที่ประจำตัวผู้เสียภาษี : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.taxNo" name="taxNo"
                          #taxNo="ngModel" readonly>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ชื่อบริษัทผู้ส่งออก : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัทผู้ส่งออก" #input
                          [(ngModel)]="form.companyName" name="companyName" #companyName="ngModel" readonly>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-64">
                </div>
              </div>
              <!-- <div class="flex">
            <div class="flex-1 w-50">
            </div>
            <div class="flex-1 w-64">
            </div>
          </div> -->
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ที่อยู่ : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.address" name="address"
                          #address="ngModel" readonly="">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> จังหวัด : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.province"
                          name="province" #province="ngModel" readonly>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เขต / อำเภอ : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.distict" name="distict"
                          #distict="ngModel" readonly>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> รหัสไปรณีย์ </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.postCode"
                          name="postCode" #postCode="ngModel" readonly>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> แขวง / ตำบล : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.subDistict"
                          name="subDistict" #subDistict="ngModel" readonly>
                        <mat-error>กรุณาระบุ</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> จังหวัด : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.province"
                          name="province" #province="ngModel" readonly>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> สถานะผู้ส่งออก : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100">
                        <mat-select [(ngModel)]="form.statusExporter" disabled>
                          <mat-option [value]="'N'">รายใหม่</mat-option>
                          <mat-option [value]="'O'">รายเก่า</mat-option>
                          <mat-option [value]="'C'">ยกเลิก</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> สถานะการอัปเดตเข้า MR : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100">
                        <mat-select [(ngModel)]="form.statusUpdate" disabled>
                          <mat-option [value]="'Y'">อัปเดต</mat-option>
                          <mat-option [value]="'N'">ไม่อัปเดต</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <!-- รายละเอียดสินค้ามาตรฐานที่จดทะเบียน -->
        <div class="flex flex-col p10">
          <mat-card>
            <mat-card-header class="fontTitleHeader">
              รายละเอียดสินค้ามาตรฐานที่จดทะเบียน
            </mat-card-header>
            <mat-card-content class="example-headers-align">
              <div *ngFor="let element of dataView">
                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div style="align-self: center;  border-bottom: 1px solid #aaa; width:100%; ">
                        {{element.productName}} </div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <!-- <div class="basis-1/2 f18" style="align-self: center;">สถานะของสินค้า : </div>
                  <div>{{element.statusProduct}}</div> -->

                      <div class="basis-1/2 f-18" style="align-self: center;"> สถานะของสินค้า : </div>
                      <div class="basis-4/5"> {{element.statusProduct === 'N' ? 'เพิ่มเติมสินค้า' :
                        element.statusProduct
                        === 'C' ? 'ยกเลิกสินค้า' : 'ไม่ระบุ'}} </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f-18" style="align-self: center;">เลขที่เครื่องหมาย : </div>
                      <div class="basis-4/5"> {{element.logoNo}} </div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f-18" style="align-self: center;">ชื่อตราเครื่องหมายส่งออก : </div>
                      <div class="basis-4/5"> {{element.logoName}} </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f-18" style="align-self: center;">ตราเครื่องหมายส่งออก : </div>
                      <!-- <div> <a href={{element.logoLink}}><u>{{element.logoLink}}</u></a></div> -->
                      <div class="basis-4/5"> <input type="image" class="txt-box" #input width="200" height="150"
                          [src]="element.logoLink" readonly></div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f-18" style="align-self: center;">วันที่มีผลบังคับใช้ตรา : </div>
                      <div class="basis-4/5"> {{element.logoIssueDate | date:'yyyy-MM-dd' }}</div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f-18" style="align-self: center;">สถานะของตราเครื่องหมาย : </div>
                      <div class="basis-4/5">{{element.logoStatus === 'N' ? 'เพิ่มตรา' : element.logoStatus === 'C' ?
                        'ยกเลิกตรา' : element.logoStatus === 'M' ? 'เปลี่ยนตรา' : 'ไม่ระบุ'}}</div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f-18" style="align-self: center;"> เหตุผลในการแก้ไข มส. 18 : </div>
                      <div class="basis-4/5"> {{element.remark}} </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </div>
</div>