<div style="display: flex;flex-flow: column;height: 100%; width: 100%; gap: 30px;" class="flex flex-col h-full">
    <div style="display:flex; flex-flow:row; height: 100%;" class="py-20"> <!-- Start Body -->
        <div style="display:flex; flex-flow:row; width: 20%;"></div>
        <!-- Card -->
        <div style="display:flex; flex-flow:row; width: 60%;">
            <div style="display:flex; flex-flow:column; align-items: center; width: 100%;">
                <mat-card style="width: 100%;">

                    <mat-card-content class="pt-5">
                        <div class="flex flex-row items-center pl-2.5">
                            <div div class="basis-1/4">
                                <div class="flex-column" style="margin-top:24px">
                                    <mat-label>
                                        เลขประจำตัวผู้เสียภาษี
                                    </mat-label>
                                </div>
                                <div class="flex-column " style="color: #9ca3af">
                                    <mat-label>
                                        Tax No.
                                    </mat-label>
                                </div>
                            </div>
                            <div class="basis-3/4">
                                <mat-form-field class="w-3/4">
                                    <input autocomplete="off" type="text" matInput class="txt-box" #Input
                                        [ngModel]="companyDetail.taxID" readonly />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex flex-row items-center pl-2.5 pt-3">
                            <div div class="basis-1/4">
                                <div class="flex-column" style="margin-top:24px">
                                    <mat-label>
                                        ชื่อบริษัทภาษาไทย
                                    </mat-label>
                                </div>
                                <div class="flex-column " style="color: #9ca3af">
                                    <mat-label>
                                        Thai name
                                    </mat-label>
                                </div>
                            </div>
                            <div class="basis-3/4">
                                <mat-form-field class="w-3/4">
                                    <input autocomplete="off" type="text" matInput class="txt-box" #input
                                        [ngModel]="companyDetail.nameTh" readonly />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex flex-row items-center pl-2.5 pt-3">
                            <div div class="basis-1/4">
                                <div class="flex-column" style="margin-top:24px">
                                    <mat-label>
                                        ชื่อภาษาอังกฤษ
                                    </mat-label>
                                </div>
                                <div class="flex-column " style="color: #9ca3af">
                                    <mat-label>
                                        English name
                                    </mat-label>
                                </div>
                            </div>
                            <div class="basis-3/4">
                                <mat-form-field class="w-3/4">
                                    <input autocomplete="off" type="text" matInput class="txt-box" #input
                                        [ngModel]="companyDetail.nameEn" readonly />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex flex-row items-center pl-2.5 pt-3">
                            <div div class="basis-1/4">
                                <div class="flex-column" style="margin-top:24px">
                                    <mat-label>
                                        ที่อยู่
                                    </mat-label>
                                </div>
                                <div class="flex-column " style="color: #9ca3af">
                                    <mat-label>
                                        Address & Street
                                    </mat-label>
                                </div>
                            </div>
                            <div class="basis-3/4">
                                <mat-form-field class="w-3/4">
                                    <input autocomplete="off" type="text" matInput class="txt-box" #input
                                        [ngModel]="companyDetail.addressStreet" readonly />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex flex-row items-center pl-2.5 pt-3">
                            <div div class="basis-1/4">
                                <div class="flex-column" style="margin-top:24px">
                                    <mat-label>
                                        แขวง/ตำบล
                                    </mat-label>
                                </div>
                                <div class="flex-column " style="color: #9ca3af">
                                    <mat-label>
                                        District
                                    </mat-label>
                                </div>
                            </div>
                            <div class="basis-3/4">
                                <mat-form-field class="w-3/4">
                                    <input autocomplete="off" type="text" matInput class="txt-box" #input
                                        [ngModel]="companyDetail.district" readonly />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex flex-row items-center pl-2.5 pt-3">
                            <div div class="basis-1/4">
                                <div class="flex-column" style="margin-top:24px">
                                    <mat-label>
                                        เขต/อำเภอ
                                    </mat-label>
                                </div>
                                <div class="flex-column " style="color: #9ca3af">
                                    <mat-label>
                                        Sub Province
                                    </mat-label>
                                </div>
                            </div>
                            <div class="basis-3/4">
                                <mat-form-field class="w-3/4">
                                    <input autocomplete="off" type="text" matInput class="txt-box" #input
                                        [ngModel]="companyDetail.subProvince" readonly />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex flex-row items-center pl-2.5 pt-3">
                            <div div class="basis-1/4">
                                <div class="flex-column" style="margin-top:24px">
                                    <mat-label>
                                        จังหวัด
                                    </mat-label>
                                </div>
                                <div class="flex-column " style="color: #9ca3af">
                                    <mat-label>
                                        Province
                                    </mat-label>
                                </div>
                            </div>
                            <div class="basis-3/4">
                                <mat-form-field class="w-3/4">
                                    <input autocomplete="off" type="text" matInput class="txt-box" #input
                                        [ngModel]="companyDetail.province" readonly />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex flex-row items-center pl-2.5 pt-3">
                            <div div class="basis-1/4">
                                <div class="flex-column" style="margin-top:24px">
                                    <mat-label>
                                        รหัสไปรษณีย์
                                    </mat-label>
                                </div>
                                <div class="flex-column " style="color: #9ca3af">
                                    <mat-label>
                                        Postal code
                                    </mat-label>
                                </div>
                            </div>
                            <div class="basis-3/4">
                                <mat-form-field class="w-3/4">
                                    <input autocomplete="off" type="text" matInput class="txt-box" #input
                                        [ngModel]="companyDetail.postcode" readonly />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex flex-row items-center pl-2.5 pt-3">
                            <div div class="basis-1/4">
                                <div class="flex-column" style="margin-top:24px">
                                    <mat-label>
                                        ประเทศ
                                    </mat-label>
                                </div>
                                <div class="flex-column " style="color: #9ca3af">
                                    <mat-label>
                                        Country
                                    </mat-label>
                                </div>
                            </div>
                            <div class="basis-3/4">
                                <mat-form-field class="w-3/4">
                                    <input autocomplete="off" type="text" matInput class="txt-box" #input
                                        [ngModel]="companyDetail.countryCode" readonly />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="flex flex-row items-center pl-2.5 pt-3">
                            <div div class="basis-1/4">
                                <div class="flex-column" style="margin-top:24px">
                                    <mat-label>
                                        หมายเลขโทรศัพท์
                                    </mat-label>
                                </div>
                                <div class="flex-column " style="color: #9ca3af">
                                    <mat-label>
                                        Tel.
                                    </mat-label>
                                </div>
                            </div>
                            <div class="basis-3/4">
                                <mat-form-field class="w-3/4">
                                    <input autocomplete="off" type="text" matInput class="txt-box" #input
                                        [ngModel]="companyDetail.phone" readonly />
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div> <!-- End Card -->
        <div style="display:flex; flex-flow:row; width: 20%;"></div>
    </div> <!-- End Body -->

    <div style="display:flex; flex-flow:row; height: 100%;"> <!-- Start Body 2 -->
        <div style="display:flex; flex-flow:row; width: 20%;"></div>
        <!-- Card -->
        <div style="display:flex; flex-flow:row; width: 60%;">
            <div style="display:flex; flex-flow:column; align-items: center; width: 100%;">
                <mat-card style="width: 100%;">
                    <mat-card-header style="background-color: dimgray; justify-content: center;">
                        <div style="display:flex; align-items: center; font-size: 20px; color: white;">Contact</div>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="flex flex-column items-center pl-2.5 pt-3">
                            <div class="flex flex-row basis-2/4">
                                <div div class="basis-2/4">
                                    <div class="flex-column" style="margin-top:24px">
                                        <mat-label>
                                            ชื่อผู้ติดต่อ
                                        </mat-label>
                                    </div>
                                    <div class="flex-column " style="color: #9ca3af">
                                        <mat-label>
                                            Contact Name
                                        </mat-label>
                                    </div>
                                </div>
                                <div class="basis-2/4">
                                    <mat-form-field class="w-3/4">
                                        <input autocomplete="off" type="text" matInput class="txt-box" #input
                                            [ngModel]="companyDetail.contactName" readonly />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex flex-row basis-2/4">
                                <div div class="basis-2/4">
                                    <div class="flex-column" style="margin-top:24px">
                                        <mat-label>
                                            นามสกุล
                                        </mat-label>
                                    </div>
                                    <div class="flex-column " style="color: #9ca3af">
                                        <mat-label>
                                            Contact Lastname
                                        </mat-label>
                                    </div>
                                </div>
                                <div class="basis-2/4">
                                    <mat-form-field class="w-3/4">
                                        <input autocomplete="off" type="text" matInput class="txt-box" #input
                                            [ngModel]="companyDetail.contactLastname" readonly />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-column items-center pl-2.5 pt-3 mb-10	">
                            <div class="flex flex-row basis-2/4">
                                <div div class="basis-2/4">
                                    <div class="flex-column" style="margin-top:24px">
                                        <mat-label>
                                            อีเมลผู้ติดต่อ
                                        </mat-label>
                                    </div>
                                    <div class="flex-column " style="color: #9ca3af">
                                        <mat-label>
                                            Contact Email
                                        </mat-label>
                                    </div>
                                </div>
                                <div class="basis-2/4">
                                    <mat-form-field class="w-3/4">
                                        <input style="margin: 0;" autocomplete="off" type="text" matInput
                                            class="txt-box" #input [ngModel]="companyDetail.contactEmail" readonly />
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex flex-row basis-2/4">
                                <div div class="basis-2/4">
                                    <div class="flex-column" style="margin-top:24px">
                                        <mat-label>
                                            หมายเลขโทรศัพท์ผู้ติดต่อ
                                        </mat-label>
                                    </div>
                                    <div class="flex-column " style="color: #9ca3af">
                                        <mat-label>
                                            Contact Phone No.
                                        </mat-label>
                                    </div>
                                </div>
                                <div class="basis-2/4">
                                    <mat-form-field class="w-3/4">
                                        <input style="margin: 0;" autocomplete="off" type="text" matInput
                                            class="txt-box" #input [ngModel]="companyDetail.contactPhone" readonly />
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div> <!-- End Card -->
        <div style="display:flex; flex-flow:row; width: 20%;"></div>
    </div> <!-- End Body 2 -->
    <div style="display:flex; flex-flow:row; height: 100%;"> <!-- Start Body 2 -->
        <div style="display:flex; flex-flow:row; width: 20%;"></div>
        <!-- Card -->
        <div style="display:flex; flex-flow:row; width: 60%;">
            <div style="display:flex; flex-flow:column; align-items: center; width: 100%;">
                <mat-card style="width: 100%;">
                    <mat-card-header style="background-color: dimgray; justify-content: center;">
                        <div style="display:flex; align-items: center; font-size: 20px; color: white;">Logo</div>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="flex flex-column items-center pl-2.5 pt-3 mb-10	">
                            <div class="flex flex-row basis-2/4">
                                <div div class="basis-2/4">
                                    <div class="flex-column pt-5">
                                        <mat-label>
                                            ตราหรือเครื่องหมายส่งออก
                                        </mat-label>
                                    </div>
                                    <div class="flex-column " style="color: #9ca3af">
                                    </div>
                                </div>
                                <div class="basis-2/4 p-0" style="padding:0px">
                                    <mat-card class="w-3/4 w-32" style="height: 150px;">
                                        <img [src]="companyDetail?.trademark" class="justify-center" width="120"
                                            height="150">
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div> <!-- End Card -->
        <div style="display:flex; flex-flow:row; width: 20%;"></div>
    </div> <!-- End Body 2 -->
    <div class="flex flex-row py-5 justify-center">
        <div class="flex flex-column px-5">
            <button mat-raised-button color="accent" class="h-12 px-6 m-2 text-lg rounded-lg">
                &nbsp;&nbsp;&nbsp;แก้ไข&nbsp;&nbsp;&nbsp;
            </button>
        </div>
        <div class="flex flex-column px-5">
            <button mat-raised-button color="accent" class="h-12 px-6 m-2 text-lg rounded-lg"
                [routerLink]="['/dashboard']">
                &nbsp;ย้อนกลับ&nbsp;
            </button>
        </div>
    </div>
</div>