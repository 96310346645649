<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">เลือกผู้ส่งออก | Add Exporter</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="flex flex-col py-5 gap-4 ">
      <div>
        <div class="flex flex-row">
          <div class="basis-1/3">รายชื่อบริษัท : </div>
          <div class="basis-2/3">
            <mat-form-field class="w100">
              <mat-select [(ngModel)]="form.company" (selectionChange)="selectionCompany(form.company)" name="company">
                <mat-option *ngFor="let company of companyList" [value]="company">
                  {{company.companyNameEn}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="form" [disabled]="form.companyNameTh == null"
      color="accent">เพิ่มใบเสร็จ</button>
  </mat-dialog-actions>