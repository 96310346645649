import { Injectable } from "@angular/core";
import { ApiNewsService } from "../api/api-news.service";

@Injectable({
  providedIn: "root",
})
export class AppNewsService {
  constructor(private apiNewsService: ApiNewsService) {}

  getNewsList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiNewsService.getNewsList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getCategorymenuList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiNewsService.getCategorymenuList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getCategoryNewsist(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiNewsService.getCategoryNewsist(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getSubCategorymenuList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiNewsService.getSubCategorymenuList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
