<mat-card *ngIf="action != 'delete'">
 <mat-card-header  class="fontTitleHeader" >
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">Receiver</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="flex flex-col py-5 gap-4 ">
      <div class="pl-2 pr-2 pt-5 ">
        <form name="dialogForm" [formGroup]="dialogForm" novalidate>
          <div class="flex flex-row items-center pl-2.5">
            <div class="basis-1/4">
              <mat-label>Receiver Name</mat-label>
            </div>
            <div class="basis-3/4">
              <mat-label>:&nbsp;&nbsp;</mat-label>
              <mat-form-field class="w-3/4">
                <input autocomplete="off" type="text" matInput #input name="receiver" class="form-control"
                  formControlName="name" [(ngModel)]="local_data.name" ngModel maxlength="70" required="true" />
              </mat-form-field>
              <div
                *ngIf="dialogForm.controls['name'].invalid && (dialogForm.controls['name'].dirty || dialogForm.controls['name'].touched)"
                class="p-1 text-sm text-red-800" role="alert">
                <div *ngIf="dialogForm.controls['name'].errors.required">
                  **Name is required.
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row items-center pl-2.5 pt-2.5">
            <div class="basis-1/4">
              <mat-label>Address and Street</mat-label>
            </div>
            <div class="basis-3/4">
              <mat-label>:&nbsp;&nbsp;</mat-label>
              <mat-form-field class="w-3/4">
                <input autocomplete="off" type="text" matInput #input name="addressAndStreet" formControlName="addr"
                  class="form-control" [(ngModel)]="local_data.addr" ngModel maxlength="70" required="true" />
              </mat-form-field>
              <div
                *ngIf="dialogForm.controls['addr'].invalid && (dialogForm.controls['addr'].dirty || dialogForm.controls['addr'].touched)"
                class="p-1 text-sm text-red-800" role="alert">
                <div *ngIf="dialogForm.controls['addr'].errors.required">
                  **Address and Street is required.
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row items-center pl-2.5 pt-2.5">
            <div class="basis-1/4">
              <mat-label>District</mat-label>
            </div>
            <div class="basis-3/4">
              <mat-label>:&nbsp;&nbsp;</mat-label>
              <mat-form-field class="w-3/4">
                <input autocomplete="off" type="text" matInput #input name="district" formControlName="district"
                  [(ngModel)]="local_data.district" ngModel maxlength="35" />
              </mat-form-field>
            </div>
          </div>
          <div class="flex flex-row items-center pl-2.5 pt-2.5">
            <div class="basis-1/4">
              <mat-label>Sub-Province</mat-label>
            </div>
            <div class="basis-3/4">
              <mat-label>:&nbsp;&nbsp;</mat-label>
              <mat-form-field class="w-2/4">
                <input autocomplete="off" type="text" matInput #input name="subProvince" formControlName="subProvince"
                  [(ngModel)]="local_data.subProvince" ngModel maxlength="35" required="true" />
              </mat-form-field>
              <div
                *ngIf="dialogForm.controls['subProvince'].invalid && (dialogForm.controls['subProvince'].dirty || dialogForm.controls['subProvince'].touched)"
                class="p-1 text-sm text-red-800" role="alert">
                <div *ngIf="dialogForm.controls['subProvince'].errors.required">
                  **subProvince is required.
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row items-center pl-2.5 pt-2.5">
            <div class="basis-1/4">
              <mat-label>Province</mat-label>
            </div>
            <div class="basis-3/4">
              <mat-label>:&nbsp;&nbsp;</mat-label>
              <mat-form-field class="w-2/4">
                <input autocomplete="off" type="text" matInput #input name="province" formControlName="province"
                  [(ngModel)]="local_data.province" ngModel maxlength="35" required="true" />
              </mat-form-field>
              <div
                *ngIf="dialogForm.controls['province'].invalid && (dialogForm.controls['province'].dirty || dialogForm.controls['province'].touched)"
                class="p-1 text-sm text-red-800" role="alert">
                <div *ngIf="dialogForm.controls['province'].errors.required">
                  **Province is required.
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row items-center pl-2.5 pt-2.5">
            <div class="basis-1/4">
              <mat-label>Postcode</mat-label>
            </div>
            <div class="basis-3/4">
              <mat-label>:&nbsp;&nbsp;</mat-label>
              <mat-form-field class="w-2/4">
                <input autocomplete="off" type="text" matInput #input name="postcode" formControlName="postcode"
                  maxlength="9" ngModel [(ngModel)]="local_data.postcode" required="true" />
              </mat-form-field>
              <div
                *ngIf="dialogForm.controls['postcode'].invalid && (dialogForm.controls['postcode'].dirty || dialogForm.controls['postcode'].touched)"
                class="p-1 text-sm text-red-800" role="alert">
                <div *ngIf="dialogForm.controls['postcode'].errors.required">
                  **Postcode is required.
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row items-center pl-2.5 pt-2.5">
            <div class="basis-1/4">
              <mat-label>Country</mat-label>
            </div>
            <div class="basis-3/4">
              <mat-label>:&nbsp;&nbsp;</mat-label>
              <mat-form-field class="w-2/4">
                <input autocomplete="auto" type="text" matInput #input #local_data.country_name name="country"
                  [formControl]="myControl" [matAutocomplete]="auto" [(ngModel)]="local_data.country_name"
                  (ngModelChange)="local_data.country_name = $event.toUpperCase()" />
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                  (optionSelected)="onSelect($event.option.value)">
                  <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <div
                *ngIf="dialogForm.controls['country'].invalid && (dialogForm.controls['country'].dirty || dialogForm.controls['country'].touched)"
                class="p-1 text-sm text-red-800" role="alert">
                <div *ngIf="dialogForm.controls['country'].errors.required">
                  **Country is required.
                </div>
              </div>
              <mat-label>&nbsp;&nbsp;-&nbsp;&nbsp;</mat-label>
              <mat-form-field class="w-2/12">
                <input autocomplete="off" autoActiveFirstOption="true" type="text" matInput #input name="country"
                  formControlName="country" [(ngModel)]="local_data.country" disabled ngModel maxlength="2"
                  required="true" />
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </mat-dialog-content>

</mat-card>

<mat-card *ngIf="action == 'delete'">
 <mat-card-header  class="fontTitleHeader" >
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">Delete Receiver</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="flex flex-col py-5 gap-4 ">
      <div mat-dialog-content>
        Would you like to delete ?
      </div>
      <div mat-dialog-actions>
        <button type="submit">
          Save
        </button>
        <button mat-raised-button color="accent" mat-dialog-close cdkFocusInitial (click)="doAction()">
          Ok
        </button>
        <button mat-raised-button color="accent" mat-dialog-close>No</button>
      </div>
    </div>
  </mat-dialog-content>

</mat-card>