import { Injectable } from "@angular/core";
import { ApiItemtypeService } from "../api/api-itemtype.service";

@Injectable({
    providedIn: "root",
  })
  export class AppItemtypeService {
    constructor(private ApiItemtypeService: ApiItemtypeService) {}
  
    getDataRefGoodsClass(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiItemtypeService.getDataRefGoodsClass(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
  
    getDataDetailProduct(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiItemtypeService.getDataDetailProduct(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }

    AdjustDataRefGoodsClass(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiItemtypeService.AdjustDataRefGoodsClass(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
 
    
  }
  