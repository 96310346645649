<div class="frame" *ngIf="actionItem?.value !== ''">
    <div class="button-row">
        <button mat-raised-button color="accent" matTooltipPosition="above" matTooltip="บันทึก"
            (click)="saveButtonPermissionSubmit()" [disabled]="!actionButtonTo"> บันทึก </button>
        <button mat-raised-button matTooltipPosition="above" matTooltip="ล้างข้อมูล"
            (click)="resetButtonSubmit()">ล้างข้อมูล</button>
    </div>
    <!-- <div fxLayout="row" fxLayoutAlign="{{displayFlex}}" fxLayoutGap="20px"> -->
    <div fxLayout="row" fxLayoutGap="20px" class="checkbox">
        <div fxLayout="column" fxLayoutAlign="space-around start">
            <h3>ปุ่มสำหรับ{{actionItem?.text}}: {{actionButtonTo?.text}}</h3>
            <dx-tree-view id="buttonTreeView1" *ngIf="actionItem?.value === 'U'" #buttonTreeView1
                [items]="dataButtonUserList" [width]="300" [showCheckBoxesMode]="showCheckBoxesMode"
                [selectionMode]="selectionMode" [selectNodesRecursive]="selectNodesRecursive"
                [selectByClick]="selectByClick" [noDataText]="'ไม่มีข้อมูล'" [expandNodesRecursive]="true"
                [itemsExpr]="'children'" [keyExpr]="'menuCode'" [parentIdExpr]="'parentCode'" [displayExpr]="'name'"
                [expandedExpr]="'expanded'" (onSelectionChanged)="treeViewSelectionChanged($event)"
                (onContentReady)="treeViewContentReady($event)" (onItemClick)="selectItem($event)">
                <div *dxTemplate="let item of 'item'">
                    {{ item.name }}
                </div>
            </dx-tree-view>
            <dx-tree-view id="buttonTreeView2" *ngIf="actionItem?.value === 'R'" #buttonTreeView2
                [items]="dataButtonUserRoleList" [width]="300" [showCheckBoxesMode]="showCheckBoxesMode"
                [selectionMode]="selectionMode" [selectNodesRecursive]="selectNodesRecursive"
                [selectByClick]="selectByClick" [noDataText]="'ไม่มีข้อมูล'" [expandNodesRecursive]="true"
                [itemsExpr]="'children'" [keyExpr]="'menuCode'" [parentIdExpr]="'parentCode'" [displayExpr]="'name'"
                [expandedExpr]="'expanded'" (onSelectionChanged)="treeViewSelectionChanged($event)"
                (onContentReady)="treeViewContentReady($event)" (onItemClick)="selectItem($event)">
                <div *dxTemplate="let item of 'item'">
                    {{ item.name }}
                </div>
            </dx-tree-view>
            <dx-tree-view id="buttonTreeView3" *ngIf="actionItem?.value === 'G'" #buttonTreeView3
                [items]="dataButtonUserGroupList" [width]="300" [showCheckBoxesMode]="showCheckBoxesMode"
                [selectionMode]="selectionMode" [selectNodesRecursive]="selectNodesRecursive"
                [selectByClick]="selectByClick" [noDataText]="'ไม่มีข้อมูล'" [expandNodesRecursive]="true"
                [itemsExpr]="'children'" [keyExpr]="'menuCode'" [parentIdExpr]="'parentCode'" [displayExpr]="'name'"
                [expandedExpr]="'expanded'" (onSelectionChanged)="treeViewSelectionChanged($event)"
                (onContentReady)="treeViewContentReady($event)" (onItemClick)="selectItem($event)">
                <div *dxTemplate="let item of 'item'">
                    {{ item.name }}
                </div>
            </dx-tree-view>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around start" *ngIf="actionItem?.value === 'U'">
            <h3>ปุ่มสำหรับสิทธิ์ผู้ใช้งาน: {{actionButtonTo?.user?.userRoleName}}</h3>
            <dx-tree-view id="buttonTreeView2" #buttonTreeView2
                [items]="dataButtonUserRoleList" [disabled]="true" [width]="300" [showCheckBoxesMode]="showCheckBoxesMode"
                [selectionMode]="selectionMode" [selectNodesRecursive]="selectNodesRecursive"
                [selectByClick]="selectByClick" [noDataText]="'ไม่มีข้อมูล'" [expandNodesRecursive]="true"
                [itemsExpr]="'children'" [keyExpr]="'menuCode'" [parentIdExpr]="'parentCode'" [displayExpr]="'name'"
                [expandedExpr]="'expanded'" (onSelectionChanged)="treeViewSelectionChanged($event)"
                (onContentReady)="treeViewContentReady($event)" (onItemClick)="selectItem($event)">
                <div *dxTemplate="let item of 'item'">
                    {{ item.name }}
                </div>
            </dx-tree-view>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-around start" *ngIf="actionItem?.value === 'U'">
            <h3>ปุ่มสำหรับกลุ่มผู้ใช้งาน: {{actionButtonTo?.user?.userGroupName}}</h3>
            <dx-tree-view id="buttonTreeView3" #buttonTreeView3
                [items]="dataButtonUserGroupList" [disabled]="true" [width]="300" [showCheckBoxesMode]="showCheckBoxesMode"
                [selectionMode]="selectionMode" [selectNodesRecursive]="selectNodesRecursive"
                [selectByClick]="selectByClick" [noDataText]="'ไม่มีข้อมูล'" [expandNodesRecursive]="true"
                [itemsExpr]="'children'" [keyExpr]="'menuCode'" [parentIdExpr]="'parentCode'" [displayExpr]="'name'"
                [expandedExpr]="'expanded'" (onSelectionChanged)="treeViewSelectionChanged($event)"
                (onContentReady)="treeViewContentReady($event)" (onItemClick)="selectItem($event)">
                <div *dxTemplate="let item of 'item'">
                    {{ item.name }}
                </div>
            </dx-tree-view>
        </div>
    </div>
</div>