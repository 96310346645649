import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormHelperService {
  constructor() { }
  validateForm(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateForm(control);
      }
    });
  }
  resetValidateForm(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      form.get(key).setErrors(null);
      // form.reset();
    });
  }
  disableForm(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      form.get(key).disable();
    });
  }
}

