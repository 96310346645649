import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-user-group-form',
  templateUrl: './user-group-form.component.html',
  styleUrls: ['./user-group-form.component.css']
})
export class UserGroupFormComponent implements OnInit {

  userGroupForm: FormGroup;

  @Output() userGroupFormEmit: EventEmitter<FormGroup> = new EventEmitter();
  @Input() dataInput: any;
  
  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.userGroupFormInit();
  }
  userGroupFormInit(): void {
    this.userGroupForm = this.formBuilder.group({
      groupName: [this.dataInput?.groupName, [Validators.required]],
      userGroupCode: [this.dataInput?.userGroupCode],
      isActive: [this.dataInput?.isActive, [Validators.required]],
    });
    this.userGroupFormEmit.emit(this.userGroupForm);
  }
  userGroupFormReset(): void {
    this.userGroupFormInit();
  }

}
