import { Component, OnInit, Inject } from "@angular/core";
import { stringToKeyValue } from "@angular/flex-layout/extended/style/style-transforms";
import { MatDialog } from "@angular/material/dialog";
import { IAppUserResponse } from "app/business/interface/app/app-user.interface";
import { AppUserService } from "app/business/service/app/app-user.service";
import { user } from "app/mock-api/common/user/data";
import { PasswordChangeComponent } from "./password-change/password-change.component";

@Component({
  selector: "app-user-management-bl",
  templateUrl: "./user-management.component.html",
  styleUrls: ["./user-management.component.scss"],
})
export class UserManagementBLComponent implements OnInit {
  dataSource: any = {};
  id: number;
  userToken: any;
  form: any = {} as IAppUserResponse;
  isAlert: boolean = false;
  isWarn: boolean = false;
  userProfile: any = {};
  user: any = {};
  alertMessage: any = {
    message: "",
  };
  viewPin: boolean = false;
  userPw: any = "";
  loginUser: any = JSON.parse(localStorage.getItem("loginUser"));
  constructor(
    private appUserService: AppUserService,
    private dialog: MatDialog
  ) {
    const jwt = localStorage.getItem("userProfile");
    const user = jwt ? JSON.parse(jwt) : {};
    this.userToken = user;
  }

  ngOnInit(): void {
    // ;
    let data = JSON.parse(localStorage.getItem("userProfile"));
    this.userProfile.uId = data.uId ? data.uId : data.uid;

    this.userProfile.userName = data.userName;

    this.userProfile.gId = data.gId;

    const formData = {
      username: this.userProfile.userName,
      uId: this.userProfile.uId ? this.userProfile.uId : this.userProfile.uid,
    };
    this.appUserService.getUserProfile(formData).then((res) => {
      this.user = res;
    });

    const companyId = parseInt(data.cpId);
    const groupCode = data.userGroupCode;

    console.log("isAlert", groupCode);
    if (groupCode != "1" && groupCode != "4" && groupCode != "5") {
      const actionReq = {
        comId: companyId,
      };

      if (this.loginUser.isBlacklisted) {
        this.isAlert = true;
        this.appUserService.getReasonLogBlackList(actionReq).then((res) => {
          if (res === "error" || res == null) {
            return;
          } else {
            const message = res.map((e, i) => {
              return (i + 1 + ") " + e.reason) as string;
            });
            this.alertMessage.message = message.join("\n");
            console.log("res alertMessage", this.alertMessage);
          }
        });
      }

      // if (groupCode === "3" || groupCode === "8") {
      //   this.appNotificationService.getNotRegisteratedEmailInvoiceList(companyId).then((res) => {
      //     if (res === 'error') {
      //       return;
      //     } else {

      //       if (res?.totalItem > 0) {
      //         this.notRegisteredData = res;

      //         this.dialog.open(NotRegisteredEmailAlertDialogComponent, {
      //           width: '60vw',
      //           data: this.notRegisteredData,
      //           disableClose: true
      //         });
      //       }
      //     }
      //   });
      // }
    }
  }

  changePassword() {
    var dialogRef = this.dialog.open(PasswordChangeComponent, {
      disableClose: true,
      width: "50vw",
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.viewPassword(false);

        const formData = {
          username: this.userProfile.userName,
          uId: this.userProfile.uId
            ? this.userProfile.uId
            : this.userProfile.uid,
        };
        this.appUserService.getUserProfile(formData).then((res) => {
          this.user = res;
        });
      }
    });
  }

  viewPassword(bool: boolean) {
    this.viewPin = bool;

    if (bool) {
      var reqData = this.user.passwordHashed;

      reqData.uId = this.userProfile.uId;

      this.appUserService.getUserPassword(reqData).then((res: any) => {
        if (res === "error") {
          this.viewPin = false;
          return;
        } else {
          this.userPw = this.decryptCaesar(res.password, 3);
        }
      });
    } else {
      this.userPw = null;
    }
  }

  decryptCaesar(ciphertext, shift): string {
    let plaintext = "";
    for (let i = 0; i < ciphertext.length; i++) {
      const char = ciphertext[i];
      if (char.match(/[a-z]/i)) {
        const baseChar = char.toUpperCase() === char ? "A" : "a";
        const decryptedChar = String.fromCharCode(
          ((char.charCodeAt(0) - baseChar.charCodeAt(0) - shift + 26) % 26) +
            baseChar.charCodeAt(0)
        );
        plaintext += decryptedChar;
      } else {
        plaintext += char;
      }
    }
    return plaintext;
  }
}
