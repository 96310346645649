import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppCompanyService } from "app/business/service/app/app-company.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { IGetCompanyStaffResponse,IGetCompanyTypeListResponse } from "app/business/interface/app/app-company.interface";


@Component({
  selector: 'app-compamy-staff-inquiry',
  templateUrl: './compamy-staff-inquiry.component.html',
  styleUrls: ['./compamy-staff-inquiry.component.css']
})
export class CompamyStaffInquiryComponent implements OnInit {

  searchForm: any ={};
  form: any = {};
  dataSource = new MatTableDataSource<any>();
  dataDropdown : any[] = [];
  type : string;
  displayedColumns: string[] = [
    "taxID",
    "nameTh",
    "nameEn",
    "phone",
    "companyTypeName",
    "action",
  ];
  dataView : any = [];
  dataViewDropdown : IGetCompanyTypeListResponse[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppCompanyService: AppCompanyService,) 
    { 

    }


  ngOnInit() {
    this.searchForm.Type = 'All';
    this.loadData();
    this.loadDropdown();

  }
  async loadData(): Promise<void> {
    this.type = this.searchForm.Type;
    if(this.searchForm.Type == 'All') {
      this.type = ' ';
    }
    const formData = {
      taxNo : this.searchForm.TaxNo,
      phoneNo : this.searchForm.Phone,
      CompanyNameTH : this.searchForm.ThaiName,
      CompanyNameEN : this.searchForm.EnglishName,
      companyType : this.type,
    };
    this.AppCompanyService.GetCompanyStaff(formData).then((res) => {
        this.dataView = res;
        this.dataSource = new MatTableDataSource(this.dataView);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  stopPropagation(event) {
    event.stopPropagation();
  }
  async loadDropdown(): Promise<void> {
    //send data to api
    const formData = {

    };
     await this.AppCompanyService.getCompanyTypeList(formData).then((res)=>{ 
      this.dataDropdown = res
    });
  
  }


  

}
