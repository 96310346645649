<!-- <h1 mat-dialog-title align="center">{{data.header}}</h1>
<div mat-dialog-content [innerHTML]="data.content" align="center" class="pb-20">

</div>
<div mat-dialog-actions align="center">
  <button mat-stroked-button  class= "default-button" *ngIf="data.showCancelBtn" [mat-dialog-close]="false" [mat-dialog-close]>{{data.cancelText}}</button>
  <button mat-raised-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>{{data.confirmText}}</button>
</div> -->

<div (keydown.enter)="$event.preventDefault()">
  <div style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
  background-color: white;font-size: 20px;border-radius: 6px;" class=" pl-5 pr-5">
    <!-- <h2 style="text-align: center;"> {{data.header}}</h2> -->
  </div>
  <div mat-dialog-content class="flex flex-col  justify-center items-center">
    <h3 align="center" style=" margin-bottom: 0px; font-size: 1.25em;">{{data.content}}</h3>
  </div>
  <div mat-dialog-actions align="center">
    <button *ngIf="data.showCancelBtn" mat-stroked-button class="default-button" [mat-dialog-close]="false"
      [mat-dialog-close]>{{data.cancelText}}</button>
    <button mat-raised-button color="accent" mat-dialog-close cdkFocusInitial [mat-dialog-close]="true" style="background-color: #003f80 !important;
   color: white!important; ">{{data.confirmText}}</button>
  </div>
</div>