<mat-toolbar class="bgGray">
  <button mat-icon-button class="bglightgrey" routerLink= "/page/company/company-staff">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <span class="nav-spacer-title-right"></span>
  <strong>Company Management</strong>
</mat-toolbar>
<div class="flex flex-col p10">
  <div style="padding: 0px 10px 0px 10px;">
    <!-- Title -->
    <div class="flex flex-col p10">
      <mat-card>
        <div class = "pl-2 pt-5" style="align-self: left;"> MR UPDATE : {{form.changeUpCom}}</div>
        <div class="flex">
          <div class="flex-1">
            <div class="flex flex-row p10">
              <div style="align-self: center;"> วันหมดอายุ : </div>
              <div class="pl-2">
                <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                    [(ngModel)]="form.expire" name="expire" #expireDate="ngModel" readonly>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="flex-1">
            <div class="flex flex-row p10">
              <div style="align-self: center;">แก้ไขครั้งล่าสุดเมื่อ : </div>
              <div class="pl-2">
                <mat-form-field class="w100">
                  <input autocomplete="off" matInput [(ngModel)]="form.update"
                    name="update" #update="ngModel" readonly>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <!-- ข้อมูลบริษัท -->
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          ข้อมูลบริษัท
        </mat-card-header>
        <mat-card-content>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ใบทะเบียนเลขที่ ผค. / ผธ. : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.registrationNopk" name="registrationNopk" #registrationNopk="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">  
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> รหัสสมาชิก : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input  autocomplete="off" type="text" matInput 
                      #input [(ngModel)]="form.membercode" name="membercode" #membercode="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> เลขนิติบุคคล/บัตรประชาชน : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input  autocomplete="off" type="text" matInput 
                      #input [(ngModel)]="form.idNo" name="idNo" #idNo="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>  
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> เลขประจำตัวผู้เสียภาษี : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input  autocomplete="off" type="text" matInput 
                      #input [(ngModel)]="form.taxID" name="taxID" #taxID="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ประเภทธุรกิจ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input  autocomplete="off" type="text" matInput 
                      #input [(ngModel)]="form.taxNoResponse" name="taxNoResponse" #taxNoResponse="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ชื่อบริษัทภาษาไทย : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.nameTh" name="nameTh" #nameTh="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
  
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ชื่อบริษัทภาษาอังกฤษ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.nameEn" name="nameEn" #nameEn="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
  
              </div>
            </div>
          </div>
          
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ที่อยู่ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.addressStreet" name="addressStreet" #addressStreet="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
  
              </div>
            </div>
          </div>
          

          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  แขวง/ตำบล : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.district" name="district" #district="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> เขต/อำเภอ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input  autocomplete="off" type="text" matInput 
                      #input [(ngModel)]="form.subProvince" name="subProvince" #subProvince="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  จังหวัด : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.province" name="province" #province="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> รหัสไปษณีย์ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input  autocomplete="off" type="text" matInput 
                      #input [(ngModel)]="form.postcode" name="postcode" #postcode="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  หมายเลขโทรศัพท์ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.phone" name="phone" #phone="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> โทรสาร : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input  autocomplete="off" type="text" matInput 
                      #input [(ngModel)]="form.fax" name="fax" #fax="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  อีเมล์ที่รับใบกำกับภาษี : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.contactEmail" name="contactEmail" #contactEmail="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
  
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-4/5">
                    <input  type="checkbox" disabled="true" readonly> ข้าพเจ้ายินยอมให้ระบบส่งใบกำกับภาษี 
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
  
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          ผู้ติดต่อ
        </mat-card-header>
        <mat-card-content>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ชื่อ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.contactName" name="contactName" #contactName="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> นามสกุล : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.contactLastname" name="contactLastname" #contactLastname="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  หมายเลขโทรศัพท์ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.contactPhone" name="contactPhone" #contactPhone="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> อีเมล์ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.contactEmail" name="contactEmail" #contactEmail="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          สินค้าที่ได้รับอนุญาต
        </mat-card-header>
        <mat-card-content>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  </div> 
                <div class="basis-4/5">
                    <mat-checkbox   [checked]="form.rice ==='Y' ? true : false" [disabled]="true">ข้าวหอมมะลิไทย</mat-checkbox><br>
                    <mat-checkbox   [checked]="form.corn ==='Y' ? true : false" [disabled]="true">ข้าวโพด</mat-checkbox><br>
                    <mat-checkbox   [checked]="form.greenbean ==='Y' ? true : false" [disabled]="true">ถั่วเขียว</mat-checkbox><br>
                    <mat-checkbox   [checked]="form.fishmeal ==='Y' ? true : false" [disabled]="true">ปลาป่น</mat-checkbox><br>
                    <mat-checkbox   [checked]="form.fluff ==='Y' ? true : false" [disabled]="true">ปุยนุ่น</mat-checkbox>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> </div>
                <div class="basis-4/5">
                  <mat-checkbox   [checked]="form.cassava ==='Y' ? true : false" [disabled]="true">ผลิตภัณฑ์มันสำปะหลัง</mat-checkbox><br>
                  <mat-checkbox   [checked]="form.millet ==='Y' ? true : false" [disabled]="true">ข้าวฟ่าง</mat-checkbox><br>
                  <mat-checkbox   [checked]="form.blackmatpe ==='Y' ? true : false" [disabled]="true">ถั่วเขียวผิวดำ</mat-checkbox><br>
                  <mat-checkbox   [checked]="form.whiterice ==='Y' ? true : false" [disabled]="true">ข้าวขาว</mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          ลายมือชื่อผู้ที่ได้รับมอบอำนาจ
        </mat-card-header>
        <mat-card-content>
          <div class="flex-1 w-64">
            <div class="flex flex-row p10">
              <div class="mat-button">
                <button mat-raised-button color="accent" (click)="exportCompanyPDF()">
                  <div style="display: flex;align-items: left;">
                    <span>Preview</span>
                  </div>
                </button>
              </div>
            </div>
          </div>  
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ผู้ได้รับมอบอำนาจคนที่ 1 : </div>
                <div class="basis-4/5">
                    <div>{{form.signpic1}}</div>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ชื่อ/นามสกุล(ไทย): </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.signname_th1" name="signname_th1" #signname_th1="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(อังกฤษ) : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.signname_en1" name="signname_en1" #signname_en1="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ตำแหน่ง : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.position1" name="position1" #position1="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
              </div>
            </div>
          </div>
          <hr>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ผู้ได้รับมอบอำนาจคนที่ 2 : </div>
                <div class="basis-4/5">
                  <div>{{form.signpic2}}</div>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ชื่อ/นามสกุล(ไทย): </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.signname_th2" name="signname_th2" #signname_th2="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(อังกฤษ) : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.signname_en2" name="signname_en2" #signname_en2="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ตำแหน่ง : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.position2" name="position2" #position2="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
              </div>
            </div>
          </div>
          <hr>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ผู้ได้รับมอบอำนาจคนที่ 3 : </div>
                <div class="basis-4/5">
                  <div>{{form.signpic3}}</div>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ชื่อ/นามสกุล(ไทย): </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.signname_th3" name="signname_th3" #signname_th3="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(อังกฤษ) : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.signname_en3" name="signname_en3" #signname_en3="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ตำแหน่ง : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.position3" name="position3" #position3="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
              </div>
            </div>
          </div>
          <hr>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ผู้ได้รับมอบอำนาจคนที่ 4 : </div>
                <div class="basis-4/5">
                  <div>{{form.signpic4}}</div>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ชื่อ/นามสกุล(ไทย): </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.signname_th4" name="signname_th4" #signname_th4="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(อังกฤษ) : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.signname_en4" name="signname_en4" #signname_en4="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;">  ตำแหน่ง : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.position4" name="position4" #position4="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          เครื่องหมายที่ใช้เกี่ยวกับการส่งสินค้าออก
        </mat-card-header>
        <mat-card-content >
         <div *ngFor="let element of form.shippingMasksList"> 
            <div class="flex">
              <div class="flex-1 w-50" style="border-style: solid;">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">ประเภทของสินค้า : </div>
                   <div>{{element.ProductName}}</div> 
                </div>
              </div>
              <div class="flex-1 w-50" style="border-style: solid;">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">  </div>
                  <div>  <input type="image" 
                    class="txt-box"
                    #input
                    width="200" 
                    height="150"
                    [src]="element.path"
                    readonly></div> 
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-50 p10" style="border-style: solid;">
                <div class="flex flex-row">
                  <div class="basis-1/2 f18" style="align-self: center;"> เลขที่ : </div>
                  <div> {{element.no}}</div> 
                </div>
              </div>
            </div> 
            <hr>
        </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          รายชื่อตัวแทนที่ได้รับอนุญาต
        </mat-card-header>
        <mat-card-content >
          <table mat-table matSort [dataSource]="form.shippingRelationList" > 
            <!--**********1.companyName**********-->
            <ng-container matColumnDef="companyName">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ชื่อตัวแทน</th>
              <td mat-cell *matCellDef="let element" style="padding-left: 80px;" > {{element.companyName}} </td>
            </ng-container>
            <!--**********2.isActive**********-->
            <ng-container matColumnDef="isActive">
              <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
                อนุมัติ</th>
              <td mat-cell *matCellDef="let element"> {{element.isActive}} </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          ตราบริษัท
        </mat-card-header>
        <mat-card-content >
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-4/5">
                  <input type="image" 
                    class="txt-box"
                    #input
                    width="200" 
                    height="150"
                    [src]="form.trademark"
                    readonly>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
  
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>