import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiBankService {

  constructor(private apiService: ApiService) { }

  getTestList(any): Observable<any[]> {
    return this.apiService.post("/api/ConfigurationgetMasterbank", any);
  }

  bankAccount(any): Observable<any[]> {
    return this.apiService.post("/api/ConfigurationgetMasterbank", any);
  }

  saveBankAccount(any): Observable<any[]> {
    return this.apiService.post("/api/ConfigurationSaveMasterbank", any);
  }
  
  bankCode(any): Observable<any[]> {
    return this.apiService.post("/api/ConfigurationgetListCodeBank", any);
  }
}
