import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-re-calculate-fob-rate',
  templateUrl: './re-calculate-fob-rate.component.html',
  styleUrls: ['./re-calculate-fob-rate.component.css']
})
export class ReCalculateFobRateComponent implements OnInit {
  form: any = {};

  constructor(
    public dialogRef: MatDialogRef<ReCalculateFobRateComponent>,
  ) { }

  ngOnInit() {
  }

  save() {

  }
}
