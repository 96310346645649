import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { AppO2Service } from "app/business/service/app/app-o2.service";
import { AuthService } from "app/core/auth/auth.service";
import { DxDataGridComponent } from "devextreme-angular";
import { CreateShortModalComponent } from "../create-short-modal/create-short-modal.component";

@Component({
  selector: "app-short-ms24-inquiry",
  templateUrl: "./short-ms24-inquiry.component.html",
  styleUrls: ["./short-ms24-inquiry.component.scss"],
})
export class ShortMs24InquiryComponent implements OnInit {
  searchForm: any = {};
  form: any = {};
  productList: any = {};
  statusList: any = {};
  dataSource = new MatTableDataSource<any>();
  isLoading = false;
  count: number;
  ms13Id: any;
  displayedColumns: string[] = [
    "number",
    "refNo",
    "requestDate",
    "exporterName",
    "description",
    "statusCode",
    "action",
  ];

  url: any;
  buttonList: any[] = [];
  timer: any;
  searchString: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private appO2Service: AppO2Service,
    private confirmService: ConfirmDialogService,
    private appMs24Service: AppMs24Service,
    private router: Router,
    private _authService: AuthService,
    private appMenuService: AppMenuService,
  ) {
    if (this.userProfile.userGroupCode == "UG002" || this.userProfile.userGroupCode == "UG003") {
      var data = this.displayedColumns.filter(e => e != "exporterName");
      this.displayedColumns = [];
      this.displayedColumns = data;
    }
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    await this.getRouter();
    this.searchForm = {};

    if (this.userProfile.userGroupCode == "UG002" || this.userProfile.userGroupCode == "UG003") {
      this.searchForm.cpId = parseInt(this.userProfile.cpId)
    } else {
      this.searchForm.cpId = null;
    }
    this.searchForm.refNo = "";
    this.searchForm.description = "";
    this.searchForm.requestDate = null;
    this.searchForm.statusId = null;

    this.loadData();
    this.searchForm.nameSearch = "";
  }


  clearOption() {
    this.searchForm = {};

    if (this.userProfile.userGroupCode == "UG002" || this.userProfile.userGroupCode == "UG003") {
      this.searchForm.cpId = parseInt(this.userProfile.cpId)
    } else {
      this.searchForm.cpId = null;
    }
    this.searchForm.refNo = "";
    this.searchForm.description = "";
    this.searchForm.requestDate = null;
    this.searchForm.statusId = null;
    this.searchForm.nameSearch = "";

    this.loadDataSource();
  }

  async loadData() {
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: 0,
      isActive: "Y",
    };
    await this.appMasterService.getProduct(formProduct).then((product) => {
      //console.log(product);
      this.productList = product;
    });

    //this.productList = await this.appMasterService.getProduct(formProduct);
    // ;
    const formStatus = {
      statusId: 0,
      isActive: "Y",
    };
    // this.statusList = await this.appMasterService.getStatus(formStatus);
    await this.appMasterService.getStatus(formStatus).then((status) => {
      //console.log(status);
      this.statusList = status;
    });

    this.loadDataSource();
  }

  loadDataSource() {
    this.isLoading = true;
    const formData = {
      UID: this.userProfile.uId,
      cpId: this.searchForm.cpId,
      keyword: this.searchForm.keyword,
      refNo: this.searchForm.refNo,
      description: this.searchForm.description,
      requestDate: this.searchForm.requestDate,
      status: this.searchForm.statusId,
      pagination: {
        Skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    this.appMs24Service.getMs24ShortRequestLists(formData).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        var dataSourceList = res.data.map((x, n) => {
          return {
            ...x,
            statusCode: this.statusList.find((f) => f.statusId == x.status)?.statusName
          };
        });
        this.dataSource.data = dataSourceList;
        this.count = res.totalItem
      }
    })
    this.dataSource.paginator.length = this.count;
    this.isLoading = false;
  }

  async _page(e) {
    this.isLoading = true;

    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    this.searchForm.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };

    const formData = {
      UID: this.userProfile.uId,
      cpId: this.searchForm.cpId,
      keyword: this.searchForm.keyword,
      refNo: this.searchForm.refNo,
      description: this.searchForm.description,
      requestDate: this.searchForm.requestDate,
      status: this.searchForm.statusId,
      pagination: this.searchForm.pagination
    };
        
    await this.appMs24Service.getMs24ShortRequestLists(formData).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        var dataSourceList = res.data.map((x, n) => {
          return {
            ...x,
            //rowNo: (formData.pagination.skip * formData.pagination.take)+(n+1),
            action: "",
            statusCode: this.statusList.find((f) => f.statusId == x.status)?.statusName,
          };
        });
        this.dataSource.data = dataSourceList;
        this.count = res.totalItem
      }
    })
    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: Event) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.searchForm.keyword = event;
      this.dataSource.paginator = this.paginator;
      this.loadDataSource();
    }, 500); // Specify the desired delay in milliseconds
  }


  deleteItem(e) {
    // -สถานะเปลี่ยนเป็น Save to Database
    this.confirmService.open({
      header: "",
      content: "ต้องการลบคำร้องขอทำ Short นี้หรือไม่?",
    }).then(res => {
      if (res) {
        console.log(e)
        var rqDeleteShort = {
          shortId: e.shortId,
          status: 12
        };

        this.appMs24Service.saveMs24ShortActiveStatus(rqDeleteShort).then(res => {
          if (res === 'error') {
            return;
          } else {
            this.loadDataSource();
          }
        })
      }
    });
  }

  updateStatusApprove(e, status) {
    var message = "";

    if (status === 9) {
      message = "คุณต้องการอนุมัติคำร้องขอทำ Short นี้หรือไม่?"
    } else if (status === 4) {
      message = "คุณต้องการปฏิเสธคำร้องขอทำ Short นี้หรือไม่?"
    } else if (status === 1) {
      message = "คุณต้องการถอนคำร้องขอทำ Short หรือไม่?"
    } else if (status === 2) {
      message = "คุณต้องการยื่นคำร้องขอทำ Short นี้อีกครั้งไม่?"
    }

    this.confirmService.open({
      header: "",
      content: message,
    }).then(res => {
      if (res) {
        console.log(e)
        var rqUpdateApprove = {
          shortId: e.shortId,
          status: status
        };

        this.appMs24Service.saveMs24ShortApproveStatus(rqUpdateApprove).then(res => {
          if (res === 'error') {
            return;
          } else {
            this.loadDataSource();
          }
        })
      }
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  // menuOpen() {}
  menuClosed() {
    console.log("menuClosed @configbug");
  }

  searchOption() {
    this.searchForm.keyword = "";
    this.searchString = "";

    this.loadDataSource();
  }

  printItem(e) {
    
  }

  onRefresh() {
    this.clearOption();
  }
}
