import { LiveAnnouncer } from '@angular/cdk/a11y';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { AppCompanyService } from 'app/business/service/app/app-company.service';
import { IgetCompanyProfileListRequest } from 'app/business/interface/app/app-co.interface';
import { ConnectMrDialogComponent } from '../connect-mr-dialog/connect-mr-dialog.component';
import { Router } from '@angular/router';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-company-list-inquiry',
  templateUrl: './company-list-inquiry.component.html',
  styleUrls: ['./company-list-inquiry.component.css']
})
export class CompanyListInquiryComponent implements OnInit, AfterViewInit {
  companyTypeList: any = [];
  BenefitTarget: any = [];
  form: any = {};
  BenefitTargetValue: string;
  displayedColumns: string[] = ['number', 'companyTaxId', 'cpId', 'companyNameTh', 'companyNameEn', 'phone', 'typeId', 'action'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  isLoading = false;
  userProfile: any;
  count: number;
  timer: any;
  apiRequest: IgetCompanyProfileListRequest = {
    cpId: null,
    // companyCode: null,
    companyNameEn: null,
    companyNameTh: null,
    phone: null,
    typeId: null,
    companyTaxId: null,
    pagination: {
      Skip: 0,
      Take: 10
    }
  };

  url: any;
  buttonList: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private appCompanyService: AppCompanyService,
    private router: Router,
    private appMenuService: AppMenuService,
    private _authService:AuthService,
    private appUserService: AppUserService,
  ) { }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }

  async ngOnInit() {
    this.userProfile = JSON.parse(this._authService.loginUser);
    this.userProfile = {
      ...this.userProfile,
      cid:this.userProfile.cpId,
      userName:this.userProfile.name,
    };

   
    // this.comTypeList = await this.appMasterService.getShippingMask(null);

    this.appMasterService.getCompanyAddressType("").then((res) => {
      if (res === "error") {
        this.companyTypeList = [];
      } else {
        this.companyTypeList = res;
      }
    });

    this.dataSource.paginator = this.paginator
    this.apiRequest.pagination = {
      Skip:
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
      Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
    }
    this.loadData(this.apiRequest)
    await this.getRouter();

  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }
  async loadData(request: IgetCompanyProfileListRequest) {
    this.isLoading = true;
    await this.appCompanyService.getCompanyProfileList(request).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res !== null) {
          this.dataSource.data = res.companyList;
          this.count = res.totalItem;
          this.isLoading = false;
        } else {
          this.dataSource.data = []
          this.count = 0
          this.isLoading = false;
        }
      }
    })
    this.dataSource.paginator.length = this.count
  }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer
  
    this.timer = setTimeout(() => {
      this.apiRequest.filterValue = event;
      this.dataSource.paginator = this.paginator
      this.loadData(this.apiRequest)
  
    }, 500); // Specify the desired delay in milliseconds
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }
  stopPropagation(event) {
    event.stopPropagation()
  }
  onSearch() {
    this.apiRequest.cpId = Number(this.form.cpId);
    this.apiRequest.companyTaxId = this.form.companyTaxId;
    this.apiRequest.companyNameTh = this.form.companyNameTh;
    this.apiRequest.companyNameEn = this.form.companyNameEn;    
    this.apiRequest.phone = this.form.phone;
    this.apiRequest.typeId = this.form.typeId;
    this.dataSource.paginator = this.paginator
    this.loadData(this.apiRequest)
  }

  print() {}
  showDetail() {}
  editItem() {}

  async _page(e) {
    this.isLoading = true;
    this.apiRequest.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    }

    await this.appCompanyService.getCompanyProfileList(this.apiRequest).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        if (res.response !== null) {
          this.dataSource.data = res.companyList;
        } else {
          this.dataSource = new MatTableDataSource((res.response3 = []))
        }
        this.count = res.totalItem
      }
    })
    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
    this.isLoading = false;
  }

  clearOption() {
    this.form = {}
    this.apiRequest.cpId = null;
    this.apiRequest.companyTaxId = '';
    this.apiRequest.companyNameTh = '';
    this.apiRequest.companyNameEn = '';
    this.apiRequest.phone = '';
    this.loadData(this.apiRequest)
  }

  openMrConfig() {
    this.dialog.open(ConnectMrDialogComponent, {
      width: '50vw',
      disableClose: true
    });
  }
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
