import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-export-doc-inquiry',
  templateUrl: './export-doc-inquiry.component.html',
  styleUrls: ['./export-doc-inquiry.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExportDocInquiryComponent implements OnInit, AfterViewInit {
  BenefitTarget: any = [];
  form: any = {};
  BenefitTargetValue: string;
  displayedColumns: string[] = ['select', 'position', 'name', 'weight', 'tax', 'enabled', 'action'];
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.BenefitTarget = [{ benefitTargetCode: 1, benefitTargetTName: '10' }, { benefitTargetCode: 2, benefitTargetTName: '20' }];
    this.dataSource = new MatTableDataSource([
      { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H', action: '' },
      { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He', action: '' },
      { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li', action: '' },
      { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be', action: '' },
      { position: 5, name: 'Boron', weight: 10.811, symbol: 'B', action: '' },
      { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C', action: '' },
      { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N', action: '' },
      { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O', action: '' },
      { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F', action: '' },
      { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne', action: '' },
      { position: 11, name: 'Sodium', weight: 22.9897, symbol: 'Na', action: '' },
      { position: 12, name: 'Magnesium', weight: 24.305, symbol: 'Mg', action: '' },
      { position: 13, name: 'Aluminum', weight: 26.9815, symbol: 'Al', action: '' },
      { position: 14, name: 'Silicon', weight: 28.0855, symbol: 'Si', action: '' },
      { position: 15, name: 'Phosphorus', weight: 30.9738, symbol: 'P', action: '' },
      { position: 16, name: 'Sulfur', weight: 32.065, symbol: 'S', action: '' },
      { position: 17, name: 'Chlorine', weight: 35.453, symbol: 'Cl', action: '' },
      { position: 18, name: 'Argon', weight: 39.948, symbol: 'Ar', action: '' },
      { position: 19, name: 'Potassium', weight: 39.0983, symbol: 'K', action: '' },
      { position: 20, name: 'Calcium', weight: 40.078, symbol: 'Ca', action: '' },
    ])
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  selectionPercent() {

  }
  editItem(e) {

  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  save() {

  }

}