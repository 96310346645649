import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'

@Component({
  selector: 'app-district-manage-popup',
  templateUrl: './district-manage-popup.component.html',
  styleUrls: ['./district-manage-popup.component.css']
})
export class DistrictManagePopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  regionList: any[] = [];
  provinceList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<DistrictManagePopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
      this.form.geoId = 0;
      this.form.provinceId = 0;
    }
    
  }
  ngOnInit(): void {
    this.regionList = [
      {
        "regionCode": 0,
        "regionName": "กรุณาเลือก"
      },
      {
        "regionCode": 1,
        "regionName": "ภาคเหนือ"
      },
      {
        "regionCode": 2,
        "regionName": "ภาคกลาง"
      },
      {
        "regionCode": 3,
        "regionName": "ภาคตะวันออกเฉียงเหนือ"
      },
      {
        "regionCode": 4,
        "regionName": "ภาคตะวันตก"
      },
      {
        "regionCode": 5,
        "regionName": "ภาคตะวันออก"
      },
      {
        "regionCode": 6,
        "regionName": "ภาคใต้"
      }
    ]

    this.loadData();
  }

  loadData() {
    const data = {
      geoId: this.form.geoId
    };

    this.appMasterService.getMasterProvince(data).then(res => {
      if (res === "error") {
        return;
      } else {
        this.provinceList = res;

        const CheckAll = this.provinceList.find(element => element.provinceName === "กรุณาเลือก");
        if (CheckAll === undefined) {
          this.provinceList.unshift({ "provinceId": 0, "provinceName": "กรุณาเลือก" });
        }
      }
    });
  }

  onGeoChange(e) {
    if (e.value == 0) {
      this.form.geoId = null;
    }

    this.loadData();
    this.form.provinceId = 0;
  }


  save() {
    
    if ((this.form.districtDgaCode !== undefined && this.form.districtDgaCode !== null && this.form.districtDgaCode !== "") &&
        (this.form.districtName !== undefined && this.form.districtName !== null && this.form.districtName !== "") &&
        (this.form.districtEname !== undefined && this.form.districtEname !== null && this.form.districtEname !== "") &&
        (this.form.provinceId !== 0)) {
      const data = {
        id: this.form.id,
        districtName: this.form.districtName,
        districtEname: this.form.districtEname,
        districtDgaCode: this.form.districtDgaCode,
        provinceId: this.form.provinceId,
        isActive: this.form.isActive
      };

      this.appMasterService.saveDistrict(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });

    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}

