import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMs13Service } from "app/business/service/app/app-ms13.service";
import { findLastIndex, isNumber } from "lodash";

@Component({
  selector: "app-surveyor-check-result",
  templateUrl: "./surveyor-check-result.component.html",
  styleUrls: ["./surveyor-check-result.component.css"],
})
export class SurveyorCheckResultComponent implements OnInit {
  form: any = {};
  formItem: any = {};
  dataSource: any = [];
  datas: any = {};
  productList: any[] = [];
  qtyUnitList: any = [];
  qtyUnitOneList: any = [];
  panelOpenState: boolean = false;
  editItem: boolean = false;
  disabled: boolean = false;
  columns: any[] = [];
  options: any = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6
  };

  constructor(
    private appMasterService: AppMasterService,
    private alertMessageService: AlertMessageService,
    private confirmMessageService: ConfirmDialogService,
    private appMs13Service: AppMs13Service,
    public dialogRef: MatDialogRef<SurveyorCheckResultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.datas = data;
  }

  async ngOnInit() {
    await this.loadData();

    if (this.datas.surveyId != undefined && this.datas.surveyId != null) {
      this.loadSurveyData(this.datas.surveyId)
    }

    if (this.datas.disabled != undefined && this.datas.disabled != null) {
      this.disabled = this.datas.disabled;
    }
  }

  async loadData() {
    this.appMasterService.getQtyUnit({ ID: "" }).then((res) => {
      this.qtyUnitList = res.map(el => {
        return {
          ...el,
          name: el.code == "AB" || el.code == "LO" ? el.name + " (1 " + el.code + ")" : el.name
        }
      });
    });
    this.appMasterService.getQtyUnitOne({ ID: "" }).then((ress) => {
      this.qtyUnitOneList = ress;
    });

    console.log(this.datas);
    this.form.hId = this.datas.hId;

    if (this.datas.docTypeId == 2) {
      this.form.checkResult = "เทียบได้ไม่ต่ำกว่ามาตรฐานที่กระทรวงพาณิชย์กำหนด";
      this.form.checkPackageResult = "ส่งออกโดยไม่มีการบรรจุหุ้มห่อ";
    } else {
      this.form.checkResult = "เทียบได้ไม่ต่ำกว่ามาตรฐานที่กระทรวงพาณิชย์กำหนด";
      this.form.checkPackageResult = "อยู่ในสภาพที่เรียบร้อยเหมาะสำหรับการส่งออก";
    }
    this.form.checkQuantityResult = "กระสอบที่บรรจุถูกต้อง";

    this.productList = this.datas.productList.map(e => {
      return {
        ...e,
        fobValueBaht: e.fobValueBaht != null ? isNumber(e.fobValueBaht) ? e.fobValueBaht : Number(e.fobValueBaht?.replaceAll(',', '')) : 0,
        fobValueUsd: e.fobValueUsd != null ? isNumber(e.fobValueUsd) ? e.fobValueUsd : Number(e.fobValueUsd?.replaceAll(',', '')) : 0,
        grossWeightPerUnit: e.grossWeightPerUnit != null ? isNumber(e.grossWeightPerUnit) ? e.grossWeightPerUnit : Number(e.grossWeightPerUnit?.replaceAll(',', '')) : 0,
        netWeight: e.netWeight != null ? isNumber(e.netWeight) ? e.netWeight : Number(e.netWeight?.replaceAll(',', '')) : 0,
        netWeightPerUnit: e.netWeightPerUnit != null ? isNumber(e.netWeightPerUnit) ? e.netWeightPerUnit : Number(e.netWeightPerUnit?.replaceAll(',', '')) : 0,
        quantity: e.quantity != null ? isNumber(e.quantity) ? e.quantity : Number(e.quantity?.replaceAll(',', '')) : 0,
        unitPrice: e.unitPrice != null ? isNumber(e.unitPrice) ? e.unitPrice : Number(e.unitPrice?.replaceAll(',', '')) : 0,
        productName: this.datas.goodsName
      }
    });

    console.log(this.productList)

    this.columns = [
      {
        caption: 'ลำดับรายการ',
        cellTemplate: 'wFlow',
        alignment: 'center',
        width: '100',
      },
      {
        dataField: 'goodsDescription',
        caption: 'รายการสินค้า',
        dataType: 'string',
        alignment: 'center',
      },
      {
        caption: 'ปริมาณที่ตรวจ',
        cellTemplate: 'qty',
        alignment: 'center',
      },
      {
        caption: 'น้ำหนักเฉพาะต่อหน่วย',
        cellTemplate: 'nw',
        alignment: 'center',
      },
      {
        caption: 'น้ำหนักที่ตรวจ',
        cellTemplate: 'sgnw',
        alignment: 'center',
      },
      {
        caption: 'จัดการ',
        cellTemplate: 'action',
        alignment: 'center'
      }
    ]

  }

  async loadSurveyData(e) {
    var rqSurveyResult = {
      surveyId: e
    };

    this.appMs13Service.getMs13SurveyorFullResult(rqSurveyResult).then(res => {
      if (res === 'error') {
        return;
      } else {
        this.form.surveyId = res.surveyId;
        this.form.hId = res.hId;
        this.form.checkResult = res.checkResult;
        this.form.checkQuantityResult = res.checkQuantityResult;
        this.form.checkPackageResult = res.checkPackageResult;
        this.form.surveyRemark = res.surveyRemark;

        this.dataSource = res.itemList.map((e, i) => {
          var unit = this.qtyUnitList.find(el => el.id == e.quantityUnit);
          var surveyUnit = this.qtyUnitList.find(el => el.id == e.surveyQuantityUnit);
          return {
            ...e,
            id: i + 1,
            itemNo: e.itemNo != null ? Number(e.itemNo) : i + 1,
            quantityUnitId: unit.id,
            surveyQuantityUnitId: surveyUnit.id,
            quantityUnit: unit.code,
            surveyQuantityUnit: surveyUnit.code
          }
        });
      }
    })
  }

  selectProduct(e) {
    let selectedItem = this.productList.find(el => el.itemNo === e.value);

    console.log(selectedItem)

    this.formItem = {};

    this.formItem = { ...selectedItem };
    this.formItem.surveyQuantity = Number(this.formItem.quantity).toLocaleString('en', this.options);

    var unit = this.qtyUnitList.find(el => el.code == this.formItem.quantityUnit);
    this.formItem.quantityUnitId = unit.id;
    this.formItem.surveyQuantityUnitId = unit.id;
    this.formItem.surveyQuantityUnit = this.formItem.quantityUnit;
    this.formItem.surveyQuantityUnitName = unit.name;
    this.formItem.quantityUnitName = unit.name;
    this.formItem.surveyNetWeight = Number(this.formItem.netWeight).toLocaleString('en', this.options);;
    this.formItem.weightPerUnit = this.formItem.noneQty != 'Y' ? this.formItem.netWeightPerUnit : 0;
    this.formItem.surveyNetWeighUnit = this.formItem.noneQty != 'Y' ? Number(this.formItem.netWeightPerUnit).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
    this.formItem.surveyNetWeightUnit = this.formItem.netWeightUnit ?? 'KGM'
    this.formItem.priceBahtPerUnit = this.formItem.noneQty != 'Y' ? this.calPriceBahtPerUnit(this.formItem.netWeight, this.formItem.unitPrice, this.formItem.fobValueBaht) : Number('0').toLocaleString('en', this.options);
    this.formItem.productItemUnit = 'O';
    this.formItem.surveyGrossWeightUnit = this.formItem.noneQty != 'Y' ? Number(this.formItem.grossWeightUnit).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
    this.formItem.itemNo = e.value

    Number('0').toLocaleString('en', this.options);

    var currentCheckWeight = this.dataSource.map(e => Number(e.surveyQuantity) * Number(e.surveyGrossWeightUnit)).reduce((a, b) => a + b, 0);
    this.formItem.availableNetWeight = selectedItem.netWeight - currentCheckWeight;

    this.formItem.isSelected = true;
  }

  editProduct(e) {
    let selectedItem = this.dataSource.find(el => el.id === e);

    this.formItem = {};

    this.formItem = { ...selectedItem };

    this.formItem.surveyQuantity = Number(this.formItem.surveyQuantity).toLocaleString('en', this.options);
    this.formItem.surveyNetWeight = Number(this.formItem.surveyNetWeight).toLocaleString('en', this.options);
    this.formItem.surveyNetWeighUnit = Number(this.formItem.surveyNetWeighUnit).toLocaleString('en', this.options);
    this.formItem.surveyGrossWeightUnit = Number(this.formItem.surveyGrossWeightUnit).toLocaleString('en', this.options);

    var unit = this.qtyUnitList.find(el => el.code == this.formItem.quantityUnit);
    this.formItem.quantityUnitId = unit.id;
    this.formItem.surveyQuantityUnitId = unit.id;
    this.formItem.surveyQuantityUnit = this.formItem.quantityUnit;
    this.formItem.surveyQuantityUnitName = unit.name;
    this.formItem.quantityUnitName = unit.name;
    this.formItem.quantityUnitName = this.qtyUnitList.find(el => el.code == this.formItem.quantityUnit).name;

    this.formItem.weightPerUnit = this.formItem.noneQty != 'Y' ? this.calNetWeightPerUnit(this.formItem.netWeight, this.formItem.quantity) : this.formItem.netWeightPerUnit;
    this.formItem.priceBahtPerUnit = this.formItem.noneQty != 'Y' ? this.calPriceBahtPerUnit(this.formItem.netWeight, this.formItem.unitPrice, this.formItem.fobValueBaht) : 0;
    this.formItem.productItemUnit = 'O';

    this.formItem.grossWeightUnit = this.formItem.noneQty != 'Y' ? Number(this.formItem.grossWeightUnit) : 0;

    var currentCheckWeight = this.dataSource.filter(el => el.id != e).map(e => Number(e.surveyQuantity) * Number(e.surveyGrossWeightUnit)).reduce((a, b) => a + b, 0);
    this.formItem.availableNetWeight = selectedItem.netWeight - currentCheckWeight;

    this.formItem.isSelected = true;
    this.editItem = true;
  }

  resetEdit() {
    this.formItem = {};
    this.formItem.isSelected = false;
    this.editItem = false;
  }

  delProduct(e) {
    this.confirmMessageService.open({
      header: "",
      content: "คุณต้องการลบรายการตรวจสอบนี้หรือไม่?"
    }).then(res => {
      if (res) {
        var filteredDatasource = this.dataSource.filter(el => el.id != e).map((obj, index) => ({ ...obj, id: index + 1 }));
        this.dataSource = [...filteredDatasource];
      }
    })
  }

  calNetWeightPerUnit(netWeight, quantity) {
    let nwValue = !isNaN(netWeight) ? netWeight : 0
    let qtValue = !isNaN(quantity) ? quantity : 0

    let netWeightPerUnit = nwValue / qtValue;
    return netWeightPerUnit.toFixed(6);
  }

  calPriceBahtPerUnit(netWeight, unitPrice, fobValueBaht) {
    let nwValue = !isNaN(netWeight) ? netWeight : 0
    let upValue = !isNaN(unitPrice) ? unitPrice : 0
    let fobValue = !isNaN(fobValueBaht) ? fobValueBaht : 0

    // Calculate the exchange rate
    const exchangeRate = (fobValue * 1000) / (nwValue * upValue);

    let priceBahtPerUnit = upValue * exchangeRate;

    return priceBahtPerUnit.toFixed(6);
  }

  addProduct() {
    //then save
    if (this.editItem) {
      // //check weight first
      // var surGross = Number(this.formItem.surveyGrossWeightUnit?.replaceAll(',', ''));
      // var surNet = Number(this.formItem.surveyNetWeighUnit?.replaceAll(',', ''));

      // if (this.formItem.grossWeightUnit < surGross) {
      //   this.alertMessageService.open({
      //     message: "คุณระบุน้ำหนักรวมต่อหน่วยตรวจปล่อยเกินกว่าน้ำหนักรวมต่อหน่วยที่สามารถตรวจปล่อยได้ กรุณาตรวจสอบน้ำหนักอีกครั้ง",
      //     title: ""
      //   });

      //   return;
      // }

      // if (this.formItem.netWeightPerUnit < surNet) {
      //   this.alertMessageService.open({
      //     message: "คุณระบุน้ำหนักเฉพาะต่อหน่วยตรวจปล่อยเกินกว่าน้ำหนักเฉพาะต่อหน่วยที่สามารถตรวจปล่อยได้ กรุณาตรวจสอบน้ำหนักอีกครั้ง",
      //     title: ""
      //   });

      //   return;
      // }

      let newItem = { ...this.formItem };
      newItem.isSelected = false;
      newItem.surveyQuantity = Number(this.formItem.surveyQuantity?.replaceAll(',', ''));
      newItem.surveyNetWeight = Number(this.formItem.surveyNetWeight?.replaceAll(',', ''));
      newItem.surveyNetWeighUnit = Number(this.formItem.surveyNetWeighUnit?.replaceAll(',', ''));
      newItem.surveyGrossWeightUnit = Number(this.formItem.surveyGrossWeightUnit?.replaceAll(',', ''));

      let currentDatasource = this.dataSource.map((item) =>
        item.id === newItem.id ? newItem : item
      );

      this.dataSource = [...currentDatasource];

      this.formItem = {};
      this.formItem.isSelected = false;
      this.editItem = false;
    } else {
      // //check weight first
      // var surGross = Number(this.formItem.surveyGrossWeightUnit?.replaceAll(',', ''));
      // var surNet = Number(this.formItem.surveyNetWeighUnit?.replaceAll(',', ''));

      // if (this.formItem.grossWeightUnit < surGross) {
      //   this.alertMessageService.open({
      //     message: "คุณระบุน้ำหนักรวมต่อหน่วยตรวจปล่อยเกินกว่าน้ำหนักรวมต่อหน่วยที่สามารถตรวจปล่อยได้ กรุณาตรวจสอบน้ำหนักอีกครั้ง",
      //     title: ""
      //   });

      //   return;
      // }

      // if (this.formItem.netWeightPerUnit < surNet) {
      //   this.alertMessageService.open({
      //     message: "คุณระบุน้ำหนักเฉพาะต่อหน่วยตรวจปล่อยเกินกว่าน้ำหนักเฉพาะต่อหน่วยที่สามารถตรวจปล่อยได้ กรุณาตรวจสอบน้ำหนักอีกครั้ง",
      //     title: ""
      //   });

      //   return;
      // }

      if (this.formItem.itemNo == undefined || this.formItem.itemNo == null) {
        this.alertMessageService.open({
          title: "",
          message: "กรุณาเลือกรายการสินค้าที่ต้องการตรวจสอบก่อนการบันทึก"
        })
      } else {
        var checkData = this.dataSource.find(el => el.itemNo == this.formItem.itemNo);

        if (checkData != undefined && checkData != null) {
          this.alertMessageService.open({
            message: "คุณทำการตรวจสอบรายการสินค้านี้ไปแล้ว หากต้องการแก้ไขกรุณากดแก้ไขจากตารางด้านล่าง",
            title: ""
          });
        } else {
          if (this.formItem.noneQty != 'Y') {
            if (this.formItem.surveyGrossWeightUnit == undefined || this.formItem.surveyGrossWeightUnit == null || this.formItem.surveyGrossWeightUnit == "") {
              this.alertMessageService.open({
                title: "",
                message: "กรุณากรอกน้ำหนักรวมต่อหน่วยก่อนการบันทึก"
              })
            } else {
              let currentDatasource = [...this.dataSource];
              let newItem = { ...this.formItem };
              newItem.isSelected = false;
              newItem.surveyQuantity = Number(this.formItem.surveyQuantity?.replaceAll(',', ''));
              newItem.surveyNetWeight = Number(this.formItem.surveyNetWeight?.replaceAll(',', ''));
              newItem.surveyNetWeighUnit = Number(this.formItem.surveyNetWeighUnit?.replaceAll(',', ''));
              newItem.surveyGrossWeightUnit = Number(this.formItem.surveyGrossWeightUnit?.replaceAll(',', ''));

              const maxId = Math.max(...currentDatasource.map((obj) => obj.id), 0);
              newItem.id = maxId + 1;

              // //remove item if exist
              // var filteredDatasource = currentDatasource.filter(e => !(e.itemId == newItem.itemId));
              // currentDatasource = filteredDatasource;

              currentDatasource.push(newItem);

              this.dataSource = [...currentDatasource];

              this.formItem = {};
              this.formItem.isSelected = false;
            }
          } else {
            let currentDatasource = [...this.dataSource];
            let newItem = { ...this.formItem };
            newItem.isSelected = false;
            newItem.surveyQuantity = Number(this.formItem.surveyQuantity?.replaceAll(',', ''));
            newItem.surveyNetWeight = Number(this.formItem.surveyNetWeight?.replaceAll(',', ''));
            newItem.surveyNetWeighUnit = Number(this.formItem.surveyNetWeighUnit?.replaceAll(',', ''));
            newItem.surveyGrossWeightUnit = Number(this.formItem.surveyGrossWeightUnit?.replaceAll(',', ''));

            const maxId = Math.max(...currentDatasource.map((obj) => obj.id), 0);
            newItem.id = maxId + 1;

            // //remove item if exist
            // var filteredDatasource = currentDatasource.filter(e => !(e.itemId == newItem.itemId));
            // currentDatasource = filteredDatasource;

            currentDatasource.push(newItem);

            this.dataSource = [...currentDatasource];

            this.formItem = {};
            this.formItem.isSelected = false;
          }
        }
      }
    }
  }

  saveSurveyReport() {
    if (this.dataSource.length > 0) {
      this.confirmMessageService.open({
        header: "",
        content: "คุณต้องการบันทึกผลการตรวจสอบหรือไม่?"
      }).then(res => {
        if (res) {
          //api
          console.log(this.form, this.dataSource);

          var rqSaveSurveyReport = {
            surveyId: this.form.surveyId,
            ms13HId: this.form.hId,
            checkResult: this.form.checkResult,
            checkQuantityResult: this.form.checkQuantityResult,
            checkPackageResult: this.form.checkPackageResult,
            surveyRemark: this.form.surveyRemark,
            itemList: this.dataSource.map(e => {
              return {
                ...e,
                itemNo: e.itemNo.toString(),
                quantityUnit: e.quantityUnitId,
                surveyQuantityUnit: e.surveyQuantityUnitId
              }
            })
          }

          this.appMs13Service.saveMs13SurveyResult(rqSaveSurveyReport).then(res => {
            if (res === 'error') {
              return;
            } else {
              this.alertMessageService.open({
                title: "",
                message: "บันทึกผลการตรวจสอบเรียบร้อย"
              }).then(res => {
                this.dialogRef.close(true);
              })
            }
          })
        }
      });
    } else {
      this.alertMessageService.open({
        title: "",
        message: "กรุณาเพิ่มรายละเอียดการตรวจสอบอย่างน้อย 1 รายการก่อนการบันทึก"
      });
    }
  }

  convertToNumber(value: string | null): number {
    return value ? Number(value) : 0;
  }

  onFocusNetWeight() {
    if (this.formItem.surveyNetWeight != undefined && this.formItem.surveyNetWeight != null && this.formItem.surveyNetWeight != "") {
      let _netWeight = this.formItem.surveyNetWeight != undefined && this.formItem.surveyNetWeight != null && this.formItem.surveyNetWeight != "" ? isNumber(this.formItem.surveyNetWeight) ? this.formItem.surveyNetWeight : this.formItem.surveyNetWeight?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_netWeight))) {
        this.formItem.surveyNetWeight = Number('0').toLocaleString('en', this.options);
        return;
      }
      let netWeight = this.formItem.surveyNetWeight != undefined && this.formItem.surveyNetWeight != "" && this.formItem.surveyNetWeight != null ? Number(_netWeight).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', netWeight);

      this.formItem.surveyNetWeight = netWeight;
    }
  }

  onBlurNetWeight() {
    if (this.formItem.surveyNetWeight != undefined && this.formItem.surveyNetWeight != null && this.formItem.surveyNetWeight != "") {
      let _netWeight = this.formItem.surveyNetWeight != undefined && this.formItem.surveyNetWeight != null && this.formItem.surveyNetWeight != "" ? isNumber(this.formItem.surveyNetWeight) ? this.formItem.surveyNetWeight : this.formItem.surveyNetWeight?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_netWeight))) {
        this.formItem.surveyNetWeight = Number('0').toLocaleString('en', this.options);
        return;
      }

      let netWeight = this.formItem.surveyNetWeight != undefined && this.formItem.surveyNetWeight != "" && this.formItem.surveyNetWeight != null ? Number(_netWeight).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', netWeight);

      this.formItem.surveyNetWeight = netWeight;
    }
  }

  onFocusQuantity() {
    if (this.formItem.surveyQuantity != undefined && this.formItem.surveyQuantity != null && this.formItem.surveyQuantity != "") {
      let _quantity = this.formItem.surveyQuantity != undefined && this.formItem.surveyQuantity != null && this.formItem.surveyQuantity != "" ? isNumber(this.formItem.surveyQuantity) ? this.formItem.surveyQuantity : this.formItem.surveyQuantity?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_quantity))) {
        this.formItem.surveyQuantity = Number('0').toLocaleString('en', this.options);
        return;
      }
      let quantity = this.formItem.surveyQuantity != undefined && this.formItem.surveyQuantity != "" && this.formItem.surveyQuantity != null ? Number(_quantity).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', quantity);

      this.formItem.surveyQuantity = quantity;
    }
  }

  onBlurQuantity() {
    if (this.formItem.surveyQuantity != undefined && this.formItem.surveyQuantity != null && this.formItem.surveyQuantity != "") {
      let _quantity = this.formItem.surveyQuantity != undefined && this.formItem.surveyQuantity != null && this.formItem.surveyQuantity != "" ? isNumber(this.formItem.surveyQuantity) ? this.formItem.surveyQuantity : this.formItem.surveyQuantity?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_quantity))) {
        this.formItem.surveyQuantity = Number('0').toLocaleString('en', this.options);
        return;
      }
      let quantity = this.formItem.surveyQuantity != undefined && this.formItem.surveyQuantity != "" && this.formItem.surveyQuantity != null ? Number(_quantity).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', quantity);

      this.formItem.surveyQuantity = quantity;
    }
  }

  onFocusNetWeightUnit() {
    if (this.formItem.surveyNetWeighUnit != undefined && this.formItem.surveyNetWeighUnit != null && this.formItem.surveyNetWeighUnit != "") {
      let _netWeight = this.formItem.surveyNetWeighUnit != undefined && this.formItem.surveyNetWeighUnit != null && this.formItem.surveyNetWeighUnit != "" ? isNumber(this.formItem.surveyNetWeighUnit) ? this.formItem.surveyNetWeighUnit : this.formItem.surveyNetWeighUnit?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_netWeight))) {
        this.formItem.surveyNetWeighUnit = Number('0').toLocaleString('en', this.options);
        return;
      }
      let netWeight = this.formItem.surveyNetWeighUnit != undefined && this.formItem.surveyNetWeighUnit != "" && this.formItem.surveyNetWeighUnit != null ? Number(_netWeight).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', netWeight);

      this.formItem.surveyNetWeighUnit = netWeight;
    }
  }

  onBlurNetWeightUnit() {
    if (this.formItem.surveyNetWeighUnit != undefined && this.formItem.surveyNetWeighUnit != null && this.formItem.surveyNetWeighUnit != "") {
      let _netWeight = this.formItem.surveyNetWeighUnit != undefined && this.formItem.surveyNetWeighUnit != null && this.formItem.surveyNetWeighUnit != "" ? isNumber(this.formItem.surveyNetWeighUnit) ? this.formItem.surveyNetWeighUnit : this.formItem.surveyNetWeighUnit?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_netWeight))) {
        this.formItem.surveyNetWeighUnit = Number('0').toLocaleString('en', this.options);
        return;
      }
      let netWeight = this.formItem.surveyNetWeighUnit != undefined && this.formItem.surveyNetWeighUnit != "" && this.formItem.surveyNetWeighUnit != null ? Number(_netWeight).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', netWeight);

      this.formItem.surveyNetWeighUnit = netWeight;
    }
  }

  onFocusGrossWeightUnit() {
    if (this.formItem.surveyGrossWeightUnit != undefined && this.formItem.surveyGrossWeightUnit != null && this.formItem.surveyGrossWeightUnit != "") {
      let _grossWeight = this.formItem.surveyGrossWeightUnit != undefined && this.formItem.surveyGrossWeightUnit != null && this.formItem.surveyGrossWeightUnit != "" ? isNumber(this.formItem.surveyGrossWeightUnit) ? this.formItem.surveyGrossWeightUnit : this.formItem.surveyGrossWeightUnit?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_grossWeight))) {
        this.formItem.surveyGrossWeightUnit = Number('0').toLocaleString('en', this.options);
        return;
      }
      let grossWeight = this.formItem.surveyGrossWeightUnit != undefined && this.formItem.surveyGrossWeightUnit != "" && this.formItem.surveyGrossWeightUnit != null ? Number(_grossWeight).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', grossWeight);

      this.formItem.surveyGrossWeightUnit = grossWeight;
    }
  }

  onBlurGrossWeightUnit() {
    if (this.formItem.surveyNetWeighUnit != undefined && this.formItem.surveyNetWeighUnit != null && this.formItem.surveyNetWeighUnit != "") {
      let _netWeight = this.formItem.surveyNetWeighUnit != undefined && this.formItem.surveyNetWeighUnit != null && this.formItem.surveyNetWeighUnit != "" ? isNumber(this.formItem.surveyNetWeighUnit) ? this.formItem.surveyNetWeighUnit : this.formItem.surveyNetWeighUnit?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_netWeight))) {
        this.formItem.surveyNetWeighUnit = Number('0').toLocaleString('en', this.options);
        return;
      }
      let netWeight = this.formItem.surveyNetWeighUnit != undefined && this.formItem.surveyNetWeighUnit != "" && this.formItem.surveyNetWeighUnit != null ? Number(_netWeight).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', netWeight);

      this.formItem.surveyNetWeighUnit = netWeight;
    }
  }
}
