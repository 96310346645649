<mat-card>
 <mat-card-header  class="fontTitleHeader" >
    View Data
  </mat-card-header>
  <mat-card-content class="example-headers-align">
    <div class="flex flex-col">
      <div class="flex flex-row p10">
        <div class="basis-1/6">
          Shipping Name :
        </div>
        <div class="basis-1/2 f18">
          {{dataSource.shippingName}}
        </div>
      </div>
      <div class="flex flex-row p10">
        <div class="basis-1/6" style="align-self: center;">
          Shipping Address :</div>
        <div class="basis-1/2 f18">
          {{dataSource.shippingAddress}}
        </div>
      </div>
      <div class="flex flex-row p10">
        <div class="basis-1/6" style="align-self: center;">
          Active : </div>
        <div class="basis-1/2 f18">
          {{dataSource.active}}
        </div>
      </div>
      <div class="flex flex-row p10">
        <div class="basis-1/6" style="align-self: center;">
          Date Created: </div>
        <div class="basis-1/2 f18">
          {{(dataSource.dateAdd | date:'yyyy-MM-dd hh:mm:ss') || '-'}}
        </div>
      </div>
      <div class="flex flex-row p10">
        <div class="basis-1/6" style="align-self: center;">
          Expire Date: </div>
        <div class="basis-1/2 f18">
          {{(dataSource.expireDate | date:'yyyy-MM-dd') || '-'}}
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center p10">
      <div class="basis-1/2 f18">
        <button mat-raised-button style="width: 100px;" color="accent" [mat-dialog-close] mat-button>
          ย้อนกลับ
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>