import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppDFTService } from "app/business/service/app/app-dft.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { IAppDftExporter } from "app/business/interface/app/app-dft.interface";
import { FormControl } from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS, } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import moment from "moment";



export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-dft-exporter-inquiry',
  templateUrl: './dft-exporter-inquiry.component.html',
  styleUrls: ['./dft-exporter-inquiry.component.css'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
})
export class DftExporterInquiryComponent implements OnInit {

  searchForm: any = {};
  form: any = {};
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "companyNameTh",
    "taxNo",
    "exporterNo",
    "createDate",
    "statusExporter",
    "statusUpdateData",
    "action",
  ];
  dataView: IAppDftExporter[] = [];
  date = new FormControl();
  StatusUpdateExporter: string = ' ';
  StatusUpdateData: string = ' ';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppDFTService: AppDFTService,
  ) {


  }


  ngOnInit() {
    this.loadData();
  }

  async loadData(): Promise<void> {
    const formData = {
      createDate: this.searchForm.createDate,
      exporterNo: this.searchForm.exporterNo,
      companyName: this.searchForm.companyName,
      taxNo: this.searchForm.taxNo,
      status: this.searchForm.status,
      statusUpdate: this.searchForm.statusUpdate
    };

    this.AppDFTService.ExporterList(formData).then((res) => {
      if (res) {
        this.dataView = res;
        this.dataSource = new MatTableDataSource(this.dataView);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;


        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  clearOption() {
    this.searchForm = {};
    this.loadData();
  }

}


