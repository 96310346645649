import { NgModule } from "@angular/core";
import {
  DxSelectBoxModule,
  DxDataGridModule,
  DxTemplateModule,
  DxButtonModule,
  DxTreeViewModule,
  DxCheckBoxModule,
  DxListModule,
  DxFileManagerModule,
  DxFileUploaderModule,
  DxRangeSliderModule,
  DxNumberBoxModule,
  DxGalleryModule,
} from "devextreme-angular";

export const devExtremeDesignAllModule = [
  DxSelectBoxModule,
  DxDataGridModule,
  DxTemplateModule,
  DxButtonModule,
  DxTreeViewModule,
  DxCheckBoxModule,
  DxListModule,
  DxRangeSliderModule,
  DxNumberBoxModule,
  DxGalleryModule,
  DxFileManagerModule,
  DxFileUploaderModule,
];

@NgModule({
  imports: [devExtremeDesignAllModule],
  exports: [devExtremeDesignAllModule],
})
export class DevExtremeDesignModule {}
