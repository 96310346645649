<div style="height: 100%;gap: 30px;" class="flex flex-col">
    <div style="margin-top: 30px;" class="pl-20 flex flex-row">
        <div class="flex flex-col gap-4" style="flex: 0 50%">
            <div></div>
            <div class="flex-row flex justify-between gap-1.5">
                <div class="mat-popup-header flex flex-col justify-center" style="flex: 0 23%;">
                    <h4 class="labeltext">ชื่อบริษัท</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <div style="flex: 1" class="flex flex-row">{{companyData?.tcCompanynameTh}}</div>

                </div>
                <div style="flex: 0 10%;"></div>
            </div>
            <!-- <div class="flex-row flex justify-between gap-1.5">
                <div class="mat-popup-header flex flex-col justify-center" style="flex: 0 15%;">
                    <h4>ชื่อบริษัท</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <div style="flex: 1" class="flex flex-row">{{dataEmail[0]?.tcCompanynameTh}}</div>

                </div>
                <div style="flex: 0 10%;"></div>
            </div> -->
            <div class="flex-row flex justify-between gap-1.5">
                <div class="mat-popup-header flex flex-col justify-center" style="flex: 0 23%;">
                    <h4 class="labeltext">เลขทะเบียนนิติบุคคล</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <div style="flex: 1" class="flex flex-row">{{companyData?.tcNid}}</div>
                </div>
                <div style="flex: 0 10%;"></div>
            </div>
            <div class="flex-row flex justify-between gap-1.5">
                <div class="mat-popup-header flex flex-col justify-center" style="flex: 0 23%;">
                    <h4 class="labeltext">ประกอบกิจการ</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <mat-form-field appearance="fill" style="flex: 1" class="flex flex-row">
                        <mat-select [(ngModel)]="companyData.tctType" name="status" #status="ngModel" [disabled]="true">
                            <mat-option *ngFor="let ctl of companyTypeList" [value]="ctl.companyTypeId">
                                {{ctl.companyTypeName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="flex: 0 10%;"></div>
            </div>
            <div class="flex-row flex justify-between gap-1.5">
                <div class="mat-popup-header flex flex-col justify-center" style="flex: 0 23%;">
                    <h4 class="labeltext">อีเมล์</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <div style="flex: 1" class="flex flex-row">{{companyData?.tcCompanyEmail}}</div>
                </div>
                <div style="flex: 0 10%;"></div>
            </div>

            <div class="flex-row flex justify-between gap-1.5">
                <div class="mat-popup-header flex flex-col" style="flex: 0 23%;">
                    <h4 class="labeltext" style="margin-top: 12px;">อีเมล์ E-TAX</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between">
                    <div style="margin-top: 12px;">:</div>
                    <div style="flex: 1" class="flex flex-col justify-center gap-1">
                        <ng-container *ngFor="let item of emailList; let i = index">
                            <mat-form-field class="flex">
                                <input autocomplete="off" style="width: 100%;" type="text"
                                    placeholder="อีเมล์ {{i + 1}}" matInput #input [(ngModel)]="item.email"
                                    name="tccEmailReceiveTaxInvoice{{i + 1}}" id="newTaxEmail1"
                                    onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9._%+-,]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g, '').replace(/\s/g, '').replace(/[^\u0020-\u007E,]+/g, '')"
                                    #tccEmailReceiveTaxInvoice="ngModel" [readonly]="item.readonly">
                                <textarea *ngIf="i > 4" style="width: 10%;" placeholder="อีเมล์อื่น ๆ " id="newTaxEmail"
                                    [ngModelOptions]="{standalone: true}"
                                    onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9._%+-,]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g, '').replace(/\s/g, '').replace(/[^\u0020-\u007E,]+/g, '')"
                                    required="true" matInput #input>
                                </textarea>
                                <button *ngIf="item.readonly" mat-icon-button matTooltip="แก้ไข"
                                    (click)="item.readonly = !item.readonly">
                                    <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                                </button>
                                <button *ngIf="!item.readonly" mat-icon-button matTooltip="บันทึก"
                                    (click)="item.readonly = !item.readonly;checkItem()">
                                    <mat-icon svgIcon="heroicons_outline:save"></mat-icon>
                                </button>
                                <button *ngIf="!item.readonly" mat-icon-button matTooltip="ลบ" (click)="deleteItem(i)">
                                    <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                                </button>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="emailList.length < 5">
                            <mat-form-field style="flex: 1" class="flex">
                                <input autocomplete="off" id="newTaxEmail" type="text" matInput
                                    onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9._%+-,]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g, '').replace(/\s/g, '').replace(/[^\u0020-\u007E,]+/g, '')"
                                    placeholder="อีเมล์ {{emailList.length + 1}}" #input>
                                <button mat-icon-button matTooltip="เพิ่ม" (click)="addItem()">
                                    <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
                                </button>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="emailList.length == 5">
                            <mat-form-field>
                                <textarea matInput placeholder="อีเมล์อื่น ๆ "
                                    onkeyup="this.value = this.value.replace(/[^a-zA-Z0-9._%+-,]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/g, '').replace(/\s/g, '').replace(/[^\u0020-\u007E,]+/g, '')"
                                    id="newTaxEmailOther"></textarea>
                                <button mat-icon-button matTooltip="เพิ่ม" (click)="addItemOther()">
                                    <mat-icon svgIcon="heroicons_outline:plus"></mat-icon>
                                </button>
                            </mat-form-field>
                        </ng-container>
                        <ng-container *ngIf="emailList.length < 5">
                            <div class="warning-box">
                                <span>* กรุณาระบุอีเมล์ E-TAX อย่างน้อย 1 อีเมล์ สูงสุด 5 อีเมล์
                                    <br> กรณีมีมากกว่า 1 อีเมล์ให้ใส่ , คั่นระหว่างอีเมล์
                                    <br> <u>ตัวอย่าง</u> example@example.com,example1@example.com
                                </span>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div style="flex: 0 10%;"></div>
            </div>

        </div>
        <div class="flex flex-col gap-4" style="flex: 0 50%">
            <div></div>
            <div class="flex-row flex justify-between gap-1.5">
                <div class="mat-popup-header flex flex-col justify-center" style="flex: 0 23%;">
                    <h4 class="labeltext">ชื่อบริษัท (อังกฤษ)</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <div style="flex: 1" class="flex flex-row">{{companyData?.tcCompanynameEn}}</div>

                </div>
                <div style="flex: 0 10%;"></div>
            </div>
            <div class="flex-row flex justify-between gap-1.5">
                <div class="mat-popup-header flex flex-col justify-center" style="flex: 0 23%;">
                    <h4 class="labeltext">วันที่จดทะเบียน</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <div style="flex: 1" class="flex flex-row">{{companyData?.tcNidDate | date:'yyyy-MM-dd'}}
                    </div>

                </div>
                <div style="flex: 0 10%;"></div>
            </div>
            <div class="flex-row flex justify-between gap-1.5">
                <div class="mat-popup-header flex flex-col justify-center" style="flex: 0 23%;">
                    <h4 class="labeltext">วันหมดอายุสมาชิก</h4>
                </div>
                <div class="mat-popup flex flex-row justify-between items-center">
                    <div>:</div>
                    <div style="flex: 1" class="flex flex-row">{{companyData?.tcExpireDate | date:'yyyy-MM-dd'}}
                    </div>
                </div>
                <div style="flex: 0 10%;"></div>
            </div>
        </div>
    </div>

    <div class="flex justify-center">
        <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
    </div>

    <div class="mat-elevation-z8 " style="margin-bottom:30px;">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">

            <!-- Position Column -->
            <!-- bankAccountNo Column -->
            <ng-container matColumnDef="tcaAddressStreet">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ที่อยู่ </th>
                <td mat-cell *matCellDef="let element"> {{element.tcaAddressStreet}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="subDistrictName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ตำบล / แขวง
                </th>
                <td mat-cell *matCellDef="let element" class="text-left"> {{element.subDistrictName}} </td>
            </ng-container>

            <ng-container matColumnDef="districtName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> อำเภอ / เขต </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    {{element.districtName}} </td>
            </ng-container>

            <ng-container matColumnDef="province">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> จังหวัด </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    {{element.province}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="postCode">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> รหัสไปรษณีย์ </th>
                <td mat-cell *matCellDef="let element" class="text-left"> {{element.postCode}} </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> โทรศัพท์ </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    {{element.phone}} </td>
            </ng-container>

            <ng-container matColumnDef="fax">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> แฟกซ์ </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    {{element.fax}} </td>
            </ng-container>

            <ng-container matColumnDef="tcaTypeComAddName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ประเภทที่อยู่ </th>
                <td mat-cell *matCellDef="let element" class="text-left">
                    {{element.tcaTypeComAddName}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>