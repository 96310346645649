import { Injectable } from "@angular/core";
import { ApiShippingService } from "../api/api-shipping.service";

@Injectable({
    providedIn: "root",
  })
  export class AppShippingService {
    constructor(private ApiShippingService: ApiShippingService) {}
  
    ShippingList(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiShippingService.ShippingList(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
  
    getDataCompanyDetail(dataReq: any): Promise<any> {
      return new Promise((resolve, reject) => {
        this.ApiShippingService.getDataCompanyDetail(dataReq).subscribe((res) => {
          resolve(res);
        });
      });
    }
 
    
  }
  