import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AuthService } from 'app/core/auth/auth.service';
import { Observable, startWith, map } from 'rxjs';
import { AddReceiverFormHelperComponent } from '../add-receiver-form-helper/add-receiver-form-helper.component';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-add-receiver-company-modal',
  templateUrl: './add-receiver-company-modal.component.html',
  styleUrls: ['./add-receiver-company-modal.component.css']
})
export class AddReceiverCompanyModalComponent implements OnInit {
  form: any = {}
  shippingName: string
  searchInput: string = ' '
  dataSource: any[] = []
  filteredOptions: Observable<string[]>
  searchCountryForm: FormGroup
  uId: number
  CompanyId: number
  userToken: any;
  isEdit: boolean = false;
  datas: any = {};
  get countrySearch(): AbstractControl {
    return this.searchCountryForm.get('countrySearch')
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private appMasterService: AppMasterService,
    public dialogRef: MatDialogRef<AddReceiverCompanyModalComponent>,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService,
    private confirmMessageService: ConfirmDialogService,
    private appUserService: AppUserService,
    private _authService: AuthService,

  ) {
    this.datas = data;

    this.searchCountryForm = this.formBuilder.group({
      countrySearch: ['', []],
    })
  }

  async ngOnInit() {

    this.userToken = JSON.parse(
      this._authService.loginUser
    )

    this.uId = this.userToken.uId;
    this.CompanyId = this.userToken.cpId;
    this.form.cpId = this.CompanyId;

    await this.loadData();

    if (this.datas.isEdit) {
      this.isEdit = this.datas.isEdit;
      this.form = { ...this.datas.itemData };
      this.form.cpId = this.CompanyId;

      let a = this.dataSource.find(
        (x) => x.code == this.form.countryCode,
      );

      this.searchCountryForm.get('countrySearch').patchValue(a);
    }
  }

  async loadData() {
    const data = {
      type: 1,
    }

    await this.appMasterService.getCountry({}).then((res) => {
      this.dataSource = res;
    });

    this.filteredOptions = this.countrySearch.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return typeof value === 'string' ? value : value.name
      }),
      map((name) => {
        return name ? this._filter(name) : this.dataSource.slice()
      }),
    )
  }

  onFocus() {
  }

  save() {
    console.log(this.form);

    if (this.validateForm()) {
      var formData = { ...this.form };

      this.appMasterService.saveCompanyReceiver(formData).then(res => {
        if (res === 'error') {
          return;
        } else {
          this.alertMessageService.open({
            message: "บันทึกข้อมูลสำเร็จ",
            title: ""
          })
          this.dialogRef.close(true);
        }
      });
    } else {
      this.alertMessageService.open({
        message: "กรุณากรอกข้อมูลให้ครบถ้วน",
        title: ""
      });
    }
  }

  validateForm() {
    var allowSave = true;

    if (this.form.nameEN == undefined || this.form.nameEN == null || this.form.nameEN == '') {
      allowSave = false;
    }

    if (this.form.address == undefined || this.form.address == null || this.form.address == '') {
      allowSave = false;
    }

    if (this.form.subProvince == undefined || this.form.subProvince == null || this.form.subProvince == '') {
      allowSave = false;
    }

    if (this.form.province == undefined || this.form.province == null || this.form.province == '') {
      allowSave = false;
    }

    if (this.form.postcode == undefined || this.form.postcode == null || this.form.postcode == '') {
      allowSave = false;
    }

    if (this.form.countryCode == undefined || this.form.countryCode == null || this.form.countryCode == '') {
      allowSave = false;
    }

    return allowSave;
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase()
    return this.dataSource.filter((option) =>
      option.name.toLowerCase().includes(filterValue),
    )
  }
  displayFn(value: any): string | undefined {
    if (!value) {
      return ''
    }
    return value ? value.name : undefined
  }

  openHelper() {
    this.dialog.open(AddReceiverFormHelperComponent, {
      disableClose: false,
      width: '60vw'
    })
  }

  reset() {
    this.confirmMessageService.open({
      header: "",
      content: "คุณต้องการละทิ้งการเปลี่ยนแปลงแล้วกลับไปใช้ค่าเดิมหรือไม่?"
    }).then(res => {
      if (res) {
        this.form = { ...this.datas.itemData };
        this.form.cpId = this.CompanyId;

        let a = this.dataSource.find(
          (x) => x.code == this.form.countryCode,
        );

        this.searchCountryForm.get('countrySearch').patchValue(a);
      }
    })
  }

  selectionCountryRecieveChange(event) {
    console.log(event)
    var selectedValue = event.option.value;
    this.form.country = selectedValue.name;
    this.form.countryCode = selectedValue.code;
  }
}
