import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: 'root'
  })
  export class ApiReportService {
    constructor(
        private apiService: ApiService
    ) { }

    DailyReport(request: any): Observable<any[]> {
      return this.apiService.post('/api/dailyreport', request);
    }
    DailyReportPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/dailyreportPdf', request);
    }
    reportDataMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportDataMs24', request);
    }
    reportDataMs24Pdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportDataMs24Pdf', request);
    }
    reportSummaryApproveDataMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummaryApproveDataMs24', request);
    }
    reportSummaryApproveDataMs24Pdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummaryApproveDataMs24Pdf', request);
    }

    reportSummaryPrintDataMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummaryPrintDataMs24', request);
    }
    reportSummaryPrintDataMs24Pdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummaryPrintDataMs24Pdf', request);
    }

    reportControlDataMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportControlDataMs24', request);
    }
    reportControlDataMs24Pdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportControlDataMs24Pdf', request);
    }

    reportProductWorkloadSummary(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportProductWorkloadSummary', request);
    }
    reportProductWorkloadSummaryPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportProductWorkloadSummaryPdf', request);
    }

    reportProductWorkloadSummaryPayment(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportProductWorkloadSummaryPayment', request);
    }
    reportProductWorkloadSummaryPaymentPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportProductWorkloadSummaryPaymentPdf', request);
    }

    reportSummarizingAmountOfWeightMs24PaidPayment(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingAmountOfWeightMs24PaidPayment', request);
    }
    reportSummarizingAmountOfWeightMs24PaidPaymentPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingAmountOfWeightMs24PaidPaymentPdf', request);
    }

    reportSummarizingDaysAndNumberMs24ReceivedPayment(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingDaysAndNumberMs24ReceivedPayment', request);
    }
    reportSummarizingDaysAndNumberMs24ReceivedPaymentPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingDaysAndNumberMs24ReceivedPaymentPdf', request);
    }

    reportSummarizingIssuanceFormsProduct(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingIssuanceFormsProduct', request);
    }
    reportSummarizingIssuanceFormsProductPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingIssuanceFormsProductPdf', request);
    }

    reportSummarizingMs24PaidPayment(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingMs24PaidPayment', request);
    }
    reportSummarizingMs24PaidPaymentPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingMs24PaidPaymentPdf', request);
    }

    reportSummarizingPaidPayment(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingPaidPayment', request);
    } 
    reportExportByCountry(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportExportByCountry', request);
    } 
    reportExportByCountryPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportExportByCountryPdf', request);
    } 

    reportTop10ShipoutByCountry(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportTop10ShipoutByCountry', request);
    } 
    reportTop10ShipoutByCountryPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportTop10ShipoutByCountryPdf', request);
    } 

    reportTop10ShipoutByCompany(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportTop10ShipoutByCompany', request);
    } 
    reportTop10ShipoutByCompanyPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportTop10ShipoutByCompanyPdf', request);
    } 

    reportCountryExport(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportCountryExport', request);
    } 
    reportCountryExportPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportCountryExportPdf', request);
    } 

    reportCompanyInSystem(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportCompanyInSystem', request);
    } 
    reportMs13FileComplaint(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13FileComplaint', request);
    } 
    reportMs13FileComplaintPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13FileComplaintPdf', request);
    } 

    reportMs13CompleteMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13CompleteMs24', request);
    } 
    reportMs13CompleteMs24Pdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13CompleteMs24Pdf', request);
    } 

    reportMs13IncompleteMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13IncompleteMs24', request);
    } 
    reportMs13IncompleteMs24Pdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13IncompleteMs24Pdf', request);
    } 

    reportMs13SurveyorIncompleteMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13SurveyorIncompleteMs24', request);
    } 
    reportMs13SurveyorIncompleteMs24Pdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13SurveyorIncompleteMs24Pdf', request);
    } 

    reportMs13IsCommit(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13IsCommit', request);
    } 
    reportMs13IsCommitPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13IsCommitPdf', request);
    } 

    reportFileControlMs13(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportFileControlMs13', request);
    } 
    reportFileControlMs13Pdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportFileControlMs13Pdf', request);
    } 

    reportMs13ApprovePerCompany(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13ApprovePerCompany', request);
    } 
    reportMs13ApprovePerCompanyPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13ApprovePerCompanyPdf', request);
    } 

    reportMs13PrintList(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13PrintList', request);
    } 
    reportMs13PrintListPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13PrintListPdf', request);
    } 

    reportMs24ByMonth(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs24ByMonth', request);
    }
    reportMs24ByMonthPdf(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs24ByMonthPdf', request);
    }

    printAllCertificateMS24(request: any): Observable<any[]> {
      return this.apiService.post('/api/printAllCertificateMS24', request);
    } 

    printAllCertificateMS13(request: any): Observable<any[]> {
      return this.apiService.post('/api/printAllCertificateMS13', request);
    }
    
    reportSendDocumentToOcsConnectExternal(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSendDocumentToOcsConnectExternal', request);
    }
    
    reportInvoiceBymouthComponent(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportInvoiceBymouthComponent', request);
    }
  }
  