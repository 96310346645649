<mat-card class="example-card">
  <mat-card-header class="fontTitleHeader">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal"> พิมพ์เอกสาร มส.24 </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content style="padding: 20px;">
    <form #FormValidateItem="ngForm">
      <div class="f18" style="padding-bottom: 10px;">
        กรุณาเลือกผู้ใช้งานที่ต้องการให้พิมพ์ มส.24
      </div>
      <mat-form-field class="w100">
        <input required matInput type="text" [formControl]="botUserSearch" [matAutocomplete]="auto" name="userCode" [readonly]="valueSelect">
        <mat-autocomplete #auto="matAutocomplete" required (optionSelected)="selectionBotUserChange($event)">
          <mat-option *ngIf="isLoading">
            <mat-spinner [diameter]="20"></mat-spinner>
          </mat-option>
          <mat-option *ngFor="let option of filteredOptions$  | async" [value]="option">
            {{option.display}}
          </mat-option>
        </mat-autocomplete>
        <button mat-icon-button matTooltip="ลบ" (click)="clearValue()"
          style="width: 48px !important; margin-right: -10px" *ngIf="valueSelect">
          <i class="ti ti-backspace" style="color: #8e9db1;"></i>
        </button>
      </mat-form-field>
    </form>
  </mat-card-content>
  <div class="flex flex-col items-center" style="padding-bottom: 20px">
    <div class="basis-1/2 f18 flex" style="gap: 8px">
      <button mat-raised-button color="accent" (click)="savePrintUser()" [disabled]="!FormValidateItem.valid">
        ยืนยัน
      </button>
      <button mat-raised-button [mat-dialog-close]>
        ยกเลิก
      </button>
    </div>
  </div>
</mat-card>