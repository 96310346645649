<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <!-- <app-head-o2 style="width: 100%;"></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <!--**********Tab Menubar**********-->
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">
          
          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field" >
            <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
              [(ngModel)]="searchForm.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!--**********Button search**********-->
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <!--**********Menu for button search**********-->
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">              
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>Shipping Name</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.shippingName" name="shippingName" #shippingName="ngModel">
                  </mat-form-field>
                </div>
              </div>    
            </div>
              <div class="flex justify-end formm">
                <div>
                  <button type = "submit" mat-raised-button color="accent"  style="flex:0 50%;" (click)="loadData()">
                    ค้นหา
                  </button>
                </div>
              </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <!--**********Table Shipping Data**********-->
  <div class="mat-elevation-z8">
   <table mat-table matSort [dataSource]="dataSource" > 
      <!--**********1.ShoppingName**********-->
      <ng-container matColumnDef="shippingName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Shipping Name</th>
        <td mat-cell *matCellDef="let element" style="padding-left: 80px;" sortActionDescription="Sort by name"> {{element.shippingName}} </td>
      </ng-container>
      <!--**********2.Active**********-->
      <ng-container matColumnDef="active">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          Active</th>
        <td mat-cell *matCellDef="let element"> {{element.active}} </td>
      </ng-container>
      <!--**********3.Date Added**********-->
      <ng-container matColumnDef="dateAdd">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Date Added
        </th>
        <td mat-cell *matCellDef="let element"> {{(element.dateAdd | date:'yyyy-MM-dd hh:mm:ss') || '-'}} </td>
      </ng-container>
      <!--**********4.Expire Date**********-->
      <ng-container matColumnDef="expireDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Expire Date</th>
        <td mat-cell *matCellDef="let element"> {{(element.expireDate | date:'yyyy-MM-dd') || '-'}} </td>
      </ng-container>
      <!--**********5.Action button**********-->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          <button mat-icon-button matTooltip="รายละเอียด" (click)="DetailCompany(element)">
            <mat-icon>description</mat-icon>
          </button>   
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
   <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>