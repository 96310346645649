import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppItemtypeService } from "app/business/service/app/app-itemtype.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { SelectionModel } from '@angular/cdk/collections';
import { IResGetDataItemtype } from "app/business/interface/app/app-itemtype.interface";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { ItemtypeAdjustManagementComponent } from "../itemtype-adjust-management/itemtype-adjust-management.component";
import { AuthService } from "app/core/auth/auth.service";
import { Router } from "@angular/router";
import { AppMenuService } from "app/business/service/app/app-menu.service";



@Component({
  selector: 'app-itemtype-inquiry',
  templateUrl: './itemtype-inquiry.component.html',
  styleUrls: ['./itemtype-inquiry.component.css']
})
export class ItemtypeInquiryComponent implements OnInit {

  searchForm: any = {};
  form: any = {};
  slidetoggerDetail: string = " ";
  message: string;
  dataSource = new MatTableDataSource<any>();
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [
    "select",
    "refGoodsDocName",
    "refGoodsCode",
    "refGoodsName",
    // "refGoodsStatusData",
    "action",
  ];
  dataView: IResGetDataItemtype[] = [];
  AdminSearch: string = '';
  dataProduct : any[] = [];
  url: any;
  buttonList: any[] = [];


  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppItemtypeService: AppItemtypeService,
    private ConfirmDialogService: ConfirmDialogService,
    private AlertMessageService: AlertMessageService,
    private confirmDialogService: ConfirmDialogService,
    private alertMessageService: AlertMessageService,
    private router: Router,
    private _authService:AuthService,
    private appMenuService: AppMenuService,
  ) {

  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    await this.getRouter();
    this.loadDataProduct();
    this.loadData();
  }
  async loadDataProduct(): Promise<void> {
    //send data to api
    const formData = {
      
    };
     await this.AppItemtypeService.getDataDetailProduct(formData).then((res)=>{ 
      this.dataProduct = res
    });
  }

  clearOption() {
    this.searchForm = {};
    this.loadData();
  }

  async loadData(): Promise<void> {
    //send data to api
    const formData = {
      refGoodsCode: this.searchForm.itemtypeCode,
      refGoodsDocId: this.searchForm.itemtypeTypeproduct,
      refGoodsName: this.searchForm.itemtypeCategory,
    };
    
    this.AppItemtypeService.getDataRefGoodsClass(formData).then((res) => {
      if (res) {
        this.dataView = res;
        this.dataSource = new MatTableDataSource(this.dataView);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  stopPropagation(event) {
    event.stopPropagation();
  }
  AdjustItemType(groupId) {
    const dialogRef = this.dialog.open(ItemtypeAdjustManagementComponent, {
      disableClose: false,
      width: '60vw',
      data: groupId,
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res == "OK") {
        this.loadData();
      }

    });
  }


  isAllSelected() {
    //console.log(this.selection.selected, 'this.isAllSelected');
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  async onChange(e, dataInquiry) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
    const formData = {
      refGoodsClassId: dataInquiry.refGoodsClassId,
      refGoodsDocId: dataInquiry.refGoodsDocId,
      refGoodsCode: dataInquiry.refGoodsCode,
      refGoodsName: dataInquiry.refGoodsName,
      refGoodsStatusData: this.form.isActive
    };
    await this.AppItemtypeService.AdjustDataRefGoodsClass(formData).then((res) => {
      this.message = res;
      this.loadData();
    });
    await this.AlertMessageService.info({ title: '', message: this.message });
  }

  delete(e) {
    this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
      if (res) {

        const formData = {
          refGoodsClassId: e.refGoodsClassId,
          refGoodsDocId: e.refGoodsDocId,
          refGoodsCode: e.refGoodsCode,
          refGoodsName: e.refGoodsName,
          refGoodsStatusData: 'N'
        };

        this.AppItemtypeService.AdjustDataRefGoodsClass(formData).then((res) => {
          if (res === "error") {
            return;
          } else {
            this.loadData();
          }
        });

      }
    });
  }

  DeleteItemtype() {
    // this.selection.selected.forEach((item, index, chats) => {
    //   const data = {
    //     refGoodsClassId: item.refGoodsClassId,
    //     refGoodsDocId: item.refGoodsDocId,
    //     refGoodsCode: item.refGoodsCode,
    //     refGoodsName: item.refGoodsName,
    //     refGoodsStatusData: 'N'
    //   };
    //   this.AppItemtypeService.AdjustDataRefGoodsClass(data).then((res: any) => {
    //     if (res === "error") {
    //       return;
    //     } else {
    //       this.loadData();
    //     }
    //   });
    // });

    if (this.selection?.selected?.length > 0) {
      this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
        if (res) {
          this.selection.selected.forEach((item, index, chats) => {
            const data = {
              refGoodsClassId: item.refGoodsClassId,
              refGoodsDocId: item.refGoodsDocId,
              refGoodsCode: item.refGoodsCode,
              refGoodsName: item.refGoodsName,
              refGoodsStatusData: 'N'
            };
            this.AppItemtypeService.AdjustDataRefGoodsClass(data).then((res: any) => {
              if (res === "error") {
                return;
              } else {
                this.loadData();
              }
            });
          });
        }
      });
    } else {
      this.alertMessageService.open({ message: "กรุณาเลือกรายการที่จะลบข้อมูล", title: "ข้อผิดพลาด" });
    }

  }
}
