import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, Inject, OnInit, Optional, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppStaffService } from "app/business/service/app/app-staff.service";
import { DxDataGridComponent } from "devextreme-angular";

@Component({
    selector: "app-staff-history-ms13-modal",
    templateUrl: "./staff-history-ms13-modal.component.html",
    styleUrls: ["./staff-history-ms13-modal.component.css"],
})
export class StaffHistoryMs13ModalComponent implements OnInit {
    form: any = {};
    pages: any;
    productList: any = [];
    local_data: any;
    statusList: any = [];
    userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
    dataSource = new MatTableDataSource<any>();

    displayedColumns: string[] = [
        "action",
        "description",
        "securityCode",
        "actionBy",
        "actionDate",
        "ip",
    ];


    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
    @ViewChild(DxDataGridComponent, { static: false })
    dataGrid: DxDataGridComponent;

    constructor(
        private appStaffService: AppStaffService,
        public dialogRef: MatDialogRef<StaffHistoryMs13ModalComponent>,
        private _liveAnnouncer: LiveAnnouncer,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.local_data = { ...data };
    }

    ngOnInit() {
        this.dataSource.paginator = this.paginator; 
        this.dataSource.sort = this.sort;
        this.loadData();
    }

    ngAfterViewInit() {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    }
    async loadData() {
        const data = {
            UID: this.userProfile.gId,
            hId: this.local_data.id
        };
        await this.appStaffService.StaffGetLogMs13(data).then((res) => {
            this.dataSource.data = res
        });
    }

    announceSortChange(sortState: Sort) {
        if (sortState.direction) {
            this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
        } else {
            this._liveAnnouncer.announce("Sorting cleared");
        }
    }

}
