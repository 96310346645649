import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { BehaviorSubject, map, Observable } from "rxjs";
import { ApiMasterService } from "../api/api-master.service";
import { ApiUserService } from "../api/api-user.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AppMasterService {
  companyData: BehaviorSubject<any[]>;
  countryData: BehaviorSubject<any[]>;
  statusData: BehaviorSubject<any[]>;
  statusTrackingData: BehaviorSubject<any[]>;
  notifications: BehaviorSubject<any[]>;

  constructor(
    private apiMasterService: ApiMasterService,
    private apiUserService: ApiUserService,
    private httpClient: HttpClient,
    private router: Router,
  ) {
    this.companyData = new BehaviorSubject(new Array<any>());
    this.countryData = new BehaviorSubject<any[]>(new Array());
    this.statusData = new BehaviorSubject<any[]>(new Array());
    this.statusTrackingData = new BehaviorSubject<any[]>(new Array());
    this.notifications = new BehaviorSubject<any[]>(new Array());
  }

  getStatus(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getStatus(dataReq).subscribe((res) => {
        this.statusData.next(res);
        resolve(res);
      });
    });
  }

  getStatusTracking(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getStatusTracking(dataReq).subscribe((res) => {
        this.statusData.next(res);
        resolve(res);
      });
    });
  }

  getProduct(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getProduct(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getProvince(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getProvince(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getPort(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getPort(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getCountry(dataReq: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getCountry(dataReq).subscribe((res) => {
        this.countryData.next(res);
        resolve(res);
      });
    });
  }

  getQtyUnit(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getQtyUnit(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getCurrency(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getCurrency(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getWeightUnit(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getWeightUnit(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getTariff(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getTariff(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getTarifftype(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getTarifftype(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getExporter(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getExporter(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getShipping(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getShipping(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getReceiver(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getReceiver(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getSurveyor(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getSurveyor(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getCompanyByCompanyCode(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .getCompanyByCompanyCode(dataReq)
        .subscribe((res) => {
          this.companyData.next(res);
          resolve(res);
        });
    });
  }

  getTransMode(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getTransMode(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getQtyUnitOne(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getQtyUnitOne(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  uploadFile(formData: FormData): Observable<any> {
    const url = `${environment.api}/api/uploadImageToBlobs`;
    return this.httpClient.post(url, formData).pipe(map((res) => res["items"]));
  }

  saveMs13Attachment(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveMs13Attachment(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  saveMs24Attachment(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveMs24Attachment(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getShippingMask(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getShippingMask(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getTypeClass(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getTypeClass(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getCompanyList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getCompanyList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  DropdownSurveyor(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.DropdownSurveyor(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getUserByCompanyType(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getUserByCompanyType(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getConfigDByHCode(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getConfigDByHCode(request).subscribe((res: any) => {
        resolve(res);
      });
    });
  }
  getListCscBranchManage(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListCscBranchManage(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  uploadImageToBlobs(formData: FormData): Observable<any> {
    const url = `${environment.api}/api/uploadImageToBlobs`;
    return this.httpClient.post(url, formData).pipe(map((res) => res["items"]));
  }
  getCompanyAddressType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getCompanyAddressType(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getCompanyType(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getCompanyType(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getEmail(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getEmail(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getListUnit(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListUnit(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  saveUnit(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveUnit(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  updateUnitBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateUnitBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getListWeight(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListWeight(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  saveWeight(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveWeight(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  updateWeightBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateWeightBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getMasterBankCode(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterBankCode(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  saveBankCode(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveBankCode(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateBankCodeBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateBankCodeBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMasterProvince(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterProvince(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveProvince(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveProvince(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateProvinceBatch(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateProvinceBatch(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getStepPrice(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getStepPrice(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getPaymentType(): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getPaymentType().subscribe((res) => {
        resolve(res);
      });
    });
  }

  getFormRate(): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getFormRate().subscribe((res) => {
        resolve(res);
      });
    });
  }

  getRelationShipperCompanyList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .getRelationShipperCompanyList(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getCompanyReceiverList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getCompanyReceiverList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getSurveyorDftEmployeeList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .getSurveyorDftEmployeeList(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getListRelationship(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getListRelationship(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  deleteRelationship(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.deleteRelationship(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveRelationship(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveRelationship(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getShipperRelationship(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getShipperRelationship(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveCompanyReceiver(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveCompanyReceiver(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveCompanyReceiverActive(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .saveCompanyReceiverActive(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getNswTrackingDocumentList(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .getNswTrackingDocumentList(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getNswTrackingResponseList(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .getNswTrackingResponseList(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getDftTrackingDocumentList(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .getDftTrackingDocumentList(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getDftTrackingResponseList(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .getDftTrackingResponseList(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getMasterBankAccount(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getMasterBankAccount(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getIsPaymentByCompany(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getIsPaymentByCompany(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  sendMS24toNSwebExternal(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .sendMS24toNSwebExternal(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  sendCancelMS24toNSwebExternal(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .sendCancelMS24toNSwebExternal(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  sendMS24toOcsConnectExternal(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .sendMS24toOcsConnectExternal(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  sendMS13toOcsConnectExternal(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .sendMS13toOcsConnectExternal(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  manualGetNsWebResponseMessage(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .manualGetNsWebResponseMessage(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getDocumentActionLogDetail(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .getDocumentActionLogDetail(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getDocumentActionMtList() {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getDocumentActionMtList().subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateMs24Active(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService.updateMs24Active(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getBranch() {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getBranch().subscribe((res) => {
        resolve(res);
      });
    });
  }

  getNswDocumentItemTrackingList(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .getNswDocumentItemTrackingList(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }
  getDftDocumentItemTrackingList(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .getDftDocumentItemTrackingList(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getUserTCC(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getUserTCC(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  sendCancelMS13toOcsConnectExternal(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .sendCancelMS13toOcsConnectExternal(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  sendCancelMS24toOcsConnectExternal(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService
        .sendCancelMS24toOcsConnectExternal(request)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getNotiList(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getNotiList(request).subscribe((res) => {
        this.notifications.next(res);
        resolve(res);
      });
    });
  }

  getNotiPopupList(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService.getNotiPopupList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  actionNoti(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService.actionNoti(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveActionNotificationUserExternal(request) {
    return new Promise((resolve, reject) => {
      this.apiMasterService.saveActionNotificationUserExternal(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  logout(user) {
    this.apiMasterService.logOut(user).subscribe((res) => {
      this.router.navigate(['/sign-out']);
    })
  }
}
