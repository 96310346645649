import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMs13Service } from "app/business/service/app/app-ms13.service";
import { AppO2Service } from "app/business/service/app/app-o2.service";
import moment from "moment";
import _ from 'underscore';

@Component({
  selector: "app-amount-of-weight-touse-ms13-modal",
  templateUrl: "./amount-of-weight-touse-ms13-modal.component.html",
  styleUrls: ["./amount-of-weight-touse-ms13-modal.component.css"],
})
export class AmountOfWeightTouseMs13ModalComponent implements OnInit {
  form: any = {};
  @ViewChild("FormValidateItem") FormValidateItem: NgForm;
  disabled: boolean = false;
  dataSource: any = [];
  qtyUnitList: any[] = [];
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));

  constructor(
    public dialogRef: MatDialogRef<AmountOfWeightTouseMs13ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appMs13Service: AppMs13Service,
    private appO2Service: AppO2Service,
    private appMasterService: AppMasterService,
    private router: Router
  ) { }

  async ngOnInit() {
    console.log(this.data);

    await this.appMasterService.getQtyUnit({ ID: null }).then((res) => {
      if (res == 'error') {
        return;
      } else {
        this.qtyUnitList = res;
      }
    });

    console.log("data.selectionProduct ==>", this.data.selectionProduct);
    this.dataSource = this.data.selectionProduct.map(el => {
      var qtyItem = this.qtyUnitList.find(ele => ele.code == el.quantityUnit);
      return {
        ...el,
        weightUsedMs13: el.availableNet,
        amountUsedMs13: el.quantity,
        quantityUnitName: qtyItem.name,
      }
    });

    console.log("dataSource ==>", this.dataSource);
  }

  createMs13() {
    var loadedStartDate = moment();
    var loadedEndDate = moment();
    loadedEndDate.add(19, 'days')
    var form = this.data.formData;

    var dataItemD = this.dataSource.map(e => {
      return {
        itemStatus: 1,
        itemNo: e.itemNo,
        goodsDescription: e.goodsDescription,
        tariffCode: e.tariffCode,
        tariffSequence: e.tariffSequence,
        statisticalCode: e.tariffSequence,
        unitPrice: e.unitPrice,
        netWeight: e.weightUsedMs13,
        netWeightUnit: e.netWeightUnit,
        netWeightPerUnit: e.weightUsedMs13 / e.amountUsedMs13,
        noneQty: e.noneQty ?? e.noneQtys,
        currency: form.currency,
        quantity: e.quantity,
        quantityUnit: e.quantityUnit,
        uetWeighUnit: e.uetWeighUnit,
        quantityUsed: e.quantityUsed,
        weightUsed: e.weightUsed,
        invoiceNo: e.invoiceNo,
        invoiceDate: e.invoiceDate,
        invoiceItem: e.invoiceItem,
        availableNet: e.amountUsedMs13,
        id: this.data?.id ?? null,
        fobValueBaht: e.fobValueBaht ?? 0.0,
        fobValueUsd: e.fobValueUsd ?? 0.0,
        grossWeight: e.grossWeight ?? 0.0,
        grossWeightUnit: e.grossWeightUnit ?? 0.0,
        availableNetww: e.availableNetww ? e.availableNetww + e.weightUsed : 0.0,
        usedNetww: e.usedNetww ?? 0.0,
        stopInspect: e.stopInspect ?? "N",
        kPot1: e.kPot1 ?? "-",
        kPot2: e.kPot2 ?? "-",
        kPot3: e.kPot3 ?? "-",
        kPotQty1: e.kPotQty1 ?? 0,
        kpotQty2: e.kpotQty2 ?? 0,
        kpotQty3: e.kpotQty3 ?? 0,
        grossWeightUnitExp: e.grossWeightUnitExp ?? 0.0,
      }
    });

    var dataO2D = this.dataSource.map(e => {
      return {
        ID: e.id,
        hId: e.hId,
        itemStatus: e.itemStatus,
        itemNo: e.itemNo,
        goodsDescription: e.goodsDescription,
        tariffCode: e.tariffCode,
        tariffSequence: e.tariffSequence,
        statisticalCode: e.statisticalCode,
        unitPrice: e.unitPrice,
        netWeight: e.weightUsedMs13,
        netWeightUnit: e.netWeightUnit,
        netWeightPerUnit: e.weightUsedMs13 / e.amountUsedMs13,
        noneQty: e.noneQty,
        currency: e.currency,
        fobValueBaht: e.fobValueBaht,
        quantity: e.quantity,
        quantityUnit: e.quantityUnit,
        uetWeighUnit: e.weightUsedMs13,
        quantityUsed: e.amountUsedMs13,
        weightUsed: e.weightUsedMs13,
        availableNet: e.weightUsedMs13,
        invoiceNo: e.invoiceNo,
        invoiceDate: e.invoiceDate,
        invoiceItem: e.invoiceItem,
        goodsLoadDate1: loadedStartDate,
        goodsLoadDate2: loadedEndDate
      }
    });

    const formDataMS13H = {
      ownerId: this.userProfile.uId,
      exporterId: form.exporterID,
      shippingId: form.shippingID,
      status: 1, //Save as Draft
      exporterNo: form.exporterNo,
      // exporter: this.export.nameTH,
      exporter: form.exporterNo,
      companyTax: form.companyTaxId,
      companyThName: form.companyThName,
      companyEnName: form.companyEnName,
      exporterStreetandNo: form.exporterStreetandNo,
      exporterDistrict: form.exporterDistrict,
      exporterSubProvince: form.exporterSubProvince,
      exporterProvince: form.exporterProvince,
      exporterPostcode: form.exporterPostcode,

      recieveThName: form.recieveThName,
      recieveEnName: form.recieveEnName,
      recieveStreetNo: form.recieveStreetNo,
      recieveDistrict: form.recieveDistrict,
      recieveSubProvince: form.recieveSubProvince,
      recieveProvince: form.recieveProvince,
      recievePostcode: form.recievePostcode,
      recieveCountry: form.recieveCountryCode,

      shippingName: form.shippingName,
      shippingStreetNo: form.shippingStreetNo,
      shippingDistrict: form.shippingDistrict,
      shippingSubProvince: form.shippingSubProvince,
      shippingProvince: form.shippingProvince,
      shippingPostcode: form.shippingPostcode,

      o2Id: form.o2Id,
      o2DocNo: form.o2DocNo,
      o2ExpireDate: form.o2ExpireDate,

      buyerCountry: form.buyerCountryCode,
      destinationCountry: form.destinationCountryCode,

      transportationMode: form.transportationMode,
      vesselName: form.vesselName,
      port: form.port,
      porttxt: form.porttxt,
      currency: form.currency,
      exchangeRate: form.exchangeRate,
      o2IssueDate: form.o2IssueDate,
      typeDocument: form.docTypeId,

      registrationNoPk: form.exporterNo,

      goodsName: form.productName,
      typeGoods: form.typeOfDocument,

      source: form.source,

      attachO2: form.attachO2,

      goodsLoadDate1: loadedStartDate,
      goodsLoadDate2: loadedEndDate,

      items: dataItemD,
    };

    var rqSaveMs13FromO2 = {
      ms13Request: formDataMS13H,
      o2ItemRequest: dataO2D
    };

    console.log(rqSaveMs13FromO2);

    this.appMs13Service.saveMs13FromO2(rqSaveMs13FromO2).then((res) => {
      console.log("saveMs13FromO2", res);
      if (res === 'error') {
        return;
      } else {
        this.router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
          this.router.navigate(["/page/o2/create-ms13"], {
            queryParams: {
              page: 'ms13',
              type: 'Edit',
              id: res.id,
              docNo: res.o2DocNo,
              status: res.status
            }
          }).then((r) => {
            this.dialogRef.close();
          });;
        })
      }
    });
  }
}
