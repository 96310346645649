import { ApiService } from "./../api.service";
import { Injectable } from "@angular/core";
import {
  IApiLoginResponse,
  IAppLoginRequest,
} from "app/business/interface/ap-user-interface";
import {
  IApiGetUserGroupListRequest,
  IApiGetUserListRequest,
  IApiGetUserRoleListRequest,
  IApiSaveUserRequest,
  IApiSaveUserRoleRequest,
  IApiUserRequest,
  IAppUserGroupResponse,
} from "app/business/interface/api/api-user.interface";
import { Observable } from "rxjs";
import { IAppUserRoleResponse } from "app/business/interface/app/app-user.interface";

@Injectable({
  providedIn: "root",
})
export class ApiUserService {
  constructor(private apiService: ApiService) {}

  login(data: IAppLoginRequest): Observable<any> {
    return this.apiService.post("/api/login", data);
  }
  UserList(request: IApiUserRequest): Observable<any[]> {
    return this.apiService.post("/api/getUserById", request);
  }
  getUserProfile(data: any): Observable<any> {
    return this.apiService.post("/api/getUserProfile", data);
  }
  getUserList(data: IApiGetUserListRequest): Observable<any[]> {
    return this.apiService.post("/api/getUserList", data);
  }
  getUserRoleList(
    data: IApiGetUserRoleListRequest
  ): Observable<IAppUserRoleResponse[]> {
    return this.apiService.post("/api/getMstUserRoleList", data);
  }
  getUserGroupList(
    data: IApiGetUserGroupListRequest
  ): Observable<IAppUserGroupResponse[]> {
    return this.apiService.post("/api/getMstUserGroupList", data);
  }
  saveUserGroup(data: any): Observable<any> {
    return this.apiService.post("/api/saveUserGroup", data);
  }
  saveUser(data: IApiSaveUserRequest): Observable<any> {
    return this.apiService.post("/api/saveUser", data);
  }
  saveUserRole(data: IApiSaveUserRoleRequest): Observable<any> {
    return this.apiService.post("/api/saveUserRole", data);
  }

  getUserDropdownList(data: IApiGetUserListRequest): Observable<any[]> {
    return this.apiService.post("/api/getUserDropdownList", data);
  }
  getEmail(any): Observable<any[]> {
    return this.apiService.post("/api/getEmail", any);
  }
  saveEmail(any): Observable<any[]> {
    return this.apiService.post("/api/saveEmail", any);
  }
  getUserManage(request: any): Observable<any[]> {
    return this.apiService.post("/api/getUserManage", request);
  }
  getUserPassword(request: any): Observable<any[]> {
    return this.apiService.post("/api/getUserPassword", request);
  }
  saveNewPassword(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveNewPassword", request);
  }
  getReasonLogBlackList(request: any): Observable<any[]> {
    return this.apiService.post("/api/getReasonLogBlackList", request);
  }
  getListBlackList(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListBlackList", request);
  }
  updateBlackList(request: any): Observable<any[]> {
    return this.apiService.post("/api/updateBlackList", request);
  }
  getDataCompany(reqData: any): Observable<any[]> {
    return this.apiService.post("/api/getDataCompany", reqData);
  }
  getAllReasonLog(request: any): Observable<any> {
    return this.apiService.post("/api/getAllReasonLog", {
      cpId: request,
    });
  }
  getReasonLog(request: any): Observable<any> {
    return this.apiService.post("/api/getReasonLog", request);
  }
}
