import { LiveAnnouncer } from "@angular/cdk/a11y";
import { SelectionModel } from "@angular/cdk/collections";
import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppBankService } from "app/business/service/app/app-bank.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { ConfigBankPopupComponent } from "../config-bank-inquiry/config-bank-popup/config-bank-popup.component";
import { ConfirmPopupComponent } from "../confirm-popup/confirm-popup.component";
;
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { Router } from "@angular/router";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AuthService } from "app/core/auth/auth.service";
import { StepPricePopupInquiryComponent } from "./step-price-popup-inquiry/step-price-popup-inquiry.component";

@Component({
  selector: "app-step-price-inquiry",
  templateUrl: "./step-price-inquiry.component.html",
  styleUrls: ["./step-price-inquiry.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class StepPriceInquiryComponent implements OnInit, AfterViewInit {
  BenefitTarget: any = [];
  form: any = {};
  BenefitTargetValue: string;
  displayedColumns: string[] = [
    "number",
    "code",
    "name",
    "preFix",
    "desc",
    "action",
  ];
  dataSource = new MatTableDataSource<any>();
  userProfile:any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  url: any;
  buttonList: any[] = [];
  itemList: any[];
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private alertMessageService: AlertMessageService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router,
    private appMenuService: AppMenuService,
    private appUserService: AppUserService,
    private _authService: AuthService,
  ) { }

  async getRouter(): Promise<void> {
    
    this.url = this.router.url;

    // const profile = JSON.parse(this.appUserService.getSessionUserProfile());
    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }
  
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    )
    await this.getRouter();
    this.BenefitTarget = [
      { benefitTargetCode: 1, benefitTargetTName: "10" },
      { benefitTargetCode: 2, benefitTargetTName: "20" },
    ];
    this.form.isActive = "A";

    // this.buttonList.forEach(e => {
    //   // if (e.buttonCode === "BT098") {
    //   this.displayedColumns.unshift("select");
    //   // }
    // });

    this.loadData();
  }
  loadData() {
    const data = {
      name: this.form.name,
      code: this.form.code,
      desc: this.form.desc,
    };
    this.appMasterService.getStepPrice(data).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.itemList = res;
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;        
      }
    });
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  editItem(e) {
    const data = { ...e };
    const dialogRef = this.dialog.open(StepPricePopupInquiryComponent, {
      disableClose: false,
      width: "60vw",
      data: data,
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.loadData();
    });
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();

    if (filterValue?.length > 0) {
      var res = this.itemList.filter(e => (e.code.toLowerCase().includes(filterValue) || e.name.toLowerCase().includes(filterValue) || e.desc.toLowerCase().includes(filterValue)));

      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    } else {
      this.dataSource = new MatTableDataSource(this.itemList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
  save() {}

  addAccount(data): void {
    const dialogRef = this.dialog.open(StepPricePopupInquiryComponent, {
      disableClose: false,
      width: "60vw",
      data: null,
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.loadData();
      }
    });
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  delete(e) {
    this.confirmDialogService.open({ header: "", content: "คุณต้องการลบข้อมูลนี้หรือไม่?" }).then(res => {
      if (res) {
        const data = {
          name: e.name,
          code: e.code,
          desc: e.desc,
          isActive: "N"
        };
        this.appMasterService.saveWeight(data).then((res: any) => {
          if (res === "error") {
            return;
          } else {
            this.loadData();
          }
        });
      }
    });
  }

}
