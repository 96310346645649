<div class="card" style="width: 70% !important; margin-top: 20px; margin-bottom: 40px;">
  <ng-container *ngIf="isAlert">
    <div class="alert-message">
      <div>
        การแจ้งเตือน
      </div>
      <div class="blacklist-text">
        {{alertMessage.message}}
      </div>
    </div>
  </ng-container>
  <!-- <ng-container *ngIf="isWarn">
    <div class="warn-message">
      <div>
        การแจ้งเตือน
      </div>
      <div class="blacklist-text">
        {{alertMessage.message}}
      </div>
    </div>
  </ng-container> -->
  <div class="flex p-10">
    <div class="flex-1 w-45">
      <!-- User Profile-->
      <!-- Photo -->
      <div class="flex justify-center">
        <!-- <mat-icon style="font-size: large;" svgIcon="heroicons_solid:user-circle"></mat-icon> -->
        <img src="assets/icons/user.svg" style="height: 150px; width: 150px;">
      </div>
      <!-- End Photo -->
      <div class="pb-2" style="padding-top: 20px; text-align: start;">
        <div class="title-th">ข้อมูลผู้ใช้งาน</div>
        <div class="title-eng myBox">User Profile</div>
      </div>
      <!-- Username -->
      <div class="flex flex-col pt-3">
        <div class="flex pt-2 pb-2">
          <div class="flex-1 w-50">
            <div class="flex items-center leading-5 text-md">
              <mat-icon svgIcon="heroicons_outline:user-circle"></mat-icon>
              <div class="ml-1.5">ชื่อผู้ใช้งาน</div>
            </div>
          </div>
          <div class="flex-1 w-50">
            <div class="flex items-center leading-5 text-md">
              <div class="ml-1.5">{{user?.userName}}</div>
            </div>
          </div>
        </div>
        <ng-container *ngIf="userProfile?.gId === 'UG001' || userProfile?.gId === 'UG004'">
          <div class="flex pt-2 pb-2">
            <div class="flex-1 w-50" style="align-items: center; display: flex;">
              <div class="flex items-center leading-5 text-md">
                <mat-icon svgIcon="password"></mat-icon>
                <div class="ml-1.5">รหัสผ่าน</div>
              </div>
            </div>
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md" style="width: 100%">
                <div class="ml-1.5 flex flex-row"
                  style="justify-content: space-between; width: 90%; align-items: center;" *ngIf="viewPin == false">
                  <div style="font-size: 2em;margin-top:-5px ;" *ngIf="user != undefined && user != null">••••••••</div>
                  <div>
                    <button mat-icon-button matTooltip="ดูรหัสผ่าน" (click)="viewPassword(true)"
                      matTooltipPosition="above" style="height:20px">
                      <mat-icon svgIcon="eye-open"></mat-icon>
                    </button>
                  </div>
                </div>
                <div class="ml-1.5 flex flex-row"
                  style="justify-content: space-between; width: 90%; align-items: center;" *ngIf="viewPin == true">
                  <div>{{userPw}}</div>
                  <div>
                    <button mat-icon-button matTooltip="เปลี่ยนรหัสผ่าน" (click)="changePassword()"
                      matTooltipPosition="above" style="height:20px">
                      <mat-icon svgIcon="heroicons_outline:cog"></mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="ปิดรหัสผ่าน" (click)="viewPassword(false)"
                      matTooltipPosition="above" style="height:20px">
                      <mat-icon svgIcon="eye-close"></mat-icon>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div class="flex pt-2 pb-2">
          <div class="flex-1 w-50">
            <div class="flex items-center leading-5 text-md">
              <mat-icon svgIcon="heroicons_outline:identification"></mat-icon>
              <div class="ml-1.5">กลุ่มผู้ใช้งาน</div>
            </div>
          </div>
          <div class="flex-1 w-50">
            <div class="flex items-center leading-5 text-md">
              <div class="ml-1.5">{{user?.userGroupName}}</div>
            </div>
          </div>
        </div>
        <div class="flex pt-2 pb-2">
          <div class="flex-1 w-50">
            <div class="flex items-center leading-5 text-md">
              <mat-icon svgIcon="heroicons_outline:identification"></mat-icon>
              <div class="ml-1.5">ชื่อ</div>
            </div>
          </div>
          <div class="flex-1 w-50">
            <div class="flex items-center leading-5 text-md">
              <div class="ml-1.5">{{user?.name}}</div>
            </div>
          </div>
        </div>
        <div class="flex pt-2 pb-2">
          <div class="flex-1 w-50">
            <div class="flex items-center leading-5 text-md">
              <mat-icon svgIcon="feather:smartphone"></mat-icon>
              <div class="ml-1.5">เบอร์โทร</div>
            </div>
          </div>
          <div class="flex-1 w-50">
            <div class="flex items-center leading-5 text-md">
              <div class="ml-1.5" style="text-align:left;">{{user?.phone}}</div>
            </div>
          </div>
        </div>
        <div class="flex pt-2 pb-2">
          <div class="flex-1 w-50">
            <div class="flex items-center leading-5 text-md">
              <mat-icon svgIcon="heroicons_outline:mail"></mat-icon>
              <div class="ml-1.5">อีเมล</div>
            </div>
          </div>
          <div class="flex-1 w-50">
            <div class="flex items-center leading-5 text-md">
              <div class="ml-1.5">{{user?.email}}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- End User-->
    </div>
    <!-- <div class="flex-1 w-10">

  </div> -->
    <div class="flex-1 w-45">
      <!-- Company Profile-->
      <div class="pb-2" style="text-align: start;">
        <div class="title-th">ข้อมูลบริษัท</div>
        <div class="title-eng myBox">Company Profile</div>
        <!-- Company Code -->
        <div class="flex flex-col pt-3">
          <div class="flex pt-2 pb-2">
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <mat-icon svgIcon="heroicons_outline:user-circle"></mat-icon>
                <div class="ml-1.5">เลขประจำตัวผู้เสียภาษี</div>
              </div>
            </div>
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <div class="ml-1.5">{{user?.companyTaxId}}</div>
              </div>
            </div>
          </div>
          <div class="flex pt-2 pb-2">
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <mat-icon svgIcon="heroicons_outline:identification"></mat-icon>
                <div class="ml-1.5">ชื่อบริษัท</div>
              </div>
            </div>
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <div class="ml-1.5">{{user?.companyName}}</div>
              </div>
            </div>
          </div>
          <div class="flex pt-2 pb-2">
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <mat-icon svgIcon="heroicons_outline:home"></mat-icon>
                <div class="ml-1.5">ที่อยู่บริษัท</div>
              </div>
            </div>
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <div class="ml-1.5">{{user?.address}}</div>
              </div>
            </div>
          </div>
          <div class="flex pt-2 pb-2">
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <mat-icon svgIcon="heroicons_outline:mail"></mat-icon>
                <div class="ml-1.5">อีเมลบริษัท</div>
              </div>
            </div>
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <div class="ml-1.5">{{user?.companyEmail}}</div>
              </div>
            </div>
          </div>

          <div class="flex pt-2 pb-2">
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <mat-icon svgIcon="heroicons_outline:phone"></mat-icon>
                <div class="ml-1.5">แฟกซ์</div>
              </div>
            </div>
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <div class="ml-1.5">{{user?.fax}}</div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="userProfile?.gId !== 'UG001' && userProfile?.gId !== 'UG004'">
            <div class="flex pt-2 pb-2">
              <div class="flex-1 w-50">
                <div class="flex items-center leading-5 text-md">
                  <mat-icon svgIcon="heroicons_outline:calendar"></mat-icon>
                  <div class="ml-1.5">วันที่เป็นสมาชิก</div>
                </div>
              </div>
              <div class="flex-1 w-50">
                <div class="flex items-center leading-5 text-md">
                  <div class="ml-1.5">{{user?.applyDate | date:'yyyy-MM-dd HH:mm'}}</div>
                </div>
              </div>
            </div>
            <div class="flex pt-2 pb-2">
              <div class="flex-1 w-50">
                <div class="flex items-center leading-5 text-md">
                  <mat-icon svgIcon="heroicons_outline:calendar"></mat-icon>
                  <div class="ml-1.5">วันที่หมดอายุ</div>
                </div>
              </div>
              <div class="flex-1 w-50">
                <div class="flex items-center leading-5 text-md">
                  <div class="ml-1.5">{{user?.expireDate | date:'yyyy-MM-dd HH:mm'}}</div>
                </div>
              </div>
            </div>
          </ng-container>
          <div class="flex pt-2 pb-2">
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <mat-icon svgIcon="heroicons_outline:login"></mat-icon>
                <div class="ml-1.5">เข้าสู่ระบบครั้งล่าสุด</div>
              </div>
            </div>
            <div class="flex-1 w-50">
              <div class="flex items-center leading-5 text-md">
                <div class="ml-1.5">{{user?.lastLogin | date : "yyyy-MM-dd HH:mm"}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>