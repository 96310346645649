import { AppMs13Service } from "app/business/service/app/app-ms13.service";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppO2Service } from "app/business/service/app/app-o2.service";
import { Observable, Subject } from "rxjs";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import * as _ from 'underscore';
import { isNumber } from "lodash";
import moment from "moment";

@Component({
  selector: "app-create-product-goods-item-modal",
  templateUrl: "./create-product-goods-item-modal.component.html",
  styleUrls: ["./create-product-goods-item-modal.component.css"],
})
export class CreateProductGoodsItemModalComponent implements OnInit {
  form: any = {};
  @ViewChild("FormValidateItem") FormValidateItem: NgForm;
  disabled: boolean = false;

  grpForm: FormGroup;
  valueSelect = false;
  isLoading: boolean;
  tariffList: any = [];
  tariffTypeList: any = [];
  tariffTypeFullList: any = [];
  qtyUnitList: any = [];
  qtyUnitOneList: any = [];
  selectedRow: any;
  filteredOptionsTariff$: Observable<string[]>;
  filteredOptionsTariffType$: Observable<string[]>;
  oldFobData: any = {};
  reg: any = new RegExp('^\d+$')
  options: any = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 6
  };
  get tariffSearch(): AbstractControl {
    return this.grpForm.get("tariffSearch");
  }
  get tariffTypeSearch(): AbstractControl {
    return this.grpForm.get("tariffTypeSearch");
  }
  private _unsubscribeAll$: Subject<any>;
  constructor(
    private appO2Service: AppO2Service,
    private confirmService: ConfirmDialogService,
    private appMasterService: AppMasterService,
    private appMs13Service: AppMs13Service,
    private alertMessageService: AlertMessageService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<CreateProductGoodsItemModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  async ngOnInit() {
    this.grpForm = new FormGroup({
      tariffSearch: new FormControl(),
      tariffTypeSearch: new FormControl(),
    });

    this.grpForm = this.fb.group({
      tariffSearch: [""],
      tariffTypeSearch: [""],
    });
    this.loadData();
    console.log("data", this.data);
    this.form.noneQtys = false;
    this.formInit();
    await this.getTariff();
    await this.getTarifftype();

    this.form.netQty = null;
    this.grpForm.valueChanges.subscribe((newValue) => {
      this.filteredOptionsTariff$ = this.filterTariffValues(
        newValue.tariffSearch
      );
    });
    this.grpForm.valueChanges.subscribe((newValue) => {
      this.filteredOptionsTariffType$ = this.filterTariffTypeValues(
        newValue.tariffTypeSearch
      );
    });
    this.initData();
  }
  transformValue = function (value) {

    value = parseFloat(value);
    value = parseInt(value).toLocaleString('en', this.options) + '.' + parseInt(value.toString().split('.')[1] || '0');
    value = value.replace(',', '.');
    value = value.replaceLast('.', ',');
    return value;
  };

  onFocusChang() {
    if (this.form.quantity != undefined && this.form.quantity != null && this.form.quantity != "") {
      let _quantity = this.form.quantity != undefined && this.form.quantity != null && this.form.quantity != "" ? isNumber(this.form.quantity) ? this.form.quantity : this.form.quantity?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_quantity))) {
        this.form.quantity = Number('0').toLocaleString('en', this.options);
        return;
      }
      var quantity = this.form.quantity != "" && this.form.quantity != null ? Number(_quantity).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', quantity);

      this.form.quantity = quantity;
    }
  }

  onBlurChang() {
    if (this.form.quantity != undefined && this.form.quantity != null && this.form.quantity != "") {
      let _quantity = this.form.quantity != undefined && this.form.quantity != null && this.form.quantity != "" ? isNumber(this.form.quantity) ? this.form.quantity : this.form.quantity?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_quantity))) {
        this.form.quantity = Number('0').toLocaleString('en', this.options);
        return;
      }
      var quantity = this.form.quantity != undefined && this.form.quantity != "" && this.form.quantity != null ? Number(_quantity).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);

      console.log('==>>>>', quantity);
      this.form.quantity = quantity;
    }
  }

  onFocusNetWeight() {
    if (this.form.netWeight != undefined && this.form.netWeight != null && this.form.netWeight != "") {
      let _netWeight = this.form.netWeight != undefined && this.form.netWeight != null && this.form.netWeight != "" ? isNumber(this.form.netWeight) ? this.form.netWeight : this.form.netWeight?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_netWeight))) {
        this.form.netWeight = Number('0').toLocaleString('en', this.options);
        return;
      }
      let netWeight = this.form.netWeight != undefined && this.form.netWeight != "" && this.form.netWeight != null ? Number(_netWeight).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', netWeight);

      this.form.netWeight = netWeight;
    }
  }

  onBlurNetWeight() {
    if (this.form.netWeight != undefined && this.form.netWeight != null && this.form.netWeight != "") {
      let _netWeight = this.form.netWeight != undefined && this.form.netWeight != null && this.form.netWeight != "" ? isNumber(this.form.netWeight) ? this.form.netWeight : this.form.netWeight?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_netWeight))) {
        this.form.netWeight = Number('0').toLocaleString('en', this.options);
        return;
      }

      let netWeight = this.form.netWeight != undefined && this.form.netWeight != "" && this.form.netWeight != null ? Number(_netWeight).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', netWeight);

      this.form.netWeight = netWeight;
    }
  }

  onFocusUnitPrice() {
    if (this.form.unitPrice != undefined && this.form.unitPrice != null && this.form.unitPrice != "") {
      let _unitPrice = this.form.unitPrice != undefined && this.form.unitPrice != null && this.form.unitPrice != "" ? isNumber(this.form.unitPrice) ? this.form.unitPrice : this.form.unitPrice?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_unitPrice))) {
        this.form.unitPrice = Number('0').toLocaleString('en', this.options);
        return;
      }

      var unitPrice = this.form.unitPrice != undefined && this.form.unitPrice != "" && this.form.unitPrice != null ? Number(_unitPrice).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', unitPrice);

      this.form.unitPrice = unitPrice;
    }
  }

  onBlurUnitPrice() {
    if (this.form.unitPrice != undefined && this.form.unitPrice != null && this.form.unitPrice != "") {
      let _unitPrice = this.form.unitPrice != undefined && this.form.unitPrice != null && this.form.unitPrice != "" ? isNumber(this.form.unitPrice) ? this.form.unitPrice : this.form.unitPrice?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_unitPrice))) {
        this.form.unitPrice = Number('0').toLocaleString('en', this.options);
        return;
      }
      var unitPrice = this.form.unitPrice != undefined && this.form.unitPrice != "" && this.form.unitPrice != null ? Number(_unitPrice).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', unitPrice);

      this.form.unitPrice = unitPrice;
    }
  }

  onFocusFob() {
    if (this.form.fobValueBaht != undefined && this.form.fobValueBaht != null && this.form.fobValueBaht != "") {
      let _fobValueBaht = this.form.fobValueBaht != undefined && this.form.fobValueBaht != null && this.form.fobValueBaht != "" ? isNumber(this.form.fobValueBaht) ? this.form.fobValueBaht : this.form.fobValueBaht?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_fobValueBaht))) {
        this.form.fobValueBaht = Number('0').toLocaleString('en', this.options);
        return;
      }

      var fobValueBaht = this.form.fobValueBaht != undefined && this.form.fobValueBaht != "" && this.form.fobValueBaht != null ? Number(_fobValueBaht).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', fobValueBaht);

      this.form.fobValueBaht = fobValueBaht;
    }
  }

  onBlurFob() {
    if (this.form.fobValueBaht != undefined && this.form.fobValueBaht != null && this.form.fobValueBaht != "") {
      let _fobValueBaht = this.form.fobValueBaht != undefined && this.form.fobValueBaht != null && this.form.fobValueBaht != "" ? isNumber(this.form.fobValueBaht) ? this.form.fobValueBaht : this.form.fobValueBaht?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_fobValueBaht))) {
        this.form.fobValueBaht = Number('0').toLocaleString('en', this.options);
        return;
      }

      var fobValueBaht = this.form.fobValueBaht != undefined && this.form.fobValueBaht != "" && this.form.fobValueBaht != null ? Number(_fobValueBaht).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', fobValueBaht);

      this.form.fobValueBaht = fobValueBaht;
    }
  }

  onFocusFobUsd() {
    if (this.form.fobValueUsd != undefined && this.form.fobValueUsd != null && this.form.fobValueUsd != "") {
      let _fobValueUsd = this.form.fobValueUsd != undefined && this.form.fobValueUsd != null && this.form.fobValueUsd != "" ? isNumber(this.form.fobValueUsd) ? this.form.fobValueUsd : this.form.fobValueUsd?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_fobValueUsd))) {
        this.form.fobValueUsd = Number('0').toLocaleString('en', this.options);
        return;
      }

      var fobValueUsd = this.form.fobValueUsd != undefined && this.form.fobValueUsd != "" && this.form.fobValueUsd != null ? Number(_fobValueUsd).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', fobValueUsd);

      this.form.fobValueUsd = fobValueUsd;
    }
  }

  onBlurFobUsd() {
    if (this.form.fobValueUsd != undefined && this.form.fobValueUsd != null && this.form.fobValueUsd != "") {
      let _fobValueUsd = this.form.fobValueUsd != undefined && this.form.fobValueUsd != null && this.form.fobValueUsd != "" ? isNumber(this.form.fobValueUsd) ? this.form.fobValueUsd : this.form.fobValueUsd?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_fobValueUsd))) {
        this.form.fobValueUsd = Number('0').toLocaleString('en', this.options);
        return;
      }

      var fobValueUsd = this.form.fobValueUsd != undefined && this.form.fobValueUsd != "" && this.form.fobValueUsd != null ? Number(_fobValueUsd).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', fobValueUsd);

      this.form.fobValueUsd = fobValueUsd;
    }
  }


  onFocusPerWeight() {
    if (this.form.netWeightPerUnit != undefined && this.form.netWeightPerUnit != null && this.form.netWeightPerUnit != "") {
      let _netWeightPerUnit = this.form.netWeightPerUnit != undefined && this.form.netWeightPerUnit != null && this.form.netWeightPerUnit != "" ? isNumber(this.form.netWeightPerUnit) ? this.form.netWeightPerUnit : this.form.netWeightPerUnit?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_netWeightPerUnit))) {
        this.form.netWeightPerUnit = Number('0').toLocaleString('en', this.options);
        return;
      }

      var netWeightPerUnit = this.form.netWeightPerUnit != undefined && this.form.netWeightPerUnit != "" && this.form.netWeightPerUnit != null ? Number(_netWeightPerUnit).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', netWeightPerUnit);

      this.form.netWeightPerUnit = netWeightPerUnit;
    }
  }

  onBlurPerWeight() {
    if (this.form.netWeightPerUnit != undefined && this.form.netWeightPerUnit != null && this.form.netWeightPerUnit != "") {
      let _netWeightPerUnit = this.form.netWeightPerUnit != undefined && this.form.netWeightPerUnit != null && this.form.netWeightPerUnit != "" ? isNumber(this.form.netWeightPerUnit) ? this.form.netWeightPerUnit : this.form.netWeightPerUnit?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_netWeightPerUnit))) {
        this.form.netWeightPerUnit = Number('0').toLocaleString('en', this.options);
        return;
      }

      var netWeightPerUnit = this.form.netWeightPerUnit != undefined && this.form.netWeightPerUnit != "" && this.form.netWeightPerUnit != null ? Number(_netWeightPerUnit).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', netWeightPerUnit);

      this.form.netWeightPerUnit = netWeightPerUnit;
    }
  }

  onFocusGrossPerWeight() {
    if (this.form.grossWeightPerUnit != undefined && this.form.grossWeightPerUnit != null && this.form.grossWeightPerUnit != "") {
      let _grossWeightPerUnit = this.form.grossWeightPerUnit != undefined && this.form.grossWeightPerUnit != null && this.form.grossWeightPerUnit != "" ? isNumber(this.form.grossWeightPerUnit) ? this.form.grossWeightPerUnit : this.form.grossWeightPerUnit?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_grossWeightPerUnit))) {
        this.form.grossWeightPerUnit = Number('0').toLocaleString('en', this.options);
        return;
      }

      var grossWeightPerUnit = this.form.grossWeightPerUnit != undefined && this.form.grossWeightPerUnit != "" && this.form.grossWeightPerUnit != null ? Number(_grossWeightPerUnit).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', grossWeightPerUnit);

      this.form.grossWeightPerUnit = grossWeightPerUnit;
    }
  }

  onBlurGrossPerWeight() {
    if (this.form.grossWeightPerUnit != undefined && this.form.grossWeightPerUnit != null && this.form.grossWeightPerUnit != "") {
      let _grossWeightPerUnit = this.form.grossWeightPerUnit != undefined && this.form.grossWeightPerUnit != null && this.form.grossWeightPerUnit != "" ? isNumber(this.form.grossWeightPerUnit) ? this.form.grossWeightPerUnit : this.form.grossWeightPerUnit?.replaceAll(',', '') : NaN;
      if (isNaN(Number(_grossWeightPerUnit))) {
        this.form.grossWeightPerUnit = Number('0').toLocaleString('en', this.options);
        return;
      }

      var grossWeightPerUnit = this.form.grossWeightPerUnit != undefined && this.form.grossWeightPerUnit != "" && this.form.grossWeightPerUnit != null ? Number(_grossWeightPerUnit).toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
      console.log('==>>>>', grossWeightPerUnit);

      this.form.grossWeightPerUnit = grossWeightPerUnit;
    }
  }

  initData() {
     ;
    if (this.data.type != "new") {
      if (this.data.type != "info") {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
      if (this.data.page == "o2") {
        const formData = {
          ID: this.data.id,
          hId: this.data.hId,
        };
        this.appO2Service.getO2item(formData).then((res) => {
          console.log(res);
          if (res != null && res.length > 0) {
            this.form = res[0];

            if (this.form.noneQty == "N") {
              this.form.noneQtyOnes = false;
              this.form.noneQtys = true;
              this.form.netQty = "";
            } else {
              this.form.noneQtyOnes = true;
              this.form.noneQtys = false;
              this.form.netQty = this.form.quantityUnit;
              this.form.quantity = "";
              this.form.quantityUnit = "";
            }

            var itemNo = this.form.itemNo != undefined && this.form.itemNo != null ? Number(this.form.itemNo) : 1;
            this.form.itemNo = itemNo;

            var tariffItem = this.tariffList.find(e => e.id == this.form.tariffCode);
            var tariffTypeItem = this.tariffTypeFullList.find(e => e.tariffClass == this.form.tariffCode && e.id == this.form.tariffSequence);

            this.grpForm = this.fb.group({
              tariffSearch: [this.form.tariffCode],
              tariffTypeSearch: [this.form.tariffSequence],
            });

            this.grpForm.get("tariffSearch").setValue(tariffItem?.desc);
            this.grpForm.get("tariffTypeSearch").setValue(tariffTypeItem?.desc);

            this.tariffTypeList = [];

            this.tariffTypeList = this.tariffTypeFullList.filter(el => el.tariffClass == this.form.tariffCode);

            if (this.tariffTypeList.length === 1) {
              var firstItem = _.first(this.tariffTypeList);

              this.grpForm.get("tariffTypeSearch").setValue(firstItem.desc);
            }

            this.filteredOptionsTariffType$ = this.tariffTypeList;

            this.onBlurChang();
            this.onBlurNetWeight();
            this.onBlurUnitPrice();
            this.onBlurPerWeight();
            this.onBlurFob();

            if (this.disabled) {
              this.grpForm.get("tariffSearch").disable();
              this.grpForm.get("tariffTypeSearch").disable();
            }

            if (this.data.type === 'edit') {
              this.form.currency = this.data.currency;
              this.form.exchangeRate = this.data.exchangeRate;
              this.calNetWeightPerUnit(this.form.netWeightPerUnit);
              this.calFOB(null);
            }

          } else {
            this.form = this.data.item;
            this.form.grossWeightPerUnit = this.form.grossWeightUnit;
            if (this.form.noneQty == "N") {
              this.form.noneQtyOnes = false;
              this.form.noneQtys = true;
              this.form.netQty = "";
            } else {
              this.form.noneQtyOnes = true;
              this.form.noneQtys = false;
              this.form.netQty = this.form.quantityUnit;
              this.form.quantity = "";
              this.form.quantityUnit = "";
            }

            this.form.availableNet =
              this.form.availableNet != null
                ? this.form.availableNet
                : this.form.netWeight - this.form.weightUsed;
            this.calNetWeightPerUnit(this.form.netWeightPerUnit);
            this.calFOB(null);

            var tariffItem = this.tariffList.find(e => e.id == this.form.tariffCode);
            var tariffTypeItem = this.tariffTypeFullList.find(e => e.tariffClass == this.form.tariffCode && e.id == this.form.tariffSequence);

            this.grpForm = this.fb.group({
              tariffSearch: [this.form.tariffCode],
              tariffTypeSearch: [this.form.tariffSequence],
            });

            this.grpForm.get("tariffSearch").setValue(tariffItem.desc);
            this.grpForm.get("tariffTypeSearch").setValue(tariffTypeItem.desc);

            this.tariffTypeList = [];

            this.tariffTypeList = this.tariffTypeFullList.filter(el => el.tariffClass == this.form.tariffCode);

            if (this.tariffTypeList.length === 1) {
              var firstItem = _.first(this.tariffTypeList);

              this.grpForm.get("tariffTypeSearch").setValue(firstItem.desc);
            }

            this.filteredOptionsTariffType$ = this.tariffTypeList;

            if (this.disabled) {
              this.grpForm.get("tariffSearch").disable();
              this.grpForm.get("tariffTypeSearch").disable();
            }
          }
        });
      } else {
        this.form = this.data.item;


        // this.form.availableNetww = Number(this.form.availableNetww).toLocaleString('en', this.options);
        // this.form.fobValueBaht = Number(this.form.fobValueBaht).toLocaleString('en', this.options);
        // this.form.fobValueUsd = Number(this.form.fobValueUsd).toLocaleString('en', this.options);
        // this.form.grossWeight = Number(this.form.grossWeight).toLocaleString('en', this.options);
        // this.form.grossWeightUnit = Number(this.form.grossWeightUnit).toLocaleString('en', this.options);
        // this.form.netWeight = Number(this.form.netWeight).toLocaleString('en', this.options);
        // this.form.netWeightPerUnit = Number(this.form.netWeightPerUnit).toLocaleString('en', this.options);
        // this.form.unitPrice = Number(this.form.unitPrice).toLocaleString('en', this.options);
        // this.form.usedNetww = Number(this.form.usedNetww).toLocaleString('en', this.options);
        // this.form.weighUsed = Number(this.form.weighUsed).toLocaleString('en', this.options);


        this.form.exchangeRate = this.data.exchangeRate;
        this.form.grossWeightPerUnit = !isNaN(this.form.grossWeightUnit) ? this.form.grossWeightUnit.toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
        
        if (this.form.noneQty == "N") {
          this.form.noneQtyOnes = false;
          this.form.noneQtys = true;
          this.form.netQty = "";
        } else {
          this.form.noneQtyOnes = true;
          this.form.noneQtys = false;
          this.form.netQty = this.form.quantityUnit;
          this.form.quantity = "";
          this.form.quantityUnit = "";
        }

        var tariffItem = this.tariffList.find(e => e.id == this.form.tariffCode);
        var tariffTypeItem = this.tariffTypeFullList.find(e => e.tariffClass == this.form.tariffCode && e.id == this.form.tariffSequence);

        this.grpForm = this.fb.group({
          tariffSearch: [this.form.tariffCode],
          tariffTypeSearch: [this.form.tariffSequence],
        });

        this.grpForm.get("tariffSearch").setValue(tariffItem.desc);
        this.grpForm.get("tariffTypeSearch").setValue(tariffTypeItem.desc);

        this.tariffTypeList = [];

        this.tariffTypeList = this.tariffTypeFullList.filter(el => el.tariffClass == this.form.tariffCode);

        if (this.tariffTypeList.length === 1) {
          var firstItem = _.first(this.tariffTypeList);

          this.grpForm.get("tariffTypeSearch").setValue(firstItem.desc);
        }

        this.filteredOptionsTariffType$ = this.tariffTypeList;

        if (this.disabled) {
          this.grpForm.get("tariffSearch").disable();
          this.grpForm.get("tariffTypeSearch").disable();
        }

        this.onBlurChang();
        this.onBlurNetWeight();
        this.onBlurUnitPrice();
        this.onBlurPerWeight();
        this.onBlurFob();

        this.calNetWeightPerUnit(this.form.netWeightPerUnit);
        this.calFOB(null);

        console.log(this.form)
      }
    } else {
      this.form.noneQtyOnes = false;
      this.form.noneQtys = true;
      this.form.noneQty = "N";
    }
  }

  formInit(): void {
    console.log(this.data)
    this.form.netWeighUnit = "";
    this.form.currency = this.data.currency;
    this.form.exchangeRate = this.data.exchangeRate;
  }

  async loadData() {
    this.appMasterService.getQtyUnit({ ID: "" }).then((res) => {
      this.qtyUnitList = res;
    });
    this.appMasterService.getQtyUnitOne({ ID: "" }).then((ress) => {
      this.qtyUnitOneList = ress;
    });
    this.form.netWeightUnit = "KGM";
  }

  async getTariff() {
    const rqTariff = {
      docTypeId: this.data.docTypeId,
    };
    await this.appMasterService.getTariff(rqTariff).then((res) => {
      this.tariffList = res;
    });
    this.filteredOptionsTariff$ = this.tariffList;
  }

  selectionTariffChange(e): void {
    this.grpForm.get("tariffSearch").setValue(e.option.value.desc);
    this.form.tariffCode = e.option.value.id;

    this.grpForm.get("tariffTypeSearch").setValue(null);
    this.form.statisticalCode = null;
    this.form.tariffSequence = null;

    this.tariffTypeList = [];

    this.tariffTypeList = this.tariffTypeFullList.filter(el => el.tariffClass == e.option.value.id);

    if (this.tariffTypeList.length === 1) {
      var firstItem = _.first(this.tariffTypeList);

      this.grpForm.get("tariffTypeSearch").setValue(firstItem.desc);
      this.form.statisticalCode = firstItem.id;
      this.form.tariffSequence = firstItem.id;
    }

    this.filteredOptionsTariffType$ = this.tariffTypeList;
  }
  filterTariffValues(search: any) {
    return this.tariffList.filter(
      (value) => value.id.includes(search) || value.desc.includes(search)
    );
  }

  async getTarifftype() {
    const rqTariff = {
      ID: "",
    };
    await this.appMasterService.getTarifftype(rqTariff).then((res) => {
      this.tariffTypeFullList = res;
    });
    this.filteredOptionsTariffType$ = this.tariffTypeFullList;
  }
  selectionTariffTypeChange(e): void {
    this.grpForm.get("tariffTypeSearch").setValue(e.option.value.desc);
    this.form.statisticalCode = e.option.value.id;
    this.form.tariffSequence = e.option.value.id;
  }
  filterTariffTypeValues(search: any) {
    var ss = this.tariffTypeList.filter(
      (value) => value.id.includes(search) || value.desc.includes(search)
    );
    return ss;
  }

  selectQty(e): void {
    this.form.netWeightUnit = this.qtyUnitList.find((f) => f.id == e)?.code;
  }

  setCheckNoneQty(value) {
    this.form.noneQtyOnes = !value
    this.form.noneQtys = value
    this.form.noneQty = value == false ? "Y" : "N";
    this.form.noneQtyOne = value == false ? "N" : "Y";

    this.form.netQty = "";
  }

  setCheckNoneQtyOne(value) {
    this.form.noneQtyOnes = value
    this.form.noneQtys = !value
    this.form.noneQty = value == true ? "Y" : "N";
    this.form.noneQtyOne = value == true ? "N" : "Y";

    this.form.quantity = "";
    this.form.quantityUnit = "";
  }

  calNetWeightPerUnit(e) {
    // 
    let _netWeight = "";
    let _quantity = "";
    let netWeight = 0;
    let quantity = 0;

    if (this.form.netWeight != null && this.form.netWeight != "") {
      if (isNumber(this.form.netWeight)) {
        netWeight = this.form.netWeight;
      } else {
        _netWeight = this.form.netWeight.replaceAll(',', '');
        netWeight = Number(_netWeight);
      }
    } else {
      netWeight = 0;
    }

    if (this.form.quantity != null && this.form.quantity != "") {
      if (isNumber(this.form.quantity)) {
        quantity = this.form.quantity;
      } else {
        _quantity = this.form.quantity.replaceAll(',', '');
        quantity = Number(_quantity);
      }
    } else {
      quantity = 0;
    }

    let nwValue = !isNaN(netWeight) ? netWeight : 0
    let qtValue = !isNaN(quantity) ? quantity : 0
    let netWeightPerUnit = qtValue > 0 ? Number(nwValue / qtValue) : 0;
    let grossWeightPerUnit = qtValue > 0 ? Number(nwValue / qtValue) : 0;
    this.form.netWeightPerUnit = !isNaN(netWeightPerUnit) ? netWeightPerUnit.toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
    //this.form.grossWeightPerUnit = !isNaN(grossWeightPerUnit) ? grossWeightPerUnit.toLocaleString('en', this.options) : Number('0').toLocaleString('en', this.options);
  }

  calFOB(e) {
    let _netWeight = '';
    let _unitPrice = '';
    let _exchangeRate = this.form.exchangeRate != null && this.form.exchangeRate != "" ? this.form.exchangeRate : 0;
    let netWeight = 0;
    let unitPrice = 0;
    let exchangeRate = Number(_exchangeRate);

    if (this.form.netWeight != null && this.form.netWeight != "") {
      if (isNumber(this.form.netWeight)) {
        netWeight = this.form.netWeight;
      } else {
        _netWeight = this.form.netWeight.replaceAll(',', '');
        netWeight = Number(_netWeight);
      }
    } else {
      netWeight = 0;
    }

    if (this.form.unitPrice != null && this.form.unitPrice != "") {
      if (isNumber(this.form.unitPrice)) {
        unitPrice = this.form.unitPrice;
      } else {
        _unitPrice = this.form.unitPrice.replaceAll(',', '');
        unitPrice = Number(_unitPrice);
      }
    } else {
      unitPrice = 0;
    }

    let nwValue = !isNaN(netWeight) ? netWeight : 0
    let upValue = !isNaN(unitPrice) ? unitPrice : 0

    var fobBaht = Number((nwValue * upValue * exchangeRate) / 1000)
    var fobUsd = Number((nwValue * upValue) / 1000)

    this.form.fobValueBaht = this.roundTo(fobBaht, 6).toLocaleString('en', this.options);
    this.form.fobValueUsd = this.roundTo(fobUsd, 6).toLocaleString('en', this.options);
  }

  roundTo(num: number, places: number) {
    const factor = 10 ** places;
    return Math.round(num * factor) / factor;
  };

  validateForm() {
    let formCheck = true;

    if (this.form.invoiceNo === undefined || this.form.invoiceNo === null || this.form.invoiceNo === '') {
      formCheck = false;
    }

    if (this.form.invoiceDate === undefined || this.form.invoiceDate === null || this.form.invoiceDate === '') {
      formCheck = false;
    }

    if (this.form.invoiceItem === undefined || this.form.invoiceItem === null || this.form.invoiceItem === '') {
      formCheck = false;
    }

    if (this.form.goodsDescription === undefined || this.form.goodsDescription === null || this.form.goodsDescription === '') {
      formCheck = false;
    }

    if (this.form.tariffCode === undefined || this.form.tariffCode === null || this.form.tariffCode === '') {
      formCheck = false;
    }

    if (this.form.statisticalCode === undefined || this.form.statisticalCode === null || this.form.statisticalCode === '') {
      formCheck = false;
    }

    if (this.form.noneQtys) {
      if (this.form.quantity === undefined || this.form.quantity === null || this.form.quantity === '') {
        formCheck = false;
      }

      if (this.form.netWeight === undefined || this.form.netWeight === null || this.form.netWeight === '') {
        formCheck = false;
      }

      if (this.form.unitPrice === undefined || this.form.unitPrice === null || this.form.unitPrice === '') {
        formCheck = false;
      }

      if (this.data.page == 'ms13') {  
        if (this.form.grossWeightPerUnit === undefined || this.form.grossWeightPerUnit === null || this.form.grossWeightPerUnit === '') {
          formCheck = false;
        }
      }
    }

    if (this.form.noneQtyOnes) {
      if (this.data.page == 'ms13') {  
        if (this.form.grossWeightPerUnit === undefined || this.form.grossWeightPerUnit === null || this.form.grossWeightPerUnit === '') {
          formCheck = false;
        }
      }

      if (this.form.netQty === undefined || this.form.netQty === null || this.form.netQty === '') {
        formCheck = false;
      }

      if (this.form.unitPrice === undefined || this.form.unitPrice === null || this.form.unitPrice === '') {
        formCheck = false;
      }
    }

    return formCheck;
  }

  saveItem(): void {
     
    if (this.validateForm()) {
      if (this.data.type != "info") {
        let _weightUsed =  isNumber(this.form.weightUsed) ? this.form.weightUsed : (this.form?.weightUsed) ? Number(this.form.weightUsed?.replaceAll(',', '')) : 0;
        let availableNet = isNumber(this.form.netWeight) ? this.form.netWeight - _weightUsed: Number(this.form.netWeight?.replaceAll(',', '')) - _weightUsed;

        if ((this.form.invoiceDate instanceof Date)) {
          var newDate = moment(this.form.invoiceDate);

          var adjustedDate = newDate.toISOString(true);

          this.form.invoiceDate = adjustedDate
        }

        const formData = {
          ID: this.data?.id ?? 0,
          hId: this.data?.hId ?? 1,
          itemStatus: this.form.itemStatus ?? 1,
          itemNo: this.form.itemNo,
          goodsDescription: this.form.goodsDescription,
          tariffCode: this.form.tariffCode,
          tariffSequence: this.form.tariffSequence,
          statisticalCode: this.form.statisticalCode,
          unitPrice: isNumber(this.form.unitPrice) ? this.form.unitPrice : Number(this.form.unitPrice?.replaceAll(',', '')),
          netWeight: isNumber(this.form.netWeight) ? this.form.netWeight : Number(this.form.netWeight?.replaceAll(',', '')),
          netWeightUnit: this.form.netWeightUnit,
          netWeightPerUnit: isNumber(this.form.netWeightPerUnit) ? this.form.netWeightPerUnit : Number(this.form.netWeightPerUnit?.replaceAll(',', '')),
          grossWeightUnit: isNumber(this.form.grossWeightPerUnit) ? this.form.grossWeightPerUnit : Number(this.form.grossWeightPerUnit?.replaceAll(',', '')),
          noneQty: this.form.noneQty,
          currency: this.form.currency,
          fobValueBaht: isNumber(this.form.fobValueBaht) ? this.form.fobValueBaht : Number(this.form.fobValueBaht?.replaceAll(',', '')),
          quantity: isNumber(this.form.quantity) ? this.form.quantity : Number(this.form.quantity?.replaceAll(',', '')),
          quantityUnit: this.form.noneQty === 'N' ? this.form.quantityUnit : this.form.netQty,
          uetWeighUnit: this.form.uetWeighUnit,
          quantityUsed: this.form.quantityUsed,
          weightUsed: this.form.weightUsed,
          invoiceNo: this.form.invoiceNo,
          invoiceDate: this.form.invoiceDate,
          invoiceItem: this.form.invoiceItem,
          availableNet: availableNet,
        };
        console.log(formData);
        // return;
        if (this.data.page == "o2") {
          this.appO2Service.O2itemsave(formData).then((res) => {
            if (res === 'error') {
              return;
            } else {
              this.dialogRef.close(res);
            }
          });
        } else {
          // this.form.availableNetww = this.form.quantity - this.form.quantityUsed;
          // this.form.grossWeightUnit = this.form.netWeight;
          // this.form.usedNetww = this.form.quantityUsed;
          let _weightUsed = isNumber(this.form?.weightUsed) ? this.form?.weightUsed : (this.form?.weightUsed) ? Number(this.form.weightUsed?.replaceAll(',', '')) : 0;
          let availableNet = isNumber(this.form?.netWeight) ? this.form.netWeight : Number(this.form.netWeight?.replaceAll(',', '')) - _weightUsed;
          const formDataItemMs13 = {
            id: this.form?.itemId ?? null,
            hId: this.form?.itemHid ?? this.data?.hId,
            itemStatus: this.form.itemStatus ?? 1,
            itemNo: this.form.itemNo?.toString(),
            goodsDescription: this.form.goodsDescription,
            tariffCode: this.form.tariffCode,
            tariffSequence: this.form.tariffSequence,
            statisticalCode: this.form.statisticalCode,
            unitPrice: isNumber(this.form.unitPrice) ? this.form.unitPrice : Number(this.form.unitPrice?.replaceAll(',', '')),
            netWeight: isNumber(this.form.netWeight) ? this.form.netWeight : Number(this.form.netWeight?.replaceAll(',', '')),
            netWeightUnit: this.form.netWeightUnit,
            netWeightPerUnit: isNumber(this.form.netWeightPerUnit) ? this.form.netWeightPerUnit : Number(this.form.netWeightPerUnit?.replaceAll(',', '')),
            noneQty: this.form.noneQty,
            currency: this.form.currency,
            fobValueBaht: isNumber(this.form.fobValueBaht) ? this.form.fobValueBaht : Number(this.form.fobValueBaht?.replaceAll(',', '')),
            fobValueUsd: isNumber(this.form.fobValueUsd) ? this.form.fobValueUsd : Number(this.form.fobValueUsd?.replaceAll(',', '')),
            quantity: isNumber(this.form.quantity) ? this.form.quantity : Number(this.form.quantity?.replaceAll(',', '')),
            quantityUnit: this.form.noneQty === 'N' ? this.form.quantityUnit : this.form.netQty,
            uetWeighUnit: this.form.uetWeighUnit,
            quantityUsed: this.form.quantityUsed,
            weightUsed: this.form.weightUsed,
            invoiceNo: this.form.invoiceNo,
            invoiceDate: this.form.invoiceDate,
            invoiceItem: this.form.invoiceItem,
            availableNet: availableNet,
            grossWeight: this.form.grossWeight ?? 0.0,
            grossWeightUnit: isNumber(this.form.grossWeightPerUnit) ? this.form.grossWeightPerUnit : Number(this.form.grossWeightPerUnit?.replaceAll(',', '')),
            availableNetww: this.form.availableNetww ?? 0.0,
            usedNetww: this.form.usedNetww ?? 0.0,
            stopInspect: this.form.stopInspect ?? "N",
            kPot1: this.form.kPot1 ?? "-",
            kPot2: this.form.kPot2 ?? "-",
            kPot3: this.form.kPot3 ?? "-",
            kPotQty1: this.form.kPotQty1 ?? 0,
            kpotQty2: this.form.kpotQty2 ?? 0,
            kpotQty3: this.form.kpotQty3 ?? 0,
            grossWeightUnitExp: this.form.grossWeightUnitExp ?? 0.0,
          };
          // return;
          this.appMs13Service.saveItemMs13(formDataItemMs13).then((res) => {
            if (res === 'error') {
              return;
            } else {
              this.dialogRef.close(res);
            }
          });
        }
      } else {
        this.dialogRef.close();
      }
    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบถ้วนก่อนการบันทึก", title: "" });
    }
  }
}
