<mat-card style="width: 100%;">
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">รายการเพิ่มเติม | Other Payment</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <!-- <mat-tab-group (selectedTabChange)="tabChanged($event)" mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab label="Other List"> -->
    <div class="flex flex-col py-5 gap-4">
      <div>
        <div class="flex flex-row">
          <div class="basis-1/3">รายการค่าธรรมเนียม : </div>
          <div class="basis-2/3">
            <mat-form-field class="w100">
              <mat-select [(ngModel)]="form.fee" (selectionChange)="selectionFeeRate($event)" name="fee">
                <mat-option *ngFor="let fee of feeRateList" [value]="fee">
                  {{fee.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div>
        <div class="flex flex-row">
          <div class="basis-1/3">จำนวน : </div>
          <div class="basis-2/3">
            <mat-form-field class="w100">
              <input autocomplete="off" required type="number" matInput placeholder="จำนวน" #input
                [(ngModel)]="form.qty" [min]="1" name="qty" #netQty="ngModel">
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="form.flagDocumentNo == 'Y'">
        <div class="flex flex-row">
          <div class="basis-1/3">{{form.documentNoWording}} : </div>
          <div class="basis-2/3">
            <mat-form-field *ngIf="!isShowList" class="w100">
              <textarea autocomplete="off" type="text" matInput placeholder="{{form.documentNoWording}}" #input
                [(ngModel)]="form.remark" name="remark" #remark="ngModel">
                  </textarea>
            </mat-form-field>

            <div *ngIf="isShowList" [formGroup]="docForm">
              <mat-form-field class="w100">
                <input type="text" matInput [disabled]="disabled" formControlName="docSearch"
                  [matAutocomplete]="autoaxxxxrr">
                <mat-autocomplete #autoaxxxxrr="matAutocomplete" (optionSelected)="selectionDocChange($event)">
                  <mat-option *ngFor="let option of filteredOptionsDoc$" [value]="option">
                    {{option.fullRefNo}} {{ option.refNo}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>

          </div>
        </div>
      </div>
    </div>
    <!-- </mat-tab> 
    </mat-tab-group> -->
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [disabled]="form.qty == null && form.reprint == null" [mat-dialog-close]="form"
      color="accent">เพิ่มรายละเอียด</button>
    <!-- <button mat-raised-button [mat-dialog-close]>ยกเลิก</button> -->
  </mat-dialog-actions>