import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { AppO2Service } from "app/business/service/app/app-o2.service";
import { DxDataGridComponent } from "devextreme-angular";
import { CreateO2ModalComponent } from "../../o-two/create-o2-modal/create-o2-modal.component";
import { Router } from "@angular/router";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AuthService } from "app/core/auth/auth.service";
import { CreateMs24ModalComponent } from "../create-ms24-modal/create-ms24-modal.component";
import { CreateShortModalComponent } from "../create-short-modal/create-short-modal.component";
import { RenewMs24InquiryComponent } from "../renew-ms24-inquiry/renew-ms24-inquiry.component";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { Ms24RecordLogDetailComponent } from "../ms24-record-log-detail/ms24-record-log-detail.component";

@Component({
  selector: "app-ms24-inquiry",
  templateUrl: "./ms24-inquiry.component.html",
  styleUrls: ["./ms24-inquiry.component.css"],
})
export class Ms24InquiryComponent implements OnInit {
  searchForm: any = {};
  form: any = {};
  productList: any = {};
  statusList: any = {};
  dataSource = new MatTableDataSource<any>();
  isLoading = false;
  count: number;
  ms13Id: any;
  displayedColumns: string[] = [
    "number",
    "ms13No",
    "o2No",
    "docNo",
    "productId",
    "expireDate",
    "vesselName",
    "statusCode",
    "invoiceNo",
    "action",
  ];

  url: any;
  buttonList: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  searchString: any = "";
  timer: any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private appO2Service: AppO2Service,
    private confirmService: ConfirmDialogService,
    private alertMessageService: AlertMessageService,
    private appMs24Service: AppMs24Service,
    private router: Router,
    private _authService: AuthService,
    private appMenuService: AppMenuService,
  ) {
    if (this.userProfile.gId != "UG002") {
      //Admin/BOT/Surveyor
      this.displayedColumns.splice(1, 0, "companyName");
    }
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    await this.getRouter();
    this.searchForm = {};
    this.loadData();
    this.searchForm.nameSearch = "";
  }
  clearOption() {
    this.searchForm = {};
    this.searchString = "";
    this.searchOption();
  }

  async loadData() {
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: 0,
      isActive: "Y",
    };
    await this.appMasterService.getProduct(formProduct).then((product) => {
      //console.log(product);
      this.productList = product;
    });

    //this.productList = await this.appMasterService.getProduct(formProduct);
    // ;
    const formStatus = {
      statusId: 0,
      isActive: "Y",
      hCode: "STATUS"
    };
    // this.statusList = await this.appMasterService.getStatus(formStatus);
    await this.appMasterService.getStatus(formStatus).then((status) => {
      //console.log(status);
      this.statusList = status;
    });

    this.loadDataSource();
  }

  async loadDataSource() {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    const formData = {
      UID: this.userProfile.uId,
      keyword: this.searchForm.keyword ?? null,
      refNo: this.searchForm.refNo,
      o2No: this.searchForm.o2No,
      docNo: this.searchForm.docNo,
      ms13No: this.searchForm.ms13No,
      petitionNo: this.searchForm.petitionNo,
      vesselName: this.searchForm.vesselName,
      expireDate: this.searchForm.expireDate,
      productId: this.searchForm.productId,
      status: this.searchForm.statusId,
      pagination: {
        skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
    };
    // console.log(this.statusList);
    // console.log(this.productList);
    await this.appMs24Service.getMs24List(formData).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        var dataSourceList = res.data.map((x, n) => {
          return {
            ...x,
            //rowNo: (formData.pagination.skip * formData.pagination.take)+(n+1),
            action: "",
            statusCode: this.statusList.find((f) => f.statusId == x.status)?.statusName,
          };
        });
        this.dataSource.data = dataSourceList;
        this.count = res.total
      }
    })
    this.dataSource.paginator.length = this.count;
    this.isLoading = false;
  }

  async _page(e) {
    this.isLoading = true;

    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    this.searchForm.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };

    const formData = {
      UID: this.userProfile.uId,
      keyword: this.searchForm.keyword ?? null,
      refNo: this.searchForm.refNo,
      o2No: this.searchForm.o2No,
      docNo: this.searchForm.docNo,
      ms13No: this.searchForm.ms13No,
      petitionNo: this.searchForm.petitionNo,
      vesselName: this.searchForm.vesselName,
      expireDate: this.searchForm.expireDate,
      productId: this.searchForm.productId,
      status: this.searchForm.statusId,
      pagination: {
        Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
        /*(!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),*/
        Take: !e.pageSize ? 10 : e.pageSize, //!this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appMs24Service.getMs24List(formData).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        var dataSourceList = res.data.map((x, n) => {
          return {
            ...x,
            //rowNo: (formData.pagination.skip * formData.pagination.take)+(n+1),
            action: "",
            statusCode: this.statusList.find((f) => f.statusId == x.status)?.statusName,
          };
        });
        this.dataSource.data = dataSourceList;
        this.count = res.total
      }
    })

    this.dataSource.paginator.length = this.count
    this.dataSource.paginator.pageIndex = e.pageIndex
    this.isLoading = false;
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: Event) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.searchForm.keyword = event;
      this.dataSource.paginator = this.paginator;
      this.loadDataSource();
    }, 500); // Specify the desired delay in milliseconds
  }

  async deleteItem(e) {
    // -สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "ต้องการลบใบ มส 24 หรือไม่",
      content: "ยืนยันการลบ",
    });
    // ;
    if (alertConfirm) {
      var rqInactiveMs24 = {
        ms24Hid: e.id,
        isActive: "N"
      }

      this.appMs24Service.updateMs24Active(rqInactiveMs24).then(res => {
        if (res === 'error') {
          return;
        } else {
          this.loadDataSource();
        }
      });
    }
  }

  createMs24(e) {
    const dialogRef = this.dialog.open(CreateO2ModalComponent, {
      disableClose: true,
      maxWidth: "none",
      width: "65vw",
      data: {
        page: "createMs24",
      },
    });

    // dialogRef.afterClosed().subscribe((result) => {
    //   // console.log('The dialog was closed');
    //   // this.animal = result;
    // });
  }
  stopPropagation(event) {
    event.stopPropagation();
  }
  // menuOpen() {}
  menuClosed() {
    console.log("menuClosed @configbug");
  }

  async searchOption() {
    this.searchForm.keyword = "";
    this.searchString = "";

    this.loadDataSource();
  }

  exportMs24(e) {
    const formDataMs13 = {
      ms13hId: e.id,
      copyNumber: 4,
    };
    this.appMs24Service.printCertificateMS24(formDataMs13).then((res) => {
      window.open(res.pathPdf, "_blank");
      this.searchOption();
    });
  }
  async expireO2(e) {
    //-สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "ต้องการสิ้นสุดงานหรือไม่",
      content: "ยืนยันการสิ้นสุดงาน",
    });
    // ;
    if (alertConfirm) {
    }
  }

  addShort(e) {
    var dData = {
      item: e,
      mode: 'add'
    }

    this.dialog.open(CreateShortModalComponent, {
      data: dData,
      width: '75vw',
      disableClose: true
    })
  }

  renewMs24(e) {
    this.dialog.open(RenewMs24InquiryComponent, {
      width: '75vw',
      disableClose: true,
      data: {
        item: e,
        isStaff: false,
        isDetail: false
      }
    });
  }

  sendDft(e) {
    this.confirmService.open({
      header: "",
      content: "คุณต้องการส่งเอกสารไปที่ระบบของกรมการค้าระหว่างประเทศใหม่หรือไม่?"
    }).then(res => {
      if (res) {
        const formDataMs13 = {
          id: e.id,
        };

        this.appMasterService.sendMS24toOcsConnectExternal(formDataMs13).then(res => {
          if (res != 'error') {
            this.alertMessageService.open({
              message: "ส่งข้อความเรียบร้อย",
              title: ""
            });
          }
        });
      }
    });
  }

  openDocumentHistory(e) {
    this.dialog.open(Ms24RecordLogDetailComponent, {
      data: e,
      disableClose: false,
      width: '65vw',
    })
  }

  onRefresh() {
    this.clearOption();
  }
}
