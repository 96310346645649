import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';

@Component({
  selector: 'app-ms13-reject-reason-modal',
  templateUrl: './ms13-reject-reason-modal.component.html',
  styleUrls: ['./ms13-reject-reason-modal.component.css']
})
export class Ms13RejectReasonModalComponent implements OnInit {
  remark: string = "";

  constructor(
    private dialogRef: MatDialogRef<Ms13RejectReasonModalComponent>,
    private alertMessage: AlertMessageService
  ) { }

  ngOnInit() {
  }

  saveReason() {
    if (this.remark == undefined || this.remark == null || this.remark == "" || this.remark.length == 0) {
      this.alertMessage.open({
        message: "กรุณาระบุเหตุผลที่ไม่อนุมัติเอกสาร",
        title: ""
      });

      return;
    }

    this.dialogRef.close(this.remark);
  }
}
