<div>
  <div class="flex flex-row justify-between items-center text-lg p-1 pl-2 m-5">
    <div style="width: 5%"></div>
    <div style="font-size: 1.3em;">ประกาศ</div>
    <div>
      <button style="height:24px;" mat-icon-button type="button" [mat-dialog-close]="true">
        <i class="ti ti-x"></i>
      </button>
    </div>
  </div>
</div>
<mat-dialog-content class="p-0 m-0" style="max-height: 80vh !important;">
  <div class="widget-container">
    <div class="widget-container">
      <dx-gallery [dataSource]="dataSource" [loop]="true" [height]="550" [slideshowDelay]="slideshowDelay" [loop]="true"
        [showNavButtons]="true" [showIndicator]="true">
        <div *dxTemplate="let galleryItem of 'item'">
          <a href="{{galleryItem.shorturl_th}}" target="_blank">
            <div class="text-3xl"
              style="white-space: pre-wrap;font-family: kanit-regular; src: url(/src/assets/fonts/kanit/kanit-light.otf) format('opentype');">
              {{galleryItem.name}}
            </div>
            <img style="width: 100%; height: 100%;" src="{{ galleryItem.pic }}" />
          </a>
        </div>
      </dx-gallery>
    </div>
  </div>
</mat-dialog-content>