import { filter } from "rxjs";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { environment } from "environments/environment";

@Component({
  selector: "app-shippingmask-modal",
  templateUrl: "./shippingmask-modal.component.html",
  styleUrls: ["./shippingmask-modal.component.css"],
})
export class ShippingmaskModalComponent implements OnInit {
  shippingMaskList: any = [];
  form: any = {};
  constructor(
    private appMasterService: AppMasterService,
    public dialogRef: MatDialogRef<ShippingmaskModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit() {
    this.shippingMaskList = await this.appMasterService.getShippingMask(null);
  }

  setShippingMaskList(e) {
    this.form.paht = this.shippingMaskList.find((f) => f.id == e.value).path;
    this.form.paht = environment.api + this.form.paht;
  }
}
