import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { userSearch } from 'app/business/interface/app/app-user.interface';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { debounceTime, map, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-ms24-print-user-modal',
  templateUrl: './ms24-print-user-modal.component.html',
  styleUrls: ['./ms24-print-user-modal.component.css']
})
export class Ms24PrintUserModalComponent implements OnInit {
  userList: any[] = [];
  botUserSearch = new FormControl();
  filteredOptions$: Observable<string[]>;
  isLoading: boolean = false;
  valueSelect: boolean = false;
  form: any = {};
  constructor(
    private dialogRef: MatDialogRef<Ms24PrintUserModalComponent>,
    private appUserService: AppUserService,
    private httpLoadingService: HttpLoadingService,
    private confirmDialog: ConfirmDialogService,
  ) { }

  ngOnInit() {
    this.loadData();
    this.valueChange();
  }

  async loadData() {
    var reqData = {
      cilentCode: '',
      userName: '',
      name: '',
      isActive: '',
      userRoleCode: '',
      userCode: '',
      userGroupCode: 'UG004',
    };

    await this.appUserService.getUserDropdownList(reqData).then(res => {
      if (res && res.length > 0) {
        this.userList = res?.filter(x => x.isActive === "Y");
      } else {
        this.userList = []
      }
    })
  }

  clearValue(): void {
    this.valueSelect = false;
    this.isLoading = false;
    this.botUserSearch.setValue('');
    this.form.user = null;
  }
  
  selectionBotUserChange(e) {
    console.log(e);
    this.botUserSearch.setValue(e.option.value.display);
    this.form.user = e.option.value;

    this.valueSelect = true;
  }

  savePrintUser() {
    this.confirmDialog.open({
      header: "",
      content: "คุณต้องการพิมพ์เอกสารด้วยผู้ใช้งาน " + this.form.user.display + " หรือไม่?"
    }).then(res => {
      if (res) {
        this.dialogRef.close(this.form.user);
      }
    })
  }

  displayBotUser(e): string {
    return e && e.name ? `${e.name}` : '';
  }

  valueChange() {
    this.filteredOptions$ = this.botUserSearch.valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isLoading = true;

        const key = value;
        var reqData = {
          cilentCode: '',
          userName: '',
          name: '',
          isActive: '',
          userRoleCode: '',
          userCode: '',
          userGroupCode: 'UG004',
          filterValue: key,
        };
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appUserService.getUserDropdownList(reqData);
          }
        } else {
          this.valueSelect = false;
          this.isLoading = false;
          return;
        }

      }),
      map((res: any[]) => {
        this.isLoading = false;
        this.httpLoadingService.enabled = true;


        let userData = res?.filter(x => x.isActive === "Y");
        return userData?.map(item => {
          const { userName, staffName } = (item as userSearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${userName} ${staffName}`
          };
        });
      })
    );
  }

}
