import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppMs13Service } from 'app/business/service/app/app-ms13.service';
import { AppMs24Service } from 'app/business/service/app/app-ms24.service';
import { AppO2Service } from 'app/business/service/app/app-o2.service';
import { AuthService } from 'app/core/auth/auth.service';
import moment from 'moment';
import { ImageModalComponent } from '../../ms13/Image-modal/Image-modal.component';
import { RenewMs13InquiryComponent } from '../../ms13/renew-ms13-inquiry/renew-ms13-inquiry.component';
import { Ms24ViewerComponent } from '../ms24-viewer/ms24-viewer.component';

@Component({
  selector: 'app-renew-ms24-inquiry',
  templateUrl: './renew-ms24-inquiry.component.html',
  styleUrls: ['./renew-ms24-inquiry.component.css']
})
export class RenewMs24InquiryComponent implements OnInit {
  form: any = {};
  formAttachment: any = {};
  fileImg: any;
  file: any;
  value: any[] = [];
  typePage: any;
  typePageName: any;
  id: any;
  dataSource: any = [];
  uploadPass: boolean = false;
  disabled: boolean = false;
  buttonList: any[] = [];
  url: any;
  isStaff: boolean = false;
  isDetail: boolean = false;
  formFile: any = {};

  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  constructor(
    private dialogRef: MatDialogRef<RenewMs13InquiryComponent>,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private appMaster: AppMasterService,
    private appMs13Service: AppMs13Service,
    private appO2Service: AppO2Service,
    private alertService: AlertMessageService,
    private appMs24Service: AppMs24Service,
    private confirmService: ConfirmDialogService,
    private _authService: AuthService,
    private appMenuService: AppMenuService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.form = data.item;
    this.isStaff = data.isStaff;
    this.isDetail = data.isDetail;
  }

  async ngOnInit() {
    await this.getRouter();
    this.id = this.form.id;
    console.log("int ", this.id);

    this.appMs13Service.mS24Attachment.subscribe(res => {
      this.dataSource = res;
    });

    this.getRenew();
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: null,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };

    await this.appMenuService.getButtonPermissionPerPages(formData)
      .then((bb) => {
        this.buttonList = bb
      });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);
    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  getRenew() {
    this.appMs24Service.getrenewMS24({ ID: this.id }).then((res) => {
      this.form = res;
      this.form.expireDateMax = new Date();
      var maxDay = moment(new Date(this.form.expireDate))
        .add(30, "days")
        .format("YYYY-MM-DD");
      this.form.expireDateMax = new Date(maxDay);

      var newExpDate = moment(new Date(this.form.expireDate))
        .add(9, "days")
        .format("YYYY-MM-DD");
      this.form.newExpireDate = newExpDate;

      this.form.docNo = this.form.docNo ?? "-";
    });

    const formDataMS24 = {
      ID: this.id,
      docNo: this.form.docNo,
      companyTax: this.userProfile.companyCode,
      refNo: this.form.refNo,
      productId: this.form.product,
    };

    this.appMs13Service.getDataMS24Attachment(formDataMS24);
  }

  async onFileChangedBr(event): Promise<void> {
    if (event.value.length === 0) {
      return;
    }
    if (this.formAttachment.fileName === undefined || this.formAttachment.fileName === null || this.formAttachment.fileName === '') {
      this.alertService.open({
        message: "กรุณากรอกชื่อเอกสารก่อนอัปโหลด",
        title: ""
      });
      this.value = [];
      return;
    }
    event.value.forEach((item) => {
      this.file = item;
      this.onUploadBr();
    });
    this.value = [];
  }

  async sentRenew(status) {
    if (this.dataSource > 0) {
      this.alertService.open({
        message: "กรุณาอัปโหลดเอกสารประกอบคำร้องขอต่ออายุ มส.24 อย่างน้อย 1 รายการ",
        title: ""
      });
      return;
    } else {
      var rqRenewMs24 = {
        id: this.id,
        refNo: this.form.docNo,
        expireDate: this.form.expireDate,
        status: status,
        newExpireDate: null
      }

      if (status === 9) {
        if ((this.form.newExpireDate instanceof Date)) {
          var newDate = moment(this.form.newExpireDate);

          var adjustedDate = newDate.toISOString(true);

          this.form.newExpireDate = adjustedDate
        }

        rqRenewMs24.newExpireDate = this.form.newExpireDate;
      }

      this.appMs24Service.renewMS24sent(rqRenewMs24).then((res) => {
        if (res != "error") {
          var message = this.isStaff ? 'บันทึกผลอนุมัติการขอต่ออายุเอกสารแล้ว' : "คุณได้ดำเนินการขอต่ออายุ มส.24 ดังกล่าวแล้ว ขณะนี้ทางเจ้าหน้าที่กำลังดำเนินการ"
          this.alertService.info({
            title: "",
            message: message,
          }).then((x) => {
            if (!this.isStaff) {
              this.router.navigate(["page/ms24/list-renew-ms24"], {});
            }
            this.dialogRef.close(true);
          });
        }
      });
    }
  }

  onUploadBr(): void {
    const formData = new FormData();
    formData.append("image", this.file, this.file.name);
    formData.append("blobType", "CSCAttachments");
    formData.append("typePage", this.typePage);
    formData.append("action", "new");
    formData.append("linkDoc", this.id ?? 0);
    this.appMaster.uploadFile(formData).subscribe((result) => {
      console.log("uploadFile", result);
      if (result != null) {
        this.formFile = result[0];
        this.fileImg = result[0]?.filePath;
        this.form.pic = result[0]?.fileName + "." + result[0]?.fileType;
        this.formFile.appType = result[0]?.fileType;
        this.formFile.fileExtension = this.file.type;
        const attachment = {
          cmms24aFileName: this.formAttachment.fileName,
          cmms24aDocumentName: this.form.docNo,
          linkDoc: 1,
          documentContent: "image",
          cmms24aDocumentSize: this.formFile.fileByte,
          cmms24aFileExtension: this.formFile.fileExtension,
          cmms24aDocumentByte: this.formFile.fileByte,
          cmms24aPathFile: result[0]?.filePath,
          cmms24aIsActive: "Y",
          cmms24hId: this.id
        };

        //SAVE Ms24 ATTACHMENT

        this.appMaster.saveMs24Attachment(attachment).then(res => {
          console.log("API", res);
          const formDataMs13 = {
            ID: this.id,
            docNo: this.form.docNo,
            companyTax: this.userProfile.companyCode,
            refNo: this.form.refNo,
            productId: this.form.product,
          };
          this.appMs13Service.getDataMS24Attachment(formDataMs13);
        });
      }
    });
  }

  showImg(file) {
    const dialogRef = this.dialog.open(ImageModalComponent, {
      disableClose: true,
      // maxWidth: "none",
      width: "45vw",
      height: "35vw",
      data: {
        path: file.cmms24aPathFile,
        title: "เอกสารประกอบการพิจารณา",
      },
    });
  }

  async deleteImg(e) {
    const alertConfirm = await this.confirmService.open({
      header: "",
      content: "ต้องการลบเอกสารหรือไม่",
    });
    // ;
    if (alertConfirm) {
      e.cmms24aIsActive = 'N';
      this.appMaster.saveMs24Attachment(e).then(res => {
        console.log("API", res);
        const formDataMs13 = {
          ID: this.id,
          docNo: this.form.docNo,
          companyTax: this.userProfile.companyCode,
          refNo: this.form.refNo,
          productId: this.form.product,
        };
        this.appMs13Service.getDataMS24Attachment(formDataMs13);
      });
    }
  }

  openMs24Copy() {
    this.dialog.open(Ms24ViewerComponent, {
      disableClose: true,
      data: {
        id: this.id,
      },
      width: '85vw',
      height: '85vh'
    });
  }
}
