<mat-card>
   <mat-card-header  class="fontTitleHeader" >
        <div class="flex justify-between" style="width: 100%;">
            <div  class="fontTitleModal" >กรุณาระบุเลขที่บัญชีที่สมัคร e-Payment</div>
            <div>
                <button mat-icon-button class="close-button" [mat-dialog-close]="true">
                    <i class="ti ti-x text-white"></i>
                  </button>
            </div>
        </div>
    </mat-card-header>
    <mat-dialog-content>
        <div class="flex flex-col py-5 gap-4 ">

            <div class="pl-22 pr-22 flex flex-col gap-4" name="FormCheckInputbank" [formGroup]="FormCheckInputbank">
                <div class="flex-row flex justify-between gap-2.5">
                    <div class="mat-popup-header flex flex-col">
                        <h4>เลขประจำตัวผู้เสียภาษี</h4>
                        <h4>Tax No.</h4>
                    </div>
                    <div class="mat-popup flex flex-row justify-between items-center">
                        <div>:</div>
                        <mat-form-field style="flex: 0 80%;" class="flex">
                            <input autocomplete="off" type="text" maxlength="13" matInput
                                placeholder="เลขประจำตัวผู้เสียภาษี" matInput #input [(ngModel)]="form.accTaxNo"
                                name="accTaxNo" oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                formControlName="accTaxNo">
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex-row flex justify-between gap-2.5">
                    <div class="mat-popup-header flex flex-col">
                        <h4>ธนาคาร</h4>
                        <h4>Bank.</h4>
                    </div>
                    <div class="mat-popup flex flex-row justify-between items-center">
                        <div>:</div>
                        <mat-form-field style="flex: 0 80%;" class="flex">
                            <mat-select [(ngModel)]="form.bankCode" name="bankCode" formControlName="bankCode">
                                <mat-option *ngFor="let bank of bankList" [value]="bank.bankCode">
                                    {{bank.bankCode}} : {{bank.bankName}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex-row flex justify-between gap-2.5">
                    <div class="mat-popup-header flex flex-col">
                        <h4>เลขที่บัญชี</h4>
                        <h4>Account No.</h4>
                    </div>
                    <div class="mat-popup flex flex-row justify-between items-center">
                        <div>:</div>
                        <mat-form-field style="flex: 0 80%;" class="flex">
                            <input autocomplete="off" type="text" maxlength="10" maxlength="14" matInput
                                placeholder="เลขที่บัญชี" matInput #input [(ngModel)]="form.bankAccountNo"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');" name="bankAccountNo"
                                formControlName="bankAccountNo">
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex-row flex justify-between gap-2.5">
                    <div class="mat-popup-header flex flex-col">
                        <h4>ชื่อบัญชี</h4>
                        <h4>Account Name.</h4>
                    </div>
                    <div class="mat-popup flex flex-row justify-between items-center">
                        <div>:</div>
                        <mat-form-field style="flex: 0 80%;" class="flex">
                            <input autocomplete="off" type="text" matInput placeholder="ชื่อบัญชี" matInput #input
                                [(ngModel)]="form.bankAccountName" name="bankAccountName"
                                formControlName="bankAccountName">
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex-row flex justify-between gap-2.5">
                    <div class="mat-popup-header flex flex-col">
                        <h4>รหัสสาขา</h4>
                        <h4>Branch Code.</h4>
                    </div>
                    <div class="mat-popup flex flex-row justify-between items-center">
                        <div>:</div>
                        <mat-form-field style="flex: 0 80%;" class="flex">
                            <input autocomplete="off" type="text" matInput placeholder="รหัสสาขา" matInput #input
                                [(ngModel)]="form.bankBranch" name="bankBranch" maxlength="4"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');" formControlName="bankBranch">
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex-row flex justify-between gap-2.5">
                    <div class="mat-popup-header flex flex-col">
                        <h4>Account Type</h4>
                        <h4>Account Type</h4>
                    </div>
                    <div class="mat-popup flex flex-row justify-between items-center">
                        <div>:</div>
                        <mat-form-field style="flex: 0 80%;" class="flex">
                            <mat-select [(ngModel)]="form.accType" name="accType" formControlName="accType">
                                <mat-option *ngFor="let item of accountList" [value]="item.typeCode">
                                    {{item.typeName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="flex-row flex justify-between gap-2.5">
                    <div class="mat-popup-header flex flex-col">
                        <h4>ใช้งาน</h4>
                        <h4>Active.</h4>
                    </div>
                    <div class="mat-popup flex flex-row justify-between items-center">
                        <div>:</div>
                        <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false" (change)="onChange($event)"
                            style="flex: 0 80%;" class="flex">
                            Active</mat-slide-toggle>
                    </div>
                </div>
                <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
                    <button mat-raised-button color="accent" (click)="save()"
                        [disabled]="FormCheckInputbank.pristine || FormCheckInputbank.invalid"
                        [mat-dialog-close]>บันทึก</button>
                </div>
            </div>
        </div>
    </mat-dialog-content>