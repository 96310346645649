import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FormHelperService } from '@bundle/service/form-helper.service';
import { AppUserService } from 'app/business/service/app/app-user.service';

@Component({
  selector: 'app-user-role-form',
  templateUrl: './user-role-form.component.html',
  styleUrls: ['./user-role-form.component.scss']
})
export class UserRoleFormComponent implements OnInit {
  userRoleForm: FormGroup;

  @Output() userRoleFormEmit: EventEmitter<FormGroup> = new EventEmitter();
  @Input() dataInput: any;
  constructor(
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.userRoleFormInit();
  }
  userRoleFormInit(): void {
    this.userRoleForm = this.formBuilder.group({
      roleName: [this.dataInput?.roleName, [Validators.required]],
      userRoleCode: [this.dataInput?.userRoleCode],
      isActive: [this.dataInput?.isActive, [Validators.required]],
    });
    this.userRoleFormEmit.emit(this.userRoleForm);
  }
  userRoleFormReset(): void {
    this.userRoleFormInit();
  }
}
