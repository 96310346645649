<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <!-- <app-head-o2 style="width: 100%;" [type]="'ms13'"></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
      </div>
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">
          <!-- <h4>records</h4> -->
          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="เลขที่คำร้อง / เลขที่ใบอนุญาต อ.2" matInput
              #input [(ngModel)]="searchString" name="searchText" #search="ngModel"
              (keyup)="applyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu" (menuClosed)="menuClosed()">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex-row">
                <div class="mat-button">
                  <mat-form-field class="my-form-field formm" fxFlex="50">
                    <mat-label>วันที่ยื่นคำร้อง</mat-label>
                    <input required autocomplete="off" placeholder="วันที่ยื่นคำร้อง" matInput
                      [matDatepicker]="StartDate" [(ngModel)]="searchForm.refDate" name="refDate" #refDate="ngModel"
                      readonly>
                    <mat-datepicker-toggle matSuffix [for]="StartDate"></mat-datepicker-toggle>
                    <mat-datepicker #StartDate></mat-datepicker>
                    <mat-error>กรุณาระบุ วันที่ยื่นคำร้อง</mat-error>
                  </mat-form-field>
                  <div [formGroup]="ms13Form" fxFlex="50">
                    <mat-form-field style="width: 100%;" class="my-form-field formm">
                      <mat-label>ผู้ตรวจ</mat-label>
                      <input type="text" matInput formControlName="userSearch" [matAutocomplete]="autoas">
                      <mat-autocomplete #autoas="matAutocomplete" (optionSelected)="selectionUserChange($event)">
                        <mat-option *ngFor="let option of filteredOptionsUser$" [value]="option">
                          {{option.fullName}}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่คำร้อง</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.refNo" name="refNo" #refNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่ใบอนุญาต อ.2</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.o2DocNo" name="o2DocNo" #o2DocNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทสินค้า</mat-label>
                    <mat-select [(ngModel)]="searchForm.product" name="product" #product="ngModel" ngmodel>
                      <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>ประเภทสินค้า</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>สถานะ</mat-label>
                    <mat-select [(ngModel)]="searchForm.status" name="status" #status="ngModel" ngmodel>
                      <mat-option *ngFor="let status of statusList" [value]="status.statusId">
                        {{status.statusName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>สถานะ</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div>
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" (click)="searchOption()" style="flex:0 50%;">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="refNo" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-center text-center">
          เลขที่คำร้อง
        </th>
        <td mat-cell *matCellDef="let element" class="justify-center text-center">
          {{ element.fullRefNo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="refDate" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-center text-center">
          วันเวลาที่ยื่นคำร้อง
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.refDate | date: 'yyyy-MM-dd' }} {{ element.refDate | date: 'HH:mm' }}

        </td>
      </ng-container>
      <ng-container matColumnDef="companyName" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-center text-center">
          ผู้ส่งออก
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.companyName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="o2DocNo" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-center text-center">
          เลขที่ใบอนุญาต อ.2
        </th>
        <td mat-cell *matCellDef="let element" class="justify-center text-center">
          {{ element.o2DocNo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="o2IssueDate" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-center text-center">
          วันที่ออกใบอนุญาต อ.2
        </th>
        <td mat-cell *matCellDef="let element" class="justify-center text-center">
          {{ element.o2IssueDate | date: 'yyyy-MM-dd' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="o2ExpireDate" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-center text-center">
          วันที่หมดอายุ
        </th>
        <td mat-cell *matCellDef="let element" class="justify-center text-center">
          {{ element.o2ExpireDate | date: 'yyyy-MM-dd' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="productType" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-center text-center">
          ประเภทสินค้า
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.productType }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-center text-center">
          สถานะ
        </th>
        <td mat-cell *matCellDef="let element" class="justify-center text-center">
          {{ element.status }}
        </td>
      </ng-container>
      <ng-container matColumnDef="reviewBy" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          ผู้ตรวจ
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.reviewBy }}
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element;let i = index" style="text-align: center;">
          <button mat-icon-button matTooltip="รายละเอียด" routerLink="/page/o2/create-ms13"
            [queryParams]="{ page: 'ms13',  type : 'Detail', refNo : element.refNo, id:element.id , docNo :element.o2DocNo , status : element.statusId , isStaff : 'Y' }">
            <i class="ti ti-file-description"></i>
          </button>

          <button mat-icon-button matTooltip="ประวัติการแก้ไข" (click)="history(element)" matTooltipPosition="above">
            <i class="ti ti-history"></i>
          </button>

          <button mat-icon-button matTooltip="Export มส.13" (click)="exportMs13(element)">
            <i class="ti ti-printer"></i>
          </button>

          <button mat-icon-button matTooltip="ล็อคการพิมพ์" (click)="lockPrint(element)" matTooltipPosition="above">
            <i class="ti ti-lock"></i>
          </button>

          <button mat-icon-button matTooltip="ปลดล็อคการพิมพ์" (click)="unlockPrint(element)"
            matTooltipPosition="above">
            <i class="ti ti-lock-open"></i>
          </button>

          <!-- BOT TAKE งาน -->
          <button mat-icon-button matTooltip="กดรับงาน" (click)="acceptJobs(element, 3)" matTooltipPosition="above" *ngIf="element.statusId === 2">
            <i class="ti ti-direction-sign"></i>
          </button>

          <button mat-icon-button matTooltip="ส่งข้อมูลไปยังกรมการค้าต่างประเทศ" (click)="sendDft(element)"
            matTooltipPosition="above">
            <i class="ti ti-message-forward"></i>
          </button>

          <button mat-icon-button matTooltip="รอการตรวจ DNA จากกรมการค้า" matTooltipPosition="above">
            <i class="ti ti-dna"></i>
          </button>

          <!-- SURVEYOR TAKE งาน -->
          <button mat-icon-button matTooltip="กดรับงาน" (click)="surveyorJobs(element, 8)" matTooltipPosition="above" *ngIf="element.statusId === 7">
            <i class="ti ti-direction-sign"></i>
          </button>

          <!-- SURVEYOR DONE งาน -->
          <button mat-icon-button matTooltip="สิ้นสุดงาน" (click)="surveyorJobs(element, 5)" matTooltipPosition="above" *ngIf="element.statusId === 8">
            <i class="ti ti-zoom-check"></i>
          </button>
          <!-- <button mat-icon-button matTooltip="" matTooltip="สิ้นสุดงาน"
            *ngIf="element.statusId == 7 && userProfile.userTypeId == 4" (click)="updateBotReceive(element)"
            matTooltipPosition="above">
            <mat-icon>forward</mat-icon>
          </button> -->

        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements" (page)="pageChanged($event)">
    </mat-paginator>
  </div>
</div>