<!-- Notifications toggle -->
<button mat-icon-button [matTooltip]="'การแจ้งเตือน'" (click)="openPanel()" #notificationsOrigin style="height: 40px !important">
    <ng-container *ngIf="unreadCount > 0">
        <span class="absolute top-0 right-0 left-0 flex items-center justify-center h-3">
            <span class="flex items-center justify-center shrink-0 min-w-4 h-4 px-1 ml-4 mt-2.5 rounded-full bg-red-700 text-indigo-50 text-xs font-medium">
                {{unreadCount}}
            </span>
        </span>
    </ng-container>
    <mat-icon style="color: #fff;" [svgIcon]="'heroicons_outline:bell'"></mat-icon>
</button>

<!-- Notifications panel -->
<ng-template #notificationsPanel>

    <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-90 sm:w-90 sm:rounded-2xl overflow-hidden shadow-lg">

        <!-- Header -->
        <div class="flex shrink-0 items-center py-4 pr-4 pl-6 text-on-primary" style="background-color: #4297D7;">
            <div class="sm:hidden -ml-1 mr-3">
                <button
                    mat-icon-button
                    (click)="closePanel()">
                    <mat-icon
                        class="icon-size-5 text-current"
                        [svgIcon]="'heroicons_solid:x'"></mat-icon>
                </button>
            </div>
            <div class="text-lg font-medium leading-10">การแจ้งเตือน</div>
            <div class="ml-auto">
                <button
                    mat-icon-button
                    [matTooltip]="'ทำเครื่องหมายอ่านแล้วทั้งหมด'"
                    [disabled]="unreadCount === 0"
                    (click)="markAllAsRead(notifyList)" style="height: 40px !important">
                    <i class="ti ti-mail-opened icon-size-5"></i>
                </button>
            </div>
        </div>

        <!-- Content -->
        <div class="relative flex flex-col flex-auto sm:max-h-120 divide-y overflow-y-auto bg-card">
            <!-- Notifications -->
            <ng-container *ngFor="let notification of notifyList">
                <div [class]="getNotificationClass(notification.notificationReadStatus)">

                    <!-- Notification with a link -->
                    <ng-container *ngIf="notification.notificationLink">
                        <!-- Normal links -->
                        <ng-container>
                            <a
                                class="flex flex-auto py-5 pl-6 cursor-pointer"
                                (click)="redirectToLink(notification)">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </a>
                        </ng-container>
                    </ng-container>

                    <!-- Notification with a reflink -->
                    <!-- <ng-container *ngIf="notification.linkRef && !notification.notificationLink"> -->
                        <!-- Normal links -->
                        <!-- <ng-container>
                            <a
                                class="flex flex-auto py-5 pl-6 cursor-pointer"
                                (click)="redirectToRefLink(notification)">
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </a>
                        </ng-container>
                    </ng-container> -->

                    <!-- Notification without a link -->
                    <ng-container *ngIf="!notification.notificationLink">
                            <a
                                class="flex flex-auto py-5 pl-6 cursor-pointer"
                                (click)="showNotificationDetail(notification)"
                                >
                                <ng-container *ngTemplateOutlet="notificationContent"></ng-container>
                            </a>
                    </ng-container>

                    <!-- Actions -->
                    <div class="relative flex flex-col my-5 mr-6 ml-2">
                        <!-- Indicator -->
                        <button
                            class="w-6 h-6 min-h-6"
                            mat-icon-button
                            (click)="toggleRead(notification)"
                            [disabled]="notification.notificationReadStatus !== 'W'"
                            [matTooltip]="notification.notificationReadStatus === 'R' ? 'ทำเครื่องหมายยังไม่ได้อ่าน' : 'ทำเครื่องหมายอ่านแล้ว'">
                            <span
                                class="w-2 h-2 rounded-full"
                                [ngClass]="{'bg-gray-400 dark:bg-gray-500 sm:opacity-0 sm:group-hover:opacity-100': notification.notificationReadStatus === 'R',
                                            'bg-primary-tcc': notification.notificationReadStatus === 'W'}"></span>
                        </button>
                        <!-- Remove -->
                        <button
                            class="w-6 h-6 min-h-6 sm:opacity-0 sm:group-hover:opacity-100"
                            mat-icon-button
                            (click)="delete(notification)"
                            [matTooltip]="'ลบ'">
                            <mat-icon
                                class="icon-size-4"
                                [svgIcon]="'heroicons_solid:x'"></mat-icon>
                        </button>
                    </div>

                </div>

                <!-- Notification content template -->
                <ng-template #notificationContent>
                    <!-- Icon -->
                    <ng-container *ngIf="notification.isExpireAlert == 'N'">
                        <div class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                            <i class="ti ti-mail-exclamation icon-size-5"></i>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="notification.isExpireAlert == 'Y'">
                        <div class="flex shrink-0 items-center justify-center w-8 h-8 mr-4 rounded-full bg-gray-100 dark:bg-gray-700">
                            <i class="ti ti-file-alert icon-size-5"></i>
                        </div>
                    </ng-container>
                    <!-- Image -->
                    <!-- <ng-container *ngIf="notification.notificationImagePath">
                        <img
                            class="shrink-0 w-8 h-8 mr-4 rounded-full overflow-hidden object-cover object-center"
                            [src]="notification.notificationImagePath">
                    </ng-container> -->
                    <!-- Title, description & time -->
                    <div class="flex flex-col flex-auto">
                        <ng-container *ngIf="notification.notificationTopicTh">
                            <div
                                class="font-semibold line-clamp-1"
                                [innerHTML]="notification.notificationTopicTh"></div>
                        </ng-container>
                        <ng-container *ngIf="notification.notificationDescTh">
                            <div
                                class="line-clamp-2"
                                [innerHTML]="notification.notificationDescTh"></div>
                        </ng-container>
                        <div class="mt-2 text-sm leading-none text-secondary">
                            {{convertDate(notification?.notificationDate)}} {{notification.notificationDate | date : "HH:mm"}}
                        </div>
                    </div>
                </ng-template>
            </ng-container>

            <!-- No notifications -->
            <ng-container *ngIf="!notifyList || !notifyList.length">
                <div class="flex flex-col flex-auto items-center justify-center sm:justify-start py-12 px-8">
                    <div class="flex flex-0 items-center justify-center w-14 h-14 rounded-full" style="background-color: #e3f3ff">
                        <mat-icon [svgIcon]="'heroicons_outline:bell'" style="color: #4297D7"></mat-icon>
                    </div>
                    <div class="mt-5 text-2xl font-semibold tracking-tight">ไม่มีการแจ้งเตือนใหม่</div>
                    <div class="w-full max-w-60 mt-1 text-md text-center text-secondary">หากมีการแจ้งเตือนใหม่ ข้อความจะปรากฎที่นี่</div>
                </div>
            </ng-container>

        </div>

    </div>

</ng-template>
