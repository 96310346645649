<div style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
  background-color: #4297d6;border-radius: 6px 6px 0 0; padding-top:15px; padding-bottom: 15px;margin-bottom: 20px;" class=" pl-5 pr-5" *ngIf="data.title === undefined && data.title === null && data.title !== ''">
  <h2 style="text-align: center;"> {{ data?.title }}</h2>
</div>
<div style="display: flex;flex-flow: row;gap: 30px;height: 5vh;justify-content: space-between;align-items: center;color: white;
  background-color: white;font-size: 20px;border-radius: 6px;" class=" pl-5 pr-5" *ngIf="data.title === undefined || data.title === null || data.title === ''">
  <!-- <h2 style="text-align: center;"> {{data?.title || 'แจ้งเตือน' }}</h2> -->
</div>
<div mat-dialog-content class="flex flex-col  justify-center items-center">
  <span align="center" style="text-align: left !important; margin-bottom: 0px; white-space: pre-wrap; font-size: 1.25em;">{{data.message}}</span>
</div>
<div mat-dialog-actions align="center">
  <button mat-raised-button class="primary" style="background-color: #003f80 !important;
  color: white!important; " (click)="closeDialog()" mat-button mat-dialog-close>ตกลง</button>
  <!-- <button mat-raised-button color="accent" mat-dialog-close cdkFocusInitial style="background-color: #003f80 !important;
 color: white!important; ">{{data.confirmText}}</button> -->
</div>
