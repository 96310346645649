import { Component, NgModule, OnInit } from "@angular/core";
import { IGetCompanyResponse } from "app/business/interface/app/app-company.interface";
import { AppCompanyService } from "app/business/service/app/app-company.service";

@Component({
    selector:"app-company-detail-inquiry",
    templateUrl:"./company-detail-inquiry.component.html",
    styleUrls:["./company-detail-inquiry.component.css"],
})

export class CompanyDetailInquiryComponent implements OnInit {
    
    previews: string;

    companyDetail : IGetCompanyResponse = { } as IGetCompanyResponse;

    constructor(
        private companyService: AppCompanyService
    ){}

     ngOnInit(): void {
        this.loadData();
     }

     async loadData(){
        const request = {
            // taxId : localStorage.getItem('taxId')
            // เอา taxid ไปหาข้อมูลแทนที่จะเป็น id 
            taxId : '0105534079141'
        }
        await this.companyService.GetCompany(request).then((res)=>{
            this.companyDetail = res
        });
     }
}