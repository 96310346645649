<mat-toolbar class="bgGray">
  <button mat-icon-button class="bglightgrey" [mat-dialog-close]="false">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <button mat-icon-button class="bglightgrey" (click)="reset()">
    <mat-icon>cached</mat-icon>
  </button>
  <button mat-icon-button class="bglightgrey" [mat-dialog-close]="true" (click)="save()">
    <mat-icon>save</mat-icon>
  </button>
</mat-toolbar>
<!-- การตรวจสอบมาตรฐานสินค้า -->
<div class="flex flex-col p10">
  <mat-card>
    <mat-card-header class="h-16"> </mat-card-header>
    <mat-card-content class="example-headers-align">
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="basis-1/5 self-center">ผลการตรวจสอบ :</div>
            <div class="basis-1/5">
              <mat-form-field class="w100">
                <input required autocomplete="off" type="text" matInput #input name="checkResult"
                  [(ngModel)]="surveyorResults.checkResult" #checkResult="ngModel" />
                <mat-error>กรุณาระบุ</mat-error>
              </mat-form-field>
            </div>
            <div class="basis-2/5 self-center pl-2.5">
              ( เช่น มาตรฐานเทียบได้ เท่ากับ มาตรฐานที่ทางราชการกำหนด )
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="basis-1/5 self-center">ผลการตรวจสอบปริมาณ :</div>
            <div class="basis-1/5">
              <mat-form-field class="w100">
                <input required autocomplete="off" type="text" matInput #input name="checkQty"
                  [(ngModel)]="surveyorResults.checkQty" #checkQty="ngModel" />
                <mat-error>กรุณาระบุ</mat-error>
              </mat-form-field>
            </div>
            <div class="basis-2/5 self-center pl-2.5">
              ( เช่น กระสอบที่บรรจุถูกต้อง )
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="basis-1/5 self-center">ผลการตรวจสอบหีบห่อ :</div>
            <div class="basis-1/5">
              <mat-form-field class="w100">
                <input required autocomplete="off" type="text" matInput #input name="checkPackageResult"
                  [(ngModel)]="surveyorResults.checkPackageResult" />
                <mat-error>กรุณาระบุ</mat-error>
              </mat-form-field>
            </div>
            <div class="basis-2/5 self-center pl-2.5">
              ( เช่น การเย็บปากกระสอบ เรียบร้อย )
            </div>
          </div>
        </div>
      </div>
      <div class="flex">
        <div class="flex-1 w-100">
          <div class="flex flex-row p10">
            <div class="basis-1/5" style="align-self: center">หมายเหตุ :</div>
            <div class="basis-1/5">
              <mat-form-field class="w100">
                <input autocomplete="off" type="text" matInput #input name="surveyRemark"
                  [(ngModel)]="surveyorResults.surveyRemark" />
                <mat-error>กรุณาระบุ</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
      <!-- ส่วนกรอกข้อมูลและรายละเอียดการตรวจสอบ -->
      <fieldset class="mt-8 border-2 border-solid rounded-lg">
        <legend class="ml-2.5 pr-2.5">
          ส่วนกรอกข้อมูลและรายละเอียดการตรวจสอบ
        </legend>
        <div class="flex flex-row">
          <div class="w-fit">
            <div class="p10">
              <div class="basis-1/2 f18" style="align-self: center">
                <button mat-raised-button (click)="addResult()">เพิ่มผลการตรวจสอบ</button>
              </div>
            </div>
          </div>
          <div class="w-fit">
            <div class="p10">
              <div class="basis-1/2 f18" style="align-self: center">
                <button mat-raised-button *ngIf="collapsed" (click)="expand()">
                  ซ่อนรายละเอียดสินค้า
                </button>
                <button mat-raised-button *ngIf="!collapsed" (click)="collapse()">
                  แสดงรายละเอียดสินค้า
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row pt-2.5">
          <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
            รายการสินค้าที่ตรวจ :
          </div>
          <div class="flex basis-1/3">
            <mat-form-field class="basis-2/4 ">
              <mat-select [(ngModel)]="surveyorResults.goodsSeq" (ngModelChange)="goodsChange($event)" name="goodsSeq"
                #goodsSeq="ngModel" ngmodel>
                <mat-option *ngFor="let item of productList" [value]="item.seq">
                  {{item.seq}} {{item.goodsDescription}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row pt-2.5">
          <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
            ประเภทหน่วยของจำนวนที่สั่งปล่อย :
          </div>
          <div class="flex basis-1/3">
            <mat-form-field class="basis-2/4">
              <mat-select name="exporter" disabled>
                <mat-option>อื่นๆ</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row pt-2.5">
          <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
            ปริมาณที่ตรวจปล่อย :
          </div>
          <div class="flex basis-1/3">
            <mat-form-field class="basis-2/4">
              <input required autocomplete="off" type="text" matInput #input
                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" name="surveyQty"
                [(ngModel)]="surveyorResults.surveyQty" />
            </mat-form-field>
            <mat-form-field class="basis-2/4 pl-1">
              <input readonly autocomplete="off" type="text" matInput name="surveyQtyUnit"
                [(ngModel)]="surveyorResults.surveyQtyUnit" />
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row pt-2.5">
          <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
            น้ำหนัก :
          </div>
          <div class="flex basis-1/3">
            <mat-form-field class="basis-2/4">
              <input required autocomplete="off" type="text" matInput #input name="surveyNetWeight"
                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" [(ngModel)]="surveyorResults.surveyNetWeight" />
            </mat-form-field>
            <!-- weightList -->
            <mat-form-field class="basis-2/4 pl-1">
              <mat-select [(ngModel)]="surveyorResults.surveyNetWeightUnit" name="surveyNetWeightUnit"
                #surveyNetWeightUnit="ngModel" ngmodel>
                <mat-option *ngFor="let items of weightList" [value]="items.code">
                  {{items.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row pt-2.5">
          <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
            น้ำหนักเฉพาะต่อหน่วย :
          </div>
          <div class="flex basis-1/3">
            <mat-form-field class="basis-2/4">
              <input required autocomplete="off" type="text" matInput #input name="surveyNetWeightPerUnit"
                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');"
                [(ngModel)]="surveyorResults.surveyNetWeightPerUnit" />
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row pt-2.5">
          <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
            น้ำหนักรวมต่อหน่วย :
          </div>
          <div class="flex basis-1/3">
            <mat-form-field class="basis-2/4">
              <input required autocomplete="off" type="text" matInput #input name="surveyor_gross_weight_per_unit"
                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');"
                [(ngModel)]="surveyorResults.surveyGrossWeightPerUnit" />
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-row pt-2.5">
          <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center"></div>
          <div class="flex basis-1/3">
            <section class="basis-3/4">
              <mat-checkbox>จำนวนที่เหลือไม่ต้องส่งออก</mat-checkbox>
            </section>
          </div>
        </div>

      </fieldset>
      <!-- รายละเอียดสินค้า -->
      <section class="collapsible" [@collapse]="collapsed">
        <fieldset class="mt-8 border-2 border-solid rounded-lg">
          <legend class="ml-2.5 pr-2.5">รายละเอียดสินค้า</legend>
          <div class="flex flex-row pl-2.5 pt-2.5">
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  Taniff Code :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.tariffCode }}
                </div>
              </div>
            </div>
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  ราคาต่อหน่วย (เงินบาท) :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.unitPrice }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row pl-2.5 pt-2.5">
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  Taniff Sequence :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.tariffSeq }}
                </div>
              </div>
            </div>
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  ปริมาณ :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.quantity }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row pl-2.5 pt-2.5">
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  Statistical Code :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.statisCode }}
                </div>
              </div>
            </div>
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  หน่วยของปริมาณ :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.quantityUnit }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row pl-2.5 pt-2.5">
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  น้ำหนักเฉพาะรวม :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.netWeight }}
                </div>
              </div>
            </div>
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  น้ำหนักเฉพาะต่อหน่วย :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.netWeightPerUnit }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row pl-2.5 pt-2.5">
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  หน่วยของน้ำหนัก :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.netWeightUnit }}
                </div>
              </div>
            </div>
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  น้ำหนักรวมต่อหน่วย :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.grossWeightPerUnit }}
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-row pl-2.5 pt-2.5 pb-5">
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  มูลค่า เอฟโอบี (เงินบาท) :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.fobValueBaht }}
                </div>
              </div>
            </div>
            <div class="basis-2/4">
              <div class="flex flex-row">
                <div class="basis-2/4" style="align-self: center">
                  น้ำหนักที่สามารถปล่อยได้ :
                </div>
                <div class="basis-2/4" style="align-self: center">
                  {{ this.local.netWeight }}
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </section>
      <!-- KPOT -->
      <fieldset class="mt-4 border-2 border-solid rounded-lg">
        <div class="flex flex-row pt-2.5">
          <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
            หมายเลข KPOT :
          </div>
          <div class="flex basis-1/3">
            <mat-form-field class="basis-2/4">
              <input required autocomplete="off" type="text" matInput #input name="kPot1"
                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" [(ngModel)]="surveyorResults.kPot1" />
            </mat-form-field>
            <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
              จำนวน :
            </div>
            <mat-form-field class="basis-2/4  pl-1">
              <input required autocomplete="off" type="text" matInput #input name="kPotQty1"
                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" [(ngModel)]="surveyorResults.kPotQty1" />
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-row pt-2.5">
          <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
            หมายเลข KPOT :
          </div>
          <div class="flex basis-1/3">
            <mat-form-field class="basis-2/4">
              <input required autocomplete="off" type="text" matInput #input name="kPot1"
                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" [(ngModel)]="surveyorResults.kPot2" />
            </mat-form-field>
            <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
              จำนวน :
            </div>
            <mat-form-field class="basis-2/4  pl-1">
              <input required autocomplete="off" type="text" matInput #input name="kPotQty1"
                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" [(ngModel)]="surveyorResults.kPotQty2" />
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-row pt-2.5 pb-2.5">
          <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
            หมายเลข KPOT :
          </div>
          <div class="flex basis-1/3">
            <mat-form-field class="basis-2/4">
              <input required autocomplete="off" type="text" matInput #input name="kPot1"
                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" [(ngModel)]="surveyorResults.kPot3" />
            </mat-form-field>
            <div class="flex basis-1/3 pr-2.5" style="justify-content: right; align-self: center">
              จำนวน :
            </div>
            <mat-form-field class="basis-2/4  pl-1">
              <input required autocomplete="off" type="text" matInput #input name="kPotQty1"
                onkeyup="this.value=this.value.replace(/[^0-9]/g,'');" [(ngModel)]="surveyorResults.kPotQty3" />
            </mat-form-field>
          </div>
        </div>
      </fieldset>

      <!-- ส่วนแสดงรายละเอียดผลการตรวจสอบที่ต้องการบันทึก -->
      <fieldset class="mt-8 border-2 border-solid rounded-lg">
        <legend class="ml-2.5 pr-2.5">
          ส่วนแสดงรายละเอียดผลการตรวจสอบที่ต้องการบันทึก
        </legend>
        <div class="flex">
          <div class="flex-1 p-2.5">
            <div class="flex flex-row pt-2.5 justify-center">
              <div class="mat-elevation-z8 w-[95%]">
                <dx-data-grid id="gridContainer" [dataSource]="datasource" [columnAutoWidth]="true" [showBorders]="true"
                  [allowColumnReordering]="true" class="justify-center">
                  <dxi-column alignment="center" caption="ลำดับรายการที่ตรวจ" dataField="seq"></dxi-column>
                  <dxi-column alignment="center" caption="ลำดับรายการสินค้า" dataField="itemNo"></dxi-column>
                  <dxi-column alignment="center" dataField="goodsDescription" caption="รายการสินค้า"></dxi-column>
                  <dxi-column alignment="center" dataField="surveyQty" caption="จำนวนที่ตรวจปล่อย"> </dxi-column>
                  <dxi-column alignment="center" dataField="surveyNetWeightPerUnit" caption="น้ำหนักสุทธิต่อรายการ"></dxi-column>
                  <dxi-column alignment="center" dataField="surveyNetWeight" caption="น้ำหนักสุทธิทั้งหมด"></dxi-column>
                  <dxi-column alignment="center" dataField="detail" caption="Action">
                  </dxi-column>
                </dx-data-grid>
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </mat-card-content>
  </mat-card>
</div>