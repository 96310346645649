export class RedirectDialogInputModel {
    header: string;
    content: string;
    confirmText?: string;
    cancelText?: string;
    showCancelBtn?: boolean = true;
    showLinkBtn?: boolean = true;
    disableClose?: boolean;
    redirectLink?: string;
}
