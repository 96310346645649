<div class="mat-elevation-z8" style="width: 100%;">
    <div fxLayout="column" fxLayoutGap="20px">
        <dx-data-grid id="gridContainer" [dataSource]="userGroupListDataSource" [remoteOperations]="false"
            [showBorders]="false" [allowColumnReordering]="true" [rowAlternationEnabled]="true"
            [allowColumnResizing]="true" [columnAutoWidth]="true" [noDataText]="'ไม่มีข้อมูล'"
            style="margin-top: 30px;">
            <dxo-paging [pageSize]="10"></dxo-paging>
            <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20]" [showInfo]="true">
            </dxo-pager>
            <dxo-scrolling [useNative]="true" mode="standard" [scrollByContent]="true" [scrollByThumb]="true"
                showScrollbar="onHover"></dxo-scrolling>
            <!-- <dxo-scrolling [useNative]="false" [scrollByContent]="true" [scrollByThumb]="true" showScrollbar="onHover">
</dxo-scrolling> -->

            <!-- 
<dxo-selection mode="multiple" [selectAllMode]="'allMode'" [showCheckBoxesMode]="'always'">
  
</dxo-selection> -->
            <dxo-pager *ngIf="userGroupListDataSource?.length > 0" [showPageSizeSelector]="true" [visible]="true"
                [allowedPageSizes]="false"></dxo-pager>

            <dxo-search-panel [visible]="false"></dxo-search-panel>
            <!-- <dxo-paging [pageSize]="10"></dxo-paging> -->
            <!-- <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[false]"></dxo-pager> -->
            <dxo-filter-row [visible]="true" [showAllText]="'ทั้งหมด'"></dxo-filter-row>
            <dxi-column alignment="center" caption="ลำดับ" cellTemplate="wFlow" [width]='60'></dxi-column>
            <div *dxTemplate="let row of 'wFlow'">
                {{ (row.rowIndex+1) +(dataGrid.instance.pageIndex() * dataGrid.instance.pageSize() ) }}
            </div>
            <dxi-column [filterOperations]="['contains']" dataField="userGroupCode" caption="รหัสกลุ่มผู้ใช้"
                dataType="string" [width]='300'>
            </dxi-column>
            <dxi-column [filterOperations]="['contains']" dataField="groupName" caption="ชื่อกลุ่มผู้ใช้"
                dataType="string">
            </dxi-column>
            <dxi-column alignment="center" dataField="isActive" caption="สถานะ" dataType="string" cellTemplate="status"
                [width]='150'>
                <dxo-lookup valueExpr="code" displayExpr="name" [dataSource]="lookupStatusCode">
                </dxo-lookup>
            </dxi-column>
            <dxi-column [filterOperations]="['contains']" cellTemplate="groupEditor" caption="ผู้บันทึก"
                [width]='200'></dxi-column>
            <dxi-column caption="แก้ไข" cellTemplate="edit" [width]='60'> </dxi-column>
            <div *dxTemplate="let row of 'groupEditor'">
                {{row.data.updateByName ? row.data.updateByName + " " + row.data.updateDate : row.data.createByName + "
                " +
                row.data.createDate }}
            </div>
            <div *dxTemplate="let row of 'edit'">
                <div *ngIf="checkButton('BT106')">
                    <button mat-icon-button (click)="editUserGroup(row.data)" matTooltip="แก้ไข"
                        matTooltipPosition="above">
                        <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                    </button>
                </div>
            </div>
            <div *dxTemplate="let row of 'status'">
                <div *ngIf="row.data?.isActive == 'Y'" fxLayout="row" fxLayoutAlign="center center">
                    ใช้งาน
                </div>
                <div *ngIf="row.data?.isActive == 'N'" fxLayout="row" fxLayoutAlign="center center">
                    ยกเลิก
                </div>
            </div>
        </dx-data-grid>
    </div>