<mat-card>
  <mat-card-header class="fontTitleHeader">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal"> {{notifyData.notificationTopicTh}} </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">
      <ng-container *ngIf="notifyData.notificationImagePath">
        <div class="flex gap-2.5" style="align-items: center; flex-direction: column; height: 400px;">
          <img [src]="notifyData.notificationImagePath" style="height: 100%; width: auto;">
        </div>
      </ng-container>

      <ng-container *ngIf="!notifyData.notificationImagePath">
        <div class="flex gap-2.5" style="align-items: center; flex-direction: column; height: 150px;">
          <div class="flex flex-0 items-center justify-center rounded-full" style="width: 150px; height: 150px; background-color: #e3f3ff">
            <i class="ti ti-file-alert" style="font-size: 6em; color: #4297D7"></i>
          </div>
        </div>
      </ng-container>

      <div class="flex gap-2.5" style="flex-direction: column;font-size: medium;color: black;">
        <div>{{notifyData.notificationDescTh}}</div>
      </div>

      <div class="flex gap-2.5" style="flex-direction: column;font-size: small;">
        <div *ngIf="timeAgo.hoursAgo < 24"><strong>{{convertDate(notifyData?.notificationDate)}} {{notifyData.notificationDate | date : "HH:mm"}} <span *ngIf="timeAgo.hoursAgo == 0">({{timeAgo.minutesAgo}} นาทีก่อน)</span><span *ngIf="timeAgo.hoursAgo >= 1">({{timeAgo.hoursAgo}} ชั่วโมงก่อน)</span></strong></div>
        <div *ngIf="timeAgo.hoursAgo > 24"><strong>{{convertDate(notifyData?.notificationDate)}} {{notifyData.notificationDate | date : "HH:mm"}}</strong></div>
      </div>

      <ng-container *ngIf="notifyData.linkRef != undefined && notifyData.linkRef != null">
        <div class="flex gap-2.5" style="flex-direction: column;">
          <button mat-raised-button color="accent" (click)="redirectToRefLink(notifyData)">
            <div class="flex" style="gap: 6px;">
              <i class="ti ti-file-search" style="font-size: 1.5em;"></i>
              <span style="align-self: center;">ดูเอกสาร</span>
            </div>
          </button>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>