<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <!-- <app-head-o2 style="width: 100%;" [type]="'ms13'"></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
        <!-- Mockup TypeUser Surveyor  -->
        <button mat-raised-button style="width: 70px;font-size: 1.5em;" matTooltip="รีเฟรชข้อมูล" matTooltipPosition="above" (click)="onRefresh()">
          <i class="ti ti-refresh"></i>
        </button>
      </div>
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">
          <!-- <h4>records</h4> -->
          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput
              placeholder="เลขที่ใบรับรอง (มส.24) / เลขที่คำร้อง (มส.13) / เลขที่ใบอนุญาต (อ.2)" matInput #input
              [(ngModel)]="searchString" name="searchText" #search="ngModel" (keyup)="applyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu" (menuClosed)="menuClosed()">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>วันหมดอายุ</mat-label>
                    <input autocomplete="off" placeholder="วันหมดอายุ" matInput [matDatepicker]="exDate"
                      [(ngModel)]="searchForm.expireDate" name="expireDate" #expireDate="ngModel" readonly>
                    <mat-datepicker-toggle matSuffix [for]="exDate"></mat-datepicker-toggle>
                    <mat-datepicker #exDate></mat-datepicker>
                    <mat-error>กรุณาระบุ วันหมดอายุ</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทเรือ</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.vesselName" name="vesselName" #vesselName="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ใบรับรองเลขที่ (มส.24)</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.docNo" name="docNo" #docNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่คำร้อง (มส.13)</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.ms13No" name="ms13No" #ms13No="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่ใบอนุญาตกระทรวงพาณิชย์ (อ.2)</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.o2No" name="o2No" #o2No="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทสินค้า</mat-label>
                    <mat-select [(ngModel)]="searchForm.productId" name="productId" #productId="ngModel" ngmodel>
                      <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>ประเภทสินค้า</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div class="flex flex-row">
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" (click)="searchOption()" style="flex:0 50%;">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="companyName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ผู้ส่งออก</th>
        <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.companyName}} </td>
      </ng-container>

      <ng-container matColumnDef="ms13No">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">เลขที่คำร้อง (มส.13)</th>
        <td mat-cell *matCellDef="let element"> {{element.ms13No}} </td>
      </ng-container>

      <ng-container matColumnDef="o2No">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">เลขที่ใบอนุญาต (อ.2)</th>
        <td mat-cell *matCellDef="let element"> {{ element?.o2No != null ? 'พณ ' + element?.o2No : "-" }} </td>
      </ng-container>

      <ng-container matColumnDef="docNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">เลขที่ใบรับรอง (มส.24)</th>
        <td mat-cell *matCellDef="let element"> {{ element.refNo }} </td>
      </ng-container>

      <ng-container matColumnDef="productId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ประเภทสินค้า</th>
        <td mat-cell *matCellDef="let element"> {{ element.productName }} </td>
      </ng-container>

      <ng-container matColumnDef="expireDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">วันหมดอายุ</th>
        <td mat-cell *matCellDef="let element"> {{(element.expireDate | date:'yyyy-MM-dd') || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="vesselName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">เรือที่จะบรรทุก</th>
        <td mat-cell *matCellDef="let element"> {{element.vesselName}} </td>
      </ng-container>

      <ng-container matColumnDef="statusCode">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">สถานะ</th>
        <td mat-cell *matCellDef="let element"> {{element.statusCode}} </td>
      </ng-container>

      <ng-container matColumnDef="statusPrint">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">สถานะการพิมพ์เอกสาร</th>
        <td mat-cell *matCellDef="let element"> {{element.printCount > 0 ? "พิมพ์เอกสารแล้ว" : "ยังไม่พิมพ์เอกสาร"}}
        </td>
      </ng-container>

      <ng-container matColumnDef="userPrint">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;"> ผู้พิมพ์เอกสาร</th>
        <td mat-cell *matCellDef="let element"> {{element.printUserName || "-"}} </td>
      </ng-container>

      <ng-container matColumnDef="branchPrint">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">สาขาพิมพ์เอกสาร</th>
        <td mat-cell *matCellDef="let element"> {{element.statusCode}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element;let i = index">
          <button mat-icon-button matTooltip="รายละเอียด" *ngIf="checkButton('BT135')"
            (click)="viewMs24Document(element)">
            <i class="ti ti-file-description"></i>
          </button>

          <button mat-icon-button matTooltip="Export มส.24" *ngIf="element.printLock != 'Y' && checkButton('BT136')" (click)="exportMs24(element)">
          <!-- <button mat-icon-button matTooltip="Export มส.24" (click)="exportMs24(element)"> -->
            <i class="ti ti-printer"></i>
          </button>

          <button mat-icon-button matTooltip="ล็อคการพิมพ์" *ngIf="element.printLock != 'Y' && checkButton('BT137')"
            (click)="lockPrint(element)">
            <i class="ti ti-lock"></i>
          </button>

          <button mat-icon-button matTooltip="ปลดล็อคการพิมพ์" *ngIf="element.printLock == 'Y' && checkButton('BT138')"
            (click)="unlockPrint(element)">
            <i class="ti ti-lock-open"></i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements" (page)="pageChanged($event)">
    </mat-paginator>
  </div>
</div>