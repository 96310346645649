import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class ApiSurveyorService {

  constructor(private apiService: ApiService) { }

  getSurveyorEmp(any): Observable<any[]> {
    return this.apiService.post("/api/getSurveyorEmp", any);
  }

  listMs13Sur(any): Observable<any[]> {
    return this.apiService.post("/api/listMs13Sur", any);
  }

  getDataMs13Sur(any): Observable<any[]> {
    return this.apiService.post("/api/getDataMs13Sur", any);
  }

  saveDataMs13Sur(any): Observable<any[]> {
    return this.apiService.post("/api/saveDataMs13Sur", any);
  }
  
  getSurveyorSearchList(any): Observable<any[]> {
    return this.apiService.post("/api/getSurveyorSearchList", any);
  }

  getDataSurveyorSearch(any): Observable<any[]> {
    return this.apiService.post("/api/getDataSurveyorSearch", any);
  }

  saveDataSurveyorSearch(any): Observable<any[]> {
    return this.apiService.post("/api/saveDataSurveyorSearch", any);
  }
}
