import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, switchMap, take, tap } from 'rxjs';
import { IResNotifyList, Notification } from 'app/layout/common/notifications/notifications.types';
import { ApiService } from 'app/business/service/api.service';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService 
{
    private _notifications: ReplaySubject<IResNotifyList[]> = new ReplaySubject<IResNotifyList[]>(1);

    /**
     * Constructor
     */
    constructor(
        private _httpClient: HttpClient,
        private apiService: ApiService,
        private _authService: AuthService,
    ) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for notifications
     */
    get notifications$(): Observable<IResNotifyList[]> {
        return this._notifications.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all notifications
     */
    getAll(): Observable<IResNotifyList[]> {
        const userProfile = JSON.parse(
            this._authService.loginUser
        )
        const actionReq = {
            userCode: userProfile.uId.toString(),
            companyId: parseInt(userProfile.cpId),
            // notificationTypeId: "N",
            listNotificationReadStatus: ['W', 'R']
        };

        return this.apiService.post("/api/getNotificationListExternal", actionReq).pipe(
            tap((notifications) => {
                this._notifications.next(notifications);
            })
        );
    }

    // ไม่ได้ใช้ Noti ถูก create จากฝั่ง API
    //  /**
    //  * Create a notification
    //  *
    //  * @param notification
    //  */
    // create(notification: IResNotifyList): Observable<IResNotifyList>
    // {
    //     return this.notifications$.pipe(
    //         take(1),
    //         switchMap(notifications => this._httpClient.post<IResNotifyList>('api/common/notifications', {notification}).pipe(
    //             map((newNotification) => {

    //                 // Update the notifications with the new notification
    //                 this._notifications.next([...notifications, newNotification]);

    //                 // Return the new notification from observable
    //                 return newNotification;
    //             })
    //         ))
    //     );
    // }

    /**
     * Update the notification
     *
     * @param id
     * @param notification
     */
    update(notification: IResNotifyList): Observable<IResNotifyList> {
        const userProfile = JSON.parse(
            this._authService.loginUser
        )
        const stringId = notification.notificationId.toString();

        const updateReq = {
            userCode: userProfile.uId.toString(),
            listNotificationId: [stringId],
            notificationActionType: "R",
            notificationIsActive: "Y",
            notificationGroupId: "N",
            userName: parseInt(userProfile.uId)
        }

        //send update to api
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this.apiUpdate(updateReq).pipe(
                map((res: any) => {
                    if (res === 'error') {
                        const index = notifications.findIndex(item => item.notificationId === notification.notificationId);
                        const notiItem = notifications[index];
                        return notiItem;
                    } else {
                        // Find the index of the updated notification
                        const index = notifications.findIndex(item => item.notificationId === notification.notificationId);

                        const notiItem = notifications[index];
                        notiItem.notificationReadStatus = "R";

                        // Update the notification
                        notifications[index] = notiItem;

                        // Update the notifications
                        this._notifications.next(notifications);

                        // Return the updated notification
                        return notiItem;
                    }
                })
            ))
        );
    }

    /**
     * Delete the notification
     *
     * @param id
     */
    delete(notification: IResNotifyList) {
        const userProfile = JSON.parse(
            this._authService.loginUser
        )
        const stringId = notification.notificationId.toString();

        const deleteReq = {
            userCode: userProfile.uId.toString(),
            listNotificationId: [stringId],
            notificationActionType: "D",
            notificationIsActive: "Y",
            notificationGroupId: "N",
            userName: parseInt(userProfile.uId)
        }

        //send delete to delete api
        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this.apiUpdate(deleteReq).pipe(
                map((res: any) => {
                    let isDeleted: boolean = false;

                    if (res === 'error') {
                        return isDeleted;
                    } else {
                        // Find the index of the deleted notification
                        const index = notifications.findIndex(item => item.notificationId === notification.notificationId);

                        // Delete the notification
                        notifications.splice(index, 1);

                        // Update the notifications
                        this._notifications.next(notifications);

                        isDeleted = true;
                        // Return the deleted status
                        return isDeleted;
                    }
                })
            ))
        );
    }

    apiUpdate(req: any): Observable<any> {
        return this.apiService.post("/api/saveActionNotificationUserExternal", req);
    }

    /**
     * Mark all notifications as read
     */
    markAllAsRead(notifyList: IResNotifyList[]): Observable<boolean> {
        const userProfile = JSON.parse(
            this._authService.loginUser
        )
        const updateReq = {
            userCode: userProfile.uId.toString(),
            listNotificationId: notifyList.map(e => e.notificationId.toString()),
            notificationActionType: "R",
            notificationIsActive: "Y",
            notificationGroupId: "N",
            userName: parseInt(userProfile.uId)
        }

        return this.notifications$.pipe(
            take(1),
            switchMap(notifications => this.apiUpdate(updateReq).pipe(
                map((res: any) => {
                    let isUpdate: boolean = false;
                    if (res === 'error') {
                        return isUpdate;
                    } else {
                        // Go through all notifications and set them as read
                        notifications.forEach((notification, index) => {
                            notifications[index].notificationReadStatus = "R";
                        });

                        // Update the notifications
                        this._notifications.next(notifications);

                        // Return the updated status
                        isUpdate = true;
                        return isUpdate;
                    }
                })
            ))
        );
    }
}
