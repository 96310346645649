import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { FormHelperService } from '@bundle/service/form-helper.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { IResNotifyList } from 'app/layout/common/notifications/notifications.types';
import { AuthService } from 'app/core/auth/auth.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';

@Component({
  selector: 'app-noti-popup',
  templateUrl: './noti-popup.component.html',
  styleUrls: ['./noti-popup.component.css']
})
export class NotiPopupComponent implements OnInit {
  dataUserRole: any;
  form: any = {};
  userProfile: any = {};
  userToken: any;
  data: IResNotifyList[] = [];
  slideshowDelay = 5000;
  disablePopup: boolean = false;

  constructor(
    private alertService: AlertMessageService,
    private formHelper: FormHelperService,
    private dialogRef: MatDialogRef<NotiPopupComponent>,
    private _authService: AuthService,
    private appMasterService: AppMasterService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.data = data;
  }

  ngOnInit(): void {
    console.log("ngOnInit ", this.data)
  }

  convertDate(dateStr: any) {
    var date = new Date(dateStr);
    var thaiDate = date.toLocaleDateString('th-TH', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return thaiDate;
  }

  disablePopupShow(e) {
    this.disablePopup = e;
  }

  closePopup() {
    if (this.disablePopup) {
      const userProfile = JSON.parse(
        this._authService.loginUser
      )

      const updateReq = {
        userCode: userProfile.uId.toString(),
        listNotificationId: this.data.map(e => e.notificationId.toString()),
        notificationActionType: "R",
        notificationIsActive: "Y",
        notificationGroupId: "P",
        userName: parseInt(userProfile.uId)
      }

      this.appMasterService.saveActionNotificationUserExternal(updateReq).then(res => {
        if (res) {
          return;
        }
      })
    }

    this.dialogRef.close();
  }
}