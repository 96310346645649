<mat-chip-list #chipList fxLayout="row" fxLayoutAlign="center center" *ngIf="!showField">
  <div *ngFor="let item of filterList ">
      <mat-chip *ngIf="item.value !== '' " [removable]="removableFilter(item)" (removed)="removeFilter(item)">
          <span>{{item.label}}</span>
          <mat-icon *ngIf="removableFilter(item)" matChipRemove>cancel</mat-icon>
      </mat-chip>
  </div>
</mat-chip-list>

<mat-accordion class="ml-10 mr-10">
  <mat-expansion-panel (opened)="showField = true" (closed)="showField = false">
      <mat-expansion-panel-header>
          <mat-panel-title>
              {{showField ? 'ย่อแถบการค้นหา': 'ค้นหาเพิ่มเติม'}}
          </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="mt-12 mb-12" class="pr-10 pl-10" fxLayout="column" fxLayoutAlign="space-between none"
          style="line-height: 23px;display: flex;width: 100%;margin-bottom: 20px;">
          <div class="input-row" fxLayout.lt-md="column" fxLayoutGap.lt-md="0px"
              style="width: 100%;margin-right: 15px;display: flex;gap: 10px;">
              <div fxLayout="row" fxLayoutGap="10px"
                  style="width: 100%;margin-bottom: 15px;">
                  <mat-form-field class="form-block" style="width: 100%">
                      <mat-label>รหัสกลุ่มผู้ใช้</mat-label>
                      <input type="text" matInput placeholder="รหัสสิทธิ์ผู้ใช้" [(ngModel)]="form.userGroupCode"
                          (ngModelChange)="userGroupCodeChange();">
                  </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutGap="10px"
                  style="width: 100%;margin-bottom: 15px;">
                  <mat-form-field class="form-block" style="width: 100%">
                      <mat-label>ชื่อกลุ่มผู้ใช้</mat-label>
                      <input type="text" matInput placeholder="ชื่อสิทธิ์ผู้ใช้" [(ngModel)]="form.groupName"
                          (ngModelChange)="groupNameChange();">
                  </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutGap="10px"
                  style="width: 100%;margin-bottom: 15px;">
                  <mat-form-field class="form-block" style="width: 100%">
                      <mat-label>สถานะ</mat-label>
                      <mat-select flex="20" [(ngModel)]="form.isActive" (ngModelChange)="isActiveChange();">
                          <mat-option [value]="''">
                              ทั้งหมด
                          </mat-option>
                          <mat-option [value]="'Y'"> ใช้งาน</mat-option>
                          <mat-option [value]="'N'"> ยกเลิก</mat-option>
                      </mat-select>
                  </mat-form-field>
              </div>
          </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px"
          style="margin-bottom: 20px;display:flex;justify-content: flex-end;gap: 8px;">
          <button mat-raised-button (click)="searchFormReset()" matTooltipPosition="above"
              matTooltip="ล้างข้อมูล">ล้างข้อมูล</button>
          <button mat-raised-button color="accent" matTooltipPosition="above" (click)="searchFormSubmit()"
              matTooltip="ค้นหา">
              <mat-icon>search</mat-icon>ค้นหา
          </button>
      </div>
  </mat-expansion-panel>
</mat-accordion>