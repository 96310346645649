import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppReportService } from 'app/business/service/app/app-report.service';
import moment from 'moment';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-report-ms13-incomplete-ms24',
  templateUrl: './report-ms13-incomplete-ms24.component.html',
  styleUrls: ['./report-ms13-incomplete-ms24.component.css'],
  providers: [
    { 
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
})

export class ReportMs13IncompleteMs24Component implements OnInit {

  previews: string;
  statusOptionList: any = [];
  dailyReport: any = {};
  _params: any = {};
  myControl = new FormControl('');
  myDate = new Date();
  dailyReportForm: FormGroup = this.formBuilder.group({
    startDate: ['', Validators.required],
    endDate: ['', Validators.required],
    typeList: [''],
    statusList: []
  })
  constructor(
    private alertService: AlertMessageService,
    private formBuilder: FormBuilder,
    private appReport: AppReportService,
    private appMasterService: AppMasterService,
  ) {

  }

  async ngOnInit() {
    this.dailyReport.typeList = "0";
    this.dailyReport.statusList = "0";

    // const rqStatus = {
    //   hCode:"STATUS",
    //   statusId: 0,
    //   isActive: "Y",
    //   valueList:"9,13"
    // };
    // await this.appMasterService.getStatus(rqStatus).then((res) => {
    //   this.statusOptionList = res;
    // });
  }

  updateFormSearch(e): void {
    // 
    this._params = e;
  }

  async ExportExcel() {
    //console.log(this._params.value);
    this.dailyReport = this._params.value;
    // 
    await this.appReport.reportMs13IncompleteMs24(this.dailyReport).then((res) => {
      if (res !== 'error') {
        var today = moment().format("yyyy-MM-DD");
        this.exportExcelData("รายงาน มส.13 ทั้งหมดที่ได้รับอนุมัติและยังไม่มาขอออก มส.24-" + today + ".xlsx", res);

      } else {
        this.alertService.info({ title: '', message: 'ไม่สามารถ Export รายงานได้' });
      }
    });
  }

  exportExcelData(fileName, data) {
    var blob = new Blob([this.stringToByteArray(atob(data))]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  }
  stringToByteArray(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  clearStartDate() {
    this.dailyReport.startDate = null;
  }

  clearEndDate() {
    this.dailyReport.endDate = null;
  }
}
