import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMs13Service } from 'app/business/service/app/app-ms13.service';
import { O2ViewerComponent } from '../o2-viewer/o2-viewer.component';
import { CreateProductGoodsItemModalComponent } from '../../o-two/create-product-goods-item-modal/create-product-goods-item-modal.component';
import * as _ from 'underscore';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { SurveyorCheckResultComponent } from '../surveyor-check-result/surveyor-check-result.component';

@Component({
  selector: 'app-ms13-viewer',
  templateUrl: './ms13-viewer.component.html',
  styleUrls: ['./ms13-viewer.component.css']
})
export class Ms13ViewerComponent implements OnInit {
  form: any = {};
  formSur: any = {};
  statusList: any = [];
  dataSource: any = [];
  dataSourceAtt: any = [];
  formO2: boolean = false;
  surveyDataSource: any = [];

  constructor(
    private appMasterService: AppMasterService,
    private appMs13Service: AppMs13Service,
    private alertMessageService: AlertMessageService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<Ms13ViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {
    this.appMasterService.statusData.subscribe(res => {
      this.statusList = res;
    });

    var rqGetMs13 = {
      id: this.data.id
    }

    this.appMs13Service.getDataMS13(rqGetMs13).then(async res => {
      if (res === 'error') {
        // this.dialogRef.close()
      } else {
        this.form = res;
        this.form.formatExchangeRate = this.form.exchangeRate.toFixed(6);

        if (this.form.o2Hid != undefined && this.form.o2Hid != null) {
          this.formO2 = true;
        }

        const rqSurveyorDd = {
          surveyorID: this.form.inspectbyId,
          isActive: "Y",
        };

        await this.appMasterService.DropdownSurveyor(rqSurveyorDd).then(res => {
          if (res === 'error') {
            return;
          } else {
            var data = _.first(res);
            this.form.inspectBy = data.companyNameTH;
          }
        });

        const formShippingMask = {
          companyID: Number(this.form.exporterId),
          userId: Number(this.form.ownerId),
          isActive: "Y",
        };

        this.appMasterService.getShippingMask(formShippingMask).then((res) => {
          if (res === 'error') {
            this.form.shippingMarkId = null;
            this.form.shippingMark = 'ไม่สามารถโหลดข้อมูลได้'
          } else {
            var shippingMark = _.first(res);
            this.form.shpMaskpic = shippingMark.path;
            this.form.shippingMark = shippingMark.no;
          }
        });

      }
    });

    this.appMs13Service.getDataMS13Detail(rqGetMs13).then(res => {
      if (res === 'error') {
        this.dataSource = [];
      } else {
        console.log(this.statusList);
        this.dataSource = res.map(e => {
          return {
            ...e,
            itemStatusName: this.statusList.find(el => el.statusId == e.itemStatus).statusName
          }
        });
      }
    })

    this.appMs13Service.getDataMS13Attachment(rqGetMs13).then(res => {
      if (res === 'error') {
        this.dataSourceAtt = [];
      } else {
        this.dataSourceAtt = res;
      }
    })

    this.appMs13Service.getMs13SurveyorAuditPerson(rqGetMs13).then(resSur => {
      if (resSur === 'error') {
        this.formSur = {};
      } else {
        this.formSur = resSur;
      }
    });

    this.appMs13Service.getMs13SurveyorResultList(rqGetMs13).then(res => {
      if (res === 'error') {
        this.surveyDataSource = [];
        return;
      } else {
        this.surveyDataSource = res;
      }
    })
  }

  viewO2Copy() {
    this.dialog.open(O2ViewerComponent, {
      disableClose: true,
      data: {
        formData: this.form,
        goodsDatasoure: this.dataSource,
      },
      width: '85vw',
      height: '85vh'
    });
  }

  addProduct(id, data): void {
    const dialogRef = this.dialog.open(CreateProductGoodsItemModalComponent, {
      disableClose: true,
      maxWidth: "none",
      width: "65vw",
      data: {
        hId: this.form.id,
        status: this.form.status,
        type: "info",
        id: id,
        exchangeRate: this.form.exchangeRate,
        page: "ms13",
        item: data,
        currency: this.form.currency,
      },
    });
  }

  openTab(e) {
    window.open(e, '_blank').focus();
  }

  printMs13() {
    var rqPrintMs13Doc = {
      ms13hId: this.data.id, //real
      // ms13hId: 105648, //mock
      copyNumber: 4
    };

    this.appMs13Service.printCertificateMS13(rqPrintMs13Doc).then(res => {
      if (res === 'error') {
        return;
      } else {
        if (res === null) {
          this.alertMessageService.open({
            message: "ไม่พบสำเนา มส.13 กรุณาตรวจสอบ",
            title: ""
          });

          return;
        } else {
          var data = res;
          window.open(data.pathPdf, "_blank");
        }
      }
    });
  }

  editSurveyorCheck(e, type) {
    const dialogRef = this.dialog.open(SurveyorCheckResultComponent, {
      disableClose: true,
      maxHeight: "90vh",
      width: "90vw",
      data: {
        surveyId: e,
        hId: this.form.id,
        productList: this.dataSource,
        disabled: type === 'info' ? true : false,
      },
    });
  }
}
