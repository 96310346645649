<div style="display: flex; flex-flow: column; height: 100%; gap: 30px" class="flex flex-col h-full">
  <!-- <app-head-o2></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex; flex-flow: row; gap: 10px; align-items: stretch"></div>
      <div style="
          display: flex;
          flex-flow: row;
          gap: 10px;
          flex: 1;
          justify-content: flex-end;
        ">
        <div class="mat-button" style="gap: 10px; flex: 0 40%">
          <mat-form-field style="display: flex; flex-flow: row; flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="คำค้นหา"
              (keyup)="applyFilter($event.target.value)" />
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" mat-button [matMenuTriggerFor]="menu">
          <div style="
              display: flex;
              gap: 10px;
              align-items: center;
              text-align: center;
            ">
            <span>เงื่อนไขการค้นหา</span>
          </div>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)" (keydown.tab)="stopPropagation($event)">
            <form (ngSubmit)="searchData()">
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่ใบอนุญาต</mat-label>
                    <input autocomplete="off" type="text" matInput [(ngModel)]="search.pt_no" name="pt_no" />
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ชื่อ</mat-label>
                    <input autocomplete="off" type="text" matInput [(ngModel)]="search.name" class="basis-full"
                      name="name" />
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>จังหวัด</mat-label>
                    <input autocomplete="off" type="text" matInput [(ngModel)]="search.province" class="basis-full"
                      name="province" />
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เบอร์โทร</mat-label>
                    <input autocomplete="off" type="text" matInput [(ngModel)]="search.phone" name="phone" />
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div class="flex flex-row">
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" type="submit" style="flex: 0 50%">
                    ค้นหา
                  </button>
                </div>
              </div>
            </form>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort (matSortChange)="SortChange($event)" [dataSource]="dataSource">
      <ng-container matColumnDef="ptNo" style="text-align: center">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
          เลขที่ใบอนุญาต
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          {{ element.ptNo }}
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>ชื่อ</th>
        <td mat-cell *matCellDef="let element" [innerHTML]="element.name"></td>
      </ng-container>

      <ng-container matColumnDef="province">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>จังหวัด</th>
        <td mat-cell *matCellDef="let element">
          {{ element.province }}
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>เบอร์โทรศัพท์</th>
        <td mat-cell *matCellDef="let element">
          {{ element.phone }}
        </td>
      </ng-container>

      <ng-container matColumnDef="productId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
          สินค้าที่ได้รับอนุญาต
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.product }}
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">
          Action
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <button mat-icon-button matTooltip="แก้ไข" *ngIf="checkButton('BT064')" matTooltipPosition="above"
            routerLink="/page/surveyor-search/surveyor-search-detail" [queryParams]="{ ID: element.id }">
            <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" style="text-align: center"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>