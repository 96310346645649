<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <!-- <app-head-o2></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex; flex-flow: row; gap: 10px; align-items: stretch">
        <button mat-raised-button style="width: 70px;font-size: 1.5em;" matTooltip="รีเฟรชข้อมูล" matTooltipPosition="above" (click)="onRefresh()">
          <i class="ti ti-refresh"></i>
        </button>
      </div>
      <div style="
            display: flex;
            flex-flow: row;
            gap: 10px;
            flex: 1;
            justify-content: flex-end;
          ">
        <div class="mat-button" style="gap: 10px; flex: 0 40%">
          <mat-form-field style="display: flex; flex-flow: row; flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="คำค้นหา"
              (keyup)="applyFilter($event.target.value)" />
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" mat-button [matMenuTriggerFor]="menu">
          <div style="display: flex; gap: 10px; align-items: center; text-align: center;">
            <span>เงื่อนไขการค้นหา</span>
          </div>
        </button>
        <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)" (keydown.tab)="stopPropagation($event)">
            <form (ngSubmit)="searchData()">
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>วันหมดอายุ</mat-label>
                    <input matInput [matDatepicker]="picker1" [(ngModel)]="search.expireDate" name="expireDate">
                    <mat-hint>YYYY/MM/DD</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1 dateFormat="YYYY/MM/DD"></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ผู้ส่งออก</mat-label>
                    <input autocomplete="off" type="text" matInput [(ngModel)]="search.companyName"
                      name="companyName" />
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่</mat-label>
                    <input autocomplete="off" type="text" matInput [(ngModel)]="search.docNo" name="docNo" />
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทสินค้า</mat-label>
                    <mat-select [(ngModel)]="search.productType" name="productId" #productId="ngModel" ngmodel>
                      <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>ประเภทสินค้า</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>สถานะ</mat-label>
                    <mat-select [(ngModel)]="search.status" name="status">
                      <mat-option value="">แสดงทั้งหมด</mat-option>
                      <mat-option value="2">Sent</mat-option>
                      <mat-option value="3">In Process</mat-option>
                      <mat-option value="9">Complete</mat-option>
                      <mat-option value="4">Reject</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div>
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" type="submit" style="flex: 0 50%">
                    ค้นหา
                  </button>
                </div>
              </div>
            </form>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort (matSortChange)="SortChange($event)" [dataSource]="dataSource">
      <ng-container matColumnDef="companyName" style="text-align: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
          ผู้ส่งออก
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.companyName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="docNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          เลขที่ใบอนุญาต/เลขที่คำร้อง/เลขที่ใบรับรอง
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.fullRefNo }}
        </td>
      </ng-container>

      <ng-container matColumnDef="expireDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef>
          วันหมดอายุ
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ (element.expireDateNew != undefined && element.expireDateNew != null ? element.expireDateNew :
          element.expireDateOld) | date:'yyyy-MM-dd' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="productId">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
          ประเภทสินค้า
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.productName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="acceptBy">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
          ผู้ตรวจสอบ
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.acceptBy }}
        </td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
          สถานะ
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.statusCode }}
        </td>
      </ng-container>

      <ng-container matColumnDef="typeID">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center">
          ประเภทเอกสาร
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          {{ element.typeDoc }}
        </td>
      </ng-container>
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center">
          Action
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <div *ngIf="element.status === 2">
            <button mat-icon-button matTooltip="พิจารณาคำร้อง" (click)="staffGetRenew('sent',element)"
              matTooltipPosition="above">
              <i class="ti ti-direction-sign"></i>
            </button>
          </div>
          <div *ngIf="element.status === 3">
            <button mat-icon-button matTooltip="พิจารณาคำร้อง" (click)="openRenew(element)" matTooltipPosition="above">
              <i class="ti ti-direction-sign"></i>
            </button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns" style="text-align:center;"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>