import { Injectable } from "@angular/core";
import { ApiExternalService } from "../api/api-external.service";

@Injectable({
    providedIn: "root",
})
export class AppExternalService {
    constructor(private apiExternalService: ApiExternalService) { }

    getLicenseO2External(dataReq: any): Promise<any> {
        return new Promise((resolve, reject) => {
            this.apiExternalService.getLicenseO2External(dataReq).subscribe((res) => {
                resolve(res);
            });
        });
    }
}
