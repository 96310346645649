import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { ConfirmPopupComponent } from '../confirm-popup/confirm-popup.component';
import { AppO2Service } from 'app/business/service/app/app-o2.service';
import { filter } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import moment from "moment";
import { NgForm } from "@angular/forms";

@Component({
  selector: 'app-add-company-member-dialog',
  templateUrl: './add-company-member-dialog.component.html',
  styleUrls: ['./add-company-member-dialog.component.css']
})
export class AddCompanyMemberDialogComponent implements OnInit {
  form: any = {};
  pages: any;
  productList: any = [];
  statusList: any = [];
  currentDate: Date = new Date();
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  status: any;
  @ViewChild("createOnlineForm") createOnlineForm: NgForm;
  @ViewChild("createManualForm") createManualForm: NgForm;
  @ViewChild("createMs13Form") createMs13Form: NgForm;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appMasterService: AppMasterService,
    private appO2Service: AppO2Service,
    public dialogRef: MatDialogRef<AddCompanyMemberDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    const { page } = this.route.snapshot.queryParams;
    // console.log("createO2 ngOnInit", page);
    this.pages = this.data.page; //"ms13";
    // this.pages = page ;//"ms13";
    this.loadData();
  }

  // onChange(e) {
  //   if (e.checked == true) {
  //     this.data.cpParamiter = "ON";
  //     this.status = "ใช้งาน";
  //   } else {
  //     this.data.cpParamiter = "OFF";
  //     this.status = "ไม่ใช้งาน";
  //   }
  // }

  async loadData() {
    // const formProduct = {
    //   companyID: Number(this.userProfile.cpId),
    //   productId: 0,
    //   isActive: "Y",
    // };
    // this.productList = await this.appMasterService.getProduct(formProduct);
    // if (this.pages == "o2") {
    //   this.productList = this.productList.filter(
    //     (f: { productId: number }) => f.productId == 3 || f.productId == 10
    //   );
    // }
    const formProduct = {
      page: this.pages,
      isActive: "Y",
    };
    this.productList = await this.appMasterService.getProduct(formProduct);
    if (this.pages == "o2") {
      this.productList = this.productList.filter(
        (f: { productId: number }) => f.productId == 3 || f.productId == 10
      );
    } else {
      this.productList = this.productList.filter(
        (f: { productId: number }) => f.productId != 3 && f.productId != 10
      );
    }

    this.appMasterService.getProduct(formProduct).then((res: any) => {
      if (res == "error") {
      }
      else {
        this.productList = res;
      }
    })

    const formStatus = {
      statusId: 0,
      isActive: "Y",
    };
    this.statusList = await this.appMasterService.getStatus(formStatus);
    
  }

  setDate(val: { targetElement: { name: string }; value: moment.MomentInput }) {
    if (val.targetElement.name == "expireDate") {
      this.form.expireDate = moment(val.value).format("YYYY-MM-DD");
    } else {
      this.form.issueDate = moment(val.value).format("YYYY-MM-DD");
      this.form.maxExpireDate = moment(val.value)
        .add(30, "days")
        .format("YYYY-MM-DD");
      this.form.minExpireDate = moment(val.value)
        .add(-30, "days")
        .format("YYYY-MM-DD");
    }
  }

  onValid(e: { status: string }): boolean {
    return e.status == "VALID" ? false : true;
  }

}

