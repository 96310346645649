import { AppNewsService } from "./../../business/service/app/app-news.service";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-news-modal",
  templateUrl: "./news-modal.component.html",
  styleUrls: ["./news-modal.component.css"],
})
export class NewsModalComponent implements OnInit {
  dataSource: any = [];
  newsData: any = {};
  slideshowDelay = 5000;
  constructor(private appNewsService: AppNewsService) {}

  ngOnInit() {
    this.getNews();
  }

  getNews() {
    const formReq = {
      categorymenuid: 2,
      categorynewsid: 38,
      password: "Thaichamber2023++",
      username: "admin_api",
      subcategorynewsid: 11,
    };
    this.appNewsService.getNewsList(formReq).then((res) => {
      this.newsData = res?.data;
      this.dataSource = this.newsData;
    });
  }
}
