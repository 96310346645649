<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">วิธีการกรอกบริษัทผู้รับสินค้า </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="flex flex-col mat-typography" style="gap: 15px;">
    <div class="flex flex-row justify-center gap-3">
      <div class="flex flex-col justify-center" style="width: 40%;">
        <h4>Address Line 1</h4>
        <h4>(Street Address, Building, Alley, Road)</h4>
      </div>
      <div class="flex-auto flex flex-row gap-3" style="width: 60%">
        <div class="flex flex-col justify-center">:</div>
        <div class="flex flex-col justify-center flex-1">
          <div>
            กรอกที่อยู่จัดส่งของบริษัท อันได้แก่ บ้านเลขที่ อาคาร ชั้น ซอย ถนน ข้อมูลในช่องนี้จะปรากฎในช่อง "เลขที่อยู่บริษัท / ถนน" ในแบบฟอร์ม อ.2 มส.13 หรือ มส.24
          </div>
          <div>
            ตัวอย่าง: House no.45 second floor, 5th cross.
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-center gap-3">
      <div class="flex flex-col justify-center" style="width: 40%;">
        <h4>Address Line 2</h4>
        <h4>(Apartment, Suite, P.O. Box, Sub-area, Sub-district)</h4>
      </div>
      <div class="flex-auto flex flex-row gap-3" style="width: 60%">
        <div class="flex flex-col justify-center">:</div>
        <div class="flex flex-col justify-center flex-1">
          <div>
            กรอกที่อยู่จัดส่งเสริมของบริษัท (ถ้ามี) เช่น อาคาร ปณ. แขวง ข้อมูลในช่องนี้จะปรากฎในช่อง "แขวง (ตำบล)" ในแบบฟอร์ม อ.2 มส.13 หรือ มส.24
          </div>
          <div>
            ตัวอย่าง: Banaswadi
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-center gap-3">
      <div class="flex flex-col justify-center" style="width: 40%;">
        <h4>City</h4>
        <h4>(District, City, Town, Suburb)</h4>
      </div>
      <div class="flex-auto flex flex-row gap-3" style="width: 60%">
        <div class="flex flex-col justify-center">:</div>
        <div class="flex flex-col justify-center flex-1">
          <div>
            กรอกเมือง หรือเขตที่ตั้ง  ข้อมูลในช่องนี้จะปรากฎในช่อง "เขต (อำเภอ)" ในแบบฟอร์ม อ.2 มส.13 หรือ มส.24
          </div>
          <div>
            ตัวอย่าง: Bangalore
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-center gap-3">
      <div class="flex flex-col justify-center" style="width: 40%;">
        <h4>Region</h4>
        <h4>(State, Province, Territory)</h4>
      </div>
      <div class="flex-auto flex flex-row gap-3" style="width: 60%">
        <div class="flex flex-col justify-center">:</div>
        <div class="flex flex-col justify-center flex-1">
          <div>
            กรอกรัฐ มณฑล หรือจังหวัดที่ตั้ง ข้อมูลในช่องนี้จะปรากฎในช่อง "จังหวัด" ในแบบฟอร์ม อ.2 มส.13 หรือ มส.24
          </div>
          <div>
            ตัวอย่าง: KA หรือ Karnataka
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-row justify-center gap-3">
      <div class="flex flex-col justify-center" style="width: 40%;">
        <h4>Post Code / ZIP Code</h4>
      </div>
      <div class="flex-auto flex flex-row gap-3" style="width: 60%">
        <div class="flex flex-col justify-center">:</div>
        <div class="flex flex-col justify-center flex-1">
          <div>
            กรอกรหัสไปรษณีย์ ข้อมูลในช่องนี้จะปรากฎในช่อง "รหัสไปรษณีย์" ในแบบฟอร์ม อ.2 มส.13 หรือ มส.24
          </div>
          <div>
            ตัวอย่าง: 56004
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>