import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { debounceTime, map, startWith, switchMap, takeUntil } from "rxjs/operators";
import { HttpLoadingService } from '@bundle/service/http-loading.service';
import { of, Subject } from "rxjs";
import { userSearch } from 'app/business/interface/app/app-user.interface';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-button-filter-permission',
  templateUrl: './button-filter-permission.component.html',
  styleUrls: ['./button-filter-permission.component.scss']
})
export class ButtonFilterPermissionComponent implements OnInit {
  form: any;
  userList: any[];
  userGroupList: any[];
  userRoleList: any[];
  clientList: any[];
  action: string;
  actionList: any[];
  disabled: boolean;
  userToken: any;
  searchUserForm: FormGroup;
  valueSelect: boolean = false;
  isLoading: boolean = false;
  filteredOptions$: any = [];

  @Output() buttonFormFilterEmit: EventEmitter<any> = new EventEmitter();
  constructor(
    private _formBuilder: FormBuilder,
    private _authService:AuthService,
    private appUserService: AppUserService,
    private appMasterService: AppMasterService,
    private appMenuService: AppMenuService,
    private httpLoadingService: HttpLoadingService,
  ) {
    this.actionList = [
      { text: 'กลุ่มผู้ใช้', value: 'G' },
      { text: 'สิทธิ์ผู้ใช้', value: 'R' },
      { text: 'รายบุคคล', value: 'U' },
    ];
    this.action = '';
    this.disabled = true;
  }

  ngOnInit(): void {
    this.userToken = JSON.parse(
      this._authService.loginUser
    )
    this.searchUserForm = this._formBuilder.group({
      userSearch: ['']
    });

    this.buttonFormFilterInit();
    this.setSubcribe();
    this.valueChange();
  }

  setSubcribe(): void {
 
    this.appUserService.userRoleList.subscribe(res => {
      if (res && res.length > 0) {
        this.userRoleList = res?.filter(x => x.isActive === "Y" && x.isEdit != "N");
      } else {
        this.userRoleList = new Array();
      }
    });
    this.appUserService.userGroupList.subscribe(res => {
      if (res && res.length > 0) {
        this.userGroupList = res?.filter(x => x.isActive === "Y");
      } else {
        this.userGroupList = new Array();
      }
    });

    var reqData = {
      cilentCode: '',
      userName: '',
      name: '',
      isActive: '',
      userRoleCode: '',
      userCode: '',
      userGroupCode: '',
      cpId: this.userToken.cpId,
    };

    this.appUserService.getUserDropdownList(reqData).then((res: any) => {
      if (res && res.length > 0) {
        this.userList = res?.filter(x => x.isActive === "Y");
      } else {
        this.userList = new Array();
      }
    });
    // this.appMasterService.clientList.subscribe(res => {
    //   if (res && res.length > 0) {
    //     this.clientList = res;
    //   } else {
    //     this.clientList = new Array();
    //   }
    // });

    this.checkGroupAccess(this.userToken.gId);
  }

  buttonFormFilterInit(): void {
    this.action = '';
    this.disabled = true;
    const actionItem = this.actionList.find(e => e.value === this.action);
    this.appMenuService.actionButtonPermission.next(actionItem);
    this.form = {
      action: this.action,
      actionLabel: actionItem?.text,
      menuGroupCode: 'B',
      userGroupCode: '',
      userRoleCode: '',
      userCode: '',
      gId:  this.userToken.gId
    };

    this.buttonFormFilterEmit.emit(this.form);
    this.appMenuService.getButtonAll(this.form);
  }

  actionChange(): void {
    this.action = this.form.action;
    const actionItem = this.actionList.find(e => e.value === this.action);
    this.appMenuService.actionButtonPermission.next(actionItem);
    this.appMenuService.actionButtonTo.next(null);
    this.form = {
      action: this.action,
      actionLabel: actionItem?.text,
      menuGroupCode: 'B',
      userGroupCode: '',
      userRoleCode: '',
      userCode: '',
      gId:  this.userToken.gId
    };
    this.appMenuService.getButtonAll(this.form);
  }
  userGroupChange(): void {
    if (this.form.userGroupCode !== ''){
      const userGroup = this.userGroupList.find(e => e.userGroupCode === this.form.userGroupCode);
      this.appMenuService.actionButtonTo.next({
        value: userGroup.userGroupCode,
        text: userGroup.groupName
      });
      this.appMenuService.getButtonAll(this.form);
    }
  }
  userRoleChange(): void {
    if (this.form.userRoleCode !== ''){
      const userRole = this.userRoleList.find(e => e.userRoleCode === this.form.userRoleCode);
      this.appMenuService.actionButtonTo.next({
        value: userRole.userRoleCode,
        text: userRole.roleName
      });
      this.appMenuService.getButtonAll(this.form);
    }
  }
  userChange(): void {
    if (this.form.user !== undefined && this.form.user  !== null){
      this.appMenuService.actionButtonTo.next({
        value: this.form.user.userCode,
        text: this.form.user.name,
        user: this.form.user
      });
      this.appMenuService.getButtonAll({ 
        ...this.form,
        userCode: this.form.user?.userId,
        userRoleCode: this.form.user?.userRoleCode, 
        userGroupCode: this.form.user?.userGroupCode });
    }
  }
  
  checkGroupAccess(groupCode: string) {
    if (groupCode !== "1" && groupCode !== "4" && groupCode !== "5") {
      this.actionList.unshift({ text: 'กรุณาเลือก', value : '' });
      return;
    } else {
      this.actionList.unshift({ text: 'กลุ่มผู้ใช้', value: 'G' });
      this.actionList.unshift({ text: 'กรุณาเลือก', value : '' });
      return;
    }
  }

  clearValue(): void {
    this.valueSelect = false;
    this.isLoading = false;
    this.searchUserForm.get('userSearch').setValue('');
    this.form.user = null;
  }

  selectionChange(e): void {
    this.searchUserForm.get('userSearch').setValue(e.option.value.display);
    this.form.user = e.option.value;

    this.valueSelect = true;

    this.userChange();
  }

  valueChange() {
    this.filteredOptions$ = this.searchUserForm.get('userSearch').valueChanges.pipe(
      debounceTime(500),
      switchMap(value => {
        if (!value) {
          this.valueSelect = false;
          return of(null);
        }

        this.httpLoadingService.enabled = false;
        this.isLoading = true;

        const key = value;
        var reqData = {
          cilentCode: '',
          userName: '',
          name: '',
          isActive: '',
          userRoleCode: '',
          userCode: '',
          userGroupCode: '',
          filterValue: key,
          cpId:  this.userToken.cpId,
        };
        if (key !== '') {
          if (this.valueSelect) {
            return of(null);
          }
          else {
            this.valueSelect = false;
            return this.appUserService.getUserDropdownList(reqData);
          }
        } else {
          this.valueSelect = false;
          this.isLoading = false;
          return;
        }

      }),
      map((res: any[]) => {
        this.isLoading = false;
        this.httpLoadingService.enabled = true;


        let userData = res?.filter(x => x.isActive === "Y");
        return userData?.map(item => {
          const { userName, staffName } = (item as userSearch);
          return {
            ...item,
            // tslint:disable-next-line: max-line-length
            display: `${userName} ${staffName}`
          };
        });
      })
    );
  }
}
