import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, Input, ViewChild } from "@angular/core";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { AppStaffService } from "app/business/service/app/app-staff.service";
import moment from "moment";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RenewO2InquiryComponent } from "../../o-two/renew-o2-inquiry/renew-o2-inquiry.component";
import { RenewMs24InquiryComponent } from "../../ms24/renew-ms24-inquiry/renew-ms24-inquiry.component";
import { RenewMs13InquiryComponent } from "../../ms13/renew-ms13-inquiry/renew-ms13-inquiry.component";
import { AppMasterService } from "app/business/service/app/app-master.service";

// สิทธิ์
export const MY_DATE_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};
@Component({
  selector: "app-staff-renew-list",
  templateUrl: "./staff-renew-list.component.html",
  styleUrls: ["./staff-renew-list.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class StaffRenewListComponent {
  selected: "";
  //column Display Header
  displayedColumns: string[] = [
    "companyName",
    "docNo",
    "expireDate",
    "productId",
    "acceptBy",
    "status",
    "typeID",
    "action",
  ];
  search: any = {};
  dataSource = new MatTableDataSource<any>();
  receiver: any = {};
  dateSelect: any;
  productList: any = [];
  type: any;
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  // country_code = country;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() typePage: string;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appStaffService: AppStaffService,
    private appMasterService: AppMasterService,
    private alertService: AlertMessageService,
    private ConfirmDialogService: ConfirmDialogService,
    private AlertMessageService: AlertMessageService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.dateSelect = moment(this.search.selectedDate).format("YYYY-MM-DD");
  }

  async ngOnInit() {
    const { type } = this.route.snapshot.queryParams;
    this.type = type;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.search.type = "";
    this.search.product = "";
    this.search.status = "";
    console.log("StaffRenewListComponent typePage", this.typePage);
    
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: 0,
      isActive: "Y",
    };

    this.productList = await this.appMasterService.getProduct(formProduct);

    if (this.typePage == 'o2') {
      this.productList = this.productList.filter(
        (f) => f.productId == 3 || f.productId == 10
      );  
    }

    this.loadData();
  }

  clearOption() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  SortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  async loadData() {
    this.search = {};

    const data = {
      UID: 1,
      typeDoc: this.typePage,
    };
    await this.appStaffService.StaffGetListRenew(data).then((res) => {
      this.dataSource.data = res;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async searchData() {
    const data = {
      UID: 1,
      typeDoc: this.typePage,
      exporterName: this.search.companyName ? this.search.companyName : "",
      docNo: this.search.docNo ? this.search.docNo.trim() : "",
      expireDate: this.search.expireDate ? moment(this.search.expireDate) : null,
      productType: this.search.productType ? Number(this.search.productType) : "",
      status: this.search.status ? Number(this.search.status) : ""
    };

    await this.appStaffService.StaffGetListRenew(data).then((res) => {
      this.dataSource.data = res;
    });

    this.trigger.closeMenu();
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  setFilterPredicate() {
    this.dataSource.filterPredicate = (
      data: any,
      filterObjectString: string
    ) => {
      const filterObj = JSON.parse(filterObjectString);
      const expireDate = data.expireDate ? new Date(data.expireDate) : null;
      const filterExpireDate = filterObj.expireDate
        ? new Date(filterObj.expireDate)
        : null;
      const diffExpireDate =
        expireDate && filterExpireDate
          ? expireDate.getTime() - filterExpireDate.getTime()
          : null;
      return (
        (!filterObj.expireDate
          ? true
          : data.expireDate
            ? diffExpireDate < 86400000 && diffExpireDate >= 0
            : false) &&
        (data.companyName ? data.companyName : "")
          .toLowerCase()
          .includes(filterObj.companyName) &&
        (data.docNo ? data.docNo : "")
          .toLowerCase()
          .includes(filterObj.docNo) &&
        (data.typeDoc ? data.typeDoc : "")
          .toLowerCase()
          .includes(filterObj.typeDoc) &&
        (data.productType ? data.productType : "")
          .toLowerCase()
          .includes(filterObj.productType) &&
        (data.status ? data.status : "")
          .toLowerCase()
          .includes(filterObj.status)
      );
    };
  }

  async staffGetRenew(action, e) {
    const data = {
      ID: e.renewId,
      action: action,
      typeID: e.type,
    };
    const alertConfirm = await this.ConfirmDialogService.open({
      header: "ยืนยันทำรายการ",
      content: "ยืนยันทำรายการ",
    });
    if (alertConfirm) {
      await this.appStaffService.StaffVerifyRenew(data).then((res) => {
        if (res !== "error") {
          this.loadData()
          if (e.type == 2) {
            this.renewO2(res, true, true);
          } else if (e.type == 13) {
            this.renewMs13(res, true, true);
          } else if (e.type == 24) {
            this.renewMs24(res, true, true);
          }
        }
      });
    }
  }

  async openRenew(e) {
    const data = {
      id: e.renewId,
      typeID: e.type,
    };
    await this.appStaffService.StaffGetRenew(data).then((res) => {
      if (res !== "error") {
        if (e.type == 2) {
          this.renewO2(res, true, true);
        } else if (e.type == 13) {
          this.renewMs13(res, true, true);
        } else if (e.type == 24) {
          this.renewMs24(res, true, true);
        }
      }
    });
  }

  renewO2(e, isStaff, isEdit) {
    console.log(e);
    this.dialog.open(RenewO2InquiryComponent, {
      width: '75vw',
      disableClose: true,
      data: {
        item: e,
        isStaff: isStaff,
        isDetail: isEdit
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.loadData()
      }
    });
  }

  renewMs24(e, isStaff, isEdit) {
    console.log(e);
    this.dialog.open(RenewMs24InquiryComponent, {
      width: '75vw',
      disableClose: true,
      data: {
        item: e,
        isStaff: isStaff,
        isDetail: isEdit
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.loadData()
      }
    });
  }

  renewMs13(e, isStaff, isEdit) {
    // ;
    console.log(e);
    this.dialog.open(RenewMs13InquiryComponent, {
      width: '75vw',
      disableClose: true,
      data: {
        item: e,
        isStaff: isStaff,
        isDetail: isEdit
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.loadData()
      }
    });
  }

  onRefresh() {
    this.loadData();
  }
}
