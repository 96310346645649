<div class="mat-elevation-z8" style="width: 100%">
  <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
    <!-- Position Column -->
    <!-- bankAccountNo Column -->
    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ลำดับ </th>
      <td mat-cell *matCellDef="let element; let i = index" class="text-left"> {{ i + 1 }} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="memberCode">
      <th mat-header-cell *matHeaderCellDef class="text-left"> รหัสสมาชิก
      </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.memberCode}} </td>
    </ng-container>

    <ng-container matColumnDef="memberGroup">
      <th mat-header-cell *matHeaderCellDef class="text-left"> กลุ่มสมาชิก </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.memberGroup}} </td>
    </ng-container>

    <ng-container matColumnDef="memberType">
      <th mat-header-cell *matHeaderCellDef class="text-left"> ประเภทสมาชิก </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.memberType}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="text-left"> สถานะ </th>
      <td mat-cell *matCellDef="let element" class="text-left"> {{element.status}} </td>
    </ng-container>

    <ng-container matColumnDef="approveDate">
      <th mat-header-cell *matHeaderCellDef class="text-left"> วันที่อนุมัติ </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.approveDate | date:'yyyy-MM-dd HH:mm'}} </td>
    </ng-container>

    <ng-container matColumnDef="expireDate">
      <th mat-header-cell *matHeaderCellDef class="text-left"> วันที่หมดอายุ </th>
      <td mat-cell *matCellDef="let element" class="text-left">
        {{element.expireDate | date:'yyyy-MM-dd HH:mm'}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons
    aria-label="Select page of periodic elements">
  </mat-paginator>
</div>
