import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { ExportDocumentMs24Dropdown } from "app/business/interface/app/app-ms24.interface";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn} from '@angular/forms';
import { MAT_MOMENT_DATE_FORMATS,MomentDateAdapter,MAT_MOMENT_DATE_ADAPTER_OPTIONS,} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import moment from "moment";
import { AppItemtypeService } from "app/business/service/app/app-itemtype.service";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-ms24-report-export',
  templateUrl: './ms24-report-export.component.html',
  styleUrls: ['./ms24-report-export.component.css'],
  providers: [
    { provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
})
export class Ms24ReportExportComponent implements OnInit {

  form: any = {};
  FormDoc123 : FormGroup;
  dataProduct : any[] = [];
  typeproduct : string = "ALL";
  SelectDataNameDocumentMs24: ExportDocumentMs24Dropdown[] = [
    {id:1, value:'รายงานแสดงการยื่นขอออก มส.24'},
    {id:2, value:'สรุปยอดการอนุมัติ มส.24'},
    {id:3, value:'สรุปยอดการปริ้นท์ มส.24'},
    {id:4, value:'ทะเบียนคุมการออกเอกสารงานมาตรฐานสินค้า มส.24 ในระบบ'}   
  ];
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
 


  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppMs24Service: AppMs24Service,
    private ConfirmDialogService: ConfirmDialogService,
    private AlertMessageService : AlertMessageService,
    private formBuilder: FormBuilder,
    private AppItemtypeService: AppItemtypeService,
  ) { }

  ngOnInit() {
    this.form.typeproduct = 'ALL';
    this.checkFormGroup();
    this.loadDataProduct();
  }

  checkFormGroup (){
  this.FormDoc123 = new FormGroup({
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required)
  },
  [this.dateRangeValidator]);
  
  }


  private dateRangeValidator: ValidatorFn = (): {
    [key: string]: any;
    } | null => {
    let invalid = false;
    const from = this.FormDoc123 && this.FormDoc123.get("startDate").value;
    const to = this.FormDoc123 && this.FormDoc123.get("endDate").value;
    if (from && to) {
      invalid = new Date(from).valueOf() > new Date(to).valueOf();
    }
    return invalid ? { invalidRange: { from, to } } : null;
  };
  
  Loadexcel(){
    this.typeproduct = this.form.typeproduct;
    if(this.form.typeproduct == 'ALL') {
      this.typeproduct = ' ';
    }
    switch (this.form.optionSelect){
      case 1 : this.MS24ExportDocumentStaffSummaryPrint(); break;
      case 2 : this.MS24ExportDocumentStaffSummaryApproved(); break;
      case 3 : this.MS24ExportDocumentStaffRequest(); break;
      case 4 : this.MS24ExportDocumentStaffProductStandard(); break;  
    }

  }
      async MS24ExportDocumentStaffSummaryPrint(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDate,
          "endDate" : this.form.selectedEndDate,
          "productType" : this.typeproduct
        };
        await this.AppMs24Service
          .MS24ExportDocumentStaffSummaryPrint(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("รายงานแสดงการยื่นขอออกมส24" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }

      async MS24ExportDocumentStaffSummaryApproved(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDate,
          "endDate" : this.form.selectedEndDate,
          "productType" : this.typeproduct
        };
        await this.AppMs24Service
          .MS24ExportDocumentStaffSummaryApproved(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("สรุปยอดการอนุมัติมส24" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }
      async MS24ExportDocumentStaffRequest(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDate,
          "endDate" : this.form.selectedEndDate,
          "productType" : this.typeproduct
        };
        await this.AppMs24Service
          .MS24ExportDocumentStaffRequest(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("สรุปยอดการปริ้นท์มส24" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }
      async MS24ExportDocumentStaffProductStandard(): Promise<void> {
        const formdatas = {
          "startDate" : this.form.selectedStartDateApproved,
          "endDate" : this.form.selectedEndDateApproved,
          "certificatestartDate" : this.form.selectedStartDateRequest,
          "certificateEndDate" : this.form.selectedEndDateRequest,
          "billstartDate" : this.form.selectedStartDateBill,
          "billEndDate" : this.form.selectedEndDateBill,
          "productType" : this.typeproduct
        };
        await this.AppMs24Service
          .MS24ExportDocumentStaffProductStandard(formdatas)
          .then((res: any) => {
            if (res !== "error") {
              this.exportExcelData("ทำเบียนคุมการออกเอกสารงานมาตรฐานสินค้ามส24ในระบบ" + `.xlsx`, res);
            } else {
              /*this.alertService.info({
                title: "Export Failed",
                message: "",
              });*/
            }
          });
      }

      exportExcelData(fileName, data) {
        var blob = new Blob([this.stringToByteArray(atob(data))]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
      }
      stringToByteArray(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      async loadDataProduct(): Promise<void> {
        //send data to api
        const formData = {
          
        };
         await this.AppItemtypeService.getDataDetailProduct(formData).then((res)=>{ 
          this.dataProduct = res
        });
      }
}
