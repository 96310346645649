import { Component, Inject, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Component({
  selector: "app-surveyor-modal",
  templateUrl: "./surveyor-modal.component.html",
  styleUrls: ["./surveyor-modal.component.css"],
})
export class SurveyorModalComponent implements OnInit {
  surveyorList: any = [];
  form: any = {};
  datas: any = {};
  surveyorForm: FormGroup;
  filteredOptionsCompanySurveyor$: Observable<string[]>;

  get countrySearch(): AbstractControl {
    return this.surveyorForm.get("companySurveyorSearch");
  }

  constructor(
    private appMasterService: AppMasterService,
    public dialogRef: MatDialogRef<SurveyorModalComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.datas = data;

    this.surveyorForm = this.fb.group({
      companySurveyorSearch: [""]
    });
  }

  ngOnInit() {
    this.loadData();

    this.surveyorForm.valueChanges.subscribe((newValue) => {
      this.filteredOptionsCompanySurveyor$ = this.filterCompanySurveyorValues(newValue.companySurveyorSearch);
    });

    console.log(this.datas);
  }

  async loadData() {
    const rqSurveyorDd = {
      surveyorID: "",
      isActive: "Y",
    };

    await this.appMasterService.DropdownSurveyor(rqSurveyorDd).then(res => {
      if (res === 'error') {
        return;
      } else {
        this.surveyorList = res;
        this.filteredOptionsCompanySurveyor$ = res;
      }
    });

    if (this.datas?.inspectBy != undefined && this.datas?.inspectBy != null) {
      var inspectBy = this.datas.inspectBy;
      var inspectCompany = this.surveyorList.find(e => e.companyId == inspectBy);

      this.surveyorForm.get("companySurveyorSearch").setValue(inspectCompany.companyNameTH);
      this.form.inspectBy = inspectCompany;
    }
  }

  filterCompanySurveyorValues(search: any) {
    const name = typeof search === 'string' ? search.toLowerCase() : search?.companyNameTH.toLowerCase();

    return this.surveyorList.filter(function (option) {
      if (option.companyNameTH.toLowerCase().includes(name)) {
        return option;
      }
    });
  }

  selectionCompanySurveyorChange(e): void {
    this.surveyorForm.get("companySurveyorSearch").setValue(e.option.value.companyNameTH);
    this.form.inspectBy = e.option.value;
  }

  deleteItem() {
    this.surveyorForm.get("companySurveyorSearch").setValue("");
    this.form.inspectBy = null;
  }
}
