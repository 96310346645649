import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMs13Service } from 'app/business/service/app/app-ms13.service';
import { AppMs24Service } from 'app/business/service/app/app-ms24.service';
import { ImageModalComponent } from '../../ms13/Image-modal/Image-modal.component';

@Component({
  selector: 'app-ms24-viewer',
  templateUrl: './ms24-viewer.component.html',
  styleUrls: ['./ms24-viewer.component.css']
})
export class Ms24ViewerComponent implements OnInit {
  form: any = {};
  dataSource: any = [];
  statusList: any = [];
  itemProduct: any = {};
  portList: any = [];
  dataSourceAtt: any = [];
  constructor(
    private appMasterService: AppMasterService,
    private appMs24Service: AppMs24Service,
    private appMs13Service: AppMs13Service,
    private alertMessageService: AlertMessageService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<Ms24ViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.appMasterService.statusData.subscribe(res => {
      this.statusList = res;
    });

    const formDataMs24 = {
      ID: this.data.id
    }

    this.appMs24Service.getDataMS24(formDataMs24).then((res) => {
      if (res != null) {
        this.form = res;
        if (res.item.length) {
          this.itemProduct = res.item[0];
        }
        this.dataSource = res.item;
        this.form.shippingProvince =
          this.form.shippingProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.shippingProvince;
        this.form.recieveProvince =
          this.form.recieveProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.recieveProvince;
        this.form.inspectProvince =
          this.form.inspectProvince == "กรุงเทพฯ"
            ? "กรุงเทพฯ"
            : this.form.inspectProvince;

        this.dataSource = this.form?.item?.map((m) => {
          return {
            ...m,
            itemStatusName: "",
          };
        });

        const rqPort = {
          ID: Number(this.form.typeDoc),
          isActive: "Y",
        };

        this.appMasterService.getPort(rqPort).then((res) => {
          this.portList = res;
        });
      }
    });
    const formDataMs13 = {
      ID: this.data.id,
    };

    this.appMs13Service.getDataMS24Attachment(formDataMs13).then(res => {
      if (res === 'error') {
        this.dataSourceAtt = [];
      } else {
        this.dataSourceAtt = res;
      }
    });
  }


  showImg(e) {
    const dialogRef = this.dialog.open(ImageModalComponent, {
      disableClose: true,
      // maxWidth: "none",
      width: "45vw",
      height: "35vw",
      data: {
        path: e.cmms24aPathFile,
        title: "เอกสารผลวิเคราะห์",
      },
    });
  }

  printMs24() {
    const formDataMs24 = {
      ms14hId: this.data.id,
      copyNumber: 1
    };
    
    this.appMs24Service.printCopyCertificateMS24(formDataMs24).then((res) => {
      window.open(res.pathPdf, "_blank");
    });
  }
}
