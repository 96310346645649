import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';

@Component({
  selector: 'app-sub-district-selection-dialog',
  templateUrl: './sub-district-selection-dialog.component.html',
  styleUrls: ['./sub-district-selection-dialog.component.css']
})
export class SubDistrictSelectionDialogComponent implements OnInit {
  form: any = {};
  districtList: any = [];
  provinceList: any = [];
  displayedColumns: string[] = [
    "subdistrictName",
    "postalCode",
    "action",
  ];
  dataSource = new MatTableDataSource<any>();

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private appMasterService: AppMasterService,
    private confirmDialogService: ConfirmDialogService,
    private dialogRef: MatDialogRef<SubDistrictSelectionDialogComponent>
  ) { }

  ngOnInit() {
    this.loadProvince();
  }

  async loadProvince() {
    await this.appMasterService.getProvince({}).then(res => {
      if (res === 'error') {
        this.provinceList = [];
      } else {
        this.provinceList = res;
      }
    });
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  provinceChange(e) {
    var selectedProvince = this.provinceList.find(el => el.id == e.value);
    this.form.provinceCode = selectedProvince.code;
    this.form.provinceName = selectedProvince.name;

    this.loadDistrictData(e.value)
  }

  loadDistrictData(e) {
    var rqDistrict = {
      provinceId: e
    };

    this.appMasterService.getDistrict(rqDistrict).then(res => {
      if (res == 'error') {
        this.districtList = [];
      } else {
        this.districtList = res;
      }
    });

    this.appMasterService.getSubdistrict(rqDistrict).then((res: any) => {
      if (res == 'error') {
        return;
      } else {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }

  districtChange(e) {
    var selectedDistrict = this.districtList.find(el => el.districtId == e.value);
    this.form.districtCode = selectedDistrict.districtCode;
    this.form.districtName = selectedDistrict.districtName;

    var rqDistrict = {
      provinceId: this.form.provinceId,
      districtId: e.value
    };

    this.appMasterService.getSubdistrict(rqDistrict).then((res: any) => {
      if (res == 'error') {
        return;
      } else {
        this.dataSource = new MatTableDataSource(res);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    })
  }

  selectItem(e) {
    var message = "คุณต้องการเลือกที่อยู่นี้หรือไม่? ตำบล" + e.subdistrictName + " อำเภอ" + e.districtName + " จังหวัด" + this.form.provinceName + " รหัสไปรษณีย์ " + e.postalCode;

    if (this.form.provinceId == 2) {
      message = "คุณต้องการเลือกที่อยู่นี้หรือไม่? แขวง" + e.subdistrictName + " เขต" + e.districtName + " " + this.form.provinceName + " รหัสไปรษณีย์ " + e.postalCode;
    }

    this.confirmDialogService.open({
      header: "",
      content: message
    }).then(res => {
      if (res) {
        this.form.subdistrictId = e.subdistrictId;
        this.form.subdistrictName = e.subdistrictName;
        this.form.subdistrictCode = e.subdistrictCode;

        this.form.districtCode = e.districtCode;
        this.form.districtName = e.districtName;
        this.form.districtId = e.districtId;

        this.form.postalCode = e.postalCode;
        
        this.dialogRef.close(this.form);
      }
    })
  }
}
