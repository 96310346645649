import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { AppMs24Service } from 'app/business/service/app/app-ms24.service';
import { LogDocumentActionDetailModalComponent } from '../../log-document-action-detail-modal/log-document-action-detail-modal.component';
import { AppMs13Service } from 'app/business/service/app/app-ms13.service';

@Component({
  selector: 'app-ms13-record-log-detail',
  templateUrl: './ms13-record-log-detail.component.html',
  styleUrls: ['./ms13-record-log-detail.component.css']
})
export class Ms13RecordLogDetailComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "index",
    "logDate",
    "logDescription",
    "logBy",
    "action",
  ];
  clickBody: boolean = false;
  form: any = {};
  datas: any = {};

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMs13Service: AppMs13Service,
  ) {
    console.log(data);
    this.datas = data;
  }

  ngOnInit() {
    this.loadData();
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  loadData() {
    var request = {
      ms13Hid: this.datas.id
    };

    this.appMs13Service.getMs13PerDocumentActionHistoryList(request).then((res: any) => {
      if (res === 'error') {
        return;
      } else {
        if (res === null) {
          return;
        } else {
          this.dataSource = new MatTableDataSource(res);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      }
    })
  }

  openLog(e) {
    this.dialog.open(LogDocumentActionDetailModalComponent, {
      width: '70vw',
      disableClose: true,
      data: e
    });
  }
}
