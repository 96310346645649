import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class ApiMasterService {
  constructor(private apiService: ApiService) { }


  getStatus(data: any): Observable<any[]> {
    data.hCode = "STATUS";
    return this.apiService.post("/api/getStatus", data);
  }

  getStatusTracking(data: any): Observable<any[]> {
    return this.apiService.post("/api/getStatusTracking", data);
  }

  getProduct(data: any): Observable<any[]> {
    return this.apiService.post("/api/getProduct", data);
  }

  getProvince(data: any): Observable<any[]> {
    return this.apiService.post("/api/getProvince", data);
  }

  getPort(data: any): Observable<any[]> {
    return this.apiService.post("/api/getPort", data);
  }

  getCountry(data: any): Observable<any[]> {
    return this.apiService.post("/api/getCountry", data);
  }

  getCurrency(data: any): Observable<any[]> {
    return this.apiService.post("/api/getCurrency", data);
  }

  getQtyUnit(data: any): Observable<any[]> {
    return this.apiService.post("/api/getQtyUnit", data);
  }
  getWeightUnit(data: any): Observable<any[]> {
    return this.apiService.post("/api/getWeightUnit", data);
  }
  getTariff(data: any): Observable<any[]> {
    return this.apiService.post("/api/getTariff", data);
  }
  getTarifftype(data: any): Observable<any[]> {
    return this.apiService.post("/api/getTarifftype", data);
  }
  getTranSMode(data: any): Observable<any[]> {
    return this.apiService.post("/api/getTranSMode", data);
  }
  getExporter(data: any): Observable<any[]> {
    return this.apiService.post("/api/getExporter", data);
  }
  getShipping(data: any): Observable<any[]> {
    return this.apiService.post("/api/getShipping", data);
  }
  getReceiver(data: any): Observable<any[]> {
    return this.apiService.post("/api/getReceiver", data);
  }
  getSurveyor(data: any): Observable<any[]> {
    return this.apiService.post("/api/getSurveyor", data);
  }
  getCompanyByCompanyCode(data: any): Observable<any[]> {
    return this.apiService.post("/api/getCompanyByCompanyId", data);
  }
  getTransMode(data: any): Observable<any[]> {
    return this.apiService.post("/api/getTransMode", data);
  }
  getQtyUnitOne(data: any): Observable<any[]> {
    return this.apiService.post("/api/getQtyUnitOne", data);
  }
  saveMs13Attachment(data: any): Observable<any[]> {
    return this.apiService.post("/api/saveMs13Attachment", data);
  }
  saveMs24Attachment(data: any): Observable<any[]> {
    return this.apiService.post("/api/saveMs24Attachment", data);
  }
  getShippingMask(data: any): Observable<any[]> {
    return this.apiService.post("/api/getShippingMask", data);
  }
  getTypeClass(data: any): Observable<any[]> {
    return this.apiService.post("/api/getTypeClass", data);
  }
  getCompanyList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getCompanyList", data);
  }
  DropdownSurveyor(data: any): Observable<any[]> {
    return this.apiService.post("/api/DropdownSurveyor", data);
  }
  getUserByCompanyType(data: any): Observable<any[]> {
    return this.apiService.post("/api/getUserByCompanyType", data);
  }
  getConfigDByHCode(request: any): Observable<any> {
    return this.apiService.post("/api/getMtConfigList", request);
  }
  getListCscBranchManage(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListCscBranchManage", request);
  }
  getCompanyAddressType(request: any): Observable<any> {
    return this.apiService.post("/api/getCompanyAddressType", request);
  }
  getCompanyType(request: any): Observable<any> {
    return this.apiService.post("/api/getCompanyType", request);
  }
  getListUnit(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListUnit", request);
  }
  saveUnit(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveUnit", request);
  }
  updateUnitBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateUnitBatch", request);
  }
  getListWeight(request: any): Observable<any[]> {
    return this.apiService.post("/api/getListWeight", request);
  }
  saveWeight(request: any): Observable<any[]> {
    return this.apiService.post("/api/saveWeight", request);
  }
  updateWeightBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateWeightBatch", request);
  }
  getMasterBankCode(request: any): Observable<any> {
    return this.apiService.post("/api/getMasterBankCode", request);
  }
  saveBankCode(request: any): Observable<any> {
    return this.apiService.post("/api/saveBankCode", request);
  }
  updateBankCodeBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateBankCodeBatch", request);
  }
  getMasterProvince(request: any): Observable<any> {
    return this.apiService.post("/api/getMasterProvince", request);
  }
  saveProvince(request: any): Observable<any> {
    return this.apiService.post("/api/saveProvince", request);
  }
  updateProvinceBatch(request: any): Observable<any> {
    return this.apiService.post("/api/updateProvinceBatch", request);
  }
  getStepPrice(request: any): Observable<any[]> {
    return this.apiService.post("/api/getStepPrice", request);
  }
  getPaymentType(): Observable<any[]> {
    return this.apiService.get("/api/getPaymentType");
  }
  getFormRate(): Observable<any[]> {
    return this.apiService.get("/api/getFormRate");
  }
  getRelationShipperCompanyList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getRelationShipperCompanyList", data);
  }

  getCompanyReceiverList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getCompanyReceiverList", data);
  }

  getSurveyorDftEmployeeList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getSurveyorDftEmployeeList", data);
  }

  getListRelationship(data: any): Observable<any[]> {
    return this.apiService.post("/api/getRelationship", data);
  }

  deleteRelationship(data: any): Observable<any[]> {
    return this.apiService.post("/api/deleteRelationship", data);
  }

  saveRelationship(data: any): Observable<any[]> {
    return this.apiService.post("/api/saveRelationship", data);
  }

  getShipperRelationship(data: any): Observable<any[]> {
    return this.apiService.post("/api/getShipperRelationship", data);
  }

  saveCompanyReceiver(data: any): Observable<any[]> {
    return this.apiService.post("/api/saveCompanyReceiver", data);
  }

  saveCompanyReceiverActive(data: any): Observable<any[]> {
    return this.apiService.post("/api/saveCompanyReceiverActive", data);
  }

  getNswTrackingDocumentList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getNswTrackingDocumentList", data);
  }

  getNswTrackingResponseList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getNswTrackingResponseList", data);
  }

  getDftTrackingDocumentList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getDftTrackingDocumentList", data);
  }

  getDftTrackingResponseList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getDftTrackingResponseList", data);
  }

  getMasterBankAccount(data: any): Observable<any[]> {
    return this.apiService.post("/api/getMasterBankAccount", data);
  }

  getIsPaymentByCompany(data: any): Observable<any[]> {
    return this.apiService.post("/api/getIsPaymentByCompany", data);
  }

  sendMS24toNSwebExternal(data: any): Observable<any[]> {
    return this.apiService.post("/api/sendMS24toNSwebExternal", data);
  }

  sendCancelMS24toNSwebExternal(data: any): Observable<any[]> {
    return this.apiService.post("/api/sendCancelMS24toNSwebExternal", data);
  }

  sendMS24toOcsConnectExternal(data: any): Observable<any[]> {
    return this.apiService.post("/api/sendMS24toOcsConnectExternal", data);
  }

  sendMS13toOcsConnectExternal(data: any): Observable<any[]> {
    return this.apiService.post("/api/sendMS13toOcsConnectExternal", data);
  }

  manualGetNsWebResponseMessage(data: any): Observable<any[]> {
    return this.apiService.post("/api/manualGetNsWebResponseMessage", data);
  }

  getDocumentActionLogDetail(data: any): Observable<any[]> {
    return this.apiService.post("/api/getDocumentActionLogDetail", data);
  }

  getDocumentActionMtList(): Observable<any> {
    return this.apiService.post("/api/getMtConfigList", {
      configHCode: "DOCUMENT_ACTION",
    })
  }
  
  updateMs24Active(data: any): Observable<any[]> {
    return this.apiService.post("/api/updateMs24Active", data);
  }
  
  getBranch(): Observable<any[]> {
    return this.apiService.get("/api/getBranch");
  }
  
  getUserTCC(request): Observable<any[]> {
    return this.apiService.post("/api/getUserTCC", request);
  }

  getNswDocumentItemTrackingList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getNswDocumentItemTrackingList", data);
  }

  getDftDocumentItemTrackingList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getDftDocumentItemTrackingList", data);
  }

  sendCancelMS13toOcsConnectExternal(data: any): Observable<any[]> {
    return this.apiService.post("/api/sendCancelMS13toOcsConnectExternal", data);
  }

  sendCancelMS24toOcsConnectExternal(data: any): Observable<any[]> {
    return this.apiService.post("/api/sendCancelMS24toOcsConnectExternal", data);
  } 
  
  getNotiList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getNotiList", data);
  }

  getNotiPopupList(data: any): Observable<any[]> {
    return this.apiService.post("/api/getNotiPopupList", data);
  }

  actionNoti(data: any): Observable<any[]> {
    return this.apiService.post("/api/actionNoti", data);
  }

  saveActionNotificationUserExternal(data: any): Observable<any[]> {
    return this.apiService.post("/api/saveActionNotificationUserExternal", data);
  }

  logOut(req): Observable<any> {
    return this.apiService.post("/api/logOut", req);
  }

  getDischargePortFilteredList(data): Observable<any> {
    return this.apiService.post("/api/getDischargePortFilteredList", data);
  }

  getDistrict(data): Observable<any> {
    return this.apiService.post("/api/getDistrict", data);
  }

  getSubdistrict(data): Observable<any> {
    return this.apiService.post("/api/getSubdistrict", data);
  }

  getMasterDistrictList(data): Observable<any> {
    return this.apiService.post("/api/getMasterDistrictList", data);
  }

  saveDistrict(data): Observable<any> {
    return this.apiService.post("/api/saveDistrict", data);
  }

  getMasterSubDistrictList(data): Observable<any> {
    return this.apiService.post("/api/getMasterSubDistrictList", data);
  }

  saveSubDistrict(data): Observable<any> {
    return this.apiService.post("/api/saveSubDistrict", data);
  }

  updateDistrictBatch(data): Observable<any> {
    return this.apiService.post("/api/updateDistrictBatch", data);
  }

  updateSubDistrictBatch(data): Observable<any> {
    return this.apiService.post("/api/updateSubDistrictBatch", data);
  }

  getMasterDischargePortList(data): Observable<any> {
    return this.apiService.post("/api/getMasterDischargePortList", data);
  }

  saveMasterDischargePort(data): Observable<any> {
    return this.apiService.post("/api/saveMasterDischargePort", data);
  }

  getO2ManualMethodFlag(): Observable<any> {
    return this.apiService.post("/api/getMtConfigList", {
      configHCode: "O2_METHOD",
    })
  }
}
