<div class="frame" *ngIf="actionItem?.value !== ''">
  <div class="button-row">
    <button mat-raised-button color="accent" matTooltipPosition="above" matTooltip="บันทึก"
      (click)="saveMenuPermissionSubmit()" [disabled]="!actionMenuTo"> บันทึก </button>
    <button mat-raised-button matTooltipPosition="above" matTooltip="ล้างข้อมูล"
      (click)="resetMenuSubmit()">ล้างข้อมูล</button>
  </div>
  <!-- <div fxLayout="row" fxLayoutAlign="{{displayFlex}}" fxLayoutGap="20px"> -->
  <div fxLayout="row" fxLayoutGap="20px" class="checkbox">
    <div fxLayout="column" fxLayoutAlign="space-around start">
      <h3>เมนูสำหรับ{{actionItem?.text}}: {{actionMenuTo?.text}}</h3>
      <dx-tree-view id="treeview1" *ngIf="actionItem?.value === 'U'" #menuActionTreeView1 [items]="dataMenuUserList"
        [width]="300" [showCheckBoxesMode]="showCheckBoxesMode" [selectionMode]="selectionMode"
        [selectNodesRecursive]="selectNodesRecursive" [selectByClick]="selectByClick" [noDataText]="'ไม่มีข้อมูล'"
        [expandNodesRecursive]="true" [itemsExpr]="'children'" [keyExpr]="'menuCode'" [parentIdExpr]="'parentCode'"
        [displayExpr]="'name'" [expandedExpr]="'expanded'" (onSelectionChanged)="treeViewSelectionChanged($event)"
        (onContentReady)="treeViewContentReady($event)" (onItemClick)="selectItem($event)">
        <div *dxTemplate="let item of 'item'">
          {{ item.name }}
        </div>
      </dx-tree-view>
      <dx-tree-view id="treeview2" *ngIf="actionItem?.value === 'R'" #menuActionTreeView2 [items]="dataMenuUserRoleList"
        [width]="300" [showCheckBoxesMode]="showCheckBoxesMode" [selectionMode]="selectionMode"
        [selectNodesRecursive]="selectNodesRecursive" [selectByClick]="selectByClick" [noDataText]="'ไม่มีข้อมูล'"
        [expandNodesRecursive]="true" [itemsExpr]="'children'" [keyExpr]="'menuCode'" [parentIdExpr]="'parentCode'"
        [displayExpr]="'name'" [expandedExpr]="'expanded'" (onSelectionChanged)="treeViewSelectionChanged($event)"
        (onContentReady)="treeViewContentReady($event)" (onItemClick)="selectItem($event)">
        <div *dxTemplate="let item of 'item'">
          {{ item.name }}
        </div>
      </dx-tree-view>
      <dx-tree-view id="treeview3" *ngIf="actionItem?.value === 'G'" #menuActionTreeView3
        [items]="dataMenuUserGroupList" [width]="300" [showCheckBoxesMode]="showCheckBoxesMode"
        [selectionMode]="selectionMode" [selectNodesRecursive]="selectNodesRecursive" [selectByClick]="selectByClick"
        [noDataText]="'ไม่มีข้อมูล'" [expandNodesRecursive]="true" [itemsExpr]="'children'" [keyExpr]="'menuCode'"
        [parentIdExpr]="'parentCode'" [displayExpr]="'name'" [expandedExpr]="'expanded'"
        (onSelectionChanged)="treeViewSelectionChanged($event)" (onContentReady)="treeViewContentReady($event)"
        (onItemClick)="selectItem($event)">
        <div *dxTemplate="let item of 'item'">
          {{ item.name }}
        </div>
      </dx-tree-view>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around start" *ngIf="actionItem?.value === 'U'">
      <h3>เมนูสำหรับสิทธิ์ผู้ใช้งาน: {{actionMenuTo?.user?.userRoleName}}</h3>
      <dx-tree-view id="treeview2" #menuActionTreeView2 [items]="dataMenuUserRoleList"
        [width]="300" [disabled]="true" [showCheckBoxesMode]="showCheckBoxesMode" [selectionMode]="selectionMode"
        [selectNodesRecursive]="selectNodesRecursive" [selectByClick]="selectByClick" [noDataText]="'ไม่มีข้อมูล'"
        [expandNodesRecursive]="true" [itemsExpr]="'children'" [keyExpr]="'menuCode'" [parentIdExpr]="'parentCode'"
        [displayExpr]="'name'" [expandedExpr]="'expanded'" (onSelectionChanged)="treeViewSelectionChanged($event)"
        (onContentReady)="treeViewContentReady($event)" (onItemClick)="selectItem($event)">
        <div *dxTemplate="let item of 'item'">
          {{ item.name }}
        </div>
      </dx-tree-view>
    </div>
    <div fxLayout="column" fxLayoutAlign="space-around start" *ngIf="actionItem?.value === 'U'">
      <h3>เมนูสำหรับกลุ่มผู้ใช้งาน: {{actionMenuTo?.user?.userGroupName}}</h3>
      <dx-tree-view id="treeview3" #menuActionTreeView3
        [items]="dataMenuUserGroupList" [disabled]="true" [width]="300" [showCheckBoxesMode]="showCheckBoxesMode"
        [selectionMode]="selectionMode" [selectNodesRecursive]="selectNodesRecursive" [selectByClick]="selectByClick"
        [noDataText]="'ไม่มีข้อมูล'" [expandNodesRecursive]="true" [itemsExpr]="'children'" [keyExpr]="'menuCode'"
        [parentIdExpr]="'parentCode'" [displayExpr]="'name'" [expandedExpr]="'expanded'"
        (onSelectionChanged)="treeViewSelectionChanged($event)" (onContentReady)="treeViewContentReady($event)"
        (onItemClick)="selectItem($event)">
        <div *dxTemplate="let item of 'item'">
          {{ item.name }}
        </div>
      </dx-tree-view>
    </div>

  </div>
</div>