<mat-card>
  <mat-card-header class="fontTitleHeader" style="background-color: #684637;">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal"> {{disabled ? '' : 'บันทึก'}}ผลการตรวจสอบ </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="false">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="flex flex-col" style="padding: 20px; gap: 12px;">
    <div class="flex flex-row justify-center">
      <div class="flex flex-row" style="width: 75%">
        <div class="flex-1">
          <div class="flex flex-row p10">
            <div class="basis-1/2 f18" style="align-self: center;"> ผลการตรวจสอบ : <label style="color:red">*</label><br><span
                style="font-size: 12px; color: grey;">(เช่น เทียบได้ไม่ต่ำกว่ามาตรฐานที่กระทรวงพาณิชย์กำหนด)</span>
            </div>
            <div class="basis-4/5">
              <mat-form-field class="w100">
                <input autocomplete="off" required [disabled]="disabled" type="text" matInput placeholder="ผลการตรวจสอบ" #input
                  [(ngModel)]="form.checkResult" name="checkResult" #checkResult="ngModel">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center">
      <div class="flex flex-row" style="width: 75%">
        <div class="flex-1">
          <div class="flex flex-row p10">
            <div class="basis-1/2 f18" style="align-self: center;"> ผลการตรวจสอบปริมาณ : <label style="color:red">*</label><br><span
                style="font-size: 12px; color: grey;">(เช่น กระสอบที่บรรจุถูกต้อง)</span> </div>
            <div class="basis-4/5">
              <mat-form-field class="w100">
                <input autocomplete="off" required [disabled]="disabled" type="text" matInput placeholder="ผลการตรวจสอบปริมาณ" #input
                  [(ngModel)]="form.checkQuantityResult" name="checkQuantityResult" #checkQuantityResult="ngModel">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center">
      <div class="flex flex-row" style="width: 75%">
        <div class="flex-1">
          <div class="flex flex-row p10">
            <div class="basis-1/2 f18" style="align-self: center;"> ผลการตรวจสอบหีบห่อ : <label style="color:red">*</label><br><span
                style="font-size: 12px; color: grey;">(เช่น ส่งออกโดยไม่มีการบรรจุหุ้มห่อ)</span> </div>
            <div class="basis-4/5">
              <mat-form-field class="w100">
                <input autocomplete="off" required [disabled]="disabled" type="text" matInput placeholder="ผลการตรวจสอบหีบห่อ" #input
                  [(ngModel)]="form.checkPackageResult" name="checkPackageResult" #checkPackageResult="ngModel">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center">
      <div class="flex flex-row" style="width: 75%">
        <div class="flex-1">
          <div class="flex flex-row p10">
            <div class="basis-1/2 f18" style="align-self: center;"> หมายเหตุ : </div>
            <div class="basis-4/5">
              <mat-form-field class="w100">
                <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="หมายเหตุ" #input
                  [(ngModel)]="form.surveyRemark" name="surveyRemark" #surveyRemark="ngModel">
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-row justify-center">
      <fieldset style="width: 95%">
        <legend>รายละเอียดการตรวจสอบ</legend>
        <div class="flex flex-col justify-center" style="gap: 12px;">
          <div class="flex" style="gap: 12px" *ngIf="!disabled">
            <button mat-raised-button color="accent" (click)="addProduct()">
              {{editItem ? 'แก้ไขรายการตรวจสอบ' : 'เพิ่มรายการตรวจสอบ'}}
            </button>
            <button mat-raised-button (click)="resetEdit()" *ngIf="editItem">
              ยกเลิก
            </button>
          </div>
          <div class="flex justify-center" style="gap: 15px;">
            <div class="flex flex-row" style="width: 50%">
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 50%;"> รายการสินค้าที่ตรวจ : </div>
                  <div style="align-self: center; width: 50%;">
                    <mat-form-field class="w100">
                      <mat-select (selectionChange)="selectProduct($event);" [disabled]="disabled" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="formItem.itemNo" name="product" #product="ngModel">
                        <mat-option *ngFor="let pd of productList" [value]="pd.itemNo">
                          {{pd.itemNo}} - {{pd.productName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row" style="width: 50%">
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 50%;"> ประเภทหน่วยของจำนวนที่สั่งปล่อย: </div>
                  <div style="align-self: center; width: 50%;">
                    <mat-form-field class="w100">
                      <mat-select [ngModelOptions]="{standalone: true}" disabled [(ngModel)]="formItem.productItemUnit"
                        name="productItemUnit" #productItemUnit="ngModel">
                        <!-- <mat-option *ngFor="let qty of qtyUnitList" [value]="qty.id">
                          {{qty.name}}
                        </mat-option> -->
                        <mat-option value="O">อื่น ๆ</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-center" style="gap: 15px;">
            <div class="flex flex-row" style="width: 50%">
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 50%;"> ปริมาณที่ตรวจปล่อย : </div>
                  <div class="flex" style="align-self: center; width: 50%;">
                    <div style="width: 30%" *ngIf="formItem.noneQty != 'Y'">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" [disabled]="disabled" matInput placeholder="ปริมาณ" #input
                          [(ngModel)]="formItem.surveyQuantity" name="quantity" #quantity="ngModel"
                          (focus)="onFocusQuantity()" (blur)="onBlurQuantity()">
                      </mat-form-field>
                    </div>
                    <div style="width: 70%" class="flex-1">
                      <mat-form-field class="w100">
                        <mat-select [ngModelOptions]="{standalone: true}" [disabled]="disabled" [(ngModel)]="formItem.surveyQuantityUnit"
                          name="quantityUnit" #quantityUnit="ngModel">
                          <mat-option *ngFor="let qty of qtyUnitList" [value]="qty.code">
                            {{qty.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row" style="width: 50%">
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 50%;"> น้ำหนัก: </div>
                  <div class="flex" style="align-self: center; width: 50%;">
                    <div style="width: 50%">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" [disabled]="disabled" matInput placeholder="น้ำหนัก" #input
                          [(ngModel)]="formItem.surveyNetWeight" name="netWeight" #netWeight="ngModel"
                          (focus)="onFocusNetWeight()" (blur)="onBlurNetWeight()">
                      </mat-form-field>
                    </div>
                    <div style="width: 50%">
                      <mat-form-field class="w100">
                        <input autocomplete="off" type="text" matInput [disabled]="disabled" placeholder="หน่วย" #input
                          [(ngModel)]="formItem.surveyNetWeightUnit" name="netWeightUnit" #netWeightUnit="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-center" style="gap: 15px;">
            <div class="flex flex-row" style="width: 50%">
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 50%;"> น้ำหนักเฉพาะต่อหน่วย : </div>
                  <div style="align-self: center; width: 50%;">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" [disabled]="disabled" matInput placeholder="น้ำหนักเฉพาะต่อหน่วย" #input
                        [(ngModel)]="formItem.surveyNetWeighUnit" name="weightPerUnit" #weightPerUnit="ngModel">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-row" style="width: 50%">
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div class="f18" style="align-self: center; width: 50%;"> น้ำหนักรวมต่อหน่วย: </div>
                  <div style="align-self: center; width: 50%;">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" [disabled]="disabled" matInput placeholder="น้ำหนักรวมต่อหน่วย" #input
                        [(ngModel)]="formItem.surveyGrossWeightUnit" name="totalWeightPerUnit"
                        #totalWeightPerUnit="ngModel">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="formItem.isSelected">
            <div class="flex justify-center">
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"
                style="width: 100%">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    {{panelOpenState ? 'ซ่อน' : 'แสดง'}}รายละเอียดสินค้า
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="flex flex-col justify-center">
                  <div class="flex justify-center" style="gap: 15px;">
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> Tariff Code : </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{formItem.tariffCode}} </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> ราคาต่อหน่วย (เงินบาท): </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{(formItem.priceBahtPerUnit != null ? formItem.priceBahtPerUnit : 0) | number:'1.6-6'}} </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-center" style="gap: 15px;">
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> Tariff Sequence : </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{formItem.tariffSequence}} </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> ปริมาณ : </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{formItem.quantity}} </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-center" style="gap: 15px;">
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> Statistical Code : </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{formItem.statisticalCode}} </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> หน่วยของปริมาณ : </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{formItem.quantityUnitName}} </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-center" style="gap: 15px;">
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> น้ำหนักเฉพาะรวม : </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{(formItem.netWeight != null ? formItem.netWeight : 0) | number:'1.6-6'}} </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> น้ำหนักเฉพาะต่อหน่วย : </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{(formItem.weightPerUnit != null ? formItem.weightPerUnit : 0) | number:'1.6-6'}} </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-center" style="gap: 15px;">
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> หน่วยของน้ำหนัก : </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{formItem.netWeightUnit}} </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> น้ำหนักรวมต่อหน่วย : </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{(formItem.grossWeightUnit != null ? formItem.grossWeightUnit : 0) | number:'1.6-6'}} </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex justify-center" style="gap: 15px;">
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> มูลค่า เอฟโอบี (เงินบาท) : </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{(formItem.fobValueBaht != null ? formItem.fobValueBaht : 0) | number:'1.6-6'}} </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-row" style="width: 50%">
                      <div class="flex-1">
                        <div class="flex flex-row p10">
                          <div class="f18" style="align-self: center; width: 50%;"> น้ำหนักที่สามารถตรวจปล่อยได้ :
                          </div>
                          <div class="f18" style="align-self: center; width: 50%;"> {{(formItem.availableNetWeight != null ? formItem.availableNetWeight : 0) | number:'1.6-6'}} </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-expansion-panel>
            </div>
          </ng-container>
        </div>
      </fieldset>
    </div>

    <div class="flex flex-row justify-center" style="width: 100%">
      <div class="mat-elevation-z8" style="width: 95%">
        <dx-data-grid id="gridContainer" [dataSource]="dataSource" [columnAutoWidth]="true" [showBorders]="true"
          [allowColumnReordering]="true" [showBorders]="true" [columns]="columns" [noDataText]="'ยังไม่ได้เพิ่มข้อมูลรายละเอียดการตรวจสอบ'">
          <div *dxTemplate="let row of 'wFlow'">
            {{row.rowIndex + 1}}
          </div>
          <div *dxTemplate="let row of 'qty'">
            <ng-container *ngIf="row.data.noneQty == 'N'">
              {{(row.data.surveyQuantity != null ? row.data.surveyQuantity : 0) | number:'1.0'}} {{row.data.surveyQuantityUnitName}}
            </ng-container>
            <ng-container *ngIf="row.data.noneQty == 'Y'">
              {{row.data.surveyQuantityUnitName}}
            </ng-container>
          </div>
          <div *dxTemplate="let row of 'nw'">
            {{(row.data.surveyNetWeighUnit != null ? row.data.surveyNetWeighUnit : 0) | number:'1.6-6'}} {{row.data.surveyNetWeightUnit}}
          </div>
          <div *dxTemplate="let row of 'sgnw'">
            <ng-container *ngIf="row.data.noneQty == 'N'">
              {{ convertToNumber(row.data.surveyGrossWeightUnit) | number:'1.6-6'}} {{row.data.surveyNetWeightUnit}}
            </ng-container>
            <ng-container *ngIf="row.data.noneQty == 'Y'">
              {{ convertToNumber(row.data.surveyNetWeight) | number:'1.6-6'}} {{row.data.surveyNetWeightUnit}}
            </ng-container>
          </div>
          <div *dxTemplate="let row of 'action'">
            <ng-container *ngIf="disabled">
              <button mat-icon-button matTooltip="รายละเอียด" (click)="editProduct(row.data.id)" matTooltipPosition="above" style="width: 48px !important">
                <i class="ti ti-info-circle"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="!disabled">
              <button mat-icon-button matTooltip="แก้ไข" (click)="editProduct(row.data.id)" matTooltipPosition="above" style="width: 48px !important">
                <i class="ti ti-pencil"></i>
              </button>
              <button mat-icon-button matTooltip="ลบ" (click)="delProduct(row.data.id)" matTooltipPosition="above" style="width: 48px !important">
                <i class="ti ti-trash"></i>
              </button>  
            </ng-container>
          </div>
        </dx-data-grid>
      </div>
    </div>

    <div class="flex flex-col items-center p10">
      <div class="flex f18" style="gap: 15px;">
        <button mat-raised-button style="width: 100px;" color="accent" (click)="saveSurveyReport()" *ngIf="!disabled">
          บันทึกผล
        </button>
        <button mat-raised-button style="width: 100px;" [mat-dialog-close]="false">
          {{disabled ? 'ปิด' : 'ยกเลิก'}}
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>