import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-user-role-filter-form',
  templateUrl: './user-role-filter-form.component.html',
  styleUrls: ['./user-role-filter-form.component.scss']
})
export class UserRoleFilterFormComponent implements OnInit {
  filterList: any;
  form: any = {};
  showField = false;
  userRoleList: any[];
  userToken:any;
  dataFilter = [
    { seq: '1', property: 'userRoleCode', value: '', label: 'รหัสสิทธิ์ผู้ใช้ : ทั้งหมด', hasValue: true },
    { seq: '2', property: 'roleName', value: '', label: 'ชื่อสิทธิ์ผู้ใช้ : ทั้งหมด', hasValue: true },
    { seq: '3', property: 'isActive', value: '', label: 'สถานะ  : ทั้งหมด', hasValue: true },
  ];
  @Output() filter: EventEmitter<any> = new EventEmitter();

  constructor(
    private appUserService: AppUserService,
    private appMasterService: AppMasterService,
    private _authService:AuthService,
  ) { }

  ngOnInit(): void {
    this.userToken = JSON.parse(
      this._authService.loginUser
    )
  
    this.searchFormInit();

    // this.setSubcribe();
  }
  setSubcribe(): void {
    this.appUserService.userRoleList.subscribe(res => {
      if (res && res.length > 0) {
        this.userRoleList = res;
      } else {
        this.userRoleList = new Array();
      }
    });
  }
  
  searchFormReset(): void {
    this.searchFormInit();
    this.searchFormSubmit();
  }
  searchFormInit(): void {
   
    
    this.form = {
      roleName: '',
      userRoleCode: '',
      isActive: '',
      gId: this.userToken.gId,
      cpId: this.userToken.cpId
    };
    const data = this.dataFilter;
    this.filterList = data.filter(a => a.hasValue);
    this.filterList = this.filterList.sort((first, second) => {
      return (first.seq - second.seq);
    });
    this.filter.emit(this.form);
    // return data;
  }
  searchFormSubmit(): void {
    const request: any = {
      userRoleCode: this.form.userRoleCode,
      roleName: this.form.roleName,
      isActive: this.form.isActive,
      gId: this.userToken.gId,
      cpId: this.userToken.cpId
    };
    this.filter.emit(request);
    this.appUserService.getUserRoleList(request);
  }

  getUserRoleList(): void {
    this.searchFormSubmit();
  }

  roleNameChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'roleName';
    });
    // 

    data = data.map(res => {
      return {
        ...res,
        label: this.form.roleName !== '' ? 'ชื่อสิทธิ์ผู้ใช้ : ' + this.form.roleName : 'ชื่อสิทธิ์ผู้ใช้ : ทั้งหมด',
        value: this.form.roleName !== '' ? this.form.roleName : ''
      };
    });
    this.dataFilterChange('roleName', data);
  }

  userRoleCodeChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'userRoleCode';
    });
    // 

    data = data.map(res => {
      return {
        ...res,
        label: this.form.userRoleCode !== '' ? 'รหัสสิทธิ์ผู้ใช้ : ' + this.form.userRoleCode : 'รหัสสิทธิ์ผู้ใช้ : ทั้งหมด',
        value: this.form.userRoleCode !== '' ? this.form.userRoleCode : ''
      };
    });
    this.dataFilterChange('userRoleCode', data);
  }

  isActiveChange(): void {
    let data = this.filterList.filter(res => {
      return res.property === 'isActive';
    });
    // 
    const status = this.form.isActive === 'Y' ? 'ใช้งาน' : 'ยกเลิก';
    data = data.map(res => {
      return {
        ...res,
        label: this.form.isActive !== '' ? 'สถานะ : ' + status : 'สถานะ : ทั้งหมด',
        value: this.form.isActive !== '' ? this.form.isActive : ''
      };
    });
    this.dataFilterChange('isActive', data);
  }

  dataFilterChange(propName: string, dataChange: any): void {
    const datanotFind = this.filterList.filter(res => {
      return res.property !== propName;
    });
    this.filterList = [];
    this.filterList = this.filterList.concat(
      datanotFind,
      dataChange
    );
    this.filterList = this.filterList.sort((first, second) => {
      return (first.seq - second.seq);
    });
  }

  removableFilter(item): boolean {
    if (item.property !== 'userRoleCode' && item.property !== 'roleName' && item.property !== 'isActive') {
      return true;
    } else {
      return item.value !== '';
    }
  }
  removeFilter(item): void {
    this.filterList.map(element => {
      if (element.property === item.property) {
        element.value = '';
        if (!this.dataFilter.find(e => e.property === item.property)) {
          element.hasValue = false;
        } else {
          switch (item.property) {
            case 'roleName': {
              element.label = 'ชื่อสิทธิ์ผู้ใช้ : ทั้งหมด';
              this.form.roleName = '';
              this.getUserRoleList();
              break;
            }
           
            case 'userRoleCode': {
              element.label = 'รหัสสิทธิ์ผู้ใช้ : ทั้งหมด';
              this.form.userRoleCode = '';
              this.getUserRoleList();
              break;
            }
            case 'isActive': {
              element.label = 'สถานะ  : ทั้งหมด';
              this.form.isActive = '';
              this.getUserRoleList();
              break;
            }
          }
        }
      }
      return element;
    });
  }

}
