import { Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { FuseMediaWatcherService } from "@fuse/services/media-watcher";
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from "@fuse/components/navigation";
import { Navigation } from "app/core/navigation/navigation.types";
import { NavigationService } from "app/core/navigation/navigation.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AuthService } from "app/core/auth/auth.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { MatDialog } from "@angular/material/dialog";
import { NotiPopupComponent } from "app/business/component/noti-popup/noti-popup.component";
import { IResNotifyList } from "app/layout/common/notifications/notifications.types";

@Component({
  selector: "modern-layout",
  templateUrl: "./modern.component.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./modern.component.scss']
})
export class ModernLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;
  currentDate: Date = new Date();
  user: any = {};
  isStaffLogin: boolean = false;
  isCustLogin: boolean = false;
  currentActiveBranch: any;
  userProfile: any;
  loginUser: any;
  unreadCount: number = 0;
  toastPopped: boolean = false;
  //   userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  notifications: any = [];
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _navigationService: NavigationService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private appMenuService: AppMenuService,
    private appMasterService: AppMasterService,
    private _authService: AuthService,
    private dialog: MatDialog,
    private renderer: Renderer2
  ) {
    this.appMenuService.userProfile.subscribe((res) => {
      this.user = res;
      if (res != null) {
        this.isCustLogin =
          res.gId == "UG002" || res.gId == "UG003" || res.gId == "UG005"
            ? true
            : false;
        this.isStaffLogin =
          res.gId == "UG001" || res.gId == "UG004" ? true : false;
      }
    });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {

    this.userProfile = JSON.parse(this._authService.loginUser);
    this.currentActiveBranch = this.userProfile.branchName ?? "ศูนย์ราชบพิธ";
    this.loginUser = JSON.parse(localStorage.getItem("loginUser"));

    //Popup Notification
    const actionReq = {
      userCode: this.userProfile.uId.toString(),
      companyId: parseInt(this.userProfile.cpId),
      notificationTypeId: "P",
      listNotificationReadStatus: ['W']
    };

    this.appMasterService.getNotiPopupList(actionReq).then((res: IResNotifyList[]) => {
      if (res.length === 0) {
        this.notifications = [];
      } else {
        this.notifications = res.map((el: IResNotifyList) => {
          var linkRefData = el.linkRef?.split("?");
          var parameter = (linkRefData != undefined && linkRefData != null) ? this.parseQueryParams(linkRefData[1]) : null;
          return {
            ...el,
            linkRef: (linkRefData != undefined && linkRefData != null) ? linkRefData[0] : null,
            refData: parameter,
          }
        });

        this.openNotiPopup(this.notifications);
      }
    })
  }

  parseQueryParams(queryString: string) {
    return queryString.split("&").reduce((acc, param) => {
      const [key, value] = param.split("=");
      acc[key] = value;
      return acc;
    }, {});
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  openNotiPopup(e: IResNotifyList[]) {
    const dialogRef = this.dialog.open(NotiPopupComponent, {
      disableClose: false,
      width: "45vw",
      data: e,
      maxWidth: "none",
    });
  }

  onUpdateUnreadCount(e) {
    if (!this.toastPopped) {
      this.unreadCount = e;

      if (e > 0) {
        this.launchToast();
        this.toastPopped = true;
      }  
    }
  }

  launchToast() {
    const sound = new Audio('assets/file/notification.wav');
    sound.play();

    const toast = this.renderer.selectRootElement('#toast', true);
    this.renderer.addClass(toast, 'show');
    setTimeout(() => {
      this.renderer.removeClass(toast, 'show');
    }, 5000);
  }
}
