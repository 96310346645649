<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<ng-container *ngIf="isScreenSmall">
    <fuse-vertical-navigation class="dark bg-gray-900 print:hidden" [mode]="'over'" [name]="'mainNavigation'"
        [navigation]="navigation.default" [opened]="false">
        <!-- Navigation header hook -->
        <ng-container fuseVerticalNavigationContentHeader>
            <!-- Logo -->
            <div class="flex items-center h-20 pt-6 px-8">
                <img class="w-24" src="assets/images/logo/logo.png" alt="Logo image">
            </div>
        </ng-container>
    </fuse-vertical-navigation>
</ng-container>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-screen min-w-0">

    <!-- Header -->
    <div style="background-color: #003f80;--tw-bg-opacity: 1 !important;"
        class="relative flex flex-0 items-center w-screen h-16 sm:h-20 px-4 md:px-6 z-49 dark:border-b dark:bg-transparent print:hidden">
        <ng-container *ngIf="!isScreenSmall">
            <!-- Logo -->
            <div class="flex items-center mx-2 lg:mr-8 w100" style="gap: 5px;">
                <div class="hidden lg:flex">
                    <img class="dark:hidden" style="width: 250px;" src="assets/images/logo/logo.png">
                    <img class="hidden dark:flex w-24" src="assets/images/logo/logo.png">
                </div>
                <div>
                    <img class="flex lg:hidden w-36" src="assets/images/logo/logo.png">
                </div>
                <div style="color: white; line-height: 1.5em;">
                    <h2 style="font-size: 1.25em; font-weight: bold;">ระบบการออกใบรับรองมาตรฐานสินค้าอิเล็กทรอนิกส์</h2>
                    <h2>Commodity Standard Certificates System (CSC Online)</h2>
                </div>
            </div>

            <!-- Horizontal navigation -->
            <!-- <fuse-horizontal-navigation
                class="mr-2"
                [name]="'mainNavigation'"
                [navigation]="navigation.horizontal"></fuse-horizontal-navigation> -->
        </ng-container>
        <!-- Navigation toggle button -->
        <ng-container *ngIf="isScreenSmall">
            <button mat-icon-button (click)="toggleNavigation('mainNavigation')">
                <mat-icon style="color: #fff;" [svgIcon]="'heroicons_outline:menu'"></mat-icon>
            </button>
        </ng-container>
        <!-- Components -->
        <div class="flex flex-col justify-end basis-full">
            <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2" style="color: #fff !important;">
                <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
                <!--<search [appearance]="'bar'"></search>
                <shortcuts></shortcuts>
                <messages></messages>-->
                <notifications (updateUnreadCount)="onUpdateUnreadCount($event)"></notifications>

                <!-- <button  class="lg:hidden" mat-icon-button>
                    <mat-icon [matBadge]="8" matBadgeSize="small" matBadgeColor="warn"
                        [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                </button> -->
                <!-- <button class="lg:hidden" mat-icon-button 
                (click)="quickChat.toggle()">
                    <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
                </button> -->
                <user></user>
            </div>
            <div class="flex items-center pl-2 pr-2 ml-auto space-x-0.5 sm:space-x-2" style="color: #fff !important;"
                *ngIf="isCustLogin">
                ล็อคอินเข้าระบบ : {{ user?.userName }} ({{loginUser?.userGroupName}}) หมดอายุสมาชิก {{ user?.expireDate
                | thaidate : "medium" }}
            </div>
            <div class="flex items-center pl-2 pr-2 ml-auto space-x-0.5 sm:space-x-2" style="color: #fff !important;"
                *ngIf="isStaffLogin">
                ล็อคอินเข้าระบบ : {{ user?.userName }} ({{loginUser?.userGroupName}}) จากสาขา {{ currentActiveBranch }}
            </div>
        </div>

        <!--<div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2" style="color: #fff !important;">
            <!--<languages></languages>
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <!--<search [appearance]="'bar'"></search>
            <shortcuts></shortcuts>
            <messages></messages>-->
        <!-- <notifications></notifications> -->
        <!--<button
                class="lg:hidden"
                mat-icon-button
                (click)="quickChat.toggle()">
                <mat-icon [svgIcon]="'heroicons_outline:chat-alt-2'"></mat-icon>
            </button>
            <user></user>
        </div>-->
    </div>
    <!-- Content -->
    <div class="flex flex-col flex-auto w-screen">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <app-menu *ngIf="!isScreenSmall"></app-menu>
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <!-- <div
        class="relative flex flex-0 items-center w-full h-14 sm:h-20 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">
            Copyright &copy; 2023 - {{currentYear}} Relationship Republic. All rights Relationship republic </span>
    </div> -->

    <!-- Toast -->
    <div id="toast">
        <div id="img"><i class="ti ti-bell-ringing bell"></i></div>
        <div id="desc">โปรดทราบ! คุณมีการแจ้งเตือนที่ยังไม่ได้อ่าน {{unreadCount}} รายการ</div>
    </div>

</div>