<div class="flex flex-col h-full gap-4" style="padding-left: 6em; padding-right: 6em;">
  <div style="gap: 30px;" class="flex justify-items-stretch">
    <div class="flex flex-col flex-1 grid-col-1 justify-between" style="gap: 30px;">

      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            <div class="text-center"> MR UPDATE : {{form.changeUpCom}}</div>
          </mat-card-header>
          <!-- <div class="pl-2 pt-5" style="align-self: left;"> MR UPDATE : {{form.changeUpCom}}</div> -->
          <mat-card-content style="padding: 30px 30px 30px 30px;">
            <div class="flex">
              <div class="flex-1 ">
                <div class="flex flex-row p10">
                  <div style="align-self: center;"> วันหมดอายุ : </div>
                  <div class="pl-2">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.expire" name="expire"
                        #expireDate="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1">
                <div class="flex flex-row p10">
                  <div style="align-self: center;">แก้ไขครั้งล่าสุดเมื่อ : </div>
                  <div class="pl-2">
                    <mat-form-field class="w100">
                      <input autocomplete="off" matInput [(ngModel)]="form.update" name="update" #update="ngModel"
                        readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <!-- Company Information -->
      <mat-card>
        <mat-card-header class="fontTitleHeader">
          <div class="text-center">ข้อมูลบริษัท | COMPANY INFORMATION</div>
        </mat-card-header>
        <mat-card-content style="padding: 30px 30px 30px 30px;">
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>เลขที่ทะเบียน ผค. / ผธ.</h4>
                <h4>Exporter No.</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="ชื่อ" [(ngModel)]="form.exportNo" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
              </div>
            </div>
          </div>

          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>รหัสสมาชิก</h4>
                <h4>Member ID.</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="รหัสสมาชิก" [(ngModel)]="form.memID" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>เลขทะเบียนนิติบุคคล/บัตรประชาชน</h4>
                <h4>ID</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="เลขทะเบียนนิติบุคคล/บัตรประชาชน" [(ngModel)]="form.lastName"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>เลขประจำตัวผู้เสียภาษี</h4>
                <h4>Tax ID</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="เลขประจำตัวผู้เสียภาษี" [(ngModel)]="form.taxID"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>ประเภทธุรกิจ</h4>
              </div>
              <!-- <mat-form-field class="flex-auto">
                <mat-select placeholder="ประเภทธุรกิจ" [(ngModel)]="form.isAllowEpayment" name="status"
                  #status="ngModel">
                  <mat-option *ngFor="let item of isEpayment" [value]="item.value">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->

              <mat-form-field class="flex-auto">
                <input matInput placeholder="ประเภทธุรกิจ" [(ngModel)]="form.comType"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled" readonly>
              </mat-form-field>

            </div>
          </div>
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>ชื่อบริษัทภาษาไทย</h4>
                <h4>Company Name (TH)</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="ชื่อบริษัทภาษาไทย" [(ngModel)]="form.nameTH"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
              </div>
            </div>
          </div>
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>ชื่อบริษัทภาษาอังกฤษ</h4>
                <h4>Company Name (EN)</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="ชื่อบริษัทภาษาอังกฤษ" [(ngModel)]="form.nameEN"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
              </div>
            </div>
          </div>
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>ที่อยู่</h4>
                <h4>Address</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput [(ngModel)]="form.addStreet" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
              </div>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>แขวง/ตำบล</h4>
                <h4>District</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput [(ngModel)]="form.district" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>เขตอำเภอ</h4>
                <h4>Sub Province</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput [(ngModel)]="form.subProvince" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>จังหวัด</h4>
                <h4>Province</h4>
              </div>
              <mat-form-field class="flex-auto">
                <!-- <mat-select [(ngModel)]="form.isAllowEpayment" name="province" #status="ngModel">
                  <mat-option *ngFor="let item of provinceList" [value]="item.provinceName" readonly>
                    {{item.provinceName}}
                  </mat-option>
                </mat-select> -->
                <input matInput [(ngModel)]="form.province" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>อีเมล์</h4>
                <h4>E-Mail</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="อีเมล์" [(ngModel)]="form.companyEmail"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
          </div>

          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>โทรศัพท์</h4>
                <h4>Telephone Number</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="โทรศัพท์" [(ngModel)]="form.phoneNo" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>โทรสาร</h4>
                <h4>FAX Number</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="อีเมล์" [(ngModel)]="form.faxNo" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
          </div>

          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>อีเมลที่รับใบกำกับภาษี</h4>
                <h4>Email Tax</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="อีเมลที่รับใบกำกับภาษี" [(ngModel)]="form.companyName"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>อนุญาตให้ตัดจ่าย</h4>
                <h4>E-Payment</h4>
              </div>
              <mat-form-field class="flex-auto">
                <mat-select [(ngModel)]="form.isAllowEpayment" name="status" #status="ngModel">
                  <mat-option *ngFor="let item of isEpayment" [value]="item.value">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <mat-checkbox>
                ข้าพเจ้ายินยอมให้ระบบส่งใบกำกับภาษี
              </mat-checkbox>
            </div>
            <div class="flex flex50-item">
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- User Information -->
      <mat-card>
        <mat-card-header class="fontTitleHeader">
          <div class="text-center">ผู้ติดต่อ </div>
        </mat-card-header>
        <mat-card-content style="padding: 30px 30px 30px 30px;">
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>ชื่อจริง </h4>
                <h4>Frist Name </h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput [(ngModel)]="form.firstThName" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>นามสกุล</h4>
                <h4>Last Name</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput [(ngModel)]="form.lastThName" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>หมายเลขโทรศัพท์</h4>
                <h4>Mobile Number</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput [(ngModel)]="form.conPhone" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
            <div class="flex flex50-item">
              <div class="w-1/4 flex flex-col">
                <h4>อีเมล์</h4>
                <h4>Email</h4>
              </div>
              <!-- <mat-form-field class="flex-auto">
                <input matInput [matDatepicker]="picknid" [(ngModel)]="form.nIdDate"
                  [ngModelOptions]="{standalone: true}" readonly (click)="picknid.open()" [disabled]="isMREnabled"
                  required="true" class="cursor-pointer">
                <mat-datepicker-toggle matIconSuffix [for]="picknid"></mat-datepicker-toggle>
                <mat-datepicker #picknid></mat-datepicker>
              </mat-form-field> -->
              <mat-form-field class="flex-auto">
                <input matInput [(ngModel)]="form.conEmail" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled" readonly>
              </mat-form-field>
            </div>
          </div>
          <!-- <hr /> -->
          <!-- <div class="flex gap-x gap-y row-items">
            <div class="col-item">
              <app-company-address-grid [inputData]="form.companyAddress"></app-company-address-grid>
            </div>
          </div> -->
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-header class="fontTitleHeader">
          <div class="text-center">สินค้าที่ได้รับอนุญาต</div>
        </mat-card-header>
        <mat-card-content style="padding: 30px 30px 30px 30px;">
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="flex flex50-item">
                <mat-checkbox [checked]="form.rice == 'Y'">
                  ข้าวหอมมะลิไทย
                </mat-checkbox>
              </div>
            </div>
            <div class="flex flex50-item">
              <div class="flex flex50-item">
                <mat-checkbox [checked]="form.cassava == 'Y'">
                  ผลิตภัณฑ์มันสำปะหลัง
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="flex flex50-item">
                <mat-checkbox [checked]="form.corn == 'Y'">
                  ข้าวโพด
                </mat-checkbox>
              </div>
            </div>
            <div class="flex flex50-item">
              <div class="flex flex50-item">
                <mat-checkbox [checked]="form.millet == 'Y'">
                  ข้าวฟาง
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="flex flex50-item">
                <mat-checkbox [checked]="form.greenBean == 'Y'">
                  ถั่วเขียว
                </mat-checkbox>
              </div>
            </div>
            <div class="flex flex50-item">
              <div class="flex flex50-item">
                <mat-checkbox [checked]="form.blackMatpe == 'Y'">
                  ถั่วเขียวผิวดำ
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="flex flex50-item">
                <mat-checkbox [checked]="form.fishMeal == 'Y'">
                  ปลาปั่น
                </mat-checkbox>
              </div>
            </div>
            <div class="flex flex50-item">
              <div class="flex flex50-item">
                <mat-checkbox [checked]="form.whiteRice == 'Y'">
                  ข้าวขาว
                </mat-checkbox>
              </div>
            </div>
          </div>
          <div class="flex gap-x row-items">
            <div class="flex flex50-item">
              <div class="flex flex50-item">
                <mat-checkbox [checked]="form.fluff == 'Y'">
                  ปุยนุ่น
                </mat-checkbox>
              </div>
            </div>
            <div class="flex flex50-item">
            </div>
          </div>
        </mat-card-content>
      </mat-card>


      <mat-card>
        <mat-card-header class="fontTitleHeader">
          <div class="text-center">ลายมือชื่อผู้ที่ได้รับมอบอำนาจ</div>
        </mat-card-header>
        <mat-card-content style="padding: 30px 30px 30px 30px;">
          <div class="flex gap-x gap-y row-items">
            <div class="col-item">
              <!-- <div class="box-subheader">
                ลายมือชื่อผู้รับมอบอำนาจลงนาม
              </div> -->
              <app-company-authorized-signature-grid
                [inputData]="form.signList"></app-company-authorized-signature-grid>
            </div>
          </div>
        </mat-card-content>
      </mat-card>


      <!-- <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            ลายมือชื่อผู้ที่ได้รับมอบอำนาจ
          </mat-card-header>
          <mat-card-content>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="mat-button">
                  <button mat-raised-button color="accent">
                    <div style="display: flex;align-items: left;">
                      <span>Preview</span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ผู้ได้รับมอบอำนาจคนที่ 1 : </div>
                  <div class="basis-4/5">
                    <div>{{form.signpic1}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(ไทย): </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.signname_th1"
                        name="signname_th1" #signname_th1="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(อังกฤษ) : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.signname_en1"
                        name="signname_en1" #signname_en1="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ตำแหน่ง : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.position1"
                        name="position1" #position1="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                </div>
              </div>
            </div>
            <hr>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ผู้ได้รับมอบอำนาจคนที่ 2 : </div>
                  <div class="basis-4/5">
                    <div>{{form.signpic2}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(ไทย): </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.signname_th2"
                        name="signname_th2" #signname_th2="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(อังกฤษ) : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.signname_en2"
                        name="signname_en2" #signname_en2="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ตำแหน่ง : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.position2"
                        name="position2" #position2="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                </div>
              </div>
            </div>
            <hr>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ผู้ได้รับมอบอำนาจคนที่ 3 : </div>
                  <div class="basis-4/5">
                    <div>{{form.signpic3}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(ไทย): </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.signname_th3"
                        name="signname_th3" #signname_th3="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(อังกฤษ) : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.signname_en3"
                        name="signname_en3" #signname_en3="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ตำแหน่ง : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.position3"
                        name="position3" #position3="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                </div>
              </div>
            </div>
            <hr>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ผู้ได้รับมอบอำนาจคนที่ 4 : </div>
                  <div class="basis-4/5">
                    <div>{{form.signpic4}}</div>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(ไทย): </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.signname_th4"
                        name="signname_th4" #signname_th4="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ชื่อ/นามสกุล(อังกฤษ) : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.signname_en4"
                        name="signname_en4" #signname_en4="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ตำแหน่ง : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="w100">
                      <input autocomplete="off" type="text" matInput #input [(ngModel)]="form.position4"
                        name="position4" #position4="ngModel" readonly>
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div> -->

      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            เครื่องหมายที่ใช้เกี่ยวกับการส่งสินค้าออก
          </mat-card-header>
          <mat-card-content>
            <!-- <div class="flex py-6">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">
                    <button mat-raised-button color="accent">
                      เพิ่มเครื่องหมายส่งออก
                    </button>
                  </div>
                </div>
              </div>
            </div> -->

            <div *ngFor="let element of form.shippingMasksList">
              <!-- <div class="flex">
                <div class="flex-1 w-50 p10" style="border-style: solid;">
                  <div class="flex flex-row">
                    <div class="basis-1/2 f18" style="align-self: center;"></div>
                    <div class="basis-1/2 f18" style="align-self: center;"></div>
                    <div>
                      <button mat-icon-button matTooltip="ลบ" matTooltipPosition="above">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div> -->
              <div class="flex">
                <div class="flex-1 w-50" style="border-style: solid;">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;">ประเภทของสินค้า : </div>
                    <div>{{element.productName}}</div>
                  </div>
                </div>
                <div class="flex-1 w-50" style="border-style: solid;">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> </div>
                    <div> <input type="image" class="txt-box" #input width="200" height="150" [src]="element.path"
                        readonly></div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50 p10" style="border-style: solid;">
                  <div class="flex flex-row">
                    <div class="basis-1/2 f18" style="align-self: center;"> เลขที่ : </div>
                    <div> {{element.no}}</div>
                  </div>
                </div>
              </div>
              <hr>
            </div>
          </mat-card-content>
        </mat-card>
      </div>


      <div class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            รายชื่อตัวแทนที่ได้รับอนุญาต
          </mat-card-header>
          <mat-card-content style="padding: 30px 30px 30px 30px;">

            <!-- <div class="flex py-6">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;">
                    <button mat-raised-button color="accent" (click)="addCompanyMember($event)">
                      เพิ่มบริษัทตัวแทน
                    </button>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="flex">
              <div class="flex flex-col flex-1" style="overflow-x:scroll;">
                <dx-data-grid id="gridContainer" [dataSource]="form.shippingRelationList" [columnAutoWidth]="true"
                  [showBorders]="true" [allowColumnReordering]="true" [showBorders]="true">
                  <dxi-column alignment="center" dataField="companyName" caption="ชื่อตัวแทน">
                  </dxi-column>
                  <dxi-column alignment="center" dataField="isActive" caption="อนุมัติ">
                  </dxi-column>
                  <dxi-column alignment="center" caption="Action" cellTemplate="action"> </dxi-column>
                  <div *dxTemplate="let row of 'action'">
                    <!-- <button mat-icon-button matTooltip="แก้ไข" (click)="addCompanyMember('edit', row.data)"
                      matTooltipPosition="above">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="ลบ" (click)="deleteResItem(row.data)"
                      matTooltipPosition="above">
                      <mat-icon>delete</mat-icon>
                    </button> -->
                  </div>
                </dx-data-grid>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <mat-card style="max-height: 400px;">
        <mat-card-header class="fontTitleHeader">
          ตราบริษัท
        </mat-card-header>
        <mat-card-content class="example-headers-align">
          <!-- <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="file-uploader-block" *ngIf="dataSourceAtt.length < 1">
                  <dx-file-uploader [allowCanceling]="true" [(value)]="value" (onValueChanged)="onFileChangedBr($event)"
                    [multiple]="true" [uploadMode]="'instantly'" accept="image/gif, image/jpeg, image/png ,image/jpg"
                    [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']">
                  </dx-file-uploader>
                  <span class="note">Allowed file extensions: <span>.jpg, .jpeg, .gif, .png</span>.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="flex">
            <div class="flex-1 w-100">
              <div class="mat-elevation-z8">
                <dx-data-grid id="gridContainer" [dataSource]="dataSourceAtt" [columnAutoWidth]="true"
                  [showBorders]="true" [allowColumnReordering]="true" [showBorders]="true">
                  <dxi-column alignment="center" dataField="documentName" caption="ชื่อเอกสาร">
                  </dxi-column>
                  <dxi-column alignment="center" dataField="documentSize" caption="ขนาดของเอกสาร">
                  </dxi-column>
                  <dxi-column alignment="center" dataField="fileExtension" caption="ชนิดเอกสาร">
                  </dxi-column>
                  <dxi-column alignment="center" cellTemplate="action" caption="Action">
                  </dxi-column>
                  <div *dxTemplate="let row of 'action'">
                    <button (click)="showImg()" mat-icon-button matTooltip="รายละเอียด">
                      <mat-icon>info</mat-icon>
                    </button>
                    <button mat-icon-button matTooltip="ลบ" (click)="deleteImg()" matTooltipPosition="above">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </div>
                  <div *dxTemplate="let row of 'itemStatus'">
                  </div>
                </dx-data-grid>
              </div>
            </div>
          </div> -->
          <div class="mat-elevation-z8" style="width: 100%">
            <div class="flex gap-x row-items" style="padding: 20px;">
              <div class="flex flex10-item">
                <div class="flex-auto">
                  <h4>ภาพตราบริษัท</h4>
                  <h4>Company Picture</h4>
                </div>
              </div>
              <div class="flex flex90-item">
                <div class="flex-auto">
                  <div class="flex flex-col align-center">
                    <img src="{{form?.companyPicture}}" class="img-radius" (click)="imagePopup(form?.companyPicture)" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Exporter Information -->
      <!-- <mat-card>
        <mat-card-header style="height:50px">
          <div class="text-center">รายละเอียดกิจการผู้ส่งออก | EXPORTER INFORMATION</div>
        </mat-card-header>
        <mat-card-content style="padding: 30px 30px 30px 30px;">
          <div class="flex gap-x row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>โรงงาน</h4>
                <h4>Factory</h4>
              </div>
              <mat-form-field class="flex-auto">
                <mat-select placeholder="ไม่มีโรงงานผลิต (กรุณาระบุโรงงาน / บริษัทที่ซื้อ)"
                  [(ngModel)]="form.factoryData.factory" name="factory" #status="ngModel" [disabled]="isMREnabled">
                  <mat-option *ngFor="let item of isFactory" [value]="item.value">
                    {{item.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>รหัสโรงงาน</h4>
                <h4>Factory Code</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="รหัสโรงงาน" [(ngModel)]="form.factoryData.factoryCode"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>โรงงาน / บริษัทที่ซื้อ</h4>
                <h4>Factory / Buy From</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="โรงงาน / บริษัทที่ซื้อ" [(ngModel)]="form.factoryData.factoryName"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
              <div class="w-1/4 flex flex-col">
              </div>
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>สินค้าหลักที่ส่งออก / ประเภทธุรกิจ</h4>
                <h4>Exports / Business</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="สินค้าหลักที่ส่งออก / ประเภทธุรกิจ"
                  [(ngModel)]="form.factoryData.exportsBusiness" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>ประเทศที่ส่งออก / ขอวีซ่าไปประเทศ</h4>
                <h4>Destination Country</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="ประเทศที่ส่งออก / ขอวีซ่าไปประเทศ"
                  [(ngModel)]="form.factoryData.destinationCountry" [ngModelOptions]="{standalone: true}"
                  [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>วัตถุดิบ / สินค้าที่นำเข้า</h4>
                <h4>Raw Material / Import Goods</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="วัตถุดิบ / สินค้าที่นำเข้า" [(ngModel)]="form.factoryData.rawMaterial"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>
          <div class="flex gap-x gap-y row-items">
            <div class="flex flex70-item">
              <div class="w-1/4 flex flex-col">
                <h4>นำเข้าจากประเทศ</h4>
                <h4>Import From</h4>
              </div>
              <mat-form-field class="flex-auto">
                <input matInput placeholder="นำเข้าจากประเทศ" [(ngModel)]="form.factoryData.importFrom"
                  [ngModelOptions]="{standalone: true}" [disabled]="isMREnabled">
              </mat-form-field>
            </div>
            <div class="flex flex30-item">
            </div>
          </div>

          <hr class="gap-y" />
          <div class="flex gap-x gap-y row-items">
            <div class="col-item">
              <div class="box-subheader">
                ลายมือชื่อผู้รับมอบอำนาจลงนาม
              </div>
              <app-company-authorized-signature-grid
                [inputData]="form.authorizedSignature"></app-company-authorized-signature-grid>
            </div>
          </div>

          <hr class="gap-y" />
          <div class="flex gap-x gap-y row-items">
            <div class="col-item">
              <div class="box-subheader-2line">
                <span>ลายมือชื่อและพิมพ์ชื่อผู้มีอำนาจลงนามผูกพันบริษัท ตามระบุในหนังสือรับรองกระทรวงพาณิชย์</span>
                <span>(ข้าพเจ้าขอมอบอำนาจบุคคลผู้มีลายมือชื่อดังกล่าวข้างต้น เป็นผู้รับมอบอำนาจลงนามเอกสาร)</span>
              </div>
              <app-company-authorized-signature-grid
                [inputData]="form.authorizedPerson"></app-company-authorized-signature-grid>
            </div>
          </div>

          <hr class="gap-y" />
          <div class="flex gap-x gap-y row-items">
            <div class="col-item">
              <div class="box-subheader">
                ตราบริษัท
              </div>
              <div class="mat-elevation-z8" style="width: 100%">
                <div class="flex gap-x row-items" style="padding: 20px;">
                  <div class="flex flex10-item">
                    <div class="flex-auto">
                      <h4>ภาพตราบริษัท</h4>
                      <h4>Company Picture</h4>
                    </div>
                  </div>
                  <div class="flex flex90-item">
                    <div class="flex-auto">
                      <div class="flex flex-col align-center">
                        <img src="{{form?.companyPicture}}" class="img-radius"
                          (click)="imagePopup(form?.companyPicture)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card> -->
    </div>
  </div>

  <div>
    <div class="flex flex-row justify-center mt-3 gap-4">
      <button class="set-btn-unlimited flex-none " mat-raised-button color="accent" (click)="save()">บันทึก</button>
      <button class="set-btn-unlimited flex-none " mat-raised-button color="accent"
        (click)="backClicked()">ย้อนกลับ</button>
    </div>
  </div>
</div>