<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">การชำระเงิน | Payment</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="form">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="flex flex-col py-5 gap-4 ">
      <div>
        <div class="flex flex-row">
          <div class="basis-1/3">ประเภทการชำระเงิน : </div>
          <div class="basis-2/3">
            <mat-form-field class="w100">
              <mat-select [(ngModel)]="form.paymentType" (selectionChange)="selectionPaymentType(form.paymentType)"
                name="fee">
                <mat-option *ngFor="let pay of paymentTypeList" [value]="pay.code">
                  {{pay.name}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="form.paymentType == 2">
        <div class="flex flex-row">
          <div class="basis-1/3">บัญชี (ผู้จ่าย) : </div>
          <div class="basis-2/3">
            <mat-form-field class="w100">
              <mat-select [(ngModel)]="form.bankPayer" (selectionChange)="selectionPaymentType(form.bankPayer)"
                name="fee">
                <mat-option *ngFor="let bank of bankAccList" [value]="bank">
                  {{bank.accName}} : {{bank.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="form.paymentType == 2">
        <div class="flex flex-row">
          <div class="basis-1/3">บัญชี (ผู้รับ) : </div>
          <div class="basis-2/3">
            <mat-form-field class="w100">
              <mat-select [(ngModel)]="form.bankReceriver" (selectionChange)="selectionPaymentType(form.bankReceriver)"
                name="fee">
                <mat-option *ngFor="let bank of bankAccCodeList" [value]="bank">
                  {{ bank.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="form" color="accent">เพิ่มรายละเอียด</button>
    <!-- <button mat-raised-button [mat-dialog-close]>ยกเลิก</button> -->
  </mat-dialog-actions>