import { AppMasterService } from "./../../../service/app/app-master.service";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { DxDataGridComponent } from "devextreme-angular";
import { CreateO2ModalComponent } from "../create-o2-modal/create-o2-modal.component";
import { AppO2Service } from "app/business/service/app/app-o2.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { Router } from "@angular/router";
import { AuthService } from "app/core/auth/auth.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import moment from "moment";
import { RenewO2InquiryComponent } from "../renew-o2-inquiry/renew-o2-inquiry.component";

@Component({
  selector: "app-o-two-inquiry",
  templateUrl: "./o-two-inquiry.component.html",
  styleUrls: ["./o-two-inquiry.component.css"],
})
export class OTwoInquiryComponent implements OnInit {
  searchForm: any = {};
  form: any = {};
  searchString: string;
  productList: any = [];
  statusList: any = [];
  dataSource = new MatTableDataSource<any>();
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  displayedColumns: string[] = [
    "index",
    "docNo",
    "issueDate",
    "expireDate",
    "productName",
    "statusCode",
    "action",
  ];
  url: any;
  buttonList: any[] = [];
  timer: any;
  count: any = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private appO2Service: AppO2Service,
    private confirmService: ConfirmDialogService,
    private router: Router,
    private _authService: AuthService,
    private appMenuService: AppMenuService
  ) {
    if (this.userProfile.gId != "UG002") {
      //Admin/BOT/Surveyor
      this.displayedColumns.splice(1, 0, "companyName");
    }
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode,
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(
      formData
    );
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    await this.getRouter();
    this.searchForm = {};
    this.dataSource.paginator = this.paginator;

    this.loadData();
    this.searchForm.nameSearch = "";
  }

  async loadData() {
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: 0,
      isActive: "Y",
    };
    this.productList = await this.appMasterService.getProduct(formProduct);
    console.log("product detail: ", this.productList);
    this.productList = this.productList.filter(
      (f) => f.productId == 3 || f.productId == 10
    );

    const formStatus = {
      statusId: 0,
      isActive: "Y",
    };
    this.statusList = await this.appMasterService.getStatus(formStatus);

    this.loadDataSource();
  }

  async loadDataSource() {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    const formData = {
      UID: Number(this.userProfile.uId),
      keyword: this.searchForm.keyword ?? null,
      docNo: this.searchForm.docNo ?? null,
      issueDate: this.searchForm.issueDate ?? null,
      expireDate: this.searchForm.expireDate ?? null,
      productId: this.searchForm.productId ?? null,
      status: this.searchForm.status ?? null,
      pagination: {
        Skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appO2Service.o2List(formData).then((res: any) => {
      if (res === 'error') {
        return;
      } else {
        if (res != null) {
          let a = res.list.map((x) => {
            moment.locale("th"); // set default locale to Thai
            x.createDateThai = moment(x.createDate).format(
              "YYYY-MM-DD HH:mm"
            );

            x.action = "";

            return x;
          });

          this.dataSource.data = a;
          this.count = res.totalCount;
        } else {
          this.dataSource.data = [];
          this.count = 0;
        }
      }
    });

    this.dataSource.paginator.length = this.count;
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.searchForm.keyword = event;
      this.dataSource.paginator = this.paginator;
      this.loadDataSource();
    }, 500); // Specify the desired delay in milliseconds
  }

  async deleteItem(e) {
    // -สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "",
      content: "คุณต้องการลบใบอนุญาต อ.2 นี้หรือไม่",
    });
    // ;
    if (alertConfirm) {
      const updateForm = {
        ID: e.id,
        status: e.status,
        isActive: "N",
        companyCode: this.userProfile.companyCode,
      };
      this.appO2Service.updateStatusO2(updateForm).then((res) => {
        this.loadDataSource();
      });
    }
  }

  createO2(e) {
    const dialogRef = this.dialog.open(CreateO2ModalComponent, {
      disableClose: true,
      maxWidth: "none",
      width: "65vw",
      data: {
        page: "o2",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('The dialog was closed');
      // this.animal = result;
    });
  }
  stopPropagation(event) {
    event.stopPropagation();
  }
  // menuOpen() {}
  menuClosed() {
    console.log("menuClosed @configbug");
  }

  searchOption() {
    this.searchForm.keyword = "";
    this.searchString = "";

    this.loadDataSource();
  }

  clearOption() {
    this.searchForm = {};
    this.searchString = "";
    this.searchOption();
  }

  async pageChanged(e) {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    this.searchForm.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };

    const formData = {
      UID: Number(this.userProfile.uId),
      keyword: this.searchForm.keyword ?? null,
      docNo: this.searchForm.docNo ?? null,
      issueDate: this.searchForm.issueDate ?? null,
      expireDate: this.searchForm.expireDate ?? null,
      productId: this.searchForm.productId ?? null,
      status: this.searchForm.status ?? null,
      pagination: {
        Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
        /*(!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),*/
        Take: !e.pageSize ? 10 : e.pageSize, //!this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appO2Service.o2List(formData).then((res: any) => {
      if (res === 'error') {
        return;
      } else {
        if (res != null) {
          let a = res.list.map((x) => {
            moment.locale("th"); // set default locale to Thai
            x.createDateThai = moment(x.createDate).format(
              "YYYY-MM-DD HH:mm"
            );

            x.action = "";

            return x;
          });

          this.dataSource.data = a;
          this.count = res.totalCount;
        } else {
          this.dataSource.data = [];
          this.count = 0;
        }
      }
    });

    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = e.pageIndex;
  }

  async deleteDoc(e) {
    // console.log(e);
    // -สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "ต้องการสิ้นสุดงานหรือไม่",
      content: "ยืนยันการสิ้นสุดงาน",
    });
    // ;
    if (alertConfirm) {
      const updateForm = {
        ID: e.id,
        status: e.status,
        isActive: "N",
        companyCode: this.userProfile.companyCode,
      };
      this.appO2Service.updateStatusO2(updateForm).then((res) => {
        this.searchOption();
      });
    }
  }

  save() { }

  async expireO2(e) {
    console.log(e);
    // -สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "ต้องการสิ้นสุดงานหรือไม่",
      content: "ยืนยันการสิ้นสุดงาน",
    });
    // ;
    if (alertConfirm) {
      const updateForm = {
        ID: e.id,
        statusDoc: 11,
        isActive: "Y",
        companyCode: this.userProfile.companyCode,
        docNo: e.docNo,
      };
      this.appO2Service.updateStatusO2(updateForm).then((res) => {
        this.searchOption();
      });
    }
  }

  renewO2(e) {
    console.log(e);
    this.dialog.open(RenewO2InquiryComponent, {
      width: '75vw',
      disableClose: true,
      data: {
        item: e,
        isStaff: false,
        isDetail: false
      }
    });
  }

  onRefresh() {
    this.clearOption();
  }
}
