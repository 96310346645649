import { filter } from "rxjs";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
  MatDialogConfig,
} from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AppUserService } from "app/business/service/app/app-user.service";
import _ from "lodash";
import { AlertMessageService } from "../../../../@bundle/component/alert-message/alert-message.service";
import { AppMasterService } from "../../service/app/app-master.service";
import { ConfirmPopupComponent } from "../confirm-popup/confirm-popup.component";
import { ImageModalComponent } from "../ms13/Image-modal/Image-modal.component";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import moment from "moment";

@Component({
  selector: "app-blacklist-modal",
  templateUrl: "./blacklist-modal.component.html",
  styleUrls: ["./blacklist-modal.component.scss"],
})
export class BlacklistModalComponent implements OnInit {
  form: any = {
    companyNameTH: "",
    companyNameEN: "",
    contact: "",
    email: "",
    phone: "",
    address: [],
    lockStatus: "",
    grade: "",
    attachments: {},
    history: {},
    id: "",
    companyId: "",
    factoryName: "",
    endDate : "",
    startDate : ""
  };
  addrOpt: any;
  bankList: any;
  displayedColumns: string[] = ["date", "status", "reason"];
  dataSource2 = new MatTableDataSource<any>();
  editItem: boolean;
  attachTypeList: any[] = [];
  dataList: any[] = [];
  myDate = new Date();
  file: any;
  value: any[] = [];
  reasonList: any[] = [];
  checkEndDate: boolean = true;
  checkStartDate: boolean = true;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<BlacklistModalComponent>,
    public dialog2: MatDialogRef<ConfirmPopupComponent>,
    private dialog: MatDialog,
    private _liveAnnouncer: LiveAnnouncer,
    private appMasterService: AppMasterService,
    private confirmService: ConfirmDialogService,
    private appUserService: AppUserService,
    private alertService: AlertMessageService
  ) {
    this.form.id = data.id;
    this.form.companyId = data.cid;
    this.form.comId = data.comId;
    this.form.companyNameTH = data.companyNameTH;
    this.form.companyNameEN = data.companyNameEN;
    this.form.contact = data.contact;
    this.form.email = data.email;
    this.form.phone = data.phone;
    this.form.address.push(data.companyAddress);
    this.form.lockStatus = data.lockStatus;

    this.addrOpt = data.companyAddress;
    this.form.history = data.history;
    this.dataSource2.paginator = this.paginator;
    this.dataSource2.sort = this.sort;
    this.dataSource2.data = this.form.history;

    const mainAddressList = this.addrOpt.filter(
      (e) => e.companyAddressType === "M"
    );
    if (mainAddressList !== undefined && mainAddressList.length > 0) {
      const preValue: any = _.first(mainAddressList);
      this.form.selectAddress = preValue.addressId;
    } else if (this.addrOpt.length > 0) {
      const preValue: any = _.first(this.addrOpt);
      this.form.selectAddress = preValue.addressId;
    } else {
      this.form.selectAddress = "";
    }
  }
  ngOnInit(): void {
    this.appUserService.getAllReasonLog(this.form.companyId).then((res) => {
      if (res === "error") {
        this.reasonList = [];
      } else {
        this.reasonList = res.filter((f) => f.logType == "Y");

        if (this.reasonList.length === 0) {
          this.reasonList.unshift({ logId: "", logReason: "ไม่พบข้อมูล" });
          this.form.refBlacklistId = "";
        } else if (this.reasonList.length === 1) {
          const preValue = _.first(this.reasonList);
          this.form.refBlacklistId = preValue.logId;
        } else {
          var checkAll = this.reasonList.find(
            (e) => e.logReason === "กรุณาเลือก"
          );

          if (checkAll === undefined) {
            this.reasonList.unshift({ logId: "", logReason: "กรุณาเลือก" });
            this.form.refBlacklistId = "";
          }
        }
      }
    });
  }

  ngAfterViewInit() {
    this.dataSource2.paginator = this.paginator;
    this.dataSource2.sort = this.sort;
  }
  datecheck(e: MatDatepickerInputEvent<Date>) {
    if (
      moment(this.form.startDate).format("YYYY-MM-DD") >
      moment(this.form.endDate).format("YYYY-MM-DD")
    ) {
      this.form.endDate = {};
      this.checkEndDate = false;
    }
  }

  endDateCheck(e) {
    if (
      moment(this.form.startDate).format("YYYY-MM-DD") ==
        moment(this.form.endDate).format("YYYY-MM-DD") ||
      moment(this.form.startDate).format("YYYY-MM-DD") <
        moment(this.form.endDate).format("YYYY-MM-DD")
    ) {
      this.checkEndDate = true;
    }
  }
  save() {
    if (!this.form.lockReason && this.form.lockStatus === "Y") {
      this.alertService.open({
        message: "กรุณาระบุเหตุผลที่ต้องการล็อค",
        title: "กรุณาตรวจสอบข้อมูลให้ครบถ้วน",
      });
      return false;
    } else {
      if (this.form.lockStatus === "N") {
        if (this.form.refBlacklistId === "" || this.form.gradeReason === 0) {
          this.alertService.open({
            message: "กรุณาเลือกรายการที่จะปลดล็อค",
            title: "",
          });
          return false;
        }
      }

      this.confirmService
        .open({
          header: "ยืนยันทำรายการ",
          content: "ต้องการบันทึกข้อมูล",
        })
        .then((res) => {
          if (res == true) {
            this.dialogRef.close({ data: this.form });
          } else {
            return;
          }
        });
    }
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  pageChanged($event) {}

  Removing(): void {
    this.form.attachments = {};
  }

  ReasonLockPopup(e) {
    const id = { comId: e.id };
    this.appUserService.getReasonLog(id).then((res: any) => {
      if (res) {
        const ReasonPopup = this.dialog.open(ConfirmPopupComponent, {
          disableClose: false,
          data: {
            header: res.reasonLock,
            button: "close",
          },
          maxWidth: "none",
        });

        ReasonPopup.afterClosed().subscribe((res) => {});
      } else {
        return;
      }
    });
  }

  ReasonGradePopup(e) {
    const id = { comId: e.id };
    this.appUserService.getReasonLog(id).then((res: any) => {
      if (res) {
        const ReasonPopup = this.dialog.open(ConfirmPopupComponent, {
          disableClose: false,
          data: {
            header: res.reasonGrade,
            button: "close",
          },
          maxWidth: "none",
        });

        ReasonPopup.afterClosed().subscribe((res) => {});
      } else {
        return;
      }
    });
  }
}
