<div style="padding: 20px 50px 20px 50px"> 
  <mat-card>
    <div class="flex flex-col">
      <div
        class="pt-10 basis-1/4"
        style="display: flex; flex-flow: row; width: 100%"
      >
        <div
          style="
            display: flex;
            flex-flow: column;
            width: 30%;
            align-items: star;
          "
        >
          <mat-label class="pl-8" style="display: flex; align-items: start"
            >กลุ่มผู้ใช้งาน</mat-label
          >
          <mat-label class="pl-8" style="display: flex; align-items: center"
            >Group name</mat-label
          >
        </div>

        <div
          class="basis-3/4"
          style="display: flex; flex-flow: row; width: 100%"
        >
          <mat-form-field class="pl-4" style="height: auto; width: 70%">
            <input
              autocomplete="off"
              type="text"
              matInput
              #input
              [(ngModel)]="form.GroupName"
              name="GroupName"
              #GroupName="ngModel"
            />
          </mat-form-field>
        </div>
      </div>
      <div
        class="pt-4 basis-1/4"
        style="display: flex; flex-flow: row; width: 100%"
      >
        <div
          style="
            display: flex;
            flex-flow: column;
            width: 30%;
            align-items: star;
          "
        >
          <mat-label class="pl-8" style="display: flex; align-items: start"
            >บริษัท</mat-label
          >
          <mat-label class="pl-8" style="display: flex; align-items: center"
            >Company</mat-label
          >
        </div>

        <div
          class="basis-3/4"
          style="display: flex; flex-flow: row; width: 100%"
        >
          <mat-form-field class="pl-4" style="height: auto; width: 70%">
            <mat-select [(ngModel)]="form.GroupCompanyId" required="true">
                <mat-option *ngFor="let company of dataCompany" [value] = "company.companyId">
                    {{company.companyName}}
                </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div
        class="pt-4 basis-1/4"
        style="display: flex; flex-flow: row; width: 100%"
      >
        <div
          style="
            display: flex;
            flex-flow: column;
            width: 30%;
            align-items: star;
          "
        >
          <mat-label class="pl-8" style="display: flex; align-items: start"
            >Admin group</mat-label
          >
        </div>

        <div
          class="basis-3/4"
          style="display: flex; flex-flow: row; width: 100%"
        >
          <div class="pl-4">
            <mat-radio-group
              aria-labelledby="radio-group-label"
              class="radio-group"
              [(ngModel)]="form.RadioBox"
              name="RadioBox"
              #RadioBox="ngModel"
              required="true"
            >
              <mat-radio-button class="radio-button" value="Y"
                >Y</mat-radio-button
              >
              <mat-radio-button class="radio-button" value="N"
                >N</mat-radio-button
              >
            </mat-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col">
      <fieldset class="m-8 border-2 border-solid rounded-lg">
        <legend class="ml-2.5 pr-2.5">Menu (Control at group level)</legend>
        <div class="px-10">
          <!-- ตรวจเอกสาร -->
          <section>
            <span>
              <mat-checkbox
                [checked]="doc.completed"
                (change)="setDocAll($event.checked)"
                (click)="clickDoc()"
              >
                {{ doc.name }}
              </mat-checkbox>
            </span>
            <span>
              <ul>
                <li *ngFor="let subtask of doc.subdoc" class="pl-10">
                  <mat-checkbox
                    [(ngModel)]="subtask.completed"
                    (click)="clickDoc()"
                  >
                    {{ subtask.name }}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
          </section>
          <!-- ประวัติและการแก้ไข -->
          <section>
            <span>
              <mat-checkbox
                [checked]="his.completed"
                (change)="setHisAll($event.checked)"
                (click)="clickHis()"
              >
                {{ his.name }}
              </mat-checkbox>
            </span>
            <span>
              <ul>
                <li *ngFor="let subhis of his.subhis" class="pl-10">
                  <mat-checkbox
                    [(ngModel)]="subhis.completed"
                    (click)="clickHis()"
                  >
                    {{ subhis.name }}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
          </section>
          <!-- รายงาน -->
          <section>
            <span>
              <mat-checkbox
                [checked]="report.completed"
                (change)="setReportAll($event.checked)"
                (click)="clickReport()"
              >
                {{ report.name }}
              </mat-checkbox>
            </span>
            <span>
              <ul>
                <li *ngFor="let subreport of report.subreport" class="pl-10">
                  <mat-checkbox
                    [(ngModel)]="subreport.completed"
                    (click)="clickReport()"
                  >
                    {{ subreport.name }}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
          </section>
          <!-- Request Payment -->
          <section>
            <span>
              <mat-checkbox
              [(ngModel)]="reqPay.completed"
              >
                {{ reqPay.name }}
              </mat-checkbox>
            </span>
          </section>
          <!-- DFT -->
          <section>
            <span>
              <mat-checkbox
                [checked]="DFT.completed"
                (change)="setDFTAll($event.checked)"
                (click)="clickDFT()"
              >
                {{ DFT.name }}
              </mat-checkbox>
            </span>
            <span>
              <ul>
                <li *ngFor="let subDFT of DFT.subDFT" class="pl-10">
                  <mat-checkbox
                    [(ngModel)]="subDFT.completed"
                    (click)="clickDFT()"
                  >
                    {{ subDFT.name }}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
          </section>
          <!-- Tracking -->
          <section>
            <span>
              <mat-checkbox
                [checked]="tracking.completed"
                (change)="setTrackingAll($event.checked)"
                (click)="clickTracking()"
              >
                {{ tracking.name }}
              </mat-checkbox>
            </span>
            <span>
              <ul>
                <li
                  *ngFor="let subtracking of tracking.subtracking"
                  class="pl-10"
                >
                  <mat-checkbox
                    [(ngModel)]="subtracking.completed"
                    (click)="clickTracking()"
                  >
                    {{ subtracking.name }}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
          </section>
          <!-- Configuration -->
          <section>
            <span>
              <mat-checkbox
                [checked]="config.completed"
                (change)="setConfigAll($event.checked)"
                (click)="clickConfig()"
              >
                {{ config.name }}
              </mat-checkbox>
            </span>
            <span>
              <ul>
                <li *ngFor="let subconfig of config.subconfig" class="pl-10">
                  <mat-checkbox
                    [(ngModel)]="subconfig.completed"
                    (click)="clickConfig()"
                  >
                    {{ subconfig.name }}
                  </mat-checkbox>
                </li>
              </ul>
            </span>
          </section>
        </div>
      </fieldset>
    </div>
    <div class="p-4 flex flex-col items-center p10">
        <div class="basis-1/2 f18">
          <button mat-raised-button style="width: 100px;" color="accent" (click)="saveData(groupId)" mat-button>
            Save
          </button>
          &nbsp;&nbsp;
          <button mat-raised-button style="width: 100px;" color="accent" (click)="loadData(groupId)" mat-button>
            Reset
          </button>
          &nbsp;&nbsp;
          <button mat-raised-button style="width: 100px;" color="accent" mat-button routerLink="/page/group/grouplist">
            Back
          </button>
        </div>
    </div>
  </mat-card>
</div>
