import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-config-bank-popup',
  templateUrl: './config-bank-popup.component.html',
  styleUrls: ['./config-bank-popup.component.scss']
})
export class ConfigBankPopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  accountList: any;
  FormCheckInputbank : FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ConfigBankPopupComponent>,
    private appBankService: AppBankService,
    private dialog: MatDialog,
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
    }
    console.log(data)
  }
  ngOnInit(): void {
    this.checkFormGroup();
    const data = {
      
    };
    this.appBankService.bankCode(data).then((res: any) => {
      if (res === "error") {
        return;
      } else {
        this.bankList = res;
        console.log(res)
      }
    });

    this.accountList = [{ typeName: "Payer", typeCode: "P" }, { typeName: "Receiver", typeCode: "R" }];  
  }

  save() {
    console.log(this.form)
    const data = {
      "bankAccountID": this.form.id,
      "bankCode": this.form.bankCode,
      "bankAccountNo": this.form.bankAccountNo,
      "bankAccountName": this.form.bankAccountName,
      "bankBranch": this.form.bankBranch,
      "accType": this.form.accType,
      "accTaxNo": this.form.accTaxNo,
      "isBotAcc": this.form.isBotAcc ?? '1',
      "isActive": this.form.isActive ?? 'Y'
    };
    console.log("save data: ", data);
    this.appBankService.saveBankAccount(data).then((res: any) => {
      if (res === "error") {
        this.endPopup("บันทึกข้อมูลไม่สำเร็จ");
      } else {
        this.endPopup("บันทึกข้อมูลสำเร็จ");
      }
    });

  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      width: '30vw',
      data: {
        header: e,
        button:'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
 }
 checkFormGroup (){
  this.FormCheckInputbank = new FormGroup({
    accTaxNo: new FormControl('', Validators.required),
    bankCode : new FormControl(''),
    bankAccountNo: new FormControl('', Validators.required),
    bankAccountName : new FormControl('', Validators.required),
    bankBranch : new FormControl('', Validators.required),
    accType : new FormControl('', Validators.required),
  });
  }

}
