<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
    <div style="display: flex;flex-flow: column;" class="pl-20 pr-20">
        <div class="menu">
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 30%;">
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput matInput #input [(ngModel)]="searchForm.search"
                            name="search" #search="ngModel" (keyup)="applyFilter($event)"> <mat-icon
                            style="font-size: 30px;">search</mat-icon>
                    </mat-form-field>
                </div>


                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu" (menuClosed)="menuClosed()">
                    <div style="display: flex;align-items: center;">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>



                <mat-menu #menu="matMenu" xPosition="before">
                    <div style="display: flex;flex-flow: column;gap: 15px;justify-content: start;" class="pl-2 pr-2"
                        (click)="stopPropagation($event)">
                        <div style="display: flex;flex-flow: column;gap: 15px;" class="pl-2 pr-2">
                            <div class="mat-button">
                                <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                                    <mat-label>Username</mat-label>
                                    <input autocomplete="off" type="text" matInput #input
                                        [(ngModel)]="searchForm.searchUsername" name="searchUsername"
                                        #searchName="ngModel">
                                </mat-form-field> <br />
                            </div>
                            <div class="mat-button">
                                <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                                    <mat-label>Firstname</mat-label>
                                    <input autocomplete="off" type="text" matInput #input
                                        [(ngModel)]="searchForm.searchFirstname" name="searchFirstname"
                                        #searchName="ngModel">
                                </mat-form-field>
                            </div>
                            <div class="mat-button">
                                <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                                    <mat-label>Lastname</mat-label>
                                    <input autocomplete="off" type="text" matInput #input
                                        [(ngModel)]="searchForm.searchLirstname" name="searchLirstname"
                                        #searchName="ngModel">
                                </mat-form-field>
                            </div>
                            <div class="mat-button">
                                <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                                    <mat-label>Approved</mat-label>
                                    <input autocomplete="off" type="text" matInput #input
                                        [(ngModel)]="searchForm.searchApproved" name="searchApproved"
                                        #searchName="ngModel">
                                </mat-form-field>
                            </div>
                            <div style="display:flex;flex-flow:row; justify-content:end;">

                                <button mat-raised-button color="accent" (click)="searchOption()" style="flex:0 100%;">
                                    ค้นหา
                                </button>

                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>

    <div class="mat-elevation-z8 ">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange">
            <ng-container matColumnDef="userName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Username</th>
                <td mat-cell *matCellDef="let element" style="padding-left: 80px;"> {{element.userName}} </td>
            </ng-container>

            <ng-container matColumnDef="firstName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Firstname</th>
                <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.firstName}} </td>
            </ng-container>

            <ng-container matColumnDef="lastName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">LastName</th>
                <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.lastName}} </td>
            </ng-container>

            <ng-container matColumnDef="approved">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Approved</th>
                <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.approved}} </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;">
                    <button mat-icon-button matTooltip="แก้ไข">
                        <mat-icon>assignment</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>


</div>