import { AppMasterService } from "app/business/service/app/app-master.service";
import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import {
  Component,
  EventEmitter,
  Inject,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

const DEFAULT_DURATION = 300;
@Component({
  selector: "app-surveyor-audit-popup",
  templateUrl: "./surveyor-audit-popup.component.html",
  styleUrls: ["./surveyor-audit-popup.component.css"],
  animations: [
    trigger("collapse", [
      state("false", style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state("true", style({ height: "0", visibility: "hidden" })),
      transition("false => true", animate(DEFAULT_DURATION + "ms ease-in")),
      transition("true => false", animate(DEFAULT_DURATION + "ms ease-out")),
    ]),
  ],
})
export class SurveyorAuditPopupComponent implements OnInit {
  productList: any = [];
  surveyorResults: any = {};
  surveyorList: any = [];
  collapsed = false;
  datasource: any = [];
  qtyUnitList: any = [];
  weightList: any = [];
  no: number = 0;
  local: any;

  constructor(
    public dialogRef: MatDialogRef<SurveyorAuditPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private appMasterService: AppMasterService
  ) {
    this.local = data.MS13Detail;
  }

  ngOnInit() {
    console.log("data", this.data);
    this.productList = this.data.itemDetail.map((m, index) => {
      return {
        ...m,
        seq: index + 1,
        surveyQty: m.quantity,
        surveyNetWeight: m.netWeight,
        surveyNetWeightPerUnit: m.netWeightPerUnit,
        surveyGrossWeightPerUnit: m.grossWeightUnit,
      };
    });
    var qtyUnit = Number(this.local.quantityUnit);
    this.appMasterService.getQtyUnit({ ID: "" }).then((res) => {
      this.qtyUnitList = res;
      this.surveyorResults.surveyQtyUnit = this.qtyUnitList.find(
        (f) => (f.id = qtyUnit)
      ).name;
    });

    this.appMasterService.getListWeight({ isActive: "" }).then((res) => {
      this.weightList = res;
      this.surveyorResults.surveyNetWeightUnit = this.weightList.find(
        (f) => f.code == this.local.netWeightUnit
      ).code;
    });
    this.formInit();
  }

  formInit() {
    this.surveyorResults.goodsSeq = 1;
    this.surveyorResults.goodsDescription = this.productList.find(
      (f) => f.seq == 1
    )?.goodsDescription;

    this.surveyorResults.itemId = this.productList.find(
      (f) => f.seq == 1
    )?.itemId;
    this.surveyorResults.checkSeq = 1;
    this.surveyorResults.checkResult =
      "มาตรฐานเทียบได้ เท่ากับ มาตรฐานที่ทางราชการกำหนด";
    this.surveyorResults.checkQty = "กระสอบที่บรรจุถูกต้อง";
    this.surveyorResults.checkPackageResult = "การเย็บปากกระสอบ เรียบร้อย";
    this.surveyorResults.surveyQty = this.local.quantity;
    this.surveyorResults.surveyNetWeight = this.local.netWeight;
    this.surveyorResults.surveyNetWeightPerUnit = this.local.netWeightPerUnit;
    this.surveyorResults.surveyGrossWeightPerUnit =
      this.local.grossWeightPerUnit;
  }

  loadData() {}

  goodsChange(val) {
    var e = Number(val);
    this.surveyorResults.goodsDescription = this.datasource.find(
      (f) => f.seq == e
    )?.goodsDescription;
    this.surveyorResults.surveyQty = this.datasource.find(
      (f) => f.seq == e
    )?.quantity;
    this.surveyorResults.surveyNetWeight = this.datasource.find(
      (f) => f.seq == e
    )?.netWeight;
    this.surveyorResults.surveyNetWeightPerUnit = this.datasource.find(
      (f) => f.seq == e
    )?.netWeightPerUnit;
    this.surveyorResults.surveyGrossWeightPerUnit = this.datasource.find(
      (f) => f.seq == e
    )?.grossWeightPerUnit;
    this.surveyorResults.itemId = this.productList.find(
      (f) => f.seq == e
    )?.itemId;
  }
  toggle() {
    this.collapsed = !this.collapsed;
  }

  expand() {
    this.collapsed = false;
  }

  collapse() {
    this.collapsed = true;
  }

  reset() {}

  save() {
    this.dialogRef.close({
      data: this.surveyorResults,
      table: this.datasource,
    });
  }

  addResult() {
    const result = {
      seq: this.no + 1,
      itemNo: this.no + 1,
      goodsDescription: this.surveyorResults.goodsDescription,
      surveyQty:
        this.surveyorResults.surveyQty +
        " " +
        this.surveyorResults.surveyQtyUnit,
      surveyNetWeightPerUnit: this.surveyorResults.surveyNetWeightPerUnit,
      surveyNetWeight: this.surveyorResults.surveyNetWeight,
      surveyGrossWeightPerUnit: this.surveyorResults.grossWeightUnit,
    };
    this.datasource.push(result);
    this.no += 1;
  }
}
