<mat-card>
 <mat-card-header  class="fontTitleHeader" >
    <div class="flex justify-between" style="width: 100%;">
     <div  class="fontTitleModal" >
{{data.title}}
     </div> 
      <!-- <div> เอกสารประกอบแนบคำพิจารณา </div> -->
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="flex flex-col">
      <div class="flex flex-row">
        <div class="basis-full p10">
          <!-- <img with="100" height="100" src="{{data.path}}"> -->
          <img with="100" height="100" src="{{data.path}}">
        </div>
      </div>
    </div>
  </mat-card-content>
  <div class="flex flex-col items-center p10">
    <div class="basis-1/2 f18">
      <button mat-raised-button style="width: 100px;" color="accent" [mat-dialog-close]="data">
        ปิด
      </button>
    </div>
  </div>
</mat-card>