<div style="display: flex;flex-flow: column;height: 100%;gap: 30px; justify-content: stretch;">
    <div style="display: flex;flex-flow: column;margin-top: 30px;" class="pl-10 pr-10">
        <div class="menu flex flex-row">
            <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
                <button *ngIf="(checkButton('BT145'))" class="btn" mat-raised-button color="accent"
                    (click)="createPayment()">
                    Create Payment
                </button>
                <button mat-raised-button style="width: 70px;font-size: 1.5em;" matTooltip="รีเฟรชข้อมูล"
                    matTooltipPosition="above" (click)="loadData()">
                    <i class="ti ti-refresh"></i>
                </button>
            </div>
            <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
                <div class="mat-button" style="gap: 10px;flex: 0 40%;">
                    <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
                        <input autocomplete="off" type="text" matInput placeholder="Reference Number / เลขที่มส.24"
                            matInput #input [(ngModel)]="searchString" name="searchText" #searchText="ngModel"
                            (keyup)="applyFilter($event)" (focus)="setupFilter('tempName')">
                        <button matSuffix mat-icon-button aria-label="ค้นหา">
                            <mat-icon>search</mat-icon>
                        </button>
                    </mat-form-field>
                </div>
                <button mat-raised-button color="accent" [matMenuTriggerFor]="menu">
                    <div class="flex items-center">
                        <span>เงื่อนไขการค้นหา</span>
                        <mat-icon>expand_more</mat-icon>
                    </div>
                </button>
                <mat-menu #menu="matMenu" xPosition="before" class="my-menu">
                    <div class="pl-2 pr-2" (click)="stopPropagation($event)">
                        <div class="pl-2 pr-2">
                            <div class="flex flex-row py-2">
                                <div class="basis-full">
                                    <mat-form-field class="my-form-field formm w100">
                                        <mat-label>เลขที่ใบคำขอชำระเงิน | Reference No.</mat-label>
                                        <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                                            [(ngModel)]="searchForm.refNo" name="refNo" #refNo="ngModel">
                                    </mat-form-field>
                                </div>
                            </div>
                            <!-- <div class="flex flex-row py-2">
                                <div class="basis-full">
                                    <mat-form-field class="my-form-field formm w100">
                                        <mat-label>เลขที่ใบรับรอง มส.24 </mat-label>
                                        <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                                            [(ngModel)]="searchForm.docNo" name="docNo" #docNo="ngModel">
                                    </mat-form-field>
                                </div>
                            </div> -->
                            <div class="flex flex-row  py-2">
                                <div class="basis-full">
                                    <mat-form-field class="my-form-field formm w100">
                                        <mat-label>สถานะการชำระเงิน | Payment Status</mat-label>
                                        <mat-select [(ngModel)]="searchForm.status" name="paymentType"
                                            #paymentType="ngModel">
                                            <mat-option *ngFor="let status of statusList" [value]="status.id">
                                                {{status.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex-row  py-2">
                                <div class="mat-button">
                                    <mat-form-field class="my-form-field formm " fxFlex="50">
                                        <mat-label>วันที่ยื่นคำร้อง</mat-label>
                                        <input autocomplete="off" placeholder="วันที่ยื่นคำร้อง" matInput
                                            [matDatepicker]="StartDate" [(ngModel)]="searchForm.issueDate"
                                            name="issueDate" #issueDate="ngModel" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="StartDate"></mat-datepicker-toggle>
                                        <mat-datepicker #StartDate></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="my-form-field formm " style="padding-left: 5px;" fxFlex="50">
                                        <mat-label>วันหมดอายุ</mat-label>
                                        <input autocomplete="off" placeholder="วันหมดอายุ" matInput
                                            [matDatepicker]="exDates" [(ngModel)]="searchForm.expireDate"
                                            name="expireDate" #expireDate="ngModel" readonly>
                                        <mat-datepicker-toggle matSuffix [for]="exDates"></mat-datepicker-toggle>
                                        <mat-datepicker #exDates></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="flex justify-end formm py-2">
                                <div>
                                    <button mat-raised-button (click)="clearOption()"
                                        style="flex:0 50%; margin-right: 10px;">
                                        เคลียร์
                                    </button>
                                    <button mat-raised-button color="accent" (click)="loadData()" style="flex:0 50%;">
                                        ค้นหา
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="mat-elevation-z8 ">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="refNo">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">เลขที่คำขอชำระเงิน </th>
                <td mat-cell *matCellDef="let element" style="text-align: left;"> {{element.refNo}} </td>
            </ng-container>
            <ng-container matColumnDef="ms24">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;"> เลขที่มส.24 </th>
                <td mat-cell *matCellDef="let element" style="text-align: left; width: 165px;"> {{element.ms24}} </td>
            </ng-container>
            <ng-container matColumnDef="companyName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">ชื่อบริษัท</th>
                <td mat-cell *matCellDef="let element"> {{element.companyName}} </td>
            </ng-container>
            <ng-container matColumnDef="reqDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;"> วันที่เวลาที่ยื่นคำร้อง
                </th>
                <td mat-cell *matCellDef="let element"> {{element.reqDate | date:'yyyy-MM-dd HH:mm'}} </td>
            </ng-container>
            <ng-container matColumnDef="paymentType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">Payment Type</th>
                <td mat-cell *matCellDef="let element"> {{element.paymentTypeName}} </td>
            </ng-container>
            <ng-container matColumnDef="paymentAddress">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">ที่อยู่ใบเสร็จ</th>
                <td mat-cell *matCellDef="let element"> {{element.paymentAddress}} </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: left;">
                    Status
                </th>
                <td mat-cell *matCellDef="let element">
                    {{element.statusName}}
                    <p *ngIf="element.status == 'N' && (element.payRespons != null && element.payRespons != '')"
                        style="color: red;">
                        *{{element.payRespons}}
                    </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="action" style="width: 180px;">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;width: 180px;"> Action </th>
                <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;width: 180px;">
                    <div class="flex flex-row" style="justify-content: left;">
                        <div>
                            <button *ngIf="checkButton('BT146') && element.stage == 2 || element.stage == 1"
                                mat-icon-button matTooltip="แก้ไข" (click)="editPayment(element)">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                        </div>
                        <div>
                            <button *ngIf="checkButton('BT147')" mat-icon-button matTooltip="รายละเอียด" [queryParams]="{
                                        pay_hid: element.hid,
                                        disabled: true
                                        }" routerLink="/page/payment/create-payment-inquiry">
                                <mat-icon svgIcon="heroicons_outline:document-text"></mat-icon>
                            </button>
                        </div>
                        <div>
                            <!-- <button *ngIf="checkButton('BT148') && (element.stage == 2 || element.stage == 3 || element.stage == 4)" mat-icon-button matTooltip="พิมพ์ใบคำร้องขอชำระเงิน"
                                (click)="exportPDFReqPayment(element.hid)"><mat-icon
                                    svgIcon="heroicons_outline:printer"></mat-icon>
                            </button> -->
                        </div>
                        <div>
                            <button *ngIf="checkButton('BT149') && (element.stage == 3 || element.stage == 4)"
                                mat-icon-button matTooltip="พิมพ์ใบแจ้งยอด"
                                (click)="exportPDFBillPayment(element.hid)"><mat-icon
                                    svgIcon="heroicons_outline:printer"></mat-icon>
                            </button>
                        </div>
                        <div>
                            <button *ngIf="checkButton('BT150') && element.stage == 2 || element.stage == 1"
                                mat-icon-button matTooltip="ลบ" (click)="updateStatusPayment(element , 5)"
                                matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </div>
                        <div>
                            <button *ngIf="checkButton('BT183') && element.stage == 3" mat-icon-button
                                matTooltip="ดึงงานกลับ" (click)="pullPaymentFromCSC(element)">
                                <mat-icon svgIcon="heroicons_outline:x-circle"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [length]="this.count" [pageSizeOptions]="[10, 50, 100]" (page)="_page($event)"
            showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>