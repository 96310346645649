import { AppMs13Service } from "app/business/service/app/app-ms13.service";
import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { DxDataGridComponent } from "devextreme-angular";
import { AppStaffService } from "app/business/service/app/app-staff.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { StaffHistoryMs13ModalComponent } from "../staff-history-ms13-modal/staff-history-ms13-modal.component";
import { Router } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { Observable } from "rxjs";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AuthService } from "app/core/auth/auth.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";

@Component({
  selector: "app-staff-history-ms13-list",
  templateUrl: "./staff-history-ms13-list.component.html",
  styleUrls: ["./staff-history-ms13-list.component.css"],
})
export class StaffHistoryMs13ListComponent implements OnInit {
  searchForm: any = {
    refNo: "",
    refDate: "",
    o2DocNo: "",
    o2ExpireDate: "",
    product: "",
    reviewBy: "",
    status: "",
  };
  form: any = {};
  productList: any = {};
  statusList: any = {};
  userList: any = [];
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "refNo",
    "refDate",
    "companyName",
    "o2DocNo",
    "o2IssueDate",
    "o2ExpireDate",
    "productType",
    "status",
    "reviewBy",
    "action",
  ];
  url: any;
  buttonList: any[] = [];
  searchString: any;
  count: any;
  timer: any
  ms13Form: FormGroup;
  filteredOptionsUser$: Observable<string[]>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private confirmService: ConfirmDialogService,
    private appMs13Service: AppMs13Service,
    private appMs24Service: AppMs24Service,
    private appStaffService: AppStaffService,
    private router: Router,
    private alertMessage: AlertMessageService,
    private confirmMessage: ConfirmDialogService,
    private fb: FormBuilder,
    private _authService: AuthService,
    private appMenuService: AppMenuService,
    private appMasterService: AppMasterService,
  ) { }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode,
    };
    await this.appMenuService
      .getButtonPermissionPerPages(formData)
      .then((bb) => {
        this.buttonList = bb;
      });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);
    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    this.ms13Form = new FormGroup({
      userSearch: new FormControl(),
    });

    await this.getRouter();
    this.formInit();
    this.loadData();
    this.searchForm.nameSearch = "";
  }

  formInit(): void {
    this.ms13Form = this.fb.group({
      userSearch: [],
    });
  }

  clearOption() {
    this.searchForm = {};
    this.searchString = "";
    this.ms13Form.get("userSearch").setValue("");
    this.searchOption();
  }

  async loadData() {
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: 0,
      isActive: "Y",
    };
    this.productList = await this.appMasterService.getProduct(formProduct);
    const formStatus = {
      statusId: 0,
      isActive: "Y",
    };
    await this.appMasterService.getStatus(formStatus).then((status) => {
      this.statusList = status;
    });

    //4 BOT
    this.appMasterService.getUserByCompanyType({ typeId: 3 }).then((res) => {
      this.userList = res;
      this.filteredOptionsUser$ = res;
    });

    this.ms13Form.valueChanges.subscribe((newValue) => {
      this.filteredOptionsUser$ = this.filterUserValues(newValue.userSearch);
    });

    this.loadDataSource();
  }

  filterUserValues(search: any) {
    return this.userList.filter(
      (value) =>
        value?.fullName?.toLowerCase().indexOf(search?.toLowerCase()) === 0
    );
  }

  selectionUserChange(e): void {
    this.ms13Form.get("userSearch").setValue(e.option.value.fullName);
    this.searchForm.reviewBy = e.option.value.userId;
  }

  async loadDataSource() {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    const formData = {
      UID: Number(this.userProfile.uId),
      keyword: this.searchForm.keyword ?? null,
      refNo: this.searchForm.refNo,
      refDate: this.searchForm.refDate,
      o2DocNo: this.searchForm.o2DocNo,
      o2ExpireDate: this.searchForm.o2ExpireDate,
      productId: this.searchForm.product,
      reviewBy: this.searchForm.reviewBy,
      status: this.searchForm.status,
      pagination: {
        Skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        Take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appStaffService.StaffHistoryMs13List(formData).then((res) => {
      if (res === 'error') {
        this.dataSource.data = [];
        this.count = 0;
        return;
      } else {
        if (res != null) {
          let a = res.data;
          this.dataSource.data = a;
          this.count = res.totalCount;
        } else {
          this.dataSource.data = [];
          this.count = 0;
        }
      }
    });

    this.dataSource.paginator.length = this.count;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: Event) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.searchForm.keyword = event;
      this.dataSource.paginator = this.paginator;
      this.loadDataSource();
    }, 500); // Specify the desired delay in milliseconds
  }

  menuClosed() {

  }

  async deleteItem(e) {
    // -สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "ต้องการลบใบ อ.2 หรือไม่",
      content: "ยืนยันการลบ",
    });
    // ;
    if (alertConfirm) {
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  searchOption() {
    this.searchForm.keyword = "";
    this.searchString = "";

    this.loadDataSource();
  }

  async pageChanged(e: any) {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    this.searchForm.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };

    const formData = {
      UID: Number(this.userProfile.uId),
      keyword: this.searchForm.keyword ?? null,
      refNo: this.searchForm.refNo,
      refDate: this.searchForm.refDate,
      o2DocNo: this.searchForm.o2DocNo,
      o2ExpireDate: this.searchForm.o2ExpireDate,
      productId: this.searchForm.product,
      reviewBy: this.searchForm.reviewBy,
      status: this.searchForm.status,
      pagination: {
        Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
        /*(!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),*/
        Take: !e.pageSize ? 10 : e.pageSize, //!this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appStaffService.StaffHistoryMs13List(formData).then((res) => {
      if (res === 'error') {
        this.dataSource.data = [];
        return;
      } else {
        if (res != null) {
          let a = res.data;
          this.dataSource.data = a;
          this.count = res.totalCount;
        } else {
          this.dataSource.data = [];
        }
      }
    });

    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = e.pageIndex;
  }

  lockPrint(e) { 
    this.confirmService.open({
      header: "",
      content: "คุณต้องการล็อคการพิมพ์ใช่หรือไม่?"
    }).then(res => {
      if (res) {
        const req = {
          id: e.id,
          type: "ms13",
          active:"Y"
        };
        this.appMs13Service.lockPrint(req).then((res) => {
          this.alertMessage.open({
            message: "ล็อกการพิมพ์เอกสารเรียบร้อย",
            title: "lock print",
          });
          this.searchOption();
        });
      }
    });
  }

  unlockPrint(e) { 
    this.confirmService.open({
      header: "",
      content: "คุณต้องการปลดล็อคการพิมพ์ใช่หรือไม่?"
    }).then(res => {
      if (res) {
        const req = {
          id: e.id,
          type: "ms13",
          active:"Y"
        };
        this.appMs24Service.reprint(req).then((res) => {
          this.alertMessage.open({
            message: "ปลดล็อกการพิมพ์เอกสารเรียบร้อย",
            title: "ขอ Reprint",
          });
          this.searchOption();
        });
      }
    });
  }

  history(e) {
    console.log(e);
    const dialogRef = this.dialog.open(StaffHistoryMs13ModalComponent, {
      panelClass: "custom-dialog-container",
      width: "1200px",
      data: e,
    });
  }

  exportMs13(e) {
    const formDataMs13 = {
      ms13hId: e.id,
      copyNumber: 4,
    };
    this.appMs13Service.printCertificateMS13(formDataMs13).then((res) => {
      window.open(res.pathPdf, "_blank");
      this.searchOption();
    });
  }
  async expireO2(e) {
    //-สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "ต้องการสิ้นสุดงานหรือไม่",
      content: "ยืนยันการสิ้นสุดงาน",
    });
    // ;
    if (alertConfirm) {
    }
  }
  sentDataToDFT(e) { }

  sendDft(e) {
    this.confirmService.open({
      header: "",
      content: "คุณต้องการส่งเอกสารไปที่ระบบของกรมการค้าระหว่างประเทศใหม่หรือไม่?"
    }).then(res => {
      if (res) {
        const formDataMs13 = {
          id: e.id,
        };

        this.appMasterService.sendMS24toOcsConnectExternal(formDataMs13).then(res => {
          if (res != 'error') {
            this.alertMessage.open({
              message: "ส่งข้อความเรียบร้อย",
              title: ""
            });
          }
        });
      }
    });
  }

  // updateBotReceive(e) {
  //   const req = {
  //     productId: e.productId,
  //   };
  //   this.appMs13Service.genRunningMs13(req).then((res) => {
  //     this.form.refNoShow = res.prefix + " " + res.refNo;
  //     this.form.refNo = res.refNo;
  //     this.form.fullRefNo = this.form.refNoShow;
  //     this.form.refDate = new Date();
  //     const req = {
  //       id: e.id,
  //       status: 3,
  //       userId: this.userProfile.uId,
  //       refNo: this.form.refNo,
  //       fullRefNo: this.form.fullRefNo,
  //     };
  //     this.appMs13Service.updateBotReceive(req).then((res) => {
  //       if (res != "error") {
  //         this.loadData();
  //       }
  //     });
  //   });
  // }


  //FLOW APPROVE
  acceptJobs(e, status) {
    console.log(e)

    var rqUpdateStatus = {
      ms13HId: e.id,
      status: status
    };

    this.appMs13Service.saveMs13JobStatus(rqUpdateStatus).then(res => {
      if (res == 'error') {
        return;
      } else {
        this.router.navigate(["/page/o2/create-ms13"], {
          queryParams: {
            page: 'ms13',
            type: 'Detail',
            refNo: e.refNo,
            id: e.id,
            docNo: e.o2DocNo,
            status: status,
            isStaff : 'Y' 
          }
        });
      }
    })
  }

  surveyorJobs(e, status) {
    console.log(e)
    var rqUpdateStatus = {
      ms13HId: e.id,
      status: status,
      surveyAcceptName: this.userProfile.name
    };

    if (status === 5) {
      this.confirmMessage.open({
        header: "",
        content: "คุณต้องการปิดงานหรือไม่?"
      }).then(res => {
        if (res) {
          this.appMs13Service.saveMs13SurveyorJob(rqUpdateStatus).then(res => {
            if (res == 'error') {
              return;
            } else {
              this.alertMessage.open({
                message: "บันทึกปิดงานเรียบร้อย",
                title: ""
              });

              this.loadDataSource();
            }
          })
        }
      })
    } else {
      this.appMs13Service.saveMs13SurveyorJob(rqUpdateStatus).then(res => {
        if (res == 'error') {
          return;
        } else {
          this.router.navigate(["/page/o2/create-ms13"], {
            queryParams: {
              page: 'ms13',
              type: 'Detail',
              refNo: e.refNo,
              id: e.id,
              docNo: e.o2DocNo,
              status: status,
              isStaff : 'Y' 
            }
          });
        }
      })
    }
  }
}
