import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AppBankService } from 'app/business/service/app/app-bank.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'

@Component({
  selector: 'app-province-manage-popup',
  templateUrl: './province-manage-popup.component.html',
  styleUrls: ['./province-manage-popup.component.css']
})
export class ProvinceManagePopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  regionList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ProvinceManagePopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
      this.form.geoId = 0;
    }
    
  }
  ngOnInit(): void {
    this.regionList = [
      {
        "regionCode": 0,
        "regionName": "กรุณาเลือก"
      },
      {
        "regionCode": 1,
        "regionName": "ภาคเหนือ"
      },
      {
        "regionCode": 2,
        "regionName": "ภาคกลาง"
      },
      {
        "regionCode": 3,
        "regionName": "ภาคตะวันออกเฉียงเหนือ"
      },
      {
        "regionCode": 4,
        "regionName": "ภาคตะวันตก"
      },
      {
        "regionCode": 5,
        "regionName": "ภาคตะวันออก"
      },
      {
        "regionCode": 6,
        "regionName": "ภาคใต้"
      }
    ]
  }

  save() {
    
    if ((this.form.provinceName !== undefined && this.form.provinceName !== null && this.form.provinceName !== "") &&
        (this.form.provinceEname !== undefined && this.form.provinceEname !== null && this.form.provinceEname !== "")) {
      const data = {
        "provinceId": this.form.provinceId,
        "provinceName": this.form.provinceName,
        "provinceEname": this.form.provinceEname,
        "isActive": this.form.isActive
      };

      this.appMasterService.saveProvince(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });

    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
