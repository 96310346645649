import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit,Component, OnInit,ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table'

@Component({
  selector: 'app-user-management-tables-inquiry',
  templateUrl: './user-management-tables-inquiry.component.html',
  styleUrls: ['./user-management-tables-inquiry.component.scss']
})
export class UserManagementTablesInquiryComponent 
implements OnInit {
  searchForm: any = {}
  form : any={}
  dataSource = new MatTableDataSource<any>()
  displayedColumns: string[] = ['userName','firstName', 'lastName', 'approved', 'action']
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.searchForm = {
    }
    this.searchForm.userNameSearch = ''
    this.dataSource = new MatTableDataSource([
      {
        id: 1,
        userName: 'Test1',
        firstName: 'ห้างหุ้นส่วนจำกัด เจ.แอนด์ ซี เทรดดิ้งเซอร์วิซ',
        lastName: 'J AND C TRADING SERVICE LTD., PART.',
        approved: 'Y',
        action: '',
      },
      {
        id: 2,
        userName: 'Test2',
        firstName: 'ห้างหุ้นส่วนจำกัด เอเชีย ชิปปิ้ง เซอร์วิส',
        lastName: 'ASIA SHIPPING SERVICE LIMITED PARTNERSHIP',
        approved: 'Y',
        action: '',
      },
      {
        id: 3,
        userName: 'Test3',
        firstName: 'บริษัท ดี แอนด์ เค ชิปปิ้ง อิมปอร์ต แอนด์ เอ็กซ์ปอร์ต จำกัด',
        lastName: 'DNK SHIPPING IMPORT AND EXPORT CO.,LTD.',
        approved: 'Y',
        action: '',
      },
      {
        id: 4,
        userName: 'Test4',
        firstName: 'บริษัท เอ.เอ็น.โอ.อิมปอร์ต เอ็กซ์ปอร์ต เซอร์วิส จำกัด',
        lastName: 'A.N.O.IMPORT EXPORT SERVICE CO.,LTD.',
        approved: 'Y',
        action: '',
      },
      {
        id: 5,
        userName: 'Test5',
        firstName: 'บริษัท เอ.อาร์.เค.โลจิชทิค จำกัด',
        lastName: 'A.R.K.LOGISTIC CO.,LTD.',
        approved: 'Y',
        action: '',
      },
      { id: 6, userName: 'Test6',firstName: 'คาร์บอน', lastName: 'Carbon', approved: 'Y', action: '' },
      { id: 7, userName: 'Test7',firstName: 'ไนโตรเจน', lastName: 'Nitrogen', approved: 'Y', action: '' },
      { id: 8, userName: 'Test8',firstName: 'ออกซิเจน', lastName: 'Oxygen', approved: 'Y', action: '' },
      { id: 9, userName: 'Test9',firstName: 'ฟลูออไรด์', lastName: 'Fluorine', approved: 'Y', action: '' },
      { id: 10, userName: 'Test10',firstName: 'นีออน', lastName: 'Neon', approved: 'Y', action: '' },
      { id: 11, userName: 'Test11',firstName: 'โซเดียม', lastName: 'Sodium', approved: 'Y', action: '' },
      { id: 12, userName: 'Test12',firstName: 'แมกนีเซียม', lastName: 'Magnesium', approved: 'Y', action: '' },
      { id: 13, userName: 'Test13',firstName: 'อลูมิเนียม', lastName: 'Aluminum', approved: 'Y', action: '' },
      { id: 14, userName: 'Test14',firstName: 'ซิลิกอน', lastName: 'Silicon', approved: 'Y', action: '' },
      { id: 15, userName: 'Test15',firstName: 'ฟอสฟอรัส', lastName: 'Phosphorus', approved: 'Y', action: '' },
      { id: 16, userName: 'Test16',firstName: 'ซัลเฟอร์', lastName: 'Sulfur', approved: 'Y', action: '' },
      { id: 17, userName: 'Test17',firstName: 'คลอรีน', lastName: 'Chlorine', approved: 'Y', action: '' },
      { id: 18, userName: 'Test18',firstName: 'อาร์กอน', lastName: 'Argon', approved: 'Y', action: '' },
      { id: 19, userName: 'Test19',firstName: 'โพแทสเซ๊ยม', lastName: 'Potassium', approved: 'Y', action: '' },
      { id: 20, userName: 'Test20',firstName: 'แคลเซียม', lastName: 'Calcium', approved: 'Y', action: '' },
    ])
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }

  applyFilter(event: Event) {
    
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }
  editItem(e) {
    
  }

  stopPropagation(event) {
    event.stopPropagation()
  }
  // menuOpen() {}
  menuClosed() {
    
  }

  searchOption() {
    
  }

  save() {}

}
