import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMs13Service } from 'app/business/service/app/app-ms13.service';

@Component({
  selector: 'app-create-ms24-modal',
  templateUrl: './create-ms24-modal.component.html',
  styleUrls: ['./create-ms24-modal.component.css']
})
export class CreateMs24ModalComponent implements OnInit {
  form: any = {};
  confirmMs24Form: any = {};
  pages: any;
  productList: any = [];
  dataMs13List: any = [];
  refMs13List: any = [];
  statusList: any = [];
  currentDate: Date = new Date();
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  dataMs13Source = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "number",
    "goodsDescription",
    "grossWeightNet",
    "grossQty",
  ];
  ms13Id : number;
  constructor(
    private appMasterService: AppMasterService,
    private appMs13Service: AppMs13Service,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit() {
    const { page } = this.route.snapshot.queryParams;
    // console.log("createO2 ngOnInit", page);
    this.pages = this.data.page; //"ms13";
    // this.pages = page ;//"ms13";
    //this.loadData();
  }

  async loadData() {
    // const formProduct = {
    //   companyID: Number(this.userProfile.cpId),
    //   productId: 0,
    //   isActive: "Y",
    // };
    // this.productList = await this.appMasterService.getProduct(formProduct);
    // if (this.pages == "o2") {
    //   this.productList = this.productList.filter(
    //     (f: { productId: number }) => f.productId == 3 || f.productId == 10
    //   );
    // }
    const formProduct = {
      page: this.pages,
      isActive: "Y",
    };
    this.productList = await this.appMasterService.getProduct(formProduct);
    if (this.pages == "o2") {
      this.productList = this.productList.filter(
        (f: { productId: number }) => f.productId == 3 || f.productId == 10
      );
    } else {
      this.productList = this.productList.filter(
        (f: { productId: number }) => f.productId != 1 && f.productId != 2
      );
    }

    this.appMasterService.getProduct(formProduct).then((res: any) => {
      if (res == "error") {
      } else {
        this.productList = res;
      }
    });

    const formStatus = {
      statusId: 0,
      isActive: "Y",
    };
    this.statusList = await this.appMasterService.getStatus(formStatus);
  }
  async confirmCreateMs24() {
    if(this.form.refMs13No != null){
      this.confirmMs24Form.refMs13No = this.form.refMs13No;
      this.confirmMs24Form.product = this.form.product;
      this.ms13Id = this.form.refMs13No;
      const formMs13ID = {
        id: this.form.refMs13No,
      };
      await this.appMs13Service.getDataMS13ById(formMs13ID).then((res: any) => {
        // 
        if (res == "error") {
        } else {
          var dataSourceList = res.map((x,n) => {
            return {
              ...x,
              rowNo: (n+1)
            };
          });
          this.dataMs13Source.data = dataSourceList;
          //this.dataMs13Source = new MatTableDataSource(dataSourceList);
          //this.dataMs13Source.data = dataSourceList;
          this.dataMs13List.push(dataSourceList);
          console.log(this.dataMs13List);
        }
      });

      const dialogRef = this.dialog.open(CreateMs24ModalComponent, {
        disableClose: true,
        maxWidth: "none",
        width: "65vw",
        data: {
          page: "ms24",
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        // console.log('The dialog was closed');
        // this.animal = result;
      });
    }
  }
}
