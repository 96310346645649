<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">NSW Tracking Response </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]>
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="flex flex-col mat-typography" style="gap: 15px;">
    <div class="flex flex-row justify-center gap-3">
      <div class="mat-elevation-z8 w100">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
          <ng-container matColumnDef="index">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ลำดับ</th>
            <td mat-cell *matCellDef="let element; let i = index" style="justify-content: center;"> {{ i + 1 }} </td>
          </ng-container>

          <ng-container matColumnDef="responseTime">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">Response Date/Time
            </th>
            <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.responseDate |
              date:'yyyy-MM-dd'}} {{element.responseDate | date:'HH:mm:ss'}} </td>
          </ng-container>

          <ng-container matColumnDef="responseAction">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">Response Action
            </th>
            <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.responseAction}} </td>
          </ng-container>

          <ng-container matColumnDef="action" style="text-align: left; min-width: 170px;">
            <th mat-header-cell *matHeaderCellDef style="text-align: left; min-width: 170px;"> Action </th>
            <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;min-width: 170px;">
              <div class="flex">
                <div>
                  <button mat-icon-button matTooltip="View Response Body" matTooltipPosition="above"
                    (click)="openInfo(element)">
                    <i class="ti ti-script"></i>
                  </button>
                </div>
              </div>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>

    <div class="flex flex-row justify-center gap-3" *ngIf="clickBody">
      <div class="flex flex-col p10 w100">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            Response Body
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding: 16px">
            <div class="flex">
              <div class="flex flex-col w100">
                <mat-form-field class="w100">
                  <textarea matInput placeholder="Response Body" [(ngModel)]="form.responseBody" name="responseBody"
                    #responseBody="ngModel" rows="5" readonly></textarea>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </mat-dialog-content>
</mat-card>