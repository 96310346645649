import { Injectable } from "@angular/core"; 
import { Observable } from "rxjs/internal/Observable";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: "root",
})
export class ApiNewsService {
  constructor(private apiService: ApiService) {}

  getNewsList(data: any): Observable<any> {
    return this.apiService.post('/api/getNewsList', data);
  }

  getCategorymenuList(data: any): Observable<any> {
    return this.apiService.post('/api/getCategorymenuList', data);
  }

  getCategoryNewsist(data: any): Observable<any> {
    return this.apiService.post('/api/getCategoryNewsist', data);
  }

  getSubCategorymenuList(data: any): Observable<any> {
    return this.apiService.post('/api/getSubCategorymenuList', data);
  }
}
