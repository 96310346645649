import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AuthService } from 'app/core/auth/auth.service';
import { DxDataGridComponent } from 'devextreme-angular';
import moment from 'moment';
import { DftTrackingResponseComponent } from '../dft-tracking-response/dft-tracking-response.component';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { DftMessageReportComponent } from '../dft-message-report/dft-message-report.component';
import { DftTrackingRoundListComponent } from '../dft-tracking-round-list/dft-tracking-round-list.component';

@Component({
  selector: 'app-dft-tracking-inquiry',
  templateUrl: './dft-tracking-inquiry.component.html',
  styleUrls: ['./dft-tracking-inquiry.component.css']
})
export class DftTrackingInquiryComponent implements OnInit {
  searchForm: any = {};
  form: any = {};
  searchString: string;
  productList: any = [];
  statusList: any = [];
  dataSource = new MatTableDataSource<any>();
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  displayedColumns: string[] = [
    "index",
    "docTypeName",
    "referenceNo",
    "licenseNo",
    "exporterName",
    "product",
    "lastUpdate",
    "statusName",
    "action",
  ];
  url: any;
  buttonList: any[] = [];
  timer: any;
  count: any = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private confirmService: ConfirmDialogService,
    private alertMessageService: AlertMessageService,
    private router: Router,
    private _authService: AuthService,
    private appMenuService: AppMenuService
  ) { }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode,
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(
      formData
    );
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    await this.getRouter();
    this.searchForm = {};
    this.dataSource.paginator = this.paginator;

    this.loadData();
    this.searchForm.nameSearch = "";
  }

  async loadData() {
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: 0,
      isActive: "Y",
    };

    this.productList = await this.appMasterService.getProduct(formProduct);

    const formStatus = {
      statusId: 0,
      isActive: "Y",
    };

    this.statusList = await this.appMasterService.getStatusTracking(formStatus);

    this.loadDataSource();
  }

  async loadDataSource() {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    const formData = {
      keyword: this.searchForm.keyword ?? null,
      docType: this.searchForm.docType ?? null,
      referenceNo: this.searchForm.referenceNo ?? null,
      licenseNo: this.searchForm.licenseNo ?? null,
      exporterName: this.searchForm.exporterName ?? null,
      status: this.searchForm.status ?? null,
      productId: this.searchForm.productId ?? null,
      pagination: {
        skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appMasterService.getDftTrackingDocumentList(formData).then((res: any) => {
      if (res === 'error') {
        return;
      } else {
        if (res != null) {
          let a = res.data.map((x) => {
            moment.locale("th"); // set default locale to Thai
            x.formatLastUpdate = moment(x.lastUpdate).format(
              "YYYY-MM-DD HH:mm"
            );

            x.docTypeName = x.docType === 'MS24' ? 'มส.24' : x.docType === 'MS13' ? 'มส.13' : x.docType === 'O2' ? 'อ.2' : 'ไม่ระบุ';

            return x;
          });

          this.dataSource.data = a;
          this.count = res.totalItem;
        } else {
          this.dataSource.data = [];
          this.count = 0;
        }
      }
    });

    this.dataSource.paginator.length = this.count;
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: string) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.searchForm.keyword = event;
      this.dataSource.paginator = this.paginator;
      this.loadDataSource();
    }, 500); // Specify the desired delay in milliseconds
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
  // menuOpen() {}
  menuClosed() {
    console.log("menuClosed @configbug");
  }

  searchOption() {
    this.searchForm.keyword = "";
    this.searchString = "";

    this.loadDataSource();
  }

  clearOption() {
    this.searchForm = {};
    this.searchString = "";
    this.searchOption();
  }

  async pageChanged(e) {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    this.searchForm.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };

    const formData = {
      keyword: this.searchForm.keyword ?? null,
      docType: this.searchForm.docType ?? null,
      referenceNo: this.searchForm.referenceNo ?? null,
      licenseNo: this.searchForm.licenseNo ?? null,
      exporterName: this.searchForm.exporterName ?? null,
      status: this.searchForm.status ?? null,
      productId: this.searchForm.productId ?? null,
      pagination: {
        skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
        take: !e.pageSize ? 10 : e.pageSize,
      },
    };

    await this.appMasterService.getDftTrackingDocumentList(formData).then((res: any) => {
      if (res === 'error') {
        return;
      } else {
        if (res != null) {
          let a = res.data.map((x) => {
            moment.locale("th"); // set default locale to Thai
            x.formatLastUpdate = moment(x.lastUpdate).format(
              "YYYY-MM-DD HH:mm"
            );

            x.docTypeName = x.docType === 'MS24' ? 'มส.24' : x.docType === 'MS13' ? 'มส.13' : x.docType === 'O2' ? 'อ.2' : 'ไม่ระบุ';

            return x;
          });

          this.dataSource.data = a;
          this.count = res.totalItem;
        } else {
          this.dataSource.data = [];
          this.count = 0;
        }
      }
    });

    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = e.pageIndex;
  }

  openInfo(e) {
    this.dialog.open(DftTrackingRoundListComponent, {
      data: e,
      disableClose: false,
      width: '65vw',
    });
  }

  resendDftMessage(e) {
    this.confirmService.open({
      header: "",
      content: "คุณต้องการส่งข้อมูลอีกรอบหรือไม่?"
    }).then(res => {
      if (res) {
        var rqSendDftMessage = {
          id: e.docId
        };

        if (e.docType == "MS13") {
          this.appMasterService.sendMS13toOcsConnectExternal(rqSendDftMessage).then(res => {
            if (res === 'error') {
              this.loadDataSource();
              return;
            } else {
              this.alertMessageService.open({
                title: "",
                message: "ส่งข้อความเรียบร้อยแล้ว กรุณารอสักครู่ ข้อความจะปรากฎที่รายการล่าสุด"
              });
              this.loadDataSource();
            }
          })
        }
        else if (e.docType == "MS24") {
          this.appMasterService.sendMS24toOcsConnectExternal(rqSendDftMessage).then(res => {
            if (res === 'error') {
              this.loadDataSource();
              return;
            } else {
              this.alertMessageService.open({
                title: "",
                message: "ส่งข้อความเรียบร้อยแล้ว กรุณารอสักครู่ ข้อความจะปรากฎที่รายการล่าสุด"
              })
              this.loadDataSource();
            }
          })
        }
        else {
          this.alertMessageService.open({
            title: "",
            message: "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบ"
          })
        }

      }
    })
  }

  sendCancelDftMessage(e) {
    this.confirmService.open({
      header: "",
      content: "คุณต้องการส่งข้อมูลเพื่อยกเลิกใบอนุญาตนี้ไปยังกรมการค้าต่างประเทศหรือไม่?"
    }).then(res => {
      if (res) {
        var rqSendDftMessage = {
          id: e.docId
        };

        if (e.docType == "MS13") {
          this.appMasterService.sendCancelMS13toOcsConnectExternal(rqSendDftMessage).then(res => {
            if (res === 'error') {
              this.loadDataSource();
              return;
            } else {
              this.alertMessageService.open({
                title: "",
                message: "ส่งข้อความเรียบร้อยแล้ว กรุณารอสักครู่ ข้อความจะปรากฎที่รายการล่าสุด"
              });
              this.loadDataSource();
            }
          })
        }
        else if (e.docType == "MS24") {
          this.appMasterService.sendCancelMS24toOcsConnectExternal(rqSendDftMessage).then(res => {
            if (res === 'error') {
              this.loadDataSource();
              return;
            } else {
              this.alertMessageService.open({
                title: "",
                message: "ส่งข้อความเรียบร้อยแล้ว กรุณารอสักครู่ ข้อความจะปรากฎที่รายการล่าสุด"
              })
              this.loadDataSource();
            }
          })
        }
        else {
          this.alertMessageService.open({
            title: "",
            message: "ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบ"
          })
        }

      }
    });
  }

  onRefresh() {
    this.clearOption();
  }

  getReport() {
    this.dialog.open(DftMessageReportComponent, {
      width: '65vw',
      disableClose: true,
    })
  }
}
