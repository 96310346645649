import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { IAppDftSurveyorDetails } from 'app/business/interface/app/app-dft.interface';
import { AppDFTService } from "app/business/service/app/app-dft.service";
import { dft_surveyor, dft_surveyor_list } from 'app/mock-api/apps/dft-surveyor/data';

@Component({
  selector: 'app-dft-surveyor-inquiry',
  templateUrl: './dft-surveyor-inquiry.component.html',
  styleUrls: ['./dft-surveyor-inquiry.component.css']
})

export class DFTSurveyorInquiryComponent implements OnInit {
  displayedColumns: string[] = [
    'surveyorNumber',
    'nameTH',
    'type',
    'pYear',
    'issuedDate',
    'expireDate',
    'productName'
  ];
  companyID: any;
  search: any = {};
  dataSource = new MatTableDataSource<any>();
  form: any = {};
  dataView: IAppDftSurveyorDetails[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private appDftservice: AppDFTService,
    private route: ActivatedRoute,
    private alertService: AlertMessageService,
    private dialog: MatDialog,
  ) {

  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    const dftSurveyorId = this.route.snapshot.queryParams;
    this.companyID = dftSurveyorId;
    this.loadData(dftSurveyorId);
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  SortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  async loadData(e) {
    const data = {
      dftSurveyorId: e.dftSurveyorId,
    }
    await this.appDftservice.getDataSurveyor(data).then((res) => {
      this.dataView = res;
      this.dataSource = new MatTableDataSource(res.surveyorList);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      // this.form.mS5DateStart = formatDate(res[0].mS5DateStartResponse, 'yyyy-MM-dd', "en-US");
      // this.form.mS5DateEnd = formatDate(res[0].mS5DateEndResponse, 'yyyy-MM-dd', "en-US");
      // this.form.CreateDateResponse = formatDate(res[0].createDateResponse, 'yyyy-MM-dd', "en-US");
      // this.form.UpdateDateTimeResponse = res[0].updateDateTimeResponse == null ? null : formatDate(res[0].updateDateTimeResponse, 'yyyy-MM-dd', "en-US");
      this.form = { ...this.form, ...res };

      // this.form.createDatetime = res.createDatetime;
      // this.form.lastUpdate = res.lastUpdate;
      // this.form.ptNo = res.ptNo;
      // this.form.ptYear = res.ptYear;
      // this.form.taxNo = res.taxNo;
      // this.form.mS6DateStart = res.mS6DateStart;
      // this.form.mS6DateEnd = res.mS6DateEnd;
      // this.form.name = res.name;
      // this.form.status = res.status;
      // this.form.upMR = res.upMR;
      // this.form.productAccepts = res.productAccepts;
      // this.dataSource.data = res.surveyorList;
    });
    // //Mockup
    // const res = await dft_surveyor;
    // this.form.createDatetime = res.createDatetime;
    // this.form.lastUpdate = res.lastUpdate;
    // this.form.PTNo = res.PTNo;
    // this.form.PTYear = res.PTYear;
    // this.form.taxNo = res.taxNo;
    // this.form.MS6DateStart = res.MS6DateStart;
    // this.form.MS6DateEnd = res.MS6DateEnd;
    // this.form.name = res.name;
    // this.form.status = res.status;
    // this.form.upMR = res.upMR;
    // this.form.productAccept = res.productAccept;
    // this.dataSource.data = res.surveyorList;
  }

  async upDate() {
    const res = {
      cpId: this.companyID,
      updateMR: this.form.updateMR == 'No' ? 0 : this.form.updateMR == 'Yes' ? 1 : this.form.updateMR
    };
    await this.appDftservice.updateDataSurveyor(res).then((res) => {
      if (res !== 'error') {
        this.alertService.info({ title: '', message: 'บันทึกข้อมูลเรียบร้อย' }).then(x => {
          this.dialog.closeAll();
          this.loadData(this.companyID);
        });
      }
    });
  }

  searchData() {
    const filterObj = {
      surveyorNumber: (this.search.surveyorNumber ? this.search.surveyorNumber : ''),
      nameTH: (this.search.nameTH ? this.search.nameTH : '').toLowerCase(),
      type: (this.search.type ? this.search.type : '').toLowerCase()
    };
    console.log(filterObj);
    var defaulteFilterPredict = this.dataSource.filterPredicate;
    this.setFilterPredicate();
    this.dataSource.filter = JSON.stringify(filterObj);
    this.dataSource.filterPredicate = defaulteFilterPredict;
  }

  resetFilter() {
    this.search = {};
    const filterObj = {
      surveyorNumber: (this.search.surveyorNumber ? this.search.surveyorNumber : ''),
      nameTH: (this.search.nameTH ? this.search.nameTH : '').toLowerCase(),
      type: (this.search.type ? this.search.type : '').toLowerCase()
    };
    console.log(filterObj);
    var defaulteFilterPredict = this.dataSource.filterPredicate;
    this.setFilterPredicate();
    this.dataSource.filter = JSON.stringify(filterObj);
    this.dataSource.filterPredicate = defaulteFilterPredict;
  }

  setFilterPredicate() {
    this.dataSource.filterPredicate = (data: any, filterObjectString: string) => {
      const filterObj = JSON.parse(filterObjectString);
      return (
        (data.surveyorNumber ? data.surveyorNumber : '').includes(filterObj.surveyorNumber) &&
        (data.nameTH ? data.nameTH : '').toLowerCase().includes(filterObj.nameTH) &&
        (data.type ? data.type : '').toLowerCase().includes(filterObj.type)
      )
    }
  }
}