<form [formGroup]="angForm">
  <mat-toolbar class="bgGray">
    <button mat-icon-button class="bglightgrey" routerLink="/page/staff-renew/staff-renew-list">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <button mat-icon-button class="bglightgrey" (click)="reloadPage()">
      <mat-icon>cached</mat-icon>
    </button>
    <button mat-icon-button class="bglightgrey" (click)="upDate(form.status)"
      [disabled]="angForm.pristine || angForm.invalid">
      <mat-icon>save</mat-icon>
    </button>
  </mat-toolbar>

  <div class="flex flex-col p10">
    <div style="padding: 20px 30px 0px 30px">
      <mat-card class="pt-10 pb-10">
        <div class="flex flex-row">
          <div class="basis-1/4 self-center text-right pr-2.5">
            การยื่นต่ออายุในครั้งนี้มีผลถึงวันที่ :
          </div>
          <div class="basis-1/4">
            <mat-form-field class="my-form-field formm w100">
              <input matInput [matDatepicker]="picker1" [(ngModel)]="form.expireDate" required class="form-control"
                formControlName="expireDate" name="expireDate" required="true" [min]="minDate" [max]="maxDate" />
              <mat-hint>YYYY/MM/DD</mat-hint>
              <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1 dateFormat="YYYY/MM/DD"></mat-datepicker>
            </mat-form-field>
            <div *ngIf="
                angForm.controls['expireDate'].invalid &&
                (angForm.controls['expireDate'].dirty ||
                  angForm.controls['expireDate'].touched)
              " class="p-1 text-sm text-red-800" role="alert">
              <div *ngIf="angForm.controls['expireDate'].errors.required">
                **กรุณาระบุวันที่ต่ออายุ.
              </div>
            </div>
          </div>
          <div class="basis-1/4 self-center text-left pl-2.5">
            <div class="flex flex-row">
              <div class="basis-1/2 f18 self-center text-left pl-2.5 pr-2.5">
                (ปี-เดือน-วัน)
              </div>
              <!-- อ.2 -->
              <div class="basis-1/2 f18 self-center text-left pr-2.5" *ngIf="data.typeID == 2">
                <a routerLink="/page/o2/o2-manual" mat-raised-button [queryParams]="{
                    type: 'Detail',
                    page: 'o2',
                    refNo: staffGet?.refNo,
                    id: staffGet?.hid,
                    companyCode: staffGet?.companyCode,
                    productId: staffGet?.productId,
                    docNo: staffGet?.docNo
                  }">(?) ดูรายละเอียดเอกสาร</a>
              </div>
              <!-- มส.13 -->
              <!-- ยังไม่ได้ทำเวลากดกลับ หลังจากดูรายละเอียดเอกสารให้กลับมาหน้าเดิม -->
              <div class="basis-1/2 f18 self-center text-left pr-2.5" *ngIf="data.typeID == 13">
                <a routerLink="/page/o2/o2-manual" mat-raised-button [queryParams]="{
                    type: 'Detail',
                    page: 'ms13',
                    refNo: staffGet.refNo,
                    id: staffGet.hid,
                    companyTax : staffGet.companyTax,
                    docNo: staffGet.docNo
                  }">(?) ดูรายละเอียดเอกสาร</a>
              </div>
              <!-- ยังไม่เสร็จ รอ MS24 เสร็จ -->
              <div class="basis-1/2 f18 self-center text-left pr-2.5" *ngIf="data.typeID == 24">
                <a routerLink="/page/o2/o2-manual" mat-raised-button [queryParams]="{
                    type: 'Detail',
                    page: 'ms13',
                    refNo: '',
                    id: data.typeID == 1 ? staffGet.hid : 1,
                    docNo: ''
                  }">(?) ดูรายละเอียดเอกสาร</a>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row">
          <div class="basis-full flex justify-center  ">
            <!-- <button mat-icon-button class="bglightgrey" (click)="upDate(9)">
              อนุมัติ
            </button>
            <button mat-icon-button class="bglightgrey" (click)="upDate(4)">
              ไม่อนุมัติ
            </button>  -->
            <section class="pt-10">
              <mat-button-toggle-group appearance="legacy" [(ngModel)]="form.status" class="form-control"
                formControlName="status" required="true">
                <mat-button-toggle value="9">อนุมัติ</mat-button-toggle>
                <mat-button-toggle value="4">ไม่อนุมัติ</mat-button-toggle>
              </mat-button-toggle-group>
            </section>
            <div *ngIf="
                angForm.controls['status'].invalid &&
                (angForm.controls['status'].dirty ||
                  angForm.controls['status'].touched)
              " class="p-1 text-sm text-red-800" role="alert">
              <div *ngIf="angForm.controls['status'].errors.required">
                **กรุณาระบุผลการต่ออายุ.
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</form>