import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiMs24Service {

  constructor(private apiService: ApiService) { }

  getrenewMS24(data: any): Observable<any> {
    return this.apiService.post('/api/getrenewMS24', data);
  }
  renewMS24sent(data: any): Observable<any> {
    return this.apiService.post('/api/renewMS24sent', data);
  }
  getshortMS24(data: any): Observable<any> {
    return this.apiService.post('/api/getshortMS24', data);
  }
  shortMS24sent(data: any): Observable<any> {
    return this.apiService.post('/api/shortMS24sent', data);
  }
  getMS24fromMS13(data: any): Observable<any> {
    return this.apiService.post('/api/getMS24fromMS13', data);
  }
  MS24list(data: any): Observable<any> {
    return this.apiService.post('/api/MS24list', data);
  }
  getMs24Short(request: any): Observable<any> {
    return this.apiService.post('/api/getMs24Short', request);
  }

  updateMs24ShortStatus(request: any): Observable<any> {
    return this.apiService.post('/api/updateMs24ShortStatus', request);
  }

  getMs24StaffList(request: any): Observable<any> {
    return this.apiService.post('/api/getMs24StaffList', request);
  }

  getMs24StaffDetailList(request: any): Observable<any> {
    return this.apiService.post('/api/getMs24StaffDetailList', request);
  }

  MS24LogStaff(request: any): Observable<any> {
    return this.apiService.post('/api/MS24LogStaff', request);
  }

  MS24RecordStaffDocument(request: any): Observable<any> {
    return this.apiService.post('/api/MS24RecordStaffDocument', request);
  }

  MS24ExportDocumentStaffSummaryPrint(request: any): Observable<any> {
    return this.apiService.post('/api/MS24ExportDocumentStaffSummaryPrint', request);
  }

  MS24ExportDocumentStaffSummaryApproved(request: any): Observable<any> {
    return this.apiService.post('/api/MS24ExportDocumentStaffSummaryApproved', request);
  }

  MS24ExportDocumentStaffRequest(request: any): Observable<any> {
    return this.apiService.post('/api/MS24ExportDocumentStaffRequest', request);
  }

  MS24ExportDocumentStaffProductStandard(request: any): Observable<any> {
    return this.apiService.post('/api/MS24ExportDocumentStaffProductStandard', request);
  }

  getDataMS24(request: any): Observable<any> {
    return this.apiService.post('/api/getDataMS24', request);
  }
  saveDataMs24(request: any): Observable<any> {
    return this.apiService.post('/api/saveDataMs24', request);
  }
  updateDataMs24(request: any): Observable<any> {
    return this.apiService.post('/api/updateDataMs24', request);
  }
  getListPrint(request: any): Observable<any> {
    return this.apiService.post('/api/getListPrint', request);
  }
  reprint(request: any): Observable<any> {
    return this.apiService.post('/api/reqReprint', request);
  }
  getCurrentMs24ShortData(request: any): Observable<any> {
    return this.apiService.post('/api/getCurrentMs24ShortData', request);
  }
  saveMs24Short(request: any): Observable<any> {
    return this.apiService.post('/api/saveMs24Short', request);
  }
  getMs24ShortRequestLists(request: any): Observable<any> {
    return this.apiService.post('/api/getMs24ShortRequestLists', request);
  }
  saveMs24ShortApproveStatus(request: any): Observable<any> {
    return this.apiService.post('/api/saveMs24ShortApproveStatus', request);
  }
  saveMs24ShortActiveStatus(request: any): Observable<any> {
    return this.apiService.post('/api/saveMs24ShortActiveStatus', request);
  }

  printCertificateMS24(request: any): Observable<any> {
    return this.apiService.post('/api/printCertificateMS24', request);
  }

  printCopyCertificateMS24(request: any): Observable<any> {
    return this.apiService.post('/api/printCopyCertificateMS24', request);
  }
  
  getRenewMs24List(request: any): Observable<any> {
    return this.apiService.post('/api/getRenewMs24List', request);
  }

  updateStatusRenewMs24(request: any): Observable<any> {
    return this.apiService.post('/api/updateStatusRenewMs24', request);
  }

  updateMs24ApproveStatus(request: any): Observable<any> {
    return this.apiService.post('/api/updateMs24ApproveStatus', request);
  }

  updateMs24PrintUser(request: any): Observable<any> {
    return this.apiService.post('/api/updateMs24PrintUser', request);
  }

  getMs24DocumentActionHistoryList(request: any): Observable<any> {
    return this.apiService.post('/api/getMs24DocumentActionHistoryList', request);
  }

  getMs24PerDocumentActionHistoryList(request: any): Observable<any> {
    return this.apiService.post('/api/getMs24PerDocumentActionHistoryList', request);
  }

  updateMs24GoodLoadDate(request: any): Observable<any> {
    return this.apiService.post('/api/updateMs24GoodLoadDate', request);
  }

  updateMs24Active(request: any): Observable<any> {
    return this.apiService.post('/api/updateMs24Active', request);
  }
}

