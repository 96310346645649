import { Injectable } from "@angular/core";
import { ApiO2Service } from "../api/api-o2.service";

@Injectable({
  providedIn: "root",
})
export class AppO2Service {
  constructor(private apiO2Service: ApiO2Service) {}

  o2List(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.o2List(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getDataO2(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.getDataO2(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveO2(dataReq): Promise<any> {
    console.log("saveO2" , dataReq);
    return new Promise((resolve, reject) => {
      this.apiO2Service.saveO2(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveO2Online(dataReq): Promise<any> {
    console.log("saveO2" , dataReq);
    return new Promise((resolve, reject) => {
      this.apiO2Service.saveO2Online(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveO2Manual(dataReq): Promise<any> {
    console.log("saveO2" , dataReq);
    return new Promise((resolve, reject) => {
      this.apiO2Service.saveO2Manual(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateStatusO2(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.updateStatusO2(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateStatusRenewO2(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.updateStatusRenewO2(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  renewO2sent(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.renewO2sent(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getrenewO2(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.getrenewO2(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  O2itemsave(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.O2itemsave(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getO2item(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.getO2item(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getdataO2(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.getdataO2(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  checkO2DFTOnline(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.checkO2DFTOnline(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getDataDftO2(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.getDataDftO2(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  delO2Item(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.delO2Item(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  delImg(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.delImg(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getRenewO2List(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.getRenewO2List(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  checkDupDocNo(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.checkDupDocNo(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  deleteO2Img(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.deleteO2Img(request).subscribe((res) => {
        resolve(res);
      })
    })
  }

  recalculateO2FobData(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.recalculateO2FobData(request).subscribe((res) => {
        resolve(res);
      })
    })
  }

  updateO2ApproveStatus(request: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiO2Service.updateO2ApproveStatus(request).subscribe((res) => {
        resolve(res);
      })
    })
  }
}
