import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmButtonInputModel } from './confirm-button-model';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'confirm-button',
  templateUrl: './confirm-button.component.html',
  styleUrls: ['./confirm-button.component.css']
})
export class ConfirmButtonComponent implements OnInit {
  @Input() config: ConfirmButtonInputModel;
  @Output() afterConfirm = new EventEmitter<boolean>();
  constructor(private confirmDialogService: ConfirmDialogService) { }

  ngOnInit(): void {
  }

  open(): void {
    this.confirmDialogService.open({
      header: this.config.header,
      content: this.config.content
    }).then(res => {
      this.afterConfirm.next(res);
    });
  }
}
