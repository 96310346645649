import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, AfterViewInit, ViewChild, Inject} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { IResGetDataMs24RecordStaffDetailList } from "app/business/interface/app/app-ms24.interface";
import {  formatDate  } from '@angular/common';
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-ms24-record-staff-detail-information',
  templateUrl: './ms24-record-staff-detail.component.html',
  styleUrls: ['./ms24-record-staff-detail.component.css']
})
export class Ms24RecordStaffDetailComponent implements OnInit {

  searchForm: any ={};
  form: any = {};
  dataSource = new MatTableDataSource<any>();
  dataView :IResGetDataMs24RecordStaffDetailList[]=[];
  id : number = 0;
  displayedColumns: string[] = [
    "documentName",
    "documentSize",
    "documentType",
    "action"
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private AppMs24Service: AppMs24Service,
    private AlertMessage : AlertMessageService,
    private route: ActivatedRoute,
    ) 
    {
    }
    

  ngOnInit() {
    this.loadData();
  }
  async loadData(): Promise<void> {
    this.route.queryParams.subscribe(params => {this.id = params.id});
    const formData = {
      HeadID : this.id, 
    };

    this.AppMs24Service.getMs24StaffDetailList(formData).then((res) => {
        this.dataView = res;
        this.dataSource = new MatTableDataSource(this.dataView);
        this.form = {...this.form,...res[0]};
        this.form.headExpireDate = formatDate(res[0].headExpireDate == null?null : res[0].headExpireDate,'yyyy-MM-dd',"en-US");
        this.form.headIssueDate = formatDate(res[0].headIssueDate == null?null : res[0].headIssueDate,'yyyy-MM-dd',"en-US");
        this.form.headGoodsLoadDate = formatDate(res[0].headGoodsLoadDate == null?null : res[0].headGoodsLoadDate,'yyyy-MM-dd',"en-US");
        this.form.detailGoodsDescription = res[0].invoiceProductList[0].detailGoodsDescription;
        console.log(this.form);
    });
  }

  /*async saveData(): Promise<void> {
    const formData = {
      ExporterIdRequest : this.id, 
      UPMRRequest : this.form.statusUpdateDataResponse, 
    };

    this.AppMs24Service.getExporterDataDetail(formData).then((res) => {
      this.AlertMessage.info({ title: '', message: res  });
    });
  }*/

}
