<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
    <div class="menu justify-between flex items-stretch flex-row gap-5 px-20 pr-10 pl-10">
        <div class="gap-2.5 flex flex-row">
            <div *ngIf="checkButton('BT091')">
                <button mat-raised-button color="accent" (click)="addAccount($event)">
                    <div class="flex items-center">
                        <span>Add</span>
                    </div>
                </button>
            </div>
            <div *ngIf="checkButton('BT092')">
                <button mat-raised-button color="warn" (click)="deleteSelection()">
                    <div class="flex items-center">
                        <span>Delete</span>
                    </div>
                </button>
            </div>
        </div>
        <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">

            <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0" style="flex: 0 40%;">
                <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
                        [(ngModel)]="form.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
                    <button matSuffix mat-icon-button aria-label="ค้นหา">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

    </div>
    <!-- </div> -->
    <div class="mat-elevation-z8">
        <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="select">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                        Select
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="code">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> รหัสธนาคาร </th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ชื่อธนาคาร </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- <ng-container matColumnDef="branch">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> รหัสสาขา </th>
                <td mat-cell *matCellDef="let element"> {{element.branch}} </td>
            </ng-container> -->

            <ng-container matColumnDef="isActive">
                <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> การใช้งาน </th>
                <td mat-cell *matCellDef="let element"> {{element.isActive === 'Y' ? 'ใช้งาน' : 'ไม่ใช้งาน'}} </td>
            </ng-container>


            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-left"> การจัดการ </th>
                <td mat-cell *matCellDef="let element;let i = index" class="text-left">
                    <div class="flex flex-row" style="justify-content: start;">
                        <div *ngIf="checkButton('BT093')">
                            <button mat-icon-button matTooltip="รายละเอียด" (click)="editItem(element)"
                                matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                            </button>
                        </div>
                        <div *ngIf="checkButton('BT092')">
                            <button mat-icon-button matTooltip="ลบ" (click)="delete(element)"
                                matTooltipPosition="above">
                                <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
                            </button>
                        </div>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>