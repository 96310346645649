import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RenewMs13InquiryComponent } from '../renew-ms13-inquiry/renew-ms13-inquiry.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppMs13Service } from 'app/business/service/app/app-ms13.service';
import { AppO2Service } from 'app/business/service/app/app-o2.service';
import { AuthService } from 'app/core/auth/auth.service';
import { DxDataGridComponent } from 'devextreme-angular';
import { CreateO2ModalComponent } from '../../o-two/create-o2-modal/create-o2-modal.component';

@Component({
  selector: 'app-list-renew-ms13-inquiry',
  templateUrl: './list-renew-ms13-inquiry.component.html',
  styleUrls: ['./list-renew-ms13-inquiry.component.css']
})
export class ListRenewMs13InquiryComponent implements OnInit {
  searchForm: any = {};
  form: any = {};
  productList: any = [];
  statusList: any = [];
  dataSource = new MatTableDataSource<any>();
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  displayedColumns: string[] = [
    "index",
    "docNo",
    "expireDateOld",
    "expireDateNew",
    "productName",
    "statusCode",
    "action",
  ];
  url: any;
  buttonList: any[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  @Input() typePage: string;
  type: any;
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private appO2Service: AppO2Service,
    private appMs13Service: AppMs13Service,
    private confirmService: ConfirmDialogService,
    private router: Router,
    private _authService: AuthService,
    private appMenuService: AppMenuService
  ) { }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode,
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(
      formData
    );
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    await this.getRouter();
    this.searchForm = {};
    this.loadData();
    this.searchForm.nameSearch = "";
  }

  async loadData() {
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: 0,
      isActive: "Y",
    };
    this.productList = await this.appMasterService.getProduct(formProduct);
    this.productList = this.productList.filter(
      (f) => f.productId == 3 || f.productId == 10
    );

    const formStatus = {
      statusId: 0,
      isActive: "Y",
    };
    this.statusList = await this.appMasterService.getStatus(formStatus);

    const formData = {
      UID: Number(this.userProfile.uId),
      refNo: this.searchForm.refNo ?? null,
      productId: this.searchForm.productId ?? null,
      status: this.searchForm.status ?? null,
    };

    await this.appMs13Service.getRenewMs13List(formData).then((res) => {
      res.list = res.map((x) => {
        return {
          ...x,
          action: "",
          statusCode: x.statusCode,
          productName: x.productName,
        };
      });
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  async deleteItem(e) {
    // -สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "",
      content: "คุณต้องการยกเลิกคำร้องขอต่ออายุ มส.13 หรือไม่",
    });
    // ;
    if (alertConfirm) {
      const updateForm = {
        id: e.id,
        renewId: e.renewId,
        status: e.status,
        isActive: "N",
      };
      this.appMs13Service.updateStatusRenewMs13(updateForm).then((res) => {
        this.searchOption();
      });
    }
  }

  stopPropagation(event) {
    event.stopPropagation();
  }
  // menuOpen() {}
  menuClosed() {
    console.log("menuClosed @configbug");
  }

  async searchOption() {
    const formData = {
      UID: Number(this.userProfile.uId),
      refNo: this.searchForm.refNo ?? null,
      productId: this.searchForm.productId ?? null,
      status: this.searchForm.status ?? null,
    };

    await this.appMs13Service.getRenewMs13List(formData).then((res) => {
      res.list = res.map((x) => {
        return {
          ...x,
          action: "",
          statusCode: x.statusCode,
          productName: x.productName,
        };
      });
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  clearOption() {
    this.searchForm = {};
    this.searchOption();
  }

  save() { }

  async expireO2(e) {
    console.log(e);
    // -สถานะเปลี่ยนเป็น Save to Database
    const alertConfirm = await this.confirmService.open({
      header: "ต้องการสิ้นสุดงานหรือไม่",
      content: "ยืนยันการสิ้นสุดงาน",
    });
    // ;
    if (alertConfirm) {
      const updateForm = {
        ID: e.id,
        statusDoc: 11,
        isActive: "Y",
        companyCode: this.userProfile.companyCode,
        docNo: e.docNo,
      };
      this.appO2Service.updateStatusO2(updateForm).then((res) => {
        this.searchOption();
      });
    }
  }

  renewMs13(e, isStaff, isEdit) {
    console.log(e);
    this.dialog.open(RenewMs13InquiryComponent, {
      width: '75vw',
      disableClose: true,
      data: {
        item: e,
        isStaff: isStaff,
        isDetail: isEdit
      }
    }).afterClosed().subscribe(res => {
      if (res) {
        this.loadData()
      }
    });
  }

  onRefresh() {
    this.clearOption();
  }
}
