<mat-card-header>
  <div class="flex justify-between" style="width: 100%;">
    <div class="text-lg self-center"> รายละเอียดบริษัท</div>
    <div>
      <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <i class="ti ti-x text-white"></i>
      </button>
    </div>
  </div>
</mat-card-header>
<mat-dialog-content>
  <mat-card-content>
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อบริษัท(ไทย) </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Thai Name </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.companyNameTH }}
          </mat-label>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อบริษัท(อังกฤษ) </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> English Name </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.companyNameEN }}
          </mat-label>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <!-- <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อโรงงาน </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Factory </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12" appearance="fill">
            <input matInput placeholder="ชื่อโรงงาน" [(ngModel)]="form.factoryName" name="factoryName"
              #factoryName="ngModel" disabled required>
          </mat-form-field>
        </div>
      </div> -->
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ชื่อผู้ติดต่อ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> contact </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.contact }}
          </mat-label>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> E-mail ผู้ติดต่อ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> E-mail Contact </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.email }}
          </mat-label>
        </div>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2 items-center">
        <div class="basis-1/4">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> เบอร์โทรศํพท์ผู้ติดต่อ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Phone </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-label>
            {{ form.phone }}
          </mat-label>
        </div>
      </div>
    </div>
    <div class="pl-22 pr-22 flex flex-row gap-4">
      <div class="flex flex-row gap-2.5 basis-full items-center">
        <div style="flex-basis: 12.3%">
          <div class="flex-column" style="margin-top: 24px">
            <mat-label> ที่อยู่ </mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Company Address </mat-label>
          </div>
        </div>
        <div style="flex-basis: 87.7%">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12">
            <mat-select [(ngModel)]="form.selectAddress" disabled>
              <mat-option *ngFor="let address of addrOpt" [value]="address.addressId">
                {{address.companyAddressTypeName}} - {{ address.addrStreet }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="pl-22 pr-22 flex flex-row gap-4 pt-5">
      <div class="flex gap-2.5 basis-1/2 items-center" style="flex-direction: column !important;">
        <div class="flex" style="width: 100%">
          <div div class="basis-1/4">
            <div class="flex-column">
              <mat-label> สถานะการล็อก </mat-label>
            </div>
            <div class="flex-column" style="color: #9ca3af">
              <mat-label> Block Status </mat-label>
            </div>
          </div>
          <div div class="basis-3/4">
            <mat-label class="pr-2.5"> : </mat-label>
            <mat-form-field class="w-11/12">
              <mat-select [(ngModel)]="form.lockStatus">
                <mat-option value="Y">Lock</mat-option>
                <mat-option value="N">Unlock</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <ng-container *ngIf="form.lockStatus === 'N'">
          <div class="flex" style="width: 100%">
            <div div class="basis-1/4">
              <div class="flex-column">
                <mat-label> รายการที่จะปลดล็อก <span style="color: red">*</span></mat-label>
              </div>
              <div class="flex-column" style="color: #9ca3af">
                <mat-label> Unblock Items </mat-label>
              </div>
            </div>
            <div div class="basis-3/4">
              <mat-label class="pr-2.5"> : </mat-label>
              <mat-form-field class="w-11/12">
                <mat-select [(ngModel)]="form.refBlacklistId">
                  <mat-option *ngFor="let reason of reasonList" [value]="reason.logId">
                    {{reason.logReason}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="flex flex-row gap-2.5 basis-1/2">
        <div div class="basis-1/4">
          <div class="flex-column">
            <mat-label>เหตุผลสถานะการล็อก <span style="color: red" *ngIf="form.lockStatus === 'Y'">*</span></mat-label>
          </div>
          <div class="flex-column" style="color: #9ca3af">
            <mat-label> Lock Reason </mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <mat-label class="pr-2.5"> : </mat-label>
          <mat-form-field class="w-11/12" style="height: 109.2 px;" appearance="fill">
            <textarea matInput rows="4" [(ngModel)]="form.lockReason" [required]="form.lockStatus === 'Y'"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div *ngIf="form.lockStatus === 'Y'" class="pl-22 pr-22 flex flex-row gap-4 ">
      <div class="flex flex-row gap-2.5 basis-1/2">
        <div div class="basis-1/4">
          <div class="flex-column">
            <mat-label>วันที่ <span style="color: red" *ngIf="form.lockStatus === 'Y'">*</span></mat-label>
          </div>
        </div>
        <div div class="basis-3/4">
          <div class="flex flex-row">
            <div class="basis-1/2">
              <mat-form-field appearance="fill" class="w-full p-2">
                <mat-label>วันที่เริ่มต้น | Start Date
                </mat-label>
                <input matInput [matDatepicker]="startDate" (click)="startDate.open()" readonly type="datetime"
                  [(ngModel)]="form.startDate" [max]="myDate" name="startDateSearch" (dateInput)="datecheck($event)"
                  #startDateSearch="ngModel" class="cursor-pointer">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
              </mat-form-field>
              <mat-error *ngIf="checkStartDate == false"
                class="p-1 text-sm text-red-800 absolute">วันที่เริ่มต้นไม่ถูกต้อง</mat-error>
            </div>
            <div class="basis-1/2">
              <mat-form-field appearance="fill" class="w-full p-2">
                <mat-label>วันที่สิ้นสุด | End Date</mat-label>
                <input matInput [matDatepicker]="endDate" (click)="endDate.open(); checkEndDate = true" readonly
                  type="datetime" [(ngModel)]="form.endDate" [max]="myDate" [min]="form.startDate"
                  (dateInput)="endDateCheck($event)" name="endDateSearch" #endDateSearch="ngModel"
                  class="cursor-pointer">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="endDate"></mat-datepicker-toggle>
                <mat-datepicker #endDate></mat-datepicker>
              </mat-form-field>
              <mat-error *ngIf="checkEndDate == false"
                class="p-1 text-sm text-red-800 absolute">วันที่สิ้นสุดไม่ถูกต้อง</mat-error>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="flex flex-col items-center p10 ">
      <div class="basis-1/2 pt-10">
        <button mat-raised-button style="width: 100px;" color="accent" (click)="save()">
          บันทึก
        </button>
      </div>
    </div>
  </mat-card-content>
  <hr style="margin-top: 5px" />
  <div>
    <div class="pb-5">
      <h1 style="font-size: large;">ประวัติการดำเนินการ (History Action)</h1>
    </div>
    <mat-card-content class="flex-row gap-4 pt-10">
      <div class="mat-elevation-z8" style="width: 100%;">
        <table mat-table matSort matSortActive="date" matSortDirection="desc" matSortDisableClear
          [dataSource]="dataSource2" (matSortChange)="announceSortChange($event)">
          <ng-container matColumnDef="date" style="text-align: left">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-start text-left">
              วันที่
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">
              {{ element.date | date : "yyyy-MM-dd HH:mm" }}
            </td>
          </ng-container>
          <ng-container matColumnDef="status" style="text-align: left">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-start text-left">
              สถานะ
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">
              {{ element.status == 'Y' ? 'Lock' : 'Unlock' }}
            </td>
          </ng-container>
          <ng-container matColumnDef="reason" style="text-align: left">
            <th mat-header-cell mat-sort-header *matHeaderCellDef class="justify-start text-left">
              เหตุผลสถานะการล็อก
            </th>
            <td mat-cell *matCellDef="let element" class="justify-start text-left">
              {{element.reason}}
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
          aria-label="Select page of periodic elements" (page)="pageChanged($event)">
        </mat-paginator>
      </div>
    </mat-card-content>
  </div>

</mat-dialog-content>