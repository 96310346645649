import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { AlertMessageModel, IAlertDangerConfig } from "./alert-message-model";
import { AlertMessageComponent } from "./alert-message.component";

@Injectable({
  providedIn: "root",
})
export class AlertMessageService {
  constructor(private dialog: MatDialog) {}
  open(data: AlertMessageModel): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(AlertMessageComponent, {
        width: "32vw",
        data: data,
      });
      dialogRef.afterClosed().subscribe((res) => {
        resolve(res || false);
      });
    });
  }
  info(config: IAlertDangerConfig): Promise<void> {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(AlertMessageComponent, {
        data: config,
        width: "32vw",
      });

      dialogRef.afterClosed().subscribe((res) => {
        resolve(res);
      });
    });
  }
}
