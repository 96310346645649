import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { ConfirmPopupComponent } from '../../confirm-popup/confirm-popup.component';

@Component({
  selector: 'app-discharge-port-manage-popup',
  templateUrl: './discharge-port-manage-popup.component.html',
  styleUrls: ['./discharge-port-manage-popup.component.css']
})
export class DischargePortManagePopupComponent implements OnInit {
  form: any = {};
  bankList: any;
  regionList: any[] = [];
  countryList: any[] = [];
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<DischargePortManagePopupComponent>,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService
  ) {
    if (data) {
      this.form = data;
    } else {
      this.form.isActive = 'Y';
      this.form.id = 0;
    }
    
  }
  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.appMasterService.getCountry({}).then(res => {
      if (res.length === 0) {
        this.countryList = [];
      } else {
        this.countryList = res;

        const CheckAll = this.countryList.find(element => element.name === "กรุณาเลือก");
        if (CheckAll === undefined) {
          this.countryList.unshift({ "id": 0, "name": "กรุณาเลือก" });
        }
      }
    });
  }

  save() {
    
    if ((this.form.dischargePortName !== undefined && this.form.dischargePortName !== null && this.form.dischargePortName !== "") &&
        (this.form.countryId !== 0)) {
      const data = {
        id: this.form.id,
        dischargePortName: this.form.dischargePortName,
        countryId: this.form.countryId,
        isActive: this.form.isActive
      };

      this.appMasterService.saveMasterDischargePort(data).then((res: any) => {
        if (res === "error") {
          this.alertMessageService.open({message: "บันทึกข้อมูลไม่สำเร็จ", title:""});
        } else {
          this.dialogRef.close();
          this.alertMessageService.open({message: "บันทึกข้อมูลสำเร็จ", title:""});
        }
      });

    } else {
      this.alertMessageService.open({ message: "กรุณากรอกข้อมูลให้ครบ", title: "ข้อผิดพลาด" });
    }
  }

  onChange(e) {
    if (e.checked == true) {
      this.form.isActive = "Y";
    } else {
      this.form.isActive = "N";
    }
  }

  endPopup(e) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      disableClose: false,
      // width: '30vw',
      data: {
        header: e,
        button: 'close'
      },
      maxWidth: 'none'
    });

    dialogRef.afterClosed().subscribe(res => {
      this.dialogRef.close();
    });
  }
}
