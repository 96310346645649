<mat-card style="display: flex;flex-flow: column;height: 100%; width: 100%; gap: 30px;">
  <div style="display:flex; flex-flow:row; height: 100%;">
    <!-- Start Body -->
    <div style="display:flex; flex-flow:row; width: 10%;"></div>
    <!-- Card -->
    <div style="display:flex; flex-flow:row; width: 80%;">
      <div style="display:flex; flex-flow:column; align-items: center; width: 100%;">
        <mat-card style="width: 100%;">
          <mat-card-header style="background-color: dimgray;">
            <div style="display:flex; align-items: center; font-size: 20px; color: white;">User Details</div>
          </mat-card-header>
          <mat-card-content>

            <div class="pt-10" style="display:flex; flex-flow:row; width: 100%;">

              <!-- label -->
              <div style="display:flex; flex-flow:column; width: 40%; align-items: star;">
                <mat-label class="pl-6 md:pl-20 lg:pl-20" style="display:flex; height:50px; align-items: center;">
                  Username</mat-label>
                <mat-label class="pl-6 pt-5 md:pl-20 lg:pl-20" style="display:flex; height:70px; align-items: center;">
                  Group</mat-label>
                <mat-label class="pl-6 pt-5 md:pl-20 lg:pl-20" style="display:flex; height:70px; align-items: center;">
                  Company</mat-label>
                <mat-label class="pl-6 pt-5 md:pl-20 lg:pl-20" style="display:flex; height:70px; align-items: center;">
                  Firstname</mat-label>
                <mat-label class="pl-6 pt-5 md:pl-20 lg:pl-20" style="display:flex; height:70px; align-items: center;">
                  Lastname</mat-label>
                <mat-label class="pl-6 pt-5 md:pl-20 lg:pl-20" style="display:flex; height:70px; align-items: center;">
                  Phone</mat-label>
                <mat-label class="pl-6 pt-5 md:pl-20 lg:pl-20" style="display:flex; height:70px; align-items: center;">
                  Email</mat-label>
              </div> <!-- End label -->
              <!-- Input -->
              <div style="display:flex; flex-flow:column; width: 50%;">
                <mat-form-field class="pl-4 lg:w-full">
                  <input autocomplete="off" type="text" id="Username" matInput>
                </mat-form-field>
                <mat-form-field class="pl-4 pt-5" style="height:auto; width: auto;">
                  <input autocomplete="off" type="text" id="Group" matInput>
                </mat-form-field>
                <mat-form-field class="pl-4 pt-5" style="height:auto; width: auto;">
                  <input autocomplete="off" type="text" id="Company" matInput>
                </mat-form-field>
                <mat-form-field class="pl-4 pt-5" style="height:auto; width: auto;">
                  <input autocomplete="off" type="text" id="Firstname" matInput>
                </mat-form-field>
                <mat-form-field class="pl-4 pt-5" style="height:auto; width: auto;">
                  <input autocomplete="off" type="text" id="Lastname" matInput>
                </mat-form-field>
                <mat-form-field class="pl-4 pt-5" style="height:auto; width: auto;">
                  <input autocomplete="off" type="tel" id="Phone" matInput>
                </mat-form-field>
                <mat-form-field class="pl-4 pt-5" style="height:auto; width: auto;">
                  <input autocomplete="off" type="email" id="Email" matInput>
                </mat-form-field>
              </div>
              <!-- End Input -->
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div> <!-- End Card -->

    <div style="display:flex; flex-flow:row; width: 10%;"></div>
  </div> <!-- End Body -->

  <div style="display:flex; flex-flow:row; height: 100%;">
    <!-- Start Body 2 -->
    <div style="display:flex; flex-flow:row; width: 10%;"></div>
    <!-- Card -->
    <div style="display:flex; flex-flow:row; width: 80%;">
      <div style="display:flex; flex-flow:column; align-items: center; width: 100%;">
        <mat-card style="width: 100%;">
          <mat-card-header style="background-color: dimgray;">
            <div style="display:flex; font-size: 20px; color: white;">User Authority</div>
          </mat-card-header>
          <mat-card-content>

            <div class="pt-10" style="display:flex; flex-flow:row; width: 100%;">
              <!-- label Menu -->
              <div style="display:flex; flex-flow:column; width: 30%; align-items: star;">
                <mat-label class="pl-8 font-semibold " style="display:flex; height:50px; align-items: center;">Menu
                </mat-label>
              </div> <!-- End label Menu -->

              <!-- Input -->
              <div style="display:flex; flex-flow:column; width: 70%;">
                <div class="flex flex-row">
                  <!-- Zone 1 -->
                  <div class="w-2/4">
                    <div class="flex form-check content-center">
                      <input class="appearance-none checked:bg-blue-500" type="checkbox" id="Request">
                      <label class="pl-2" for="Request">Request</label>
                    </div>
                    <div class="flex content-center pl-10 pt-2">
                      <input class="appearance-none checked:bg-blue-500" type="radio" id="Requestcocf">
                      <label class="pl-2" for="Requestcocf">Request CO / CF</label>
                    </div>
                    <div class="flex content-center pl-10 pt-2">
                      <input class="appearance-none checked:bg-blue-500" type="radio" id="Userjobrequest">
                      <label class="pl-2" for="Userjobrequest">User Job Request</label>
                    </div>
                    <div class="flex form-check content-center">
                      <input class="appearance-none checked:bg-blue-500" type="checkbox" id="Payment">
                      <label class="pl-2" for="Payment">Payment</label>
                    </div>
                    <div class="flex content-center pl-10 pt-2">
                      <input class="appearance-none checked:bg-blue-500" type="radio" id="Paymentlist">
                      <label class="pl-2" for="Paymentlist">Payment List</label>
                    </div>
                    <div class="flex form-check content-center">
                      <input class="appearance-none checked:bg-blue-500" type="checkbox" id="Print">
                      <label class="pl-2" for="Print">Print</label>
                    </div>
                    <div class="flex content-center pl-10 pt-2">
                      <input class="appearance-none checked:bg-blue-500" type="radio" id="Reprintuser">
                      <label class="pl-2" for="Reprintuser">Reprint User</label>
                    </div>
                  </div>
                  <!-- End Zone 1 -->

                  <!-- Zone 2 -->
                  <div class="w-2/4">
                    <div class="flex content-center">
                      <input class="appearance-none checked:bg-blue-500" type="checkbox" id="Configuration">
                      <label class="pl-2" for="Configuration">Configuration</label>
                    </div>
                    <div class="flex content-center pl-10 pt-2">
                      <input class="appearance-none checked:bg-blue-500" type="radio" id="BankAccount">
                      <label class="pl-2" for="BankAccount">Bank Account</label>
                    </div>
                    <div class="flex content-center pl-10 pt-2">
                      <input class="appearance-none checked:bg-blue-500" type="radio" id="Relationship">
                      <label class="pl-2" for="Relationship">Relationship</label>
                    </div>
                    <div class="flex content-center pl-10 pt-2">
                      <input class="appearance-none checked:bg-blue-500" type="radio" id="EmailConfiguration">
                      <label class="pl-2" for="EmailConfiguration">Email Configuration</label>
                    </div>
                    <div class="flex content-center pl-10 pt-2">
                      <input class="appearance-none checked:bg-blue-500" type="radio" id="Templatemanagement">
                      <label class="pl-2" for="Templatemanagement">Template Management</label>
                    </div>
                    <div class="flex content-center pl-10 pt-2">
                      <input class="appearance-none checked:bg-blue-500" type="radio" id="Usermanage">
                      <label class="pl-2" for="Usermanage">User Manage</label>
                    </div>
                  </div>
                  <!-- End Zone 2 -->
                </div>
              </div>
              <!-- End Input -->
            </div>

            <div class="pt-10" style="display:flex; flex-flow:row; width: 100%;">
              <!-- label Approved -->
              <div style="display:flex; flex-flow:column; width: 30%; align-items: star; ">
                <mat-label class="pl-8 font-semibold" style="display:flex; height:50px; align-items: center;">Approved
                </mat-label>
              </div> <!-- End label Approved -->

              <!-- Input -->
              <div class="justify-center" style="display:flex; flex-flow:column; width: 70%;">
                <div class="flex flex-row content-center">
                  <div class="flex content-center pt-2">
                    <input class="appearance-none checked:bg-blue-500" type="radio" id="Yes">
                    <label class="pl-2" for="Yes">Yes</label>
                  </div>
                  <div class="flex content-center pl-10 pt-2">
                    <input class="appearance-none checked:bg-blue-500" type="radio" id="No">
                    <label class="pl-2" for="No">No</label>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
        <div class="flex justify-center py-10 gap-10">
          <div class="mat-button">
            <button class="w-40" mat-raised-button color="accent">บันทึก</button>
          </div>
          <div class="mat-button">
            <button class="w-40" mat-raised-button color="accent" style="flex: 0 50%;">ย้อนกลับ</button>
          </div>
        </div>



      </div>
    </div> <!-- End Card -->

    <div style="display:flex; flex-flow:row; width: 10%;"></div>
  </div> <!-- End Body 2 -->
</mat-card>