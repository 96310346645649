import { Component, OnInit } from "@angular/core";
import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
} from "@angular/material/core";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppReportService } from "app/business/service/app/app-report.service";
import { MY_DATE_FORMATS } from "../report-product-workload-summary/report-product-workload-summary.component";

@Component({
  selector: "app-report-summarizing-amount-of-weight-ms24-paid-payment",
  templateUrl:
    "./report-summarizing-amount-of-weight-ms24-paid-payment.component.html",
  styleUrls: [
    "./report-summarizing-amount-of-weight-ms24-paid-payment.component.css",
  ],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class ReportSummarizingAmountOfWeightMs24PaidPaymentComponent
  implements OnInit
{
  previews: string;
  statusOptionList: any = [];
  dailyReport: any = {};

  myControl = new FormControl("");
  myDate = new Date();
  dailyReportForm: FormGroup = this.formBuilder.group({
    startDate: ["", Validators.required],
    endDate: ["", Validators.required],
    typeList: [""],
    statusList: [],
  });
  constructor(
    private alertService: AlertMessageService,
    private formBuilder: FormBuilder,
    private appReport: AppReportService,
    private appMasterService: AppMasterService
  ) {}

  async ngOnInit() {
    this.dailyReport.typeList = "0";
    this.dailyReport.statusList = "0";

    const rqStatus = {
      hCode: "STATUS",
      statusId: 0,
      isActive: "Y",
      valueList: "9,13",
    };
    await this.appMasterService.getStatus(rqStatus).then((res) => {
      this.statusOptionList = res;
    });
  }

  _params: any = {};
  updateFormSearch(e): void {
    this._params = e;
  }
  async ExportExcel() {
    this.dailyReport = this._params.value;
    await this.appReport
      .reportSummarizingAmountOfWeightMs24PaidPayment(this.dailyReport)
      .then((res) => {
        if (res !== "error") {
          this.exportExcelData(
            "รายงานสรุปปริมาณน้ำหนัก มส.24 ที่จ่ายเงินแล้วตามใบคำร้องขอชำระเงิน.xlsx",
            res
          );
        } else {
          this.alertService.info({
            title: "",
            message: "ไม่สามารถ Export รายงานได้",
          });
        }
      });
  }

  exportExcelData(fileName, data) {
    var blob = new Blob([this.stringToByteArray(atob(data))]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  }
  stringToByteArray(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  clearStartDate() {
    this.dailyReport.startDate = null;
  }

  clearEndDate() {
    this.dailyReport.endDate = null;
  }
}
