import { filter } from "rxjs";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { IcoStatus } from "app/business/interface/app/app-co.interface";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AppPaymentService } from "app/business/service/app/app-payment";
import { AppUserService } from "app/business/service/app/app-user.service";
import { AuthService } from "app/core/auth/auth.service";
import { PullJobModalComponent } from "../pull-job-modal/pull-job-modal.component";

@Component({
  selector: "app-list-payment-inquiry",
  templateUrl: "./list-payment-inquiry.component.html",
  styleUrls: ["./list-payment-inquiry.component.scss"],
})
export class ListPaymentInquiryComponent implements OnInit {
  dataSource = new MatTableDataSource<any>();
  searchString: string;
  statusList: any = [];
  statusListTemp: any = [];
  searchForm: any = {
    certificateNo: "",
    endDate: null,
    startDate: null,
    isRqStatus: "0",
    receiptNo: "",
    requestNo: "",
    invoiceReference: "",
  };
  paymentTypeList: any = [
    {
      name: "เงินสด / Bill Payment",
      code: 1,
    },
    {
      name: "E-payment",
      code: 2,
    },
  ];
  statusOptions: IcoStatus[] = [
    {
      text: "แสดงทั้งหมด",
      code: "0",
    },
    {
      text: "ยังไม่ส่งตรวจ",
      code: "1",
    },
    {
      text: "กำลังตรวจสอบ",
      code: "2",
    },
    {
      text: "ผ่านอนุมัติ",
      code: "3",
    },
  ];

  displayedColumns: string[] = [
    "refNo",
    "ms24",
    "companyName",
    "reqDate",
    "paymentAddress",
    "paymentType",
    "status",
    "action",
  ];
  url: any;
  buttonList: any[] = [];
  _liveAnnouncer: any;
  isBlacklisted: boolean = false;
  userProfile: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  count: number;
  pagination: {
    currentPage: 1;
    limitPerPage: 10;
  };
  constructor(
    private appPaymentService: AppPaymentService,
    public alert: AlertMessageService,
    public appMasterService: AppMasterService,
    public confirmService: ConfirmDialogService,
    public router: Router,
    public appUserService: AppUserService,
    public appMenuService: AppMenuService,
    public _authService: AuthService,
    private dialog: MatDialog,
    private alertService: AlertMessageService
  ) {
    this.userProfile = JSON.parse(this._authService.loginUser);
    this.pagination = {
      currentPage: 1,
      limitPerPage: 10,
    };
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;
    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode,
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(
      formData
    );
  }

  async ngOnInit() {
    await this.getRouter();
    this.userProfile.userName = this.userProfile.name;
    this.loadData();
    this.appPaymentService.getStatusPaymentList().then((r) => {
      this.statusList = r;
      this.statusListTemp = r; 
    });
  }

  loadData() {
    const formData = {
      startDate: this.searchForm.issueDate,
      endDate: this.searchForm.expireDate,
      refNo: this.searchForm.refNo,
      paymentType: this.searchForm.paymentType,
      status: this.searchForm.status,
      docNo: this.searchForm.docNo,
      pagination: this.pagination,
    };
    this.appPaymentService.getPaymentList(formData).then((res) => { 
      this.dataSource = new MatTableDataSource(res.searchDataPaymentRespons);
      this.count = res.count;
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator.length = this.count;
      this.paginator.length = this.count;
    });
    this.statusList = this.statusListTemp; 
  }

  applyFilter($event) {
    const filterValue = ($event.target as HTMLInputElement).value; 
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.searchForm.refNo = filterValue;
    if (filterValue == null || filterValue == "") {
      this.clearOption();
      this.pagination.currentPage = 1;
    } else {
      this.pagination.currentPage = 1;
      this.loadData();
    }
  }

  setupFilter(column: string) {}

  stopPropagation(event) {
    event.stopPropagation();
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  clearOption() {
    this.searchForm = {};
    this.loadData();
  }

  updateStatusPayment(e, status) {
    if (e.status == 2 || e.status == 1) {
      this.confirmService
        .open({
          header: "ยืนยันการทำรายการ",
          content: "ต้องการลบใบชำระเงิน",
        })
        .then((res) => {
          if (res == true) {
            this.appPaymentService
              .updateStatusPayment({
                pay_hid: e.hid,
                stage: status,
                description: null,
                createBy: this.userProfile.uId,
              })
              .then((res) => {
                this.alert
                  .open({
                    title: "ลบใบชำระเงิน",
                    message: "ลบใบชำระเงินสำเร็จ",
                  })
                  .then((res) => {
                    this.loadData();
                  });
              });
          }
        });
    } else if (e.status == 3) {
      this.alert
        .open({
          message:
            "ไม่สามารถลบรายการชำระเงินได้ เนื่องจากสถานะการชำระเงินถูกเปลี่ยนในระบบแล้ว",
          title: "",
        })
        .then((res) => {
          this.loadData();
          return;
        });
    }
  }

  pullPaymentFromCSC(e) {
    console.log("ee", e);
    // var actionReq = {
    //   id: e.hid,
    //   action: "PULL",
    //   refTable: "DOCUMENT_PAYMENT_REQUEST",
    // };

    // this.appMasterService.getStatus(actionReq).then((res) => {
    //   if (res === "error") {
    //     return;
    //   } else {
    //     var data = res;

    //     if (data.allowAction === "Y") {
    const dialogRef = this.dialog.open(PullJobModalComponent, {
      disableClose: false,
      width: "45vw",
      data: {
        ref_no: e.refNo,
        description: "ดึงงานกลับ",
      },
      maxWidth: "none",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.remark != undefined) {
        var datareq = {
          doc_no: [e.refNo],
          cause_code: res.code,
          cause_description: res.remark,
        };
        this.appPaymentService.pullPaymentFromCSC(datareq).then((res) => {
          console.log("c", res);
          if (res.status == "0000") {
            this.alert
              .open({
                title: "ดึงงานกลับเรียบร้อย",
                message: res.description,
              })
              .then((s) => {
                this.loadData();
              });
          } else if (res.status == "0001") {
            this.alert
              .open({
                title: "ไม่พบใบชำระเงิน",
                message: "ไม่พบใบชำระเงิน " + e.refNo,
              })
              .then((s) => {
                this.loadData();
              });
          } else {
            this.alert
              .open({
                title: e.message,
                message: "ไม่สามรถดึงงานกลับได้",
              })
              .then((s) => {
                this.loadData();
              });
          }
        });
      }
    });
    //     } else {
    //       this.alert
    //         .open({
    //           message:
    //             "ไม่สามารถดึงรายการชำระเงินกลับได้ เนื่องจากสถานะการชำระเงินถูกเปลี่ยนในระบบแล้ว",
    //           title: "",
    //         })
    //         .then((res) => {
    //           this.loadData();
    //           return;
    //         });
    //     }
    //   }
    // });
  }

  exportPDFBillPayment(id) {
    this.appPaymentService
      .exportBillPaymentUpdate({ pay_hid: id })
      .then((res) => {
        if (res != null) {
          window.open(res.linkUrl, "_blank");
        }
      });

    // this.appPaymentService.exportBillPayment({ pay_hid: id }).then((res) => {
    //   if (res != null) {
    //     window.open(res.linkUrl, "_blank");
    //   }
    // });
  }

  exportPDFReqPayment(id) {
    this.appPaymentService.exportPDFReqPayment({ pay_hid: id }).then((res) => {
      if (res != null) {
        window.open(res.pathPdf, "_blank");
      }
    });
  }

  createPayment() {
    if (this.userProfile.userGroupCode != "UG004") {
      this.router.navigateByUrl("/page/payment/create-payment");
    } else {
      this.alertService
        .open({
          message: "User ของคุณไม่สามารถสร้างใบชำระเงินได้",
          title: "",
        })
        .then((res) => {
          // this._location.back();
        });
    }
  }

  editPayment(e) {
    // var actionReq = {
    //   id: e.hid,
    //   action: "EDIT",
    //   refTable: "DOCUMENT_PAYMENT_REQUEST",
    // };

    // this.appMasterService.getStatus(actionReq).then((res) => {
    //   if (res === "error") {
    //     return;
    //   } else {
    //     var data = res;
    //     if (data.allowAction === "Y") {
    this.router.navigate(["/page/payment/create-payment-inquiry"], {
      queryParams: {
        pay_hid: e.hid,
        disabled: false,
      },
    });
    // } else {
    //   this.alert
    //     .open({
    //       message:
    //         "ไม่สามารถแก้ไขรายการชำระเงินได้ เนื่องจากสถานะการชำระเงินถูกเปลี่ยนในระบบแล้ว",
    //       title: "",
    //     })
    //     .then((res) => {
    //       this.loadData();
    //       return;
    //     });
    // }
    //   }
    // });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  async _page(e) {
    this.pagination.currentPage = e.pageIndex + 1;
    this.pagination.limitPerPage = e.pageSize;
    const formData = {
      startDate: this.searchForm.issueDate,
      endDate: this.searchForm.expireDate,
      refNo: this.searchForm.refNo,
      paymentType: this.searchForm.paymentType,
      status: this.searchForm.status,
      docNo: this.searchForm.docNo,
      pagination: this.pagination,
    };
    this.appPaymentService.getPaymentList(formData).then((res) => {
      console.log("_page getPaymentList", res);
      this.dataSource = new MatTableDataSource(res.searchDataPaymentRespons);
      this.count = res.count;
    });
    this.pagination.limitPerPage = !e.pageSize ? 10 : e.pageSize;
    this.dataSource.paginator.length = this.count;
    console.log("_page this.count", this.count);
  }
}
