<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">

    <div
        class="menu justify-between flex items-stretch flex-row gap-5 px-20  pl-10  pr-10">
        <div class="gap-2.5 flex flex-row">
            <div *ngIf="checkButton('BT087')">
                <button mat-raised-button color="accent"
                    (click)="addAccount($event)" style="height: 48px">
                    <div class="flex" style="gap: 5px;">
                        <i class="ti ti-square-plus"
                            style="font-size: 1.5em;"></i>
                        <span class="self-center">เพิ่มรายการใหม่</span>
                    </div>
                </button>
            </div>
            <div *ngIf="checkButton('BT088')">
                <button mat-raised-button color="warn"
                    (click)="deleteSelection()" style="height: 48px">
                    <div class="flex" style="gap: 5px;">
                        <i class="ti ti-square-x" style="font-size: 1.5em;"></i>
                        <span class="self-center">ลบรายการที่เลือก</span>
                    </div>
                </button>
            </div>
        </div>
        <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">

            <div class="justify-evenly flex gap-2.5 items-center flex-row gap-0"
                style="flex: 0 40%;">
                <mat-form-field style="flex: 1"
                    class="my-form-field flex flex-row">
                    <input autocomplete="off" type="text" matInput
                        placeholder="คำค้นหา" matInput #input
                        [(ngModel)]="form.search" name="search"
                        #search="ngModel" (keyup)="applyFilter($event)">
                    <button matSuffix mat-icon-button aria-label="ค้นหา">
                        <mat-icon>search</mat-icon>
                    </button>
                </mat-form-field>
            </div>
        </div>

    </div>
    <!-- </div> -->
    <div class="mat-elevation-z8">
        <table mat-table matSort [dataSource]="dataSource"
            (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="select">
                <th mat-header-cell mat-sort-header *matHeaderCellDef
                    class="text-left">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                        Select
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <!-- Position Column -->
            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header
                    sortActionDescription="Sort by number"
                    class="text-left"> ตัวย่อ
                </th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
                <th mat-header-cell mat-sort-header *matHeaderCellDef
                    class="text-left"> ชื่อ </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <!-- Symbol Column -->
            <ng-container matColumnDef="desc">
                <th mat-header-cell mat-sort-header *matHeaderCellDef
                    class="text-left"> รายละเอียด </th>
                <td mat-cell *matCellDef="let element"> {{element.desc}} </td>
            </ng-container>

            <ng-container matColumnDef="qtyNone">
                <th mat-header-cell mat-sort-header *matHeaderCellDef
                    class="text-left"> Quantity None </th>
                <td mat-cell *matCellDef="let element"> {{element.qtyNone}}
                </td>
            </ng-container>

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="text-left">
                    การจัดการ </th>
                <td mat-cell *matCellDef="let element;let i = index"
                    class="text-left">
                    <div class="flex flex-row" style="justify-content: start;">
                        <button mat-icon-button matTooltip="รายละเอียด"
                            *ngIf="checkButton('BT089')"
                            (click)="editItem(element)"
                            matTooltipPosition="above">
                            <mat-icon
                                svgIcon="heroicons_outline:pencil-alt"></mat-icon>
                        </button>
                        <button mat-icon-button matTooltip="ลบ"
                            *ngIf="checkButton('BT088')"
                            (click)="delete(element)"
                            matTooltipPosition="above">
                            <mat-icon
                                svgIcon="heroicons_outline:trash"></mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]"
            showFirstLastButtons
            aria-label="Select page of periodic elements">
        </mat-paginator>
    </div>
</div>