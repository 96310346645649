import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.css']
})
export class PasswordChangeComponent implements OnInit {
  form: any = {};
  bankList: any;
  userProfile: any;
  attachType: any[] = [];
  fieldType: any[] = [];
  buttonList: any[] = [];
  dataUser: any = {};

  constructor(
    public dialogRef: MatDialogRef<PasswordChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appMasterService: AppMasterService,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService,
    private appUserService: AppUserService,
    private _authService: AuthService
  ) {
    this.dataUser = data;
  }

  ngOnInit(): void {

  }

  save() {
    this.userProfile = JSON.parse(
      this._authService.loginUser
    );
    let uId: number = 0;

    if (this.dataUser != undefined && this.dataUser != null) {
      uId = this.dataUser.userId;
    } else {
      uId = this.userProfile.uId;
    }

    var reqData = {
      uId: uId,
      newPassword: this.form.password
    };

    this.appUserService.saveNewPassword(reqData).then((res: any) => {
      if (res === 'error') {
        this.alertMessageService.open({ message: "เกิดข้อผิดพลาดระหว่างบันทึกข้อมูล", title: "" });
      } else {
        this.dialogRef.close(true);
        this.alertMessageService.open({ message: "เปลี่ยนรหัสผ่านสำเร็จ", title: "" });
      }
    })
  }
}
