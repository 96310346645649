import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppUserService } from 'app/business/service/app/app-user.service';
import { DxDataGridComponent } from 'devextreme-angular';
import * as moment from 'moment';
import { UserRoleModalComponent } from '../user-role-modal/user-role-modal.component';
import { AuthService } from 'app/core/auth/auth.service';

@Component({
  selector: 'app-user-role-list',
  templateUrl: './user-role-list.component.html',
  styleUrls: ['./user-role-list.component.scss']
})
export class UserRoleListComponent implements OnInit {
  userRoleListDataSource: any[];
  lookupStatusCode: any[];
  @Input() filter: any;
  url;
  buttonList: any;
  userToken:any;
  @ViewChild(DxDataGridComponent, { static: false }) dataGrid: DxDataGridComponent;
  constructor(
    private dialog: MatDialog,
    private appUserService: AppUserService,
    private appMenuService: AppMenuService,
    private router: Router,
    private _authService:AuthService,
  ) {
    this.userRoleListDataSource = new Array();
   }

   async ngOnInit() {
    this.userToken = JSON.parse(
      this._authService.loginUser
    );
    await this.getRouter();
    this.setSubcribe();

  }

  getUserRoleList(): void {
    this.appUserService.getUserRoleList(this.filter);
  }

  async getRouter(): Promise<void>{
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userToken.userGroupCode,
      userRoleCode: this.userToken.userRoleCode,
      userCode: this.userToken.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }
  setSubcribe(): void {
    this.lookupStatusCode = [
      { code: "Y", name: "ใช้งาน" },
      { code: "N", name: "ยกเลิก" },
    ];

    this.appUserService.userRoleList.subscribe(res => {
      if (res && res.length > 0) {
        this.userRoleListDataSource = res;

        this.userRoleListDataSource.forEach((item, i) => {
          item.createDate = item.createDate ? moment(item.createDate).format('DD/MM/YYYY') : '';
          item.updateDate = item.updateDate ? moment(item.updateDate).format('DD/MM/YYYY') : '';
        });

      } else {
        this.userRoleListDataSource = new Array();
      }
    });
  }

  editUserRole(row: any): void {
    
    const userModalRef = this.dialog.open(UserRoleModalComponent, {
      disableClose: true,
      width: '60vw',
      data: row
    });

    userModalRef.afterClosed().subscribe(res => {
      this.getUserRoleList();
    });
  }
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
