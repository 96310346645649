<mat-card style="display: flex; flex-flow: column; height: 100%; width: 100%; gap: 30px">
  <!-- <app-head-o2 [type]="'dailyreport'"></app-head-o2> -->
  <div style="display: flex; flex-flow: row;">
    <div style="display: flex; flex-flow: row; width: 10%"></div>
    <div style="display: flex; flex-flow: row; width: 80%">
      <mat-card class="w-full py-10">
        <div style="display: flex; flex-flow: row; height: 100%">
          <!-- Start Body 2 -->
          <div style="display: flex; flex-flow: row; width: 5%"></div>
          <!-- Card -->
          <div style="display: flex; flex-flow: row; width: 90%">
            <div style=" display: flex; flex-flow: column; align-items: center; width: 100%;">
              <mat-card style="width: 100%">
                <mat-card-header style="background-color: dimgray;">
                  <div style=" display: flex; align-items: left; font-size: 20px; color: white;">
                    รายงาน มส.13 ทั้งหมดที่ได้รับอนุมัติและได้มาขอออก มส.24
                  </div>
                </mat-card-header>
                <mat-card-content>
                  <app-report-filter (formSearch) = "updateFormSearch($event)"></app-report-filter>
                  <div class="flex flex-column items-center pl-2.5 pt-5">
                    <div class="flex flex-row basis-1/2 f18 text-lg  justify-end">                  
                        <button 
                            type="submit"
                            mat-raised-button 
                            color="accent" 
                            class="h-12 px-6 m-2 text-lg rounded-lg  justify-right"
                            (click)="ExportExcel()"
                            [disabled]="_params.pristine || _params.invalid"
                        >
                            Export Excel
                        </button>
                    </div>
                    <div class="flex flex-row basis-1/2 f18  text-lg">
                        <button 
                            mat-raised-button color="accent" 
                            class="h-12 px-6 m-2 text-lg rounded-lg"
                            (click)="ExportPdf()"
                            [disabled]="_params.pristine || _params.invalid"
                            >
                            Export PDF
                        </button>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <!-- End Card -->
          <div style="display: flex; flex-flow: row; width: 5%"></div>
        </div>
      </mat-card>
    </div>
    <div style="display: flex; flex-flow: row; width: 10%"></div>
    <!-- End Body 2 -->
  </div>
</mat-card>
