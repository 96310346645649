import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-email-config-agreement-dialog',
  templateUrl: './email-config-agreement-dialog.component.html',
  styleUrls: ['./email-config-agreement-dialog.component.css']
})
export class EmailConfigAgreementDialogComponent implements OnInit {
  agreementCheck: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<EmailConfigAgreementDialogComponent>
  ) { }

  ngOnInit() {
  }

  save() {
    
    this.dialogRef.close(this.agreementCheck);
  }
}
