import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
  providedIn: 'root'
})
export class ApiO2Service {

  constructor(private apiService: ApiService) { }

  o2List(data: any): Observable<any[]> {
    return this.apiService.post('/api/o2List', data);
  }

  getDataO2(data: any): Observable<any[]> {
    return this.apiService.post('/api/getDataO2', data);
  }

  saveO2(data): Observable<any[]> {
    return this.apiService.post('/api/O2save', data);
  }

  saveO2Online(data): Observable<any[]> {
    return this.apiService.post('/api/saveO2Online', data);
  }

  saveO2Manual(data): Observable<any[]> {
    return this.apiService.post('/api/saveO2Manual', data);
  }

  updateStatusO2(data: any): Observable<any[]> {
    return this.apiService.post('/api/updateStatusO2', data);
  }

  updateStatusRenewO2(data: any): Observable<any[]> {
    return this.apiService.post('/api/updateStatusRenewO2', data);
  }

  renewO2sent(data: any): Observable<any[]> {
    return this.apiService.post('/api/renewO2sent', data);
  }

  getrenewO2(data: any): Observable<any[]> {
    return this.apiService.post('/api/getrenewO2', data);
  }

  O2itemsave(data: any): Observable<any[]> {
    return this.apiService.post('/api/O2itemsave', data);
  }

  getO2item(data: any): Observable<any[]> {
    return this.apiService.post('/api/getO2item', data);
  }

  getdataO2(data: any): Observable<any[]> {
    return this.apiService.post('/api/getdataO2', data);
  }

  checkO2DFTOnline(data: any): Observable<any[]> {
    return this.apiService.post('/api/checkO2DFTOnline', data);
  }

  getDataDftO2(data: any): Observable<any[]> {
    return this.apiService.post('/api/getDataDftO2', data);
  }

  delO2Item(data: any): Observable<any[]> {
    return this.apiService.post('/api/delO2Item', data);
  }

  delImg(data: any): Observable<any[]> {
    return this.apiService.post('/api/delImg', data);
  }

  getRenewO2List(data: any): Observable<any[]> {
    return this.apiService.post('/api/getRenewO2List', data);
  }

  checkDupDocNo(data: any): Observable<any[]> {
    return this.apiService.post('/api/checkDupDocNo', data);
  }

  deleteO2Img(request: any): Observable<any> {
    return this.apiService.post('/api/deleteO2Img', request);
  }

  recalculateO2FobData(request: any): Observable<any> {
    return this.apiService.post('/api/recalculateO2FobData', request);
  }

  updateO2ApproveStatus(request: any): Observable<any> {
    return this.apiService.post('/api/updateO2ApproveStatus', request);
  }
}
