<mat-card>
 <mat-card-header  class="fontTitleHeader" >
    <div class="flex justify-between" style="width: 100%;">
      <div  class="fontTitleModal" > ตราประทับ </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="flex flex-col">
      <div class="flex flex-row">
        <!-- <div class="basis-3/12 p10" style="align-self: center;">ตราประทับ </div> -->
        <div class="basis-1/2 f18 p10">
          <mat-form-field class="my-form-field formm w100">
            <mat-label></mat-label>
            <mat-select [(ngModel)]="form.shippIdd" (selectionChange)="setShippingMaskList($event)" name="docNo" #docNo="ngModel" ngmodel>
              <mat-option *ngFor="let shipp of shippingMaskList" [value]="shipp.id">
                {{shipp.no}} 
              </mat-option>
            </mat-select>
            <mat-error>ประเภทสินค้า</mat-error>
          </mat-form-field> 
        </div>
        <div class="basis-1/2 f18 p10">
          <img with="10" height="10" src="{{form.paht}}"> 
        </div> 
      </div>
    </div>
  </mat-card-content>
  <div class="flex flex-col items-center p10">
    <div class="basis-1/2 f18">
      <button mat-raised-button style="width: 100px;" color="accent" [mat-dialog-close]="form">
        ตกลง
      </button>
    </div>
  </div>
</mat-card>