import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-management-inquiry',
  templateUrl: './user-management-inquiry.component.html',
  styleUrls: ['./user-management-inquiry.component.scss']
})
export class UserManagementComponent implements OnInit {

  constructor() { 

  }

  ngOnInit():void {
  }

}
