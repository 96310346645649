import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatNumber' })
export class FormatNumberPipe implements PipeTransform {
    transform(value: number): string {
        if (isNaN(value)) {
            return ''; // Handle non-numeric input gracefully
        }

        // Set locale to ensure proper thousands separator (comma)
        const locale = navigator.languages ? navigator.languages[0] : 'en-US';
        return new Intl.NumberFormat(locale, { style: 'decimal', minimumFractionDigits: 0 }).format(value);
    }
}
