<div>
  <form #FormValidateItem="ngForm">
    <div style="padding: 0px 10px 0px 10px;">
      <!-- MENU BAR -->
      <div class="flex flex-col p10">
        <mat-card class="flex flex-row" style="width: 100%; gap: 5px; padding: 5px;">
          <button mat-icon-button matTooltip="ย้อนกลับ" matTooltipPosition="above"
            style="width: 2em !important; height: 2em!important;" (click)="backPage()">
            <i class="ti ti-chevron-left"></i>
          </button>
          <!-- ปุ่มสำหรับ Surveyor คอมเม้นท์ไว้ก่อน เพราะยังไม่ได้กำหนดสิทธิ์ -->
          <button *ngIf="checkButton('BT119') && !disabled" mat-icon-button matTooltip="คืนค่าเดิม"
            matTooltipPosition="above" style="width: 2em !important; height: 2em !important;" (click)="reset()">
            <i class="ti ti-restore"></i>
          </button>
          <button *ngIf="checkButton('BT120') && !disabled && !FormValidateItem.valid" mat-icon-button
            matTooltip="บันทึกฉบับร่าง" matTooltipPosition="above"
            style="width: 2em !important; height: 2em !important;" (click)="save(1)">
            <i class="ti ti-notes"></i>
          </button>
        </mat-card>
      </div>

      <!-- SOURCE -->
      <div class="flex flex-col p10">
        <mat-card class="flex" style="gap: 15px; padding: 15px;">
          <div class="flex" style="gap: 10px;">
            <div class="flex-1 w-33" style="align-self: center;">
              <div class="flex-1 flex-row">
                <div class="basis-1/2 f18" style="align-self: center;">Source : {{source == "M" ? "Manual" : "Online"}}
                </div>
              </div>
            </div>
            <div class="flex-1 w-33" style="align-self: center;">
              <div class="flex-1 flex-row w100">
                <div class="basis-1/2 f18" style="align-self: center;">ประเภทสินค้า : {{form.productName}}
                </div>
              </div>
            </div>
            <div class="flex-1 w-33" style="align-self: center;">
              <div class="flex-1 flex-row w100">
                <ng-container *ngIf="!(checkO2Status())">
                  <div class="f18" style="align-self: center;">
                    สถานะ : {{form.statusName}}
                  </div>
                </ng-container>
                <ng-container *ngIf="checkO2Status()">
                  <div class="f18 flex" style="gap: 6px;">
                    <div class="f18" style="align-self: center; width: 20%;">
                      สถานะ :
                    </div>
                    <mat-form-field class="my-form-field formm w100">
                      <mat-select [(ngModel)]="formStatus.status" name="status" #status="ngModel" ngmodel
                        (selectionChange)="checkStatus($event)">
                        <mat-option *ngFor="let status of statusList" [value]="status.statusId">
                          {{status.statusName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                    <button mat-icon-button matTooltip="บันทึกสถานะ" (click)="updateStatusApprove()"
                      matTooltipPosition="above" style="width: 48px !important;">
                      <i class="ti ti-edit-circle"></i>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="flex" style="gap: 10px;">
            <div class="flex-1 w-33">
              <div class="flex flex-row">
                <div class="basis-1/2 f18" style="align-self: center;"> ใบอนุญาตกระทรวงพาณิชย์ | พณ : <label
                    style="color:red">*</label></div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input required autocomplete="off" [disabled]="disabled" matInput maxlength="13"
                      placeholder=" ใบอนุญาตกระทรวงพาณิชย์ | พณ " [(ngModel)]="form.docNo" name="docNo"
                      #docNo="ngModel">
                    <mat-error>กรุณาระบุ</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-33">
              <div class="flex flex-row">
                <div class="basis-1/2 f18" style="align-self: center;">วันที่ออกใบอนุญาต : <label
                    style="color:red">*</label></div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input required autocomplete="off" required [disabled]="disabled" placeholder="วันที่ออกใบอนุญาต"
                      matInput [matDatepicker]="startDates" [(ngModel)]="form.issueDate" ngModel name="issueDate"
                      #issueDate="ngModel" readonly>
                    <mat-datepicker-toggle matSuffix [for]="startDates"></mat-datepicker-toggle>
                    <mat-datepicker #startDates></mat-datepicker>
                    <mat-error>กรุณาระบุ</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18" style="align-self: center;"> &nbsp;(ปี-เดือน-วัน) </div>
              </div>
            </div>
            <div class="flex-1 w-33">
              <div class="flex flex-row">
                <div class="basis-1/2 f18" style="align-self: center;">วันหมดอายุ : <label style="color:red">*</label>
                </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input required autocomplete="off" required [disabled]="disabled" placeholder="วันหมดอายุ" matInput
                      [matDatepicker]="exDates" [(ngModel)]="form.expireDate" name="expireDate" #expireDate="ngModel"
                      readonly>
                    <mat-datepicker-toggle matSuffix [for]="exDates"></mat-datepicker-toggle>
                    <mat-datepicker #exDates></mat-datepicker>
                    <mat-error>กรุณาระบุ</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18" style="align-self: center;"> &nbsp;(ปี-เดือน-วัน) </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>

      <fieldset>
        <legend>ข้อมูล อ.2</legend>
        <!-- ผู้ส่งออก (Exporter Information) -->
        <div class="flex flex-col p10">
          <mat-card>
            <mat-card-header class="fontTitleHeader">
              ผู้ส่งออก (Exporter Information)
            </mat-card-header>
            <mat-card-content class="example-headers-align" style="padding: 16px;">
              <div class="flex">
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เลขประจำตัวผู้ส่งออก : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput
                          placeholder="เลขประจำตัวผู้ส่งออก" [maxLength]="5" #input [(ngModel)]="form.exporterNo"
                          name="exporterNo" #exporterNo="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เลขที่ประจำตัวผู้เสียภาษี :
                      <label style="color:red">*</label>
                    </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input required autocomplete="off" required [disabled]="disabled" type="text" matInput
                          placeholder="เลขที่ประจำตัวผู้เสียภาษี" #input [minLength]="13" [maxLength]="17"
                          [(ngModel)]="form.companyTax" name="companyTax" #companyTax="ngModel">
                        <mat-error>กรุณาระบุ</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ชื่อบริษัทผู้ส่งออก (ภาษาไทย) : <label
                        style="color:red">*</label></div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input type="text" matInput [formControl]="companyExporterSearch"
                          [matAutocomplete]="autoaxxxxrr">
                        <mat-autocomplete #autoaxxxxrr="matAutocomplete" [displayWith]="displayCompanyExporter"
                          (optionSelected)="selectionCompanyExporterChange($event)">
                          <mat-option *ngFor="let option of filteredOptionsCompanyExporter$ | async" [value]="option">
                            {{option.nameTH}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ชื่อบริษัทผู้ส่งออก (ภาษาอังกฤษ) : <label
                        style="color:red">*</label></div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input required autocomplete="off" disabled type="text" matInput
                          placeholder="ชื่อบริษัทผู้ส่งออก (ภาษาอังกฤษ)" #input [(ngModel)]="form.companyEnName"
                          name="companyEnName" #companyEnName="ngModel">
                        <mat-error>กรุณาระบุ</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เลขที่อยู่บริษัท / ถนน : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput
                          placeholder=" เลขที่อยู่บริษัท / ถนน " #input [(ngModel)]="form.exporterStreetandNo"
                          name="exporterStreetandNo" #exporterStreetandNo="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> จังหวัด : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="จังหวัด"
                          #input [(ngModel)]="form.exporterProvince" name="exporterProvince"
                          #exporterProvince="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> แขวง (ตำบล) : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder=" แขวง (ตำบล) "
                          #input [(ngModel)]="form.exporterDistrict" name="exporterDistrict"
                          #exporterDistrict="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> รหัสไปรณีย์ : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="รหัสไปรณีย์"
                          #input [(ngModel)]="form.exporterPostcode" name="exporterPostcode"
                          #exporterPostcode="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เขต (อำเภอ) : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder=" เขต (อำเภอ) "
                          #input [(ngModel)]="form.exporterSubProvince" name="exporterSubProvince"
                          #exporterSubProvince="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <!-- ข้อมูลผู้กระทำการแทนผู้ส่งออก (Shipper Information) -->
        <div class="flex flex-col p10">
          <mat-card>
            <mat-card-header class="fontTitleHeader">
              ข้อมูลผู้กระทำการแทนผู้ส่งออก (Shipper Information)
            </mat-card-header>
            <mat-card-content class="example-headers-align">
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ชื่อบริษัท (ภาษาไทย) : </div>
                    <div class="basis-4/5">
                      <div>
                        <mat-form-field class="w100">
                          <input type="text" matInput [disabled]="disabled" [formControl]="companyShipperSearch"
                            [matAutocomplete]="autoax1">
                          <mat-autocomplete #autoax1="matAutocomplete" [displayWith]="displayCompanyShipper"
                            (optionSelected)="selectionCompanyShipperChange($event)">
                            <mat-option *ngFor="let option of filteredOptionsCompanyShipper$ | async" [value]="option">
                              {{option.nameTH}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เลขที่อยู่บริษัท / ถนน : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput
                          placeholder="เลขที่อยู่บริษัท / ถนน" #input [(ngModel)]="form.shippingStreetNo"
                          name="shippingStreetNo" #shippingStreetNo="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> จังหวัด : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="จังหวัด"
                          #input [(ngModel)]="form.shippingProvince" name="shippingProvince"
                          #shippingProvince="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> แขวง (ตำบล) : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="แขวง (ตำบล)"
                          #input [(ngModel)]="form.shippingDistrict" name="shippingDistrict"
                          #shippingDistrict="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> รหัสไปรณีย์ : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="รหัสไปรณีย์"
                          #input [(ngModel)]="form.shippingPostcode" name="shippingPostcode"
                          #shippingPostcode="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เขต (อำเภอ) : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="เขต (อำเภอ)"
                          #input [(ngModel)]="form.shippingSubProvince" name="shippingSubProvince"
                          #shippingSubProvince="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <!-- ข้อมูลบริษัทผู้รับสินค้า (ผู้ซื้อ) (Receiver Information) -->
        <div class="flex flex-col p10">
          <mat-card>
            <mat-card-header class="fontTitleHeader">
              ข้อมูลบริษัทผู้รับสินค้า (ผู้ซื้อ) (Receiver Information)
            </mat-card-header>
            <mat-card-content class="example-headers-align">
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ชื่อบริษัทผู้รับสินค้า : <label
                        style="color:red">*</label></div>
                    <div class="basis-4/5">
                      <div>
                        <mat-form-field class="w100">
                          <input type="text" matInput [disabled]="disabled" [formControl]="companyReceiverSearch"
                            [matAutocomplete]="autoaxss1">
                          <mat-autocomplete #autoaxss1="matAutocomplete" [displayWith]="displayCompanyReceiver"
                            (optionSelected)="selectionCompanyReceiverChange($event)">
                            <mat-option *ngFor="let option of filteredOptionsCompanyReceiver$ | async" [value]="option">
                              {{option.nameEN}}
                            </mat-option>
                          </mat-autocomplete>
                          <button mat-icon-button matTooltip="ลบ" (click)="cancelReceiverItem()"
                            style="width: 48px !important; margin-right: -10px" *ngIf="selectedReceiverItem">
                            <i class="ti ti-backspace" style="color: #8e9db1;"></i>
                          </button>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เลขที่อยู่บริษัท / ถนน : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput
                          placeholder="เลขที่อยู่บริษัท / ถนน" #input [(ngModel)]="form.recieveStreetNo"
                          name="recieveStreetNo" #recieveStreetNo="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> จังหวัด : </div>
                    <div class="basis-4/5">
                      <!-- <mat-form-field class="my-form-field formm w100">
                      <mat-select [(ngModel)]="form.recieveProvince" [disabled]="disabled" name="recieveProvince"
                        #recieveProvince="ngModel" ngmodel>
                        <mat-option *ngFor="let province of provinceList" [value]="province.name">
                          {{province.name}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field> -->
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="จังหวัด"
                          #input [(ngModel)]="form.recieveProvince" name="recieveProvince" #recieveProvince="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> แขวง (ตำบล) : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="แขวง (ตำบล)"
                          #input [(ngModel)]="form.recieveDistrict" name="recieveDistrict" #recieveDistrict="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> รหัสไปรณีย์ : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="รหัสไปรณีย์"
                          #input [(ngModel)]="form.recievePostcode" name="recievePostcode" #recievePostcode="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เขต (อำเภอ) : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="เขต (อำเภอ)"
                          #input [(ngModel)]="form.recieveSubProvince" name="recieveSubProvince"
                          #recieveSubProvince="ngModel">
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ประเทศ :</div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input type="text" matInput [disabled]="disabled" [formControl]="countryRecieveSearch"
                          [matAutocomplete]="autoax2">
                        <mat-autocomplete #autoax2="matAutocomplete" [displayWith]="displayCountryReceive"
                          (optionSelected)="selectionCountryRecieveChange($event)">
                          <mat-option *ngFor="let option of filteredOptionsRecieveCountry$ | async" [value]="option">
                            {{option.code}} : {{option.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <!-- ข้อมูลอื่น ๆ (Other Information) -->
        <div class="flex flex-col p10">
          <mat-card>
            <mat-card-header class="fontTitleHeader">
              ข้อมูลอื่น ๆ (Other Information)
            </mat-card-header>
            <mat-card-content class="example-headers-align">
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ประเทศผู้ซื้อ (ตามมาตรฐาน ISO)
                      <label style="color:red" *ngIf="typePage == 'o2'">*</label> :
                    </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w96">
                        <input type="text" matInput [required]="typePage == 'o2'" [formControl]="countrySearch"
                          [matAutocomplete]="autoss">
                        <mat-autocomplete #autoss="matAutocomplete" (optionSelected)="selectionCountryChange($event)"
                          [displayWith]="displayCountry">
                          <mat-option *ngFor="let option of filteredOptionsCountry$ | async" [value]="option">
                            {{option.code}} : {{option.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ท่าเรือสถานที่ส่งออก : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100">
                        <mat-select (selectionChange)="setPort($event)" [disabled]="disabled" [(ngModel)]="form.port"
                          name="port" #port="ngModel" ngmodel>
                          <mat-option *ngFor="let port of portList" [value]="port.id">
                            {{port.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ประเทศปลายทาง (ตามมาตรฐาน ISO)
                      <label style="color:red" *ngIf="typePage == 'o2'">*</label> :
                    </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w96">
                        <input type="text" matInput [required]="typePage == 'o2'" [formControl]="countryISOSearch"
                          [matAutocomplete]="autosx">
                        <mat-autocomplete #autosx="matAutocomplete" [displayWith]="displayCountryIso"
                          (optionSelected)="selectionCountryISOChange($event)">
                          <mat-option *ngFor="let option of filteredOptionsISOCountry$ | async" [value]="option">
                            {{option.code}} : {{option.name}}
                          </mat-option>
                        </mat-autocomplete>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ชื่อพาหนะ / เที่ยว : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" required [disabled]="disabled" matInput
                          placeholder="ชื่อพาหนะ / เที่ยว" #input [(ngModel)]="form.vesselName" name="vesselName"
                          #vesselName="ngModel">
                        <mat-error>กรุณาระบุ</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> สกุลเงิน
                      <label style="color:red" *ngIf="typePage == 'o2'">*</label> :
                    </div>
                    <div class="flex basis-4/5" style="gap: 5px;">
                      <div style="width: 70%">
                        <mat-form-field class="w100">
                          <input type="text" [disabled]="disabled" [required]="typePage == 'o2'" matInput
                            [formControl]="currencySearch" [matAutocomplete]="aut11">
                          <mat-autocomplete #aut11="matAutocomplete" [displayWith]="displayCurrency"
                            (optionSelected)="selectionCurrencyChange($event)">
                            <mat-option *ngFor="let opt of filteredOptionsCurrency$ | async" [value]="opt">
                              {{opt.code}} : {{opt.name}}
                            </mat-option>
                          </mat-autocomplete>
                        </mat-form-field>
                      </div>
                      <div style="width: 30%">
                        <mat-form-field class="w100">
                          <input autocomplete="off" readonly [disabled]="true" matInput placeholder="" #input
                            [(ngModel)]="form.currency" name="currency" #currency="ngModel">
                          <mat-error>กรุณาระบุ</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> อัตราแลกเปลี่ยน
                      <label style="color:red" *ngIf="typePage == 'o2'">*</label> :
                    </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <input autocomplete="off" [required]="typePage == 'o2'" [disabled]="disabled" type="number"
                          matInput placeholder="อัตราแลกเปลี่ยน" #input [(ngModel)]="form.exchangeRate"
                          (keyup)="onExchangerateKeyup($event.target.value)" name="exchangeRate"
                          #exchangeRate="ngModel">
                        <mat-error>กรุณาระบุ</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ส่งออกโดยทาง <label style="color:red"
                        *ngIf="typePage == 'o2'">*</label> : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100">
                        <mat-select [(ngModel)]="form.transportationMode" [disabled]="disabled"
                          name="transportationMode" #transportationMode="ngModel" ngmodel>
                          <mat-option *ngFor="let tran of transModeList" [value]="tran.id">
                            {{tran.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                </div>
              </div>
              <div class="flex">
                <div class="flex-1 w-50">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> เงื่อนไขการอนุญาต : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="w100">
                        <textarea matInput name="o2Condition" [disabled]="disabled" [(ngModel)]="form.o2Condition"
                          #o2Condition="ngModel" ngModel></textarea>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
                <div class="flex-1 w-50">
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </fieldset>

      <!-- รายการสินค้า (Other Information) -->
      <div class="flex flex-col p10" *ngIf="typePage != 'ms24'">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            รายการสินค้า (Other Information)
          </mat-card-header>
          <mat-card-content class="example-headers-align">
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row" style="padding: 12px 0 12px 0">
                  <div class="basis-1/2 f18 flex" style="gap: 5px;" *ngIf="typePage != 'ms13'">
                    <button mat-raised-button color="accent"
                      *ngIf="checkButton('BT115') && !disabled && !checkAddProduct" (click)="addProduct('new' , 0)">
                      {{typePage == 'o2' ? 'เพิ่มรายการสินค้า' : 'สร้าง มส.24'}}
                    </button>
                    <button mat-raised-button color="accent"
                      *ngIf="(disabled && checkButton('BT174')) && (form.status === 5)" (click)="createMs13()">
                      สร้าง มส.13
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-100">
                <div class="mat-elevation-z8" style="width: 100% !important">
                  <dx-data-grid id="gridContainer" [dataSource]="dataSource" [columnAutoWidth]="true"
                    [showBorders]="true" [allowColumnReordering]="true" (onSelectionChanged)="selectionChanged($event)"
                    [showBorders]="true" [noDataText]="'ยังไม่ได้เพิ่มรายการสินค้า'">
                    <dxo-selection *ngIf="(disabled && checkButton('BT174')) && (form.status === 5)"
                      [selectAllMode]="true" [allowSelectAll]="false" mode="multiple"></dxo-selection>
                    <dxi-column caption="รายการที่" alignment="center" cellTemplate="wFlow" [width]='100'>
                    </dxi-column>
                    <div *dxTemplate="let row of 'wFlow'">
                      {{row.rowIndex+1}}
                    </div>
                    <dxi-column alignment="center" dataField="goodsDescription" caption="รายละเอียดสินค้า">
                    </dxi-column>
                    <dxi-column alignment="center" caption="ราคา">
                      <dxi-column alignment="center" caption="ต่อจำนวน" cellTemplate="unitPrice">
                      </dxi-column>
                      <div *dxTemplate="let row of 'unitPrice'">
                        {{ (row.data?.unitPrice != null ? row.data?.unitPrice : 0) | number:'1.2-6' }}
                      </div>
                      <dxi-column alignment="center" caption="FOB(บาท)" cellTemplate="fobValueBaht">
                      </dxi-column>
                      <div *dxTemplate="let row of 'fobValueBaht'">
                        {{ (row.data?.fobValueBaht != null ? row.data?.fobValueBaht : 0) | number:'1.2-6' }}
                      </div>
                    </dxi-column>
                    <dxi-column alignment="center" caption="จำนวน" cellTemplate="quantity">
                    </dxi-column>
                    <div *dxTemplate="let row of 'quantity'">
                      <ng-container *ngIf="row.data?.noneQty ==  'N'">
                        {{ (row.data?.quantity != null ? row.data?.quantity : 0) | number:'1.0'}}
                        {{row.data?.quantityUnitName ?? 'ไม่ระบุ'}}
                      </ng-container>
                      <ng-container *ngIf="row.data?.noneQty ==  'Y'">
                        {{ row.data?.quantityUnit === 'AB' ? 'แพ็คจำนวนมาก (1 AB)' : row.data?.quantityUnit === 'LO' ?
                        'จำนวน (1 LO)' : 'ไม่ระบุ' }}
                      </ng-container>
                    </div>
                    <dxi-column alignment="center" caption="น้ำหนัก">
                      <dxi-column alignment="center" caption="สุทธิ" cellTemplate="netWeight">
                      </dxi-column>
                      <div *dxTemplate="let row of 'netWeight'">
                        {{ (row.data?.netWeight != null ? row.data?.netWeight : 0) | number:'1.2-6'}}
                        {{row.data?.netWeightUnit}}
                      </div>
                      <dxi-column alignment="center" caption="เฉพาะต่อหน่วย" cellTemplate="netWeightPerUnit">
                      </dxi-column>
                      <div *dxTemplate="let row of 'netWeightPerUnit'">
                        {{ (row.data?.netWeightPerUnit != null ? row.data?.netWeightPerUnit : 0) | number:'1.2-6'}}
                        {{row.data?.netWeightUnit}}
                      </div>
                      <dxi-column alignment="center" caption="ใช้ไปแล้ว" cellTemplate="weightUsed">
                      </dxi-column>
                      <div *dxTemplate="let row of 'weightUsed'">
                        {{ (row.data?.weightUsed != null ? row.data.weightUsed : 0) | number:'1.2-6'}}
                        {{row.data?.netWeightUnit}}
                      </div>
                      <dxi-column alignment="center" caption="คงเหลือ" cellTemplate="availableNet">
                      </dxi-column>
                      <div *dxTemplate="let row of 'availableNet'">
                        {{ (row.data?.availableNet != null ? row.data?.availableNet : 0) | number:'1.2-6'}}
                        {{row.data?.netWeightUnit}}
                      </div>
                    </dxi-column>
                    <dxi-column alignment="center" dataField="itemStatusName" caption="สถานะ">
                    </dxi-column>
                    <dxi-column alignment="center" caption="Action" cellTemplate="action"> </dxi-column>
                    <div *dxTemplate="let row of 'action'">
                      <button *ngIf="checkButton('BT123')" mat-icon-button matTooltip="รายละเอียด"
                        matTooltipPosition="above" (click)="addProduct('info',row.data?.id ,row.data )">
                        <i class="ti ti-info-circle"></i>
                      </button>
                      <button mat-icon-button matTooltip="แก้ไข"
                        *ngIf="checkButton('BT124') && !disabled && typePage == 'o2'"
                        (click)="addProduct('edit',row.data?.id )" matTooltipPosition="above">
                        <i class="ti ti-edit"></i>
                      </button>
                      <button mat-icon-button matTooltip="ลบ"
                        *ngIf="checkButton('BT125') && !disabled && typePage == 'o2'"
                        (click)="delProduct(row.data?.id , row.data?.hId , row.data)" matTooltipPosition="above">
                        <i class="ti ti-trash"></i>
                      </button>
                    </div>
                  </dx-data-grid>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div *ngIf="typePage == 'o2'" class="flex flex-col p10">
        <mat-card>
          <mat-card-header class="fontTitleHeader">
            สำเนา อ.2
          </mat-card-header>
          <mat-card-content class="example-headers-align" style="padding-top: 16px;">
            <div class="flex">
              <div class="flex-1 w-64" *ngIf="!disabled">
                <div class="flex flex-row p10">
                  <div class="file-uploader-block" *ngIf="dataSourceAtt.length < 1">
                    <dx-file-uploader [allowCanceling]="true" [(value)]="value"
                      (onValueChanged)="onFileChangedBr($event)" [multiple]="true" [uploadMode]="'instantly'"
                      accept="image/gif, image/jpeg, image/png ,image/jpg"
                      [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']">
                    </dx-file-uploader>
                    <span class="note">Allowed file extensions: <span>.jpg, .jpeg, .gif, .png</span>.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 w-100">
                <div class="mat-elevation-z8" style="width: 100% !important">
                  <dx-data-grid id="gridContainer" [dataSource]="dataSourceAtt" [columnAutoWidth]="true"
                    [showBorders]="true" [allowColumnReordering]="true" [showBorders]="true"
                    [noDataText]="'ยังไม่ได้แนบสำเนา อ.2'">
                    <!-- <dxo-selection [selectAllMode]="false" mode="multiple"></dxo-selection> -->
                    <dxi-column alignment="center" dataField="documentName" caption="ชื่อเอกสาร">
                    </dxi-column>
                    <dxi-column alignment="center" cellTemplate="documentSize" caption="ขนาดของเอกสาร">
                    </dxi-column>
                    <div *dxTemplate="let row of 'documentSize'">
                      {{row?.data.documentSize}} กิโลไบต์ (kB)
                    </div>
                    <dxi-column alignment="center" dataField="fileExtension" caption="ชนิดเอกสาร">
                    </dxi-column>
                    <dxi-column alignment="center" cellTemplate="action" caption="Action">
                    </dxi-column>
                    <div *dxTemplate="let row of 'action'">
                      <button *ngIf="checkButton('BT126')" (click)="showImg()" mat-icon-button matTooltip="รายละเอียด">
                        <i class="ti ti-info-circle"></i>
                      </button>
                      <button *ngIf="checkButton('BT127') && !disabled" mat-icon-button matTooltip="ลบ"
                        (click)="deleteImg(row?.data)" matTooltipPosition="above">
                        <i class="ti ti-trash"></i>
                      </button>
                    </div>
                    <div *dxTemplate="let row of 'itemStatus'">
                    </div>
                  </dx-data-grid>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="flex flex-col p10" *ngIf="!disabled && form?.status != 8">
        <mat-card>
          <mat-card-content class="flex justify-center example-headers-align">
            <button *ngIf="checkButton('BT120')" class="p10" style="margin-right: 20px;"
              [disabled]="!FormValidateItem.valid" mat-raised-button (click)="save(1)">
              บันทึกฉบับร่าง
            </button>
            <button *ngIf="checkButton('BT121')" class="p10" style="margin-right: 20px;"
              [disabled]="!FormValidateItem.valid" mat-raised-button (click)="save(5)">
              บันทึก อ.2
            </button>
            <button *ngIf="checkButton('BT172')" class="p10" mat-raised-button (click)="backPage()">
              ยกเลิก
            </button>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</div>