<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">{{!isDetail ? 'ยื่น' : 'รายละเอียด'}}คำร้องขอต่ออายุ มส.24</div>
      <div>
        <button class="close-button" [mat-dialog-close]="false">
          <mat-icon class="text-white" [mat-dialog-close]>cancel</mat-icon>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="flex flex-col mat-typography" style="gap: 15px; padding-bottom: 12px;">
    <div class="pl-5 pr-5 flex flex-row justify-center" style="gap: 14px;">
      <div class="flex flex-row justify-center gap-3" style="width: 50%">
        <div class="flex flex-col justify-center" style="width: 50%">
          <h4>เลขที่คำร้อง/เลขที่เอกสาร มส.24</h4>
        </div>
        <div class="flex flex-row gap-3" style="width: 50%">
          <div class="flex flex-col justify-center">:</div>
          <!-- <div [formGroup]="searchShipperForm" class="flex-1"> -->
          <mat-form-field class="flex-1">
            <input required autocomplete="off" readonly matInput placeholder="เลขที่คำร้อง/เลขที่เอกสาร มส.24"
              [(ngModel)]="form.docNo" name="docNo" #docNo="ngModel">
          </mat-form-field>
          <!-- </div> -->
        </div>
      </div>

      <div class="flex flex-row justify-center gap-3" style="width: 50%">
        <div class="flex flex-col justify-center" style="width: 50%">
          <h4>วันที่เอกสารหมดอายุ (เดิม)</h4>
        </div>
        <div class="flex flex-row gap-3" style="width: 50%">
          <div class="flex flex-col justify-center">:</div>
          <!-- <div [formGroup]="searchShipperForm" class="flex-1"> -->
          <mat-form-field class="flex-1" (click)="exDates.open()">
            <input required autocomplete="off" disabled readonly placeholder="วันที่เอกสารหมดอายุ (เดิม)" matInput
              [matDatepicker]="exDates" [(ngModel)]="form.expireDate" name="expireDate" #expireDate="ngModel">
            <mat-datepicker-toggle matSuffix [for]="exDates"></mat-datepicker-toggle>
            <mat-datepicker #exDates></mat-datepicker>
            <mat-error>กรุณาระบุ</mat-error> </mat-form-field>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <!-- <div class="pl-5 pr-5 flex flex-row justify-center gap-3" *ngIf="!isDetail">
      <div class="flex flex-row justify-center" style="gap: 14px;">
        <div class="flex flex-row justify-center gap-3" style="align-self: flex-start;width: 50%;">
          <div class="flex flex-col justify-center" style="width: 50%">
            <h4>ชื่อเอกสาร</h4>
          </div>
          <div class="flex flex-row gap-3" style="width: 50%">
            <div class="flex flex-col justify-center">:</div>
            <mat-form-field class="flex-1">
              <input required autocomplete="off" matInput placeholder="เลขที่เอกสาร" [(ngModel)]="formAttachment.fileName"
                name="fileName" #fileName="ngModel">
            </mat-form-field>
          </div>
        </div>
        <div class="basis-1/2 f18" style="align-self: center;">
          <div class="file-uploader-block">
            <dx-file-uploader [allowCanceling]="true" [(value)]="value" (onValueChanged)="onFileChangedBr($event)"
              [multiple]="true" [uploadMode]="'instantly'" accept="image/gif, image/jpeg, image/png ,image/jpg"
              [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']" style="margin: -7px;">
            </dx-file-uploader>
            <span class="note" style="margin-left: 3px; font-size: 13px;">รูปแบบไฟล์ที่อนุญาต: <span>.jpg, .jpeg, .gif,
                .png</span>.</span>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="pl-5 pr-5 flex flex-row justify-center gap-3">
      <div class="mat-elevation-z8 w100">
        <dx-data-grid id="gridContainer" [dataSource]="dataSource" [columnAutoWidth]="true" [showBorders]="true"
          [allowColumnReordering]="true" [showBorders]="true" [noDataText]="'ยังไม่ได้แนบเอกสารประกอบคำพิจารณา'">
          <dxi-column alignment="center" dataField="cmms24aFileName" caption="ชื่อเอกสาร">
          </dxi-column>
          <dxi-column alignment="center" dataField="cmms24aDocumentSize" caption="ขนาดของเอกสาร">
          </dxi-column>
          <dxi-column alignment="center" dataField="cmms24aFileExtension" caption="ชนิดเอกสาร">
          </dxi-column>
          <dxi-column alignment="center" cellTemplate="action" caption="Action">
          </dxi-column>
          <div *dxTemplate="let row of 'action'">
            <button (click)="showImg(row.data)" mat-icon-button matTooltip="รายละเอียด">
              <i class="ti ti-info-circle"></i>
            </button>
            <button mat-icon-button *ngIf="!isDetail" (click)="deleteImg(row.data)" color="accent">
              <i class="ti ti-trash"></i>
            </button>
          </div>
          <div *dxTemplate="let row of 'itemStatus'">
          </div>
        </dx-data-grid>
      </div>
    </div> -->

    <div class="pl-5 pr-5 flex flex-row justify-center" style="gap: 14px;" *ngIf="isStaff">
      <div class="flex flex-row justify-center gap-3" style="width: 70%">
        <div class="flex flex-col justify-center" style="width: 35%">
          <h4>วันที่เอกสารหมดอายุ (ใหม่):</h4>
          <h4 style="color: red">(เฉพาะกรณีที่อนุมัติคำร้อง)</h4>
        </div>
        <div class="flex flex-row gap-3" style="width: 65%">
          <div class="flex flex-col justify-center">:</div>
          <mat-form-field class="flex-1" (click)="nexDates.open()">
            <input required autocomplete="off" readonly placeholder="วันที่เอกสารหมดอายุ (ใหม่)" matInput
              [matDatepicker]="nexDates" [(ngModel)]="form.newExpireDate" [min]="form.expireDate"
              [max]="form.expireDateMax" name="expireDate" #expireDate="ngModel">
            <mat-datepicker-toggle matSuffix [for]="nexDates"></mat-datepicker-toggle>
            <mat-datepicker #nexDates></mat-datepicker>
            <mat-error>กรุณาระบุ</mat-error> </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex justify-center w100" style="padding-bottom: 12px;">
      <div class="flex" style="flex-wrap: nowrap;align-items: center;gap: 7x;" *ngIf="!isStaff">
        <button mat-raised-button (click)="openMs24Copy()" style="gap: 6px;">
          <mat-icon svgIcon="heroicons_outline:information-circle"></mat-icon> ดูรายละเอียดเอกสาร
        </button>
        <button mat-raised-button color="accent" (click)="sentRenew(2)" style="width: 100px;"
          *ngIf="!isDetail">ยื่นคำร้อง</button>
        <button mat-raised-button [mat-dialog-close] style="width: 100px;">ยกเลิก</button>
      </div>
      <div class="flex" style="flex-wrap: nowrap;align-items: center;gap: 7px;" *ngIf="isStaff">
        <button mat-raised-button (click)="openMs24Copy()" style="gap: 6px;">
          <mat-icon svgIcon="heroicons_outline:information-circle"></mat-icon> ดูรายละเอียดเอกสาร
        </button>
        <button mat-raised-button (click)="sentRenew(9)" style="width: 100px;">อนุมัติ</button>
        <button mat-raised-button (click)="sentRenew(4)" style="width: 100px;">ไม่อนุมัติ</button>
      </div>
    </div>
  </mat-dialog-actions>
</mat-card>