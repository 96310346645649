<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div *ngIf="checkButton('BT003')" style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
        <button mat-raised-button color="accent" (click)="createO2($event)">
          สร้างใบ อ.2
        </button>
        <button mat-raised-button style="width: 70px;font-size: 1.5em;" matTooltip="รีเฟรชข้อมูล" matTooltipPosition="above" (click)="onRefresh()">
          <i class="ti ti-refresh"></i>
        </button>
      </div>
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">
          <!-- <h4>records</h4> -->
          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="เลขที่ใบอนุญาต" matInput #input
              [(ngModel)]="searchString" name="searchText" #searchText="ngModel"
              (keyup)="applyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu" (menuClosed)="menuClosed()">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex-row">
                <div class="mat-button">
                  <mat-form-field class="my-form-field formm" fxFlex="50">
                    <mat-label>วันที่ออกใบอนุญาต</mat-label>
                    <input autocomplete="off" placeholder="วันที่ออกใบอนุญาต" matInput [matDatepicker]="StartDate"
                      [(ngModel)]="searchForm.issueDate" name="issueDate" #issueDate="ngModel" readonly>
                    <mat-datepicker-toggle matSuffix [for]="StartDate"></mat-datepicker-toggle>
                    <mat-datepicker #StartDate></mat-datepicker>
                    <mat-error>กรุณาระบุ วันที่ออกใบอนุญาต</mat-error>
                  </mat-form-field>
                  <mat-form-field class="my-form-field formm" fxFlex="50">
                    <mat-label>วันหมดอายุ</mat-label>
                    <input autocomplete="off" placeholder="วันหมดอายุ" matInput [matDatepicker]="exDates"
                      [(ngModel)]="searchForm.expireDate" name="expireDate" #expireDate="ngModel" readonly>
                    <mat-datepicker-toggle matSuffix [for]="exDates"></mat-datepicker-toggle>
                    <mat-datepicker #exDates></mat-datepicker>
                    <mat-error>กรุณาระบุ วันหมดอายุ</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่ใบอนุญาต</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.docNo" name="docNo" #docNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทสินค้า</mat-label>
                    <mat-select [(ngModel)]="searchForm.productId" name="productId" #productId="ngModel" ngmodel>
                      <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>ประเภทสินค้า</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>สถานะ</mat-label>
                    <mat-select [(ngModel)]="searchForm.status" name="status" #status="ngModel" ngmodel>
                      <mat-option *ngFor="let status of statusList" [value]="status.statusId">
                        {{status.statusName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>สถานะ</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div>
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" (click)="searchOption()" style="flex:0 50%;">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="index">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ลำดับ</th>
        <td mat-cell *matCellDef="let element; let i = index" style="justify-content: center;"> {{ i + 1 }} </td>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ผู้ส่งออก</th>
        <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.companyName}} </td>
      </ng-container>

      <ng-container matColumnDef="docNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">เลขที่ใบอนุญาต</th>
        <td mat-cell *matCellDef="let element" style="justify-content: center;"> พณ {{element.docNo}} </td>
      </ng-container>

      <ng-container matColumnDef="issueDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          วันที่ออกใบอนุญาต</th>
        <td mat-cell *matCellDef="let element"> {{(element.issueDate | date:'yyyy-MM-dd') || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="expireDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">วันหมดอายุ
        </th>
        <td mat-cell *matCellDef="let element"> {{(element.expireDate | date:'yyyy-MM-dd') || '-'}} </td>
      </ng-container>

      <ng-container matColumnDef="productName" style="justify-content: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ประเภทสินค้า</th>
        <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
      </ng-container>

      <ng-container matColumnDef="statusCode">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">สถานะ</th>
        <td mat-cell *matCellDef="let element"> {{element.statusCode}} </td>
      </ng-container>

      <ng-container matColumnDef="action" style="text-align: left; min-width: 170px;">
        <th mat-header-cell *matHeaderCellDef style="text-align: left; min-width: 170px;"> Action </th>
        <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;min-width: 170px;">
          <div class="flex">
            <div *ngIf="checkButton('BT005')">
              <button mat-icon-button matTooltip="รายละเอียด" matTooltipPosition="above" routerLink="/page/o2/o2-manual"
                [queryParams]="{ page: 'o2', source : element.source, type : 'Detail' , id : element.id , docNo : element.docNo,productId : element.productId }">
                <i class="ti ti-file-description"></i>
              </button>
            </div>
            <!-- -แสดงเฉพาะ item ที่ TAB_STATUS.TSU_ID = MST_O2_HEAD. TSU_ID (‘’1’’),(“5”) -สถานะ Draft -สถานะ Available -->
            <div *ngIf="checkButton('BT004') && element.status == 1">
              <button mat-icon-button matTooltip="แก้ไข อ.2" matTooltipPosition="above" routerLink="/page/o2/o2-manual"
                [queryParams]="{ page: 'o2',source : element.source, type : 'Edit', id : element.id , docNo : element.docNo ,productId : element.productId }">
                <!-- {{element}} -->
                <i class="ti ti-edit"></i>
              </button>
            </div>
            <div *ngIf="checkButton('BT006') && element.flagExpireDate">
              <button mat-icon-button matTooltip="ต่ออายุ" matTooltipPosition="above" (click)="renewO2(element)">
                <i class="ti ti-reload"></i>
              </button>
            </div>
            <div *ngIf="checkButton('BT007') && element.status != 1 && element.status != 11">
              <button mat-icon-button matTooltip="ย้ายสถานะสิ้นสุดงาน" matTooltipPosition="above"
                (click)="expireO2(element)">
                <i class="ti ti-replace"></i>
              </button>
            </div>
            <div *ngIf="checkButton('BT008') && element.status != 6 ">
              <button mat-icon-button matTooltip="ลบ" matTooltipPosition="above" (click)="deleteItem(element)"
                matTooltipPosition="above">
                <i class="ti ti-trash"></i>
              </button>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements" (page)="pageChanged($event)">
    </mat-paginator>
  </div>
  <!-- <div class="mat-elevation-z8" align="center">
    <dx-data-grid id="wFlow" width="90%" [dataSource]="dataSource" [remoteOperations]="false" [showBorders]="false"
      [allowColumnReordering]="true" [rowAlternationEnabled]="true" [allowColumnResizing]="true"
      [columnAutoWidth]="true" class="mt-12 mb-12 formm" [noDataText]="'ไม่มีข้อมูล'">
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[5, 10, 20]" [showInfo]="true">
      </dxo-pager>
      <dxo-scrolling [useNative]="true" mode="standard" [scrollByContent]="true" [scrollByThumb]="true"
        showScrollbar="onHover"></dxo-scrolling>
      <dxo-pager *ngIf="dataSourceTemp?.length > 0" [showPageSizeSelector]="true" [visible]="true"
        [allowedPageSizes]="false"></dxo-pager>
      <dxo-search-panel [visible]="false"></dxo-search-panel>
      <dxo-paging [pageSize]="10"></dxo-paging>
      <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[false]"></dxo-pager>
      <dxo-filter-row [visible]="true"></dxo-filter-row>
      <dxi-column caption="#" cellTemplate="wFlow" [width]='60'>
      </dxi-column>
      <div *dxTemplate="let row of 'wFlow'">
        {{row.rowIndex+1}}
      </div>
      <dxi-column [filterOperations]="['contains']" dataField="docNo" caption="เลขที่ใบอนุญาต" dataType="string">
      </dxi-column>
      <dxi-column [filterOperations]="['contains','between']" dataField=" expireDate" caption="วันที่ออกใบอนุญาต"
        dataType="date" format="dd/MM/yyyy">
      </dxi-column>
      <dxi-column [filterOperations]="['contains','between']" dataField="issueDate" caption="วันหมดอายุ" dataType="date"
        format="dd/MM/yyyy">
      </dxi-column>
      <dxi-column [filterOperations]="['contains']" dataField="productId" caption="ประเภทสินค้า" dataType="string">
      </dxi-column>
      <dxi-column [filterOperations]="['contains']" dataField="statusCode" caption="สถานะ" dataType="string">
      </dxi-column>
      <dxi-column caption="Action" cellTemplate="detail"> </dxi-column>
      <div *dxTemplate="let row of 'detail'">
        <button mat-icon-button matTooltip="รายละเอียด">
          <mat-icon>create</mat-icon>
        </button>
        <button mat-icon-button matTooltip="ต่ออายุ">
          <mat-icon>autorenew</mat-icon>
        </button>
        <button mat-icon-button matTooltip="ย้ายสถานะสิ้นสุดงาน">
          <mat-icon>check_circle_outline</mat-icon>
        </button>
        <button mat-icon-button matTooltip="ลบ" (click)="editItem(element)" matTooltipPosition="above">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </dx-data-grid>
  </div> -->
</div>