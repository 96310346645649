import { Component, OnInit, Inject, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppItemtypeService } from "app/business/service/app/app-itemtype.service";
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn } from '@angular/forms';



@Component({
  selector: 'app-itemtype-adjust-management',
  templateUrl: './itemtype-adjust-management.component.html',
  styleUrls: ['./itemtype-adjust-management.component.css']
})
export class ItemtypeAdjustManagementComponent implements OnInit {

  form: any = {};
  FormDoc: FormGroup;
  dataSource: any = [];
  dataProduct: any[] = [];
  typeproduct: number = 0;
  category: string = ' ';
  itemtypeId: number = 0;
  code: number = 0;
  message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<ItemtypeAdjustManagementComponent>,
    private AppItemtypeService: AppItemtypeService,
    private confirmService: ConfirmDialogService,
    private AlertMessage: AlertMessageService,
    public formGroup: FormBuilder,
  ) {
    this.itemtypeId = data.refGoodsClassId
    this.typeproduct = data.refGoodsDocId
    this.code = data.refGoodsCode
    this.category = data.refGoodsName

  }

  ngOnInit() {
    this.loadDataProduct();
    this.showData();
    this.checkFormGroup();
  }
  async loadDataProduct(): Promise<void> {
    //send data to api
    const formData = {

    };
    await this.AppItemtypeService.getDataDetailProduct(formData).then((res) => {
      this.dataProduct = res
    });
  }
  async showData() {
    if (this.itemtypeId > 0) {
      this.form.typeproduct = this.typeproduct;
      this.form.code = this.code;
      this.form.category = this.category;
    }

  }
  checkFormGroup() {
    this.FormDoc = new FormGroup({
      productControl: new FormControl('', Validators.required),
      codeControl: new FormControl('', Validators.required),
      categoryControl: new FormControl('', Validators.required)
    });
  }
  onReset() {
    this.form.typeproduct = 0;
    this.form.code = ' ';
    this.form.category = '';
    this.showData();
  }
  async saveData() {
    const formData = {
      refGoodsClassId: this.itemtypeId,
      refGoodsDocId: this.form.typeproduct,
      refGoodsCode: this.form.code,
      refGoodsName: this.form.category,
      refGoodsStatusData: 'Y'
    };
    await this.AppItemtypeService.AdjustDataRefGoodsClass(formData).then((res) => {
      this.message = res
    });

    this.AlertMessage.info({ title: '', message: this.message });
    if (this.message != "เกิดข้อมูลการเกิดซ้ำ") {
      this.dialogRef.close("OK");
    }
  }

  decimalFixed(val) {
    if (val.toString().split('.')[1] !== undefined) {
      if (val.toString().split('.')[1].length >= 0) {
        this.form.code = Number.parseFloat(val).toFixed(1);
      }
    }
  }
}
