<mat-toolbar class="bgGray">
  <button mat-icon-button class="bglightgrey" routerLink= "/page/ms24/ms24-record-staff">
    <mat-icon>arrow_back</mat-icon>
  </button>
  <button mat-icon-button class="bglightgrey" (click)="loadData()">
    <mat-icon>cached</mat-icon>
  </button>
  <button mat-icon-button class="bglightgrey">
    <mat-icon>save</mat-icon>
  </button>

  <span class="nav-spacer-title-right"></span>
  <strong>แก้ไขข้อมูล มส.24</strong>
</mat-toolbar>
<div class="flex flex-col p10">
  <div style="padding: 0px 10px 0px 10px;">
    <!-- Title -->
    <div class="flex flex-col p10">
      <mat-card>
        <div class="flex">
          <div class="column">
              <div class = "pl-2 pt-3" style="align-self: left;"> ใบอนุญาตกระทรวงพาณิชย์ : {{dataView[0]?.headO2DocNo}} ลงวันที่ 2017-05-22 </div>
              <div class = "pl-2 pt-5" style="align-self: left;"> เลขที่ประจำตัวผู้เสียภาษี : {{dataView[0]?.headCompanyTax}}  </div>
              <div class = "pl-2 pt-5 pb-3" style="align-self: left;"> คำร้องขอเลขที่ : {{dataView[0]?.headMs13DocNO}}  </div>    
          </div>
          <div class="column">
              <div class = "pl-2 pt-3" style="align-self: right;"> สถานะ : {{dataView[0]?.headMs24Status}} </div>
              <div class = "pl-2 pt-5" style="align-self: right;"> ประเภทสินค้า : {{dataView[0]?.headProductType}}</div>
              <div class = "pl-2 pt-5 pb-3" style="align-self: right;"> ใบรับรองเลขที่ : {{dataView[0]?.headMs24Refno}} </div>
          </div>
        </div>
      </mat-card>
    </div>
    <!-- ข้อมูลบริษัท -->
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          ข้อมูลผู้ขอใบรับรอง
        </mat-card-header>
        <mat-card-content class="example-headers-align">
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ชื่อบริษัทผู้ส่งออก (ภาษาไทย) : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headCompanyNameTH" name="headCompanyNameTH" #headCompanyNameTH="ngModel">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
          </div>

          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ใบเลขทะเบียนเลขที่ ผค. : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headRegistrationNO" name="headRegistrationNO" #headRegistrationNO="ngModel" >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
          </div>
  
        </mat-card-content>
      </mat-card>
    </div>
     <!-- ข้อมูลสินค้า -->
     <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          ข้อมูลสินค้า
        </mat-card-header>
        <mat-card-content class="example-headers-align">
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ชื่อสินค้า : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headGoodsName" name="headGoodsName" #headGoodsName="ngModel">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
          </div>

          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ประเภทสินค้า : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headTypeGoods" name="headTypeGoods" #headTypeGood ="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
          </div>

          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ชนิด / ชั้น : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headTypeClass" name="headTypeClass" #headTypeClass ="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
          </div>

          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ตราหรือเครื่องหมายการส่งออก : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headShippingMark" name="headShippingMark" #headShippingMark="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
          </div>
  
        </mat-card-content>
      </mat-card>
    </div>

     <!-- ข้อมูลรายการสินค้า -->
     <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          ข้อมูลรายการสินค้า
        </mat-card-header>
        <mat-card-content class="example-headers-align">
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> INVOICE : </div>
                <div class="basis-4/5">
                  <div> NO : {{dataView[0]?.invoiceProductList[0].listInvoice[0].detailInvoiceNo}}</div>
                  <div> Date : {{dataView[0]?.invoiceProductList[0].listInvoice[0].detailInvoiceDate}}</div>
                  <div> ลำดับรายการสินค้า : {{dataView[0]?.invoiceProductList[0].listInvoice[0].detailInvoiceItem}}</div>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> รายการสินค้า : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.detailGoodsDescription" name="detailGoodsDescription" #detailGoodsDescription="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>  
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> น้ำหนักเฉพาะ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.detailNetWeightPerUnit" name="detailNetWeightPerUnit" #detailNetWeightPerUnit="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> น้ำหนัก : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.detailNetWeight" name="detailNetWeight" #detailNetWeight="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>  
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ปริมาณ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.detailQuantity" name="detailQuantity" #detailQuantity="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> น้ำหนักรวม : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.detailGrossWeightPerUnit" name="detailGrossWeightPerUnit" #detailGrossWeightPerUnit="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>  
          </div>
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> มูลค่ารวม (บาท) : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.detailFobValueBath" name="detailFobValueBath" #detailFobValueBath="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> มูลค่ารวม (USD) : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.detailFobVslueUSD" name="detailFobVslueUSD" #detailFobVslueUSD="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>  
          </div>
  
        </mat-card-content>
      </mat-card>
    </div>

    <!-- ข้อมูลการส่งออก -->
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          ข้อมูลการส่งออก
        </mat-card-header>
        <mat-card-content class="example-headers-align">
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> เรือใหญ่ที่จะบรรทุก : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headVesselName" name="headVesselName" #headVesselName="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div> 
          </div>

          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ท่าหรือสถานที่ส่งออก : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headporttxt" name="headporttxt" #headporttxt="ngModel" >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> เมืองตราส่ง : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headBuyerCountry" name="headBuyerCountry" #headBuyerCountry="ngModel" >
                  </mat-form-field>
                </div>
              </div>
            </div>  
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ประเทศ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headDestinationCountry" name="headDestinationCountry" #headDestinationCountry="ngModel">
                  </mat-form-field>
                </div>
              </div>
            </div>  
          </div>

          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ส่งออกโดยทาง : </div>
                <div class="basis-4/5">
                <div> {{dataView[0]?.headTransportation}} </div>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> ชื่อพาหนะ / เที่ยว : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headAccualVesselName" name="headAccualVesselName" #headAccualVesselName="ngModel">
                  </mat-form-field>
                </div>
              </div>
            </div>  
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> วันที่จะบรรทุก : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headGoodsLoadDate" name="headGoodsLoadDate" #headGoodsLoadDate="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>  
          </div>
  
        </mat-card-content>
      </mat-card>
    </div>

    <!-- ข้อมูลการตรวจสอบ -->
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          ข้อมูลการตรวจสอบ
        </mat-card-header>
        <mat-card-content class="example-headers-align">
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> มาตรฐาน : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headCheckResult" name="headCheckResult" #headCheckResult="ngModel" >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
          </div>

          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> การบรรจุหุ้มห่อ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headPackageResult" name="headPackageResult" #headPackageResult="ngModel" >
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
          </div>

  
        </mat-card-content>
      </mat-card>
    </div>

     <!-- ข้อมูลการตรวจสอบ -->
     <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          อื่นๆ
        </mat-card-header>
        <mat-card-content class="example-headers-align">
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> วันที่เอกสารหมดอายุ : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headExpireDate" name="headExpireDate" #headExpireDate="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
          </div>

          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> วันที่ออกเอกสาร : </div>
                <div class="basis-4/5">
                  <mat-form-field class="w100">
                    <input autocomplete="off" type="text" matInput  #input
                      [(ngModel)]="form.headIssueDate" name="headIssueDate" #headIssueDate="ngModel" readonly>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
               
              </div>
            </div>  
          </div>

  
        </mat-card-content>
      </mat-card>
    </div>

    <!-- เอกสารแนบการประกอบการพิจารณา -->
    <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          เอกสารแนบการประกอบการพิจารณา
        </mat-card-header>
        <mat-card-content class="example-headers-align">
          <div class="flex">
            <table mat-table matSort [dataSource]="form.attachmentDocMs24List" > 
              <!--**********1.documentName**********-->
              <ng-container matColumnDef="documentName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ชื่อเอกสาร</th>
                <td mat-cell *matCellDef="let element" style="padding-left: 80px;" > {{element.attachmentMs24DocumentName}} </td>
              </ng-container>
               <!--**********2.documentSize**********-->
               <ng-container matColumnDef="documentSize">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ขนาดของเอกสาร</th>
                <td mat-cell *matCellDef="let element" style="padding-left: 80px;" > {{element.attachmentMs24documentSize}} </td>
              </ng-container>
               <!--**********3.documentType**********-->
               <ng-container matColumnDef="documentType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ชนิดของเอกสาร</th>
                <td mat-cell *matCellDef="let element" style="padding-left: 80px;" > {{element.attachmentMs24AppType}} </td>
              </ng-container>
              <!--**********4.actiob**********-->
              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
                <td mat-cell *matCellDef="let element" style="text-align: center;">
                  <button mat-icon-button matTooltip="รายละเอียด" [routerLink]="['/page/ms24/ms24-record-staff-detail']"
                  [queryParams]="{ id: element.exporterIdResponse}">
                    <mat-icon>description</mat-icon>
                  </button>   
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
          </div>

  
        </mat-card-content>
      </mat-card>
    </div>



  </div>
</div>