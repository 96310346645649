import { Injectable } from "@angular/core";
import { ApiReportService } from "../api/api-report.service";

@Injectable({
  providedIn: "root",
})
export class AppReportService {
  constructor(private ApiReport: ApiReportService) {}

  DailyReport(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.DailyReport(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  printAllCertificateMS24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.printAllCertificateMS24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  printAllCertificateMS13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.printAllCertificateMS13(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportDataMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportDataMs24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportSummaryApproveDataMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportSummaryApproveDataMs24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportSummaryPrintDataMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportSummaryPrintDataMs24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportControlDataMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportControlDataMs24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportProductWorkloadSummary(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportProductWorkloadSummary(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportProductWorkloadSummaryPayment(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportProductWorkloadSummaryPayment(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportSummarizingAmountOfWeightMs24PaidPayment(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportSummarizingAmountOfWeightMs24PaidPayment(
        dataReq
      ).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportSummarizingDaysAndNumberMs24ReceivedPayment(
    dataReq: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportSummarizingDaysAndNumberMs24ReceivedPayment(
        dataReq
      ).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportSummarizingIssuanceFormsProduct(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportSummarizingIssuanceFormsProduct(dataReq).subscribe(
        (res) => {
          resolve(res);
        }
      );
    });
  }
  reportSummarizingMs24PaidPayment(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportSummarizingMs24PaidPayment(dataReq).subscribe(
        (res) => {
          resolve(res);
        }
      );
    });
  }
  reportSummarizingPaidPayment(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportSummarizingPaidPayment(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportExportByCountry(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportExportByCountry(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportTop10ShipoutByCountry(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportTop10ShipoutByCountry(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportTop10ShipoutByCompany(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportTop10ShipoutByCompany(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportCountryExport(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportCountryExport(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportCompanyInSystem(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportCompanyInSystem(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportMs13FileComplaint(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportMs13FileComplaint(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  reportMs24ByMonth(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportMs24ByMonth(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportMs13CompleteMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportMs13CompleteMs24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportMs13IncompleteMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportMs13IncompleteMs24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportMs13SurveyorIncompleteMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportMs13SurveyorIncompleteMs24(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportMs13IsCommit(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportMs13IsCommit(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportFileControlMs13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportFileControlMs13(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportMs13ApprovePerCompany(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportMs13ApprovePerCompany(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  reportMs13PrintList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportMs13PrintList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  reportSendDocumentToOcsConnectExternal(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportSendDocumentToOcsConnectExternal(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  reportInvoiceBymouthComponent(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.ApiReport.reportInvoiceBymouthComponent(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
