import { Component, Inject } from '@angular/core'
import { FormGroup, AbstractControl, FormBuilder } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service'
import { AppMasterService } from 'app/business/service/app/app-master.service'
import { AppUserService } from 'app/business/service/app/app-user.service'
import { AuthService } from 'app/core/auth/auth.service'
import { Observable, startWith, map } from 'rxjs'

@Component({
  selector: 'app-config-relationship-popup',
  templateUrl: './config-relationship-popup.component.html',
  styleUrls: ['./config-relationship-popup.component.scss'],
})
export class ConfigRelationshipPopupComponent {
  shippingName: string
  searchInput: string = ' '
  dataSource: any[] = []
  filteredOptions: Observable<string[]>
  searchShipperForm: FormGroup
  uId: number
  CompanyId: number
  userToken: any;
  get shipperSearch(): AbstractControl {
    return this.searchShipperForm.get('shipperSearch')
  }
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private formBuilder: FormBuilder,
    private appMasterService: AppMasterService,
    public dialogRef: MatDialogRef<ConfigRelationshipPopupComponent>,
    private dialog: MatDialog,
    private alertMessageService: AlertMessageService,
    private appUserService: AppUserService,
    private _authService: AuthService,

  ) {
    this.searchShipperForm = this.formBuilder.group({
      shipperSearch: ['', []],
    })
  }

  ngOnInit(): void {

    this.userToken = JSON.parse(
      this._authService.loginUser
    )

    this.uId = this.userToken.uId;
    this.CompanyId = this.userToken.cpId;

    this.loadData()
  }

  async loadData() {
    const data = {
      type: 1,
    }
    await this.appMasterService.getShipperRelationship(data).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        this.dataSource = res
      }
    })
    this.filteredOptions = this.shipperSearch.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return typeof value === 'string' ? value : value.name
      }),
      map((name) => {
        return name ? this._filter(name) : this.dataSource.slice()
      }),
    )
    if (this.data.companyNameTh !== '') {
      let a = this.dataSource.find(
        (x) => x.companyNameTh == this.data.companyNameTh,
      )
      this.searchShipperForm.get('shipperSearch').patchValue(a)
    }
  }
  onFocus() {
  }

  save() {
    const request = {
      ID: this.data.ID,
      EXID: this.CompanyId,
      SHID: this.searchShipperForm.value.shipperSearch.id,
      createBy: this.data.userName
    }
    this.appMasterService.saveRelationship(request)
      .then((res: any) => {
        if (res == 'error') {
          return
        } else {
          this.dialogRef.close(true);
          this.alertMessageService.open({ message: "บันทึกข้อมูลสำเร็จ", title: "" });
        }
      })
  }
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase()
    return this.dataSource.filter((option) =>
      option.companyNameTh.toLowerCase().includes(filterValue),
    )
  }
  displayFn(value: any): string | undefined {
    if (!value) {
      return ''
    }
    return value ? value.companyNameTh : undefined
  }
}
