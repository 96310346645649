<mat-card>
  <mat-card-header class="fontTitleHeader" style="background-color: #003F80">
    <div class="flex justify-between" style="width: 100%;">
      <div class="fontTitleModal"> ประกาศ </div>
      <div>
        <button mat-icon-button class="close-button" (click)="closePopup()">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>

  <mat-dialog-content class="p-0 m-0" style="max-height: 80vh !important;">
    <div style="padding: 25px 25px 5px 25px; width: 100%">
      <dx-gallery [dataSource]="data" [height]="550" [slideshowDelay]="slideshowDelay" [loop]="true" [showNavButtons]="true" [showIndicator]="true">
        <div *dxTemplate="let notifyData of 'item'">
          <div class="flex" style="width: 100%">
            <div class="flex flex-col gap-4" style="width: 100%">
              <div class="flex" style="flex-direction: column;font-size: small; align-self: flex-start;">
                <span style="font-size: 1.5em; text-align: left;">{{notifyData.notificationTopicTh}}</span>
                <span style="color: grey; text-align: left;"><strong>ประกาศ ณ วันที่ {{convertDate(notifyData?.notificationDate)}} {{notifyData.notificationDate | date : "HH:mm"}}</strong></span>
              </div>

              <ng-container *ngIf="notifyData.notificationImagePath && notifyData.notificationLink">
                <div class="flex gap-2.5" style="align-items: center; flex-direction: column; height: 400px;">
                  <a [href]="notifyData.notificationLink" target="_blank" style="height: 100%; width: auto;">
                    <img [src]="notifyData.notificationImagePath" style="height: 100%; width: auto;">
                  </a>
                </div>
              </ng-container>

              <ng-container *ngIf="notifyData.notificationImagePath && !notifyData.notificationLink">
                <div class="flex gap-2.5" style="align-items: center; flex-direction: column; height: 400px;">
                  <img [src]="notifyData.notificationImagePath" style="height: 100%; width: auto;">
                </div>
              </ng-container>

              <ng-container *ngIf="!notifyData.notificationImagePath">
                <div class="flex gap-2.5" style="align-items: center; flex-direction: column; height: 150px;">
                  <div class="flex flex-0 items-center justify-center rounded-full" style="width: 150px; height: 150px; background-color: #e3f3ff">
                    <i class="ti ti-file-alert" style="font-size: 6em; color: #4297D7"></i>
                  </div>
                </div>
              </ng-container>

              <div class="flex gap-2.5" style="flex-direction: column;font-size: medium;color: black; white-space: break-spaces;">
                <span style="text-align: left;">{{notifyData.notificationDescTh}}</span>
              </div>
            </div>
          </div>
        </div>
      </dx-gallery>
    </div>
    <div style="padding: 0px 10px 0px 10px; width: 100%">
      <mat-checkbox class="example-margin" [(ngModel)]="form.disablePopup" [ngModelOptions]="{standalone: true}" (ngModelChange)="disablePopupShow($event)">
        ไม่แสดงอีกในวันนี้
      </mat-checkbox>
    </div>
  </mat-dialog-content>
</mat-card>
