<form name="dailyReportForm" [formGroup]="dailyReportForm" novalidate>
    <div class="flex flex-column items-center pl-2.5 pt-10" *ngIf="form?.displayApproveStatusTh === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> สถานะ :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.statusList" name="statusList" formControlName="statusList" ngmodel
                    (ngModelChange)="functionChangeEvent($event)">
                    <mat-option value="0">ทั้งหมด</mat-option>
                    <mat-option value="1">ผ่านการอนุมัติ</mat-option>
                    <mat-option value="2">ยกเลิก</mat-option>
                    <!-- <mat-option *ngFor="let status of statusOptionList" [value]="status.statusId">
                        {{status.statusName}}
                    </mat-option> -->
                </mat-select>
                <!-- <mat-select 
               [(ngModel)]="dailyReport.statusList"
               name="statusList"
               formControlName="statusList"
               >
                  <mat-option  value="0" >All</mat-option>
                  <mat-option  value="9">Complete</mat-option>
                  <mat-option  value="13">Waiting Payment</mat-option>
              </mat-select> -->
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayTypeTh === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> ประเภทสินค้า :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.typeList" name="typeList" formControlName="typeList" ngmodel
                    (ngModelChange)="functionChangeEvent($event)">
                    <mat-option  value="1">ข้าวหอมมะลิไทย (ส.ขม.)</mat-option>
                    <mat-option  value="2">ผลิตภัณฑ์มันสำปะหลัง (ส.ผส.)</mat-option>
                    <mat-option  value="3">ปุยนุ่น (ส.ปน.)</mat-option>
                    <mat-option  value="4">ถั่วเขียว (ส.ถข.)</mat-option>
                    <mat-option  value="5">ถั่วเขียวผิวดำ (ส.ถผ.)</mat-option>
                    <mat-option  value="6">ข้าวฟ่าง (ส.ขฟ.)</mat-option>
                    <mat-option  value="7">ปลาป่น (ส.ปป.)</mat-option>
                    <mat-option  value="8">ข้าวโพด (ส.ขพ.)</mat-option>
                    <mat-option  value="9">ข้าวขาว (ส.ขข.)</mat-option>
                    <!-- <mat-option value="0">Show all</mat-option>
                    <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                    </mat-option> -->
                </mat-select>
                <!-- <mat-select 
               [(ngModel)]="dailyReport.typeList"
               name="typeList"
               formControlName="typeList"
               >

              </mat-select> -->
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5" [class]="form?.displayApproveStatusTh != 'Y' ? 'pt-10' : 'pt-5'" *ngIf="form?.displayDateTimePeriod === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> วันเริ่มต้น :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="w-full pl-2.5" appearance="fill">
                <input matInput #input class="form-control" [matDatepicker]="picker1" (click)="picker1.open()" readonly
                    name="startDate" formControlName="startDate" [(ngModel)]="dailyReport.startDate" required="true"
                    [max]="myDate" (ngModelChange)="functionChangeEvent($event)">
                <mat-hint>YYYY/MM/DD</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 dateFormat="YYYY/MM/DD" disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div *ngIf="dailyReportForm.controls['startDate'].invalid && (dailyReportForm.controls['startDate'].dirty || dailyReportForm.controls['startDate'].touched)"
            class="p-1 text-sm text-red-800" role="alert">
            <div *ngIf="dailyReportForm.controls['startDate'].errors.required">
                **กรุณาระบุวันเริ่มต้น.
            </div>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayDateTimePeriod === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> วันสุดท้าย :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="w-full pl-2.5" appearance="fill">
                <input matInput #input class="form-control" [matDatepicker]="picker2" (click)="picker2.open()" readonly
                    name="endDate" formControlName="endDate" [(ngModel)]="dailyReport.endDate" [max]="myDate"
                    [min]="dailyReport.startDate" required="true" (ngModelChange)="functionChangeEvent($event)">
                <mat-hint>YYYY/MM/DD</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 dateFormat="YYYY/MM/DD" disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div *ngIf="dailyReportForm.controls['endDate'].invalid && (dailyReportForm.controls['endDate'].dirty || dailyReportForm.controls['endDate'].touched)"
            class="p-1 text-sm text-red-800" role="alert">
            <div *ngIf="dailyReportForm.controls['endDate'].errors.required">
                **กรุณาระบุวันสุดท้าย.
            </div>
        </div>
    </div>

    <div class="flex flex-column items-center pl-2.5 pt-10" *ngIf="form?.displayYearMonth === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> เดือน :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.month" name="month" formControlName="month"
                    ngmodel (ngModelChange)="functionChangeEvent($event)">
                    <mat-option *ngFor="let selectMonth  of selectMonthFilterList;" [value]="selectMonth.no">
                        {{selectMonth.month}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5 pt-10" *ngIf="form?.displayYearMonth === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> ปี :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.year" name="year" formControlName="year"
                    ngmodel (ngModelChange)="functionChangeEvent($event)">
                    <mat-option *ngFor="let year  of selectYear;" [value]="year">
                        {{year}}
                      </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.country === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> ประเทศปลายทาง :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.selectCountry" name="selectCountry" formControlName="selectCountry"
                    ngmodel (ngModelChange)="functionChangeEvent($event)">
                    <mat-option value="">Show all</mat-option>
                    <mat-option *ngFor="let coutry of countryOptionList" [value]="coutry.code">
                        {{coutry.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayType === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> ประเภทสินค้า :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.typeList" name="typeList" formControlName="typeList" ngmodel
                    (ngModelChange)="functionChangeEvent($event)">
                    <mat-option value="0">Show all</mat-option>
                    <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                    </mat-option>
                </mat-select>
                <!-- <mat-select 
               [(ngModel)]="dailyReport.typeList"
               name="typeList"
               formControlName="typeList"
               >
                  <mat-option  value= "0" >Show all</mat-option>
                  <mat-option  value="1">ข้าวหอมมะลิไทย</mat-option>
                  <mat-option  value="2">ผลิตภัณฑ์มันสำปะหลัง</mat-option>
                  <mat-option  value="3">ปุยนุ่น</mat-option>
                  <mat-option  value="4">ถั่วเขียว</mat-option>
                  <mat-option  value="5">ถั่วเขียวผิวดำ</mat-option>
                  <mat-option  value="6">ข้าวฟ่าง</mat-option>
                  <mat-option  value="7">ปลาป่น</mat-option>
                  <mat-option  value="8">ข้าวโพด</mat-option>
                  <mat-option  value="9">ข้าวขาว</mat-option>
              </mat-select> -->
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayStatus === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> สถานะ :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.statusList" name="statusList" formControlName="statusList" ngmodel
                    (ngModelChange)="functionChangeEvent($event)">
                    <mat-option value="0">All</mat-option>
                    <mat-option *ngFor="let status of statusOptionList" [value]="status.statusId">
                        {{status.statusName}}
                    </mat-option>
                </mat-select>
                <!-- <mat-select 
               [(ngModel)]="dailyReport.statusList"
               name="statusList"
               formControlName="statusList"
               >
                  <mat-option  value="0" >All</mat-option>
                  <mat-option  value="9">Complete</mat-option>
                  <mat-option  value="13">Waiting Payment</mat-option>
              </mat-select> -->
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayApproveStatus === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> สถานะ :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.statusList" name="statusList" formControlName="statusList" ngmodel
                    (ngModelChange)="functionChangeEvent($event)">
                    <mat-option value="0">All</mat-option>
                    <mat-option value="1">Approved</mat-option>
                    <mat-option value="2">Rejected</mat-option>
                    <mat-option value="3">Cancel</mat-option>
                    <!-- <mat-option *ngFor="let status of statusOptionList" [value]="status.statusId">
                        {{status.statusName}}
                    </mat-option> -->
                </mat-select>
                <!-- <mat-select 
               [(ngModel)]="dailyReport.statusList"
               name="statusList"
               formControlName="statusList"
               >
                  <mat-option  value="0" >All</mat-option>
                  <mat-option  value="9">Complete</mat-option>
                  <mat-option  value="13">Waiting Payment</mat-option>
              </mat-select> -->
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5 pt-10" *ngIf="form?.displayDateStartOnly === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> วัน :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="w-full pl-2.5" appearance="fill">
                <input matInput #input class="form-control" [matDatepicker]="picker11" (click)="picker11.open()"
                    readonly name="startDateOnly" formControlName="startDateOnly"
                    [(ngModel)]="dailyReport.startDateOnly" required="true" [max]="myDate"
                    (ngModelChange)="functionChangeEvent($event)">
                <mat-hint>YYYY/MM/DD</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="picker11"></mat-datepicker-toggle>
                <mat-datepicker #picker11 dateFormat="YYYY/MM/DD" disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        <div *ngIf="dailyReportForm.controls['startDateOnly'].invalid && (dailyReportForm.controls['startDateOnly'].dirty || dailyReportForm.controls['startDateOnly'].touched)"
            class="p-1 text-sm text-red-800" role="alert">
            <div *ngIf="dailyReportForm.controls['startDateOnly'].errors.required">
                **กรุณาระบุวันที่.
            </div>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayCompanyType === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> ประเภทธุรกิจ :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <!-- <mat-select [(ngModel)]="dailyReport.typeList" name="typeList" formControlName="typeList" ngmodel (ngModelChange)="functionChangeEvent($event)">
            <mat-option  value="0" >Show all</mat-option>
            <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
              {{product.productName}}
            </mat-option>
          </mat-select> -->
                <mat-select [(ngModel)]="dailyReport.companyTypeId" name="companyTypeId" formControlName="companyTypeId"
                    ngmodel (ngModelChange)="functionChangeEvent($event)">
                    <mat-option value="2">Exporter</mat-option>
                    <mat-option value="3">Surveyor</mat-option>
                    <mat-option value="4">Shipping</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayBranch === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> ศูนย์ดำเนินการ :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.branch" name="branch" formControlName="branch" ngmodel
                    (ngModelChange)="functionChangeEvent($event)">
                    <mat-option value="0">Show all</mat-option>
                    <mat-option *ngFor="let branch of branchList" [value]="branch.id">
                        {{branch.branch}}
                    </mat-option>
                </mat-select>
                <!-- <mat-select [(ngModel)]="dailyReport.companyTypeId" name="companyTypeId" formControlName="companyTypeId"
                    ngmodel (ngModelChange)="functionChangeEvent($event)">
                    <mat-option value="2">Exporter</mat-option>
                    <mat-option value="3">Surveyor</mat-option>
                    <mat-option value="4">Shipping</mat-option>
                </mat-select> -->
            </mat-form-field>
        </div>
    </div>

    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayBranchRCNo === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> ศูนย์ดำเนินการ :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.branchCode" name="branchCode" formControlName="branchCode" ngmodel
                    (ngModelChange)="functionChangeEvent($event)">
                    <mat-option value="0">Show all</mat-option>
                    <mat-option value="RT20101">ศูนย์ราชบพิธ</mat-option>
                    <mat-option value="RT20102">ศูนย์สนามบินน้ำฯ</mat-option>
                    <mat-option value="RT20103">ศูนย์อินเตอร์เน็ต</mat-option>
                    <!-- <mat-option *ngFor="let branch of branchRCList" [value]="branch.branchCode">
                        {{branch.branch}}
                    </mat-option> -->
                </mat-select> 
            </mat-form-field>
        </div>
    </div>

    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayUserNameCreate === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> จัดทำโดย :</mat-label>
            </div>
        </div> 
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.userNameCreate" name="userNameCreate" formControlName="userNameCreate" ngmodel
                    (ngModelChange)="functionChangeEvent($event)">
                    <mat-option *ngFor="let user of userList" [value]="user.name">
                        {{user.name}}
                    </mat-option>
                </mat-select> 
            </mat-form-field>
        </div>
    </div>

    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayUserNameVerify === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> ตรวจสอบโดย :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.userNameVerify" name="userNameVerify" formControlName="userNameVerify" ngmodel
                    (ngModelChange)="functionChangeEvent($event)">
                    <!-- <mat-option value="0">Show all</mat-option>
                    <mat-option value="RT20101">ศูนย์ราชบพิธ</mat-option>
                    <mat-option value="RT20102">ศูนย์สนามบินน้ำฯ</mat-option>
                    <mat-option value="RT20103">ศูนย์อินเตอร์เน็ต</mat-option> -->
                    <mat-option *ngFor="let user of userList" [value]="user.name">
                        {{user.name}}
                    </mat-option>
                </mat-select> 
            </mat-form-field>
        </div>
    </div>

    <div class="flex flex-column items-center pl-2.5 pt-5" *ngIf="form?.displayUserNamePayment === 'Y'">
        <div class="flex flex-row basis-1/3  text-lg">
            <div class="flex-column text-right w-full">
                <mat-label> บัญชีและการเงิน :</mat-label>
            </div>
        </div>
        <div class="flex flex-row basis-1/3  text-lg">
            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                <mat-select [(ngModel)]="dailyReport.userNamePayment" name="userNamePayment" formControlName="userNamePayment" ngmodel
                    (ngModelChange)="functionChangeEvent($event)">
                    <mat-option *ngFor="let user of userFinancialList" [value]="user.name">
                        {{user.name}}
                    </mat-option>
                </mat-select> 
            </mat-form-field>
        </div>
    </div>
</form>