import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: 'root'
  })
  export class ApiReportService {
    constructor(
        private apiService: ApiService
    ) { }

    DailyReport(request: any): Observable<any[]> {
      return this.apiService.post('/api/dailyreport', request);
    }
    reportDataMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportDataMs24', request);
    }
    reportSummaryApproveDataMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummaryApproveDataMs24', request);
    }
    reportSummaryPrintDataMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummaryPrintDataMs24', request);
    }
    reportControlDataMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportControlDataMs24', request);
    }
    reportProductWorkloadSummary(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportProductWorkloadSummary', request);
    }
    reportProductWorkloadSummaryPayment(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportProductWorkloadSummaryPayment', request);
    }
    reportSummarizingAmountOfWeightMs24PaidPayment(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingAmountOfWeightMs24PaidPayment', request);
    }
    reportSummarizingDaysAndNumberMs24ReceivedPayment(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingDaysAndNumberMs24ReceivedPayment', request);
    }
    reportSummarizingIssuanceFormsProduct(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingIssuanceFormsProduct', request);
    }
    reportSummarizingMs24PaidPayment(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingMs24PaidPayment', request);
    }
    reportSummarizingPaidPayment(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSummarizingPaidPayment', request);
    } 
    reportExportByCountry(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportExportByCountry', request);
    } 
    reportTop10ShipoutByCountry(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportTop10ShipoutByCountry', request);
    } 
    reportTop10ShipoutByCompany(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportTop10ShipoutByCompany', request);
    } 
    reportCountryExport(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportCountryExport', request);
    } 
    reportCompanyInSystem(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportCompanyInSystem', request);
    } 
    reportMs13FileComplaint(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13FileComplaint', request);
    } 
    reportMs13CompleteMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13CompleteMs24', request);
    } 
    reportMs13IncompleteMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13IncompleteMs24', request);
    } 
    reportMs13SurveyorIncompleteMs24(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13SurveyorIncompleteMs24', request);
    } 
    reportMs13IsCommit(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13IsCommit', request);
    } 
    reportFileControlMs13(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportFileControlMs13', request);
    } 
    reportMs13ApprovePerCompany(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13ApprovePerCompany', request);
    } 
    reportMs13PrintList(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs13PrintList', request);
    } 

    reportMs24ByMonth(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportMs24ByMonth', request);
    }
    printAllCertificateMS24(request: any): Observable<any[]> {
      return this.apiService.post('/api/printAllCertificateMS24', request);
    } 
    printAllCertificateMS13(request: any): Observable<any[]> {
      return this.apiService.post('/api/printAllCertificateMS13', request);
    }
    
    reportSendDocumentToOcsConnectExternal(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportSendDocumentToOcsConnectExternal', request);
    }
    
    reportInvoiceBymouthComponent(request: any): Observable<any[]> {
      return this.apiService.post('/api/reportInvoiceBymouthComponent', request);
    }
  }
  