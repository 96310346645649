import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppMasterService } from 'app/business/service/app/app-master.service';

@Component({
  selector: 'app-log-document-action-detail-modal',
  templateUrl: './log-document-action-detail-modal.component.html',
  styleUrls: ['./log-document-action-detail-modal.component.css']
})
export class LogDocumentActionDetailModalComponent implements OnInit {
  responseBody: any = {};
  requestBody: any = "";

  constructor(
    private appMasterService: AppMasterService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    console.log(data)
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    var rqGetLog = {
      id: this.data.logId
    };

    await this.appMasterService.getDocumentActionLogDetail(rqGetLog).then(res => {
      if (res === 'error') {
        return;
      } else {
        this.responseBody = res;

        var responseBodyObj = JSON.parse(this.responseBody.actionJson);
        var responseBodyStr = JSON.stringify(responseBodyObj, null, 4);
  
        this.requestBody = responseBodyStr;

        console.log(this.responseBody);
      }
    });
  }

  exportDocument() {
    window.open(this.data.docUrl, '_blank').focus();
  }
}
