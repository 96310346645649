import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { AppMs24Service } from 'app/business/service/app/app-ms24.service';
import { AuthService } from 'app/core/auth/auth.service';
import _ from 'underscore';

@Component({
  selector: 'app-create-short-modal',
  templateUrl: './create-short-modal.component.html',
  styleUrls: ['./create-short-modal.component.css']
})
export class CreateShortModalComponent implements OnInit {
  disabled: boolean = false;
  form: any = {};
  dataSource: any[] = [];
  datas: any = {};
  mode: any;

  url: any;
  buttonList: any[] = [];
  constructor(
    public dialogRef: MatDialogRef<CreateShortModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private appMs24Service: AppMs24Service,
    private alertMessageService: AlertMessageService,
    private confirmMessageService: ConfirmDialogService,
    private router: Router,
    private _authService:AuthService,
    private appMenuService: AppMenuService,
  ) {
    console.log(data);
    this.datas = data.item;
  }

  async ngOnInit() {
    await this.getRouter();
    this.form.ms24no = this.datas.refNo;
    this.form.expireDate = this.datas.expireDate;
    this.loadData();
  }

  loadData() {
    var rqM24ShortData = {
      hId: this.datas.id
    };

    this.appMs24Service.getCurrentMs24ShortData(rqM24ShortData).then(res => {
      if (res === 'error') {
        this.dataSource = [];
        return;
      } else {
        this.dataSource = res;
      }
    })
  }

  getDynamicLimit(rowData: any): number {
    // Calculate the limit based on rowData.netWeight and rowData.shortWeightUsed
    return rowData.netWeight - rowData.shortWeightUsed;
  }

  saveItem() {
    console.log(this.dataSource);
    let allowSave: boolean = false;
    var el = _.first(this.dataSource);

    const shortRequest = Number(el.shortWeightRequest)
    let shortLimit = this.getDynamicLimit(el);

    if (isNaN(shortRequest) || shortRequest === 0) {
      allowSave = false;
      this.alertMessageService.open({
        message: "",
        title: "กรุณากรอกน้ำหนักที่ Short"
      });

      return;
    } else if (shortRequest > shortLimit) {
      allowSave = false;
      this.alertMessageService.open({
        message: "น้ำหนักที่ Short ได้สูงสุดคือ " + shortLimit.toFixed(6) + " " + el.netWeightUnit,
        title: "คุณกรอกน้ำหนักที่ Short เกินกำหนด กรุณาตรวจสอบอีกครั้ง"
      });

      return;
    } else {
      this.confirmMessageService.open({
        header:"",
        content: "คุณต้องการยื่นคำขอทำ Short นี้หรือไม่?"
      }).then(res => {
        if (res) {
          var shortDtl = {
            itemNo: "1",
            netWeight: el.netWeight,
            shortWeightRequest: shortRequest,
            weightUnit: el.netWeightUnit
          };

          var rqSaveShort = {
            ms24HId: this.datas.id,
            shortDetail: el.productName + " น้ำหนักที่ Short " + shortRequest.toFixed(6) + " ก.ก.",
            itemDetail: shortDtl
          };

          this.appMs24Service.saveMs24Short(rqSaveShort).then(res => {
            if (res === 'error') {
              return;
            } else {
              this.alertMessageService.open({
                title: "",
                message: "ยื่นคำขอสำเร็จ"
              }).then(res => {
                this.dialogRef.close()
                //rdr short list
              })
            }
          })
        }
      })
    }
  }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }
  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }
}
