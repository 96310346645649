<mat-card style="display: flex;flex-flow: column;height: 100%;gap: 30px;">
  <!--**********Element Data**********-->
  <div class="mat-elevation-z8 w100 center">
     <div class="flex flex-col p10">
      <mat-card>
       <mat-card-header  class="fontTitleHeader" >
          รายงาย มส.13
        </mat-card-header>
        <mat-card-content class="example-headers-align center" style="width:600px;">
          <div class="flex">
            <div class="flex-1 w-64">
              <div class="flex flex-row p10">
                <div class="basis-1/2 f18" style="align-self: center;"> รายงานการสรุป : </div>
                <div class="basis-4/5">
                <mat-form-field class="my-form-field formm w100">
                  <mat-label></mat-label>
                  <mat-select  [(ngModel)]="form.optionSelect">
                    <mat-option *ngFor="let Dropdown of SelectDataNameDocumentMs24" [value]="Dropdown.id">
                      {{Dropdown.value}}
                    </mat-option>
                  </mat-select>
                  <mat-error>กรุณาระบุ ชื่อกิจกรรม</mat-error>
                </mat-form-field>
                </div>
              </div>
            </div>
          </div>
          <div  *ngIf="form.optionSelect !=1" >
            <form  name ="FormDoc" [formGroup]="FormDoc"> 
              <div class="flex">
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> วันที่เริ่มต้น : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100" appearance="fill">
                        <input matInput [matDatepicker]="picker1" [(ngModel)]="form.selectedStartDate" formControlName ="startDate">
                        <mat-hint>YYYY/MM/DD</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1 dateFormat="YYYY/MM/DD"></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="FormDoc.controls['startDate'].invalid && (FormDoc.controls['startDate'].dirty || FormDoc.controls['startDate'].touched)" class="p-1 text-sm text-red-800" role="alert">
                        <div *ngIf="FormDoc.controls['startDate'].errors.required">
                          **Start Date is required.
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>

              <div class="flex">
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> วันสุดท้าย : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100" appearance="fill">
                        <input matInput [matDatepicker]="picker2" [(ngModel)]="form.selectedEndDate" formControlName ="endDate" [min]="form.selectedStartDate">
                        <mat-hint>YYYY/MM/DD</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2 dateFormat="YYYY/MM/DD"></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="FormDoc.controls['endDate'].invalid && (FormDoc.controls['endDate'].dirty || FormDoc.controls['endDate'].touched)" class="p-1 text-sm text-red-800" role="alert">
                        <div *ngIf="FormDoc.controls['endDate'].errors.required">
                          **End Date is required.
                        </div>
                      </div>
                      <div *ngIf="FormDoc.touched && FormDoc.invalid" class="p-1 text-sm text-red-800" role="alert">                    
                        **Please add a valid from and to date.
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </form>
              <div class="flex" *ngIf="form.optionSelect ==2 || form.optionSelect ==3 || form.optionSelect ==4 || form.optionSelect ==6">
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> ประเภทสินค้า : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100">
                        <mat-select [(ngModel)]="form.typeproduct" >
                          <mat-option value="ALL">แสดงทั้งหมด</mat-option>
                          <mat-option *ngFor="let product of dataProduct" [value] = "product.productId">
                          {{product.productName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div> 
              </div>
              <div class="pt-4 flex flex-col items-center p10">
                <div class="basis-1/2 f18">
                  <button mat-raised-button style="width: 100px;" color="accent" (click)="Loadexcel()" 
                  [disabled]="FormDoc.pristine || FormDoc.invalid" class="btn btn-success" 
                  mat-button>
                    Export Excell
                  </button>
                  &nbsp;&nbsp;
                  <button mat-raised-button style="width: 100px;" color="accent"  mat-button>
                    Export PDF
                  </button>
                </div>
          
              </div>
          </div>
          <div  *ngIf="form.optionSelect ==1">
            <form  name ="FormDocExportMs13" [formGroup]="FormDocExportMs13"> 
              <div class="flex">
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> วันที่เริ่มต้น : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100" appearance="fill">
                        <input matInput [matDatepicker]="picker3" [(ngModel)]="form.startDateExportMs13" formControlName ="startDateExportMs13">
                        <mat-hint>YYYY/MM/DD</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker3"></mat-datepicker-toggle>
                        <mat-datepicker #picker3 dateFormat="YYYY/MM/DD"></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="FormDocExportMs13.controls['startDateExportMs13'].invalid && (FormDocExportMs13.controls['startDateExportMs13'].dirty || FormDocExportMs13.controls['startDateExportMs13'].touched)" class="p-1 text-sm text-red-800" role="alert">
                        <div *ngIf="FormDocExportMs13.controls['startDateExportMs13'].errors.required">
                          **Start Date is required.
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>

              <div class="flex">
                <div class="flex-1 w-64">
                  <div class="flex flex-row p10">
                    <div class="basis-1/2 f18" style="align-self: center;"> วันสุดท้าย : </div>
                    <div class="basis-4/5">
                      <mat-form-field class="my-form-field formm w100" appearance="fill">
                        <input matInput [matDatepicker]="picker4" [(ngModel)]="form.endDateExportMs13" formControlName ="endDateExportMs13"
                        [min]="form.startDateExportMs13">
                        <mat-hint>YYYY/MM/DD</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker4"></mat-datepicker-toggle>
                        <mat-datepicker #picker4 dateFormat="YYYY/MM/DD"></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="FormDocExportMs13.controls['endDateExportMs13'].invalid && (FormDocExportMs13.controls['endDateExportMs13'].dirty || FormDocExportMs13.controls['endDateExportMs13'].touched)" class="p-1 text-sm text-red-800" role="alert">
                        <div *ngIf="FormDocExportMs13.controls['endDateExportMs13'].errors.required">
                          **End Date is required.
                        </div>
                      </div>
                      <div *ngIf="FormDocExportMs13.touched && FormDocExportMs13.invalid" class="p-1 text-sm text-red-800" role="alert">                    
                        **Please add a valid from and to date.
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </form>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> ประเภทสินค้า : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="my-form-field formm w100">
                      <mat-select [(ngModel)]="form.typeproductForm1">
                        <mat-option value="ALL">แสดงทั้งหมด</mat-option>
                        <mat-option *ngFor="let product of dataProduct" [value] = "product.productId">
                        {{product.productName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div> 
            </div>
            <div class="flex">
              <div class="flex-1 w-64">
                <div class="flex flex-row p10">
                  <div class="basis-1/2 f18" style="align-self: center;"> สถานะ : </div>
                  <div class="basis-4/5">
                    <mat-form-field class="my-form-field formm w100">
                      <mat-select [(ngModel)]="form.statusList">
                        <mat-option value="ALL">แสดงทั้งหมด</mat-option>
                        <mat-option *ngFor="let status of dataStatus" [value] = "status.statusId">
                        {{status.statusName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div> 
            </div>
            <div class="pt-4 flex flex-col items-center p10">
              <div class="basis-1/2 f18">
                <button mat-raised-button style="width: 100px;" color="accent" (click)="Loadexcel()" 
                [disabled]="FormDocExportMs13.pristine || FormDocExportMs13.invalid" class="btn btn-success" 
                mat-button>
                  Export Excell
                </button>
                &nbsp;&nbsp;
                <button mat-raised-button style="width: 100px;" color="accent"  mat-button>
                  Export PDF
                </button>
              </div>
        
            </div>
          </div>
         
        </mat-card-content>
      </mat-card>
    </div>
   </div>
</mat-card>