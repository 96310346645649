<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">ดึงงานกลับ</div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="flex flex-col py-5 gap-4 ">  
      <div>
        <div class="flex flex-row">
          <div class="basis-1/3">เหตุผล : </div>
          <div class="basis-2/3">

<!-- CAN01 = เลขประจําตัวผู้เสียภาษีผิด
CAN02 = ระบุสํานักงานใหญ่ / สาขาผิด
CAN03 = ชื่อผิด
CAN04 = ที่อยูGผิด
CAN05 = ช่องทางการชําระเงินผิด
CAN06 = ระบุรายการผิด
CAN07 = ระบุจํานวนเงินผิด
CAN08 = อื่นๆ -->
            <mat-form-field class="w100">
              <mat-select [(ngModel)]="form.code" name="isActive" #isActive="ngModel">
                  <mat-option value="CAN01">เลขประจําตัวผู้เสียภาษีผิด</mat-option>
                  <mat-option value="CAN02">ระบุสํานักงานใหญ่ / สาขาผิด</mat-option>
                  <mat-option value="CAN03">ชื่อผิด</mat-option>
                  <mat-option value="CAN04">ที่อยู่ผิด</mat-option>
                  <mat-option value="CAN05">ช่องทางการชําระเงินผิด</mat-option>
                  <mat-option value="CAN06">ระบุรายการผิด</mat-option>
                  <mat-option value="CAN07">ระบุจํานวนเงินผิด</mat-option>
                  <mat-option value="CAN08">อื่นๆ</mat-option>
              </mat-select>
          </mat-form-field>

            <mat-form-field class="w100"  style="padding-top: 10px;" >
              <input autocomplete="off" required type="text" matInput placeholder="เหตุผล" #input
                [(ngModel)]="form.remark" name="remark" #remark="ngModel">
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="form" [disabled]="form.remark == null"
      color="accent">ยืนยัน</button>
    <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
  </mat-dialog-actions>