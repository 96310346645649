import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../api.service";

@Injectable({
    providedIn: 'root'
  })
  export class ApiStaffService {
    constructor(
        private apiService: ApiService
    ) { }

    StaffGetListRenew(request: any): Observable<any[]> {
      return this.apiService.post('/api/StaffGetListRenew', request);
    }
      
    StaffGetRenew(request: any): Observable<any[]> {
      return this.apiService.post('/api/StaffGetRenew', request);
    }

    StaffVerifyRenew(request: any): Observable<any[]> {
      return this.apiService.post('/api/staffverifyrenew', request);
    }
    
    StaffHistoryMs13List(request: any): Observable<any[]> {
      return this.apiService.post('/api/staffhistoryms13list', request);
    }

    StaffGetLogMs13(request: any): Observable<any[]> {
      return this.apiService.post('/api/staffgetlogms13', request);
    }
  }
  