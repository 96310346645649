<div>
    <!-- Company Profile -->
    <div class="pt-5">
        <div class="pl-5">COMPANY PROFILE | ข้อมูลบริษัท</div>
        <div class="pl-5">
            <!-- Company Code -->
            <div class="pt-3" style="display: flex; flex-flow: row;">
                <div class="border border-end-0  pl-3" style="width:150px;">
                    <p>Company Code</p>
                </div>
                <div class="border border-start-0 pl-3" style="width:300px; ">
                    <p>COMPANY01234</p>
                </div>
            </div> <!-- End Company Code-->

            <!-- Company Name -->
            <div class="pt-3" style="display: flex; flex-flow: row;">
                <div class="border border-end-0  pl-3" style="width:150px;">
                    <p>Company Name</p>
                </div>
                <div class="border border-start-0 pl-3" style="width:300px;">
                    <p>บริษัท ส่งออกทุกอย่าง จำกัด</p>
                </div>
            </div> <!-- End Company Name-->

            <!-- Address -->
            <div class="pt-3" style="display: flex; flex-flow: row;">
                <div class="border border-end-0  pl-3" style="width:150px;">
                    <p>Address</p>
                </div>
                <div class="border border-start-0 pl-3" style="width:300px; display: flex; flex-flow: row wrap;">
                    <p>150 ถนนราชบพิธ แขวงวัดราชบพิธ เขตพระนคร กรุงเทพฯ 10200</p>
                </div>
            </div> <!-- End Company Address-->

            <!-- Company Email -->
            <div class="pt-3" style="display: flex; flex-flow: row;">
                <div class="border border-end-0  pl-3" style="width:150px;">
                    <p>Company Email</p>
                </div>
                <div class="border border-start-0 pl-3" style="width:300px;">
                    <p>exportcompany@mail.com</p>
                </div>
            </div> <!-- End Company Address-->

            <!-- Fax -->
            <div class="pt-3" style="display: flex; flex-flow: row;">
                <div class="border border-end-0  pl-3" style="width:150px;">
                    <p>Fax</p>
                </div>
                <div class="border border-start-0 pl-3" style="width:300px;">
                    <p>02-123-4567</p>
                </div>
            </div> <!-- End Fax-->

            <!-- Apply Date -->
            <div class="pt-3" style="display: flex; flex-flow: row;">
                <div class="border border-end-0  pl-3" style="width:150px;">
                    <p>Apply Date</p>
                </div>
                <div class="border border-start-0 pl-3" style="width:300px;">
                    <p>2022-08-01</p>
                </div>
            </div> <!-- End Apply Date-->

            <!-- Expire Date -->
            <div class="pt-3" style="display: flex; flex-flow: row;">
                <div class="border border-end-0  pl-3" style="width:150px;">
                    <p>Expire Date</p>
                </div>
                <div class="border border-start-0 pl-3" style="width:300px;">
                    <p>2022-08-01</p>
                </div>
            </div> <!-- End Expire Date-->

            <!-- Last Login -->
            <div class="pt-3" style="display: flex; flex-flow: row;">
                <div class="border border-end-0  pl-3" style="width:150px;">
                    <p>Last Login</p>
                </div>
                <div class="border border-start-0 pl-3" style="width:300px;">
                    <p>2022-08-09</p>
                </div>
            </div> <!-- End Expire Date-->

        </div>
    </div> <!-- End Company Profile -->
</div>