import { Component, OnInit, ViewChild } from '@angular/core';
import { AddReceiverCompanyModalComponent } from './add-receiver-company-modal/add-receiver-company-modal.component';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { ConfirmDialogService } from '@bundle/component/confirm-dialog/confirm-dialog.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppMenuService } from 'app/business/service/app/app-menu.service';

@Component({
  selector: 'app-config-receiver-company',
  templateUrl: './config-receiver-company.component.html',
  styleUrls: ['./config-receiver-company.component.css']
})
export class ConfigReceiverCompanyComponent implements OnInit {
  searchForm: any = {}
  form: any = {}
  dataSource = new MatTableDataSource<any>()
  displayedColumns: string[] = [
    'companyName',
    'country',
    'action',
  ]
  uId: number
  userName: string
  companyId: number
  url: any;
  buttonList: any[];
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  @ViewChild(MatPaginator) paginator: MatPaginator
  @ViewChild(MatSort) sort: MatSort
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger


  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private confirmMessageService: ConfirmDialogService,
    private appMenuService: AppMenuService,
    private appMasterService: AppMasterService,
    private alertMessageService: AlertMessageService,
    private router: Router,
  ) { }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const formData = {
      url: this.url,
      userGroupCode: this.userProfile.userGroupCode,
      userRoleCode: this.userProfile.userRoleCode,
      userCode: this.userProfile.userCode
    };
    this.buttonList = await this.appMenuService.getButtonPermissionPerPages(formData);
  }

  async ngOnInit() {
    // await this.getRouter();
    this.searchForm.nameSearch = ''
    this.uId = this.userProfile.uId;
    this.userName = this.userProfile.userName;
    this.companyId = Number(this.userProfile.cpId);
    this.loadDataList()
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  applyFilterName(event: Event) {
    const filterValueName = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValueName.trim().toLowerCase()
  }

  addReceiver(e) {
    const dialogRef = this.dialog.open(AddReceiverCompanyModalComponent, {
      disableClose: true,
      width: '60vw',
      data: {
        isEdit: e == null ? false : true,
        itemData: e == null ? null : e
      }
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.loadDataList()
      }
    })
  }

  stopPropagation(event) {
    event.stopPropagation()
  }

  // menuOpen() {}
  
  menuClosed() {

  }

  searchOption() {
    this.dataSource.filter = this.searchForm.searchName.trim().toLowerCase()
    this.searchForm.searchName = ''
  }

  loadDataList() {
    const data = {
      companyId: this.companyId,
    }

    this.appMasterService.getCompanyReceiverList(data).then((res: any) => {
      if (res === 'error') {
        return
      } else {
        let a = res.map((item) => {
          switch (item.isActive) {
            case 'Y':
              item.isActive = 'เปิดใช้งาน'
              break
            case 'N':
              item.isActive = 'ปิดใช้งาน'
              break
          }
          return item
        })
        this.dataSource = new MatTableDataSource(a)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.sort
        this.searchForm.search = ''
      }
    })
  }

  delete(e) {
    this.confirmMessageService.open({ header: "", content: "คุณต้องการลบบริษัทผู้รับสินค้านี้หรือไม่?" }).then(res => {
      if (res == false) {
        return
      } else {
        const request = {
          id: e.id,
          isActive: 'N'
        }
        this.appMasterService.saveCompanyReceiverActive(request).then((res: any) => {
          if (res === 'error') {
            return
          } else {
            this.alertMessageService.open({ message: "ลบข้อมูลสำเร็จ", title: "" });
            this.loadDataList();
          }
        })
      }
    })
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList?.find(e => e.buttonCode == buttonCode);

    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  selectionCountryRecieveChange(e): void {
    // this.buyerCountryForm
    //   .get("countryRecieveSearch")
    //   .setValue(e.option.value.code + " : " + e.option.value.name);
    this.form.recieveCountryId = e.option.value.id;
    this.form.recieveCountry = e.option.value.code;
    this.form.recieveCountryCode = e.option.value.code;
  }
}
