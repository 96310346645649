import { ApiService } from "./../api.service";
import { HttpLoadingService } from "./../http-loading.service";
import { AuthService } from "../../../../app/core/auth/auth.service";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(
    private apiService: ApiService,
    private httpLoadingService: HttpLoadingService,
    private _authService: AuthService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.apiService.loading.next(true);
    this.httpLoadingService.start();
     
    var token = localStorage.getItem("token");
    if (this._authService.token != "" || (token != null && token != "")) {
      var tok = this._authService.token ?? token; 
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${tok}`,
        },
      });
    } else {
    }
    return next.handle(req);
  }
}
