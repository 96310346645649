<form #confirmMs24Form="ngForm">
  <mat-card *ngIf="pages == 'createMs24'">
    <mat-card-header class="fontTitleHeader">
      <div class="flex justify-between" style="width: 100%;">
        <div> สร้าง มส. 24</div>
        <div>
          <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <i class="ti ti-x text-white"></i>
          </button>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div class="basis-3/12 p10" style="align-self: center;">ประเภทสินค้าที่ต้องการสร้าง มส. 24 </div>
          <div class="basis-1/2 f18 p10">
            <mat-form-field class="my-form-field formm w100">
              <mat-label></mat-label>
              <mat-select [(ngModel)]="form.product" requested name="docNo" #docNo="ngModel" ngmodel (selectionChange)="dataListMs13()">
                <mat-option *ngFor="let product of productList" [value]="product.productId">
                  {{product.productName}}
                </mat-option>
              </mat-select>
              <mat-error>ประเภทสินค้า</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="flex flex-row">
          <div class="basis-3/12 p10" style="align-self: center;">เลือกเลขที่คำร้อง มส. 13 </div>
          <div class="basis-1/2 f18 p10">
            <mat-form-field class="my-form-field formm w100">
              <mat-label></mat-label>
              <mat-select [(ngModel)]="form.refMs13No" requested name="refMs13No" #refMs13No="ngModel" ngmodel>
                <mat-option *ngFor="let refMs13 of refMs13List" [value]="refMs13.ms13Id">
                  {{refMs13.ms13No}}
                </mat-option>
              </mat-select>
              <mat-error>ประเภทสินค้า</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card-content>
    <div class="flex flex-col items-center p10">
      <div class="basis-1/2 f18">
        <button mat-raised-button [mat-dialog-close] style="flex:0 50%; margin-right: 10px;">
          ปิด
        </button>
        <button mat-raised-button style="width: 100px;" color="accent"
          (click)="confirmCreateMs24($event)"
          [disabled]="onValid(confirmMs24Form)"
          [queryParams]="{ source : 'M' , page :pages , product:  form.product , status : 1 , type : 'NEW' }"
          [mat-dialog-close]>
          ถัดไป
        </button>
      </div>
    </div>
  </mat-card>
</form>

<form #createMs24Form="ngForm">
  <mat-card *ngIf="pages == 'ms24'">
    <mat-card-header class="fontTitleHeader">
      <div class="flex justify-between" style="width: 100%;">
        <div> สร้าง มส. 24</div>
        <div>
          <button mat-icon-button class="close-button" [mat-dialog-close]="true">
            <i class="ti ti-x text-white"></i>
          </button>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="flex flex-col">
        <div class="flex flex-row">
          <div class="mat-elevation-z8">
            <table mat-table style="margin-top: 10px;" matSort [dataSource]="dataMs13Source" (matSortChange)="announceSortChange($event)">
              <ng-container matColumnDef="number">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> ลำดับ </th>
                <td mat-cell *matCellDef="let i = index">
                  {{this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
                </td>
              </ng-container>
              <ng-container matColumnDef="goodsDescription">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">รายการสินค้า</th>
                <td mat-cell *matCellDef="let element"> {{element.goodsDescription}} </td>
              </ng-container>
              <ng-container matColumnDef="grossWeightNet">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">น้ำหนักที่สามารถออก มส. 24 ได้</th>
                <td mat-cell *matCellDef="let element"> {{element.grossWeightNet}} KGM</td>
              </ng-container>
              <ng-container matColumnDef="">
                <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">น้ำหนักที่ออกใบ มส. 24</th>
                <td mat-cell *matCellDef="let element">
                  <!-- <input autocomplete="off" type="text" matInput placeholder="น้ำหนักที่ออกใบ มส. 24" #input [(ngModel)]="searchForm.weightUit" name="weightUit" #weightUit="ngModel"> KGM -->
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator [pageSize]="10" [pageSizeOptions]="[ 10, 20]" showFirstLastButtons
              aria-label="Select page of periodic elements">
            </mat-paginator>
          </div>

        </div>
      </div>
    </mat-card-content>
    <div class="flex flex-col items-center p10">
      <div class="basis-1/2 f18">
        <button mat-raised-button [mat-dialog-close] style="flex:0 50%; margin-right: 10px;">
          ปิด
        </button>
        <button mat-raised-button style="width: 100px;" color="accent" routerLink="/page/o2/create-ms24"
          [disabled]="onValid(createMs24Form)"
          [queryParams]="{ source : 'M' , page :pages , product:  form.product , status : 1 , type : 'NEW', id: ms13Id }"
          [mat-dialog-close]>
          ถัดไป
        </button>
      </div>
    </div>
  </mat-card>
</form>