<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center">{{isEdit ? 'แก้ไข' : 'เพิ่ม'}}บริษัทผู้รับสินค้า </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="false">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="flex flex-col mat-typography" style="gap: 15px; overflow: hidden; padding-bottom: 12px;">
    <div class="pl-5 pr-5 flex flex-row justify-center gap-3">
      <div class="flex-1 flex flex-col justify-center">
        <h4>Company Name <label style="color: red">*</label></h4>
      </div>
      <div class="flex-auto flex flex-row gap-3">
        <div class="flex flex-col justify-center">:</div>
        <!-- <div [formGroup]="searchShipperForm" class="flex-1"> -->
        <mat-form-field class="flex-1">
          <input required autocomplete="off" matInput placeholder="Company Name" [(ngModel)]="form.nameEN" name="nameEN"
            #nameEN="ngModel">
        </mat-form-field>
        <!-- </div> -->
      </div>
    </div>

    <div class="pl-5 pr-5 flex flex-row justify-center gap-3">
      <div class="flex-1 flex flex-col justify-center">
        <h4>Address Line 1 <label style="color: red">*</label></h4>
        <h4>(Street Address, Building, Alley, Road)</h4>
      </div>
      <div class="flex-auto flex flex-row gap-3">
        <div class="flex flex-col justify-center">:</div>
        <!-- <div [formGroup]="searchShipperForm" class="flex-1"> -->
        <mat-form-field class="flex-1">
          <input required autocomplete="off" matInput placeholder="Address Line 1" [(ngModel)]="form.address"
            name="address" #address="ngModel">
        </mat-form-field>
        <!-- </div> -->
      </div>
    </div>

    <div class="pl-5 pr-5 flex flex-row justify-center gap-3">
      <div class="flex-1 flex flex-col justify-center">
        <h4>Address Line 2 (optional)</h4>
        <h4>(Apartment, Suite, P.O. Box, Sub-area, Sub-district)</h4>
      </div>
      <div class="flex-auto flex flex-row gap-3">
        <div class="flex flex-col justify-center">:</div>
        <!-- <div [formGroup]="searchShipperForm" class="flex-1"> -->
        <mat-form-field class="flex-1">
          <input autocomplete="off" matInput placeholder="Address Line 2" [(ngModel)]="form.district" name="district"
            #district="ngModel">
        </mat-form-field>
        <!-- </div> -->
      </div>
    </div>

    <div class="pl-5 pr-5 flex flex-row justify-center gap-3">
      <div class="flex-1 flex flex-col justify-center">
        <h4>City <label style="color: red">*</label></h4>
        <h4>(District, City, Town, Suburb)</h4>
      </div>
      <div class="flex-auto flex flex-row gap-3">
        <div class="flex flex-col justify-center">:</div>
        <!-- <div [formGroup]="searchShipperForm" class="flex-1"> -->
        <mat-form-field class="flex-1">
          <input required autocomplete="off" matInput placeholder="City" [(ngModel)]="form.subProvince"
            name="subProvince" #subProvince="ngModel">
        </mat-form-field>
        <!-- </div> -->
      </div>
    </div>

    <div class="pl-5 pr-5 flex flex-row justify-center gap-3">
      <div class="flex-1 flex flex-col justify-center">
        <h4>Region <label style="color: red">*</label></h4>
        <h4>(State, Province, Territory)</h4>
      </div>
      <div class="flex-auto flex flex-row gap-3">
        <div class="flex flex-col justify-center">:</div>
        <!-- <div [formGroup]="searchShipperForm" class="flex-1"> -->
        <mat-form-field class="flex-1">
          <input required autocomplete="off" matInput placeholder="Region" [(ngModel)]="form.province" name="province"
            #province="ngModel">
        </mat-form-field>
        <!-- </div> -->
      </div>
    </div>

    <div class="pl-5 pr-5 flex flex-row justify-center gap-3">
      <div class="flex-1 flex flex-col justify-center">
        <h4>Post Code / ZIP Code <label style="color: red">*</label></h4>
      </div>
      <div class="flex-auto flex flex-row gap-3">
        <div class="flex flex-col justify-center">:</div>
        <!-- <div [formGroup]="searchShipperForm" class="flex-1"> -->
        <mat-form-field class="flex-1">
          <input required autocomplete="off" matInput placeholder="Post Code / ZIP Code" [(ngModel)]="form.postcode"
            name="vesselName" #vesselName="ngModel">
        </mat-form-field>
        <!-- </div> -->
      </div>
    </div>

    <div class="pl-5 pr-5 flex flex-row justify-center gap-3">
      <div class="flex-1 flex flex-col justify-center">
        <h4>Country <label style="color: red">*</label></h4>
      </div>
      <div class="flex-auto flex flex-row gap-3">
        <div class="flex flex-col justify-center">:</div>
        <!-- <div [formGroup]="searchShipperForm" class="flex-1"> -->
        <mat-form-field [formGroup]="searchCountryForm" class="flex-1">
          <input type="text" required formControlName="countrySearch" matInput [matAutocomplete]="auto" placeholder="Country">
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
            (optionSelected)="selectionCountryRecieveChange($event)">
            <mat-option *ngFor="let options of filteredOptions | async" [value]="options">
              {{options.name}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
        <!-- </div> -->
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex justify-between w100">
      <div>
        <button mat-icon-button matTooltip="วิธีการกรอก?" matTooltipPosition="above" style="width: 48px !important;"
          (click)="openHelper()">
          <i class="ti ti-help"></i>
        </button>
      </div>
      <div class="flex" style="flex-wrap: nowrap;align-items: center;">
        <button mat-raised-button color="accent" (click)="save()" style="width: 100px;">บันทึก</button>
      </div>
      <div style="width: 48px;">
        <ng-container *ngIf="isEdit">
          <button mat-icon-button matTooltip="รีเซ็ตฟอร์ม" matTooltipPosition="above" style="width: 48px !important;"
            (click)="reset()">
            <i class="ti ti-restore"></i>
          </button>
        </ng-container>
      </div>
    </div>
  </mat-dialog-actions>
</mat-card>