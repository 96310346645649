import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppMasterService } from 'app/business/service/app/app-master.service';
import { AppReportService } from 'app/business/service/app/app-report.service';
import moment from 'moment';

@Component({
  selector: 'app-dft-message-report',
  templateUrl: './dft-message-report.component.html',
  styleUrls: ['./dft-message-report.component.css']
})
export class DftMessageReportComponent implements OnInit {
  dailyReport: any = {};
  statusOptionList: any[] = [];
  myControl = new FormControl("");
  myDate = new Date();
  dailyReportForm: FormGroup = this.formBuilder.group({
    month: "",
    year: "",
    startDate: ["", Validators.required],
    startDateOnly: [""],
    endDate: ["", Validators.required],
    typeList: [""],
    statusList: [],
    branch: [""],
  });

  constructor(
    private formBuilder: FormBuilder,
    private appMasterService: AppMasterService,
    private appReportService: AppReportService,
    private alertService: AlertMessageService,
  
  ) { }

  ngOnInit() {
    this.appMasterService.statusData.subscribe(res => {
      if (res.length === 0) {
        this.statusOptionList = [];
      } else {
        this.statusOptionList = res;
      }
    });

    this.dailyReport.typeList = "0";
    this.dailyReport.statusList = "0";
  }

  async exportExcel() {
    if ((this.dailyReport.startDate instanceof Date)) {
      var newDate = moment(this.dailyReport.startDate);

      var adjustedDate = newDate.toISOString(true);

      this.dailyReport.startDate = adjustedDate
    }

    if ((this.dailyReport.endDate instanceof Date)) {
      var newDate = moment(this.dailyReport.endDate);

      var adjustedDate = newDate.toISOString(true);

      this.dailyReport.endDate = adjustedDate
    }

    await this.appReportService.reportSendDocumentToOcsConnectExternal(this.dailyReport).then((res) => {
      if (res !== 'error') {
        var today = moment().format("yyyy-MM-DD");
        this.exportExcelData("รายงานสรุปการส่งเอกสารไปยังระบบ OCS Connect ของกรมการค้าต่างประเทศ-" + today + ".xlsx", res);

      } else {
        this.alertService.info({ title: '', message: 'ไม่สามารถ Export รายงานได้' });
      }
    });
  }


  exportExcelData(fileName, data) {
    var blob = new Blob([this.stringToByteArray(atob(data))]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
  }

  stringToByteArray(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
}
