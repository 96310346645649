import jwt_decode from "jwt-decode";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { environment } from "environments/environment";
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  Subscription,
  throwError,
} from "rxjs";
import { ApiResponseModel } from "./api/api-model";
import { HttpLoadingService } from "./http-loading.service";
import { AuthService } from "app/core/auth/auth.service";
// import { AuthService } from "app/core/auth/auth.service";
// import { AuthService } from "../../../app/core/auth/auth.service";
@Injectable({
  providedIn: "root",
})
export class ApiService {
  loading: BehaviorSubject<boolean>;
  timeout;
  tokenSubscription = new Subscription();
  userProfile: any;
  private url = environment.api;
  private httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
    }),
    params: null,
  };
  private httpFileOptions = {
    headers: new HttpHeaders({
      responseType: "blob",
    }),
    params: null,
  };
  constructor(
    private httpClient: HttpClient,
    public alert: AlertMessageService,
    private httpLoadingService: HttpLoadingService,
    private router: Router,
    private _authService:AuthService,
    private route: ActivatedRoute //   private translate: TranslateService // private _authService: AuthService, // private _authService: AuthService,
  ) {
    this.loading = new BehaviorSubject(false);
  }

  get(action: string, params?: any): Observable<any> {
    this.httpOptions.params = params;
    const httpRequest = this.httpClient.get(
      `${this.url}${action}`,
      this.httpOptions
    );
    return this.handleResponse(httpRequest);
  }

  post(
    action: string,
    requestData: any,
    params?: any,
    alert?: boolean
  ): Observable<any> {
    this.httpOptions.params = params; 
    const httpRequest = this.httpClient.post<any>(
      `${this.url}${action}`,
      requestData,
      this.httpOptions
    );
    return this.handleResponse(httpRequest, alert);
  }

  put(
    action: string,
    requestData: any,
    params?: any,
    alert?: boolean
  ): Observable<any> {
    this.httpOptions.params = params;
    const httpRequest = this.httpClient.put(
      `${this.url}${action}`,
      requestData,
      this.httpOptions
    );
    return this.handleResponse(httpRequest, alert);
  }

  postForm(
    action: string,
    requestData: FormData,
    alert?: boolean
  ): Observable<any> {
    const httpRequest = this.httpClient.post(
      `${this.url}${action}`,
      requestData
    );
    return this.handleResponse(httpRequest, alert);
  }

  getFile(url: string, params?: any): Observable<Blob> {
    this.httpFileOptions.params = params;
    return this.httpClient.get<Blob>(url, this.httpFileOptions);
  }

  private handleResponse_(
    httpRequest: Observable<any>,
    alert?: boolean
  ): Observable<any> {
    return httpRequest.pipe(
      map(
        (next: ApiResponseModel) => {
          this.httpLoadingService.stop();
          if (!next.success) {
            const res = {
              title: next.description,
              message: next.message,
            };
            this.alert.open(res);
            return "error";
          } else {
            // console.log("next", next.key);
            localStorage.setItem("token", next.key);
            return next.items;
          }
        },
        (error: ApiResponseModel) => {
          this.alert.open({
            title: "error",
            message: "การเชื่อมต่อไม่สำเร็จ",
          });
          const res = {
            status: false,
            msg: "Http error.",
            data: error,
          };
          console.error(res);
          return "error";
        }
      ),
      catchError((err) => {
        const res = {
          status: false,
          msg: `${err.statusText} : ${err.url}`,
          data: err,
        };
        const httpError = {
          title: err.url,
          message: err.statusText,
        };
        this.alert.open(httpError);

        this.httpLoadingService.stop();
        const { token } = this.route.snapshot.queryParams;
        if (err?.statusText === "Request Timeout" && !token) {
          this.router.navigate(["login"]);
        }

        console.error("catchError", httpError);
        // return of(res);
        return throwError("error");
      })
    );
  }

  private handleResponse(
    httpRequest: Observable<any>,
    alert?: boolean
  ): Observable<any> {
    return httpRequest.pipe(
      map(
        (next: ApiResponseModel) => {
          this.httpLoadingService.stop();
           ;
          if (!next.success) {
            if (next?.success == undefined) {
            } else {
              const res = {
                title: next.description,
                message: next.message,
              };
              this.alert.open(res);
            }
            return "error";
          } else { 
             
            let getToken = this.getDecodedAccessToken(next.key);
            getToken = {
              ...getToken,
              uId: getToken.uId,
              uid: getToken.uId,
            };
            this.userProfile = JSON.stringify(getToken);
            var login = localStorage.getItem("login");
            localStorage.getItem("userProfile");
            if (login == "true") {
              localStorage.setItem("userProfile", JSON.stringify(getToken));
            }
            const urlLink = this.router.url;
            if (
              urlLink != "/condition" &&
              urlLink != "/sign-in" &&
              urlLink != "/" &&
              !getToken &&
              getToken.userName == null
            ) {
              return;
            }
            //=======================
            localStorage.setItem("token", next.key);

            this._authService.token = next.key;
            return next.items;
          }
        },
        (error: ApiResponseModel) => {
          this.alert.open({
            title: "error",
            message: "การเชื่อมต่อไม่สำเร็จ",
          });
          const res = {
            status: false,
            msg: "Http error.",
            data: error,
          };
          return "error";
        }
      ),
      catchError((err) => {
        const res = {
          status: false,
          msg: `${err.statusText} : ${err.url}`,
          data: err,
        };
        const httpError = {
          title: err.url,
          message: err.statusText,
        };
        this.alert.open(httpError);

        this.httpLoadingService.stop();
        const { token } = this.route.snapshot.queryParams;

        return throwError("error");
      })
    );
  }
  getDecodedAccessToken(token: string): any {
    return jwt_decode(token);
  }
}
