<table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
  <!-- Position Column -->
  <ng-container matColumnDef="position">
    <th mat-header-cell *matHeaderCellDef class="text-center"> No. </th>
    <td mat-cell *matCellDef="let element; let i = index" class="text-center"> {{i + 1}} </td>
  </ng-container>

  <!-- Fav Column -->
  <ng-container matColumnDef="attachType">
    <th mat-header-cell *matHeaderCellDef class="text-center"> ประเภทเอกสาร<br>Attachment Type </th>
    <td mat-cell *matCellDef="let element; let i = index">
      <mat-form-field floatLabel="never" style="width:100%;margin-top: 4px;margin-bottom: 4px;">
        <mat-select [(ngModel)]="element.attachType" placeholder="ประเภทเอกสาร/Attachment Type"
          (selectionChange)="onSelectionChanged($event, element)" [disabled]="isDisabled"
          (ngModelChange)="onSelectionChanged($event, element)">
          <mat-option *ngFor="let destination of attachTypeList" [value]="destination.maT_DOC_TYPE_ID">
            {{destination.maT_DOC_NAME}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="attachFilePic">
    <th mat-header-cell *matHeaderCellDef class="text-center"> ภาพตัวอย่าง<br>Example </th>
    <td mat-cell *matCellDef="let element" class="text-center">
      <div style="display: flex;flex-direction: column;align-items: center;">
        <img class="h100" (click)="onView(element.attachFilePic)" [src]="element.attachFilePic" />
      </div>
    </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="remark">
    <th mat-header-cell *matHeaderCellDef class="text-center"> หมายเหตุ<br>Remark </th>
    <td mat-cell *matCellDef="let element">
      <mat-form-field floatLabel="never" style="width:100%;margin-top: 4px;margin-bottom: 4px;">
        <input matInput placeholder="หมายเหตุ/Remark" [value]="element.remark" [(ngModel)]="element.remark"
          (keyup)="onChanged($event)" [disabled]="isDisabled" maxlength="1000">
      </mat-form-field>
    </td>
  </ng-container>

  <!-- Action -->
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef class="text-center"> </th>
    <td mat-cell *matCellDef="let element;let i = index" class="text-center">
      <div style="margin-top:6px;">
        <button (click)="deleteAttachment(element, i)">
          <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
        </button>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>