<div class="menu-top">
  <h2 style="cursor:pointer;text-align: center;">Group Profile</h2>
  <i class="ti ti-x text-white f17e" style="cursor: pointer;" mat-dialog-close></i>
</div>
    <div class="flex flex-col">
      <div class="pt-10 basis-1/4" style="display:flex; flex-flow:row; width: 100%;">
              
        <div style="display:flex; flex-flow:column; width: 30%; align-items: star;"> 
          <mat-label class="pl-8" style="display:flex; align-items: start;">กลุ่มผู้ใช้งาน</mat-label>
          <mat-label class="pl-8" style="display:flex; align-items: center;">Group name</mat-label>
        </div>  
      
        <div class="basis-3/4" style="display:flex; flex-flow:row; width: 100%;">
          <mat-form-field class="pl-4" style="height:auto; width: 70%;">
            <input autocomplete="off" type="text" matInput  #input
            [(ngModel)]="form.GroupName" name="GroupName" #GroupName="ngModel"> 
          </mat-form-field>
        </div>
      </div>
      <div class="pt-4 basis-1/4" style="display:flex; flex-flow:row; width: 100%;">
              
        <div style="display:flex; flex-flow:column; width: 30%; align-items: star;"> 
          <mat-label class="pl-8" style="display:flex; align-items: start;">บริษัท</mat-label>
          <mat-label class="pl-8" style="display:flex; align-items: center;">Company</mat-label>
        </div>  
      
        <div class="basis-3/4" style="display:flex; flex-flow:row; width: 100%;">
          <mat-form-field class="pl-4" style="height:auto; width: 70%;">
                    <mat-select [(ngModel)]="form.GroupCompanyId" required="true">
                      <mat-option *ngFor="let company of dataCompany" [value] = "company.companyId">
                      {{company.companyName}}
                      </mat-option>
                    </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="pt-4 basis-1/4" style="display:flex; flex-flow:row; width: 100%;">

        <div style="display:flex; flex-flow:column; width: 30%; align-items: star;"> 
          <mat-label class="pl-8" style="display:flex; align-items: start;">Admin group</mat-label>
        </div>  

        <div class="basis-3/4" style="display:flex; flex-flow:row; width: 100%;">
          <div class = "pl-4" > 
            <mat-radio-group aria-labelledby="radio-group-label" class="radio-group" 
            [(ngModel)]="form.RadioBox" name="RadioBox" #RadioBox="ngModel" required="true">
              <mat-radio-button class="radio-button" value="Y">Y</mat-radio-button>
              <mat-radio-button class="radio-button" value="N">N</mat-radio-button>   
            </mat-radio-group>
          </div>    
        </div>
      </div>
    </div>
    <div class="pt-4 flex flex-col items-center p10">
      <div class="basis-1/2 f18">
        <button mat-raised-button style="width: 100px;" color="accent" (click)="saveData()" mat-button>
          Save
        </button>
        &nbsp;&nbsp;
        <button mat-raised-button style="width: 100px;" color="accent" (click)="onReset()" mat-button>
          Reset
        </button>
      </div>

    </div>
  

