import { Component, OnInit, ViewChild } from "@angular/core";
import moment from "moment";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AppStaffService } from "app/business/service/app/app-staff.service";
import { IResStaffGetRenew } from "app/business/interface/app/app-staff.interface";
import { MatDatepicker } from "@angular/material/datepicker";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { MatDialog } from "@angular/material/dialog";

const MY_DATE_FORMATS = {
  parse: {
    dateInput: "YYYY-MM-DD",
  },
  display: {
    dateInput: "YYYY-MM-DD",
    monthYearLabel: "YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY",
  },
};

@Component({
  selector: "app-staff-renew-approve-inquiry",
  templateUrl: "./staff-renew-approve-inquiry.component.html",
  styleUrls: ["./staff-renew-approve-inquiry.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
  ],
})
export class StaffRenewApproveInquiryComponent implements OnInit {
  form: any = {};
  angForm: FormGroup = this.formBuilder.group({
    status: ["", Validators.required],
    expireDate: ["", Validators.required],
  });
  dateSelect: any;

  data: any = {};
  staffGet: IResStaffGetRenew;
  minDate: Date;
  maxDate: Date;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private appStaffService: AppStaffService,
    private alertService: AlertMessageService,
    private dialog: MatDialog,
    private router: Router
  ) {
    this.dateSelect = moment(this.form.selectedDate).format("YYYY-MM-DD");
  }

  async ngOnInit() {
    const { id, typeID } = this.route.snapshot.queryParams;
    this.data.id = id;
    this.data.typeID = typeID;
    await this.loadData(this.data);
    if (typeID == 2) {
      this.minDate = new Date(this.staffGet?.expireDate);
      this.maxDate = new Date(this.minDate);
      this.maxDate.setDate(this.maxDate.getDate() + 30);
      this.form.expireDate = new Date(this.minDate);
    } else if (typeID == 13) {
      // Condition คือ ต่ออายุได้ไม่เกิน 20 วัน/ต่ออายุได้ 1 ครั้ง
      // ถ้าเป็น ข้าวหอมมะลิ/มันสำปะหลัง วันหมดอายุต้องไม่เกินใบ อ.2 ส่วนอื่นๆ ได้ไม่เกิน 20 วัน
      this.minDate = new Date(this.staffGet.expireDate);
      this.maxDate = new Date(this.minDate);
      this.maxDate.setDate(this.maxDate.getDate() + 20);
      this.form.expireDate = new Date(this.minDate);
    } else if (typeID == 24) {
      this.minDate = new Date(this.staffGet.expireDate);
      this.maxDate = new Date(this.minDate);
      this.maxDate.setDate(this.maxDate.getDate() + 10);
    }
  }

  async loadData(e) {
    const data = {
      id: e.id,
      typeID: e.typeID,
    };
    await this.appStaffService.StaffGetRenew(data).then((res) => {
      this.staffGet = res;
    });
    console.log(this.staffGet);
  }

  reloadPage() {
    this.form = {};
  }

  upDate(status) {
    this.form.status = status;
    if (this.form.status == 4) {
      const data = {
        ID: this.staffGet.id,
        action: "reject",
        typeID: this.data.typeID,
      };
      this.appStaffService.StaffVerifyRenew(data).then((res) => {
        if (res !== "error") {
          this.alertService
            .info({ title: "", message: "บันทึกข้อมูลเรียบร้อย" })
            .then((x) => {
              this.dialog.closeAll();
              this.router.navigate(["/page/staff-renew/staff-renew-list"]);
            });
        }
      });
    } else if (this.form.status == 9) {
      const data = {
        ID: this.staffGet.id,
        action: "complete",
        typeID: this.data.typeID,
        oldDate: this.staffGet.oldDate,
        newDate: this.form.expireDate,
      };
      this.appStaffService.StaffVerifyRenew(data).then((res) => {
        if (res !== "error") {
          this.alertService
            .info({ title: "", message: "บันทึกข้อมูลเรียบร้อย" })
            .then((x) => {
              this.dialog.closeAll();
              this.router.navigate(["/page/staff-renew/staff-renew-list"]);
            });
        }
      });
    }
  }
}
