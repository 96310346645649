<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center"> Province </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content>
    <div class="pl-22 pr-22 flex flex-col gap-4">    
      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>ชื่อจังหวัด (ภาษาไทย) <span style="color:red;">*</span></h4>
          <h4>Province Name (Thai)</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="ชื่อจังหวัด (ภาษาไทย)" matInput #input [(ngModel)]="form.provinceName"
              name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5" style="align-items: center;">
        <div class="mat-popup-header flex flex-col">
          <h4>ชื่อจังหวัด (อังกฤษ) <span style="color:red;">*</span></h4>
          <h4>Province Name (English)</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-form-field style="flex: 0 95%;" class="flex">
            <input required autocomplete="off" type="text" matInput placeholder="ชื่อจังหวัด (อังกฤษ)" matInput #input [(ngModel)]="form.provinceEname"
              name="name" #name="ngModel">
          </mat-form-field>
        </div>
      </div>

      <div class="flex-row flex justify-between gap-2.5">
        <div class="mat-popup-header flex flex-col">
          <h4>ใช้งาน</h4>
          <h4>Active</h4>
        </div>
        <div class="mat-popup flex flex-row justify-between items-center" style="width:74%">
          <div>:</div>
          <mat-slide-toggle [checked]="form.isActive === 'Y' ? true : false" (change)="onChange($event)"
            style="flex: 0 95%;" class="flex">
            Active</mat-slide-toggle>
        </div>
      </div>
      <div class="flex-row flex justify-center gap-2.5" style="gap: 10px;">
        <button mat-raised-button color="accent" (click)="save()">บันทึก</button>
        <button mat-raised-button [mat-dialog-close]>ยกเลิก</button>
      </div>
    </div>
  </mat-dialog-content>