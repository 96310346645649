<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
        <button mat-raised-button color="accent" (click)="getReport()">
          <div class="flex" style="gap: 5px;">
            <i class="ti ti-report-analytics" style="font-size: 1.5em;"></i>
            <span class="self-center">Get Message Report</span>
          </div>
        </button>

        <button mat-raised-button style="width: 70px;font-size: 1.5em;" matTooltip="รีเฟรชข้อมูล" matTooltipPosition="above" (click)="onRefresh()">
          <i class="ti ti-refresh"></i>
        </button>
      </div>
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">
          <!-- <h4>records</h4> -->
          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="Reference No. / License No. / Exporter Name"
              matInput #input [(ngModel)]="searchString" name="searchText" #searchText="ngModel"
              (keyup)="applyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu" (menuClosed)="menuClosed()">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>License No.</mat-label>
                    <input autocomplete="off" type="text" matInput #input [(ngModel)]="searchForm.licenseNo"
                      name="licenseNo" #licenseNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>Reference No.</mat-label>
                    <input autocomplete="off" type="text" matInput #input [(ngModel)]="searchForm.referenceNo"
                      name="referenceNo" #referenceNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>Exporter Name</mat-label>
                    <input autocomplete="off" type="text" matInput #input [(ngModel)]="searchForm.exporterName"
                      name="exporterName" #exporterName="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>DocType</mat-label>
                    <mat-select [(ngModel)]="searchForm.docType" name="docType" #docType="ngModel" ngmodel>
                      <mat-option value="O2">อ.2</mat-option>
                      <mat-option value="MS13">มส.13</mat-option>
                      <mat-option value="MS24">มส.24</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>สถานะ</mat-label>
                    <mat-select [(ngModel)]="searchForm.status" name="status" #status="ngModel" ngmodel>
                      <mat-option *ngFor="let status of statusList" [value]="status.statusId">
                        {{status.statusName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>สถานะ</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทสินค้า</mat-label>
                    <mat-select [(ngModel)]="searchForm.productId" name="productId" #productId="ngModel" ngmodel>
                      <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>ประเภทสินค้า</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div>
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" (click)="searchOption()" style="flex:0 50%;">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="index">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ลำดับ</th>
        <td mat-cell *matCellDef="let element; let i = index" style="justify-content: center;"> {{ i + 1 }} </td>
      </ng-container>

      <ng-container matColumnDef="docTypeName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">DocType</th>
        <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.docTypeName}} </td>
      </ng-container>

      <ng-container matColumnDef="referenceNo" style="justify-content: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Reference No.</th>
        <td mat-cell *matCellDef="let element"> {{element.referenceNo}} </td>
      </ng-container>

      <ng-container matColumnDef="licenseNo" style="justify-content: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">License No.</th>
        <td mat-cell *matCellDef="let element"> {{element.licenseNo}} </td>
      </ng-container>

      <ng-container matColumnDef="exporterName" style="justify-content: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Exporter Name</th>
        <td mat-cell *matCellDef="let element"> {{element.exporterName}} </td>
      </ng-container>

      <ng-container matColumnDef="product" style="justify-content: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Product</th>
        <td mat-cell *matCellDef="let element"> {{element.product}} </td>
      </ng-container>

      <ng-container matColumnDef="statusName" style="justify-content: center;">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Status</th>
        <td mat-cell *matCellDef="let element"> {{element.statusName}} </td>
      </ng-container>

      <ng-container matColumnDef="lastUpdate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">Last Update
        </th>
        <td mat-cell *matCellDef="let element"> {{(element.lastUpdate | date:'yyyy-MM-dd') || '-'}}
          {{(element.lastUpdate | date:'HH:mm') || null}} </td>
      </ng-container>

      <ng-container matColumnDef="action" style="text-align: left; min-width: 170px;">
        <th mat-header-cell *matHeaderCellDef style="text-align: left; min-width: 170px;"> Action </th>
        <td mat-cell *matCellDef="let element;let i = index" style="text-align: left;min-width: 170px;">
          <div class="flex">
            <div>
              <button mat-icon-button matTooltip="Information" matTooltipPosition="above" (click)="openInfo(element)">
                <i class="ti ti-info-circle"></i>
              </button>
            </div>
            <!-- <div>
              <button mat-icon-button matTooltip="Forward" matTooltipPosition="above">
                <i class="ti ti-mail-forward"></i>
              </button>
            </div> -->
            <div>
              <button mat-icon-button matTooltip="Resend" matTooltipPosition="above" (click)="resendDftMessage(element)">
                <i class="ti ti-mail-share"></i>
              </button>
            </div>
            <div>
              <button mat-icon-button matTooltip="Cancel License" matTooltipPosition="above" (click)="sendCancelDftMessage(element)" *ngIf="element.status != 4 && element.status != 5 && element.status != 6">
                <i class="ti ti-mail-off"></i>
              </button>
            </div>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements" (page)="pageChanged($event)">
    </mat-paginator>
  </div>
</div>