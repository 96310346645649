import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { AppSurveyorService } from 'app/business/service/app/app-surveyor.service';
import { surveyorData,surveyorDatalist } from 'app/mock-api/apps/dft-surveyor/data';

@Component({
    selector: 'app-surveyor-search-inquiry',
    templateUrl: './surveyor-search-inquiry.component.html',
    styleUrls: ['./surveyor-search-inquiry.component.css']
})

export class SurveyorSearchInquiryComponent implements OnInit {

    form: any ={};
    ID: any;

    constructor(
        private route: ActivatedRoute,
        private appSurveyor : AppSurveyorService,
        private alertService: AlertMessageService,
        private dialog: MatDialog,
    ){

    }
    ngOnInit(): void {
      const ID = this.route.snapshot.queryParams;
      this.ID = ID;
      console.log(this.ID)
      if(this.ID.ID){
          this.loadData(ID);
      }
    }

    async loadData(id){
        const surId = {
            surveyorID: id.ID
        }
        this.form = await this.appSurveyor.getDataSurveyorSearch(surId);
    }

    async saveRemark(id){
        const surId = {
            surveyorID: id.ID,
            remark: this.form.REMARK
        }
        this.appSurveyor.saveDataSurveyorSearch(surId).then((res) =>{
            if (res !== 'error') {
              this.alertService.info({ title: '', message: 'บันทึกข้อมูลเรียบร้อย' }).then(x =>{
                this.dialog.closeAll();
                this.loadData(id.ID);
              }); 
            }
          });
    }
        
}