<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <!-- <app-head-o2 style="width: 100%;" [type]="'ms13'"></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;align-items: stretch;">
        <!-- Mockup TypeUser Surveyor  -->
        <button mat-raised-button style="width: 70px;font-size: 1.5em;" matTooltip="รีเฟรชข้อมูล" matTooltipPosition="above" (click)="onRefresh()">
          <i class="ti ti-refresh"></i>
        </button>
      </div>
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">
          <!-- <h4>records</h4> -->
          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="เลขที่คำร้อง / เลขที่ใบอนุญาต อ.2" matInput
              #input [(ngModel)]="searchString" name="searchText" #search="ngModel"
              (keyup)="applyFilter($event.target.value)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu" (menuClosed)="menuClosed()">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>วันหมดอายุ</mat-label>
                    <input autocomplete="off" placeholder="วันหมดอายุ" matInput [matDatepicker]="exDate"
                      [(ngModel)]="searchForm.expireDate" name="expireDate" #expireDate="ngModel" readonly>
                    <mat-datepicker-toggle matSuffix [for]="exDate"></mat-datepicker-toggle>
                    <mat-datepicker #exDate></mat-datepicker>
                    <mat-error>กรุณาระบุ วันหมดอายุ</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทเรือ</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.vesselName" name="vesselName" #vesselName="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ใบรับรองเลขที่</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.docNo" name="docNo" #docNo="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่คำร้อง</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.ms13No" name="ms13No" #ms13No="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขที่ใบอนุญาต อ.2</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" #input
                      [(ngModel)]="searchForm.o2No" name="o2No" #o2No="ngModel">
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>สถานะเอกสาร</mat-label>
                    <mat-select [(ngModel)]="searchForm.actionType" name="actionType" #actionType="ngModel" ngmodel>
                      <mat-option *ngFor="let action of actionTypeList" [value]="action.documentActionCode">
                        {{action.documentActionTName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex flex-row">
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทสินค้า</mat-label>
                    <mat-select [(ngModel)]="searchForm.productId" name="productId" #productId="ngModel" ngmodel>
                      <mat-option *ngFor="let product of productList" [value]="product.docTypeId">
                        {{product.productName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>ประเภทสินค้า</mat-error>
                  </mat-form-field>
                </div>
                <div class="basis-1/2 f18">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>สถานะ</mat-label>
                    <mat-select [(ngModel)]="searchForm.statusId" name="statusId" #statusId="ngModel" ngmodel>
                      <mat-option *ngFor="let status of statusList" [value]="status.statusId">
                        {{status.statusName}}
                      </mat-option>
                    </mat-select>
                    <mat-error>สถานะ</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="flex justify-end formm">
                <div class="flex flex-row">
                  <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                    เคลียร์
                  </button>
                  <button mat-raised-button color="accent" (click)="searchOption()" style="flex:0 50%;">
                    ค้นหา
                  </button>
                </div>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <ng-container matColumnDef="index">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ลำดับ</th>
        <td mat-cell *matCellDef="let element; let i = index" class="text-left"> {{ i + 1 }} </td>
      </ng-container>
      
      <ng-container matColumnDef="companyName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="justify-content: center;">ผู้ส่งออก</th>
        <td mat-cell *matCellDef="let element" style="justify-content: center;"> {{element.companyName}} </td>
      </ng-container>
      
      <ng-container matColumnDef="docRefNo">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          เลขที่คำร้อง</th>
        <td mat-cell *matCellDef="let element"> {{element.docRefNo || '-'}} </td>
      </ng-container>
      
      <ng-container matColumnDef="logDate">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          วันที่เกิดรายการ</th>
        <td mat-cell *matCellDef="let element"> {{(element.logDate | date:'yyyy-MM-dd HH:mm') || '-'}} </td>
      </ng-container>
      
      <ng-container matColumnDef="actionDescription">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">รายละเอียดรายการ</th>
        <td mat-cell *matCellDef="let element"> {{element.actionDescription || '-'}} </td>
      </ng-container>
      
      <ng-container matColumnDef="logBy">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">ผู้ทำรายการ
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.logBy }} </td>
      </ng-container>
      
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element;let i = index">
          <button mat-icon-button matTooltip="รายละเอียดเอกสาร" routerLink="/page/o2/create-ms24" 
          [queryParams]="{ page :'ms24' , product:  element.productId , status : element.status , type : 'Detail', id: element.ms24hid, refNo : element.refNo }" matTooltipPosition="above">
            <i class="ti ti-file-description"></i>
          </button>

          <button mat-icon-button matTooltip="รายละเอียดรายการ" matTooltipPosition="above" (click)="openLog(element)">
            <i class="ti ti-list-search"></i>
          </button>

          <button *ngIf="checkPrintButton(element)"
            mat-icon-button matTooltip="พิมพ์เอกสาร มส.24" (click)="exportMs24(element)" matTooltipPosition="above">
            <i class="ti ti-printer"></i>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements" (page)="pageChanged($event)">
    </mat-paginator>
  </div>
</div>