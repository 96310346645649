import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { ApiMs13Service } from "../api/api-ms13.sevice";

@Injectable({
  providedIn: "root",
})
export class AppMs13Service {
  ms13Lists: BehaviorSubject<any[]>;
  mS13Attachment: BehaviorSubject<any[]>;
  mS24Attachment: BehaviorSubject<any[]>;
  mS13Detail: BehaviorSubject<any[]>;
  constructor(private apiMs13Service: ApiMs13Service) {
    this.ms13Lists = new BehaviorSubject<any[]>(new Array());
    this.mS13Attachment = new BehaviorSubject<any[]>(new Array());
    this.mS24Attachment = new BehaviorSubject<any[]>(new Array());
    this.mS13Detail = new BehaviorSubject<any[]>(new Array());
    
  }

  saveItemMs13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.MS13itemsave(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getDataMS13Detail(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getDataMS13Detail(dataReq).subscribe((res) => {
        this.mS13Detail.next(res);
        resolve(res);
      });
    });
  }
  getDataMS13Attachment(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getDataMS13Attachment(dataReq).subscribe((res) => {
        this.mS13Attachment.next(res);
        resolve(res);
      });
    });
  }
  getDataMS24Attachment(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getDataMS24Attachment(dataReq).subscribe((res) => {
        this.mS24Attachment.next(res);
        resolve(res);
      });
    });
  }
  getListMs13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.MS13list(dataReq).subscribe((res) => {
        this.ms13Lists.next(res);
        resolve(res);
      });
    });
  }
  getDataMS13ById(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getDataMS13ById(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  checkStatusPrint(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.checkStatusPrint(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getListMs13No(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.MS13Nolist(dataReq).subscribe((res) => {
        //this.ms13NoLists.next(res);
        resolve(res);
      });
    });
  }

  saveMs13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.MS13save(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMS13Item(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getMS13item(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getDataMS13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getdataMS13(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getRenewMS13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getrenewMS13(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  SentRenewMs13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.renewMS13sent(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  exportMs13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.exportMs13(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  MS13ExportExcelStaffDocumentRequest(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service
        .MS13ExportExcelStaffDocumentRequest(dataReq)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  MS13ExportExcelStaffAllApprove(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service
        .MS13ExportExcelStaffAllApprove(dataReq)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  MS13ExportExcelStaffAllApproveNoMs24(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service
        .MS13ExportExcelStaffAllApproveNoMs24(dataReq)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  MS13ExportExcelStaffSurveyer(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service
        .MS13ExportExcelStaffSurveyer(dataReq)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  MS13ExportExcelStaffCountBotReview(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service
        .MS13ExportExcelStaffCountBotReview(dataReq)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  MS13ExportExcelStaffControl(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service
        .MS13ExportExcelStaffControl(dataReq)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  MS13ExportExcelStaffRice(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.MS13ExportExcelStaffRice(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  MS13ExportExcelStaffSummaryPrint(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service
        .MS13ExportExcelStaffSummaryPrint(dataReq)
        .subscribe((res) => {
          resolve(res);
        });
    });
  }

  getDataMs13Sur(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getDataMs13Sur(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  genRunningMs13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.genRunningMs13(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateUseDataMS13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.updateUseDataMS13(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  updateStatusMs13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.updateStatusMs13(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateBotReceive(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.updateBotReceive(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateBotApprove(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.updateBotApprove(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getRenewMs13List(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getRenewMs13List(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveMs13FromO2(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.saveMs13FromO2(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveMs13SurveyorAuditPerson(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.saveMs13SurveyorAuditPerson(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveConfirmMs13SurveyorResult(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.saveConfirmMs13SurveyorResult(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMs13SurveyorAuditPerson(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getMs13SurveyorAuditPerson(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }

  getMs13SurveyorResultList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getMs13SurveyorResultList(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }

  getMs13SurveyorFullResult(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getMs13SurveyorFullResult(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }

  saveMs13SurveyResult(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.saveMs13SurveyResult(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }

  saveMs13JobStatus(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.saveMs13JobStatus(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }
  
  saveMs13SurveyorJob(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.saveMs13SurveyorJob(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }

  printCertificateMS13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.printCertificateMS13(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }

  printCopyCertificateMS13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.printCopyCertificateMS13(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }

  deleteMs13Attachment(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.deleteMs13Attachment(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }

  deleteMs13Item(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.deleteMs13Item(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }

  updateStatusRenewMs13(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.updateStatusRenewMs13(dataReq).subscribe((res) => {
        resolve(res);
      })
    })
  }

  lockPrint(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.lockPrint(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMs13SurveyLocationHistory(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getMs13SurveyLocationHistory(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  recalculateMs13FobData(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.recalculateMs13FobData(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateMs13ApproveStatus(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.updateMs13ApproveStatus(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMs13DocumentActionHistoryList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getMs13DocumentActionHistoryList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getMs13PerDocumentActionHistoryList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiMs13Service.getMs13PerDocumentActionHistoryList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
