import { LiveAnnouncer } from "@angular/cdk/a11y";
import { Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular";
import { AppO2Service } from "../../../service/app/app-o2.service";

import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AppMasterService } from "app/business/service/app/app-master.service";
import { AppMs24Service } from "app/business/service/app/app-ms24.service";
import { AppMenuService } from "app/business/service/app/app-menu.service";
import { AuthService } from "app/core/auth/auth.service";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { Ms24PrintUserModalComponent } from "../../ms24/ms24-print-user-modal/ms24-print-user-modal.component";
import { AppMs13Service } from "app/business/service/app/app-ms13.service";
import { Ms24ViewerComponent } from "../../ms24/ms24-viewer/ms24-viewer.component";

@Component({
  selector: "app-print-inquiry",
  templateUrl: "./print-inquiry.component.html",
  styleUrls: ["./print-inquiry.component.css"],
})
export class PrintInquiryComponent implements OnInit {
  searchForm: any = {};
  form: any = {};
  productList: any = {};
  statusList: any = {};
  dataSource = new MatTableDataSource<any>();
  displayedColumns: string[] = [
    "companyName",
    "ms13No",
    "o2No",
    "docNo",
    "productId",
    "expireDate",
    "vesselName",
    "statusCode",
    "statusPrint",
    "userPrint",
    "branchPrint",
    "action",
  ];

  url: any;
  buttonList: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  userProfile: any = JSON.parse(localStorage.getItem("userProfile") as string);
  pagination: {
    take: 1;
    skip: 10;
  };
  count: number;
  searchString: any = "";
  timer: any;

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private appMasterService: AppMasterService,
    private appO2Service: AppO2Service,
    private confirmService: ConfirmDialogService,
    private appMs24Service: AppMs24Service,
    private appMs13Service: AppMs13Service,
    private router: Router,
    private _authService: AuthService,
    private alertMessageService: AlertMessageService,
    private appMenuService: AppMenuService
  ) { }

  async getRouter(): Promise<void> {
    this.url = this.router.url;

    const profile = JSON.parse(this._authService.loginUser);
    const formData = {
      url: this.url,
      userGroupCode: profile?.userGroupCode,
      userRoleCode: profile?.userRoleCode,
      userCode: profile?.userCode,
    };
    await this.appMenuService
      .getButtonPermissionPerPages(formData)
      .then((bb) => {
        this.buttonList = bb;
      });
  }

  checkButton(buttonCode: string) {
    var checkBtn = this.buttonList.find((e) => e.buttonCode == buttonCode);
    if (checkBtn != undefined) {
      return true;
    } else {
      return false;
    }
  }

  async ngOnInit() {
    await this.getRouter();
    this.loadData();
    this.searchForm.nameSearch = "";
  }

  async loadData() {
    const formProduct = {
      companyID: Number(this.userProfile.cpId),
      productId: 0,
      isActive: "Y",
    };
    this.productList = await this.appMasterService.getProduct(formProduct);

    const formStatus = {
      statusId: 0,
      isActive: "Y",
    };
    await this.appMasterService.getStatus(formStatus).then((status) => {
      this.statusList = status;
    });

    this.loadDataSource();
  }

  searchOption() {
    this.searchForm.keyword = "";
    this.searchString = "";

    this.loadDataSource();
  }

  clearOption() {
    this.searchForm = {};
    this.searchString = "";
    this.searchOption();
  }

  async loadDataSource() {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    const formData = {
      UID: this.userProfile.uId,
      refNo: this.searchForm.refNo,
      keyword: this.searchForm.keyword,
      o2No: this.searchForm.o2No,
      docNo: this.searchForm.docNo,
      petitionNo: this.searchForm.petitionNo,
      vesselName: this.searchForm.vesselName,
      expireDate: this.searchForm.expireDate,
      productId: this.searchForm.productId,
      ms13No: this.searchForm.ms13No,
      status: this.searchForm.status,
      pagination: {
        skip:
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
          (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),
        take: !this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      }
    };

    await this.appMs24Service.getListPrint(formData).then(res => {
      if (res === 'error') {
        return;
      } else {
        var dataSource = res.data.map((x, n) => {
          return {
            ...x,
            // rowNo: formData.pagination.skip * formData.pagination.take + (n + 1),
            action: "",
            // statusCode: this.statusList.find((f) => f.statusId == x.status)
            //   ?.statusName,
            productName: x.docTypeName,
          };
        });
        this.dataSource.data = dataSource;
        this.count = res.total
      }
    });
    this.dataSource.paginator.length = this.count;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce("Sorting cleared");
    }
  }

  applyFilter(event: Event) {
    clearTimeout(this.timer); // Clear any existing timer

    this.timer = setTimeout(() => {
      // This code will be executed after a specified delay (e.g., 500 milliseconds)

      // Send the value to the backend or perform any other necessary actions
      this.searchForm.keyword = event;
      this.dataSource.paginator = this.paginator;
      this.loadDataSource();
    }, 500); // Specify the desired delay in milliseconds
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  menuClosed() { }

  async pageChanged(e: any) {
    this.searchForm.keyword =
      typeof this.searchForm.keyword !== "string"
        ? ""
        : this.searchForm.keyword;

    this.searchForm.pagination = {
      Skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
      Take: !e.pageSize ? 10 : e.pageSize,
    };

    const formData = {
      UID: this.userProfile.uId,
      refNo: this.searchForm.refNo,
      keyword: this.searchForm.keyword,
      o2No: this.searchForm.o2No,
      docNo: this.searchForm.docNo,
      petitionNo: this.searchForm.petitionNo,
      vesselName: this.searchForm.vesselName,
      expireDate: this.searchForm.expireDate,
      productId: this.searchForm.productId,
      ms13No: this.searchForm.ms13No,
      status: this.searchForm.status,
      pagination: {
        skip: (!e.pageIndex ? 0 : e.pageIndex) * (!e.pageSize ? 0 : e.pageSize),
        /*(!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex) *
        (!this.paginator?.pageIndex ? 0 : this.paginator?.pageIndex),*/
        take: !e.pageSize ? 10 : e.pageSize, //!this.paginator?.pageSize ? 10 : this.paginator?.pageSize,
      },
    };

    await this.appMs24Service.getListPrint(formData).then((res) => {
      if (res != null) {
        if (res === 'error') {
          return;
        } else {
          if (res != null) {
            let a = res.data;
            this.dataSource.data = a;
            this.count = res.total;
          } else {
            this.dataSource.data = [];
          }
        }
      }
    });

    this.dataSource.paginator.length = this.count;
    this.dataSource.paginator.pageIndex = e.pageIndex;
  }

  viewMs24Document(e) {
    this.dialog.open(Ms24ViewerComponent, {
      disableClose: true,
      data: {
        id: e.id,
      },
      width: '85vw',
      height: '85vh'
    });
  }

  reprint(e) {
    const req = {
      id: e.id,
      type: e.type,
    };
    this.appMs24Service.reprint(req).then((res) => {
      // window.open(res.linkUrl, "_blank");
      this.alertMessageService.open({
        message: "ปลดล็อกการพิมพ์เอกสารเรียบร้อย",
        title: "ขอ Reprint",
      });
      this.loadDataSource();
    });
  }

  unlockPrint(e) {
    const req = {
      id: e.id,
      type: e.type,
    };
    this.appMs24Service.reprint(req).then((res) => {
      // window.open(res.linkUrl, "_blank");
      this.alertMessageService.open({
        message: "ปลดล็อกการพิมพ์เอกสารเรียบร้อย",
        title: "ขอ Reprint",
      });
      this.loadDataSource();
    });
  }

  lockPrint(e) {
    const req = {
      id: e.id,
      type: e.type,
    };
    this.appMs13Service.lockPrint(req).then((res) => {
      // window.open(res.linkUrl, "_blank");
      this.alertMessageService.open({
        message: "ล็อกการพิมพ์เอกสารเรียบร้อย",
        title: "ล็อกการพิมพ์",
      });
      this.loadDataSource();
    });
  }

  exportMs24(e) {
    this.dialog.open(Ms24PrintUserModalComponent, {
      width: '50vw',
      disableClose: true,
    }).afterClosed().subscribe(res => {
      console.log(res);
      if (res != undefined) {
        var rqUpdatePrintUser = {
          ms24hid: e.id,
          userId: Number(res.userId)
        };

        this.appMs24Service.updateMs24PrintUser(rqUpdatePrintUser).then(async ress => {
          if (ress === 'error') {
            return;
          } else {
            var oldData = ress;
            console.log(ress);

            const formDataMs24 = {
              ms14hId: e.id,
              copyNumber: 4,
              docNo: e.o2DocNo,
              companyTax: this.userProfile.companyCode,
              refNo: e.refNo,
              productId: e.productId,
              lastPrintUserId: oldData.lastPrintUserId,
              lastPrintDate: oldData.lastPrintDate
            };

            await this.appMs24Service.printCertificateMS24(formDataMs24).then((res) => {
              window.open(res.pathPdf, "_blank");
            });
            this.searchOption();
          }
        })
      }
    })
  }

  onRefresh() {
    this.searchOption();
  }
}
