import { AppPaymentService } from "./../../../service/app/app-payment";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AuthService } from "app/core/auth/auth.service";

@Component({
  selector: "app-add-bill-company-modal",
  templateUrl: "./add-bill-company-modal.component.html",
  styleUrls: ["./add-bill-company-modal.component.css"],
})
export class AddBillCompanyModalComponent implements OnInit {
  form: any = {};
  companyList: any = [];
  userProfile: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<AddBillCompanyModalComponent>,
    public appPayment: AppPaymentService,
    private _authService: AuthService
  ) {
    this.userProfile = JSON.parse(this._authService.loginUser);
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.companyList = [
      {
        id: 15666,
        companyNameTh: "บริษัท อัลญาฟา จำกัด",
        companyNameEn: "ALYAFA CO.,LTD.",
        isActive: "Y",
        address: null,
        branch: null,
        email: "formmulti2017@gmail.com",
        taxId: "0135562008571",
        isEpayment: "N",
        createDate: "2023-06-27T20:51:20.087",
        expireDate: "2024-09-11T00:00:00",
      },
    ];
    // this.appPayment
    //   .getCompanyListForPayment({
    //     cpId: this.data.cpId,
    //     gId: this.data.gId,
    //   })
    //   .then((res) => {
    //     this.companyList = res;
    //   });
  }

  selectionCompany(e) {
    this.form = e;
  }
}
