<div style="gap: 30px;padding-top: 30px;" class="flex flex-col h-full">
  <div class="menu justify-between flex items-stretch flex-row gap-5 px-20 pr-10 pl-10">
    <div class="gap-2.5 flex flex-row">
      <div>
        <button mat-raised-button color="accent" (click)="addItem($event)" style="height: 48px">
          <div class="flex" style="gap: 5px;">
            <i class="ti ti-square-plus" style="font-size: 1.5em;"></i>
            <span class="self-center">เพิ่มรายการใหม่</span>
          </div>
        </button>
      </div>
    </div>
    <div style="flex: 1;" class="gap-2.5 flex justify-end flex-row">
      <div class="justify-evenly flex gap-2.5 items-center flex-row" style="flex: 0 50%;">
        <mat-form-field style="flex: 0 40%" class="my-form-field flex flex-row">
          <mat-select required placeholder="เลือกเฉพาะประเทศ" name="type" (selectionChange)="onCountryChange($event)">
            <mat-option *ngFor="let country of countryList" [value]="country.id">
              {{country.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="flex: 1" class="my-form-field flex flex-row">
          <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input [(ngModel)]="searchString"
            name="search" #search="ngModel" (keyup)="applyFilter($event.target.value)">
          <button matSuffix mat-icon-button aria-label="ค้นหา">
            <mat-icon>search</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
  
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
      <!-- Symbol Column -->
      <ng-container matColumnDef="dischargePortName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-left"> ชื่อเมืองตราส่ง </th>
        <td mat-cell *matCellDef="let element"> {{element.dischargePortName}} </td>
      </ng-container>

      <ng-container matColumnDef="countryName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center"> ประเทศ </th>
        <td mat-cell *matCellDef="let element"> {{element.countryName}} </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="text-center"> การจัดการ </th>
        <td mat-cell *matCellDef="let element;let i = index" class="text-center">
          <div class="flex flex-row" style="justify-content: start;">
            <div>
              <button mat-icon-button matTooltip="รายละเอียด/แก้ไข" (click)="editItem(element)" matTooltipPosition="above" style="height: 40px !important">
                <i class="ti ti-edit"></i>
              </button>
            </div>
            <div>
              <button mat-icon-button matTooltip="ลบ" (click)="delete(element)" matTooltipPosition="above" style="height: 40px !important">
                <i class="ti ti-trash"></i>
              </button>
            </div>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 50, 100]" showFirstLastButtons
      aria-label="Select page of periodic elements" (page)="pageChanged($event)">
    </mat-paginator>
  </div>
</div>