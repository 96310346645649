import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmDialogService } from "@bundle/component/confirm-dialog/confirm-dialog.service";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppGroupListService } from "app/business/service/app/app-group.service";
import { IAppGroupListResponse , IAppGroupAdjustResponse } from "app/business/interface/app/app-group.interface";


@Component({
  selector: 'app-group-adjust-management',
  templateUrl: './group-adjust-management.component.html',
  styleUrls: ['./group-adjust-management.component.css']
})
export class GroupAdjustManagementComponent implements OnInit {
  form : any = {};
  dataSource : any = {};
  dataCompany : any[] = [];
  Groupid: number;
  idCompany: number;
  Groupname: string;
  GroupAdmin: string;
  message : string;
  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    public dialogRef: MatDialogRef<GroupAdjustManagementComponent>,
    private AppGroupListService: AppGroupListService,
    private confirmService: ConfirmDialogService,
    private AlertMessage : AlertMessageService,
  ) 
  {
    this.Groupid = data.groupId
    this.idCompany = data.companyId
    this.Groupname = data.groupName
    this.GroupAdmin = data.adminGroup
  }
  ngOnInit() {
    this.showData(); 
    this.loadDataCompany();
  }
  async loadDataCompany(): Promise<void> {
    //send data to api
    const formData = {
      CompanyId: this.idCompany,
    };
     await this.AppGroupListService.CompanyDropdown(formData).then((res)=>{ 
      this.dataCompany = res
    });
  
  }
  async showData() {
    this.form.GroupCompanyId = this.idCompany;
    this.form.GroupName= this.Groupname;
    if(this.Groupid > 0){
      this.form.RadioBox = this.GroupAdmin;
    }
  }

  onReset(){
    this.form.GroupName = '';
    this.form.RadioBox = '';
    this.form.GroupCompanyId = 0;
    this.showData();
  }

   async saveData(){
    const formData = {
      Groupid :this.Groupid,
      GroupName : this.form.GroupName,
      GroupCompanyId : this.form.GroupCompanyId,
      GroupStatusAdmin : this.form.RadioBox,

    };
    await this.AppGroupListService.AdjustGroup(formData).then((res)=>{ 
      this.message = res
    });

    this.AlertMessage.info({ title: '', message: this.message  });
    if(this.message != "เคยมีชื่อกลุ่มนี้แล้ว"){     
       this.dialogRef.close("OK");
    }
  }
 
}
