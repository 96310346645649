<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <!-- <app-head-o2 style="width: 100%;"></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <!--**********Tab Menubar**********-->
    <div class="menu">
      <div class="mat-button gap-2 px-10">
        <button mat-raised-button color="accent" *ngIf="checkButton('BT076')" (click)="AdjustItemType($event)">
          <div class="flex items-center">
            <span>Add</span>
          </div>
        </button>
        <div class="flex flex-row"> </div>
        <button mat-raised-button color="warn" *ngIf="checkButton('BT077')" (click)="DeleteItemtype()">
          <div class="flex items-center">
            <span>Delete</span>
          </div>
        </button>
      </div>
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">

          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
              [(ngModel)]="searchForm.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!--**********Button search**********-->
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <!--**********Menu for button search**********-->
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex flex-row">
                <div class="basis-full">
                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>CODE</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="Code" #input
                      [(ngModel)]="searchForm.itemtypeCode" name="itemtypeCode" #itemtypeCode="ngModel">
                  </mat-form-field>

                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ประเภทสินค้า</mat-label>
                    <!-- <input autocomplete="off" type="text" matInput placeholder="ประเภทสินค้า" #input
                      [(ngModel)]="searchForm.itemtypeTypeproduct" name="itemtypeTypeproduct" #itemtypeTypeproduct="ngModel"> -->

                    <mat-select [(ngModel)]="searchForm.itemtypeTypeproduct" name="itemtypeTypeproduct"
                      #itemtypeTypeproduct="ngModel">
                      <mat-option *ngFor="let product of dataProduct" [value]="product.productId">
                        {{product.productName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ชนิด / ชั้นของสินค้า</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="ชนิด / ชั้นของสินค้า" #input
                      [(ngModel)]="searchForm.itemtypeCategory" name="itemtypeCategory" #itemtypeCategory="ngModel">
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="flex justify-end pt-2">
              <div>
                <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                  เคลียร์
                </button>
                <button type="submit" mat-raised-button color="accent" style="flex:0 50%;" (click)="loadData()">
                  ค้นหา
                </button>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <!--**********Table ietmtype Data**********-->
  <div class="mat-elevation-z8">
    <table mat-table [dataSource]="dataSource" matSort>

      <ng-container matColumnDef="select">
        <th mat-header-cell mat-sort-header *matHeaderCellDef class="text-center">
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()">
            Select
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row" class="text-center">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
            [checked]="selection.isSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>
      <!--**********1.Type product**********-->
      <ng-container matColumnDef="refGoodsDocName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;"
          sortActionDescription="Sort by name">ประเภทสินค้า</th>
        <td mat-cell *matCellDef="let element"> {{element.refGoodsDocName}} </td>
      </ng-container>
      <!--**********2.Code**********-->
      <ng-container matColumnDef="refGoodsCode">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          CODE</th>
        <td mat-cell *matCellDef="let element"> {{element.refGoodsCode}} </td>
      </ng-container>
      <!--**********3.Catagory**********-->
      <ng-container matColumnDef="refGoodsName">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          ชนิด / ชั้นของสินค้า</th>
        <td mat-cell *matCellDef="let element"> {{element.refGoodsName}} </td>
      </ng-container>
      <!--**********4.Catagory**********-->
      <!-- <ng-container matColumnDef="refGoodsStatusData">
        <th mat-header-cell mat-sort-header *matHeaderCellDef style="text-align: center;">
          สถานะการเปิดใช้งาน</th>
        <td mat-cell *matCellDef="let element"> {{element.refGoodsStatusData}} </td>
      </ng-container> -->
      <!--**********5.Action button**********-->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          <button mat-icon-button matTooltip="รายละเอียด" *ngIf="checkButton('BT078')" (click)="AdjustItemType(element)"
            matTooltipPosition="above">
            <mat-icon svgIcon="heroicons_outline:pencil-alt"></mat-icon>
          </button>
          <button mat-icon-button matTooltip="ลบ" *ngIf="checkButton('BT077')" (click)="delete(element)"
            matTooltipPosition="above">
            <mat-icon svgIcon="heroicons_outline:trash"></mat-icon>
          </button>
          <!-- <mat-slide-toggle [checked]="element.refGoodsStatusData === 'Y' ? true : false"
          (change)="onChange($event,element)">
            {{element.refGoodsStatusData}}
          </mat-slide-toggle>  -->
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20]" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>