import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";

@Component({
  selector: "app-confirm-popup-etax",
  templateUrl: "./confirm-popup-etax.component.html",
  styleUrls: ["./confirm-popup-etax.component.css"],
})
export class ConfirmPopupEtaxComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmPopupEtaxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
