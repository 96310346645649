import { LiveAnnouncer } from "@angular/cdk/a11y";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { SurveyorAuditPopupComponent } from "./surveyor-audit-popup/surveyor-audit-popup.component";
import { CreateO2ManualInquiryComponent } from "../o-two/create-o2-manual-inquiry/create-o2-manual-inquiry.component";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
} from "@angular/forms";
import { Observable } from "rxjs";
import { ApiSurveyorService } from "app/business/service/api/api-surveyor.service";
import { AppSurveyorService } from "app/business/service/app/app-surveyor.service";
import { AppMasterService } from "app/business/service/app/app-master.service";

@Component({
  selector: "app-survey-ms13-inquiry",
  templateUrl: "./surveyor-ms13-inquiry.component.html",
  styleUrls: ["./surveyor-ms13-inquiry.component.css"],
})
export class SurveyorMS13InquiryComponent implements OnInit {
  // ข้อมูล surveyor ที่จะนำไป update ใน MS13H
  MS13H_surveyor: any = {};
  result: any = {};
  auditDatasource: any = [];
  inspectBy: string;
  @Input() MS13Detail: any;
  @Input() itemDetail: any;
  @Input() ms13Header: any;
  source: any;
  typePage: any;
  form: any = {};
  productId: any;
  formId: any;
  empList: any = [];
  surveyorList: any = [];
  userProfile: any = JSON.parse(localStorage.getItem("userProfile"));
  empForm: FormGroup;

  get empSearch(): AbstractControl {
    return this.empForm.get("empSearch");
  }

  get empSearch2(): AbstractControl {
    return this.empForm.get("empSearch2");
  }

  get empSearch3(): AbstractControl {
    return this.empForm.get("empSearch3");
  }

  get surveyAnalyze(): AbstractControl {
    return this.empForm.get("surveyAnalyze");
  }

  filterOptEmp$: Observable<string[]>;
  filterOptEmp2$: Observable<string[]>;
  filterOptEmp3$: Observable<string[]>;
  filterSurveyAnalyze$: Observable<string[]>;
  @Output() formSurveyorUpdate = new EventEmitter();
  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private appSurveyorService: AppSurveyorService,
    private fb: FormBuilder,
    private appMasterService: AppMasterService
  ) {
    this.formInit();
  }

  ngOnInit() {
    this.formInit();
    const {
      source,
      docNo,
      type,
      page,
      product,
      expireDate,
      issueDate,
      status,
      id,
      productId,
      refNo,
      category,
    } = this.route.snapshot.queryParams;
    this.source = source;
    this.typePage = page;
    this.form = this.MS13Detail;
    this.form.docNo = docNo;
    this.form.product = product ?? productId;
    this.productId = product ?? productId;
    this.form.expireDate = new Date(expireDate);
    this.form.issueDate = issueDate == null ? new Date() : new Date(issueDate);
    this.form.status = status;
    this.form.id = id;
    this.form.refNo = refNo;
    this.formId = id;

    const rqSurveyorDd = {
      surveyorID: "",
      isActive: "Y",
    };
    this.appMasterService.DropdownSurveyor(rqSurveyorDd).then((res) => {
      this.surveyorList = res;
      this.form.inspectBy = this.form.inspectById;
      this.form.inspectByName = this.surveyorList.find(
        (f) => f.surveyorID == this.form.inspectBy
      ).companyNameTH;
    });

    this.form.surveyAcceptName = this.userProfile.name;
    this.form.surveyAccept = this.userProfile.uId;

    const { surveyAcceptDate, surveyOrderDate1, surveyOrderDate2 } =
      this.route.snapshot.queryParams;
    this.form.surveyAcceptDate = moment(surveyAcceptDate);
    this.form.surveyOrderDate1 = moment(surveyOrderDate1);
    this.form.surveyOrderDate2 = moment(surveyOrderDate2);
    this.loadDate();
    this.empForm.valueChanges.subscribe((newValue) => {
      this.filterOptEmp$ = this.filterEmpValues(newValue.empSearch);
    });
  }

  formInit(): void {
    this.empForm = new FormGroup({
      empSearch: new FormControl(),
      empSearch2: new FormControl(),
      empSearch3: new FormControl(),
      surveyAnalyze: new FormControl(),
    });
    this.empForm = this.fb.group({
      empSearch: [""],
      empSearch2: [""],
      empSearch3: [""],
      surveyAnalyze: [""],
    });
  }

  loadDate() {
    const req = {
      surveyorID: this.form.inspectBy,
      productId: this.form.productId,
    };
    this.appSurveyorService.getSurveyorEmp(req).then((res) => {
      this.empList = res.filter((f) => f.type == "B");
      this.filterOptEmp$ = res.filter((f) => f.type == "B");
      this.filterOptEmp2$ = res.filter((f) => f.type == "B");
      this.filterOptEmp3$ = res.filter((f) => f.type == "B");
      this.filterSurveyAnalyze$ = res.filter((f) => f.type == "A");

      if (this.ms13Header != null && this.ms13Header != undefined) {
        this.form.surveyOrder1 = this.ms13Header.surveyOrder1;
        this.form.surveyOrderDate1 = this.ms13Header.surveyOrderDate1;
        this.form.surveyOrder2 = this.ms13Header.surveyOrder2;
        this.form.surveyOrderDate2 = this.ms13Header.surveyOrderDate2;
        this.form.surveyCheif = this.ms13Header.surveyCheif;
        this.form.surveyBy2 = this.ms13Header.surveyBy2;
        this.form.surveyBy3 = this.ms13Header.surveyBy3;
        this.form.surveyAnalyze = this.ms13Header.surveyAnalyze;

        this.form.surveyCheifNo = this.empList.find(
          (f) => f.id == this.form.surveyCheif
        )?.permitNo;
        this.form.surveyByNo2 = this.empList.find(
          (f) => f.id == this.form.surveyBy2
        )?.permitNo;
        this.form.surveyByNo3 = this.empList.find(
          (f) => f.id == this.form.surveyBy3
        )?.permitNo;
        this.form.surveyAnalyzeNo = this.empList.find(
          (f) => f.id == this.form.surveyAnalyze
        )?.permitNo;

        var surveyCheifName = this.empList.find(
          (f) => f.id == this.form.surveyCheif
        )?.name;
        this.empForm.get("empSearch").setValue(surveyCheifName);

        var empSearch2 = this.empList.find(
          (f) => f.id == this.form.surveyBy2
        )?.name;
        this.empForm.get("empSearch2").setValue(empSearch2);

        var empSearch3 = this.empList.find(
          (f) => f.id == this.form.surveyBy3
        )?.name;
        this.empForm.get("empSearch3").setValue(empSearch3);

        var surveyAnalyze = this.empList.find(
          (f) => f.id == this.form.surveyAnalyze
        )?.name;
        this.empForm.get("surveyAnalyze").setValue(surveyAnalyze);

        this.result = this.ms13Header.mstMs13SurResultRespons;
        this.auditDatasource = [this.ms13Header.mstMs13SurDetailRespons];
      }
    });

    // this.appSurveyorService.getDataMs13Sur().then(res => {

    // })
  }

  filterEmpValues(search: any) {
    if (search != "") {
      return this.empList.filter(
        (value) =>
          value?.name?.toLowerCase().indexOf(search?.toLowerCase()) === 0
      );
    } else {
      return this.empList;
    }
  }

  selectionEmpChange(e): void {
    this.empForm.get("empSearch").setValue(e.option.value.name);
    this.form.surveyCheif = e.option.value.id;
    this.form.surveyCheifNo = e.option.value.permitNo;
    this.formSurveyorUpdate.emit(this.form);
  }

  selectionEmp2Change(e): void {
    this.empForm.get("empSearch2").setValue(e.option.value.name);
    this.form.surveyBy2 = e.option.value.id;
    this.form.surveyByNo2 = e.option.value.permitNo;
    this.formSurveyorUpdate.emit(this.form);
  }

  selectionEmp3Change(e): void {
    this.empForm.get("empSearch3").setValue(e.option.value.name);
    this.form.surveyBy3 = e.option.value.id;
    this.form.surveyByNo3 = e.option.value.permitNo;
    this.formSurveyorUpdate.emit(this.form);
    // this.form.survey_cheif = e.option.value.id;
  }

  selectionSurveyAnalyzeChange(e): void {
    this.empForm.get("surveyAnalyze").setValue(e.option.value.name);
    this.form.surveyAnalyze = e.option.value.id;
    this.form.surveyAnalyzeNo = e.option.value.permitNo;
    this.formSurveyorUpdate.emit(this.form);
    // this.form.survey_cheif = e.option.value.id;
  }

  addAuditResults() {
    const dialogRef = this.dialog.open(SurveyorAuditPopupComponent, {
      disableClose: false,
      width: "90vw",
      height: "90vh",
      data: { MS13Detail: this.MS13Detail, itemDetail: this.itemDetail },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.result = res.data;
        this.auditDatasource = res.table;
        this.form.auditDatasource = res.table;
        this.form.surveyorAudit = res.data;
        this.formSurveyorUpdate.emit(this.form);
      }
    });
  }
}
