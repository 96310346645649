<mat-card>
  <mat-card-header>
    <div class="flex justify-between" style="width: 100%;">
      <div class="text-lg self-center"> เพิ่ม/แก้ไข สิทธิ์ผู้ใช้งานระบบ </div>
      <div>
        <button mat-icon-button class="close-button" [mat-dialog-close]="true">
          <i class="ti ti-x text-white"></i>
        </button>
      </div>
    </div>
  </mat-card-header>
  <mat-dialog-content class="mat-typography">
    <!-- {{dataUser | json}} -->
    <app-user-role-form [dataInput]="dataUserRole" (userRoleFormEmit)="userRoleFormEmit($event)"></app-user-role-form>
  </mat-dialog-content>
  <div class="flex-row flex justify-center gap-2.5" style="margin-bottom: 20px;">
    <button mat-raised-button color="accent" (click)="userRoleFormSubmit()"> บันทึก</button>
    <button mat-raised-button class="default-button" mat-dialog-close>ปิด</button>
  </div>