import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IApiGetReceiverRequest } from 'app/business/interface/api/api-receiver.interface';
import { Observable, map, startWith } from 'rxjs';
import { country } from 'app/mock-api/apps/country/data';


@Component({
  selector: 'app-receiver-create',
  templateUrl: './receiver-create.component.html',
  styleUrls: ['./receiver-create.component.css']
})

export class ReceiverCreateComponent implements OnInit {

  action: string;
  local_data: any;
  options: string[];
  filteredOptions: Observable<string[]>;
  myControl = new FormControl('');
  country_code = country;
  dialogForm: FormGroup = this.formBuilder.group({
    name:['',Validators.required],
    addr:['',Validators.required],
    district:[''],
    subProvince:['',Validators.required],
    province:['',Validators.required],
    postcode:['',Validators.required],
    country:['',Validators.required],
  })
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ReceiverCreateComponent>,
    //@Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: IApiGetReceiverRequest
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }
  ngOnInit() {
    // options รอ Data จริงตอนนี้ใช้ mockup อยู่
    this.options = country.map(a => a.name);
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (filterValue.length > 1) {
      this.valChange(filterValue);
      return this.options.filter(option => option.toLowerCase().includes(filterValue));
    }
    else {
      return [];
    }
  }

  onSelect(value: string): void {
    var code = this.country_code.filter((option) => option.name === value).map((option) => option.code);
    this.local_data.country = code;
  }
  doAction() {
    console.log(this.local_data);
    this.dialogRef.close({ event: this.action, data: this.local_data });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  valChange(data): void {
    const res = country.some(item => item.name.toLowerCase() === data);
    if (res === true) {
      var code = this.country_code.filter((option) => option.name.toLowerCase() === data).map((option) => option.code);
      this.local_data.country = code;
    }
    else {
      this.local_data.country = [];
    }
  }
}