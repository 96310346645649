import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  IApiLoginResponse,
  IAppLoginRequest,
} from "app/business/interface/ap-user-interface";
import { BehaviorSubject } from "rxjs";
import { ApiMasterService } from "../api/api-master.service";
import { ApiUserService } from "../api/api-user.service";
import {
  IAppGetMtUserListResponse,
  IAppUserRoleResponse,
} from "app/business/interface/app/app-user.interface";
import { IAppUserGroupResponse } from "app/business/interface/api/api-user.interface";

@Injectable({
  providedIn: "root",
})
export class AppUserService {
  userList: BehaviorSubject<IAppGetMtUserListResponse[]>;
  userDdList: BehaviorSubject<IAppGetMtUserListResponse[]>;
  userProfile: BehaviorSubject<any>;
  userRoleList: BehaviorSubject<IAppUserRoleResponse[]>;
  userGroupList: BehaviorSubject<IAppUserGroupResponse[]>;
  constructor(private apiUserService: ApiUserService, private router: Router) {
    this.userList = new BehaviorSubject(new Array<IAppGetMtUserListResponse>());
    this.userDdList = new BehaviorSubject(
      new Array<IAppGetMtUserListResponse>()
    );
    this.userProfile = new BehaviorSubject(null);
    this.userRoleList = new BehaviorSubject(new Array<IAppUserRoleResponse>());
    this.userGroupList = new BehaviorSubject(
      new Array<IAppUserGroupResponse>()
    );
  }

  login(requestData: IAppLoginRequest): Promise<any> {
    return new Promise((resolve) => {
      this.apiUserService.login(requestData).subscribe((res) => {
        this.userProfile.next(res);
        // console.log('this.userProfile', res);
        // this.setSessionUserGroup(res?.userGroupCode);
        // this.setSessionUserRole(res?.userRoleCode);
        // this.setSessionUserProfile(JSON.stringify(res));
        // console.log('this.getSessionUserProfile()', JSON.parse(this.getSessionUserProfile()));
        resolve(res);
      });
    });
  }
  logout() {
    // this.apiUserService.logout().subscribe(res => {
    this.clearToken().then(() => {
      this.router.navigate(["/login"]);
    });
    // });
  }
  loadToken(): string {
    let token = "";
    const session = localStorage.getItem("token");
    if (session) {
      token = session;
    }
    return token;
  }
  clearToken() {
    return new Promise<void>((resolve) => {
      localStorage.clear();
      resolve();
    });
  }
  UserList(dataReq: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.apiUserService.UserList(dataReq).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getUserProfile(requestData: any): Promise<any> {
    return new Promise((resolve) => {
      this.apiUserService.getUserProfile(requestData).subscribe((res) => {
        //
        resolve(res);
      });
    });
  }
  getUserList(reqData: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.apiUserService.getUserList(reqData).subscribe((res) => {
        //this.saleOrderList.next(res);
        resolve(res);
      });
    });
  }
  getUserRoleList(requestData: any): Promise<any[]> {
    return new Promise((resolve) => {
      this.apiUserService.getUserRoleList(requestData).subscribe((res) => {
        //
        const result = res?.map((item, i) => {
          return {
            ...item,
            status: item.isActive === "Y" ? "ใช้งาน" : "ยกเลิก",
            index: i + 1,
          };
        });
        this.userRoleList.next(result);
        resolve(res);
      });
    });
  }
  getUserGroupList(requestData: any): Promise<any[]> {
    return new Promise((resolve) => {
      this.apiUserService.getUserGroupList(requestData).subscribe((res) => {
        const result = res?.map((item, i) => {
          return {
            ...item,
            status: item.isActive === "Y" ? "ใช้งาน" : "ยกเลิก",
            index: i + 1,
          };
        });
        this.userGroupList.next(result);
        resolve(res);
      });
    });
  }
  saveUserGroup(requestData: any): Promise<any> {
    return new Promise((resolve) => {
      this.apiUserService.saveUserGroup(requestData).subscribe((res) => {
        //
        resolve(res);
      });
    });
  }
  saveUser(requestData: any): Promise<any> {
    return new Promise((resolve) => {
      this.apiUserService.saveUser(requestData).subscribe((res) => {
        //
        resolve(res);
      });
    });
  }
  saveUserRole(requestData: any): Promise<any> {
    return new Promise((resolve) => {
      this.apiUserService.saveUserRole(requestData).subscribe((res) => {
        //
        resolve(res);
      });
    });
  }
  getUserDropdownList(reqData: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.apiUserService.getUserDropdownList(reqData).subscribe((res) => {
        this.userDdList.next(res);
        resolve(res);
      });
    });
  }
  getEmail(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getEmail(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  saveEmail(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.saveEmail(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
  getUserManage(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getUserManage(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getUserPassword(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getUserPassword(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getReasonLogBlackList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getReasonLogBlackList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  saveNewPassword(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.saveNewPassword(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getListBlackList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getListBlackList(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  updateBlackList(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.updateBlackList(request).subscribe((res) => {
        resolve(res);
      });
    });
  } 

  getDataCompany(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getDataCompany(request).subscribe((res) => {
        resolve(res);
        });
    });
  }

  getAllReasonLog(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getAllReasonLog(request).subscribe((res) => {
        resolve(res);
      });
    });
  }

  getReasonLog(request: any): any {
    return new Promise((resolve, reject) => {
      this.apiUserService.getReasonLog(request).subscribe((res) => {
        resolve(res);
      });
    });
  }
}
