import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { AlertMessageService } from "@bundle/component/alert-message/alert-message.service";
import { AppReportService } from "app/business/service/app/app-report.service";

import moment from "moment";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-report-control-ms24',
  templateUrl: './report-control-ms24.component.html',
  styleUrls: ['./report-control-ms24.component.css'],
  providers: [
    { 
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
  ],
})
export class ReportControlMs24Component implements OnInit {
  previews: string;
  _params: any = {};
  dailyReport : any = {}  ;

  myControl = new FormControl('');
  myDate = new Date();
  dailyReportForm:  FormGroup = this.formBuilder.group({
      startDate:['',Validators.required],
      endDate:['',Validators.required],
      typeList:[''],
      statusList:[]
    })
  constructor(
      private alertService: AlertMessageService,
      private formBuilder: FormBuilder,
      private appReport : AppReportService
  ){

  }

   ngOnInit(): void {
    this.dailyReport.typeList = "0";
    this.dailyReport.statusList = "0";
   }

   updateFormSearch(e): void {
    // 
    this._params = e;
  }
  async ExportExcel(){
  //console.log(this._params.value);
  this.dailyReport = this._params.value;
      await this.appReport.reportControlDataMs24(this.dailyReport).then((res) => {
          if (res !== 'error') {
              this.exportExcelData("รายงาน : ทะเบียนคุมการออกเอกสารงานมาตรฐานสินค้า_มส24 ในระบบ.xlsx", res); 
          } else {
            this.alertService.info({ title: '', message: 'ไม่สามารถ Export รายงานได้' });
          }
        });
   }

   
  exportExcelData(fileName, data) {
      var blob = new Blob([this.stringToByteArray(atob(data))]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
  }
  stringToByteArray(s) {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
  }

  clearStartDate(){
      this.dailyReport.startDate = null;
  }

  clearEndDate(){
      this.dailyReport.endDate = null;
  }
}
