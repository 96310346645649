<div class="flex-col" style="width: 100%;">
  <div class="card-pd tltle-menu">
    สร้างเอกสาร มส.24
  </div>
  <div class="flex flex-col px-20">
    <!-- MENU BAR -->
    <div class="flex flex-col" style="padding-left: 20px; padding-right: 20px; padding-bottom: 10px;">
      <mat-card class="flex flex-row" style="width: 100%; gap: 5px; padding: 5px;">
        <button mat-icon-button matTooltip="ย้อนกลับ" matTooltipPosition="above"
          style="width: 2em !important; height: 2em!important;" (click)="backPage()">
          <i class="ti ti-chevron-left"></i>
        </button>
        <!-- ปุ่มสำหรับ Surveyor คอมเม้นท์ไว้ก่อน เพราะยังไม่ได้กำหนดสิทธิ์ -->
        <button *ngIf="checkButton('BT128')" mat-icon-button matTooltip="คืนค่าเดิม" matTooltipPosition="above"
          style="width: 2em !important; height: 2em !important;" (click)="reset()">
          <i class="ti ti-restore"></i>
        </button>
        <button *ngIf="checkButton('BT129')" mat-icon-button matTooltip="บันทึกฉบับร่าง" matTooltipPosition="above"
          style="width: 2em !important; height: 2em !important;">
          <i class="ti ti-notes"></i>
        </button>
        <button *ngIf="checkButton('BT130')" mat-icon-button matTooltip="สำเนาเอกสาร มส.13" matTooltipPosition="above"
          style="width: 2em !important; height: 2em !important;" (click)="viewMs13Copy()">
          <i class="ti ti-license"></i>
        </button>
      </mat-card>
    </div>

    <div>
      <form #FormValidateItem="ngForm">
        <div style="padding: 0px 10px 0px 10px;">
          <!-- มส.24 REQUEST SUMMARY -->
          <div class="flex flex-col p10">
            <mat-card style="padding: 20px; gap: 5px;">
              <div class="flex">
                <div class="flex" style="width: 50%">
                  <div class="f18" style="align-self: center;"
                    *ngIf="form.o2Docno != undefined && form.o2Docno != null">
                    ใบอนุญาตกระทรวงพาณิชย์ที่ : พณ{{form.o2Docno}} ลงวันที่ {{form.o2IssueDate | thaidate}}
                  </div>
                </div>
                <div class="flex justify-end" style="width: 50%">
                  <ng-container *ngIf="!(checkMs24Status())">
                    <div class="f18" style="align-self: center;">
                      สถานะ : {{form.statusName}}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="checkMs24Status()">
                    <div class="f18 flex" style="gap: 6px; width: 60%">
                      <div class="f18" style="align-self: center; width: 20%;">
                        สถานะ :
                      </div>
                      <mat-form-field class="my-form-field formm w100">
                        <mat-select [(ngModel)]="formStatus.status" name="status" #status="ngModel" ngmodel
                          (selectionChange)="checkStatus($event)">
                          <mat-option *ngFor="let status of statusList" [value]="status.statusId">
                            {{status.statusName}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <button mat-icon-button matTooltip="บันทึกสถานะ" (click)="updateStatusApprove()"
                        matTooltipPosition="above" style="width: 48px !important;">
                        <i class="ti ti-edit-circle"></i>
                      </button>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="flex">
                <div class="flex" style="width: 55%">
                  <div class="flex" style="width: 25%;">
                    <div class="f18" style="align-self: center;">
                      เลขที่ประจำตัวผู้เสียภาษี :
                    </div>
                  </div>
                  <div class="flex justify-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">
                      {{form.companyTax}}
                    </div>
                  </div>
                  <div class="flex" style="width: 16%;">
                    <div class="f18" style="align-self: center;">
                      คำร้องขอเลขที่ :
                    </div>
                  </div>
                  <div class="flex justify-center" style="width: 25%;">
                    <div class="f18" style="align-self: center;">
                      {{form.ms13FullRefNo}}
                    </div>
                  </div>
                </div>
                <div class="flex justify-end" style="width: 45%">
                  <div class="f18" style="align-self: center;">
                    ประเภทสินค้า : {{form.goodsName}}
                  </div>
                </div>
              </div>
            </mat-card>
            <ng-container *ngIf="(form.status == 14) || (form.status == 3 && form.remark != undefined && form.remark != null)">
              <div class="flex">
                <div class="flex w100">
                  <div class="flex flex-row p10 w100">
                    <div class="f18" style="width: 15%; align-self: center;">สาเหตุที่ไม่อนุมัติ : </div>
                    <div style="width: 85%">
                      <span class="f18"> {{this.form.remark}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>

          <!-- ข้อมูลผู้ขอใบรับรอง -->
          <div class="flex flex-col p10">
            <mat-card>
              <mat-card-header class="fontTitleHeader">
                ข้อมูลผู้ขอใบรับรอง (Applicant Information)
              </mat-card-header>
              <mat-card-content class="example-headers-align" style="padding: 16px">
                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> ชื่อบริษัทผู้ส่งออก (ภาษาไทย) :</div>
                      <div class="basis-4/5">
                        <div class="f18" style="align-self: center;">{{form.companyThName}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;">ใบทะเบียน ผค. :</div>
                      <div class="basis-4/5">
                        <div class="f18" style="align-self: center;">{{form.registrationNoPk}}</div>
                      </div>
                      <div class="basis-1/2 f18" style="align-self: center;"></div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <!-- ข้อมูลสินค้า -->
          <div class="flex flex-col p10">
            <mat-card>
              <mat-card-header class="fontTitleHeader">
                ข้อมูลสินค้า (Product Information)
              </mat-card-header>
              <mat-card-content class="example-headers-align" style="padding: 16px">
                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> ชื่อสินค้า :</div>
                      <div class="basis-4/5">
                        <div class="f18" style="align-self: center;">{{itemProduct.goodsDescription}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;">ประเภทสินค้า :</div>
                      <div class="basis-4/5">
                        <div class="f18" style="align-self: center;">{{form.typeGoods}}</div>
                      </div>
                      <div class="basis-1/2 f18" style="align-self: center;"></div>
                    </div>
                  </div>
                </div>

                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> ชนิด/ชั้น :</div>
                      <div class="basis-4/5">
                        <div class="f18" style="align-self: center;">{{form.typeClass}}</div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;">ตราหรือเครื่องหมายส่งออก :</div>
                      <div class="basis-4/5">
                        <div class="f18" style="align-self: center;">{{form.shippingMark ?? 'ไม่ระบุตรา'}}</div>
                      </div>
                      <div class="basis-1/2 f18" style="align-self: center;"></div>
                    </div>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <!-- ข้อมูลรายการสินค้า -->
          <div class="flex flex-col p10">
            <mat-card>
              <mat-card-header class="fontTitleHeader">
                ข้อมูลรายการสินค้า (Product Item Information)
              </mat-card-header>
              <mat-card-content class="example-headers-align" style="padding: 16px">
                <div class="mat-elevation-z8" style="width: 100%">
                  <dx-data-grid id="gridContainer" [dataSource]="dataSource" [columnAutoWidth]="true"
                    [showBorders]="true" [allowColumnReordering]="true" (onSelectionChanged)="selectionChanged($event)"
                    [showBorders]="true" [noDataText]="'ไม่มีข้อมูล'">
                    <dxo-selection *ngIf="(typePage == 'ms13' && disabled) && (form.status === 5)"
                      [selectAllMode]="true" mode="single"></dxo-selection>
                    <dxi-column caption="รายการที่" alignment="center" cellTemplate="wFlow" [width]='100'>
                    </dxi-column>
                    <div *dxTemplate="let row of 'wFlow'">
                      {{row.rowIndex+1}}
                    </div>
                    <dxi-column alignment="center" caption="Invoice" cellTemplate="invoice"> </dxi-column>
                    <div class="flex flex-col" *dxTemplate="let row of 'invoice'">
                      <div class="flex" style="gap: 6px;">
                        <div>No. : </div>
                        <div>{{row.data.invoiceNo}}</div>
                      </div>
                      <div class="flex" style="gap: 6px;">
                        <div>วันที่ : </div>
                        <div>{{row.data.invoiceDate | thaidate}}</div>
                      </div>
                      <div class="flex" style="gap: 6px;">
                        <div>ลำดับรายการสินค้า : </div>
                        <div>{{row.data.invoiceItem}}</div>
                      </div>
                    </div>
                    <dxi-column alignment="center" dataField="goodsDescription" [width]="450" caption="รายการสินค้า">
                    </dxi-column>
                    <dxi-column alignment="center" caption="ปริมาณ" cellTemplate="quantity"> </dxi-column>
                    <div *dxTemplate="let row of 'quantity'">
                      {{ (row.data.quantity != null ? row.data.quantity : 0) | number:'1.0-0'}}
                      {{row.data.quantityUnitName}}
                    </div>
                    <dxi-column alignment="center" caption="น้ำหนัก" cellTemplate="netWeight"> </dxi-column>
                    <div *dxTemplate="let row of 'netWeight'">
                      {{ (row.data.netWeight != null ? row.data.netWeight : 0) | number:'1.0-6'}}
                      {{row.data.netWeightUnit}}
                    </div>
                    <dxi-column alignment="center" caption="มูลค่า (บาท)" cellTemplate="valueBaht"></dxi-column>
                    <div *dxTemplate="let row of 'valueBaht'">
                      {{ (row.data.fobValueBaht != null ? row.data.fobValueBaht : 0) | number:'1.2-6'}}
                    </div>
                    <dxi-column alignment="center" caption="มูลค่า (USD)" cellTemplate="fobUsd"> </dxi-column>
                    <div *dxTemplate="let row of 'fobUsd'">
                      {{ (row.data.fobValueUsd != null ? row.data.fobValueUsd : 0) | number:'1.2-6'}}
                    </div>
                    <dxi-column alignment="center" caption="ราคาต่อจำนวน" cellTemplate="unitPrice">
                    </dxi-column>
                    <div *dxTemplate="let row of 'unitPrice'">
                      {{ (row.data.unitPrice != null ? row.data.unitPrice : 0) | number:'1.2-6'}} {{row.data.currency}}
                    </div>
                    <dxi-column alignment="center" caption="น้ำหนักเฉพาะต่อหน่วย" cellTemplate="grossWeight">
                    </dxi-column>
                    <div *dxTemplate="let row of 'grossWeight'">
                      {{ (row.data.surveyNetWeightPerUnit != null ? row.data.surveyNetWeightPerUnit : 0) |
                      number:'1.2-6'}}
                      {{row.data.netWeightUnit}}
                    </div>
                    <dxi-column alignment="center" caption="น้ำหนักรวม" cellTemplate="grossWeightPerUnit">
                    </dxi-column>
                    <div *dxTemplate="let row of 'grossWeightPerUnit'">
                      {{ (row.data.surveyNetWeight != null ? row.data.surveyNetWeight : 0) | number:'1.2-6'}}
                      {{row.data.netWeightUnit}}
                    </div>
                    <!-- <dxi-column alignment="center" caption="ราคาต่อจำนวน" cellTemplate="pricePerUnit2">
                    </dxi-column>
                    <div *dxTemplate="let row of 'pricePerUnit2'">
                      {{row.data.netWeight}} {{row.data.currency}}
                    </div> -->

                  </dx-data-grid>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <!-- ข้อมูลการส่งออก -->
          <div class="flex flex-col p10">
            <mat-card>
              <mat-card-header class="fontTitleHeader">
                ข้อมูลการส่งออก (Export Information)
              </mat-card-header>
              <mat-card-content class="example-headers-align" style="padding: 16px">
                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> เรือใหญ่ที่จะบรรทุก :</div>
                      <div class="basis-4/5">
                        <mat-form-field class="w100">
                          <input required autocomplete="off" [disabled]="disabled" matInput
                            placeholder="เรือใหญ่ที่จะบรรทุก" [(ngModel)]="form.accualVesselName"
                            name="accualVesselName" #accualVesselName="ngModel">
                          <mat-error>กรุณาระบุ</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                    <ng-container *ngIf="!(checkCompleteForm())">
                      <div class="flex flex-row p10">
                        <div class="basis-1/2 f18" style="align-self: center;"> วันที่จะบรรทุก :</div>
                        <div class="basis-4/5">
                          <mat-form-field class="w100" (click)="goodsLoadDate.open()">
                            <input autocomplete="off" placeholder="วันที่จะบรรทุก" matInput
                              [matDatepicker]="goodsLoadDate" [(ngModel)]="form.goodsLoadDate" name="goodsLoadDate"
                              #portLoadDate="ngModel" readonly [disabled]="disabled">
                            <mat-datepicker-toggle matSuffix [for]="goodsLoadDate"></mat-datepicker-toggle>
                            <mat-datepicker #goodsLoadDate></mat-datepicker>
                            <mat-error>กรุณาระบุ</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="checkCompleteForm()">
                      <div class="flex flex-row p10">
                        <div class="basis-1/2 f18" style="align-self: center;"> วันที่บรรทุก : </div>
                        <div class="basis-4/5">
                          <div class="flex flex-row" style="gap: 5px;">
                            <div class="basis-1/2 f18">
                              <mat-form-field class="w100" (click)="goodsLoadDates1.open()">
                                <input autocomplete="off" placeholder="เริ่ม" matInput [matDatepicker]="goodsLoadDates1"
                                  [(ngModel)]="form.goodsLoadDate" [max]="form.goodsLoadDateEnd" name="goodsLoadDate"
                                  #goodsLoadDate="ngModel" (dateChange)="onGoodsLoadDateChange()" readonly>
                                <mat-datepicker-toggle matSuffix [for]="goodsLoadDates1"></mat-datepicker-toggle>
                                <mat-datepicker #goodsLoadDates1></mat-datepicker>
                                <mat-error>กรุณาระบุ</mat-error>
                              </mat-form-field>
                            </div>
                            <div class="basis-1/2 f18">
                              <mat-form-field class="w100" (click)="goodsLoadDates2.open()">
                                <input autocomplete="off" placeholder="สิ้นสุด" matInput
                                  [matDatepicker]="goodsLoadDates2" [(ngModel)]="form.goodsLoadDateEnd"
                                  [min]="form.goodsLoadDate" name="goodsLoadDateEnd" #goodsLoadDateEnd="ngModel"
                                  readonly>
                                <mat-datepicker-toggle matSuffix [for]="goodsLoadDates2"></mat-datepicker-toggle>
                                <mat-datepicker #goodsLoadDates2></mat-datepicker>
                                <mat-error>กรุณาระบุ</mat-error>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> ท่าหรือสถานที่ส่งออก :</div>
                      <div class="basis-4/5">
                        <mat-form-field class="w100">
                          <mat-select (selectionChange)="setPort($event)" [disabled]="disabled" [(ngModel)]="form.port"
                            name="port" #port="ngModel" placeholder="ท่าหรือสถานที่ส่งออก" ngmodel>
                            <mat-option *ngFor="let port of portList" [value]="port.id">
                              {{port.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> ประเทศ :</div>
                      <div class="basis-4/5">
                        <mat-form-field class="w100">
                          <input type="text" matInput [formControl]="myControl" [matAutocomplete]="autosx"
                            placeholder="ประเทศ" [readonly]="destCountrySelect">
                          <mat-autocomplete #autosx="matAutocomplete"
                            (optionSelected)="selectionLoadedPortCountryChange($event)" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                              {{option.code}} : {{option.name}}
                            </mat-option>
                          </mat-autocomplete>
                          <button mat-icon-button matTooltip="ลบ" (click)="cancelCountryItem()"
                            style="width: 48px !important; margin-right: -10px" *ngIf="destCountrySelect">
                            <i class="ti ti-backspace" style="color: #8e9db1;"></i>
                          </button>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> ส่งออกโดยทาง :</div>
                      <div class="basis-4/5">
                        <mat-form-field class="w100">
                          <mat-select [(ngModel)]="form.transportationMode" [disabled]="disabled"
                            name="transportationMode" #transportationMode="ngModel" ngmodel placeholder="ส่งออกโดยทาง">
                            <mat-option *ngFor="let tran of transModeList" [value]="tran.id">
                              {{tran.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> เมืองตราส่ง :</div>
                      <div class="basis-4/5">
                        <mat-form-field class="w100">
                          <input type="text" required matInput [formControl]="dischargePortSearch"
                          [matAutocomplete]="autodis" [readonly]="dischargeSelect">
                          <mat-autocomplete #autodis="matAutocomplete" [displayWith]="displayDischargePort"
                            (optionSelected)="selectionDischargePortChange($event)">
                            <mat-option *ngFor="let option of filteredDischargePortOptions | async"
                              [value]="option">
                              {{option.dischargePortName}}
                            </mat-option>
                          </mat-autocomplete>
                          <button mat-icon-button matTooltip="ลบ" (click)="cancelDischargePortItem()"
                            style="width: 48px !important; margin-right: -10px" *ngIf="dischargeSelect">
                            <i class="ti ti-backspace" style="color: #8e9db1;"></i>
                          </button>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex">
                  <div class="flex-1 w-50">
                    <ng-container *ngIf="!(checkCompleteForm())">
                      <div class="flex flex-row p10">
                        <div class="basis-1/2 f18" style="align-self: center;"> ชื่อพาหนะ / เที่ยว :</div>
                        <div class="basis-4/5">
                          <mat-form-field class="w100">
                            <input required autocomplete="off" [disabled]="disabled" matInput
                              placeholder="ชื่อพาหนะ / เที่ยว" [(ngModel)]="form.vesselName" name="vesselName"
                              #vesselName="ngModel">
                            <mat-error>กรุณาระบุ</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="checkCompleteForm()">
                      <div class="flex flex-row p10">
                        <div class="basis-1/2 f18" style="align-self: center;"> ชื่อพาหนะ / เที่ยว :</div>
                        <div class="basis-4/5">
                          <mat-form-field class="w100">
                            <input required autocomplete="off" matInput
                              placeholder="ชื่อพาหนะ / เที่ยว" [(ngModel)]="form.vesselName" name="vesselName"
                              #vesselName="ngModel">
                            <mat-error>กรุณาระบุ</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="flex-1 w-50">

                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <!-- ข้อมูลการตรวจสอบ -->
          <div class="flex flex-col p10">
            <mat-card>
              <mat-card-header class="fontTitleHeader">
                ข้อมูลการตรวจสอบ (Inspection Information)
              </mat-card-header>
              <mat-card-content class="example-headers-align" style="padding: 16px">
                <div class="flex">
                  <div class="flex" style="width: 75%">
                    <div class="flex flex-row p10 w100">
                      <div class="f18" style="align-self: center; width: 25%"> มาตรฐาน :</div>
                      <div style="width:75%">
                        <mat-form-field class="w100">
                          <input required autocomplete="off" [disabled]="disabled" matInput placeholder="มาตรฐาน"
                            [(ngModel)]="form.checkResult" name="checkResult" #checkResult="ngModel">
                          <mat-error>กรุณาระบุ</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1" style="width: 25%">

                  </div>
                </div>

                <div class="flex">
                  <div class="flex" style="width: 75%">
                    <div class="flex flex-row p10 w100">
                      <div class="f18" style="align-self: center; width: 25%"> การบรรจุหุ้มห่อ :</div>
                      <div style="width:75%">
                        <mat-form-field class="w100">
                          <input required autocomplete="off" [disabled]="disabled" matInput
                            placeholder="การบรรจุหุ้มห่อ" [(ngModel)]="form.checkPackageResult"
                            name="checkPackageResult" #checkPackageResult="ngModel">
                          <mat-error>กรุณาระบุ</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1" style="width: 25%">

                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <!-- แนบผลวิเคราะห์ -->
          <div class="flex flex-col p10" style="padding-bottom: 20px;">
            <mat-card>
              <mat-card-header class="fontTitleHeader">
                แนบผลวิเคราะห์ (Analysis Attachment)
              </mat-card-header>
              <mat-card-content class="example-headers-align" style="padding: 16px;">
                <div class="flex" *ngIf="!disabled">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> ชื่อเอกสาร : <span
                          style="color: red;">*</span></div>
                      <div class="basis-4/5">
                        <mat-form-field class="w100">
                          <input autocomplete="off" [disabled]="disabled" type="text" matInput placeholder="ชื่อเอกสาร"
                            #input [(ngModel)]="formAttachment.fileName" name="fileName" #fileName="ngModel">
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50"></div>
                </div>
                <div class="flex" *ngIf="!disabled">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> เลือกเอกสารที่ต้องใช้แนบ : </div>
                      <div class="basis-4/5">
                        <div class="file-uploader-block">
                          <dx-file-uploader [allowCanceling]="true" [(value)]="value"
                            (onValueChanged)="onFileChangedBr($event)" [multiple]="true" [uploadMode]="'instantly'"
                            accept="image/gif, image/jpeg, image/png ,image/jpg"
                            [allowedFileExtensions]="['.jpg', '.jpeg', '.gif', '.png']">
                          </dx-file-uploader>
                          <span class="note">Allowed file extensions: <span>.jpg, .jpeg, .gif, .png</span>.</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50">
                    <!-- <img style="width: 150px; height: 150px;" src="{{fileImg}}"> -->
                  </div>
                </div>
                <div class="flex">
                  <div class="mat-elevation-z8" style="width: 100%">
                    <dx-data-grid id="gridContainer" [dataSource]="dataSourceAtt" [columnAutoWidth]="true"
                      [showBorders]="true" [allowColumnReordering]="true" [showBorders]="true"
                      [noDataText]="'ยังไม่ได้แนบเอกสารผลวิเคราะห์'">
                      <dxi-column alignment="center" dataField="cmms24aFileName" caption="ชื่อเอกสาร">
                      </dxi-column>
                      <dxi-column alignment="center" cellTemplate="documentSize" caption="ขนาดของเอกสาร">
                      </dxi-column>
                      <div *dxTemplate="let row of 'documentSize'">
                        {{row?.data.cmms24aDocumentSize}} กิโลไบต์ (kB)
                      </div>
                      <dxi-column alignment="center" dataField="cmms24aFileExtension" caption="ชนิดเอกสาร">
                      </dxi-column>
                      <dxi-column alignment="center" cellTemplate="action" caption="Action">
                      </dxi-column>
                      <div *dxTemplate="let row of 'action'">
                        <button mat-icon-button (click)="showImg(row?.data)" matTooltipPosition="above"
                          matTooltip="ดูรายละเอียด" color="accent" style="height: 40px !important">
                          <i class="ti ti-photo-search"></i>
                        </button>
                        <button (click)="openTab(row?.data.cmms24aPathFile)" mat-icon-button matTooltipPosition="above"
                          matTooltip="เปิดเอกสาร" color="accent" style="height: 40px !important">
                          <i class="ti ti-file-search"></i>
                        </button>
                        <button mat-icon-button (click)="deleteImg(row?.data)" matTooltipPosition="above"
                          matTooltip="ลบเอกสาร" color="accent" style="height: 40px !important" *ngIf="!disabled">
                          <i class="ti ti-trash"></i>
                        </button>
                      </div>
                    </dx-data-grid>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <!-- หมายเหตุสำหรับพนักงาน -->
          <div class="flex flex-col" style="padding-bottom: 20px; padding-left: 10px; padding-right: 10px;"
            *ngIf="checkResultApproval()">
            <mat-card>
              <mat-card-header class="fontTitleHeader">
                {{ !(this.userProfile.gId === 'UG004') ? 'ผลการอนุมัติ' : 'หมายเหตุสำหรับพนักงาน'}}
              </mat-card-header>
              <mat-card-content class="example-headers-align" style="padding: 16px">
                <div class="flex" *ngIf="form.status != 14">
                  <div class="flex-1 w-50">
                    <div class="flex flex-row p10">
                      <div class="basis-1/2 f18" style="align-self: center;"> วันที่เอกสารหมดอายุ : <span
                          style="color: red;">*</span></div>
                      <div class="basis-4/5">
                        <mat-form-field class="w100" (click)="expireDate.open()">
                          <input autocomplete="off" placeholder="วันที่เอกสารหมดอายุ" matInput
                            [matDatepicker]="expireDate" [(ngModel)]="form.expireDate" name="expireDate"
                            #portLoadDate="ngModel" readonly [disabled]="checkFormStaff()">
                          <mat-datepicker-toggle matSuffix [for]="expireDate"></mat-datepicker-toggle>
                          <mat-datepicker #expireDate></mat-datepicker>
                          <mat-error>กรุณาระบุ</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                  <div class="flex-1 w-50"></div>
                </div>
                <div class="flex">
                  <div class="flex" style="width: 19.4%;">
                    <div class="flex flex-row p10">
                      <div class="f18" style="align-self: center;"> {{ !(this.userProfile.gId === 'UG004') ?
                        'สาเหตุที่ไม่อนุมัติ' : 'หมายเหตุสำหรับพนักงาน'}} : </div>
                    </div>
                  </div>
                  <div class="flex flex-col" style="width: 80.6%; gap: 5px;">
                    <mat-form-field class="w100">
                      <textarea matInput placeholder="หมายเหตุสำหรับพนักงาน" [disabled]="checkFormStaff()"
                        [(ngModel)]="form.remark" name="remark" #fileName="ngModel"></textarea>
                    </mat-form-field>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>

          <!-- Action -->
          <div class="flex flex-col" style="padding-bottom: 20px; padding-left: 10px; padding-right: 10px;"
            *ngIf="checkExporterButtonDiv()">
            <mat-card>
              <mat-card-content class="flex justify-center example-headers-align" style="gap: 20px;">
                <button class="p10" *ngIf="checkButton('BT129') && form.status == '1'" mat-raised-button
                  (click)="save(1)">
                  <!-- <button class="p10" style="margin-right: 20px;" mat-raised-button (click)="save(1)">-->
                  บันทึกฉบับร่าง
                </button>
                <!-- For surveyor Tum [disabled]="!FormValidateItem.valid" -->
                <button class="p10" *ngIf="checkButton('BT131') && (form.status == '1')" mat-raised-button
                  (click)="save(2)">
                  <!-- <button class="p10" style="margin-right: 20px;" mat-raised-button (click)="save(2)"> -->
                  ยื่นคำร้องขอ มส.24
                </button>
                <button class="p10" *ngIf="checkButton('BT131') && (form.status == '14')" mat-raised-button
                  (click)="save(2)">
                  <!-- <button class="p10" style="margin-right: 20px;" mat-raised-button (click)="save(2)"> -->
                  ยื่นแก้ไขคำร้องขอ มส.24
                </button>
                <button class="p10"
                  *ngIf="checkButton('BT132') && (form.status == '1' || form.status == '2' || form.status == '13')"
                  mat-raised-button (click)="save(3)">
                  <!-- <button class="p10" style="margin-right: 20px;" mat-raised-button (click)="save(3)"> -->
                  บันทึก
                </button>
              </mat-card-content>
            </mat-card>
          </div>
          <div class="flex flex-col p10" style="padding-bottom: 20px; padding-left: 10px; padding-right: 10px;"
            *ngIf="checkButtonDiv()">
            <mat-card>
              <mat-card-content class="flex justify-center example-headers-align" style="gap: 20px;">
                <button class="p10"
                  *ngIf="(form.status == '9')"
                  mat-raised-button (click)="saveLoadDate()">
                  <!-- <button class="p10" style="margin-right: 20px;" *ngIf="form.status == 2" mat-raised-button (click)="save(7)"> -->
                  บันทึกแก้ไข
                </button>
                <button class="p10"
                  *ngIf="checkButton('BT133') && (form.status == '1' || form.status == '2' || form.status == '3' || form.status == '13')"
                  mat-raised-button (click)="save(5)">
                  <!-- <button class="p10" style="margin-right: 20px;" *ngIf="form.status == 2" mat-raised-button (click)="save(7)"> -->
                  อนุมัติ มส.24
                </button>
                <button class="p10" *ngIf="checkButton('BT134') && (form.status == '1' || form.status == '2')"
                  mat-raised-button (click)="save(14)">
                  <!-- <button class="p10" style="margin-right: 20px;" *ngIf="form.status == 2" mat-raised-button (click)="save(14)"> -->
                  ไม่อนุมัติ
                </button>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>