import { takeUntil } from 'rxjs/operators';
// import { Subject } from 'rxjs';
import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { AlertMessageService } from '@bundle/component/alert-message/alert-message.service';
import { IGetUserProfileResponse, IMenuDataModel, IMenuResponse, ISetPermissionButtonRequest, ISetPermissionMenuRequest } from 'app/business/interface/api/api-menu.interface';
import { AppMenuService } from 'app/business/service/app/app-menu.service';
import { DxTreeViewComponent } from 'devextreme-angular';
import _ from 'underscore';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-button-permission',
  templateUrl: './button-permission.component.html',
  styleUrls: ['./button-permission.component.scss']
})
export class ButtonPermissionComponent implements OnInit, OnDestroy {
  // Tree View prop
  showCheckBoxesMode = 'selectAll';
  selectionMode = 'multiple';
  selectNodesRecursive = true;
  selectByClick = true;
  unsubscribe$: Subject<any>;

  // component prop
  form: any;
  actionItem: any;
  actionButtonTo: any;
  displayFlex: string ;
  selectedNodes: any[];
  dataButtonUserRoleList: any[];
  dataButtonUserGroupList: any[];
  dataButtonUserList: any[];

  @ViewChild('buttonTreeView1', { static: false }) buttonTreeView1: DxTreeViewComponent;
  @ViewChild('buttonTreeView2', { static: false }) buttonTreeView2: DxTreeViewComponent;
  @ViewChild('buttonTreeView3', { static: false }) buttonTreeView3: DxTreeViewComponent;
  @Input() dataInput: any;

  // service prop
  buttonList: IMenuResponse[];
  userButtonList: IMenuDataModel[];
  userRoleButtonList: IMenuDataModel[];
  userGroupButtonList: IMenuDataModel[];
  userProfile: IGetUserProfileResponse;
  dataForSavePermission: ISetPermissionButtonRequest;
  constructor(
    private appMenuService: AppMenuService,
    private alertMessageService: AlertMessageService
  ) {
    this.form = this.dataInput;
    this.buttonList = new Array<IMenuResponse>();
    this.userButtonList = new Array<IMenuDataModel>();
    this.userRoleButtonList = new Array<IMenuDataModel>();
    this.userGroupButtonList = new Array<IMenuDataModel>();
    this.selectedNodes = new Array();
    this.userProfile = null;
    this.dataForSavePermission = {
      userCode: '',
      userGroupCode: '',
      userRoleCode: '',
      setPermissionType: 'B',
      menuList: []
    } as ISetPermissionButtonRequest;

    this.dataButtonUserRoleList = new Array();
    this.dataButtonUserGroupList = new Array();
    this.dataButtonUserList = new Array();
  }



  ngOnInit(): void {
    this.unsubscribe$ = new Subject();
    this.setSubcribe();
  }
  
  ngOnDestroy(): void {
    // this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getButtonAll(): void {
    this.appMenuService.getButtonAll({
      menuGroupCode: 'B',
      userCode: this.actionItem?.value === 'U' ?  this.actionButtonTo?.user.userId : '',
      userGroupCode: this.actionItem?.value === 'U' ? this.actionButtonTo?.user.userGroupCode : this.actionItem?.value === 'G'
        ? this.actionButtonTo?.value : '',
      userRoleCode: this.actionItem?.value === 'U' ? this.actionButtonTo?.user.userRoleCode : this.actionItem?.value === 'R'
        ? this.actionButtonTo?.value : '',
    });
  }
  resetButtonSubmit(): void {
    this.dataForSavePermission = {
      userCode: '',
      userGroupCode: '',
      userRoleCode: '',
      setPermissionType: 'B',
      menuList: []
    } as ISetPermissionMenuRequest;
    this.getButtonAll();
  }

  setSubcribe(): void {
    this.appMenuService.actionButtonPermission
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(res => {
      this.actionItem = res;
      this.displayFlex = this.actionItem?.value === 'U' ? 'space-around start' : 'start start';
    });
    this.appMenuService.actionButtonTo.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
      this.actionButtonTo = res;
    });
    this.appMenuService.buttonList.pipe(takeUntil(this.unsubscribe$)).subscribe(res => {

      this.buttonList = res;
      this.appMenuService.userButtonList.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
        this.userButtonList = result;
        const menuCodeList = _.pluck(this.userButtonList, 'menuCode');
        this.dataButtonUserList = this.dataMenuInit(this.buttonList, this.userButtonList);
      });
      this.appMenuService.userRoleButtonList.subscribe(result => {
        this.userRoleButtonList = result;
        const menuCodeList = _.pluck(this.userRoleButtonList, 'menuCode');
        this.dataButtonUserRoleList = this.dataMenuInit(this.buttonList, this.userRoleButtonList);
      });
      this.appMenuService.userGroupButtonList.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
        this.userGroupButtonList = result;
        const menuCodeList = _.pluck(this.userGroupButtonList, 'menuCode');
        this.dataButtonUserGroupList = this.dataMenuInit(this.buttonList, this.userGroupButtonList);
      });
      this.appMenuService.userProfile.pipe(takeUntil(this.unsubscribe$)).subscribe(result => this.userProfile = result);
    });

  }
  saveButtonPermissionSubmit(): void {
    // this.getSelectedNodes(this.treeView1);
    let menuCodeList = [];
    switch (this.actionItem.value) {
      case 'R': {
        menuCodeList = this.getSelectedNodes('treeView2');
        this.dataForSavePermission.userRoleCode = this.actionButtonTo?.value;
        this.dataForSavePermission.userGroupCode = '';
        this.dataForSavePermission.userCode = '';
        break;
      }
      case 'G': {
        menuCodeList = this.getSelectedNodes('treeView3');
        this.dataForSavePermission.userGroupCode = this.actionButtonTo?.value;
        this.dataForSavePermission.userRoleCode = '';
        this.dataForSavePermission.userCode = '';
        break;
      }
      case 'U': {
        menuCodeList = this.getSelectedNodes('treeView1');
        // this.dataForSavePermission.userCode = this.actionButtonTo?.value;
        this.dataForSavePermission.userCode = this.actionButtonTo?.user.userId
        this.dataForSavePermission.userGroupCode = '';
        this.dataForSavePermission.userRoleCode = '';
        break;
      }
    }
    this.dataForSavePermission.menuList = _.uniq(menuCodeList);
    this.appMenuService.savePermissionMenuOrButton(this.dataForSavePermission).then(res => {
      if (res !== 'error') {
        this.alertMessageService.info({ title: 'การบันทึก', message: 'บันทึกข้อมูลเรียบร้อยแล้ว' });
        this.getButtonAll();
      }
    });

  }
  getSelectedNodes(treeviewTo: string): any[] {

    this.selectedNodes = treeviewTo === 'treeView1' ? this.buttonTreeView1.instance.getSelectedNodes()
      : treeviewTo === 'treeView2' ? this.buttonTreeView2.instance.getSelectedNodes() : this.buttonTreeView3.instance.getSelectedNodes();
    let menuCodeList = [];
    this.selectedNodes.map(e => {
      menuCodeList.push(e.key);
    });
    menuCodeList = _.uniq(menuCodeList);
    return menuCodeList;
  }

  dataMenuInit(datas: IMenuResponse[], menuList: IMenuDataModel[]): any[] {
    const menuHasChildList = _.pluck(datas.filter(r => r.children.length > 0), 'menuCode');
    const useMenuNotRoot = menuList?.filter(r => {
      if (!_.contains(menuHasChildList, r.menuCode)) {
        return r;
      }
    });
    const menuCodeList = _.pluck(useMenuNotRoot, 'menuCode');
    const result = datas.map((e) => {
      return {
        ...e,
        expanded: true,
        children: this.dataMenuInit(e.children, menuList),
        selected: _.contains(menuCodeList, e.menuCode)
      };
    });
    return result;
  }

  selectItem(e): void {
  }
  treeViewSelectionChanged(e: any): void {
    // this.syncSelection(e.component);

  }

  treeViewContentReady(e: any): void {
    // this.syncSelection(e.component);

  }

}
