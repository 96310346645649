<div style="display: flex;flex-flow: column;height: 100%;gap: 30px;" class="flex flex-col h-full">
  <!-- <app-head-o2 style="width: 100%;"></app-head-o2> -->
  <div class="flex flex-col pl-10 pr-10 pt-10">
    <!--**********Tab Menubar**********-->
    <div class="menu">
      <div style="display: flex;flex-flow: row;gap: 10px;flex: 1;justify-content: flex-end;">
        <div class="mat-button" style="gap: 10px;flex: 0 40%;">

          <mat-form-field style="display: flex;flex-flow: row;flex: 1" class="my-form-field">
            <input autocomplete="off" type="text" matInput placeholder="คำค้นหา" matInput #input
              [(ngModel)]="searchForm.search" name="search" #search="ngModel" (keyup)="applyFilter($event)">
            <button matSuffix mat-icon-button aria-label="ค้นหา">
              <mat-icon>search</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!--**********Button search**********-->
        <button mat-raised-button color="accent" [matMenuTriggerFor]="myMenu">
          <div class="flex items-center">
            <span>เงื่อนไขการค้นหา</span>
            <mat-icon>expand_more</mat-icon>
          </div>
        </button>
        <!--**********Menu for button search**********-->
        <mat-menu #myMenu="matMenu" xPosition="before" class="my-menu">
          <div class="pl-2 pr-2" (click)="stopPropagation($event)">
            <div class="pl-2 pr-2">
              <div class="flex flex-row">
                <div class="basis-full">
                  <div class="flex">
                    <mat-form-field class="my-form-field formm w100" appearance="fill">
                      <mat-label>วันที่รับข้อมูล</mat-label>
                      <input matInput [matDatepicker]="picker1" [(ngModel)]="searchForm.createDate">
                      <mat-hint>YYYY/MM/DD</mat-hint>
                      <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1 dateFormat="YYYY/MM/DD"></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>ใบทะเบียนเลขที่ ผค.</mat-label>
                      <input autocomplete="off" type="text" matInput placeholder="ใบทะเบียนเลขที่ ผค." #input
                        [(ngModel)]="searchForm.exporterNo" name="exporterNo" #exporterNo="ngModel">
                    </mat-form-field>
                  </div>

                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>ชื่อบริษัทผู้ส่งออก</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="ชื่อบริษัทผู้ส่งออก" #input
                      [(ngModel)]="searchForm.companyName" name="companyName"
                      #companyName="ngModel">
                  </mat-form-field>

                  <mat-form-field class="my-form-field formm w100">
                    <mat-label>เลขประจำตัวผู้เสียภาษี</mat-label>
                    <input autocomplete="off" type="text" matInput placeholder="เลขประจำตัวผู้เสียภาษี" #input
                      [(ngModel)]="searchForm.taxNo" name="taxNo" #taxNo="ngModel">
                  </mat-form-field>

                  <div class="flex">
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>สถานะผู้ส่งออก</mat-label>
                      <mat-select [(ngModel)]="searchForm.status">
                        <mat-option value="">ทั้งหมด</mat-option>
                        <mat-option value="N">รายใหม่</mat-option>
                        <mat-option value="O">รายเก่า</mat-option>
                        <mat-option value="C">ยกเลิก</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-form-field class="my-form-field formm w100">
                      <mat-label>สถานะการอัปเดตข้อมูล</mat-label>
                      <mat-select [(ngModel)]="searchForm.statusUpdate">
                        <mat-option value="">ทั้งหมด</mat-option>
                        <mat-option value="Y">ใช้งาน</mat-option>
                        <mat-option value="N">ไม่ใช้งาน</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end formm">
              <div class="flex flex-row">
                <button mat-raised-button (click)="clearOption()" style="flex:0 50%; margin-right: 10px;">
                  เคลียร์
                </button>
                <button type="submit" mat-raised-button color="accent" style="flex:0 50%;" (click)="loadData()">
                  ค้นหา
                </button>
              </div>
            </div>
          </div>
        </mat-menu>
      </div>
    </div>
  </div>
  <!--**********Table Shipping Data**********-->
  <div class="mat-elevation-z8">
    <table mat-table matSort [dataSource]="dataSource">
      <!--**********2.taxNoResponse**********-->
      <ng-container matColumnDef="companyNameTh">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">ชื่อบริษัทผู้ส่งออก
        </th>
        <td mat-cell *matCellDef="let element"> {{element.companyNameTh}} </td>
      </ng-container>
      <!--**********3.taxNoResponse**********-->
      <ng-container matColumnDef="taxNo">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">เลขประจำตัวผู้เสียภาษี
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.taxNo}} </td>
      </ng-container>
      <!--**********4.exporterNoResponse**********-->
      <ng-container matColumnDef="exporterNo">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">ใบทะเบียนเลขที่ ผค.</th>
        <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.exporterNo}} </td>
      </ng-container>
      <!--**********5.createDateResponse**********-->
      <ng-container matColumnDef="createDate">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">วันและเวลาที่รับข้อมูล</th>
        <td mat-cell *matCellDef="let element" style="padding-left: 80px;" sortActionDescription="Sort by name"
          style="text-align: center;"> {{element.createDate | date:'yyyy-MM-dd' || '-'}} </td>
      </ng-container>
      <!--**********6.statusExporterResponse**********-->
      <ng-container matColumnDef="statusExporter">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">สถานะผู้ส่งออก
        </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;"> {{ element.statusExporter == 'C' ? 'ยกเลิก' : element.statusExporter == 'O' ? 'รายเก่า' : element.statusExporter == 'N' ? 'รายใหม่' : 'ไม่ระบุ'}} </td>
      </ng-container>
      <!--**********7.statusUpdateDataResponse**********-->
      <ng-container matColumnDef="statusUpdateData">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;">สถานะการอัปเดตข้อมูล</th>
        <td mat-cell *matCellDef="let element" style="text-align: center;"> {{ element.statusUpdate == 'Y' ? 'ใช้งาน' : element.statusUpdate == 'N' ? 'ไม่ใช้งาน' : 'ไม่ระบุ' }} </td>
      </ng-container>
      <!--**********8.Action button**********-->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef style="text-align: center;"> Action </th>
        <td mat-cell *matCellDef="let element" style="text-align: center;">
          <button mat-icon-button matTooltip="ดูรายละเอียด" matTooltipPosition="above" [routerLink]="['/page/DFT/ExporterDetails']"
            [queryParams]="{ id: element.exporterId}">
            <mat-icon>search</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[10, 20, 30]" showFirstLastButtons
      aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</div>