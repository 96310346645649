<mat-card
  style="display: flex; flex-flow: column; height: 100%; width: 100%; gap: 30px"
>
  <!-- <app-head-o2 [type]="'dailyreport'"></app-head-o2> -->
  <div style="display: flex; flex-flow: row;">
    <div style="display: flex; flex-flow: row; width: 10%"></div>
    <div style="display: flex; flex-flow: row; width: 80%">
      <mat-card class="w-full py-10">
        <div style="display: flex; flex-flow: row; height: 100%">
          <!-- Start Body 2 -->
          <div style="display: flex; flex-flow: row; width: 5%"></div>
          <!-- Card -->
          <div style="display: flex; flex-flow: row; width: 90%">
            <div
              style="
                display: flex;
                flex-flow: column;
                align-items: center;
                width: 100%;
              "
            >
              <mat-card style="width: 100%">
                <mat-card-header
                  style="background-color: dimgray;"
                >
                  <div
                    style="
                      display: flex;
                      align-items: left;
                      font-size: 20px;
                      color: white;
                    "
                  >
                    รายงานประจำวัน
                  </div>
                </mat-card-header>
                <mat-card-content>
                    <form  name ="dailyReportForm" [formGroup]="dailyReportForm" novalidate> 
                    <div class="flex flex-column items-center pl-2.5 pt-10">
                        <div class="flex flex-row basis-1/3  text-lg">
                            <div class="flex-column text-right w-full">
                            <mat-label> วันเริ่มต้น :</mat-label>
                            </div>
                        </div>
                        <div class="flex flex-row basis-1/3  text-lg">
                            <mat-form-field class="w-full pl-2.5" appearance="fill">
                                <input 
                                    matInput 
                                    #input
                                    class="form-control"
                                    [matDatepicker]="picker1" 
                                    (click)="picker1.open()"
                                    readonly
                                    name="startDate"
                                    formControlName="startDate"
                                    [(ngModel)]="dailyReport.startDate"         
                                    required="true"
                                    [max]="myDate"
                                    >
                                <mat-hint>YYYY/MM/DD</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1 dateFormat="YYYY/MM/DD" disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div *ngIf="dailyReportForm.controls['startDate'].invalid && (dailyReportForm.controls['startDate'].dirty || dailyReportForm.controls['startDate'].touched)" class="p-1 text-sm text-red-800" role="alert">
                            <div *ngIf="dailyReportForm.controls['startDate'].errors.required">
                            **กรุณาระบุวันเริ่มต้น.
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-column items-center pl-2.5 pt-5">
                        <div class="flex flex-row basis-1/3  text-lg">
                            <div class="flex-column text-right w-full">
                            <mat-label> วันสุดท้าย :</mat-label>
                            </div>
                        </div>
                        <div class="flex flex-row basis-1/3  text-lg">
                            <mat-form-field class="w-full pl-2.5" appearance="fill">
                                <input 
                                    matInput
                                    #input
                                    class="form-control"
                                    [matDatepicker]="picker2" 
                                    (click)="picker2.open()"
                                    readonly
                                    name="endDate"
                                    formControlName="endDate"
                                    [(ngModel)]="dailyReport.endDate" 
                                    [max]="myDate"
                                    [min]="dailyReport.startDate"
                                    required="true"
                                    >
                                <mat-hint>YYYY/MM/DD</mat-hint>
                                <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2 dateFormat="YYYY/MM/DD" disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div *ngIf="dailyReportForm.controls['endDate'].invalid && (dailyReportForm.controls['endDate'].dirty || dailyReportForm.controls['endDate'].touched)" class="p-1 text-sm text-red-800" role="alert">
                            <div *ngIf="dailyReportForm.controls['endDate'].errors.required">
                            **กรุณาระบุวันสุดท้าย.
                            </div>
                        </div>
                    </div>
                    <div class="flex flex-column items-center pl-2.5 pt-5">
                        <div class="flex flex-row basis-1/3  text-lg">
                            <div class="flex-column text-right w-full">
                            <mat-label> ประเภทสินค้า :</mat-label>
                            </div>
                        </div>
                        <div class="flex flex-row basis-1/3  text-lg">
                            <mat-form-field class="my-form-field w-full pl-2.5" appearance="fill">
                                <mat-select 
                                 [(ngModel)]="dailyReport.typeID"
                                 name="typeID"
                                 formControlName="typeID"
                                 >
                                    <mat-option  value= "0" >Show all</mat-option>
                                    <mat-option  value="1">ข้าวหอมมะลิไทย</mat-option>
                                    <mat-option  value="2">ผลิตภัณฑ์มันสำปะหลัง</mat-option>
                                    <mat-option  value="3">ปุยนุ่น</mat-option>
                                    <mat-option  value="4">ถั่วเขียว</mat-option>
                                    <mat-option  value="5">ถั่วเขียวผิวดำ</mat-option>
                                    <mat-option  value="6">ข้าวฟ่าง</mat-option>
                                    <mat-option  value="7">ปลาป่น</mat-option>
                                    <mat-option  value="8">ข้าวโพด</mat-option>
                                    <mat-option  value="9">ข้าวขาว</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="flex flex-column items-center pl-2.5 pt-5">
                        <div class="flex flex-row basis-1/2 f18 text-lg  justify-end">                  
                            <button 
                                type="submit"
                                mat-raised-button 
                                color="accent" 
                                class="h-12 px-6 m-2 text-lg rounded-lg  justify-right"
                                (click)="ExportExcel()"
                                [disabled]="dailyReportForm.pristine || dailyReportForm.invalid"
                            >
                                Export Excel
                            </button>
                        </div>
                        <div class="flex flex-row basis-1/2 f18  text-lg">
                            <button 
                                mat-raised-button color="accent" 
                                class="h-12 px-6 m-2 text-lg rounded-lg"
                                [disabled]="dailyReportForm.pristine || dailyReportForm.invalid"
                                >
                                Export PDF
                            </button>
                        </div>
                    </div>
                    </form>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <!-- End Card -->
          <div style="display: flex; flex-flow: row; width: 5%"></div>
        </div>
      </mat-card>
    </div>
    <div style="display: flex; flex-flow: row; width: 10%"></div>
    <!-- End Body 2 -->
  </div>
</mat-card>
